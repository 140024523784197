import { getFormattedZero, getFractionDigitsLimitedNumber } from 'common/utils';
import { useCallback, useMemo } from 'react';
import InputField, { PlaceholderType } from './InputField';
import IconButton from 'components/buttons/IconButton';
import BigNumber from 'bignumber.js';

/** @component NumberInputStepper */
const NumberInputStepper = ({
  direction,
  disabled,
  onClick,
}: {
  direction: 'plus' | 'minus';
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return <IconButton size="sm" iconType={direction} type="tonal" className="" disabled={disabled} onClick={onClick} />;
};

/**
 * @caution we use Big numbers like which have 18 digit decimal,
 * so input value must be as string for safety
 *
 * */
type NumberInputProps = {
  value?: string;
  unit?: string;
  placeholder?: string;
  placeholderType?: PlaceholderType;
  onChangeValue?: (value: string) => void;
  isError?: boolean;
  disabled?: boolean;
  maximumFractionDigits?: number;
  step?: number;
  max?: string;
  stepperEnabled?: boolean;
};

const NumberInput = ({
  value = '',
  unit,
  placeholder,
  placeholderType,
  onChangeValue,
  isError,
  disabled,
  maximumFractionDigits,
  step,
  max,
  stepperEnabled = false,
}: NumberInputProps) => {
  /** @desc sanity check */
  const refinedStep = useMemo<number>(() => {
    if (step === undefined || Number.isNaN(step) || step === Infinity) return 1;
    return step;
  }, [step]);

  /** @desc cut fraction digits */
  const cutFractionDigits = useCallback(
    (value: string): string => {
      if (maximumFractionDigits !== undefined)
        return getFractionDigitsLimitedNumber(value, maximumFractionDigits) ?? value;
      return value;
    },
    [maximumFractionDigits]
  );

  const onClickStepper = useCallback(
    (direction: 'plus' | 'minus') => {
      const currentNumber = new BigNumber(value);
      const stepNumber = new BigNumber(refinedStep).multipliedBy(direction === 'plus' ? 1 : -1);
      const steppedNumber = (currentNumber.isNaN() ? new BigNumber(0) : currentNumber).plus(stepNumber);
      const steppedValue = cutFractionDigits(steppedNumber.toString());
      onChangeValue?.(steppedValue);
    },
    [onChangeValue, refinedStep, cutFractionDigits, value]
  );

  return (
    <InputField
      type="number"
      value={value}
      onChangeValue={(value) => onChangeValue?.(cutFractionDigits(value))}
      placeholder={placeholder ?? getFormattedZero({ mantissa: 1 })}
      placeholderType={placeholderType ?? 'number'}
      step={refinedStep}
      max={max}
      isError={isError}
      disabled={disabled}
      left={
        stepperEnabled ? (
          <NumberInputStepper direction="minus" disabled={disabled} onClick={() => onClickStepper('minus')} />
        ) : undefined
      }
      right={
        stepperEnabled ? (
          <NumberInputStepper direction="plus" disabled={disabled} onClick={() => onClickStepper('plus')} />
        ) : (
          <div className="font_caption_m md:font_caption_s text-on_surface_variant_light">{unit}</div>
        )
      }
    />
  );
};

export default NumberInput;
