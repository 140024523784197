const AirdropIconSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4978 8.64997L14.5 8.65204L8.5 14.9713L2.5 8.65204L2.50212 8.65002C2.50243 5.33776 5.18764 2.65274 8.49997 2.65274C11.8123 2.65274 14.4975 5.33773 14.4978 8.64997ZM4.15278 8.65059L7.1428 11.7997L6.14613 8.65059C5.71239 7.86754 4.58652 7.86754 4.15278 8.65059ZM7.40434 8.65059L8.5 12.1125L9.59566 8.65059C9.09583 7.82975 7.90417 7.82975 7.40434 8.65059ZM10.8539 8.65059L9.8572 11.7997L12.8472 8.65059C12.4135 7.86754 11.2876 7.86754 10.8539 8.65059Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AirdropIconSvg;
