import { SigningStargateClient } from '@cosmjs/stargate';
import { useState, useEffect, useCallback } from 'react';
import { useMainStore } from 'provider/mainContext';
import { CLIENT_PROTOCOL } from 'COMMON_VARIABLES';

import useConnectedWallet from './useConnectedWallet';

export default function useOtherChainBalance(queryChainId: string, denom: string) {
  const mainStore = useMainStore();
  const [balance, setBalance] = useState({ denom: '', amount: '0' });
  const { connectedWallet: wallet, isLoading: isWalletLoading } = useConnectedWallet(queryChainId);

  const getChainBalance = useCallback(async () => {
    if (!mainStore.chainsData.isInit || !mainStore.wallet.isActive || !wallet) {
      setBalance({ denom: '', amount: '0' });
    } else if (wallet) {
      const queryChainInfo = mainStore.chainsData.info[queryChainId];
      if (queryChainInfo) {
        const client = await SigningStargateClient.connect(`${CLIENT_PROTOCOL}${queryChainInfo.wsEndpoint}`);
        const result = await client.getBalance(wallet.address, denom);
        setBalance(result);
        client.disconnect();
      }
    }
  }, [queryChainId, denom, mainStore.chainsData.isInit, mainStore.chainsData.info, mainStore.wallet.isActive, wallet]);

  useEffect(() => {
    if (mainStore.chainsData?.info[queryChainId]?.chainId) {
      getChainBalance();
    }
  }, [queryChainId, denom, mainStore.chainsData.info, mainStore.chainsData.isInit, wallet, getChainBalance]);

  return { balance, wallet, isWalletLoading };
}
