import { Coin } from 'common/crescent/lpfarm/types/cosmos/base/v1beta1/coin';
import { ResponseOne } from './format';

const TX_TYPES = [
  'swapOrder',
  'liquidStake',
  'liquidUnstake',
  'poolDeposit',
  'poolWithdraw',
  'ibcSend',
  'vote',
  'lfFarm',
  'lfUnfarm',
  'lpfarmHarvest',
  'lpfarmFarm',
  'lpfarmUnfarm',
] as const;

const TX_EVENT_TYPES = [
  'swapFull',
  'swapCancel',
  'swapPart',
  'swapExpired',
  'txDone',
  'txSend',
  'ibcAck',
  'ibcRecv',
  'TxDone',
] as const;

export type TxType = typeof TX_TYPES[number];
export type TxEventType = typeof TX_EVENT_TYPES[number];

/** raw data */
export interface TxEventBase<T, K> {
  txhash: string;
  alarmed: 'Y' | 'N';
  height: number;
  timestamp_us: number;
  evt_type: TxEventType;
  tx_type: TxType;
  json_raw: T;
  result: K; // could be null or undefined?
}

interface TxSwapJsonRaw {
  reqId: number;
  pairId: number;
  txhash: string;
  direction: 0 | 1;
  orderType: number;
  offerDenom: string;
  orderPrice: string;
  demandDenom: string;
  offerAmount: string;
  expireTimestamp: number;
  orderBaseAmount: string;
  remainOfferAmount: string;
  receivedDemandAmount: string;
}

interface TxSwapResult {
  price: string;
  reqId: number;
  pairId: number;
  batchId: number;
  offerDenom: string;
  demandDenom: string;
  offerAmount: string;
  demandAmount: string;
  swappedBaseAmount: string;
}

interface TxLiquidStakeJsonRaw {
  amount: string;
  txhash: string;
  bondedAmount: string;
}

interface TxLiquidUnstakeJsonRaw {
  amount: string;
  txhash: string;
  unbondingAmount: string;
}

interface TxPoolDepositJsonRaw {
  reqId: number;
  denomA: string;
  denomB: string;
  poolId: number;
  txhash: string;
  depositAAmount: string;
  depositBAmount: string;
}

/** @desc deprecation */
interface TxPoolDepositResult {
  acceptedAAmount?: string;
  acceptedBAmount?: string;
  mintedPoolAmount: string;
}

interface TxPoolWithdrawJsonRaw {
  reqId: number;
  poolId: number;
  txhash: string;
  offerPoolAmount: string;
}

interface TxPoolWithdrawResult {
  denomA: string;
  denomB: string;
  withdrawAAmount: string;
  withdrawBAmount: string;
}

interface TxFarmStakeJsonRaw {
  txhash: string;
  stakingCoin: string;
}

interface TxFarmUnstakeJsonRaw {
  txhash: string;
  unstakingCoin: string;
}

interface TxFarmHarvestJsonRaw {
  txhash: string;
  rewardCoins: string;
  stakingCoinDenom: string;
}

interface TxIBCSendJsonRaw {
  denom: string;
  amount: string;
  sender: string;
  txhash: string;
  receiver: string;
  direction: 0 | 1;
  recvChannel: string;
  sendCahinId: string;
  sendChannel: string;
}

interface TxIBCRecvResult {
  denom: string;
  amount: string;
}

export type VoteOption = 1 | 2 | 3 | 4;

interface TxVoteJsonRaw {
  option: VoteOption;
  weight: string;
  txhash?: string;
}

interface TxLiquidFarmJsonRaw {
  farmer: string;
  farmingCoin: string;
  mintedCoin: string;
  poolId: number;
  txhash: string;
  withdrawRewards: string;
}

interface TxLiquidUnfarmJsonRaw {
  farmer: string;
  poolId: number;
  txhash: string;
  unfarmedCoin: string;
  unfarmingCoin: string;
}

interface TxHarvestJsonRaw {
  rewardCoins: string;
  stakingCoinDenom: string;
  txhash: string;
}

interface TxLpFarmJsonRaw {
  txhash: string;
  stakingCoin: Coin;
  withdrawnRewards: Coin[];
}

interface TxLpUnfarmJsonRaw {
  txhash: string;
  unstakingCoin: Coin;
  withdrawnRewards: Coin[];
}

/** all tx types */
export interface TxSwapOrder extends TxEventBase<TxSwapJsonRaw, TxSwapJsonRaw> {
  tx_type: 'swapOrder';
  evt_type: 'txSend';
}

export interface TxSwapFull extends TxEventBase<TxSwapJsonRaw, TxSwapResult> {
  tx_type: 'swapOrder';
  evt_type: 'swapFull';
}

export interface TxSwapPart extends TxEventBase<TxSwapJsonRaw, TxSwapResult> {
  tx_type: 'swapOrder';
  evt_type: 'swapPart';
}

export interface TxSwapCancel extends TxEventBase<TxSwapJsonRaw, TxSwapResult> {
  tx_type: 'swapOrder'; // ??
  evt_type: 'swapCancel';
}

export interface TxSwapExpired extends TxEventBase<TxSwapJsonRaw, TxSwapResult> {
  tx_type: 'swapOrder';
  evt_type: 'swapExpired';
}

export interface TxLiquidStake extends TxEventBase<TxLiquidStakeJsonRaw, TxLiquidStakeJsonRaw> {
  tx_type: 'liquidStake';
  evt_type: 'txDone' | 'txSend'; // ??
}

export interface TxLiquidUnstake extends TxEventBase<TxLiquidUnstakeJsonRaw, TxLiquidUnstakeJsonRaw> {
  tx_type: 'liquidUnstake';
  evt_type: 'txDone' | 'txSend'; // ??
}

export interface TxPoolDepositSend extends TxEventBase<TxPoolDepositJsonRaw, TxPoolDepositJsonRaw> {
  tx_type: 'poolDeposit';
  evt_type: 'txSend';
}

export interface TxPoolDepositDone extends TxEventBase<TxPoolDepositJsonRaw, TxPoolDepositResult> {
  tx_type: 'poolDeposit';
  evt_type: 'txDone';
}

export interface TxPoolWithdrawSend extends TxEventBase<TxPoolWithdrawJsonRaw, TxPoolWithdrawJsonRaw> {
  tx_type: 'poolWithdraw';
  evt_type: 'txSend';
}

export interface TxPoolWithdrawDone extends TxEventBase<TxPoolWithdrawJsonRaw, TxPoolWithdrawResult> {
  tx_type: 'poolWithdraw';
  evt_type: 'txDone';
}

// export interface TxFarmStake extends TxEventBase<TxFarmStakeJsonRaw, TxFarmStakeJsonRaw> {
//   tx_type: 'farmStake';
//   evt_type: 'txDone';
// }

// export interface TxFarmUnStake extends TxEventBase<TxFarmUnstakeJsonRaw, TxFarmUnstakeJsonRaw> {
//   tx_type: 'farmUnstake';
//   evt_type: 'txDone';
// }

// export interface TxFarmHarvest extends TxEventBase<TxFarmHarvestJsonRaw, TxFarmHarvestJsonRaw> {
//   tx_type: 'farmHarvest';
//   evt_type: 'txDone';
// }

// export interface TxFarmHarvestAuto extends TxEventBase<TxFarmHarvestJsonRaw, TxFarmHarvestJsonRaw> {
//   tx_type: 'farmHarvestAuto';
//   evt_type: 'txDone';
// }

export interface TxIBCSend extends TxEventBase<TxIBCSendJsonRaw, TxIBCSendJsonRaw> {
  tx_type: 'ibcSend';
  evt_type: 'txSend';
}

export interface TxIBCAck extends TxEventBase<TxIBCSendJsonRaw, null> {
  tx_type: 'ibcSend';
  evt_type: 'ibcAck';
}

export interface TxIBCRecv extends TxEventBase<TxIBCSendJsonRaw, TxIBCRecvResult> {
  tx_type: 'ibcSend';
  evt_type: 'ibcRecv';
}

export interface TxVote extends TxEventBase<TxVoteJsonRaw, TxVoteJsonRaw> {
  tx_type: 'vote';
  evt_type: 'txDone' | 'TxDone'; // ??
}

// export interface TxAirdrop extends TxEventBase<any, any> {
//   tx_type: 'airdrop';
//   evt_type: 'txDone' | 'txSend'; // ??
// }

export interface TxLiquidFarm extends TxEventBase<TxLiquidFarmJsonRaw, TxLiquidFarmJsonRaw> {
  tx_type: 'lfFarm';
  evt_type: 'txDone';
}

export interface TxLiquidUnfarm extends TxEventBase<TxLiquidUnfarmJsonRaw, TxLiquidUnfarmJsonRaw> {
  tx_type: 'lfUnfarm';
  evt_type: 'txDone';
}

export interface TxHarvest extends TxEventBase<TxHarvestJsonRaw, TxHarvestJsonRaw> {
  tx_type: 'lpfarmHarvest';
  evt_type: 'txDone';
}

export interface TxLpFarm extends TxEventBase<TxLpFarmJsonRaw, TxLpFarmJsonRaw> {
  tx_type: 'lpfarmFarm';
  evt_type: 'txDone';
}

export interface TxLpUnfarm extends TxEventBase<TxLpUnfarmJsonRaw, TxLpUnfarmJsonRaw> {
  tx_type: 'lpfarmUnfarm';
  evt_type: 'txDone';
}

export type TxEvent =
  | TxSwapOrder
  | TxSwapFull
  | TxSwapPart
  | TxSwapCancel
  | TxSwapExpired
  | TxLiquidStake
  | TxLiquidUnstake
  | TxPoolDepositSend
  | TxPoolDepositDone
  | TxPoolWithdrawSend
  | TxPoolWithdrawDone
  // | TxFarmStake
  // | TxFarmUnStake
  // | TxFarmHarvest
  // | TxFarmHarvestAuto
  | TxIBCSend
  | TxIBCAck
  | TxIBCRecv
  | TxVote
  // | TxAirdrop
  | TxLiquidFarm
  | TxLiquidUnfarm
  | TxHarvest
  | TxLpFarm
  | TxLpUnfarm;

export interface TxEventResponse extends ResponseOne<TxEvent[]> {}

/** @summary type guard */
export function isValidTxEventType(txEvent: TxEvent): txEvent is TxEvent {
  return TX_EVENT_TYPES.includes(txEvent.evt_type) && TX_TYPES.includes(txEvent.tx_type);
}
