import type { MyPoolDetail, PoolDetail } from 'types/pool';

/** @desc type guard to narrow type */
export const hasMyPoolDetailProperties = (pool: any): boolean => {
  return pool?.myBalance !== undefined;
};

export const isMyPoolDetail = (pool: PoolDetail | MyPoolDetail): pool is MyPoolDetail => {
  return hasMyPoolDetailProperties(pool);
};
