import { useEffect, useState } from 'react';
import OrderbookSet from 'components/orderbook/index';
import SwapWidget from 'components/widgets/SwapWidget';
import { Observer } from 'mobx-react-lite';
import TradeTypeSelector from 'components/trade/TradeTypeSelector';
import useNavigateToDesktopTrade from './useNavigateToDesktopTrade';
import useTradeTypeSearch from 'hooks/trade/useTradeTypeSearch';
import { useNavigate } from 'react-router-dom';
import type { TradeType } from 'types/swap';

const Trading = () => {
  const { getTradeTypeSearch, replaceTradeTypeSearch } = useTradeTypeSearch();

  const tradeType = getTradeTypeSearch();
  useNavigateToDesktopTrade(tradeType);

  const [selectedIndex, setSelectedIndex] = useState<0 | 1>(0);

  const navigate = useNavigate();

  useEffect(() => {
    const tradeType = localStorage.getItem('tradeType') ?? 'swap';
    if (tradeType === 'swap' || tradeType === 'orderbook') {
      setSelectedIndex(tradeType === 'swap' ? 0 : 1);
    }
  }, []);

  const onChangeTradeType = (index: 0 | 1) => {
    setSelectedIndex(index);

    const tradeType: TradeType = index === 0 ? 'swap' : 'orderbook';
    localStorage.setItem('tradeType', tradeType);
    navigate(`/trading${replaceTradeTypeSearch(tradeType)}`);
  };

  return (
    <Observer>
      {() => (
        <main role="main" className="relative w-full m-auto">
          <div className="px-4 py-3 md:hidden">
            <TradeTypeSelector selectedIndex={selectedIndex} onChangeIndex={onChangeTradeType} />
          </div>

          {selectedIndex === 0 ? (
            <div className="flex flex-col items-center md:pt-[3.75rem] px-4 md:px-0">
              <SwapWidget />
            </div>
          ) : (
            <div className="pb-20 md:pb-0">
              <OrderbookSet />
            </div>
          )}
        </main>
      )}
    </Observer>
  );
};

export default Trading;
