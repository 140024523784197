/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";
import * as Long from "long";
import { util, configure, Writer, Reader } from "protobufjs/minimal";
import { Coin, DecCoin } from "../../../cosmos/base/v1beta1/coin";
import { Duration } from "../../../google/protobuf/duration";

export const protobufPackage = "crescent.lpfarm.v1beta1";

export interface Params {
  privatePlanCreationFee: Coin[];
  feeCollector: string;
  maxNumPrivatePlans: number;
  maxBlockDuration: Duration | undefined;
}

export interface Plan {
  id: number;
  description: string;
  farmingPoolAddress: string;
  terminationAddress: string;
  rewardAllocations: RewardAllocation[];
  startTime: Date | undefined;
  endTime: Date | undefined;
  isPrivate: boolean;
  isTerminated: boolean;
}

export interface RewardAllocation {
  denom: string;
  pairId: number;
  rewardsPerDay: Coin[];
}

export interface Farm {
  totalFarmingAmount: string;
  currentRewards: DecCoin[];
  outstandingRewards: DecCoin[];
  period: number;
}

export interface Position {
  farmer: string;
  denom: string;
  farmingAmount: string;
  previousPeriod: number;
  startingBlockHeight: number;
}

export interface HistoricalRewards {
  cumulativeUnitRewards: DecCoin[];
  referenceCount: number;
}

const baseParams: object = { feeCollector: "", maxNumPrivatePlans: 0 };

export const Params = {
  encode(message: Params, writer: Writer = Writer.create()): Writer {
    for (const v of message.privatePlanCreationFee) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.feeCollector !== "") {
      writer.uint32(18).string(message.feeCollector);
    }
    if (message.maxNumPrivatePlans !== 0) {
      writer.uint32(24).uint32(message.maxNumPrivatePlans);
    }
    if (message.maxBlockDuration !== undefined) {
      Duration.encode(
        message.maxBlockDuration,
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Params {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseParams } as Params;
    message.privatePlanCreationFee = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.privatePlanCreationFee.push(
            Coin.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.feeCollector = reader.string();
          break;
        case 3:
          message.maxNumPrivatePlans = reader.uint32();
          break;
        case 4:
          message.maxBlockDuration = Duration.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Params {
    const message = { ...baseParams } as Params;
    message.privatePlanCreationFee = [];
    if (
      object.privatePlanCreationFee !== undefined &&
      object.privatePlanCreationFee !== null
    ) {
      for (const e of object.privatePlanCreationFee) {
        message.privatePlanCreationFee.push(Coin.fromJSON(e));
      }
    }
    if (object.feeCollector !== undefined && object.feeCollector !== null) {
      message.feeCollector = String(object.feeCollector);
    } else {
      message.feeCollector = "";
    }
    if (
      object.maxNumPrivatePlans !== undefined &&
      object.maxNumPrivatePlans !== null
    ) {
      message.maxNumPrivatePlans = Number(object.maxNumPrivatePlans);
    } else {
      message.maxNumPrivatePlans = 0;
    }
    if (
      object.maxBlockDuration !== undefined &&
      object.maxBlockDuration !== null
    ) {
      message.maxBlockDuration = Duration.fromJSON(object.maxBlockDuration);
    } else {
      message.maxBlockDuration = undefined;
    }
    return message;
  },

  toJSON(message: Params): unknown {
    const obj: any = {};
    if (message.privatePlanCreationFee) {
      obj.privatePlanCreationFee = message.privatePlanCreationFee.map((e) =>
        e ? Coin.toJSON(e) : undefined
      );
    } else {
      obj.privatePlanCreationFee = [];
    }
    message.feeCollector !== undefined &&
      (obj.feeCollector = message.feeCollector);
    message.maxNumPrivatePlans !== undefined &&
      (obj.maxNumPrivatePlans = message.maxNumPrivatePlans);
    message.maxBlockDuration !== undefined &&
      (obj.maxBlockDuration = message.maxBlockDuration
        ? Duration.toJSON(message.maxBlockDuration)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Params>): Params {
    const message = { ...baseParams } as Params;
    message.privatePlanCreationFee = [];
    if (
      object.privatePlanCreationFee !== undefined &&
      object.privatePlanCreationFee !== null
    ) {
      for (const e of object.privatePlanCreationFee) {
        message.privatePlanCreationFee.push(Coin.fromPartial(e));
      }
    }
    if (object.feeCollector !== undefined && object.feeCollector !== null) {
      message.feeCollector = object.feeCollector;
    } else {
      message.feeCollector = "";
    }
    if (
      object.maxNumPrivatePlans !== undefined &&
      object.maxNumPrivatePlans !== null
    ) {
      message.maxNumPrivatePlans = object.maxNumPrivatePlans;
    } else {
      message.maxNumPrivatePlans = 0;
    }
    if (
      object.maxBlockDuration !== undefined &&
      object.maxBlockDuration !== null
    ) {
      message.maxBlockDuration = Duration.fromPartial(object.maxBlockDuration);
    } else {
      message.maxBlockDuration = undefined;
    }
    return message;
  },
};

const basePlan: object = {
  id: 0,
  description: "",
  farmingPoolAddress: "",
  terminationAddress: "",
  isPrivate: false,
  isTerminated: false,
};

export const Plan = {
  encode(message: Plan, writer: Writer = Writer.create()): Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint64(message.id);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.farmingPoolAddress !== "") {
      writer.uint32(26).string(message.farmingPoolAddress);
    }
    if (message.terminationAddress !== "") {
      writer.uint32(34).string(message.terminationAddress);
    }
    for (const v of message.rewardAllocations) {
      RewardAllocation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.startTime),
        writer.uint32(50).fork()
      ).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.endTime),
        writer.uint32(58).fork()
      ).ldelim();
    }
    if (message.isPrivate === true) {
      writer.uint32(64).bool(message.isPrivate);
    }
    if (message.isTerminated === true) {
      writer.uint32(72).bool(message.isTerminated);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Plan {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePlan } as Plan;
    message.rewardAllocations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.description = reader.string();
          break;
        case 3:
          message.farmingPoolAddress = reader.string();
          break;
        case 4:
          message.terminationAddress = reader.string();
          break;
        case 5:
          message.rewardAllocations.push(
            RewardAllocation.decode(reader, reader.uint32())
          );
          break;
        case 6:
          message.startTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 7:
          message.endTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 8:
          message.isPrivate = reader.bool();
          break;
        case 9:
          message.isTerminated = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Plan {
    const message = { ...basePlan } as Plan;
    message.rewardAllocations = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = Number(object.id);
    } else {
      message.id = 0;
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description);
    } else {
      message.description = "";
    }
    if (
      object.farmingPoolAddress !== undefined &&
      object.farmingPoolAddress !== null
    ) {
      message.farmingPoolAddress = String(object.farmingPoolAddress);
    } else {
      message.farmingPoolAddress = "";
    }
    if (
      object.terminationAddress !== undefined &&
      object.terminationAddress !== null
    ) {
      message.terminationAddress = String(object.terminationAddress);
    } else {
      message.terminationAddress = "";
    }
    if (
      object.rewardAllocations !== undefined &&
      object.rewardAllocations !== null
    ) {
      for (const e of object.rewardAllocations) {
        message.rewardAllocations.push(RewardAllocation.fromJSON(e));
      }
    }
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = fromJsonTimestamp(object.startTime);
    } else {
      message.startTime = undefined;
    }
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = fromJsonTimestamp(object.endTime);
    } else {
      message.endTime = undefined;
    }
    if (object.isPrivate !== undefined && object.isPrivate !== null) {
      message.isPrivate = Boolean(object.isPrivate);
    } else {
      message.isPrivate = false;
    }
    if (object.isTerminated !== undefined && object.isTerminated !== null) {
      message.isTerminated = Boolean(object.isTerminated);
    } else {
      message.isTerminated = false;
    }
    return message;
  },

  toJSON(message: Plan): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.description !== undefined &&
      (obj.description = message.description);
    message.farmingPoolAddress !== undefined &&
      (obj.farmingPoolAddress = message.farmingPoolAddress);
    message.terminationAddress !== undefined &&
      (obj.terminationAddress = message.terminationAddress);
    if (message.rewardAllocations) {
      obj.rewardAllocations = message.rewardAllocations.map((e) =>
        e ? RewardAllocation.toJSON(e) : undefined
      );
    } else {
      obj.rewardAllocations = [];
    }
    message.startTime !== undefined &&
      (obj.startTime =
        message.startTime !== undefined
          ? message.startTime.toISOString()
          : null);
    message.endTime !== undefined &&
      (obj.endTime =
        message.endTime !== undefined ? message.endTime.toISOString() : null);
    message.isPrivate !== undefined && (obj.isPrivate = message.isPrivate);
    message.isTerminated !== undefined &&
      (obj.isTerminated = message.isTerminated);
    return obj;
  },

  fromPartial(object: DeepPartial<Plan>): Plan {
    const message = { ...basePlan } as Plan;
    message.rewardAllocations = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = 0;
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    } else {
      message.description = "";
    }
    if (
      object.farmingPoolAddress !== undefined &&
      object.farmingPoolAddress !== null
    ) {
      message.farmingPoolAddress = object.farmingPoolAddress;
    } else {
      message.farmingPoolAddress = "";
    }
    if (
      object.terminationAddress !== undefined &&
      object.terminationAddress !== null
    ) {
      message.terminationAddress = object.terminationAddress;
    } else {
      message.terminationAddress = "";
    }
    if (
      object.rewardAllocations !== undefined &&
      object.rewardAllocations !== null
    ) {
      for (const e of object.rewardAllocations) {
        message.rewardAllocations.push(RewardAllocation.fromPartial(e));
      }
    }
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = object.startTime;
    } else {
      message.startTime = undefined;
    }
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = object.endTime;
    } else {
      message.endTime = undefined;
    }
    if (object.isPrivate !== undefined && object.isPrivate !== null) {
      message.isPrivate = object.isPrivate;
    } else {
      message.isPrivate = false;
    }
    if (object.isTerminated !== undefined && object.isTerminated !== null) {
      message.isTerminated = object.isTerminated;
    } else {
      message.isTerminated = false;
    }
    return message;
  },
};

const baseRewardAllocation: object = { denom: "", pairId: 0 };

export const RewardAllocation = {
  encode(message: RewardAllocation, writer: Writer = Writer.create()): Writer {
    if (message.denom !== "") {
      writer.uint32(10).string(message.denom);
    }
    if (message.pairId !== 0) {
      writer.uint32(16).uint64(message.pairId);
    }
    for (const v of message.rewardsPerDay) {
      Coin.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): RewardAllocation {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRewardAllocation } as RewardAllocation;
    message.rewardsPerDay = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string();
          break;
        case 2:
          message.pairId = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.rewardsPerDay.push(Coin.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RewardAllocation {
    const message = { ...baseRewardAllocation } as RewardAllocation;
    message.rewardsPerDay = [];
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom);
    } else {
      message.denom = "";
    }
    if (object.pairId !== undefined && object.pairId !== null) {
      message.pairId = Number(object.pairId);
    } else {
      message.pairId = 0;
    }
    if (object.rewardsPerDay !== undefined && object.rewardsPerDay !== null) {
      for (const e of object.rewardsPerDay) {
        message.rewardsPerDay.push(Coin.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: RewardAllocation): unknown {
    const obj: any = {};
    message.denom !== undefined && (obj.denom = message.denom);
    message.pairId !== undefined && (obj.pairId = message.pairId);
    if (message.rewardsPerDay) {
      obj.rewardsPerDay = message.rewardsPerDay.map((e) =>
        e ? Coin.toJSON(e) : undefined
      );
    } else {
      obj.rewardsPerDay = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<RewardAllocation>): RewardAllocation {
    const message = { ...baseRewardAllocation } as RewardAllocation;
    message.rewardsPerDay = [];
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom;
    } else {
      message.denom = "";
    }
    if (object.pairId !== undefined && object.pairId !== null) {
      message.pairId = object.pairId;
    } else {
      message.pairId = 0;
    }
    if (object.rewardsPerDay !== undefined && object.rewardsPerDay !== null) {
      for (const e of object.rewardsPerDay) {
        message.rewardsPerDay.push(Coin.fromPartial(e));
      }
    }
    return message;
  },
};

const baseFarm: object = { totalFarmingAmount: "", period: 0 };

export const Farm = {
  encode(message: Farm, writer: Writer = Writer.create()): Writer {
    if (message.totalFarmingAmount !== "") {
      writer.uint32(10).string(message.totalFarmingAmount);
    }
    for (const v of message.currentRewards) {
      DecCoin.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.outstandingRewards) {
      DecCoin.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.period !== 0) {
      writer.uint32(32).uint64(message.period);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Farm {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFarm } as Farm;
    message.currentRewards = [];
    message.outstandingRewards = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.totalFarmingAmount = reader.string();
          break;
        case 2:
          message.currentRewards.push(DecCoin.decode(reader, reader.uint32()));
          break;
        case 3:
          message.outstandingRewards.push(
            DecCoin.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.period = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Farm {
    const message = { ...baseFarm } as Farm;
    message.currentRewards = [];
    message.outstandingRewards = [];
    if (
      object.totalFarmingAmount !== undefined &&
      object.totalFarmingAmount !== null
    ) {
      message.totalFarmingAmount = String(object.totalFarmingAmount);
    } else {
      message.totalFarmingAmount = "";
    }
    if (object.currentRewards !== undefined && object.currentRewards !== null) {
      for (const e of object.currentRewards) {
        message.currentRewards.push(DecCoin.fromJSON(e));
      }
    }
    if (
      object.outstandingRewards !== undefined &&
      object.outstandingRewards !== null
    ) {
      for (const e of object.outstandingRewards) {
        message.outstandingRewards.push(DecCoin.fromJSON(e));
      }
    }
    if (object.period !== undefined && object.period !== null) {
      message.period = Number(object.period);
    } else {
      message.period = 0;
    }
    return message;
  },

  toJSON(message: Farm): unknown {
    const obj: any = {};
    message.totalFarmingAmount !== undefined &&
      (obj.totalFarmingAmount = message.totalFarmingAmount);
    if (message.currentRewards) {
      obj.currentRewards = message.currentRewards.map((e) =>
        e ? DecCoin.toJSON(e) : undefined
      );
    } else {
      obj.currentRewards = [];
    }
    if (message.outstandingRewards) {
      obj.outstandingRewards = message.outstandingRewards.map((e) =>
        e ? DecCoin.toJSON(e) : undefined
      );
    } else {
      obj.outstandingRewards = [];
    }
    message.period !== undefined && (obj.period = message.period);
    return obj;
  },

  fromPartial(object: DeepPartial<Farm>): Farm {
    const message = { ...baseFarm } as Farm;
    message.currentRewards = [];
    message.outstandingRewards = [];
    if (
      object.totalFarmingAmount !== undefined &&
      object.totalFarmingAmount !== null
    ) {
      message.totalFarmingAmount = object.totalFarmingAmount;
    } else {
      message.totalFarmingAmount = "";
    }
    if (object.currentRewards !== undefined && object.currentRewards !== null) {
      for (const e of object.currentRewards) {
        message.currentRewards.push(DecCoin.fromPartial(e));
      }
    }
    if (
      object.outstandingRewards !== undefined &&
      object.outstandingRewards !== null
    ) {
      for (const e of object.outstandingRewards) {
        message.outstandingRewards.push(DecCoin.fromPartial(e));
      }
    }
    if (object.period !== undefined && object.period !== null) {
      message.period = object.period;
    } else {
      message.period = 0;
    }
    return message;
  },
};

const basePosition: object = {
  farmer: "",
  denom: "",
  farmingAmount: "",
  previousPeriod: 0,
  startingBlockHeight: 0,
};

export const Position = {
  encode(message: Position, writer: Writer = Writer.create()): Writer {
    if (message.farmer !== "") {
      writer.uint32(10).string(message.farmer);
    }
    if (message.denom !== "") {
      writer.uint32(18).string(message.denom);
    }
    if (message.farmingAmount !== "") {
      writer.uint32(26).string(message.farmingAmount);
    }
    if (message.previousPeriod !== 0) {
      writer.uint32(32).uint64(message.previousPeriod);
    }
    if (message.startingBlockHeight !== 0) {
      writer.uint32(40).int64(message.startingBlockHeight);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): Position {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePosition } as Position;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.farmer = reader.string();
          break;
        case 2:
          message.denom = reader.string();
          break;
        case 3:
          message.farmingAmount = reader.string();
          break;
        case 4:
          message.previousPeriod = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.startingBlockHeight = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Position {
    const message = { ...basePosition } as Position;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = "";
    }
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom);
    } else {
      message.denom = "";
    }
    if (object.farmingAmount !== undefined && object.farmingAmount !== null) {
      message.farmingAmount = String(object.farmingAmount);
    } else {
      message.farmingAmount = "";
    }
    if (object.previousPeriod !== undefined && object.previousPeriod !== null) {
      message.previousPeriod = Number(object.previousPeriod);
    } else {
      message.previousPeriod = 0;
    }
    if (
      object.startingBlockHeight !== undefined &&
      object.startingBlockHeight !== null
    ) {
      message.startingBlockHeight = Number(object.startingBlockHeight);
    } else {
      message.startingBlockHeight = 0;
    }
    return message;
  },

  toJSON(message: Position): unknown {
    const obj: any = {};
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.denom !== undefined && (obj.denom = message.denom);
    message.farmingAmount !== undefined &&
      (obj.farmingAmount = message.farmingAmount);
    message.previousPeriod !== undefined &&
      (obj.previousPeriod = message.previousPeriod);
    message.startingBlockHeight !== undefined &&
      (obj.startingBlockHeight = message.startingBlockHeight);
    return obj;
  },

  fromPartial(object: DeepPartial<Position>): Position {
    const message = { ...basePosition } as Position;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = "";
    }
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom;
    } else {
      message.denom = "";
    }
    if (object.farmingAmount !== undefined && object.farmingAmount !== null) {
      message.farmingAmount = object.farmingAmount;
    } else {
      message.farmingAmount = "";
    }
    if (object.previousPeriod !== undefined && object.previousPeriod !== null) {
      message.previousPeriod = object.previousPeriod;
    } else {
      message.previousPeriod = 0;
    }
    if (
      object.startingBlockHeight !== undefined &&
      object.startingBlockHeight !== null
    ) {
      message.startingBlockHeight = object.startingBlockHeight;
    } else {
      message.startingBlockHeight = 0;
    }
    return message;
  },
};

const baseHistoricalRewards: object = { referenceCount: 0 };

export const HistoricalRewards = {
  encode(message: HistoricalRewards, writer: Writer = Writer.create()): Writer {
    for (const v of message.cumulativeUnitRewards) {
      DecCoin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.referenceCount !== 0) {
      writer.uint32(16).uint32(message.referenceCount);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): HistoricalRewards {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseHistoricalRewards } as HistoricalRewards;
    message.cumulativeUnitRewards = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.cumulativeUnitRewards.push(
            DecCoin.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.referenceCount = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HistoricalRewards {
    const message = { ...baseHistoricalRewards } as HistoricalRewards;
    message.cumulativeUnitRewards = [];
    if (
      object.cumulativeUnitRewards !== undefined &&
      object.cumulativeUnitRewards !== null
    ) {
      for (const e of object.cumulativeUnitRewards) {
        message.cumulativeUnitRewards.push(DecCoin.fromJSON(e));
      }
    }
    if (object.referenceCount !== undefined && object.referenceCount !== null) {
      message.referenceCount = Number(object.referenceCount);
    } else {
      message.referenceCount = 0;
    }
    return message;
  },

  toJSON(message: HistoricalRewards): unknown {
    const obj: any = {};
    if (message.cumulativeUnitRewards) {
      obj.cumulativeUnitRewards = message.cumulativeUnitRewards.map((e) =>
        e ? DecCoin.toJSON(e) : undefined
      );
    } else {
      obj.cumulativeUnitRewards = [];
    }
    message.referenceCount !== undefined &&
      (obj.referenceCount = message.referenceCount);
    return obj;
  },

  fromPartial(object: DeepPartial<HistoricalRewards>): HistoricalRewards {
    const message = { ...baseHistoricalRewards } as HistoricalRewards;
    message.cumulativeUnitRewards = [];
    if (
      object.cumulativeUnitRewards !== undefined &&
      object.cumulativeUnitRewards !== null
    ) {
      for (const e of object.cumulativeUnitRewards) {
        message.cumulativeUnitRewards.push(DecCoin.fromPartial(e));
      }
    }
    if (object.referenceCount !== undefined && object.referenceCount !== null) {
      message.referenceCount = object.referenceCount;
    } else {
      message.referenceCount = 0;
    }
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// @ts-ignore
if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
