import { formatTextCase } from 'common/utils';
import Coin from 'components/coins/Coin';
import type { InfoAsset } from 'types/asset';
import type { InfoChain } from 'types/chain';

type IBCProcessVisualWidgetProps = {
  asset: InfoAsset;
  fromChain: InfoChain;
  toChain: InfoChain;
  isWithdraw: boolean;
};

const IBCProcessVisualWidget = ({ asset, fromChain, toChain, isWithdraw }: IBCProcessVisualWidgetProps) => {
  return (
    <div
      className={`flex justify-between items-center py-3 rounded-4px ${
        isWithdraw ? 'bg-linear-ibc-withdraw' : 'bg-linear-ibc-deposit'
      }`}
    >
      <div className="w-[5.375rem] h-[58px] flex flex-col items-center gap-y-2 px-2">
        <div className="grow-0 shrink-0 h-fit font_caption_s text-on_surface_variant_dark">From</div>
        <div className="grow shrink flex justify-center items-center font_data_s text-white whitespace-normal break-words text-center">
          {isWithdraw ? 'Crescent' : formatTextCase(fromChain.displayName, { capitalize: 'first' })}
        </div>
      </div>

      {/* animation */}
      <div className="relative grow shrink flex items-center w-[100px] h-4 bg-diamond">
        <Coin size="24px" asset={asset} className="animate-send" />
      </div>

      <div className="w-[5.375rem] h-[58px] flex flex-col items-center gap-y-2 px-2">
        <div className="grow-0 shrink-0 h-fit font_caption_s text-on_surface_variant_dark">To</div>
        <div className="grow shrink flex justify-center items-center font_data_s text-white whitespace-normal break-words text-center">
          {isWithdraw ? formatTextCase(toChain.displayName, { capitalize: 'first' }) : 'Crescent'}
        </div>
      </div>
    </div>
  );
};

export default IBCProcessVisualWidget;
