const MetalicLayer = ({ className = '' }: { className?: string }) => {
  return (
    <div
      className={`${className} relative w-full h-full animate-moving-bg`}
      style={{
        willChange: 'background-position',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(90deg, rgba(255, 255, 255, 0) 54.62%, #FFFFFF 77.33%, rgba(255, 255, 255, 0) 100%), transparent',
        backgroundSize: '400%',
      }}
    ></div>
  );
};

export default MetalicLayer;
