import { formatAmount, formatUSD } from 'common/utils';
import Coin from 'components/coins/Coin';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import type { CoinDetail } from 'types/asset';

const AllClaimableRewards = ({ rewardsByRewardToken }: { rewardsByRewardToken: CoinDetail[] }) => {
  return (
    <TooltipInner title="All Claimable Rewards">
      <div className="space-y-3">
        {rewardsByRewardToken.map((reward, index) => (
          <div className="flex items-center gap-x-2" key={index}>
            <Coin asset={reward.asset} size="24px" />
            <NumberText
              size="sm"
              value={formatAmount(reward.amount, reward.asset.exponent)}
              unit={reward.asset.ticker}
              subValue={`≈ ${formatUSD(reward.amountUSD)}`}
            />
          </div>
        ))}
      </div>
    </TooltipInner>
  );
};

export default AllClaimableRewards;
