import { observer } from 'mobx-react-lite';
import MyInvestment from 'pages/components/MyInvestment';
import PairsPoolsTable from 'pages/components/PairsPoolsTable';
import PoolHighlights from 'pages/components/PoolHighlights';
import { useCallback, useEffect, useState } from 'react';
import { PoolDetail } from 'types/pool';
import ManageModal from 'components/modals/ManageModal';
import FarmFAQArea from 'pages/components/FarmFAQArea';
import PageTitleArea from 'components/layout/PageTitleArea';
import H3 from 'components/texts/H3';
import { useMainStore } from 'provider/mainContext';
import { useLocation } from 'react-router-dom';
import { MENU_NAMES } from 'COMMON_VARIABLES';

const Farm = observer(() => {
  const store = useMainStore();
  const pools = store.getPools();
  const assets = store.getAssetDetails();
  const location = useLocation();

  /** @summary manage modal */
  const [modalPool, setModalPool] = useState<PoolDetail | undefined>(undefined);
  const onPoolClick = useCallback((pool: PoolDetail) => setModalPool(pool), []);
  const [isAlreadyOpend, setIsAlreadyOpend] = useState(false);

  useEffect(() => {
    const paramsRaw = location.search.replace('?', '').split('&');
    const params: Record<string, string> = {};
    paramsRaw.forEach((item) => {
      const data = item.split('=');
      params[data[0]] = data[1];
    });
    const poolId = params['open_modal_pool_id'];
    if (!isAlreadyOpend && pools.length > 0 && poolId) {
      setModalPool(pools.find((pool) => pool.poolId === Number(poolId)));
      setIsAlreadyOpend(true);
    }
  }, [pools.length]);

  return (
    <div className="pb-0">
      <PageTitleArea title={MENU_NAMES.EARN} />

      <main role="main" className="relative w-full max-w-content m-auto px-4 pt-5 md:pt-[3rem] md:px-0">
        <div className="space-y-[2.25rem] md:space-y-[3.5rem]">
          {/* my investment */}
          {store.wallet.isActive && store.wallet.address && (
            <MyInvestment pools={pools} assets={assets} onPoolClick={onPoolClick} localStorageKeyPrefix="farm" />
          )}

          {/* all pools */}
          <div className="space-y-6">
            <H3>Pools</H3>

            <div>
              <section className="mb-[3.5rem]">
                <PoolHighlights pools={pools} onPoolClick={onPoolClick} />
              </section>

              <section className="overflow-x-auto overflow-y-hidden">
                <div className="md:min-w-[72rem]">
                  <PairsPoolsTable
                    pools={pools}
                    onPoolClick={onPoolClick}
                    hasFilter={true}
                    hasSearchInput={true}
                    localStorageKeyPrefix="pools-table"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>

      <FarmFAQArea className="mt-[3rem] md:mt-[7.5rem]" />

      {/* manage modal */}
      {modalPool && (
        <ManageModal
          isLfByDefault={true}
          pool={modalPool}
          isOpen={modalPool !== undefined}
          close={() => {
            setModalPool(undefined);
          }}
        />
      )}
    </div>
  );
});

export default Farm;
