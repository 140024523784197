import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import NA from 'components/texts/NA';

const StakingAPR = ({ apr }: { apr: BigNumber | undefined }) => {
  return (
    <div className="flex justify-between items-center gap-x-2 px-3 py-3 bg-primary_container text-on_primary_container rounded-4px">
      <div className="font_headline_s_mobile md:font_headline_s">Staking APR</div>
      <div className="font_headline_number_l_mobile md:font_headline_number_l">
        {apr ? formatAmount(apr, 2) + '%' : <NA />}
      </div>
    </div>
  );
};

export default StakingAPR;
