import { EncodeObject } from '@cosmjs/proto-signing';
import { MsgLimitOrder } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import BigNumber from 'bignumber.js';
import msgEncoder from 'common/sign/msgEncoder';
import createClaimMsg from './Claim';

const createLimitOrderMsg = (txData: any, creAddress: any) => {
  const pairBaseDenom = txData.selectedPair.baseDenom;
  const offerDenom = txData.pay.asset.denom;
  const demandDenom = txData.receive.asset.denom;
  const isReverse = pairBaseDenom === demandDenom;
  const offerAmount = txData.pay.amount;
  const demandAmount = txData.receive.amount;
  const orderDirection = pairBaseDenom === offerDenom ? 2 : 1;

  const limitOrderData: MsgLimitOrder = {
    orderer: creAddress,
    pairId: txData.selectedPair.pairId,
    direction: orderDirection,
    offerCoin: {
      denom: offerDenom,
      amount: offerAmount,
    },
    demandCoinDenom: demandDenom,
    price: new BigNumber(txData.orderPrice)
      .shiftedBy(18)
      .decimalPlaces(0, orderDirection === 1 ? BigNumber.ROUND_FLOOR : BigNumber.ROUND_CEIL)
      .toString(),
    amount: isReverse ? demandAmount : offerAmount,

    orderLifespan: { seconds: 0, nanos: 0 },
  };
  console.log(limitOrderData);
  const msg = msgEncoder.msgLimitOrder(limitOrderData);
  const msgs: EncodeObject[] = [msg];

  return msgs;
};

export default createLimitOrderMsg;
