import { formatAmount, formatUSD, getTvlByPool } from 'common/utils';
import RibbonBadge, { RibbonBadgeColor } from 'components/badges/RibbonBadge';
import Button from 'components/buttons/Button';
import PairTicker from 'components/pools/PairTicker';
import PoolId from 'components/pools/PoolId';
import PoolRewards from 'components/pools/PoolRewards';
import { useMainStore } from 'provider/mainContext';
import type { PoolDetail } from 'types/pool';
import RewardsEmissionPerDay from './tooltip/RewardsEmissionPerDay';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import CoinPair from 'components/coins/CoinPair';
import usePoolApr from './hooks/usePoolApr';
import BigNumber from 'bignumber.js';
import NumberText from 'components/texts/NumberText';

const PoolHighlightTitle = ({ pool }: { pool: PoolDetail }) => {
  const store = useMainStore();

  return (
    <div className="space-y-3">
      <CoinPair assets={pool.assets} size={store.isMobile ? '36px' : '48px'} />
      <div className="flex items-center gap-x-2">
        <PairTicker pair={pool} size="xl" />
        <PoolId poolId={pool.poolId} hashTag={true} size="md" />
      </div>
    </div>
  );
};

const PoolHighlightLabeledData = ({ label, value }: { label: string; value: JSX.Element }) => {
  return (
    <div className="min-h-[1.25rem] md:min-h-[1.5rem] flex justify-between items-center gap-x-4">
      <div className="font_caption_s md:font_caption_l text-on_surface_variant_dark">{label}</div>
      <div>{value}</div>
    </div>
  );
};

/** @component PoolHighlightCard */
const RIBBON_BADGE_SHADOW_DICT: { [key in RibbonBadgeColor]: string } = {
  green:
    'radial-gradient(100% 100% at 100% 0%, rgba(117, 247, 130, 0.4) 0%, rgba(117, 247, 130, 0.1) 26.05%, rgba(117, 247, 130, 0) 51.73%), rgba(0, 0, 0, 0.1)',
  orange:
    'radial-gradient(100% 100% at 100% 0%, rgba(255, 188, 101, 0.4) 0%, rgba(255, 188, 101, 0.1) 26.05%, rgba(255, 188, 101, 0) 51.73%), rgba(0, 0, 0, 0.1)',
  red: 'radial-gradient(100% 100% at 100% 0%, rgba(251, 133, 126, 0.4) 0%, rgba(251, 133, 126, 0.1) 26.05%, rgba(251, 133, 126, 0) 51.73%), rgba(0, 0, 0, 0.1)',
};

type PoolHighlightCardProps = {
  pool: PoolDetail;
  onClick?: () => void;
  badgeLabel?: string;
  badgeColor?: RibbonBadgeColor;
  buttonLabel?: string;
  className?: string;
};

const PoolHighlightCard = ({
  pool,
  onClick,
  badgeLabel,
  badgeColor,
  buttonLabel,
  className = '',
}: PoolHighlightCardProps) => {
  const { underlyingBCreApr } = usePoolApr(pool);

  const store = useMainStore();

  return (
    <div className={`${className} relative`}>
      {badgeLabel !== undefined && (
        <RibbonBadge label={badgeLabel} color={badgeColor} className="!absolute -top-1 right-1 md:-top-2 md:right-2" />
      )}

      <div
        className={`overflow-hidden px-4 py-5 md:px-5 md:py-6 bg-surface bg-no-repeat bg-cover bg-center border border-outline_soft rounded-8px`}
        style={{ background: badgeLabel && badgeColor ? RIBBON_BADGE_SHADOW_DICT[badgeColor] : undefined }}
      >
        <div className="space-y-3 md:space-y-7">
          <div className="space-y-4 md:space-y-6">
            {/** @todo final style is tbd */}
            <div className="relative flex items-end">
              <PoolHighlightTitle pool={pool} />
            </div>

            <div className="w-full space-y-2 md:space-y-5">
              <PoolHighlightLabeledData
                label="APR"
                value={
                  <div className="flex items-center gap-x-1">
                    <div className="font_data_number_m md:font_headline_number_m text-on_surface">
                      {formatAmount(new BigNumber(pool.apr), 1, { fixMantissa: true })}%
                    </div>
                    {underlyingBCreApr.gt(0) && (
                      <div className="font_data_number_m md:font_headline_number_xs text-on_surface_variant_dark">
                        +
                      </div>
                    )}
                    {underlyingBCreApr.gt(0) && (
                      <div className="font_data_number_m md:font_headline_number_xs text-primary">
                        {formatAmount(underlyingBCreApr, 1, { fixMantissa: true })}%
                      </div>
                    )}
                  </div>
                }
              />

              <PoolHighlightLabeledData
                label="Rewards"
                value={
                  <div className="flex items-center gap-x-1">
                    <PoolRewards pool={pool} size={store.isMobile ? '20px' : '24px'} />

                    <div className="text-on_surface_variant_dark">
                      <TooltipIcon tooltipSize="300px" tooltipContent={<RewardsEmissionPerDay pool={pool} />} />
                    </div>
                  </div>
                }
              />

              <PoolHighlightLabeledData
                label="TVL"
                value={
                  <NumberText
                    size={store.isMobile ? 'md' : 'lg'}
                    value={formatUSD(getTvlByPool(pool), { compact: true })}
                  />
                }
              />
            </div>
          </div>

          {onClick !== undefined && (
            <Button
              metalic={true}
              size={store.isMobile ? 'md' : 'lg'}
              label={buttonLabel ?? 'Go Earn'}
              onClick={onClick}
              className="w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PoolHighlightCard;
