import BigNumber from 'bignumber.js';
import { INITIAL_ASSET, MODAL_TITLES } from 'COMMON_VARIABLES';
import IconButton from 'components/buttons/IconButton';
import { TokenTypes } from 'components/constants/token';
import AmountInput from 'components/inputs/AmountInput';
import TokenSelectModal from 'components/modals/TokenSelectModal';
import { usePoolFinder } from 'hooks/usePoolFinder';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo, useState } from 'react';
import { InfoAsset, AssetDetail } from 'types/asset';

type SwapInputPairProps = {
  payAsset?: InfoAsset;
  onSelectPayAsset: (asset: InfoAsset) => void;
  payAmount?: string;
  onPayAmountChange?: (value: string) => void;
  receiveAsset?: InfoAsset;
  onSelectReceiveAsset: (asset: InfoAsset) => void;
  receiveAmount?: string;
  onReceiveAmountChange?: (value: string) => void;
  onSwitch?: () => void;
  disabled?: boolean;
};

const SwapInputPair = ({
  payAsset,
  onSelectPayAsset,
  payAmount,
  onPayAmountChange,
  receiveAsset,
  onSelectReceiveAsset,
  receiveAmount,
  onReceiveAmountChange,
  onSwitch,
  disabled,
}: SwapInputPairProps) => {
  /** @desc pool, LF excluded as default */
  const store = useMainStore();

  /** @desc payAmount usd */
  const payAmountUSD = useMemo<BigNumber>(() => {
    if (!payAmount) return new BigNumber(0);

    const asset = store.assetDetailDict[payAsset?.denom ?? ''];
    if (asset === undefined) return new BigNumber(0);

    return new BigNumber(payAmount).multipliedBy(asset.priceOracle);
  }, [payAmount, payAsset, store.assetDetailDict]);

  /** @desc token select modal */
  const [tokenSelectOrigin, setTokenSelectOrigin] = useState<'pay' | 'receive' | undefined>(undefined);

  const { pairData } = usePoolFinder(payAsset, receiveAsset);

  const tokenSelectModalOptions = useMemo<{ availabe: AssetDetail[]; unavailble: AssetDetail[] }>(() => {
    const availabe: AssetDetail[] = [];
    const unavailble: AssetDetail[] = [];

    if (pairData) {
      Object.values(store.assetDetailDict).forEach((asset) => {
        if (asset.tokenType === TokenTypes.POOL || asset.tokenType === TokenTypes.LF) return;
        if (tokenSelectOrigin === 'pay' && asset.denom === receiveAsset?.denom) return;
        if (tokenSelectOrigin === 'receive' && asset.denom === payAsset?.denom) return;

        const isCounterTokenSelected =
          (tokenSelectOrigin === 'pay' && receiveAsset && receiveAsset.denom !== '') ||
          (tokenSelectOrigin === 'receive' && payAsset && payAsset.denom !== '');

        const isAvailable = pairData[tokenSelectOrigin === 'pay' ? 'receive' : 'pay'].available.includes(asset.denom);

        !isCounterTokenSelected || isAvailable ? availabe.push(asset) : unavailble.push(asset);
      });
    }

    return { availabe, unavailble };
  }, [store.assetDetailDict, payAsset, receiveAsset, pairData, tokenSelectOrigin]);

  const onSelect = useCallback(
    (asset: InfoAsset) => {
      tokenSelectOrigin === 'pay' ? onSelectPayAsset(asset) : onSelectReceiveAsset(asset);
      setTokenSelectOrigin(undefined);
    },
    [onSelectPayAsset, onSelectReceiveAsset, tokenSelectOrigin]
  );

  return (
    <div>
      <div className="w-full space-y-2">
        <div className="relative">
          <AmountInput
            value={payAmount}
            asset={store.assetDetailDict[payAsset?.denom ?? '']}
            onChangeValue={onPayAmountChange}
            onClickTokenSelect={() => setTokenSelectOrigin('pay')}
            disabled={disabled}
            maxAmount="balance"
            simpleInputData={{
              signType: 'swap',
              txChainId: INITIAL_ASSET.chainId,
            }}
          />

          {/* switch button */}
          <div className="absolute left-0 right-0 -bottom-[1rem] w-full flex justify-center">
            <IconButton
              type="tonal"
              size="sm"
              iconType="arrow-downward"
              className="elevation_dark_2"
              onClick={onSwitch}
              disabled={disabled}
            />
          </div>
        </div>

        <AmountInput
          value={receiveAmount}
          asset={store.assetDetailDict[receiveAsset?.denom ?? '']}
          comparedUSDValue={payAmountUSD}
          onChangeValue={onReceiveAmountChange}
          onClickTokenSelect={() => setTokenSelectOrigin('receive')}
          disabled={disabled}
          maxAmount="balance"
        />
      </div>

      {/* modal - token select */}
      <TokenSelectModal
        isOpen={tokenSelectOrigin !== undefined}
        title={MODAL_TITLES.TOKEN_SELECT}
        availableTitle="Available pairs"
        unavailableTitle="Unavailable pairs"
        availableAssets={tokenSelectModalOptions.availabe}
        unavailableAssets={tokenSelectModalOptions.unavailble}
        selectedDenom={tokenSelectOrigin === 'pay' ? payAsset?.denom : receiveAsset?.denom}
        onSelect={onSelect}
        onClose={() => setTokenSelectOrigin(undefined)}
      />
    </div>
  );
};

export default SwapInputPair;
