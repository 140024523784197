import { ReactNode } from 'react';

type PanelStyle = 'outline-hard' | 'outline-medium' | 'outline-soft';
type PanelPadding = '40px' | '24px' | '16px' | '12px';

const PANEL_COLOR_DICT: { [key in PanelStyle]: string } = {
  'outline-hard': 'border border-outline_hard',
  'outline-medium': 'border border-outline_medium',
  'outline-soft': 'border border-outline_soft',
};

const PANEL_PADDING_DICT: { [key in PanelPadding]: string } = {
  '40px': 'p-10',
  '24px': 'p-6',
  '16px': 'p-4',
  '12px': 'p-3',
};

type PanelProps = {
  type?: PanelStyle;
  padding?: PanelPadding;
  children: ReactNode;
  className?: string;
};

const Panel = ({ children, type = 'outline-hard', padding = '24px', className }: PanelProps) => {
  return (
    <div
      className={`w-full rounded-8px overflow-hidden bg_surface ${PANEL_COLOR_DICT[type]} ${PANEL_PADDING_DICT[padding]} ${className}`}
    >
      {children}
    </div>
  );
};

export default Panel;
