import Button, { ButtonSize, ButtonStatus } from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';

type ModalFooterProps = {
  buttonLabel: string;
  buttonStatus?: ButtonStatus;
  buttonSize?: ButtonSize;
  buttonMetalic?: boolean;
  buttonClassName?: string;
  isResetDisabled?: boolean;
  onResetClick?: () => void;
  onOkClick?: () => void;
};

const ModalFooter = ({
  buttonLabel,
  buttonStatus,
  buttonSize = 'md',
  buttonMetalic,
  buttonClassName = 'min-w-[9.25rem]',
  isResetDisabled = false,
  onResetClick,
  onOkClick,
}: ModalFooterProps) => {
  // const justifyClassName = useMemo<string>(() => {
  //   if (onResetClick && onOkClick) return 'justify-between';
  //   if (onResetClick) return 'justify-start';
  //   return 'justify-end';
  // }, [onResetClick, onOkClick]);

  return (
    <footer
      className={`absolute z-10 left-0 right-0 bottom-0 min-h-[2rem] flex justify-end items-center gap-x-4 px-5 pt-5 pb-6`}
    >
      {onResetClick !== undefined && (
        <IconButton type="tonal" iconType="reset" size="lg" disabled={isResetDisabled} onClick={onResetClick} />
      )}

      {onOkClick !== undefined && (
        <Button
          type="filled"
          label={buttonLabel}
          size={buttonSize}
          status={buttonStatus}
          metalic={buttonMetalic}
          onClick={onOkClick}
          className={buttonClassName}
        />
      )}
    </footer>
  );
};

export default ModalFooter;
