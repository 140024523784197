import BigNumber from 'bignumber.js';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo } from 'react';
import { AssetDetail } from 'types/asset';
import { PoolDetail, RewardPlan, RewardPlanDetail } from 'types/pool';

export type RewardPlanType = 'pair' | 'pool';

const REWARDS_DESC_DICT: { [key in RewardPlanType]: string } = {
  pair: 'Pair rewards (Liquidity weighted)',
  pool: 'Pool rewards',
};

const useFarmRewards = (pool: PoolDetail, options?: { perUSD?: number }) => {
  const store = useMainStore();

  const share = useMemo<BigNumber>(() => {
    if (!options?.perUSD) return new BigNumber(1);

    const totalFarmedTvl = pool.totalStakedAmount.multipliedBy(pool.priceOracle);
    return new BigNumber(options.perUSD).div(totalFarmedTvl);
  }, [options?.perUSD, pool]);

  const getRewardPlanDetail = useCallback(
    (plan: RewardPlan): RewardPlanDetail => {
      const asset: AssetDetail | undefined = store.assetDetailDict[plan.rewardDenom];

      const dailyEmissionAmount = new BigNumber(plan.rewardAmount)
        .shiftedBy(-12)
        .multipliedBy(pool.totalStakedAmount)
        .dp(12, BigNumber.ROUND_DOWN);
      const dailyEmissionAmountUSD = dailyEmissionAmount
        .multipliedBy(asset?.priceOracle ?? 0)
        .dp(2, BigNumber.ROUND_DOWN);

      const dailyReceiveAmount = dailyEmissionAmount.multipliedBy(share).dp(asset?.exponent ?? 6, BigNumber.ROUND_DOWN);
      const dailyReceiveAmountUSD = dailyReceiveAmount
        .multipliedBy(asset?.priceOracle ?? 0)
        .dp(2, BigNumber.ROUND_DOWN);

      return {
        plan,
        asset,
        dailyEmissionAmount,
        dailyEmissionAmountUSD,
        dailyReceiveAmount,
        dailyReceiveAmountUSD,
      };
    },
    [store.assetDetailDict, pool.totalStakedAmount, share]
  );

  const plansDict = useMemo<{
    [key in RewardPlanType]:
      | {
          title: string;
          plans: RewardPlanDetail[];
        }
      | undefined;
  }>(() => {
    const pairPlans: RewardPlanDetail[] = [];
    const poolPlans: RewardPlanDetail[] = [];

    pool.RewardsPerToken.forEach((item) => {
      const detail = getRewardPlanDetail(item);
      item.isPairPlan ? pairPlans.push(detail) : poolPlans.push(detail);
    });

    return {
      pair: pairPlans.length > 0 ? { title: REWARDS_DESC_DICT.pair, plans: pairPlans } : undefined,
      pool: poolPlans.length > 0 ? { title: REWARDS_DESC_DICT.pool, plans: poolPlans } : undefined,
    };
  }, [pool.RewardsPerToken, getRewardPlanDetail]);

  const dailyTotalEmissionUSD = useMemo(() => {
    return Object.values(plansDict).reduce((acc, item) => {
      const dailyTotal = item?.plans.reduce((acc, item) => acc.plus(item.dailyEmissionAmountUSD), new BigNumber(0));
      return acc.plus(dailyTotal ?? 0);
    }, new BigNumber(0));
  }, [plansDict]);

  const dailyTotalUSD = useMemo<BigNumber>(() => {
    if (!options?.perUSD) return dailyTotalEmissionUSD;

    return new BigNumber(dailyTotalEmissionUSD).multipliedBy(share);
  }, [dailyTotalEmissionUSD, options?.perUSD, share]);

  return { plansDict, dailyTotalUSD };
};

export default useFarmRewards;
