import { toShortAddress } from 'common/utils';
import Tooltip from 'components/tooltips';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { Wallet } from 'types/wallet';
import DropDown, { DropDownItem } from 'components/dropdown/DropDown';
import NA from 'components/texts/NA';
import type { LiveChain } from 'types/chain';
import { DropDownLayer } from 'components/dropdown/types';

/** @desc ChainDropDownItemLabel, ChainDropDownSelectedLabel */
const ChainDropDownItemLabel = ({ chainId }: { chainId: string }) => {
  const store = useMainStore();
  const chainLive: LiveChain | undefined = store.chainsData.live[chainId];

  return (
    <div className="font_title_xs text-on_surface truncate">
      <div className={!chainLive ? 'text-semantic_yellow' : ''}>
        {store.chainsData.info[chainId]?.displayName.toUpperCase() ?? 'Unsupported Chain'}
      </div>
    </div>
  );
};

const ChainDropDownSelectedLabel = ({
  chainId,
  wallet,
  showAddress,
}: {
  chainId: string;
  wallet: Wallet | null;
  showAddress?: boolean;
}) => {
  const store = useMainStore();
  const chainLive: LiveChain | undefined = store.chainsData.live[chainId];

  return (
    <div className="flex flex-col justify-center gap-y-2">
      <Tooltip content={chainLive ? undefined : 'Chain is not live'}>
        <div className="min-h-[1.25rem] font_title_m text-on_surface">
          <div className={`truncate ${!chainLive ? 'text-semantic_yellow' : ''}`}>
            {store.chainsData.info[chainId]?.displayName.toUpperCase() ?? 'Unsupported Chain'}
          </div>
        </div>
      </Tooltip>

      {showAddress && wallet && (
        <Tooltip size="220px" wordBreak="break-all" content={wallet.address}>
          <div className="font_caption_xs text-on_surface_variant_dark">
            {store.isMobile ? toShortAddress(wallet.address, 8, 6) : toShortAddress(wallet.address, 8)}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const getChainDropDownItem = (chainId: string, wallet: Wallet | null, showAddress?: boolean) => {
  return {
    value: chainId,
    label: <ChainDropDownItemLabel chainId={chainId} />,
    selectedLabel: <ChainDropDownSelectedLabel chainId={chainId} wallet={wallet} showAddress={showAddress} />,
  };
};

/** @desc ChainDropDown */
type ChainDropDownProps = {
  layer: DropDownLayer;
  chainIds?: string[];
  selectedChainId: string | undefined;
  onSelectChainId?: (chainId: string) => void;
  selectedChainWallet: Wallet | null;
  showAddress?: boolean;
  disabled?: boolean;
  className?: string;
};

const ChainDropDown = ({
  layer,
  chainIds,
  selectedChainId,
  onSelectChainId,
  selectedChainWallet,
  showAddress = true,
  disabled = false,
  className = 'w-[47.5%]',
}: ChainDropDownProps) => {
  const selected = useMemo<DropDownItem<string, JSX.Element> | undefined>(
    () => (selectedChainId ? getChainDropDownItem(selectedChainId, selectedChainWallet, showAddress) : undefined),
    [selectedChainId, selectedChainWallet, showAddress]
  );

  const items = useMemo<DropDownItem<string, JSX.Element>[]>(() => {
    return chainIds?.map((chainId) => getChainDropDownItem(chainId, selectedChainWallet, showAddress)) ?? [];
  }, [chainIds, selectedChainWallet, showAddress]);

  return (
    <DropDown<string, JSX.Element>
      layer={layer}
      items={items}
      selected={selected}
      onSelect={(item) => onSelectChainId?.(item.value)}
      disabled={disabled}
      className={className}
      unselectedLabel={items.length > 0 && !disabled ? 'Select a chain' : <NA />}
      minHeight="4.625rem"
    />
  );
};

export default ChainDropDown;
