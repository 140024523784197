import Icon, { IconType } from 'components/icon';
import FootPrintSvg from 'components/svgs/FootPrintSvg';
import { FeedbackType } from './types';

const TOAST_COLOR_DICT: { [key in FeedbackType]: string } = {
  info: 'text-info',
  success: 'text-greenSystem',
  warning: 'text-warning',
  error: 'text-redSystem',
  waiting: 'text-whiteo35',
  congrats: 'text-secondary',
};

const TOAST_FLAG_BG_DICT: { [key in FeedbackType]: string } = {
  info: 'linear-gradient(174.71deg, rgba(96, 140, 255, 0.36) 0%, rgba(96, 140, 255, 0.04) 100%)',
  success: 'linear-gradient(174.71deg, rgba(68, 168, 78, 0.36) 0%, rgba(68, 168, 78, 0.04) 100%)',
  warning: 'linear-gradient(174.71deg, rgba(250, 196, 0, 0.36) 0%, rgba(250, 196, 0, 0.04) 100%)',
  error: 'linear-gradient(174.71deg, rgba(234, 91, 82, 0.36) 0%, rgba(234, 91, 82, 0.04) 100%)',
  waiting: 'linear-gradient(180deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.04) 100%)',
  congrats: 'linear-gradient(180deg, rgba(255, 199, 128, 0.36) 0%, rgba(255, 199, 128, 0) 100%)',
};

const TOAST_ICON_DICT: { [key in FeedbackType]: IconType } = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
  waiting: 'loader',
  congrats: 'congrats', // tmp
};

const ToastFlag = ({ type }: { type: FeedbackType }) => {
  return (
    <div
      className="p-4 w-fit flex flex-col justify-between items-center"
      style={{
        background: TOAST_FLAG_BG_DICT[type],
      }}
    >
      <Icon type={TOAST_ICON_DICT[type]} size="20px" className={`${TOAST_COLOR_DICT[type]}`} />

      <FootPrintSvg className={TOAST_COLOR_DICT[type]} />
    </div>
  );
};

export default ToastFlag;
