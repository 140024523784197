import type WalletConnect from '@walletconnect/client';

// INFO Types
export enum WalletType {
  COSMOSTATION = 'cosmostation',
  KEPLR = 'keplr',
  LEAP = 'leap',
  WALLETCONNECT = 'walletconnect:cosmostation',
}

type WalletCommonProps = {
  isActive: boolean;
  name: string;
  address: string;
};

export type NormalWallet = WalletCommonProps & {
  type: WalletType.COSMOSTATION | WalletType.KEPLR | WalletType.LEAP | null;
};

export type WalletConnectWallet = WalletCommonProps & {
  type: WalletType.WALLETCONNECT;
  connector: WalletConnect;
  pubkey: any;
};

export type Wallet = NormalWallet | WalletConnectWallet;
