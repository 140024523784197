import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import './index.scss';
import './index-production.scss';
import reportWebVitals from './reportWebVitals';
import { MainProvider } from './provider/mainContext';
import Modal from 'react-modal';
import BigNumber from 'bignumber.js';

Modal.setAppElement('#root');

// global format
// https://mikemcl.github.io/bignumber.js/#toFor
const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};
BigNumber.config({ EXPONENTIAL_AT: 1e9, FORMAT: fmt });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MainProvider>
        <App />
      </MainProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
