type BadgeColor = 'red' | 'green';

const BADGE_COLOR_DICT: Record<BadgeColor, { text: string; bg: string }> = {
  red: {
    bg: 'bg-semantic_red',
    text: 'text-semantic_red_variant_light',
  },
  green: {
    bg: 'bg-semantic_green',
    text: 'text-semantic_green_variant_light',
  },
};

type BadgeProps = {
  color: BadgeColor;
  labelType?: 'text' | 'number';
  label: string;
};

const Badge = ({ color, labelType = 'text', label }: BadgeProps) => {
  return (
    <div
      className={`px-1 py-[1px] rounded-100px text-on_surface ${BADGE_COLOR_DICT[color].bg} ${BADGE_COLOR_DICT[color].text}`}
    >
      <div className={labelType === 'text' ? 'font_label_xs' : 'font_label_number_xs'}>{label}</div>
    </div>
  );
};

export default Badge;
