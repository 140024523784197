import { getAddiGasByTx, getGasMultiplier } from 'common/utils';
import { GAS_MIN_AMOUNT_DICT } from 'COMMON_VARIABLES';
import { useEffect, useState, useMemo } from 'react';
import { SignParams } from 'types/signingClients';

const useGas = (type: SignParams['type'] | undefined, msgCount = 1) => {
  /** @desc add additional gas async */
  const [addiGas, setAddiGas] = useState<number>(0);

  useEffect(() => {
    if (type) getAddiGasByTx(type, msgCount, setAddiGas);
  }, [type, msgCount]);

  /** @desc to sync gas amount with the above, when using gas for max function or others */
  const gas = useMemo((): number => {
    return type ? (GAS_MIN_AMOUNT_DICT[type] + addiGas) * getGasMultiplier(type) : 0;
  }, [addiGas, type]);

  return { gas };
};

export default useGas;
