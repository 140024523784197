import { observer } from 'mobx-react-lite';
import { useRef, useEffect, useCallback, useMemo } from 'react';
// @ts-ignore
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  IChartingLibraryWidget,
  ResolutionString,
  Timezone,
} from './../charting_library';
import { UDFCompatibleDatafeed } from './../datafeeds/udf/udf-compatible-datafeed';
import { isMobile } from '@walletconnect/browser-utils';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  CHART_OPTIONS,
  CHART_PROPERTIES_JSON,
  DATA_FEED_URL,
  MAIN_SERIES_PROPERTIES_JSON,
  TV_BG_COLOR_HEX,
  TV_LOCAL_STORAGE_KEYS,
  TV_OVERRIDES,
  TV_STUDIES_OVERRIDES,
} from './constants';
import { useMainStore } from 'provider/mainContext';

const MOBILE_DISABLED_FEATURES = [
  'timeframes_toolbar',
  'header_indicators',
  'header_settings',
  'header_chart_type',
  'header_undo_redo',
  'header_screenshot',
  'header_fullscreen_button',
  // 'header_resolutions',
  'header_interval_dialog_button',
  'show_interval_dialog_on_key_press',
  'header_symbol_search',
  'header_saveload',
  'compare_symbol',
  'control_bar',
  'border_around_the_chart',
  'study_dialog_search_control',
  'source_selection_markers',
  'snapshot_trading_drawings',
  'timezone_menu',
  'symbol_info',
  'countdown',
  'show_chart_property_page',
  'property_pages',
  'chart_property_page_style',
  'remove_library_container_border',
  'display_market_status',
  'main_series_scale_menu',
  'left_toolbar',
];

type TradingViewChartProps = {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  datafeedUrl?: string;
  onChangeIsLoading?: (isLoading: boolean) => void;
};

export const TradingViewChart = observer(
  ({
    symbol = 'NONE',
    // BEWARE: no trailing slash is expected in feed URL
    datafeedUrl = DATA_FEED_URL,
    onChangeIsLoading,
  }: TradingViewChartProps) => {
    /**
     * @description
     * the callback that updates tradingview localStorage data;
     * this is the most working-guaranteed way ever.
     */
    const updateTVStylesLocalData = useCallback(() => {
      const mKey = TV_LOCAL_STORAGE_KEYS.MAIN_SERIES_PROPERTIES;
      const newMainSeriesProperties = JSON.stringify(MAIN_SERIES_PROPERTIES_JSON);
      localStorage.setItem(mKey, newMainSeriesProperties);

      const key = TV_LOCAL_STORAGE_KEYS.CHART_PROPERTIES;
      const oldChartproperties = localStorage.getItem(key);
      const newChartProperties = {
        ...JSON.parse(oldChartproperties ?? '{}'),
        ...CHART_PROPERTIES_JSON,
      };
      localStorage.setItem(key, JSON.stringify(newChartProperties));
    }, []);

    /**
     * @description
     * mark new version for every new styles update
     */
    const { setLocal } = useLocalStorage({
      key: 'tradingview-v2',
      onFailGet: () => {
        updateTVStylesLocalData();
        setLocal({ value: 'true' });
      },
    });

    /** @description interval */
    const interval = useMemo<ResolutionString>(() => {
      const key = TV_LOCAL_STORAGE_KEYS.INTERVAL;
      const oldInterval = localStorage.getItem(key) as ResolutionString | undefined;
      return oldInterval ?? ('1D' as ResolutionString);
    }, [symbol]);

    const updateIntervalLocalData = useCallback((interval: ResolutionString) => {
      const key = TV_LOCAL_STORAGE_KEYS.INTERVAL;
      localStorage.setItem(key, interval);
    }, []);

    const ref = useRef(null);
    const widgetRef = useRef<IChartingLibraryWidget | null>(null);

    useEffect(() => {
      try {
        if (widgetRef.current) {
          widgetRef.current.setSymbol(symbol, interval, () => {});
        }
      } catch (e) {}
    }, [symbol, interval]);

    const store = useMainStore();

    useEffect(() => {
      if (ref.current) {
        const widgetOptions: ChartingLibraryWidgetOptions = {
          ...CHART_OPTIONS,
          symbol: symbol as string,
          datafeed: new UDFCompatibleDatafeed(datafeedUrl, 2000),
          interval,
          container: ref?.current,
          locale: getLanguageFromURL() || 'en',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
          enabled_features: ['hide_left_toolbar_by_default'],
          loading_screen: { backgroundColor: TV_BG_COLOR_HEX, foregroundColor: TV_BG_COLOR_HEX },
          disabled_features: [
            'use_localstorage_for_settings',
            'volume_force_overlay',
            'header_compare',
            'header_interval_dialog_button',
            'show_interval_dialog_on_key_press',
            'header_symbol_search',
            'header_saveload',
            ...(store.isMobile ? MOBILE_DISABLED_FEATURES : []),
          ],
          favorites: {
            intervals: [
              '1' as ResolutionString,
              '5' as ResolutionString,
              '30' as ResolutionString,
              '1H' as ResolutionString,
              '4H' as ResolutionString,
              '1D' as ResolutionString,
              '1W' as ResolutionString,
            ],
            chartTypes: ['Candles', 'Line'],
          },
        };

        if (isMobile()) {
          widgetOptions.disabled_features?.push('legend_widget');
        }

        const tvWidget = new widget(widgetOptions);

        /** @description this line affects the actual rendering */
        tvWidget.applyOverrides({ ...TV_OVERRIDES, ...TV_STUDIES_OVERRIDES });

        widgetRef.current = tvWidget;

        tvWidget.onChartReady(() => {
          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(
              null,
              (interval) => {
                updateIntervalLocalData(interval);
                onChangeIsLoading?.(false);
              },
              false
            );
          tvWidget
            .activeChart()
            .onSymbolChanged()
            .subscribe(
              null,
              () => {
                onChangeIsLoading?.(false);
                updateTVStylesLocalData();
              },
              false
            );
          tvWidget
            .activeChart()
            .onDataLoaded()
            .subscribe(null, () => onChangeIsLoading?.(false), false);
        });
      }

      return () => {
        if (widgetRef.current) {
          widgetRef.current.remove();
          widgetRef.current = null;
        }
      };
    }, []);

    return <div ref={ref} className={'TradingViewChart h-full w-full'} />;
  }
);

function getLanguageFromURL(): LanguageCode | null {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
}
