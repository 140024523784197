import BigNumber from 'bignumber.js';
import { LiquidUnfarmTxData } from 'common/msg/LiquidUnfarm';
import { LiquidUnfarmAndWithdrawTxData } from 'common/msg/LiquidUnfarmAndWithdraw';
import { UnfarmTxData } from 'common/msg/Unfarm';
import { getWithdrawTokensAmount } from 'common/utils';
import { IS_LF_ENABLED } from 'COMMON_VARIABLES';
import { TokenTypes } from 'components/constants/token';
import useLiquidFarm from 'hooks/farm/useLiquidFarm';
import useAmountUSD from 'hooks/useAmountUSD';
import { useLpFarmStaking } from 'hooks/useAPI';
// import { useFarmStaking } from 'hooks/useAPI';
import useTxClient from 'hooks/useTxClient';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { InfoAssetWithMyAmount } from 'types/asset';
import type { PoolDetail } from 'types/pool';

const useUnfarm = ({
  pool,
  rate,
  isLfByDefault,
  onTxSuccess,
}: {
  pool: PoolDetail;
  rate: number;
  isLfByDefault: boolean;
  onTxSuccess?: () => void;
}) => {
  const mainStore = useMainStore();

  const [isLoading, setLoading] = useState(false);

  const { signAndBroadcast } = useTxClient();

  /** @summary Normal unfarm, mostly just moved from ManageModal/Unfarm.tsx */
  // const { data: farmStakingResponse } = useFarmStaking(mainStore.wallet.address, 5000);
  // const myTokenBalance = useMemo(() => {
  //   if (farmStakingResponse?.data?.length > 0) {
  //     let farmingPool = farmStakingResponse?.data?.filter((item) => item.denom === pool.poolDenom)[0];
  //     if (farmingPool) {
  //       return new BigNumber(farmingPool.queuedAmount).plus(farmingPool.stakedAmount);
  //     }
  //   }
  //   return new BigNumber(0);
  // }, [farmStakingResponse, pool]);
  const { data: farmStakingResponse } = useLpFarmStaking(mainStore.wallet.address, 5000);

  const myTokenBalance = useMemo(() => {
    let farmingPool = farmStakingResponse?.data?.filter((item) => item.denom === pool.poolDenom)[0];
    return new BigNumber(farmingPool?.stakedAmount ?? 0);
  }, [farmStakingResponse, pool]);

  const priceUsd = useMemo(() => {
    if (mainStore.poolsData) {
      return mainStore.poolsData.live[pool.poolDenom].priceOracle;
    }
    return new BigNumber(0);
  }, [mainStore.poolsData, pool]);

  const amount = useMemo(() => myTokenBalance.multipliedBy(rate / 100), [rate, myTokenBalance]);
  const amountUSD = useMemo<BigNumber>(() => amount.multipliedBy(priceUsd), [amount, priceUsd]);

  // const unfarm = useCallback(async () => {
  //   setLoading(true);
  //   let error = await signAndBroadcast({
  //     type: 'unfarm',
  //     txData: {
  //       unfarmingCoin: [{ denom: pool.poolDenom, amount: amount.decimalPlaces(0).toString() }],
  //     },
  //     chainData: mainStore.chainsData,
  //   });

  //   if (!error && onTxSuccess) onTxSuccess();
  //   setLoading(false);
  // }, [signAndBroadcast, amount, mainStore, pool.poolDenom, onTxSuccess]);

  const unfarm = useCallback(async () => {
    setLoading(true);

    const txData: UnfarmTxData = {
      coin: { denom: pool.poolDenom, amount: amount.decimalPlaces(0).toString() },
    };

    const error = await signAndBroadcast({
      type: 'lpUnfarm',
      txData,
      chainData: mainStore.chainsData,
    });

    setLoading(false);

    if (!error && onTxSuccess) onTxSuccess();
  }, [signAndBroadcast, amount, mainStore, pool.poolDenom, onTxSuccess]);

  /**
   * @summary LF unfarm
   * @todo how to identify LF token, how the lfBurnRate, lfToken exponent will be given is TBD
   */
  const { getLiquidFarmByPoolId } = useLiquidFarm();
  const lfData = getLiquidFarmByPoolId(pool.poolId);

  const myAssets = mainStore.getMyAssets();
  const lfToken = useMemo<InfoAssetWithMyAmount | undefined>(
    () => myAssets.find((asset) => asset.tokenType === TokenTypes.LF && asset.denom === lfData?.lfDenom),
    [myAssets, lfData?.lfDenom]
  );

  const [isLf, setIsLf] = useState<boolean>(IS_LF_ENABLED && pool.lfEnabled && isLfByDefault && lfToken !== undefined);

  useEffect(() => {
    if (IS_LF_ENABLED) setIsLf(pool.lfEnabled);
  }, [pool.lfEnabled]);

  const lfTokenBalance = useMemo<BigNumber>(() => lfToken?.balance ?? new BigNumber(0), [lfToken]);

  const { getPriceOracle } = useAmountUSD(mainStore);
  const lfPriceUsd = useMemo<BigNumber>(
    () => (lfToken ? getPriceOracle(lfToken) : new BigNumber(0)),
    [getPriceOracle, lfToken]
  );

  const lfAmount = useMemo(() => lfTokenBalance.multipliedBy(rate).div(100), [rate, lfTokenBalance]);
  const lfAmountUSD = useMemo<BigNumber>(
    () => lfAmount.multipliedBy(lfPriceUsd).div(10 ** (lfToken?.exponent ?? 12)),
    [lfAmount, lfPriceUsd, lfToken]
  );

  const receiveAmountPerLfToken = useMemo<BigNumber>(
    () => lfData?.receiveAmountPerLfToken?.dp(12, BigNumber.ROUND_DOWN) ?? new BigNumber(0),
    [lfData?.receiveAmountPerLfToken]
  );

  const lfReceivePoolTokenAmount = useMemo<BigNumber>(
    () => lfAmount.shiftedBy(-(lfToken?.exponent ?? 12)).multipliedBy(receiveAmountPerLfToken),
    [lfAmount, receiveAmountPerLfToken, lfToken?.exponent]
  );

  const [withdraw, setWithdraw] = useState<boolean>(false);
  const withdrawTokensAmount = useMemo<[BigNumber, BigNumber]>(
    () => getWithdrawTokensAmount(lfReceivePoolTokenAmount.shiftedBy(lfToken?.exponent ?? 12), pool),
    [lfReceivePoolTokenAmount, pool, lfToken?.exponent]
  );

  const liquidUnfarm = useCallback(async () => {
    setLoading(true);

    const txData: LiquidUnfarmTxData = {
      poolId: pool.poolId,
      unfarmingCoin: {
        denom: lfToken?.denom ?? '',
        amount: lfAmount.dp(0).toString(),
      },
    };

    const error = await signAndBroadcast({
      type: 'liquidUnfarm',
      txData,
      chainData: mainStore.chainsData,
    });

    if (!error && onTxSuccess) onTxSuccess();

    setLoading(false);
  }, [mainStore.chainsData, lfAmount, lfToken, pool.poolId, signAndBroadcast, onTxSuccess]);

  const liquidUnfarmAndWithdraw = useCallback(async () => {
    setLoading(true);

    const txData: LiquidUnfarmAndWithdrawTxData = {
      poolId: pool.poolId,
      unfarmingCoin: {
        denom: lfToken?.denom ?? '',
        amount: lfAmount.dp(0).toString(),
      },
    };

    const error = await signAndBroadcast({
      type: 'liquidUnfarmAndWithdraw',
      txData,
      chainData: mainStore.chainsData,
    });

    if (!error && onTxSuccess) onTxSuccess();

    setLoading(false);
  }, [mainStore.chainsData, lfAmount, lfToken, pool.poolId, signAndBroadcast, onTxSuccess]);

  const state = useMemo<{
    ticker: string;
    balance: BigNumber;
    exponenet: number;
    amount: BigNumber;
    amountUSD: BigNumber;
    onAction: () => Promise<void>;
  }>(
    () => ({
      ticker: isLf ? 'LF Token' : 'Pool Token',
      balance: isLf ? lfTokenBalance : myTokenBalance,
      exponenet: isLf ? lfToken?.exponent ?? 12 : 12,
      amount: isLf ? lfAmount : amount,
      amountUSD: isLf ? lfAmountUSD : amountUSD,
      onAction: isLf ? (withdraw ? liquidUnfarmAndWithdraw : liquidUnfarm) : unfarm,
    }),
    [
      isLf,
      withdraw,
      lfToken,
      lfTokenBalance,
      myTokenBalance,
      lfAmount,
      amount,
      lfAmountUSD,
      amountUSD,
      liquidUnfarmAndWithdraw,
      liquidUnfarm,
      unfarm,
    ]
  );

  return {
    isLf,
    setIsLf,
    state,
    receiveAmountPerLfToken,
    lfReceivePoolTokenAmount,
    withdraw,
    setWithdraw,
    withdrawTokensAmount,
    isLoading,
  };
};

export default useUnfarm;
