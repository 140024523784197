import useSWR from 'swr';
import axios from 'axios';

// Types
import { AssetInfos, AssetLives } from '../types/asset';
import { TxIngResponse } from 'types/tx';
import { Response as FormatResponse, ResponseOne } from 'types/format';
import { PairInfos, PairLives } from '../types/pair';
import { PoolLiveResponse } from '../types/pool';
import { ChainInfos, ChainLives, ChainParamsResponse } from '../types/chain';
import { Balances } from '../types/balance';
import { ProposalResponse } from 'types/gov';
import { AirdropResponse } from 'types/airdrop';
import { FarmPoolResponse, FarmStakingResponse, LpFarmStakingResponse } from 'types/farm';
import { ROOT_ADDRESS, isUpdate } from 'COMMON_VARIABLES';
import { LiquidFarmLiveResponse } from 'types/liquidFarm';
import { TxEventResponse } from 'types/txEvent';
import { OpenOrder, Orderbook, PastOrder, TypeTradeHistory } from 'types/orderbook';

// Commons
const fetcher = (url: string) =>
  axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((e) => e);

// Asset INFO APIs
/** @todo swr hook must be changed to normal one */
export function useAllAssetInfo(interval = 0) {
  const { data, error }: { data: AssetInfos; error: any } = useSWRByEndpoint('/asset/info', interval);
  return returnGenerator(data, error);
}

export function useSingleAssetInfo(denom: string) {
  const { data, error }: { data: any; error: any } = useSWRByEndpoint(`/asset/info/${denom}`);
  return returnGenerator(data, error);
}

// Asset LIVE APIs
export function useAllAssetLive(interval = 0) {
  const { data, error }: { data: AssetLives; error: any } = useSWRByEndpoint('/asset/live', interval);
  return returnGenerator(data, error);
}

export function useAssetLives(denom: string) {
  const { data, error }: { data: any; error: any } = useSWRByEndpoint(`/asset/live/${denom}`);
  return returnGenerator(data, error);
}

/** @summary Liquid farm Live */
export function useAllLiquidFarmLive(interval = 0) {
  const { data, error }: { data: LiquidFarmLiveResponse; error: any } = useSWRByEndpoint('/liquidfarm/live', interval);
  return returnGenerator(data, error);
}

// Pair INFO APIs
export function useAllPairInfo() {
  const { data, error }: { data: PairInfos; error: any } = useSWRByEndpoint('/pair/info');
  return returnGenerator(data, error);
}

export function useSinglePairInfo(pairTokenDenom: string) {
  const { data, error } = useSWRByEndpoint(`/pair/info/${pairTokenDenom}`);
  return returnGenerator(data, error);
}

// Pool LIVE APIs
export function useAllPairLive(interval = 0) {
  const { data, error }: { data: PairLives; error: any } = useSWRByEndpoint('/pair/live', interval);
  return returnGenerator(data, error);
}

export function useSinglePairLive(pairTokenDenom: string) {
  const { data, error }: { data: any; error: any } = useSWRByEndpoint(`/pair/live/${pairTokenDenom}`);
  return returnGenerator(data, error);
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// PoolLive ////////////////////////////////////
export function useAllPoolLive(interval = 0) {
  const { data, error }: { data: PoolLiveResponse; error: any } = useSWRByEndpoint('/pool/live', interval);
  return returnGenerator(data, error);
}

export function useSinglePoolLive(pairTokenDenom: string) {
  const { data, error }: { data: any; error: any } = useSWRByEndpoint(`/pool/live/${pairTokenDenom}`);
  return returnGenerator(data, error);
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// Chain ///////////////////////////////////////
export function useAllChainInfo() {
  const { data, error }: { data: ChainInfos; error: any } = useSWRByEndpoint('/chain/info');
  return returnGenerator(data, error);
}

export function useSingleChainInfo(chainId: string) {
  const { data, error } = useSWRByEndpoint(`/chain/info/${chainId}`);
  return returnGenerator(data, error);
}

export function useAllChainLive(interval = 0) {
  const { data, error }: { data: ChainLives; error: any } = useSWRByEndpoint('/chain/live', interval);
  return returnGenerator(data, error);
}

export function useSingleChainLive(chainId: string) {
  const { data, error }: { data: any; error: any } = useSWRByEndpoint(`/pair/live/${chainId}`);
  return returnGenerator(data, error);
}
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export function useParamsInfo() {
  const { data, error }: { data: ChainParamsResponse; error: any } = useSWRByEndpoint('/params');
  return returnGenerator(data, error);
}

export function useTxIngHistory(address: string, limitTimestamp: number = 0, interval = 0) {
  const { data, error }: { data: TxIngResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/transfer/ing?limitTimestamp=${limitTimestamp}` : '',
    interval
  );
  return returnGenerator(data, error);
}

export function useProposals(address: string | null = 'anonymous', interval = 0) {
  const { data, error }: { data: ProposalResponse; error: any } = useSWRByEndpoint(`/acc/${address}/gov`, interval);
  const spamDeletedData = data?.data.filter((item) => !item?.proposal?.content?.description.includes('v2Terra.de'));
  return returnGenerator({ data: spamDeletedData }, error);
}

export function useProposal(address: string | null = 'anonymous', proposalId, interval = 0) {
  const { data, error }: { data: ProposalResponse; error: any } = useSWRByEndpoint(
    `/acc/${address}/gov/${proposalId}`,
    interval
  );
  return returnGenerator(data, error);
}

// Get staking Info
export function useStakingLive(interval = 0) {
  const { data, error }: { data: any; error: any } = useSWRByEndpoint(`/stake/live`, interval);
  return returnGenerator(data, error);
}

// Account Balance APIs
/** @todo swr hook must be changed to normal one */
export function useCrescentChainBalance(address: string, interval = 0) {
  const { data, error }: { data: Balances; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/balance/all` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}

//////////////////////////// FARM ////////////////////////////
/** @todo under replacement */
export function useFarmStaking(address: string, interval = 0) {
  const { data, error }: { data: FarmStakingResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/farm/staking` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}

/** @summary new; replacing above */
export function useLpFarmStaking(address: string, interval = 0) {
  const { data, error }: { data: LpFarmStakingResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/lpfarm/staking` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}

/** @todo seems not in use; can remove? */
export function useFarmPool(address: string, interval = 0) {
  const { data, error }: { data: FarmPoolResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/farm/pool` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}
//////////////////////////// FARM ////////////////////////////

export function useAirdropInfo(address: string | undefined, interval = 0) {
  const { data, error }: { data: AirdropResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/airdrop/claimed` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}

//////////////////////////// EVENT ///////////////////////////
// Account Balance APIs
export function useNewEvents(address: string, interval = 0) {
  const { data, error }: { data: TxEventResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/event/new` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}
export function useEvents(address: string, interval = 0) {
  const { data, error }: { data: TxEventResponse; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/event/` : '',
    address ? interval : 0
  );
  return returnGenerator(data, error);
}
//////////////////////////// EVENT ///////////////////////////

//////////////////////////// OrderBook ///////////////////////////
export function useOrderbook(pairId: string, precision = 3, interval = 0) {
  const { data, error }: { data: FormatResponse<Orderbook>; error: any } = useSWRByEndpoint(
    pairId ? `/pair/live/orderbook/${pairId}/${precision}` : '',
    interval
  );
  return returnGenerator(data, error);
}

export function useIngTxs(address: string, interval = 0) {
  const { data, error }: { data: ResponseOne<OpenOrder[]>; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/order/ing` : '',
    address ? interval : 0
  );
  return returnGenerator(data?.data, error);
}

// export function usePastOrderDetail(address: string, term = '1D', interval = 0, page = 1, count = 200) {
//   const { data, error }: { data: FormatResponse<any>; error: any } = useSWRByEndpoint(
//     address ? `/acc/${address}/order/detail?past=1&unit=${term}&page=${page}&count=${count}` : '',
//     address ? interval : 0
//   );
//   return returnGenerator(data?.data, error);
// }

// export function useTradeHistory(address: string, term = '1D', interval = 0, page = 1, count = 200) {
//   const { data, error }: { data: FormatResponse<any>; error: any } = useSWRByEndpoint(
//     address ? `/acc/${address}/order/trade?past=1&unit=${term}&page=${page}&count=${count}` : '',
//     address ? interval : 0
//   );
//   return returnGenerator(data?.data, error);
// }

export function usePastOrderDetail(address: string, term = '1D', interval = 0) {
  const { data, error }: { data: ResponseOne<PastOrder[]>; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/order/detail?past=1&unit=${term}` : '',
    address ? interval : 0
  );
  return returnGenerator(data?.data, error);
}

export function useTradeHistory(address: string, term = '1D', interval = 0) {
  const { data, error }: { data: ResponseOne<TypeTradeHistory[]>; error: any } = useSWRByEndpoint(
    address ? `/acc/${address}/order/trade?past=1&unit=${term}` : '',
    address ? interval : 0
  );
  return returnGenerator(data?.data, error);
}

//////////////////////////// OrderBook ///////////////////////////

// https://testnet-apigw.crescent.network/acc/cre13s4qk72a06vlpeyxw2znlfs6gzkhwf9l43jfd3/transfer/ing
//
// Helpers
//
function useSWRByEndpoint(url: string, interval = 0) {
  const { data, error } = useSWR(url && !isUpdate ? `${ROOT_ADDRESS}${url}` : null, fetcher, {
    refreshInterval: interval,
  });
  return { data, error };
}

/** @todo tmp for not working endpoints */
// function useSWRByEndpointTmp(url: string, interval = 0) {
//   const { data, error } = useSWR(url && !isUpdate ? `${'https://apigw-v2.crescent.network'}${url}` : null, fetcher, {
//     refreshInterval: interval,
//   });
//   return { data, error };
// }

function returnGenerator<T>(data: T, error: any) {
  if (error) {
    console.log(error);
  }
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}
