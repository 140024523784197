import TooltipIcon from 'components/tooltips/TooltipIcon';

type LabelSize = 'sm' | 'md';

const LABEL_FONT_DICT: { [key in LabelSize]: string } = {
  sm: 'font_caption_s',
  md: 'font_caption_s md:font_caption_m',
};

type LabeledDataProps = {
  label?: string;
  labelSize?: LabelSize;
  labelTooltipContent?: string | JSX.Element;
  value: string | JSX.Element;
  valueClassName?: string;
};

const LabeledData = ({
  label,
  labelSize = 'md',
  labelTooltipContent,
  value,
  valueClassName = '',
}: LabeledDataProps) => {
  return (
    <div className="w-full flex justify-between items-center gap-x-4">
      {label && (
        <div className="flex items-center gap-x-1 text-on_surface_variant_dark">
          <div className={LABEL_FONT_DICT[labelSize]}>{label}</div>
          {labelTooltipContent !== undefined && (
            <TooltipIcon tooltipSize="300px" tooltipContent={labelTooltipContent} />
          )}
        </div>
      )}

      <div className={valueClassName}>{value}</div>
    </div>
  );
};

export default LabeledData;
