import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import { AssetDetail } from 'types/asset';

type InputMaxAmountCaptionProps = {
  asset: AssetDetail | undefined;
  maxLabel: string;
  maxAmount: BigNumber;
  showTicker?: boolean;
};

const InputMaxAmountCaption = ({ asset, maxLabel, maxAmount, showTicker = false }: InputMaxAmountCaptionProps) => {
  return (
    <div className="flex items-center gap-x-1 text-on_surface_variant_light">
      <div className="font_caption_s">{maxLabel}:</div>
      <div className="flex items-center gap-x-0.5">
        <div className="font_data_number_xs">{asset ? formatAmount(maxAmount, asset.exponent) : '-'}</div>
        {showTicker && <div className="font_caption_s">{asset?.ticker}</div>}
      </div>
    </div>
  );
};

export default InputMaxAmountCaption;
