import { TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import Coin from 'components/coins/Coin';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import type { AssetDetail } from 'types/asset';

type StakingTitleProps = {
  direction: 'stake' | 'unstake';
  targetAsset: AssetDetail | undefined;
};

const StakingTitle = ({ direction, targetAsset }: StakingTitleProps) => {
  return (
    <div className="flex items-center gap-x-0.5 md:gap-x-1">
      <div className="font_headline_xs_mobile md:font_headline_s text-on_surface whitespace-nowrap">
        {direction === 'stake' ? 'Stake' : 'Unstake'} {targetAsset?.ticker}
      </div>

      {targetAsset && (
        <div className="hidden md:block">
          <Coin asset={targetAsset} />
        </div>
      )}
      {targetAsset && (
        <div className="md:hidden">
          <Coin asset={targetAsset} size="20px" />
        </div>
      )}

      {/* {targetAsset && <Coin asset={targetAsset} />} */}

      <div className="text-on_surface_variant_dark">
        <TooltipIcon
          tooltipSize="300px"
          tooltipContent={direction === 'stake' ? TOOLTIP_TEXTS.STAKING : TOOLTIP_TEXTS.UNSTAKING}
        />
      </div>
    </div>
  );
};

export default StakingTitle;
