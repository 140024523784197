export type TooltipHighlightData = { text: string; prefixUnit?: string; type?: 'string' | 'number' };

const TooltipTitle = ({ title, highlight }: { title: string | JSX.Element; highlight?: TooltipHighlightData }) => {
  return (
    <div className={'bg-inverse_surface_o24 text-on_surface px-3 py-2 space-y-2'}>
      <div className="font_caption_s">{title}</div>
      {highlight && (
        <div className="flex items-baseline gap-x-0.5">
          {highlight.prefixUnit && <div className="font_caption_l">{highlight.prefixUnit}</div>}
          <div className={highlight.type === 'string' ? 'font_data_l' : 'font_data_number_l'}>{highlight.text}</div>
        </div>
      )}
    </div>
  );
};

export default TooltipTitle;
