import 'react-toastify/dist/ReactToastify.css';

/** @docs https://fkhadra.github.io/react-toastify/how-to-style/ */
import { toast, ToastContent, ToastOptions } from 'react-toastify';

import { FeedbackType } from 'components/toast/types';
import IconButton from 'components/buttons/IconButton';
import Toast from 'components/toast';

const TOAST_FUNC_DICT: {
  [key in FeedbackType]: (content: ToastContent, options?: ToastOptions<{}> | undefined) => React.ReactText;
} = {
  info: toast.info,
  success: toast.success,
  warning: toast.warning,
  error: toast.error,
  waiting: toast.loading,
  congrats: toast.success,
};

const TOAST_OPTIONS: ToastOptions<{}> = {
  position: 'top-right',
  icon: false,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: false,
  progress: undefined,
  className:
    '!min-w-full !w-full !bg-transparent !backdrop-blur-md !rounded-none !p-0 !overflow-visible !cursor-default',
  bodyClassName: '!w-full !p-0 !m-0 !items-stretch',
  progressClassName: '!flex w-full h-3',
  closeButton: ({ closeToast }) => (
    <div className="absolute top-2 md:-top-1 right-2 md:-right-1 flex flex-col justify-start">
      <IconButton
        iconType="close"
        type="tonal"
        size="sm"
        onClick={(evt) => {
          evt.preventDefault();
          evt.nativeEvent.stopImmediatePropagation();
          evt.stopPropagation();
          closeToast();
        }}
      />
    </div>
  ),
};

const callToast = ({
  type,
  toastId,
  title,
  content,
  link,
  autoClose = undefined,
  update = false,
}: {
  type: FeedbackType;
  toastId: string;
  title: string | JSX.Element;
  content: JSX.Element | string;
  link?: { label: string; href: string };
  autoClose?: number | false | undefined;
  update?: boolean;
}): void => {
  const render = (
    <Toast
      toastId={toastId}
      type={type}
      title={title}
      content={content}
      link={link}
      autoClose={autoClose}
      glow={true}
    />
  );

  const options = {
    role: type,
    toastId,
    autoClose,
    ...TOAST_OPTIONS,
  };

  /** toast */
  if (update) {
    toast.update(toastId, {
      render,
      ...options,
    });
  } else {
    TOAST_FUNC_DICT[type](render, options);
  }
};

export default callToast;
