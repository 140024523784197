import BigNumber from 'bignumber.js';
import { connectStargateClient, fetchChainBalance, getFeeAmount } from 'common/utils';
import { CHAIN_ID } from 'COMMON_VARIABLES';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { InfoAsset } from 'types/asset';
import type { Currency, CurrencyDetail, GasPriceStep, InfoChain } from 'types/chain';
import useConnectedWallet from './useConnectedWallet';
import type { StargateClient } from '@cosmjs/stargate';

const useChainTxFeeCurrency = ({
  txChain,
  gas,
  gasPriceStepKey,
  txCnt,
}: {
  txChain?: InfoChain;
  gas: number;
  gasPriceStepKey?: keyof GasPriceStep;
  txCnt?: number;
}) => {
  const [client, setClient] = useState<StargateClient | undefined>(undefined);
  const [feeCurrencyBalances, setFeeCurrencyBalances] = useState<(Currency & { balance: BigNumber })[]>([]);

  /** async effect cleanup */
  useEffect(() => {
    return () => {
      client?.disconnect();
      setClient(client);
      setFeeCurrencyBalances(feeCurrencyBalances);
    };
  }, []);

  /** client connection */
  const connectClient = useCallback(() => (txChain ? connectStargateClient(txChain) : undefined), [txChain]);

  useEffect(() => {
    connectClient()?.then((client) => setClient(client));
  }, [connectClient]);

  /** fee fetching */
  const mainStore = useMainStore();

  const { connectedWallet: txChainWallet } = useConnectedWallet(txChain?.chainId ?? '');

  const fetchFeeCurrencyBalances = useCallback(() => {
    const isCrescentChain = txChain?.chainId === CHAIN_ID;

    return Promise.all(
      txChain?.feeCurrencies.map(async (currency) => {
        const assetInfo = Object.values(mainStore.assetsData.info).find(
          (asset) => asset.baseDenom === currency.coinMinimalDenom
        );

        const txChainBalance = await fetchChainBalance({
          wallet: txChainWallet,
          client,
          denom: (isCrescentChain ? assetInfo?.denom : currency.coinMinimalDenom) ?? '',
        });

        const balance = new BigNumber(txChainBalance.amount).div(10 ** (currency.coinDecimals ?? 0));

        return {
          ...currency,
          balance,
        };
      }) ?? []
    );
  }, [txChainWallet, client, txChain, mainStore.assetsData.info]);

  useEffect(() => {
    fetchFeeCurrencyBalances().then((feeCurrencyBalances) => setFeeCurrencyBalances(feeCurrencyBalances));
  }, [fetchFeeCurrencyBalances]);

  /** exports */
  const feeCurrencies = useMemo<CurrencyDetail[]>(() => {
    if (txChain === undefined) return [];

    return feeCurrencyBalances.map((feeCurrency) => ({
      ...feeCurrency,
      feeAmount: getFeeAmount({
        feeCurrency,
        txChain,
        gas,
        // baseGas: getBaseGasByChain(txChain.chainId, feeCurrency.coinDecimals),
        gasPriceStepKey,
        txCnt,
      }),
    }));
  }, [feeCurrencyBalances, gas, gasPriceStepKey, txCnt, txChain]);

  const getFeeCurrency = useCallback<(asset: InfoAsset) => CurrencyDetail | undefined>(
    (asset: InfoAsset) => feeCurrencies.find((currency) => currency.coinMinimalDenom === asset.baseDenom),
    [feeCurrencies]
  );

  const hasTokenToPayFeeExcept = useCallback<(assets: InfoAsset[]) => boolean>(
    (assets: InfoAsset[]) => {
      return feeCurrencies
        .filter((currency) => !assets.map((asset) => asset.baseDenom).includes(currency.coinMinimalDenom))
        .some((currency) => currency.balance.gte(currency.feeAmount));
    },
    [feeCurrencies]
  );

  return { feeCurrencies, getFeeCurrency, hasTokenToPayFeeExcept };
};

export default useChainTxFeeCurrency;
