import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD } from 'common/utils';
import CoinPair from 'components/coins/CoinPair';
import ModalFooter from 'components/modals/components/ModalFooter';
import ModalHeader from 'components/modals/components/ModalHeader';
import TransitionModal from 'components/modals/TransitionModal';
import PanelAmount from 'components/panel/PanelAmount';
import PanelLabel from 'components/panel/PanelLabel';
import PairTicker from 'components/pools/PairTicker';
import PoolId from 'components/pools/PoolId';
import { useEffect, useState } from 'react';
import { CoinDetail } from 'types/asset';
import { MyPoolDetail } from 'types/pool';
import Coin from 'components/coins/Coin';
import NumberText from 'components/texts/NumberText';
import { MODAL_TITLES } from 'COMMON_VARIABLES';

type ClaimAllRewardsModalProps = {
  isOpen?: boolean;
  isLoading?: boolean;
  pools: MyPoolDetail[];
  totalRewardsUSD: BigNumber;
  onClose: () => void;
  onOkClick: () => void;
};

const ClaimAllRewardsModal = ({
  isOpen = false,
  isLoading = false,
  pools,
  totalRewardsUSD,
  onClose,
  onOkClick,
}: ClaimAllRewardsModalProps) => {
  const [totalDisplay, setTotalDisplay] = useState<BigNumber>(totalRewardsUSD);

  useEffect(() => {
    if (!isLoading) setTotalDisplay(totalRewardsUSD);
  }, [totalRewardsUSD, isLoading]);

  return (
    <TransitionModal isOpen={isOpen} close={onClose}>
      <section className="relative pb-[6.375rem]">
        <ModalHeader title={MODAL_TITLES.CLAIM_REWARDS} onCloseClick={onClose} />

        <div className="h-[17.5rem] space-y-5 px-4 pb-7 overflow-auto md:px-5 md:pb-[2.25rem] md:space-y-6">
          <h6 className="flex flex-col items-center gap-y-2">
            <PanelLabel label="Total Rewards" size="lg" />
            <PanelAmount amount={totalDisplay} symbol="$" dp={6} size="lg" color="primary" animated={true} />
          </h6>

          {pools
            .filter((pool) => pool.claimableRewards.length > 0)
            .map((pool) => (
              <ClaimableRewardsByPool key={pool.poolId} pool={pool} isLoading={isLoading} />
            ))}
        </div>

        <ModalFooter
          buttonLabel="Claim All"
          buttonStatus={totalRewardsUSD.isZero() ? 'disabled' : isLoading ? 'loading' : 'enabled'}
          buttonSize="lg"
          buttonMetalic={true}
          buttonClassName="w-full"
          onOkClick={onOkClick}
        />
      </section>
    </TransitionModal>
  );
};

export default ClaimAllRewardsModal;

function ClaimableRewardsByPool({ pool, isLoading = false }: { pool: MyPoolDetail; isLoading?: boolean }) {
  const [rewardsDisplays, setRewardsDisplays] = useState<CoinDetail[]>(pool.claimableRewards);
  useEffect(() => {
    if (!isLoading) setRewardsDisplays(pool.claimableRewards);
  }, [pool.claimableRewards, isLoading]);

  return (
    <section className="space-y-3">
      {/* pool */}
      <div className="flex items-center gap-x-2">
        <CoinPair assets={pool.assets} size="16px" />

        <div className="flex items-center gap-x-1">
          <PairTicker pair={pool} size="sm" />
          <PoolId poolId={pool.poolId} hashTag={true} size="sm" />
        </div>
      </div>

      {/* rewards */}
      <div className="pl-5 space-y-2">
        {rewardsDisplays.map((reward, index) => (
          <div key={index} className="flex justify-between items-center gap-x-4">
            <div className="flex items-center gap-x-1">
              <Coin asset={reward.asset} size="24px" />
              <NumberText value={formatAmount(reward.amount, reward.asset.exponent)} unit={reward.asset.ticker} />
            </div>

            <div className="font_caption_number_m text-on_surface_variant_light">
              {formatUSD(reward.amountUSD, { significant: true })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
