import Coin from 'components/coins/Coin';
import type { CoinSize } from 'components/coins/constans';
import type { IconSize, IconType } from 'components/icon';
import Icon from 'components/icon';
import MetalicLayer from 'components/layer/MetalicLayer';
import Spinner from 'components/spinners/Spinner';
import Tooltip from 'components/tooltips';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import type { InfoAsset } from 'types/asset';

export type ButtonStyle = 'filled' | 'outlined' | 'text' | 'tonal' | 'surficial' | 'neon';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';
export type ButtonColor = 'plain' | 'liquid' | 'danger' | 'red' | 'green' | 'yes' | 'no' | 'veto' | 'abstain';
export type ButtonStatus = 'enabled' | 'notified' | 'loading' | 'disabled';

/** color */
const BUTTON_COLORS_DICT: { [key in ButtonStyle]: { [key in ButtonColor]: string } } = {
  filled: {
    plain: 'text-on_primary_dark bg-primary',
    liquid: 'text-liquid_variant_dark bg-liquid',
    danger: 'text-semantic_on_red bg-semantic_red',
    red: 'text-semantic_red_variant_dark bg-semantic_red',
    green: 'text-semantic_green_variant_dark bg-semantic_green',
    yes: 'text-semantic_green_variant_dark bg-gov_yes',
    no: 'text-semantic_red_variant_dark bg-gov_no',
    veto: 'text-semantic_red_variant_dark bg-gov_nowithveto',
    abstain: 'text-semantic_red_variant_dark bg-gov_abstain',
  },
  outlined: {
    plain: 'text-primary bg-transparent border border-outline_hard',
    liquid: 'text-liquid bg-transparent border border-liquid_variant_light',
    danger: 'text-semantic_red bg-transparent border border-semantic_red',
    red: 'text-semantic_red bg-transparent border border-semantic_red',
    green: 'text-semantic_green bg-transparent border border-semantic_green',
    yes: 'text-gov_yes bg-transparent border border-gov_yes',
    no: 'text-gov_no bg-transparent border border-gov_no',
    veto: 'text-gov_nowithveto bg-transparent border border-gov_nowithveto',
    abstain: 'text-gov_abstain bg-transparent border border-gov_abstain',
  },
  text: {
    plain: 'text-primary bg-transparent',
    liquid: 'text-liquid bg-transparent',
    danger: 'text-semantic_red bg-transparent',
    red: 'text-semantic_red bg-transparent',
    green: 'text-semantic_green bg-transparent',
    yes: 'text-gov_yes bg-transparent',
    no: 'text-gov_no bg-transparent',
    veto: 'text-gov_nowithveto bg-transparent',
    abstain: 'text-gov_abstain bg-transparent',
  },
  tonal: {
    plain: 'text-on_primary_container bg-primary_container',
    liquid: 'text-liquid_variant_dark bg-liquid_container',
    danger: 'text-semantic_on_red bg-semantic_red',
    red: 'text-semantic_on_red_container bg-semantic_red',
    green: 'text-semantic_on_green_container bg-semantic_green',
    yes: 'text-gov_yes bg-gov_yes_o24',
    no: 'text-gov_no bg-gov_no_o24',
    veto: 'text-gov_nowithveto bg-gov_nowithveto_o24',
    abstain: 'text-gov_abstain bg-gov_abstain_o24',
  },
  surficial: {
    plain: 'text-secondary bg-inverse_primary',
    liquid: 'text-liquid_variant_dark bg-transparent',
    danger: 'text-semantic_on_red bg-transparent',
    red: 'text-semantic_on_red bg-transparent',
    green: 'text-semantic_on_green bg-transparent',
    yes: 'text-gov_yes bg-transparent',
    no: 'text-gov_no bg-transparent',
    veto: 'text-gov_nowithveto bg-transparent',
    abstain: 'text-gov_abstain bg-transparent',
  },
  neon: {
    plain: 'text-primary bg-primary_o24 border border-primary',
    liquid: 'text-liquid bg-liquid_o24 border border-liquid',
    danger: 'text-semantic_red bg-semantic_red_o24 border border-semantic_red',
    red: 'text-semantic_red bg-semantic_red_o24 border border-semantic_red',
    green: 'text-semantic_green bg-semantic_green_o24 border border-semantic_green',
    yes: 'text-gov_yes bg-gov_yes_o24 border border-gov_yes',
    no: 'text-gov_no bg-gov_no_o24 border border-gov_no',
    veto: 'text-gov_no_o24 bg-transparent border border-gov_nowithveto',
    abstain: 'text-gov_abstain_o24 bg-transparent border border-gov_abstain',
  },
};

const BUTTON_SPINNER_COLOR_DICT: { [key in ButtonColor]: string } = {
  plain: 'border-on_primary_dark',
  liquid: 'border-liquid_variant_dark',
  danger: 'border-semantic_red_variant_dark',
  red: 'border-semantic_red_variant_dark',
  green: 'border-semantic_green_variant_dark',
  yes: 'border-semantic_green_variant_dark',
  no: 'border-semantic_red_variant_dark',
  veto: 'border-semantic_red_variant_dark',
  abstain: 'border-on_primary_dark',
};

const BUTTON_BG_LAYER_BASE =
  'before:block before:absolute before:w-full before:h-full before:top-0 before:right-0 before:left-0 before:bottom-0 before:transition-all';

const BUTTON_BG_LAYER_COLOR_DICT: { [key in ButtonStyle]: { [key in ButtonColor]: string } } = {
  filled: {
    plain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-on_primary_light_o24`,
    liquid: `${BUTTON_BG_LAYER_BASE} before:hover:bg-liquid_variant_light_o24`,
    danger: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_variant_light_o24`,
    red: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_variant_light_o24`,
    green: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_green_variant_light_o24`,
    yes: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_yes_o24`,
    no: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    veto: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    abstain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_abstain_o24`,
  },
  outlined: {
    plain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-primary_o24`,
    liquid: `${BUTTON_BG_LAYER_BASE} before:hover:bg-liquid_o24`,
    danger: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    red: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    green: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_green_o24`,
    yes: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_yes_o24`,
    no: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    veto: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    abstain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_abstain_o24`,
  },
  text: {
    plain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-primary_o24`,
    liquid: `${BUTTON_BG_LAYER_BASE} before:hover:bg-liquid_o24`,
    danger: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    red: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    green: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_green_o24`,
    yes: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_yes_o24`,
    no: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    veto: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    abstain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_abstain_o24`,
  },
  tonal: {
    plain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-on_primary_container_o24`,
    liquid: `${BUTTON_BG_LAYER_BASE} before:hover:bg-liquid_o24`,
    danger: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    red: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    green: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_green_o24`,
    yes: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_yes_o24`,
    no: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    veto: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    abstain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_abstain_o24`,
  },
  surficial: {
    plain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-primary_o24`,
    liquid: `${BUTTON_BG_LAYER_BASE} before:hover:bg-liquid_o24`,
    danger: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_variant_light_o24`,
    red: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_variant_light_o24`,
    green: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_green_variant_light_o24`,
    yes: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_yes_o24`,
    no: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    veto: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    abstain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_abstain_o24`,
  },
  neon: {
    plain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-primary_o24`,
    liquid: `${BUTTON_BG_LAYER_BASE} before:hover:bg-liquid_o24`,
    danger: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    red: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_red_o24`,
    green: `${BUTTON_BG_LAYER_BASE} before:hover:bg-semantic_green_o24`,
    yes: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_yes_o24`,
    no: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    veto: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_no_o24`,
    abstain: `${BUTTON_BG_LAYER_BASE} before:hover:bg-gov_abstain_o24`,
  },
};

const BUTTON_BG_LAYER_LOADING_COLOR_DICT: { [key in ButtonColor]: string } = {
  plain: 'before:bg-surface_o24',
  liquid: 'before:bg-liquid_variant_light_o24',
  danger: 'before:bg-semantic_red_variant_light_o24',
  red: 'before:bg-semantic_red_variant_light_o24',
  green: 'before:bg-semantic_green_variant_light_o24',
  yes: 'before:bg-gov_yes_o24',
  no: 'before:bg-gov_no_o24',
  veto: 'before:bg-gov_no_o24',
  abstain: 'before:bg-gov_abstain_o24',
};

const BUTTON_BG_LAYER_DICT: { [key in ButtonStatus]: (type: ButtonStyle, color: ButtonColor) => string } = {
  enabled: (type, color) => BUTTON_BG_LAYER_COLOR_DICT[type][color],
  notified: () => `${BUTTON_BG_LAYER_BASE} before:bg-primary_o28 before:hover:bg-primary_o24 !text-on_inverse_primary`,
  loading: (_, color) => `${BUTTON_BG_LAYER_BASE} ${BUTTON_BG_LAYER_LOADING_COLOR_DICT[color]}`,
  disabled: () => '',
};

const BUTTON_DISABLED_DICT: { [key in ButtonStyle]: string } = {
  filled: 'text-on_surface bg-on_surface_o24',
  outlined: 'text-on_surface bg-transparent border border-outline_hard_o24',
  text: 'text-on_surface bg-transparent',
  tonal: 'text-on_surface bg-on_surface_o24',
  surficial: 'text-on_surface bg-on_surface_o24',
  neon: 'text-on_surface bg-transparent border border-outline_hard_o24',
};

/** size */
const BUTTON_SIZES_DICT: { [key in ButtonSize]: string } = {
  xs: 'font_label_s px-1 py-[0.625rem] max-h-[1.25rem]',
  sm: 'font_label_s px-[0.625rem] py-[0.625rem] max-h-[1.75rem]',
  md: 'font_label_m px-4 py-[0.625rem] max-h-[2.25rem]',
  lg: 'font_label_l px-5 py-[0.625rem] h-[3rem] max-h-[3rem]',
};

const BUTTON_ICON_PADDING_OFFSET_DICT: { [key in ButtonSize]: { coinLeft: string; left: string; right: string } } = {
  xs: { coinLeft: '-ml-1', left: '', right: '' },
  sm: { coinLeft: '-ml-1', left: '', right: '' },
  md: { coinLeft: '-ml-2', left: '-ml-1', right: '-mr-1' },
  lg: { coinLeft: '-ml-2', left: '-ml-1', right: '-mr-1' },
};

const COIN_SIZES_DICT: { [key in ButtonSize]: CoinSize } = {
  xs: '16px',
  sm: '20px',
  md: '24px',
  lg: '32px',
};

const ICON_SIZES_DICT: { [key in ButtonSize]: IconSize } = {
  xs: '14px',
  sm: '16px',
  md: '20px',
  lg: '24px',
};

export type ButtonProps = {
  href?: string;
  to?: string;
  label: string;
  onClick?: () => void;
  type?: ButtonStyle;
  color?: ButtonColor;
  metalic?: boolean;
  size?: ButtonSize;
  status?: ButtonStatus;
  leadingIcon?: IconType;
  trailingIcon?: IconType;
  coinAsset?: InfoAsset;
  tooltipContent?: string;
  className?: string;
};

const Button = ({
  href,
  to,
  label,
  type = 'filled',
  color = 'plain',
  metalic = false,
  size = 'lg',
  status = 'enabled',
  onClick,
  leadingIcon,
  trailingIcon,
  coinAsset,
  tooltipContent,
  className,
}: ButtonProps) => {
  const colorClassName = useMemo<string>(
    () =>
      status === 'disabled'
        ? BUTTON_DISABLED_DICT[type]
        : `${BUTTON_COLORS_DICT[type][color]} ${BUTTON_BG_LAYER_DICT[status](type, color)}`,
    [status, type, color]
  );
  const cursorClassName = useMemo<string>(
    () => (status === 'disabled' ? 'cursor-not-allowed' : status === 'loading' ? 'cursor-wait' : ''),
    [status]
  );

  /** @desc metalic effect */
  const [animateBg, setAnimateBg] = useState<boolean>(false);
  const onMouseEnter = useCallback(() => {
    if (metalic) setAnimateBg(true);
  }, [metalic]);

  const onMouseLeave = useCallback(() => {
    setAnimateBg(false);
  }, []);

  /** render */
  const args = useMemo(() => {
    return {
      disabled: status === 'disabled' || status === 'loading',
      className: `${className} relative inline-flex justify-center items-center gap-x-2 overflow-hidden transition-opacity rounded-full transition-colors ${colorClassName} ${cursorClassName}`,
      onMouseEnter,
      onMouseLeave,
    };
  }, [status, className, colorClassName, cursorClassName, onMouseEnter, onMouseLeave]);

  const content = useMemo<JSX.Element>(() => {
    return (
      <div className={`relative w-full h-full`}>
        {animateBg && <MetalicLayer className="!absolute inset-0" />}

        <div
          className={`relative flex justify-center items-center gap-x-1 ${BUTTON_SIZES_DICT[size]} ${
            status === 'disabled' ? 'opacity-40' : status === 'loading' ? 'opacity-0' : ''
          }`}
        >
          {coinAsset && (
            <Coin
              asset={coinAsset}
              size={COIN_SIZES_DICT[size]}
              className={BUTTON_ICON_PADDING_OFFSET_DICT[size].coinLeft}
            />
          )}
          {leadingIcon && (
            <Icon
              type={leadingIcon}
              size={ICON_SIZES_DICT[size]}
              className={`${BUTTON_ICON_PADDING_OFFSET_DICT[size].left}`}
            />
          )}
          <div className="truncate">{label}</div>
          {trailingIcon && (
            <Icon
              type={trailingIcon}
              size={ICON_SIZES_DICT[size]}
              className={`${BUTTON_ICON_PADDING_OFFSET_DICT[size].right}`}
            />
          )}
        </div>

        {status === 'loading' && (
          <Spinner
            spinnerClassName={BUTTON_SPINNER_COLOR_DICT[color]}
            className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
          />
        )}
      </div>
    );
  }, [animateBg, status, label, size, coinAsset, leadingIcon, trailingIcon, color]);

  return (
    <Tooltip content={tooltipContent} className={className}>
      {to ? (
        <Link to={to} {...args}>
          {content}
        </Link>
      ) : href === undefined ? (
        <button type="button" {...args} onClick={onClick}>
          {content}
        </button>
      ) : (
        <a href={href} target="_blank" rel="noreferrer" {...args}>
          {content}
        </a>
      )}
    </Tooltip>
  );
};

export default Button;
