type RedDotSize = 'sm' | 'md';

export default function RedDot({ className = '', size = 'md' }: { className?: string; size?: RedDotSize }) {
  return (
    <div className={`${className} ${getRedDotSize(size)}`}>
      <span className={`flex relative ${getRedDotSize(size)} justify-center items-center`}>
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#EA5B52a0] opacity-75"></span>
        <span className="relative inline-flex rounded-full h-full w-full bg-[#EA5B52]"></span>
      </span>
    </div>
  );
}

function getRedDotSize(size: RedDotSize) {
  switch (size) {
    case 'sm':
      return 'w-1.5 h-1.5';
    case 'md':
      return 'w-2 h-2';
  }
}
