import { PanelAmountSize } from './types';

const PANEL_AMOUNT_TYPO_DICT: {
  [key in PanelAmountSize]: string;
} = {
  md: 'font_title_s',
  lg: 'font_title_s',
  xl: 'font_headline_m',
};

const PanelLabel = ({ label, size }: { label: string; size: PanelAmountSize }) => {
  return <div className={`${PANEL_AMOUNT_TYPO_DICT[size]} text-on_surface_variant_light`}>{label}</div>;
};

export default PanelLabel;
