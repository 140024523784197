import { MsgLiquidUnstake } from '../crescent/liquidstaking/types/crescent/liquidstaking/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createLiquidUnstakeMsg = (txData: any, creAddress: any) => {
  const liquidUnstakeData: MsgLiquidUnstake = {
    delegatorAddress: creAddress,
    amount: txData.unstakingCoin,
  };
  console.log('liquidUnstakeData', liquidUnstakeData);
  const msg = msgEncoder.msgLiquidUnstake(liquidUnstakeData);
  return [msg];
};

export default createLiquidUnstakeMsg;
