import BigNumber from 'bignumber.js';
import { isNumberString, removeComma } from 'common/utils';
import { CHAIN_ID } from 'COMMON_VARIABLES';
import useAmountUtils from 'hooks/useAmountUtils';
import useGas from 'hooks/useGas';
import { useCallback, useMemo, useState } from 'react';
import { InfoAsset } from 'types/asset';

/** @description price could be 'Auto', 'Market', or number string */
const useOrderbookQuantity = ({
  orderIndex,
  orderTypeIndex,
  availableBalance,
  payAssetInfo,
  baseInfo,
  quoteInfo,
  predPrice,
  price,
  setPrice,
}: // getInstantPrice,
{
  orderIndex: 0 | 1;
  orderTypeIndex: 0 | 1 | 2;
  availableBalance: string;
  payAssetInfo: InfoAsset | undefined;
  baseInfo: InfoAsset | undefined;
  quoteInfo: InfoAsset | undefined;
  predPrice: BigNumber | undefined;
  price: string;
  setPrice: (value: React.SetStateAction<string>) => void;
  // getInstantPrice: (amount: any) => string;
}) => {
  /** @summary return undefined when price <= 0 */
  const positivePrice = useMemo<string | undefined>(() => {
    const priceString = isNumberString(price) ? removeComma(price) : predPrice?.toString();
    return priceString && Number(priceString) > 0 ? priceString : undefined;
  }, [price, predPrice]);

  /**
   * @summary may return empty string ''
   * @description not handled correctly atm; to use, need to check and fix
   */
  // const getInstantPriceFor = useCallback<(payAmount: BigNumber) => string>(
  //   (payAmount: BigNumber) => {
  //     if (orderIndex === 0) {
  //       const buyableAmount = positivePrice
  //         ? payAmount.div(positivePrice).dp(baseInfo?.exponent ?? 18, BigNumber.ROUND_DOWN)
  //         : undefined;

  //       return buyableAmount ? getInstantPrice(buyableAmount.toString()) : '';
  //     } else {
  //       return getInstantPrice(payAmount.toString());
  //     }
  //   },
  //   [baseInfo?.exponent, getInstantPrice, orderIndex, positivePrice]
  // );

  /** converting to quantity */
  const quantityAssetInfo = useMemo<InfoAsset | undefined>(() => {
    const isBuy = orderIndex === 0;
    switch (orderTypeIndex) {
      case 0:
        return baseInfo;
      case 1:
        return baseInfo;
      case 2:
        return isBuy ? quoteInfo : baseInfo;
    }
  }, [orderIndex, orderTypeIndex, baseInfo, quoteInfo]);

  const getBuyConvertRate = useCallback<(payAmount: BigNumber) => string | undefined>(
    (payAmount: BigNumber) => {
      switch (orderTypeIndex) {
        case 0 /** limit order */:
          return positivePrice;
        // case 1 /** instant order */:
        //   return getInstantPriceFor(payAmount);
        case 2 /** market order; payAsset = quantityAsset */:
          return '1';
      }
    },
    [orderTypeIndex, positivePrice]
  );

  const convertToQuantity = useCallback<(payAmount: BigNumber) => BigNumber | undefined>(
    (payAmount: BigNumber) => {
      if (orderIndex === 0) {
        /** buy */
        const convertRate: string | undefined = getBuyConvertRate(payAmount);
        const percentQuantity = convertRate
          ? payAmount.div(convertRate).dp(quantityAssetInfo?.exponent ?? 18, BigNumber.ROUND_DOWN)
          : undefined;
        return percentQuantity;
      } else {
        /** sell */
        return payAmount;
      }
    },
    [orderIndex, quantityAssetInfo?.exponent, getBuyConvertRate]
  );

  /** percent amount */
  const { gas } = useGas('swap');
  const { getPercentAmountOf } = useAmountUtils({
    asset: payAssetInfo,
    txChainId: CHAIN_ID,
    gas,
  });

  const getPercentBalance = useCallback<(percent: number) => BigNumber | undefined>(
    (percent: number) => {
      const balance = new BigNumber(availableBalance);
      if (balance.isNaN()) return undefined;
      return balance ? getPercentAmountOf(balance, percent) : undefined;
    },
    [availableBalance, getPercentAmountOf]
  );

  /** @summary on instant order quantity change */
  // const setInstantOrderPrice = useCallback<(payAmount: BigNumber) => void>(
  //   (payAmount: BigNumber) => {
  //     if (orderTypeIndex === 1) {
  //       const instantPrice = getInstantPriceFor(payAmount);
  //       setPrice(instantPrice);
  //     }
  //   },
  //   [orderTypeIndex, setPrice]
  // );

  /** exports */
  const [quantity, setQuantity] = useState<string>('');

  const setQuantityByPercent = useCallback(
    ({
      percent,
      onChange,
    }: {
      percent: number;
      onChange?: (percentBalance: BigNumber, percentQuantity: BigNumber) => void;
    }) => {
      const percentBalance = getPercentBalance(percent);

      if (percentBalance) {
        const percentQuantity = convertToQuantity(percentBalance);

        if (percentQuantity) {
          setQuantity(percentQuantity.toString());
          onChange?.(percentBalance, percentQuantity);

          /** @todo this should be replaced with callback in the future */
          // setInstantOrderPrice(percentBalance);
        } else {
          alert('Error: price data is missing');
        }
      } else {
        setQuantity('');
      }
    },
    [convertToQuantity, getPercentBalance]
  );

  return {
    quantity,
    setQuantity,
    setQuantityByPercent,
  };
};

export default useOrderbookQuantity;
