export type CoinSize = '64px' | '48px' | '36px' | '32px' | '24px' | '20px' | '16px';

export const COIN_SIZE_REM_DICT: { [key in CoinSize]: number } = {
  '64px': 4,
  '48px': 3,
  '36px': 2.25,
  '32px': 2,
  '24px': 1.5,
  '20px': 1.25,
  '16px': 1,
};

export const COIN_BRIDGE_BADGE_SIZE_DICT: Record<CoinSize, string> = {
  '64px': '24px',
  '48px': '18px',
  '36px': '14px',
  '32px': '12px',
  '24px': '10px',
  '20px': '8px',
  '16px': '6px',
};

export type CoinColor = 'surface' | 'liquid';
