import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import type { TradeType } from 'types/swap';

const useTradeTypeSearch = () => {
  const location = useLocation();

  const getTradeTypeSearch = useCallback((): TradeType | undefined => {
    const searches = location.search.replace('?', '').split('&');
    const tradeTypeSearch = searches.find((search) => search.includes('type='));
    const searchedTradeType = tradeTypeSearch?.split('=')[1];

    return searchedTradeType === 'swap' || searchedTradeType === 'orderbook' ? searchedTradeType : undefined;
  }, [location.search]);

  const replaceTradeTypeSearch = useCallback(
    (tradeType: 'orderbook' | 'swap'): string => {
      const searchedTradeType = getTradeTypeSearch();
      const newSearch = searchedTradeType
        ? location.search.replace(`type=${searchedTradeType}`, `type=${tradeType}`)
        : `${location.search}&type=${tradeType}`;

      return newSearch;
    },
    [location.pathname, location.search]
  );

  return {
    getTradeTypeSearch,
    replaceTradeTypeSearch,
  };
};

export default useTradeTypeSearch;
