import StakingForm from 'components/staking/StakingForm';
import TabSelector from 'components/tabs/TabSelector';
import Widget from './components/Widget';
import { useMainStore } from 'provider/mainContext';

const StakingWidget = () => {
  const store = useMainStore();

  return (
    <Widget glow={!store.isMobile} padding={store.isMobile ? '16px' : '20px'}>
      <Widget.Header title="Liquid Staking" />
      <Widget.Body>
        <div className="w-full space-y-5 md:space-y-6">
          <TabSelector
            type="line"
            panels={[
              {
                key: 'Stake CRE',
                content: <StakingForm direction="stake" />,
              },
              {
                key: 'Unstake bCRE',
                content: <StakingForm direction="unstake" />,
              },
            ]}
          />
        </div>
      </Widget.Body>
    </Widget>
  );
};

export default StakingWidget;
