import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo, useState } from 'react';
import { formatAmount, getExponentDiff, getValidDigitNumber } from 'common/utils';
import BigNumber from 'bignumber.js';
import { usePastOrderDetail } from 'hooks/useAPI';
import { ORDER_SEARCH_TERMS, ORDER_UPDATE_TERM } from 'COMMON_VARIABLES';
import Table from 'components/table/Table';
import { TableStyles } from 'components/table/styles';
import PastOrderDetailsTable from './PastOrderDetailsTable';
import { TableField } from 'components/table/types';
import OrdersTablePairTitle from './OrdersTablePairTitle';

/** @desc PastOrdersTable */
type PastOrdersTableRow = {
  date: string;
  timestamp: number;
  pair: JSX.Element;
  pairDirectionSet: JSX.Element;
  direction: JSX.Element;
  avgPrice: string;
  orderPrice: string;
  filled: JSX.Element;
  status: JSX.Element;
  orderId: string;
  isEvents: boolean;
  subJsx?: JSX.Element;
  isSubJsxOpen?: boolean;
};

const MOBILE_FIELDS: TableField<PastOrdersTableRow>[] = [
  {
    label: 'Pair',
    value: 'pairDirectionSet',
    type: 'jsx',
    sortDisabled: true,
  },
];

const MD_FIELDS: TableField<PastOrdersTableRow>[] = [
  {
    label: 'Pair',
    value: 'pair',
    type: 'jsx',
    sortDisabled: true,
    widthRatio: 12,
  },
  {
    label: 'Direction',
    value: 'direction',
    type: 'jsx',
    sortDisabled: true,
    widthRatio: 4,
  },
];

type PastOrdersTableProps = {
  searchTermIndex: number;
};

export default function PastOrdersTable({ searchTermIndex }: PastOrdersTableProps) {
  const mainStore = useMainStore();
  const [openEvents, setOpenEvents] = useState<number[]>([]);

  const { data, isLoading } = usePastOrderDetail(
    mainStore.wallet.address || '',
    ORDER_SEARCH_TERMS[searchTermIndex],
    ORDER_UPDATE_TERM
  );

  const tableRows = useMemo<PastOrdersTableRow[]>(() => {
    return (
      data?.map((pastOrder) => {
        const isBuy = pastOrder.direction === 1;
        const isOpen = openEvents.includes(pastOrder.timestamp);
        const isEvents = (pastOrder.events ?? []).length > 1;
        const _pairData = mainStore?.pairsData?.info?.[pastOrder.pairId];
        const _assetData = mainStore?.assetsData?.info;
        const baseInfo = _assetData?.[_pairData?.baseDenom];
        const quoteInfo = _assetData?.[_pairData?.quoteDenom];
        const exponentDiff = getExponentDiff(baseInfo.denom, quoteInfo.denom, mainStore);
        const _rawOrderTime = new Date(
          new BigNumber(pastOrder.timestamp).shiftedBy(-3).decimalPlaces(0, 1).toNumber()
        ).toLocaleString('en-us');
        const fullYear = new Date(new Date().getTime()).getFullYear();
        const orderTime = _rawOrderTime.replace('/' + fullYear + ',', '').slice(0, -6) + ' ' + _rawOrderTime.slice(-2);

        let avgPrice: string;

        if (pastOrder.events) {
          if (pastOrder?.events?.length > 1) {
            let _temp_amount: any = 0;
            let _temp_swapped_total_base_amount: any = 0;
            pastOrder.events.forEach((order) => {
              if (order.status !== 6) {
                _temp_amount = new BigNumber(_temp_amount).plus(
                  new BigNumber(order.swap_price).multipliedBy(order.swapped_base_amount).decimalPlaces(0, 1)
                );
                _temp_swapped_total_base_amount = new BigNumber(_temp_swapped_total_base_amount)
                  .plus(order.swapped_base_amount)
                  .decimalPlaces(0, 1);
              }
            });
            avgPrice = getValidDigitNumber(_temp_amount.div(_temp_swapped_total_base_amount).toString(), 5);
          } else {
            avgPrice = getValidDigitNumber(pastOrder.events[0].swap_price, 5);
          }
          avgPrice = new BigNumber(avgPrice).shiftedBy(exponentDiff).toString();
        } else {
          avgPrice = '-';
        }

        const pairDirectionSet = (
          <OrdersTablePairTitle baseTicker={baseInfo?.ticker} quoteTicker={quoteInfo?.ticker} isBuy={isBuy} />
        );

        const subJsx = isEvents ? (
          <div className="bg_surface_1">
            <PastOrderDetailsTable
              events={pastOrder.events ?? []}
              baseExponent={baseInfo.exponent}
              exponentDiff={exponentDiff}
            />
          </div>
        ) : undefined;

        const isSubJsxOpen = openEvents.includes(pastOrder.timestamp);

        return {
          date: orderTime,
          timestamp: pastOrder.timestamp,
          pair: (
            <div className="font_caption_s">
              {baseInfo?.ticker}/{quoteInfo?.ticker}
            </div>
          ),
          direction: (
            <div className={`font_caption_s ${isBuy ? 'text-semantic_green' : 'text-semantic_red'}`}>
              {isBuy ? 'Buy' : 'Sell'}
            </div>
          ),
          pairDirectionSet,
          avgPrice: avgPrice === '0.0000' || avgPrice.includes('NaN') ? '-' : formatAmount(new BigNumber(avgPrice), 5),
          orderPrice: formatAmount(
            new BigNumber(getValidDigitNumber(new BigNumber(pastOrder.orderPrice).shiftedBy(exponentDiff).toNumber())),
            5
          ),
          filled: (
            <div className="text-right">
              {formatAmount(new BigNumber(pastOrder.filledBaseAmount).shiftedBy(-baseInfo?.exponent), 9)} /{' '}
              {formatAmount(
                new BigNumber(pastOrder.filledBaseAmount).plus(pastOrder.openBaseAmount).shiftedBy(-baseInfo?.exponent),
                9
              )}
            </div>
          ),
          status: (
            <div className="font_caption_s">
              {pastOrder.status === 5
                ? 'Canceled'
                : pastOrder.status === 4
                ? 'Fulfilled'
                : pastOrder.status === 3
                ? 'Partially matched'
                : pastOrder.status === 6
                ? 'Expired'
                : ''}
            </div>
          ),
          orderId: `${pastOrder.pairId}-${pastOrder.reqId}`,
          isEvents,
          subJsx,
          isSubJsxOpen,
        };
      }) ?? []
    );
  }, [data, openEvents, mainStore]);

  const onToggleRowSubJsx = useCallback(
    (row: PastOrdersTableRow) => {
      if (row.isEvents) {
        const _index = openEvents.indexOf(row.timestamp);
        if (_index !== -1) {
          const _temp = JSON.parse(JSON.stringify(openEvents));
          _temp.splice(_index, 1);
          setOpenEvents(_temp);
        } else {
          setOpenEvents((events) => [row.timestamp, ...events]);
        }
      }
    },
    [openEvents]
  );

  return (
    <Table<PastOrdersTableRow>
      type={TableStyles.COMPACT}
      hoverArea="exclude-sub"
      rowsScrollHeight={mainStore.isMobile ? undefined : '17rem'}
      isLoading={isLoading}
      noDataLabel={mainStore.wallet.isActive ? 'No Past Orders' : 'Connect Wallet'}
      rows={tableRows}
      onToggleRowSubJsx={onToggleRowSubJsx}
      fields={[
        ...(mainStore.isMobile ? MOBILE_FIELDS : []),
        {
          label: 'Date',
          value: 'date',
          type: 'jsx',
          sortValue: 'timestamp',
          sortType: 'number',
          sortDisabled: true,
          widthPx: 100,
        },
        ...(mainStore.isMobile ? [] : MD_FIELDS),
        {
          label: 'Avg Price',
          value: 'avgPrice',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 10,
          align: 'right',
        },
        {
          label: 'Order Price',
          value: 'orderPrice',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 10,
          align: 'right',
        },
        {
          label: 'Filled',
          value: 'filled',
          type: 'jsx',
          sortDisabled: true,
          align: 'right',
        },
        {
          label: 'Status',
          value: 'status',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 8,
        },
        {
          label: 'Order ID',
          value: 'orderId',
          type: 'jsx',
          sortDisabled: true,
          widthPx: 100,
        },
      ]}
    />
  );
}
