import { useState, useMemo, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import useTxClient from 'hooks/useTxClient';
import BigNumber from 'bignumber.js';
import Icon from 'components/icon';
import { formatAmount, getMaxSwapInputAmount } from 'common/utils';
import { useMainStore } from 'provider/mainContext';
import { PoolDetail } from 'types/pool';
import useDepositFee from '../hooks/useDepositFee';
import LabeledData from 'components/texts/LabeledData';
import Formula from 'components/texts/Formula';
import AmountInput from 'components/inputs/AmountInput';
import InfoBox from 'components/infobox';
import Coin from 'components/coins/Coin';
import { InfoAsset } from 'types/asset';
import NumberText from 'components/texts/NumberText';
import Button, { ButtonStatus } from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import EasySwapArea, { EasySwapButtonText, EasySwapData } from './EasySwapArea';
import { INFOBOX_TEXTS, INITIAL_ASSET } from 'COMMON_VARIABLES';

/** @desc DepositInputLabel */
const DepositInputLabel = ({ asset }: { asset: InfoAsset }) => {
  return (
    <div className="flex items-center gap-x-1">
      <Coin asset={asset} size="24px" />
      <div className="font_title_s text-on_surface">{asset.ticker}</div>
    </div>
  );
};

/** @desc Deposit */
const INITIAL_EASYSWAP_DATA: EasySwapData = {
  exponents: { offer: 0, demand: 0 },
  offerDenom: '',
  demandDenom: '',
  offerAmount: 0,
  offerTicker: '',
  demandTicker: '',
  isBuy: false,
};

const Deposit = observer(({ pool }: { pool: PoolDetail }) => {
  const mainStore = useMainStore();
  const { signAndBroadcast } = useTxClient();
  const [prevTokensBalance, setPrevTokensBalance] = useState<{ x: BigNumber; y: BigNumber }>({
    x: new BigNumber(0),
    y: new BigNumber(0),
  });
  const [depositInput, setDepositInput] = useState({ tokenX: '', tokenY: '' });
  const [updateType, setUpdateType] = useState<null | 'max' | 'custom'>(null);
  const [pairRateType, setPairRateType] = useState(true);
  const [easySwapButtonText, setEasySwapButtonText] = useState<EasySwapButtonText>('No need to swap');
  const [isDepositAmountUpdate, setIsDepositAmountUpdate] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [isInputOver, setIsInputOver] = useState<{ x: boolean; y: boolean }>({ x: false, y: false });
  const [isEasySwap, setIsEasySwap] = useState(true);
  const [easySwapData, setEasySwapData] = useState<{
    offerDenom: string;
    demandDenom: string;
    offerAmount: number;
    offerTicker: string;
    demandTicker: string;
    exponents: { offer: number; demand: number };
    isBuy: boolean;
  }>(INITIAL_EASYSWAP_DATA);

  const reset = useCallback(() => {
    setDepositInput({ tokenX: '', tokenY: '' });
    setUpdateType(null);
    setPairRateType(true);
    setEasySwapButtonText('No need to swap');
    setIsDepositAmountUpdate(false);
    setIsInputOver({ x: false, y: false });
    setIsEasySwap(true);
    setEasySwapData(INITIAL_EASYSWAP_DATA);
  }, []);

  useEffect(() => reset(), [pool.poolId]);

  const receiveToken = useMemo(() => {
    const isReservedOneExist = Number(pool.reserved[0].amount) > 0;
    const inputAmount = isReservedOneExist ? depositInput.tokenX : depositInput.tokenY;
    const index = isReservedOneExist ? 0 : 1;

    const rate = new BigNumber(inputAmount)
      .multipliedBy(10 ** pool.assets[index].exponent)
      .dividedBy(pool.reserved[index].amount);
    const result = new BigNumber(pool.totalSupplyAmount).multipliedBy(rate);
    return result.isNaN() ? '0' : result.decimalPlaces(0).toString();
  }, [depositInput, pool]);

  const isSingleSide = useMemo(() => {
    if (Number(pool.reserved[0].amount) > 0 && Number(pool.reserved[1].amount) > 0) {
      return { status: false, existIndex: 0 };
    } else {
      return { status: true, existIndex: Number(pool.reserved[0].amount) > 0 ? 0 : 1 };
    }
  }, [pool]);

  const ratio = useMemo<{ x: BigNumber; y: BigNumber }>(() => {
    const x = new BigNumber(pool.reserved[0].amount)
      .div(10 ** pool.assets[0].exponent)
      .div(new BigNumber(pool.reserved[1].amount).div(10 ** pool.assets[1].exponent));
    const y = new BigNumber(pool.reserved[1].amount)
      .div(10 ** pool.assets[1].exponent)
      .div(new BigNumber(pool.reserved[0].amount).div(10 ** pool.assets[0].exponent));
    return { x, y };
  }, [pool]);

  const myTokenXBalance = useMemo(() => {
    const uToken = mainStore?.balanceData?.[pool.assets[0].denom];
    if (uToken) {
      return new BigNumber(uToken).dividedBy(10 ** pool.assets[0].exponent);
    }
    return new BigNumber(0);
  }, [pool, mainStore.balanceData]);

  const myTokenYBalance = useMemo(() => {
    const uToken = mainStore?.balanceData?.[pool.assets[1].denom];
    if (uToken) {
      return new BigNumber(uToken).dividedBy(10 ** pool.assets[1].exponent);
    }
    return new BigNumber(0);
  }, [pool, mainStore.balanceData]);

  const onChangeXAmount = useCallback(
    (amount: string) => {
      if (isSingleSide.status && isSingleSide.existIndex === 1) return;
      if (isLoading) return;
      if (!amount) {
        setDepositInput({
          tokenX: '',
          tokenY: '',
        });
        return;
      }
      let tokenXAmount = new BigNumber(amount);
      setDepositInput({
        tokenX: amount,
        tokenY: tokenXAmount.multipliedBy(ratio.y).decimalPlaces(6).toString(),
      });
      setNeedEasySwapFee(false);
      setUpdateType('custom');
    },
    [isSingleSide.status, isSingleSide.existIndex, isLoading, ratio.y]
  );

  const onChangeYAmount = useCallback(
    (amount: string) => {
      if (isSingleSide.status && isSingleSide.existIndex === 0) return;
      if (isLoading) return;
      if (!amount) {
        setDepositInput({
          tokenX: '',
          tokenY: '',
        });
        return;
      }
      let tokenYAmount = new BigNumber(amount);
      setDepositInput({
        tokenX: tokenYAmount.multipliedBy(ratio.x).decimalPlaces(6).toString(),
        tokenY: amount,
      });
      setNeedEasySwapFee(false);
      setUpdateType('custom');
    },
    [isSingleSide.status, isSingleSide.existIndex, isLoading, ratio.x]
  );

  const maximizeX = useCallback(
    ({ myTokenXBalance, myTokenYBalance }: { myTokenXBalance: BigNumber; myTokenYBalance: BigNumber }) => {
      const tokenYAmount = myTokenXBalance.multipliedBy(ratio.y).dp(pool.assets[1].exponent, 1);
      if (tokenYAmount.lte(myTokenYBalance)) {
        onChangeXAmount(myTokenXBalance.toString());
      } else {
        onChangeYAmount(myTokenYBalance.toString());
      }
    },
    [ratio.y, pool.assets, onChangeXAmount, onChangeYAmount]
  );

  const maximizeY = useCallback(
    ({ myTokenXBalance, myTokenYBalance }: { myTokenXBalance: BigNumber; myTokenYBalance: BigNumber }) => {
      const tokenXAmount = myTokenYBalance.multipliedBy(ratio.x).dp(pool.assets[0].exponent, 1);
      if (tokenXAmount.lte(myTokenXBalance)) {
        onChangeYAmount(myTokenYBalance.toString());
      } else {
        onChangeXAmount(myTokenXBalance.toString());
      }
    },
    [ratio.x, pool.assets, onChangeXAmount, onChangeYAmount]
  );

  /** @summary fee subtraction hook */
  const { setNeedEasySwapFee, getBalanceToMaximize } = useDepositFee({
    mainStore,
    myTokenXBalance,
    myTokenYBalance,
    poolAssets: pool.assets,
  });

  const maximize = useCallback<(maxTarget: 'x' | 'y') => void>(
    (maxTarget: 'x' | 'y') => {
      if (isSingleSide.status && isSingleSide.existIndex === (maxTarget === 'x' ? 1 : 0)) return;
      if (isLoading) return;

      const { xBalance, yBalance } = getBalanceToMaximize(maxTarget);

      const maxFunction = maxTarget === 'x' ? maximizeX : maximizeY;
      maxFunction({ myTokenXBalance: xBalance, myTokenYBalance: yBalance });
    },
    [isSingleSide.status, isSingleSide.existIndex, isLoading, getBalanceToMaximize, maximizeX, maximizeY]
  );

  const maxXToken = () => maximize('x');
  const maxYToken = () => maximize('y');

  const deposit = async () => {
    setLoading(true);
    let error = await signAndBroadcast({
      type: 'poolDeposit',
      txData: {
        poolId: pool.poolId,
        depositCoins: [
          {
            denom: pool.reserved[0].denom,
            amount: new BigNumber(depositInput.tokenX)
              .multipliedBy(10 ** pool.assets[0].exponent)
              .decimalPlaces(0)
              .toString(),
          },
          {
            denom: pool.reserved[1].denom,
            amount: new BigNumber(depositInput.tokenY)
              .multipliedBy(10 ** pool.assets[1].exponent)
              .decimalPlaces(0)
              .toString(),
          },
        ],
      },
      chainData: mainStore.chainsData,
    });
    if (!error) {
      setDepositInput({
        tokenX: '',
        tokenY: '',
      });
    }
    setLoading(false);
  };

  function setSwapNeededAmount(type: 'max' | 'custom') {
    const tokenXdenom = pool.reserved[0].denom;
    const tokenYdenom = pool.reserved[1].denom;
    const swapRatio = pool.poolPrice.toNumber();
    const depositRatio = ratio.y.toNumber();
    const exponents = { x: pool.assets[0].exponent, y: pool.assets[1].exponent };

    if (type === 'max') {
      const { xBalance, yBalance } = getBalanceToMaximize('x');

      setEasySwapButtonText('Swap');
      const { denomSide, amount } = getMaxSwapInputAmount(
        swapRatio,
        depositRatio,
        { denom: tokenXdenom, amount: xBalance.toNumber() },
        yBalance.toNumber(),
        exponents
      );
      if (denomSide === 'x') {
        setEasySwapData({
          offerDenom: tokenXdenom,
          demandDenom: tokenYdenom,
          exponents: { offer: pool.assets[0].exponent, demand: pool.assets[1].exponent },
          offerTicker: pool.assets[0].ticker,
          demandTicker: pool.assets[1].ticker,
          offerAmount: amount,
          isBuy: false,
        });
        onChangeXAmount(new BigNumber(xBalance).minus(amount).toString());
      } else {
        setEasySwapData({
          offerDenom: tokenYdenom,
          demandDenom: tokenXdenom,
          exponents: { offer: pool.assets[1].exponent, demand: pool.assets[0].exponent },
          offerTicker: pool.assets[1].ticker,
          demandTicker: pool.assets[0].ticker,
          offerAmount: amount,
          isBuy: true,
        });
        onChangeYAmount(new BigNumber(yBalance).minus(amount).toString());
      }
    } else {
      if (isInputOver.x && isInputOver.y) {
        setEasySwapButtonText('Insufficient balance for rebalancing');
        setEasySwapData(INITIAL_EASYSWAP_DATA);
        return;
      } else if (isInputOver.x || isInputOver.y) {
        setEasySwapButtonText('Swap');
      } else {
        setEasySwapData(INITIAL_EASYSWAP_DATA);
        setEasySwapButtonText('No need to swap');
        return;
      }

      if (isInputOver.y) {
        const insufficientAmount = new BigNumber(depositInput.tokenY).minus(myTokenYBalance);
        const swapNeededAmount = insufficientAmount.dividedBy(swapRatio).decimalPlaces(exponents.x, 1).toNumber();
        if (new BigNumber(swapNeededAmount).plus(depositInput.tokenX).gt(myTokenXBalance)) {
          setEasySwapButtonText('Insufficient balance for rebalancing');
        }

        setEasySwapData({
          offerDenom: tokenXdenom,
          demandDenom: tokenYdenom,
          exponents: { offer: pool.assets[0].exponent, demand: pool.assets[1].exponent },
          offerTicker: pool.assets[0].ticker,
          offerAmount: swapNeededAmount,
          demandTicker: pool.assets[1].ticker,
          isBuy: false,
        });
      } else {
        const insufficientAmount = new BigNumber(depositInput.tokenX).minus(myTokenXBalance);
        const swapNeededAmount = insufficientAmount.multipliedBy(swapRatio).decimalPlaces(exponents.y, 1).toNumber();
        if (new BigNumber(swapNeededAmount).plus(depositInput.tokenY).gt(myTokenYBalance)) {
          setEasySwapButtonText('Insufficient balance for rebalancing');
        }
        setEasySwapData({
          offerDenom: tokenYdenom,
          demandDenom: tokenXdenom,
          exponents: { offer: pool.assets[1].exponent, demand: pool.assets[0].exponent },
          offerTicker: pool.assets[1].ticker,
          offerAmount: swapNeededAmount,
          demandTicker: pool.assets[0].ticker,
          isBuy: true,
        });
      }
    }
  }

  useEffect(() => {
    if (isDepositAmountUpdate && (myTokenXBalance.gt(prevTokensBalance.x) || myTokenYBalance.gt(prevTokensBalance.y))) {
      maxXToken();
      setPrevTokensBalance({ x: new BigNumber(0), y: new BigNumber(0) });
      setIsDepositAmountUpdate(false);
    }
  }, [myTokenXBalance, myTokenYBalance, isDepositAmountUpdate]);

  useEffect(() => {
    if (updateType === 'custom') {
      setSwapNeededAmount('custom');
    } else if (updateType === 'max') {
      setSwapNeededAmount('max');
    }
    setUpdateType(null);
  }, [updateType]);

  const button = useMemo<{
    status: ButtonStatus;
    text: string;
  }>(() => {
    if (isLoading) {
      return {
        status: 'loading',
        text: '',
      };
    } else {
      setIsInputOver({ x: false, y: false });
      const depositInputX = new BigNumber(depositInput.tokenX);
      const depositInputY = new BigNumber(depositInput.tokenY);
      if (
        (depositInputX.gt(0) || depositInputY.gt(0)) &&
        depositInputX.lte(myTokenXBalance) &&
        depositInputY.lte(myTokenYBalance)
      ) {
        return {
          status: 'enabled',
          text: 'Deposit',
        };
      } else if (!depositInputX.gt(0) && !depositInputY.gt(0)) {
        return {
          status: 'disabled',
          text: 'Enter amount',
        };
      } else {
        let isxyOver = { x: false, y: false };
        if (depositInputX.gt(myTokenXBalance)) {
          isxyOver.x = true;
        }
        if (depositInputY.gt(myTokenYBalance)) {
          isxyOver.y = true;
        }
        setIsInputOver(isxyOver);
        return {
          status: 'disabled',
          text: 'Insufficient balance',
        };
      }
    }
  }, [isLoading, depositInput, myTokenXBalance, myTokenYBalance]);

  return (
    <div className="relative">
      {/* new */}
      <div className="space-y-3 md:space-y-4">
        {isSingleSide.status && <InfoBox type="warning">{INFOBOX_TEXTS.MANAGE_POOL_OUT_OF_RANGE}</InfoBox>}

        <div className="pt-8 space-y-3 md:pt-9 md:space-y-4">
          {/* display easy swap toggler */}
          {/* <div className="flex justify-end items-center gap-x-2">
            <div className="flex items-center gap-x-1 font_caption_s text-on_surface_variant_dark">
              <div>Display easy swap</div>
              <TooltipIcon
                tooltipSize="280px"
                tooltipContent={TOOLTIP_TEXTS.EASY_SWAP}
              />
            </div>
            <ToggleButton size="sm" toggle={() => setIsEasySwap(!isEasySwap)} isEnabled={isEasySwap} />
          </div> */}

          {/* token A */}
          <div className="relative">
            <div className="absolute left-0 -top-[2.125rem]">
              <DepositInputLabel asset={pool.assets[0]} />
            </div>

            <AmountInput
              value={depositInput.tokenX}
              asset={mainStore.assetDetailDict[pool.assets[0].denom]}
              onChangeValue={onChangeXAmount}
              isError={isInputOver.x}
              maximumFractionDigits={pool.assets[0].exponent}
              maxAmount="balance"
              simpleInputData={{
                signType: 'poolDeposit',
                txChainId: INITIAL_ASSET.chainId,
              }}
            />
          </div>

          <Icon type="add" size="24px" className="text-on_surface_variant_light m-auto" />

          {/* token B */}
          <div className="relative">
            <div className="absolute left-0 -top-[2.125rem]">
              <DepositInputLabel asset={pool.assets[1]} />
            </div>

            <AmountInput
              value={depositInput.tokenY}
              asset={mainStore.assetDetailDict[pool.assets[1].denom]}
              onChangeValue={onChangeYAmount}
              isError={isInputOver.y}
              maximumFractionDigits={pool.assets[1].exponent}
              maxAmount="balance"
              simpleInputData={{
                signType: 'poolDeposit',
                txChainId: INITIAL_ASSET.chainId,
              }}
            />
          </div>
        </div>

        <LabeledData
          label="Deposit ratio"
          value={
            isSingleSide.status ? (
              <div className="font_caption_s text-on_surface_variant_light">
                {pool.assets[isSingleSide.existIndex]?.ticker} (single-side)
              </div>
            ) : (
              <Formula
                aValue={new BigNumber(1)}
                bRate={ratio.y}
                dp={6}
                aUnit={pool.assets[0].ticker}
                bUnit={pool.assets[1].ticker}
                seperator=":"
                isForward={pairRateType}
                onSwitchForward={() => setPairRateType((prev) => !prev)}
              />
            )
          }
        />

        {isEasySwap && (
          <EasySwapArea
            pool={pool}
            easySwapData={easySwapData}
            setNeedEasySwapFee={setNeedEasySwapFee}
            easySwapButtonText={easySwapButtonText}
            setUpdateType={setUpdateType}
            chainsData={mainStore.chainsData}
            onSuccess={() => {
              setPrevTokensBalance({ x: myTokenXBalance, y: myTokenYBalance });
              setTimeout(() => {
                setIsDepositAmountUpdate(true);
              }, 2000);
            }}
          />
        )}

        <Divider type="hard" />

        <LabeledData
          label="You will receive"
          value={
            <NumberText
              color="plain"
              size="sm"
              value={formatAmount(new BigNumber(receiveToken).shiftedBy(-12), 12)}
              unit="Pool Token"
            />
          }
        />
      </div>

      <Button label={button.text} status={button.status} onClick={deposit} className="w-full mt-6" />
    </div>
  );
});

export default Deposit;
