import CoinList from 'components/coins/CoinList';
import { LogoStacking } from 'components/coins/constants';
import Icon, { IconSize, IconType } from 'components/icon';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { InfoAsset } from 'types/asset';

type CheckboxColor = 'primary' | 'liquid';
type CheckboxStyle = 'plain' | 'secondary' | 'outline';
type CheckedKey = 'checked' | 'unchecked';

const ICON_TYPES_DICT: { [key in CheckedKey]: IconType } = { checked: 'checked', unchecked: 'unchecked' };

const CHECKBOX_LABEL_TYPO_DICT: { [key in CheckboxStyle]: string } = {
  plain: 'font_caption_m',
  secondary: 'font_caption_s',
  outline: 'font_label_s',
};

const CHECKBOX_CONTAINER_COLOR_DICT: {
  [key in CheckboxColor]: { [key in CheckboxStyle]: { [key in CheckedKey]: string } };
} = {
  primary: {
    plain: { checked: '', unchecked: '' },
    secondary: { checked: '', unchecked: '' },
    outline: {
      checked: 'border border-primary hover:bg-primary_o24',
      unchecked: 'border border-semantic_gray hover:bg-semantic_gray_o24',
    },
  },
  liquid: {
    plain: { checked: '', unchecked: '' },
    secondary: { checked: '', unchecked: '' },
    outline: {
      checked: 'border border-liquid hover:bg-liquid_o24',
      unchecked: 'border border-semantic_gray hover:bg-semantic_gray_o24',
    },
  },
};

const CHECKBOX_LABEL_COLOR_DICT: {
  [key in CheckboxColor]: { [key in CheckboxStyle]: { [key in CheckedKey]: string } };
} = {
  primary: {
    plain: { checked: 'text-on_surface_variant_light', unchecked: 'text-on_surface_variant_light' },
    secondary: { checked: 'text-on_surface_variant_light', unchecked: 'text-on_surface_variant_light' },
    outline: { checked: 'text-primary', unchecked: 'text-semantic_gray' },
  },
  liquid: {
    plain: { checked: 'text-on_surface_variant_light', unchecked: 'text-on_surface_variant_light' },
    secondary: { checked: 'text-on_surface_variant_light', unchecked: 'text-on_surface_variant_light' },
    outline: { checked: 'text-liquid', unchecked: 'text-semantic_gray' },
  },
};

const CHECKBOX_ICON_COLOR_DICT: { [key in CheckboxColor]: { [key in CheckedKey]: string } } = {
  primary: { checked: 'text-primary', unchecked: 'text-semantic_gray' },
  liquid: { checked: 'text-liquid', unchecked: 'text-semantic_gray' },
};

const CHECKBOX_ICON_SIZE_DICT: { [key in CheckboxStyle]: IconSize } = {
  plain: '16px',
  secondary: '16px',
  outline: '16px',
};

type CheckboxProps = {
  color?: CheckboxColor;
  type?: CheckboxStyle;
  isChecked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  coins?: InfoAsset[];
  onChange?: (checked: boolean) => void;
  className?: string;
};

const Checkbox = ({
  color = 'primary',
  type = 'plain',
  isChecked,
  defaultChecked = false,
  label,
  coins = [],
  onChange,
  className = '',
}: CheckboxProps) => {
  /** work */
  const [checked, setChecked] = useState<boolean>(defaultChecked);
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isChecked !== undefined) {
      setChecked(isChecked);
      checkboxRef.current?.setAttribute('checked', `${isChecked}`);
    }
  }, [isChecked]);

  const checkedKey = useMemo<CheckedKey>(() => (checked ? 'checked' : 'unchecked'), [checked]);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange?.(newChecked);
  };

  /** secondary */
  const orderClassName = useMemo<string>(() => (type === 'secondary' ? 'order-1' : ''), [type]);

  const containerClassName = useMemo<string>(
    () => (type === 'outline' ? `rounded-4px pl-2 pr-[0.625rem] py-[0.375rem]` : ''),
    [type]
  );

  return (
    <label
      htmlFor={label}
      className={`${className} relative inline-flex items-start justify-center cursor-pointer transition-opacity hover:opacity-80 gap-x-1 ${CHECKBOX_CONTAINER_COLOR_DICT[color][type][checkedKey]} ${containerClassName}`}
    >
      <div
        className={`overflow-hidden flex items-center justify-center ${
          type === 'outline' ? 'invisible absolute' : 'relative'
        } ${orderClassName}`}
      >
        <Icon
          size={CHECKBOX_ICON_SIZE_DICT[type]}
          type={ICON_TYPES_DICT[checkedKey]}
          className={`${CHECKBOX_ICON_COLOR_DICT[color][checkedKey]}`}
        />
        <input
          ref={checkboxRef}
          type="checkbox"
          id={label}
          className="opacity-0 block absolute w-4 h-4 cursor-pointer"
          checked={checked}
          onChange={handleChange}
        />
      </div>

      <div
        className={`flex items-center gap-x-1 !leading-4 ${CHECKBOX_LABEL_TYPO_DICT[type]} ${CHECKBOX_LABEL_COLOR_DICT[color][type][checkedKey]}`}
      >
        {coins.length > 0 && (
          <div>
            {
              <CoinList
                assets={coins}
                size="16px"
                className={checked ? '' : 'opacity-70'}
                stacking={LogoStacking.FIRST_ON_TOP}
              />
            }
          </div>
        )}
        <span>{label}</span>
      </div>
    </label>
  );
};

export default Checkbox;
