import MooncatConfettiSvg from 'resources/svgs/mooncat_confetti.svg';

/** @desc toast ui modules */
export const ToastContentLabel = ({ label }: { label: string }) => {
  return <div className="font_caption_s text-on_surface_variant_light">{label}</div>;
};

export const ToastContentItem = ({ label, value }: { label: string; value: JSX.Element | string }) => {
  return (
    <div className="flex justify-between items-start gap-x-4">
      <ToastContentLabel label={label} />
      <div className="w-full flex justify-end text-on_surface_variant_light">{value}</div>
    </div>
  );
};

export const MooncatConfetti = ({ className = '' }: { className?: string }) => {
  return <img src={MooncatConfettiSvg} alt="Mooncat with Confetti" className={`w-[86px] h-[86px] ${className}`} />;
};
