import WalletConnect from '@walletconnect/client';
import MobileAutoConnectModal from 'components/modals/MobileAutoConnectModal';
import { isAndroid, isMobile } from '@walletconnect/browser-utils';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { payloadId } from '@walletconnect/utils';
import { WalletConnectWallet, WalletType } from 'types/wallet';
import { INITIAL_ASSET } from 'COMMON_VARIABLES';

export const getWCAccounts = (connector: WalletConnect, chainId?: string) =>
  connector.sendCustomRequest({
    id: payloadId(),
    jsonrpc: '2.0',
    method: 'cosmostation_wc_accounts_v1',
    params: [chainId ?? INITIAL_ASSET.chainId],
  });

export const getWCWallet = async (connector: WalletConnect, chainId?: string): Promise<WalletConnectWallet | null> => {
  const accounts = await getWCAccounts(connector, chainId);

  if (accounts[0]) {
    return {
      type: WalletType.WALLETCONNECT,
      name: accounts[0].name,
      isActive: true,
      address: accounts[0].bech32Address,
      pubkey: accounts[0].pubKey,
      connector,
    };
  }

  return null;
};

const disconnectWC = (connector: WalletConnect) => connector.killSession();

export const connectWC = async (): Promise<WalletConnectWallet | null> => {
  const connector = new WalletConnect({
    bridge: 'https://bridge.walletconnect.org',
    signingMethods: ['cosmostation_wc_accounts_v1', 'cosmostation_wc_sign_tx_v1'],
    qrcodeModal: isMobile() ? MobileAutoConnectModal : QRCodeModal,
  });

  try {
    await disconnectWC(connector);
  } catch (e) {}

  if (connector.connected) {
    try {
      await disconnectWC(connector);
    } catch (e) {}
  }

  if (connector.connected) {
    const connectedWallet = await getWCWallet(connector);
    return connectedWallet;
  } else {
    await connector.createSession();

    return new Promise(async (resolve: (value: WalletConnectWallet | null) => void) => {
      connector.on('connect', async (error) => {
        if (error) resolve(null);

        const connectedWallet = await getWCWallet(connector);
        resolve(connectedWallet);
      });

      connector.on('disconnect', async () => await disconnectWC(connector));
    });
  }
};

export const installWCCosmostation = () => {
  const cosmostationHref = isAndroid()
    ? `intent://dapp?${window.location.href}/#Intent;package=wannabit.io.cosmostaion;scheme=cosmostation;end;`
    : `cosmostation://dapp?${window.location.href}`;
  window.location.href = cosmostationHref;
};
