import Icon from 'components/icon';
import { getDropdownCursor } from '../styles';

/** @desc DropDownPopoverButton */
const DropDownPopoverButton = <T, K>({
  open,
  selectable,
  disabled,
  label,
  labelClassName = '',
}: {
  open: boolean;
  selectable: boolean;
  disabled: boolean;
  label?: K | string | JSX.Element;
  labelClassName?: string;
}) => {
  return (
    <div
      className={`relative self-stretch flex justify-between items-center gap-x-1 px-1 py-[0.625rem] max-h-[1.25rem] transition-all rounded-100px overflow-hidden ${getDropdownCursor(
        selectable,
        disabled
      )} ${selectable && !disabled ? 'text-primary hover:bg-primary_o24' : 'text-on_surface'}`}
    >
      <div className={`font_label_s ${labelClassName}`}>{label}</div>
      {selectable && <Icon size="16px" type={open ? 'arrow-drop-up' : 'arrow-drop-down'} />}
    </div>
  );
};

export default DropDownPopoverButton;
