import { useMainStore } from 'provider/mainContext';
import { observer } from 'mobx-react-lite';

import Portfolio from './Portfolio';
import PageTitleArea from 'components/layout/PageTitleArea';
import Panel from 'components/panel/Panel';
import Button from 'components/buttons/Button';
import { EMPTY_TEXTS } from 'COMMON_VARIABLES';

const PortfolioPage = observer(() => {
  const mainStore = useMainStore();

  return (
    <div className="pb-20 md:pb-40">
      <PageTitleArea title="Portfolio" />

      <main role="main" className="relative w-full max-w-content m-auto pt-5 md:pt-[3rem] px-4 md:px-0">
        {mainStore.wallet.isActive && mainStore.wallet.address ? (
          <Portfolio />
        ) : (
          <Panel className="box_glow_l">
            <div className="h-[240px] flex flex-col items-center justify-center gap-y-8">
              <div className="text-on_surface_variant_light font_body_m">{EMPTY_TEXTS.PORTFOLIO}</div>
              <Button label="Connect Wallet" type="filled" onClick={() => (mainStore.isConnectModalOpen = true)} />
            </div>
          </Panel>
        )}
      </main>
    </div>
  );
});

export default PortfolioPage;
