type FootPrintSvgProps = {
  className?: string;
};

const FootPrintSvg = ({ className = 'text-[#999]' }: FootPrintSvgProps) => {
  return (
    <svg
      width="30"
      height="35.625"
      viewBox="0 0 32 38"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7579 19.5585C13.1985 21.5505 11.5334 22.8787 9.22966 23.3882C6.92593 23.8977 4.85617 23.3966 4.41558 21.4046C3.97499 19.4126 4.75925 16.9524 7.66446 16.3101C10.5697 15.6678 12.3174 17.5665 12.7579 19.5585Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="7.02914"
        cy="13.6699"
        rx="1.67616"
        ry="1.62029"
        transform="rotate(77.5281 7.02914 13.6699)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="3.29098"
        cy="16.3876"
        rx="1.67616"
        ry="1.62029"
        transform="rotate(62.5281 3.29098 16.3876)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="1.67616"
        cy="1.62029"
        rx="1.67616"
        ry="1.62029"
        transform="matrix(0.0441097 -0.999027 -0.999027 -0.0441097 13.1685 16.3431)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path
        d="M26.9424 11.6556C26.328 13.601 24.2218 13.9186 21.972 13.2081C19.7221 12.4975 18.1802 11.0286 18.7947 9.08315C19.4091 7.13774 21.3184 5.39926 24.1555 6.29565C26.9927 7.19205 27.5568 9.71019 26.9424 11.6556Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="24.9243"
        cy="3.6936"
        rx="1.67616"
        ry="1.62029"
        transform="rotate(107.528 24.9243 3.6936)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="20.3285"
        cy="4.17667"
        rx="1.67616"
        ry="1.62029"
        transform="rotate(92.5281 20.3285 4.17667)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="1.67616"
        cy="1.62029"
        rx="1.67616"
        ry="1.62029"
        transform="matrix(0.537713 -0.843128 -0.843128 -0.537713 28.9044 9.07709)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path
        d="M26.0532 34.5702C25.4388 36.5156 23.3326 36.8332 21.0828 36.1226C18.8329 35.412 17.291 33.9431 17.9055 31.9977C18.5199 30.0523 20.4292 28.3138 23.2663 29.2102C26.1034 30.1066 26.6676 32.6247 26.0532 34.5702Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="24.035"
        cy="26.6084"
        rx="1.67616"
        ry="1.62029"
        transform="rotate(107.528 24.035 26.6084)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="19.4395"
        cy="27.0912"
        rx="1.67616"
        ry="1.62029"
        transform="rotate(92.5281 19.4395 27.0912)"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <ellipse
        cx="1.67616"
        cy="1.62029"
        rx="1.67616"
        ry="1.62029"
        transform="matrix(0.537713 -0.843128 -0.843128 -0.537713 28.0154 31.9919)"
        fill="currentColor"
        fillOpacity="0.1"
      />
    </svg>
  );
};

export default FootPrintSvg;
