import Button from 'components/buttons/Button';
import useTradePairSelect from 'components/orderbook/hooks/useTradePairSelect';
import OrderbookChart from 'components/orderbook/orderbookChartWidget/OrderbookChart';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useNavigateToDesktopTrade from './useNavigateToDesktopTrade';
import TradeHeader from 'components/trade/TradeHeader';
import useViewportResize from 'hooks/useViewportResize';

const TradeIntro = () => {
  useNavigateToDesktopTrade('orderbook');

  const { selectedOrderbookPair, orderbookPairs, selectedPairId, onSelectPairId } = useTradePairSelect();

  const navigate = useNavigate();
  const location = useLocation();

  const onClickTrade = useCallback(() => {
    navigate(`/trading${location.search ?? ''}`);
  }, [location.search]);

  const { viewportHeight } = useViewportResize();

  return (
    <main
      role="main"
      className={`relative w-full px-3 py-5 m-auto flex flex-col gap-y-4`}
      style={{ height: `calc(${viewportHeight}px - var(--height-navbar) - var(--height-bottombar))` }}
    >
      {selectedOrderbookPair && (
        <TradeHeader pair={selectedOrderbookPair} availabePairs={orderbookPairs} onSelectPair={onSelectPairId} />
      )}

      <div className="grow shrink h-[calc(100%_-_206px)] px-2">
        <OrderbookChart pairId={selectedPairId} />
      </div>

      <div className="px-2">
        <Button
          label="Trade"
          type="neon"
          className="w-full"
          status={selectedPairId ? 'enabled' : 'disabled'}
          onClick={onClickTrade}
        />
      </div>
    </main>
  );
};

export default TradeIntro;
