import { EncodeObject } from '@cosmjs/proto-signing';
import { MsgLiquidStake } from '../crescent/liquidstaking/types/crescent/liquidstaking/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';
import createClaimMsg from './Claim';

const createLiquidStakeMsg = (txData: any, creAddress: any) => {
  const liquidStakeData: MsgLiquidStake = {
    delegatorAddress: creAddress,
    amount: txData.stakingCoin,
  };
  const msg = msgEncoder.msgLiquidStake(liquidStakeData);
  const msgs: EncodeObject[] = [msg];

  return msgs;
};

export default createLiquidStakeMsg;
