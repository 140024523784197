import { Response } from './format';
import BigNumber from 'bignumber.js';
import { TokenTypes } from 'components/constants/token';
import { PoolDetail } from './pool';
import { PairDetail } from './pair';

export type AssetTransferChain = { chain_id: string; ibc_denom: string };

// INFO Types
export interface InfoAsset {
  readonly denom: string;
  readonly ticker: string;
  readonly chainId: string;
  readonly logoUrl: string;
  readonly baseDenom: string;
  readonly chainName: string;
  readonly exponent: number;
  /** @caution valid only for lf tokens */
  readonly chains?: AssetTransferChain[];
  readonly isBeta?: boolean;
}

export interface AssetInfos extends Response<InfoAsset> {}
export interface InfoAssetWithMyAmount extends InfoAsset {
  balance: BigNumber;
  priceOracle: BigNumber;
  tokenType: TokenTypes;
  originPool?: PoolDetail;
}

// Live Types
export interface LiveAsset {
  readonly denom: string;
  readonly priceOracle: number;
  readonly updateTimestamp: number;
}
export interface AssetLives extends Response<LiveAsset> {}

export interface CoinRaw {
  denom: string;
  amount: string;
}
export type Coin = {
  denom: string;
  amount: BigNumber;
};

export type CoinDetail = {
  asset: InfoAsset;
  amount: BigNumber;
  amountUSD: BigNumber;
};

export type AssetDetail = InfoAsset & {
  availableBalance: BigNumber;
  reservedBalance: BigNumber;
  priceOracle: BigNumber;
  pairs: PairDetail[];
  tokenType: TokenTypes;
  originPool?: PoolDetail;
};

/** @desc type guard */
export function isCoinDetail(coin: {
  asset: InfoAsset | undefined;
  amount: BigNumber;
  amountUSD: BigNumber;
}): coin is CoinDetail {
  return coin.asset !== undefined;
}

/** @desc util type */
export type TickerAnatomy = { ticker: string; bridgeFix: string };
