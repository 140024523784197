import { MsgStake } from '../crescent/farming/types/crescent/farming/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createStakeMsg = (txData: any, creAddress: any) => {
  const farmData: MsgStake = {
    farmer: creAddress,
    stakingCoins: txData.farmingCoin,
  };
  console.log('farmData', farmData);
  const msg = msgEncoder.msgStake(farmData);
  return [msg];
};

export default createStakeMsg;
