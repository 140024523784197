import BigNumber from 'bignumber.js';
import { useCallback, useMemo, useState } from 'react';
import type { InfoAsset } from 'types/asset';
import useOrderbookData from './useOrderbookData';

type UseSwapProps = { pairId: number | undefined; payAssetData: InfoAsset | undefined; payAmount: string };

const useSwap = ({ pairId, payAssetData, payAmount }: UseSwapProps) => {
  const { selectedOrderbookPair: orderbookPair, orderbook } = useOrderbookData(pairId?.toString() ?? '', 3);

  const orderablePrice = useMemo<{ max: string; min: string } | undefined>(
    () =>
      orderbookPair
        ? {
            max: new BigNumber(orderbookPair.lastPrice)
              .shiftedBy(orderbookPair.exponentDiff)
              .multipliedBy(1.1)
              .toString(),
            min: new BigNumber(orderbookPair.lastPrice)
              .shiftedBy(orderbookPair.exponentDiff)
              .multipliedBy(0.9)
              .toString(),
          }
        : undefined,
    [orderbookPair]
  );

  const [isFinalOrderbookOver, setIsFinalOrderbookOver] = useState(false);

  const isSell = useMemo<boolean>(
    () => pairId !== undefined && orderbookPair?.baseDenom === payAssetData?.denom,
    [pairId, orderbookPair?.baseDenom, payAssetData]
  );

  // HELPER
  const getInstantPrice = useCallback(
    (amount, isSell) => {
      setIsFinalOrderbookOver(false);
      let _finalPrice = '';
      let _counterAssetAmount: any = new BigNumber(0);
      let _isOver = false;
      if (orderbookPair && amount && orderbook?.orderbook?.order_books) {
        const orderbooks = JSON.parse(JSON.stringify(orderbook?.orderbook?.order_books));
        if (!orderbooks) return;

        const baseExponent = orderbookPair.baseInfo.exponent;
        const exponentDiff = orderbookPair.exponentDiff;
        orderbooks.forEach((orders, index) => {
          if (_isOver || index === 0) {
            const ob = orders?.[isSell ? 'buys' : 'sells'];
            if (!isSell) {
              ob.reverse();
            }
            let total: any = new BigNumber(0);
            _counterAssetAmount = new BigNumber(0);
            const isOver = ob.every((order) => {
              if (!isSell) {
                const _temp = new BigNumber(order.pool_order_amount)
                  .plus(order.user_order_amount)
                  .shiftedBy(-baseExponent);
                total = new BigNumber(_temp).multipliedBy(order.price).plus(total);
                _counterAssetAmount = _counterAssetAmount.plus(_temp);
              } else {
                total = new BigNumber(order.pool_order_amount)
                  .plus(order.user_order_amount)
                  .shiftedBy(-baseExponent)
                  .plus(total);
                _counterAssetAmount = _counterAssetAmount.plus(
                  new BigNumber(order.pool_order_amount)
                    .plus(order.user_order_amount)
                    .shiftedBy(-baseExponent)
                    .multipliedBy(order.price)
                );
              }
              _finalPrice = new BigNumber(parseFloat(order.price)).shiftedBy(exponentDiff).toString();
              if (total?.gte(amount)) {
                return false;
              } else {
                return true;
              }
            });
            _isOver = isOver;
          }
        });

        if (_isOver && orderablePrice) {
          setIsFinalOrderbookOver(true);
          if (isSell) {
            if (new BigNumber(_finalPrice).lt(orderablePrice?.min)) {
              _finalPrice = orderablePrice?.min;
            }
          } else {
            if (new BigNumber(_finalPrice).gt(orderablePrice?.max)) {
              _finalPrice = orderablePrice?.max;
            }
          }
        }
      }
      return _finalPrice;
    },
    [orderablePrice, orderbook, orderbookPair]
  );

  const price = useMemo<BigNumber | undefined>(() => {
    if (orderbookPair === undefined) return undefined;

    if (!Number(payAmount) || Number.isNaN(Number(payAmount))) {
      return new BigNumber(orderbookPair.predPrice).shiftedBy(orderbookPair.exponentDiff);
    }

    const instantPrice = getInstantPrice(
      new BigNumber(payAmount).shiftedBy(isSell ? 0 : -orderbookPair.exponentDiff).toString(),
      isSell
    );

    return instantPrice ? new BigNumber(instantPrice) : undefined;
  }, [orderbookPair, getInstantPrice, payAmount, isSell]);

  const expectedSlippage = useMemo<number>(() => {
    if (orderbookPair === undefined || price === undefined || price.isNaN()) return 0;

    return price
      .shiftedBy(-orderbookPair.exponentDiff)
      .dividedBy(orderbookPair.lastPrice)
      .minus(1)
      .multipliedBy(100)
      .abs()
      .decimalPlaces(6, 1)
      .toNumber();
  }, [orderbookPair, price]);

  return {
    orderbookPair,
    isFinalOrderbookOver,
    isSell,
    price,
    expectedSlippage,
  };
};

export default useSwap;
