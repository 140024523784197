// hooks
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useToggle } from 'ahooks';
import { usePoolFinder } from 'hooks/usePoolFinder';
import { Observer } from 'mobx-react-lite';
import { useMainStore } from 'provider/mainContext';
import useTxClient from 'hooks/useTxClient';
import { formatAmount, getFractionDigitsLimitedNumber, getValidDigitNumber } from 'common/utils';
import type { InfoAsset } from 'types/asset';
import { INITIAL_ASSET, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import BigNumber from 'bignumber.js';
import useTailwind from 'hooks/useTailwind';
import useTradeSearch from 'hooks/trade/useTradeSearch';
import useSwap from 'hooks/useSwap';
import Widget from './components/Widget';
import SwapInputPair from 'components/swap/SwapInputPair';
import LabeledData from 'components/texts/LabeledData';
import Divider from 'components/divider/Divider';
import Button from 'components/buttons/Button';
import NumberText from 'components/texts/NumberText';
import Formula from 'components/texts/Formula';
import { useLocation } from 'react-router-dom';

// helpers
function onlyNumberValueUpdater(value: string, func: (value: string) => void, exponent: number) {
  const decimalLimitedValue = getFractionDigitsLimitedNumber(value, exponent);
  func(decimalLimitedValue ?? value);
}

const SwapWidget = () => {
  const mainStore = useMainStore();

  const [payAssetData, setPayAssetData] = useState<InfoAsset>(INITIAL_ASSET);
  const [receiveAssetData, setReceiveAssetData] = useState<InfoAsset>({
    denom: '',
    baseDenom: '',
    chainId: '',
    ticker: '',
    logoUrl: '',
    exponent: 0,
    chainName: '',
    chains: [],
  });

  const [payAmount, setPayAmount] = useState<string>('');
  const [receiveAmount, setReceiveAmount] = useState<string>('');
  const [isSwitchChecked, setIsSwitchChecked] = useState(true);
  const [isExchangeRateForward, { toggle: setIsExchangeRateForward }] = useToggle(true);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const { selectedPair } = usePoolFinder(payAssetData, receiveAssetData);

  const [buttonText, setButtonText] = useState<string>('Select a token');
  const [buttonStatus, setButtonStatus] = useState<'active' | 'disabled' | 'loading' | 'warn'>('disabled');
  const [tippyContent, setTippyContent] = useState('');
  const { signAndBroadcast } = useTxClient();
  const [lastAmountChangeDirection, setLastAmountChangeDirection] = useState(0); // 0: pay 1:receive

  /** @desc swap pair, price and slippage */
  const { orderbookPair, isFinalOrderbookOver, price, isSell, expectedSlippage } = useSwap({
    pairId: selectedPair?.pairId,
    payAssetData,
    payAmount,
  });

  const payAmountChangeHandler = useCallback(
    (value: string) => {
      // console.log('price', price);
      if (orderbookPair && selectedPair && !isSwapLoading && price?.gt(0)) {
        if (isNaN(Number(value)) || value.includes(' ')) return;

        const payInputAmount = new BigNumber(value)
          .shiftedBy(payAssetData.exponent)
          .decimalPlaces(0, BigNumber.ROUND_CEIL);
        const priceBydirection = getValidDigitNumber(
          isSell
            ? price.shiftedBy(-orderbookPair.exponentDiff).toNumber()
            : new BigNumber(1).dividedBy(price).shiftedBy(orderbookPair.exponentDiff).toNumber(),
          5
        );
        const expectedReceiveAmount = payInputAmount
          .multipliedBy(priceBydirection)
          .decimalPlaces(0, BigNumber.ROUND_FLOOR)
          .toString();

        onlyNumberValueUpdater(
          value ? new BigNumber(expectedReceiveAmount).shiftedBy(-receiveAssetData.exponent).toString() : '',
          setReceiveAmount,
          receiveAssetData.exponent
        );
        onlyNumberValueUpdater(value, setPayAmount, payAssetData.exponent);
        // const reserveAmountX = isSell
        //   ? new BigNumber(selectedPair?.totalReserved[0].amount)
        //   : new BigNumber(selectedPair?.totalReserved[1].amount);
        // const reserveAmountY = isSell
        //   ? new BigNumber(selectedPair?.totalReserved[1].amount)
        //   : new BigNumber(selectedPair?.totalReserved[0].amount);
        // const expectedReceiveAmount = reserveAmountY.minus(
        //   reserveAmountX.multipliedBy(reserveAmountY).dividedBy(reserveAmountX.plus(payInputAmount))
        // );
        // const slippage = new BigNumber(payInputAmount)
        //   .shiftedBy(2)
        //   .dividedBy(
        //     new BigNumber(payInputAmount).plus(
        //       payAssetData.denom === selectedPair.totalReserved[0].denom
        //         ? selectedPair.totalReserved[0].amount
        //         : selectedPair.totalReserved[1].amount
        //     )
        //   )
        //   .decimalPlaces(4, 1)
        //   .toNumber();

        // if (slippage < 99.99 || value === '0' || value === '') {
        //   onlyNumberValueUpdater(
        //     value ? expectedReceiveAmount.decimalPlaces(0).shiftedBy(-receiveAssetData.exponent).toString() : '',
        //     setReceiveAmount,
        //     receiveAssetData.exponent
        //   );
        //   onlyNumberValueUpdater(value, setPayAmount, payAssetData.exponent);
        // }
      } else if (!isSwapLoading && !selectedPair?.pairId && payAssetData?.denom) {
        onlyNumberValueUpdater(value, setPayAmount, payAssetData.exponent);
      }
      setLastAmountChangeDirection(0);
    },
    [
      orderbookPair,
      isSell,
      isSwapLoading,
      payAssetData?.denom,
      payAssetData.exponent,
      price,
      receiveAssetData.exponent,
      selectedPair,
    ]
  );

  // set button text
  useEffect(() => {
    if (isSwapLoading) {
      setButtonStatus('loading');
    } else {
      if (selectedPair?.pairId) {
        let rawExpectedSlippage = 0;
        if (payAmount && selectedPair) {
          rawExpectedSlippage = new BigNumber(payAmount)
            .shiftedBy(payAssetData.exponent + 2)
            .dividedBy(
              new BigNumber(payAmount)
                .shiftedBy(payAssetData.exponent)
                .plus(
                  payAssetData.denom === selectedPair.totalReserved[0].denom
                    ? selectedPair.totalReserved[0].amount
                    : selectedPair.totalReserved[1].amount
                )
            )
            .toNumber();
        }
        const isSlippageLimitOver = false;
        // expectedSlippage > new BigNumber(1).minus(slippage).abs().multipliedBy(100).toNumber();

        setTippyContent('');
        if (
          expectedSlippage > 99.9 ||
          price === undefined ||
          (rawExpectedSlippage === 0 && receiveAmount !== '0' && receiveAmount !== '')
        ) {
          setButtonStatus('disabled');
          setButtonText(`Insufficient liquidity`);
          setTippyContent('Insufficient liquidity for this trade.');
          return;
        }
        if (payAmount !== '' && parseFloat(payAmount) !== 0) {
          setTippyContent('');
          if (Number(payAmount) < 0.000001 || Number(receiveAmount) === 0) {
            setButtonStatus('disabled');
            setButtonText(`Increase the amount`);
          } else {
            const payAssetUserBalance = mainStore?.balanceData?.[payAssetData.denom];
            if (
              !new BigNumber(payAmount).shiftedBy(payAssetData.exponent).isLessThanOrEqualTo(payAssetUserBalance ?? 0)
            ) {
              setButtonStatus('disabled');
              setButtonText(`Insufficient ${payAssetData.ticker} balance`);
            } else if (isSlippageLimitOver) {
              setButtonStatus('warn');
              setButtonText('Swap Anyway');
              setTippyContent(
                'This swap tx has a high probability of failure due to slippage limit(top-right setting).'
              );
            } else {
              setButtonStatus('active');
              setButtonText('Swap');
            }
          }
        } else {
          setButtonStatus('disabled');
          setButtonText('Enter Amount');
        }
      } else if (payAssetData.ticker && receiveAssetData.ticker) {
        setButtonStatus('disabled');
        setButtonText('No pool for this pair');
      } else {
        setButtonStatus('disabled');
        setButtonText('Select a token');
      }
    }
  }, [
    selectedPair?.pairId,
    payAmount,
    receiveAmount,
    payAssetData.ticker,
    receiveAssetData.ticker,
    isSwapLoading,
    expectedSlippage,
    mainStore.wallet.isActive,
    mainStore?.balanceData,
    payAssetData.denom,
    payAssetData.exponent,
    selectedPair,
  ]);

  const PriceByDirection = useMemo(() => {
    if (orderbookPair && price) {
      const result = isSell
        ? price.shiftedBy(orderbookPair.exponentDiff)
        : new BigNumber(1).dividedBy(price).shiftedBy(-orderbookPair.exponentDiff);

      return getValidDigitNumber(result.toNumber(), 5);
    } else {
      return 1;
    }
  }, [orderbookPair, isSell, price]);

  // undefined pairId handler
  useEffect(() => {
    if (!selectedPair?.pairId) {
      setPayAmount('');
      setReceiveAmount('');
    }
  }, [selectedPair]);

  // update amount when the last price change
  useEffect(() => {
    //@ts-ignore
    if (lastAmountChangeDirection === 0) {
      payAmountChangeHandler(payAmount);
    } else {
      receiveAmountChangeHandler(receiveAmount);
    }

    if (price === undefined) {
      setReceiveAmount('');
    }
  }, [price]);

  useEffect(() => {
    if (!isSwitchChecked) {
      receiveAmountChangeHandler(receiveAmount);
    }
    setIsSwitchChecked(true);
  }, [isSwitchChecked]);

  // FUNCTIONS
  function receiveAmountChangeHandler(value: string) {
    if (isNaN(Number(value)) || value.includes(' ')) return;
    if (orderbookPair && !isSwapLoading && price?.gt(0)) {
      const receiveInputAmount = new BigNumber(value)
        .shiftedBy(receiveAssetData.exponent)
        .decimalPlaces(0, BigNumber.ROUND_FLOOR);
      const priceBydirection = getValidDigitNumber(
        !isSell
          ? price?.shiftedBy(-orderbookPair.exponentDiff).toNumber()
          : new BigNumber(1).dividedBy(price).shiftedBy(orderbookPair.exponentDiff).toNumber(),
        5
      );
      const expectedPayAmount = receiveInputAmount
        .multipliedBy(priceBydirection)
        .decimalPlaces(0, BigNumber.ROUND_CEIL)
        .toString();
      onlyNumberValueUpdater(
        value ? new BigNumber(expectedPayAmount).shiftedBy(-payAssetData.exponent).toString() : '',
        setPayAmount,
        payAssetData.exponent
      );
      onlyNumberValueUpdater(value, setReceiveAmount, receiveAssetData.exponent);
      // const slippage = new BigNumber(expectedPayAmount)
      //   .shiftedBy(2)
      //   .dividedBy(
      //     new BigNumber(expectedPayAmount).plus(
      //       payAssetData.denom === selectedPair.totalReserved[0].denom
      //         ? selectedPair.totalReserved[0].amount
      //         : selectedPair.totalReserved[1].amount
      //     )
      //   )
      //   .decimalPlaces(4, 1)
      //   .toNumber();
      // console.log('slp', slippage);
      // if (slippage < 99.99 || value === '0' || value === '') {
      // onlyNumberValueUpdater(
      //   value ? expectedPayAmount.decimalPlaces(0).shiftedBy(-payAssetData.exponent).toString() : '',
      //   setPayAmount,
      //   payAssetData.exponent
      // );
      // onlyNumberValueUpdater(value, setReceiveAmount, receiveAssetData.exponent);
      // }
    } else {
      if (!isSwapLoading && !selectedPair?.pairId && receiveAssetData?.denom) {
        onlyNumberValueUpdater(value, setReceiveAmount, receiveAssetData.exponent);
      }
    }
    setLastAmountChangeDirection(1);
  }

  /** @desc orderbook pairId sync; only under MobileDiv(min-width: 768px); */
  const { getIsMobile } = useTailwind();

  useEffect(() => {
    const orderbookPairId = localStorage.getItem('orderbookPair') ?? undefined;
    const orderbookPairDirection = mainStore.getOrderbookPairDirection();
    if (getIsMobile() && orderbookPairId !== undefined) {
      // setAssetsByPairId(orderbookPairId, orderbookPairDirection);
      const pair = mainStore.getPairs().find((pair) => pair.pairId === Number(orderbookPairId));
      if (pair !== undefined) {
        const isForward = orderbookPairDirection === 'forward';
        setPayAssetData(isForward ? pair.assets[1] : pair.assets[0]);
        setReceiveAssetData(isForward ? pair.assets[0] : pair.assets[1]);
      }
    }
  }, []);

  /** @desc change & read search params, set store state */
  const { navigateAndStorePair, getTradeAssetsFromLocationSearch } = useTradeSearch(mainStore);
  const location = useLocation();

  useEffect(() => {
    const pair =
      mainStore.getPairs().find((pair) => {
        return (
          pair.assets.filter((asset) => [payAssetData.denom, receiveAssetData.denom].includes(asset.denom)).length === 2
        );
      }) ?? null;

    navigateAndStorePair({
      path: location.pathname,
      pair,
      isForward: pair?.assets[1].denom === payAssetData.denom,
      doNotStore: !getIsMobile(),
    });
  }, [payAssetData.denom, receiveAssetData.denom]);

  useEffect(() => {
    if (mainStore.assetsData.isInit && mainStore.pairsData.isInit) {
      const [payAsset, receiveAsset] = getTradeAssetsFromLocationSearch();
      if (payAsset) setPayAssetData(payAsset);
      if (receiveAsset) setReceiveAssetData(receiveAsset);
    }
  }, [mainStore.assetsData.isInit, mainStore.pairsData.isInit]);

  function switchTokens() {
    if (!isSwapLoading) {
      setPayAmount('');
      setReceiveAmount(payAmount);
      const originPayAssetData = JSON.parse(JSON.stringify(payAssetData));
      const originReceiveAssetData = JSON.parse(JSON.stringify(receiveAssetData));
      setPayAssetData(originReceiveAssetData);
      setReceiveAssetData(originPayAssetData);
      setIsSwitchChecked(false);
    }
  }

  async function swap() {
    if (mainStore.chainsData.isInit && orderbookPair && price) {
      // console.log('slippage', slippage);
      // console.log('fianl price', new BigNumber(commonInfo.predPrice).multipliedBy(slippage).toNumber());
      // console.log('original price', commonInfo.predPrice);
      // console.log('current price', price);
      // console.log('old order price', price?.shiftedBy(-commonInfo.exponentDiff).toString());

      setIsSwapLoading(true);
      await signAndBroadcast({
        type: 'swap',
        chainData: mainStore.chainsData,
        txData: {
          selectedPair,
          orderPrice: price.shiftedBy(-orderbookPair.exponentDiff).toString(),
          pay: {
            asset: payAssetData,
            amount: new BigNumber(payAmount).shiftedBy(payAssetData.exponent).decimalPlaces(0).toString(),
          },
          receive: {
            asset: receiveAssetData,
            amount: new BigNumber(receiveAmount)
              // .dividedBy(slippage)
              .shiftedBy(receiveAssetData.exponent)
              .decimalPlaces(0, 1)
              .toString(),
          },
        },
      });
      payAmountChangeHandler('');
      setIsSwapLoading(false);
    } else {
      alert('Error: loading chain data');
    }
  }

  function login() {
    mainStore.isConnectModalOpen = true;
  }

  /** @desc swap details */
  const expectedSlippageString = useMemo<string>(() => {
    if (!(payAmount && selectedPair && expectedSlippage < 99.9)) return '';

    return `${isFinalOrderbookOver ? '>' : ''}${formatAmount(new BigNumber(expectedSlippage), 6)}%`;
  }, [expectedSlippage, isFinalOrderbookOver, payAmount, selectedPair]);

  const minReceivedString = useMemo<string>(() => {
    if (!(receiveAmount && selectedPair?.pairId && expectedSlippage < 99.9 && orderbookPair)) return '';

    return formatAmount(
      new BigNumber(payAmount)
        .decimalPlaces(payAssetData.exponent, 1)
        .multipliedBy(PriceByDirection)
        .shiftedBy(isSell ? -orderbookPair.exponentDiff : orderbookPair.exponentDiff),
      receiveAssetData.exponent
    );
  }, [
    payAmount,
    payAssetData,
    receiveAssetData,
    orderbookPair,
    isSell,
    PriceByDirection,
    expectedSlippage,
    selectedPair,
    receiveAmount,
  ]);

  return (
    <Observer>
      {() => (
        <Widget glow={!mainStore.isMobile} padding={mainStore.isMobile ? '16px' : '20px'}>
          <Widget.Header title="Swap" />
          <Widget.Body>
            <div className="w-full space-y-6">
              <SwapInputPair
                payAsset={payAssetData}
                onSelectPayAsset={setPayAssetData}
                payAmount={payAmount}
                onPayAmountChange={payAmountChangeHandler}
                receiveAsset={receiveAssetData}
                onSelectReceiveAsset={setReceiveAssetData}
                receiveAmount={receiveAmount}
                onReceiveAmountChange={receiveAmountChangeHandler}
                onSwitch={switchTokens}
                disabled={isSwapLoading}
              />

              <div className="space-y-3 md:space-y-4">
                <LabeledData
                  label="Exchange rate"
                  value={
                    <Formula
                      aValue={new BigNumber(1)}
                      aUnit={payAssetData.ticker}
                      bRate={isSell ? price : price ? new BigNumber(1).div(price) : undefined}
                      bUnit={receiveAssetData.ticker}
                      dp={6}
                      seperator={isFinalOrderbookOver ? (isExchangeRateForward ? '≧' : '≦') : '='}
                      isForward={isExchangeRateForward}
                      onSwitchForward={setIsExchangeRateForward}
                    />
                  }
                />

                <Divider />

                <LabeledData
                  label="Expected slippage"
                  labelTooltipContent={TOOLTIP_TEXTS.EXPECTED_SLIPPAGE}
                  value={
                    <NumberText
                      value={expectedSlippageString}
                      status={expectedSlippage >= 3 ? 'error' : expectedSlippage > 1 ? 'warning' : 'normal'}
                      size="sm"
                    />
                  }
                />

                <LabeledData
                  label="Minimum received"
                  labelTooltipContent={TOOLTIP_TEXTS.MINIMUM_RECEIVED}
                  value={<NumberText value={minReceivedString} unit={receiveAssetData.ticker} size="sm" />}
                />

                <LabeledData
                  label="Swap fee"
                  labelTooltipContent={TOOLTIP_TEXTS.SWAP_FEE}
                  value={<div className="font_caption_m text-primary">FREE</div>}
                />
              </div>

              {/** @todo buttonStatus refactoring */}
              <Button
                type="filled"
                size="lg"
                className="w-full"
                label={mainStore.wallet.isActive ? buttonText : 'Connect Wallet'}
                onClick={mainStore.wallet.isActive ? swap : login}
                color={buttonStatus === 'warn' ? 'danger' : 'plain'}
                status={buttonStatus === 'disabled' || buttonStatus === 'loading' ? buttonStatus : undefined}
                tooltipContent={tippyContent.length ? tippyContent : undefined}
              />
            </div>
          </Widget.Body>
        </Widget>
      )}
    </Observer>
  );
};

export default SwapWidget;
