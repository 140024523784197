import { Listbox } from '@headlessui/react';
import { useCallback, useMemo } from 'react';
import DropDownBoxButton from './components/DropDownBoxButton';
import DropDownBoxItem from './components/DropDownBoxItem';
import DropDownPopoverButton from './components/DropDownPopoverButton';
import DropDownPopoverItem from './components/DropDownPopoverItem';
import { DROPDOWN_COLOR_DICT, DROPDOWN_LIST_BORDER_GETTER_DICT, DROPDOWN_LIST_GRID_DICT } from './styles';
import { DropDownLayer, DropDownStyle } from './types';

/** @desc DropDown */
export type DropDownItem<T, K> = {
  value: T;
  label: K;
  selectedLabel?: K;
  labelClassName?: string;
};

type DropDownProps<T, K> = {
  layer: DropDownLayer;
  type?: DropDownStyle;
  items?: DropDownItem<T, K>[];
  selected: DropDownItem<T, K> | undefined;
  onSelect?: (selected: DropDownItem<T, K>) => void;
  disabled?: boolean;
  unselectedLabel?: string | JSX.Element;
  minHeight?: string;
  className?: string;
};

function DropDown<T, K = string | JSX.Element>({
  layer = 'plain',
  type = 'box',
  items = [],
  selected,
  onSelect,
  disabled = false,
  unselectedLabel = 'Unselected',
  minHeight,
  className = '',
}: DropDownProps<T, K>) {
  const selectable = useMemo<boolean>(() => items !== undefined && items.length > 0, [items]);

  const onChange = useCallback(
    (value: DropDownItem<T, K> | undefined) => {
      if (value !== undefined) onSelect?.(value);
    },
    [onSelect]
  );

  const DropDownItem = useMemo(() => (type === 'box' ? DropDownBoxItem : DropDownPopoverItem), [type]);

  return (
    <div className={`grow shrink self-stretch relative rounded-8px ${className}`}>
      <Listbox value={selected} disabled={disabled} onChange={onChange}>
        <Listbox.Button disabled={disabled} className="w-full h-full">
          {({ open, disabled }) =>
            type === 'box' ? (
              <DropDownBoxButton
                layer={layer}
                open={open}
                selectable={selectable}
                disabled={disabled}
                minHeight={minHeight}
                selected={selected}
                label={selected?.selectedLabel ?? selected?.label ?? unselectedLabel}
                labelClassName={selected?.labelClassName}
              />
            ) : (
              <DropDownPopoverButton
                open={open}
                selectable={selectable}
                disabled={disabled}
                label={selected?.selectedLabel ?? selected?.label ?? unselectedLabel}
                labelClassName={selected?.labelClassName}
              />
            )
          }
        </Listbox.Button>

        {selectable && (
          <Listbox.Options
            className={`outline-none absolute right-0 z-10 ${
              type === 'box' ? 'w-full' : 'w-max'
            } max-h-48 overflow-y-auto ${DROPDOWN_LIST_GRID_DICT[type]} ${
              DROPDOWN_COLOR_DICT[layer]
            } ${DROPDOWN_LIST_BORDER_GETTER_DICT[type](layer)} ${type === 'popover' ? 'elevation_dark_4' : ''}`}
          >
            {items.map((item, index: number) => (
              <Listbox.Option key={index} value={item}>
                {({ selected }) => (
                  <DropDownItem selected={selected} label={item.label} className={item.labelClassName} />
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        )}
      </Listbox>
    </div>
  );
}

export default DropDown;
