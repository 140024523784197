import { MsgWithdraw } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createWithdrawMsg = (txData: any, creAddress: any) => {
  const poolWithdrawData: MsgWithdraw = {
    withdrawer: creAddress,
    poolId: txData.poolId,
    poolCoin: txData.poolCoin,
  };
  const msg = msgEncoder.msgWithdraw(poolWithdrawData);
  return [msg];
};

export default createWithdrawMsg;
