import CosmostationLogoPng from 'resources/logos/cosmostation-logo.png';
import LeapLogoPng from 'resources/logos/leap-logo.png';
import KeplrLogoSvg from 'resources/logos/keplr-logo.svg';

export type WalletLogoType = 'keplr' | 'cosmostation' | 'leap';
type WalletLogoSize = 'md';

const IMG_SRC_DICT: { [key in WalletLogoType]: string } = {
  keplr: KeplrLogoSvg,
  leap: LeapLogoPng,
  cosmostation: CosmostationLogoPng,
};

const SIZE_DICT: { [key in WalletLogoSize]: string } = {
  md: 'w-5 h-5',
};

type WalletLogoProps = {
  type: WalletLogoType;
  size?: WalletLogoSize;
};

const WalletLogo = ({ type, size = 'md' }: WalletLogoProps) => {
  return <img src={IMG_SRC_DICT[type]} alt="Cosmostation Wallet Logo" className={SIZE_DICT[size]} />;
};

export default WalletLogo;
