import { useState, useMemo, useEffect } from 'react';
import type { AssetDetail } from 'types/asset';
import { useMainStore } from 'provider/mainContext';
import useTxClient from 'hooks/useTxClient';
import BigNumber from 'bignumber.js';
import { INFOBOX_TEXTS, INITIAL_ASSET } from 'COMMON_VARIABLES';
import { detectBridgeAsset, getTxChainGasPrice, goBridge } from 'common/utils';
import { TokenTypes } from 'components/constants/token';
import ChainDropDown from 'components/modals/IBCTransferModal/ChainDropDown';
import PoweredByAxelar from 'components/logos/PoweredByAxelar';
import useGas from 'hooks/useGas';
import ModalSectionHeader from '../components/ModalSectionHeader';
import IBCTransferArrow from './IBCTransferArrow';
import InfoBox from 'components/infobox';
import ExternalLink from 'components/texts/ExternalLink';
import AmountInput from 'components/inputs/AmountInput';
import Button from 'components/buttons/Button';
import useTokenTransferButton from './hooks/useTokenTransferButton';
import type { Wallet } from 'types/wallet';
import type { InfoChain } from 'types/chain';

type DepositProps = {
  asset?: AssetDetail;
  transferChain?: InfoChain;
  setTransferChainId?: (chainId: string) => void;
  transferChainWallet?: Wallet | null;
  transferChainBalance: { denom: string; amount: string };
  close: () => void;
};

const IBCTransferModalDeposit = ({
  asset,
  transferChain,
  setTransferChainId,
  transferChainWallet = null,
  transferChainBalance,
  close,
}: DepositProps) => {
  const store = useMainStore();

  const { signAndBroadcast } = useTxClient();

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    return () => setLoading(false);
  }, []);

  /** @description asset chain */
  // const assetChain = useMemo<string | undefined>(() => {
  //   return asset?.tokenType === TokenTypes.LF ? undefined : asset?.chainId;
  // }, [asset?.tokenType, asset?.chainId]);

  const assetBridge = useMemo(() => detectBridgeAsset(asset?.ticker), [asset?.ticker]);
  const isIBC = assetBridge.gateway === 'IBC';
  const isAxlBridged = assetBridge.bridge === 'AXL';

  const [amount, setAmount] = useState('');

  const lfChainIdOptions = useMemo<string[]>(() => asset?.chains?.map((chain) => chain.chain_id) ?? [], [asset]);

  const maxDespositAmount = useMemo<BigNumber>(
    () => (asset ? new BigNumber(transferChainBalance.amount).div(10 ** asset.exponent) : new BigNumber(0)),
    [transferChainBalance, asset]
  );

  const { gas } = useGas('deposit');

  const deposit = async () => {
    if (asset !== undefined) {
      setLoading(true);
      const microAmount = new BigNumber(amount).multipliedBy(10 ** asset.exponent).toFixed(0);
      const temp_fee_denom = store.chainsData.info[asset.chainId].denoms[0].base_denom;
      const txChain = store.chainsData.info[asset.chainId];
      const defaultGasRate = txChain ? getTxChainGasPrice(txChain, 'low') : 0;

      // EVM-TEST
      // txData: {
      //   originChainId: 'evmos_9001-2',
      //   originChainWallet: {
      //     chainId: 'evmos_9001-2',
      //     address: 'evmos1ct63ffxycqjqdnj3zhatx5h3l9ke4uzsqgwgtf',
      //     name: 'SH_REAL',
      //   },
      //   denom: 'aevmos',
      //   amount: '1000000',
      // },
      let error = await signAndBroadcast({
        type: 'deposit',
        txData: {
          originChainId: transferChain?.chainId,
          originChainWallet: transferChainWallet,
          denom: asset.baseDenom,
          amount: microAmount,
        },

        chainData: store.chainsData,
        fee: {
          gas: `${gas}`,
          amount: [
            {
              denom: temp_fee_denom,
              amount: `${new BigNumber(gas * defaultGasRate).dp(0, BigNumber.ROUND_CEIL)}`,
            },
          ],
        },
      });
      setLoading(false);

      if (!error) {
        close();
      }
    }
  };

  /** @desc new */
  const button = useTokenTransferButton({
    isLoading,
    chainId: transferChain?.chainId,
    txChain: transferChain,
    txChainWallet: transferChainWallet,
    connectedWallet: store.wallet,
    asset,
    amount: new BigNumber(amount),
    balance: maxDespositAmount,
    activeLabel: 'Deposit',
    transferFunc: deposit,
  });

  return (
    <>
      <div className="space-y-4 md:space-y-6">
        {/* Chain Selector */}
        <div className="space-y-2">
          <div className="flex">
            <div className="basis-[52.5%]">
              <ModalSectionHeader title="From" />
            </div>
            <div className="basis-[47.5%]">
              <ModalSectionHeader title="To" />
            </div>
          </div>

          <div className="flex items-center">
            <ChainDropDown
              layer="modal"
              chainIds={asset?.tokenType === TokenTypes.LF ? lfChainIdOptions : undefined}
              selectedChainId={transferChain?.chainId}
              selectedChainWallet={transferChainWallet}
              onSelectChainId={setTransferChainId}
              showAddress={isIBC}
            />

            <IBCTransferArrow className="flex justify-center grow" />

            <ChainDropDown
              layer="modal"
              selectedChainId={INITIAL_ASSET.chainId}
              selectedChainWallet={store.wallet}
              showAddress={isIBC}
            />
          </div>
        </div>

        {isIBC ? (
          <>
            {asset?.ticker.includes('grv') && <InfoBox type="tip">{INFOBOX_TEXTS.DEPOSIT_GRV_ASSET}</InfoBox>}

            <div className="space-y-2">
              <div className="flex items-center gap-x-2 font_caption_s text-on_surface_variant_dark">
                Amount to deposit
                {asset?.ticker.includes('grv') && (
                  <ExternalLink text="Bridge" href="https://bridge.blockscape.network" />
                )}
              </div>

              <AmountInput
                value={amount}
                maximumFractionDigits={asset?.exponent ?? 9}
                asset={asset}
                showBalanceTicker={true}
                onChangeValue={setAmount}
                maxAmount={maxDespositAmount}
                simpleInputData={{
                  signType: 'deposit',
                  txChainId: transferChain?.chainId,
                }}
              />
            </div>
          </>
        ) : (
          isAxlBridged && (
            <div className="flex flex-col items-center justify-center py-4">
              <div className="flex items-center justify-center w-full">
                <PoweredByAxelar />
              </div>
            </div>
          )
        )}

        {isIBC ? (
          <>
            <InfoBox type="tip">
              {button.needToAddChain ? INFOBOX_TEXTS.IBC_TIP_ADD_CHAIN_TO_TRANSFER : INFOBOX_TEXTS.IBC_TIP}
            </InfoBox>
            <Button label={button.text} status={button.status} onClick={button.onClick} className="w-full" />
          </>
        ) : (
          <>
            <InfoBox type="warning">{INFOBOX_TEXTS.EXTERNAL_LINK}</InfoBox>
            <Button
              label="Go bridge"
              status="enabled"
              onClick={() => {
                goBridge(assetBridge.gateway, assetBridge, {
                  source: '',
                  destination: 'crescent',
                  denom: '',
                });
              }}
              className="w-full"
            />
          </>
        )}
      </div>
    </>
  );
};

export default IBCTransferModalDeposit;
