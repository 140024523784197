import { useMainStore } from 'provider/mainContext';
import { useCrescentChainBalance } from 'hooks/useAPI';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import type { Balances } from 'types/balance';
import type { InfoAsset } from 'types/asset';
import { formatAmount } from 'common/utils';
import type { OpenOrder } from 'types/orderbook';
import Table from 'components/table/Table';
import { TableStyles } from 'components/table/styles';
import { MAX_DECIMAL } from 'COMMON_VARIABLES';

type UserFundsData = {
  info: InfoAsset | undefined;
  displayAvailableAmount: string;
  displayReservedAmount: string;
  displayTotalAmount: string;
  avblBalance: BigNumber;
  totalBalance: BigNumber;
  inOrderBalance: BigNumber;
};

type FundsTableRow = {
  tokenLabel: JSX.Element;
  token: string;
  totalBalanceLabel: string;
  totalBalance: BigNumber;
  avblBalanceLabel: string;
  avblBalance: BigNumber;
  inOrderLabel: string;
  inOrder: BigNumber;
};

// MAIN
const FundsTable = ({ openOrders, isLoading }: { openOrders: OpenOrder[]; isLoading: boolean }) => {
  const mainStore = useMainStore();
  const { data: crescentChainBalances } = useCrescentChainBalance(
    mainStore.wallet.address || '',
    mainStore.isWatching ? 1000 : 3000
  );

  // Actions
  const userBalance = useMemo<UserFundsData[] | null>(() => {
    if (crescentChainBalances) {
      const _temp: Balances['data'] = JSON.parse(JSON.stringify(crescentChainBalances.data));
      const _result: UserFundsData[] = [];
      const _priorityResult: UserFundsData[] = [];
      _temp.asset.forEach((token) => {
        if (!token.denom.includes('pool')) {
          const assetInfo = mainStore.assetsData.info?.[token.denom];

          const avblBalance = new BigNumber(token.amount).shiftedBy(-assetInfo.exponent);
          const displayAvailableAmount = formatAmount(avblBalance, 9);

          const inOrderBalance = new BigNumber(token.reserved).shiftedBy(-assetInfo.exponent);
          const displayReservedAmount = formatAmount(inOrderBalance, 9);

          const totalBalance = new BigNumber(token.reserved).plus(token.amount).shiftedBy(-assetInfo.exponent);
          const displayTotalAmount = formatAmount(totalBalance, 9);
          const userFundsData = {
            info: assetInfo,
            displayAvailableAmount,
            displayReservedAmount,
            displayTotalAmount,
            avblBalance,
            totalBalance,
            inOrderBalance,
          };
          if (assetInfo.ticker.includes('CRE')) {
            _priorityResult.push(userFundsData);
          } else {
            _result.push(userFundsData);
          }
        }
      });
      return [
        ..._priorityResult,
        ..._result.sort((a, b) => (a.info && b.info && a.info.ticker > b.info.ticker ? 1 : -1)),
      ];
    } else {
      return null;
    }
  }, [crescentChainBalances]);

  const tableRows = useMemo<FundsTableRow[]>(() => {
    return (
      userBalance?.map((item) => {
        const token = item.info?.ticker ?? '';

        return {
          token,
          tokenLabel: (
            <div className="font_title_s text-on_surface md:font_caption_s md:text-on_surface_variant_light">
              {token}
            </div>
          ),
          totalBalance: item.totalBalance,
          totalBalanceLabel: formatAmount(item.totalBalance, MAX_DECIMAL),
          avblBalance: item.avblBalance,
          avblBalanceLabel: formatAmount(item.avblBalance, MAX_DECIMAL),
          inOrder: item.inOrderBalance,
          inOrderLabel: formatAmount(item.inOrderBalance, MAX_DECIMAL),
        };
      }) ?? []
    );
  }, [userBalance]);

  return (
    <Table<FundsTableRow>
      type={TableStyles.COMPACT}
      rowsScrollHeight={mainStore.isMobile ? undefined : '17rem'}
      rows={tableRows}
      isLoading={isLoading}
      noDataLabel={mainStore.wallet.isActive ? 'No Fund' : 'Connect Wallet'}
      fields={[
        {
          label: 'Token',
          value: 'tokenLabel',
          type: 'jsx',
          sortValue: 'token',
          sortType: 'string',
          widthRatio: 10,
        },
        {
          label: 'Total Balance',
          value: 'totalBalanceLabel',
          type: 'jsx',
          sortValue: 'totalBalance',
          sortType: 'bignumber',
          widthRatio: 14,
          align: 'right',
        },
        {
          label: 'Available Balance',
          value: 'avblBalanceLabel',
          type: 'jsx',
          sortValue: 'avblBalance',
          sortType: 'bignumber',
          widthRatio: 14,
          align: 'right',
        },
        {
          label: 'In Order',
          value: 'inOrderLabel',
          type: 'jsx',
          sortValue: 'inOrder',
          sortType: 'bignumber',
          sortDisabled: true,
          widthRatio: 10,
          align: 'right',
        },
      ]}
    />
  );
};

export default FundsTable;
