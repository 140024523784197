import { initWebSocket } from 'common/websocket/websocketHandlers';
import { signEvmChain } from 'common/sign/evmSign';
import {
  getSignInfo,
  getIBCData,
  getCrescentChainSigningData,
  checkSigningData,
  displayError,
  broadcastTxHandler,
} from 'common/signingClients/utils/signUtils';
import { createCrescentMsgs } from 'common/msg/createMsgs';
import { SignParams } from 'types/signingClients';

export async function signWithWalletConnect({ wallet, type, chainData, txData, airdropClaimData, fee }: SignParams) {
  checkSigningData({ type, txData, fee, chainData });
  const walletType = 'walletconnect';
  const { creChainInfo } = getSignInfo({ chainData });
  const webSocket = initWebSocket({ type, chainData, txData, creChainInfo });
  try {
    const creAddress = wallet?.address || '';
    let signedTxRaw;
    if (type === 'deposit') {
      // External Chain Signing: IBC Deposit
      const { fromAddress, ibcDepositMsg, externalChainSigningClient } = await getIBCData({
        IBCType: type,
        walletType,
        data: { chainData, txData, creAddress, walletConnectWallet: wallet },
      });
      signedTxRaw = await externalChainSigningClient.sign(fromAddress, ibcDepositMsg, fee, '');

      // {
      //   type: '/ibc.applications.transfer.v1.MsgTransfer',
      //   value: {
      //     source_port: 'transfer',
      //     source_channel: sourceChannel,
      //     denom: txData.denom,
      //     amount: String(txData.amount),
      //     receiver: receiver,
      //     revision_number: Number(revisionData.revision_number),
      //     revision_height: Number(latestBlockHeight) + 100,
      //     timeout_timestamp: new Date().getTime() + 600000 + '000000',
      //   },
      // },
    } else {
      const { crescentChainFee, crescentChainSigningClient } = await getCrescentChainSigningData({
        walletType,
        txType: type,
        chainData,
        airdropClaimData,
        txData,
      });
      if (type === 'withdraw') {
        // Crescent Chain Signing: IBC Withdraw
        const { ibcWithdrawMsg } = await getIBCData({
          IBCType: type,
          walletType,
          data: { chainData, txData, creAddress },
        });
        signedTxRaw = await crescentChainSigningClient.sign(creAddress, ibcWithdrawMsg, crescentChainFee, '');
      } else {
        // Crescent Chain Signing: Crescent Msgs
        const CrescentChainMsg = await createCrescentMsgs({ type, txData, creAddress });
        signedTxRaw = await crescentChainSigningClient.sign(creAddress, CrescentChainMsg, crescentChainFee, '');
      }
    }
    await broadcastTxHandler({ signedTxRaw, webSocket, type, txData, chainData });
  } catch (e) {
    webSocket.close();
    return displayError(e);
  }
}
