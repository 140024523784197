import { getImageURL } from 'common/utils';
import { BRIDGE_IMAGE_URL } from 'COMMON_VARIABLES';
import { CoinColor, CoinSize, COIN_BRIDGE_BADGE_SIZE_DICT } from './constans';
import { COIN_BG_COLOR_FALLBACK_DICT } from './constants';


const BRIDE_BADGE_BORDER_DICT: Record<CoinColor, string> = {
  surface: 'border border-surface',
  liquid: 'border border-liquid',
}

type BridgeBadgeProps = {
  color?: CoinColor;
  parentSize: CoinSize;
  bridgeTicker: string;
  className?: string;
};

const BridgeBadge = ({ color = 'surface', parentSize, bridgeTicker, className = '' }: BridgeBadgeProps) => {
  return (
    <div className={className}>
      <img
        src={getImageURL(BRIDGE_IMAGE_URL[bridgeTicker])}
        alt={bridgeTicker}
        className={`rounded-full ${BRIDE_BADGE_BORDER_DICT[color]}`}
        style={{ width: COIN_BRIDGE_BADGE_SIZE_DICT[parentSize], background: COIN_BG_COLOR_FALLBACK_DICT[bridgeTicker] }}
      />
    </div>
  );
};

export default BridgeBadge;
