import TransitionModal from './TransitionModal';
import KeplrLogo from 'resources/logos/keplr-logo.svg';
import CosmostationLogo from 'resources/logos/cosmostation-logo.png';
import LeapLogo from 'resources/logos/leap-logo.png';
import WalletConnectLogo from 'resources/logos/wallet-connect-logo.svg';

import { Wallet, WalletType } from 'types/wallet';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect } from 'react';
import { isAndroid, isMobile } from '@walletconnect/utils';
import { ALERT_TEXTS, IS_PRODUCTION, MODAL_TITLES } from 'COMMON_VARIABLES';
import ModalHeader from './components/ModalHeader';

/** @desc WalletButton */
const WALLET_BUTTON_HOVER_LAYER_BASE =
  'md:before:block before:absolute before:w-full before:h-full before:top-0 before:right-0 before:left-0 before:bottom-0 before:bg-transparent before:transition-all';

const WALLET_BUTTON_HOVER_LAYER = `${WALLET_BUTTON_HOVER_LAYER_BASE} before:hover:bg-on_surface_variant_dark_o24`;

const WalletButton = ({
  label,
  logoImgSrc,
  onClick,
}: {
  label: string | JSX.Element;
  logoImgSrc: string;
  onClick: () => void;
}) => {
  return (
    <button
      type="button"
      className={`relative w-full flex items-center justify-between gap-x-2 px-4 py-3 rounded-100px overflow-hidden transition-colors text-on_surface_variant_light bg_surface_3 ${WALLET_BUTTON_HOVER_LAYER}`}
      onClick={onClick}
    >
      <div className="font_title_s">{label}</div>

      <div className="flex items-center justify-center w-5 h-5">
        <img src={logoImgSrc} alt={`${label} logo`} className="object-contain w-full h-full" />
      </div>
    </button>
  );
};

/** @desc ConnectWalletModal */
const ConnectWalletModal = ({
  isOpen,
  connect,
  close,
}: {
  isOpen: boolean;
  connect: (type: WalletType) => Promise<Wallet | null | void>;
  close: () => void;
}) => {
  const store = useMainStore();

  useEffect(() => {
    async function tryConnectMobileWallet() {
      if (isMobile() && !store.wallet.address) {
        const result = await connect(WalletType.COSMOSTATION);
        if (result) {
          return close();
        }
      }
    }
    tryConnectMobileWallet();
  }, [store.wallet.address]);

  // const connectMobileWallet = useCallback(() => {
  //   if (!isMobile()) {
  //     close();
  //     return;
  //   }

  //   if (!IS_PRODUCTION) {
  //     alert(ALERT_TEXTS.TESTNET_WALLET_CONNECT_NOT_AVAILABLE);
  //     close();
  //     return;
  //   }

  //   connect(WalletType.WALLETCONNECT);
  //   close();
  // }, [close, connect]);

  return (
    <TransitionModal size="sm" isOpen={isOpen} close={close}>
      <ModalHeader title={MODAL_TITLES.CONNECT_WALLET} onCloseClick={close} />

      <div className="px-4 pb-5 space-y-5 md:px-5 md:pb-6">
        <WalletButton
          label="Leap"
          logoImgSrc={LeapLogo}
          onClick={() => {
            connect(WalletType.LEAP);
            close();
          }}
        />

        {
          <WalletButton
            label="Cosmostation"
            logoImgSrc={CosmostationLogo}
            onClick={() => {
              connect(WalletType.COSMOSTATION);
              close();
            }}
          />
        }

        {!isMobile() && (
          <WalletButton
            label="Keplr"
            logoImgSrc={KeplrLogo}
            onClick={() => {
              connect(WalletType.KEPLR);
              close();
            }}
          />
        )}

        {/* {isMobile() && (
          <WalletButton
            label={
              <div className="flex items-center gap-x-2">
                WalletConnect <span>-</span> <img src={CosmostationLogo} alt="Cosmostation" className="w-3 h-3" />
              </div>
            }
            logoImgSrc={WalletConnectLogo}
            onClick={connectMobileWallet}
          />
        )} */}
      </div>
    </TransitionModal>
  );
};

export default ConnectWalletModal;
