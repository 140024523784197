import { PairAssetFilterKeys, PAIR_FILTER_ASSETS_DICT } from 'COMMON_VARIABLES';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MyPoolDetail, PoolDetail, PoolFilterData, PoolType } from 'types/pool';

/** @desc default values for reset */
const RANGED = true;
const BASIC = true;
const LIQUIDFARM = false;
const INCENTIVISED = false;
const ETC = true;

type PoolFilterHookProps = {
  pools: PoolDetail[] | MyPoolDetail[];
  injectedData?: PoolFilterData;
};

const usePoolFilter = ({ pools, injectedData }: PoolFilterHookProps) => {
  /** initial data */
  const initialData = useMemo<PoolFilterData>(
    () =>
      injectedData
        ? { ...injectedData }
        : {
            ranged: RANGED,
            basic: BASIC,
            liquidFarm: LIQUIDFARM,
            incentivised: INCENTIVISED,
            etc: ETC,
            pairAssets: [],
          },
    [injectedData]
  );

  /** pool type */
  const [ranged, setRanged] = useState<boolean>(initialData.ranged);
  useEffect(() => {
    setRanged(initialData.ranged);
  }, [initialData.ranged]);

  const [basic, setBasic] = useState<boolean>(initialData.basic);
  useEffect(() => {
    setBasic(initialData.basic);
  }, [initialData.basic]);

  const checkedPoolTypes = useMemo<Set<PoolType>>(() => {
    const passes = new Set<PoolType>();
    if (ranged) passes.add(2);
    if (basic) passes.add(1);
    return passes;
  }, [ranged, basic]);

  const hasCheckedPoolType = useCallback(
    (pool: PoolDetail | MyPoolDetail) => checkedPoolTypes.has(pool.poolType),
    [checkedPoolTypes]
  );

  /** liquid farm */
  const [liquidFarm, setLiquidFarm] = useState<boolean>(initialData.liquidFarm);
  useEffect(() => {
    setLiquidFarm(initialData.liquidFarm);
  }, [initialData.liquidFarm]);

  const hasLiquidFarm = useCallback(
    (pool: PoolDetail | MyPoolDetail) => (liquidFarm ? pool.lfEnabled : true),
    [liquidFarm]
  );

  /** incentivised */
  const [incentivised, setIncentivised] = useState<boolean>(initialData.incentivised);
  useEffect(() => {
    setIncentivised(initialData.incentivised);
  }, [initialData.incentivised]);

  const hasIncentivised = useCallback(
    (pool: PoolDetail | MyPoolDetail) => (incentivised ? pool.apr > 0 : true),
    [incentivised]
  );

  /** assets */
  const [etc, setEtc] = useState<boolean>(initialData.etc);
  useEffect(() => {
    setEtc(initialData.etc);
  }, [initialData.etc]);

  const [pairAssets, setPairAssets] = useState<{ group: PairAssetFilterKeys; checked: boolean }[]>(
    initialData.pairAssets
  );
  useEffect(() => {
    setPairAssets(initialData.pairAssets);
  }, [initialData.pairAssets]);

  /** select all assets */
  const [selectAllAssets, setSelectAllAssets] = useState<boolean>(true);

  useEffect(() => {
    if (etc && pairAssets.every((pAsset) => pAsset.checked)) {
      setSelectAllAssets(true);
    } else if (!etc || pairAssets.some((pAsset) => !pAsset.checked)) {
      setSelectAllAssets(false);
    }
  }, [etc, pairAssets]);

  /** assets - mutation */
  const onPairAssetCheckChange = useCallback(
    (targetGroup: PairAssetFilterKeys, targetChecked: boolean) => {
      const newPairAssets = pairAssets.map((pAsset) => ({
        ...pAsset,
        checked: pAsset.group === targetGroup ? targetChecked : pAsset.checked,
      }));
      setPairAssets(newPairAssets);
    },
    [pairAssets]
  );

  const onAssetSelectAllCheckChange = useCallback(
    (checked: boolean) => {
      setEtc(checked);
      setSelectAllAssets(checked);
      setPairAssets(pairAssets.map((pAsset) => ({ ...pAsset, checked })));
    },
    [pairAssets]
  );

  const store = useMainStore();

  const hasCheckedAsset = useCallback(
    (pool: PoolDetail | MyPoolDetail): boolean => {
      /** pair tokens */
      const hasPAsset = pairAssets
        .filter((pAsset) => pAsset.checked)
        .some((pAsset) => {
          return PAIR_FILTER_ASSETS_DICT[pAsset.group].tickers.some((ticker) =>
            pool.assets.some((asset) => asset.ticker.toUpperCase() === ticker.toUpperCase())
          );
        });
      if (hasPAsset) return true;

      /** etc */
      if (etc) {
        const pTickers = pairAssets.reduce<string[]>(
          (accm, pAsset) => [...accm, ...PAIR_FILTER_ASSETS_DICT[pAsset.group].tickers],
          []
        );

        const etcAssets = Object.values(store.assetsData.info).filter((asset) => {
          return !pTickers.some((pTicker) => pTicker.toUpperCase() === asset.ticker.toUpperCase());
        });

        return etcAssets.some((etcAsset) => pool.assets.some((asset) => asset.denom === etcAsset.denom));
      }

      return false;
    },
    [etc, pairAssets, store.assetsData.info]
  );

  /** final filtering */
  const filteredPools = useMemo<PoolDetail[] | MyPoolDetail[]>(() => {
    return pools.filter(hasCheckedPoolType).filter(hasLiquidFarm).filter(hasIncentivised).filter(hasCheckedAsset);
  }, [pools, hasCheckedPoolType, hasLiquidFarm, hasIncentivised, hasCheckedAsset]);

  /** filtered data sync */
  const [prevData, setPrevData] = useState<PoolFilterData | undefined>(undefined);

  const currentData = useMemo<PoolFilterData>(
    () => ({
      ranged,
      basic,
      liquidFarm,
      incentivised,
      etc,
      pairAssets,
    }),
    [ranged, basic, liquidFarm, incentivised, etc, pairAssets]
  );

  const keepCurrentData = useCallback(() => {
    setPrevData(currentData);
  }, [currentData]);

  /** reset */
  const rollbackAllData = useCallback((filterData: PoolFilterData) => {
    setRanged(filterData.ranged);
    setBasic(filterData.basic);
    setLiquidFarm(filterData.liquidFarm);
    setIncentivised(filterData.incentivised);
    setEtc(filterData.etc);
    setPairAssets(filterData.pairAssets);
  }, []);

  /** how many diff from initial setup */
  const getFilteredCnt = useCallback(
    (unfilteredData: PoolFilterData) => {
      const currentData: PoolFilterData = {
        ranged,
        basic,
        liquidFarm,
        incentivised,
        etc,
        pairAssets,
      };

      const diffItems = Object.keys(unfilteredData)
        .filter((key) => key !== 'pairAssets')
        .filter((key) => unfilteredData[key] !== currentData[key]);

      const diffAssets = unfilteredData.pairAssets.filter(
        (dAsset) => currentData.pairAssets.find((pAsset) => pAsset.group === dAsset.group)?.checked !== dAsset.checked
      );

      return diffItems.length + diffAssets.length;
    },
    [ranged, basic, liquidFarm, incentivised, etc, pairAssets]
  );

  return {
    ranged,
    setRanged,
    basic,
    setBasic,
    liquidFarm,
    setLiquidFarm,
    incentivised,
    setIncentivised,
    etc,
    setEtc,
    pairAssets,
    setPairAssets,
    onPairAssetCheckChange,
    selectAllAssets,
    onAssetSelectAllCheckChange,
    filteredPools,
    getFilteredCnt,
    initialData,
    prevData,
    currentData,
    keepCurrentData,
    rollbackAllData,
  };
};

export default usePoolFilter;
