import BigNumber from 'bignumber.js';
import { convertToApy, formatAmount } from 'common/utils';
import { useParamsInfo } from 'hooks/useAPI';
import { useCallback, useMemo } from 'react';

type TimeUnit = 'hour';

const TIME_DIVIDERS: { [key in TimeUnit]: number } = {
  hour: 3600,
};

const useLfAuctionDuration = (timeUnit: TimeUnit) => {
  const { data: paramsInfoData } = useParamsInfo();

  const auctionDuration = useMemo<BigNumber | undefined>(() => {
    const paramInfo = paramsInfoData?.data.find(
      (paramInfo) => paramInfo.key === 'liquidfarming.rewards_auction_duration'
    );
    return paramInfo ? new BigNumber(paramInfo.value).div(TIME_DIVIDERS[timeUnit]) : undefined;
  }, [paramsInfoData?.data, timeUnit]);

  const lfTokenApyDescription = useMemo<string>(() => {
    return `Expected APY of LF token compounding every ${
      auctionDuration ? `${auctionDuration.dp(1).toFormat()} ${timeUnit}${auctionDuration.gt(1) ? 's' : ''}` : 'epoch'
    }.`;
  }, [auctionDuration, timeUnit]);

  const getLfApy = useCallback<(apr: BigNumber) => BigNumber>(
    (apr: BigNumber) => {
      return convertToApy(apr, auctionDuration?.dp(1).toNumber() ?? 8);
    },
    [auctionDuration]
  );

  const getLfApyDescription = useCallback<(apr: BigNumber) => string>(
    (apr: BigNumber) => {
      return `Expected APY compounding every ${
        auctionDuration ? `${auctionDuration.dp(1).toFormat()} ${timeUnit}${auctionDuration.gt(1) ? 's' : ''}` : 'epoch'
      }, calculated based on its APR ${formatAmount(apr, 2)}%.`;
    },
    [auctionDuration, timeUnit]
  );

  return {
    lfTokenApyDescription,
    getLfApy,
    getLfApyDescription,
  };
};

export default useLfAuctionDuration;
