import BigNumber from 'bignumber.js';
import { FarmTxData } from 'common/msg/Farm';
import type { LiquidFarmTxData } from 'common/msg/LiquidFarm';
import { IS_LF_ENABLED } from 'COMMON_VARIABLES';
import useLiquidFarm from 'hooks/farm/useLiquidFarm';
import useTxClient from 'hooks/useTxClient';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { PoolDetail } from 'types/pool';

const useFarm = ({
  pool,
  rate,
  isLfByDefault,
  onTxSuccess,
}: {
  pool: PoolDetail;
  rate: number;
  isLfByDefault: boolean;
  onTxSuccess?: () => void;
}) => {
  const mainStore = useMainStore();

  const [isLoading, setLoading] = useState(false);

  const { signAndBroadcast } = useTxClient();

  /** @summary normal farm, mostly just moved from ManageModal/Farm.tsx */
  const myTokenBalance = useMemo(() => {
    const uToken = mainStore?.balanceData?.[pool.poolDenom];
    return uToken ? new BigNumber(uToken) : new BigNumber(0);
  }, [pool, mainStore.balanceData]);

  const amount = useMemo(() => myTokenBalance.multipliedBy(rate / 100), [rate, myTokenBalance]);

  const totalUsd = useMemo<BigNumber>(() => {
    if (mainStore.poolsData.isInit && amount.gt(0)) {
      return amount.multipliedBy(mainStore.poolsData.live?.[pool.poolDenom]?.priceOracle ?? new BigNumber(0));
    }
    return new BigNumber(0);
  }, [mainStore.poolsData, pool, amount]);

  // const farm = useCallback(async () => {
  //   setLoading(true);
  //   console.log('FARM START!');
  //   try {
  //     let error = await signAndBroadcast({
  //       type: 'farm',
  //       txData: {
  //         farmingCoin: [{ denom: pool.poolDenom, amount: amount.decimalPlaces(0).toString() }],
  //       },
  //       chainData: mainStore.chainsData,
  //     });
  //     if (!error && onTxSuccess) onTxSuccess();
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   console.log('FARM END!');
  //   setLoading(false);
  // }, [mainStore, amount, pool.poolDenom, signAndBroadcast, setLoading, onTxSuccess]);

  const farm = useCallback(async () => {
    setLoading(true);
    console.log('FARM START!');

    try {
      const txData: FarmTxData = {
        coin: { denom: pool.poolDenom, amount: amount.decimalPlaces(0).toString() },
      };

      const error = await signAndBroadcast({
        type: 'lpFarm',
        txData,
        chainData: mainStore.chainsData,
      });

      if (!error && onTxSuccess) onTxSuccess();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }

    console.log('FARM END!');
    setLoading(false);
  }, [mainStore, amount, pool.poolDenom, signAndBroadcast, setLoading, onTxSuccess]);

  /**
   * @summary LF
   * @todo pool/lf expoenent dff is TBD
   */
  const { getLiquidFarmByPoolId } = useLiquidFarm();
  const lfData = getLiquidFarmByPoolId(pool.poolId);

  const [isLf, setIsLf] = useState<boolean>(IS_LF_ENABLED && pool.lfEnabled && isLfByDefault);

  useEffect(() => {
    if (IS_LF_ENABLED) setIsLf(pool.lfEnabled);
  }, [pool.lfEnabled]);

  const minAmount = useMemo<BigNumber>(() => new BigNumber(lfData?.minDepositAmount ?? 0), [lfData?.minDepositAmount]);

  const lfMintAmountPerPoolToken = useMemo<BigNumber>(
    () => lfData?.mintAmountPerPoolToken ?? new BigNumber(0),
    [lfData?.mintAmountPerPoolToken]
  );

  const lfAmount = useMemo<BigNumber>(
    () => amount.shiftedBy(-12).multipliedBy(lfMintAmountPerPoolToken),
    [amount, lfMintAmountPerPoolToken]
  );

  const lfApr = useMemo<BigNumber>(() => new BigNumber(pool.lfApr), [pool.lfApr]);

  const liquidFarm = useCallback(async () => {
    setLoading(true);

    const txData: LiquidFarmTxData = {
      poolId: pool.poolId,
      farmingCoin: { denom: pool.poolDenom, amount: amount.dp(0, BigNumber.ROUND_DOWN).toString() },
    };

    const error = await signAndBroadcast({
      type: 'liquidFarm',
      txData,
      chainData: mainStore.chainsData,
    });

    if (!error && onTxSuccess) onTxSuccess();

    setLoading(false);
  }, [amount, mainStore.chainsData, pool.poolDenom, pool.poolId, signAndBroadcast, onTxSuccess]);

  const state = useMemo<{
    onAction: () => Promise<void>;
  }>(
    () => ({
      onAction: isLf ? liquidFarm : farm,
    }),
    [isLf, farm, liquidFarm]
  );

  return {
    isLf,
    setIsLf,
    balance: myTokenBalance,
    amount,
    minAmount,
    amountUSD: totalUsd,
    lfAmount,
    lfMintAmountPerPoolToken,
    lfApr,
    state,
    isLoading,
  };
};

export default useFarm;
