/**
 * Util type
 */
export enum ChainTypes {
  CRESCENT = 'CRESCENT',
  AGORIC = 'AGORIC',
  COSMOS = 'COSMOS',
  COLUMBUS = 'COLUMBUS',
  PHOENIX = 'PHOENIX',
  UNKNOWN = 'UNKNOWN',
}
