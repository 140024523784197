import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { TradingViewChart } from 'tradingview/components/TradingViewChart';
import { isUpdate } from 'COMMON_VARIABLES';
import Spinner from 'components/spinners/Spinner';

const OrderbookChart = ({ pairId }: { pairId: string | undefined }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      {pairId && !isLoading ? (
        <div className="relative w-full h-full bg-transparent rounded-[inherit] overflow-hidden">
          {!isUpdate && <TradingViewChart symbol={`P${pairId}`} onChangeIsLoading={setIsLoading} />}
        </div>
      ) : (
        <Spinner spinnerClassName="border-on_primary_dark" className="absolute inset-0 w-full h-full" />
      )}
    </>
  );
};

export default observer(OrderbookChart);
