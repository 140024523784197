import BigNumber from 'bignumber.js';
import {
  formatAmount,
  formatUSD,
  getFormattedZero,
  getFractionDigitsLimitedNumber,
  getIntegerSeperator,
} from 'common/utils';
import PercentButtonGroup from 'components/buttons/PercentButtonGroup';
import TokenSelectButton from 'components/buttons/TokenSelectButton';
import useAmountUtils from 'hooks/useAmountUtils';
import useGas from 'hooks/useGas';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { AssetDetail } from 'types/asset';
import { SignParams } from 'types/signingClients';
import InputMaxAmountCaption from './components/InputMaxAmountCaption';
import InputField from './InputField';

/**
 * @caution we use Big numbers like which have 18 digit decimal,
 * so input value must be as string for safety
 *
 * */
type AmountInputProps = {
  value?: string;
  asset?: AssetDetail;
  comparedUSDValue?: BigNumber;
  showBalanceTicker?: boolean;
  onChangeValue?: (value: string) => void;
  onClickTokenSelect?: () => void;
  isError?: boolean;
  disabled?: boolean;
  maximumFractionDigits?: number;
  maxAmount?: BigNumber | 'balance';
  maxLabel?: string;
  simpleInputData?: {
    signType: SignParams['type'];
    txChainId: string | undefined;
  };
  step?: number;
};

const AmountInput = ({
  value = '',
  asset,
  comparedUSDValue,
  showBalanceTicker = false,
  onChangeValue,
  onClickTokenSelect,
  isError,
  disabled,
  maximumFractionDigits,
  maxAmount: maxAmountRaw,
  maxLabel = 'Balance',
  simpleInputData,
  step,
}: AmountInputProps) => {
  /** @desc input usd */
  const valueUSD = useMemo<BigNumber>(() => {
    if (asset === undefined) return new BigNumber(0);

    const valueUSDRaw = new BigNumber(value).multipliedBy(asset.priceOracle);
    return valueUSDRaw.isNaN() ? new BigNumber(0) : valueUSDRaw;
  }, [value, asset]);

  /** @desc usd diff */
  const valueUSDDiff = useMemo<number>(() => {
    if (!comparedUSDValue || comparedUSDValue.isNaN() || comparedUSDValue.isZero() || valueUSD.isZero()) return 0;

    const intSeperator = getIntegerSeperator() ?? ',';

    const precComparedUSD = new BigNumber(
      formatUSD(comparedUSDValue, { symbol: '', fixedTo2: false }).replaceAll(intSeperator, '')
    );
    const precUSD = new BigNumber(formatUSD(valueUSD, { symbol: '', fixedTo2: false }).replaceAll(intSeperator, ''));

    if (precComparedUSD.eq(precUSD)) return 0;
    return precUSD.div(precComparedUSD).multipliedBy(100).minus(100).dp(2, 1).toNumber();
  }, [valueUSD, comparedUSDValue]);

  /** @desc cut fraction digits */
  const cutFractionDigits = useCallback(
    (value: string): string => {
      if (maximumFractionDigits !== undefined)
        return getFractionDigitsLimitedNumber(value, maximumFractionDigits) ?? value;

      if (asset) return getFractionDigitsLimitedNumber(value, asset.exponent) ?? value;

      return value;
    },
    [maximumFractionDigits, asset]
  );

  /** @desc simple input; such as HALF, MAX */
  const maxAmount = useMemo<BigNumber | undefined>(() => {
    if (asset === undefined) return undefined;
    return maxAmountRaw === 'balance' ? asset.availableBalance.shiftedBy(-asset.exponent) : maxAmountRaw;
  }, [asset, maxAmountRaw]);

  const { gas } = useGas(simpleInputData?.signType);
  const { getPercentAmountOf } = useAmountUtils({
    asset,
    txChainId: simpleInputData?.txChainId,
    gas,
  });

  const [percent, setPercent] = useState<number | undefined>();

  const onSelectPercentInput = useCallback(
    (percent: number) => {
      if (maxAmount && simpleInputData) {
        setPercent(percent);
        const percentedAmount = getPercentAmountOf(maxAmount, percent);
        onChangeValue?.(cutFractionDigits(percentedAmount?.toString() ?? value));
      }
    },
    [maxAmount, simpleInputData, getPercentAmountOf, onChangeValue, value, cutFractionDigits]
  );

  useEffect(() => {
    setPercent(undefined);
  }, [asset?.denom]);

  return (
    <InputField
      type="amount"
      value={value}
      onChangeValue={(value) => {
        setPercent(undefined);
        onChangeValue?.(cutFractionDigits(value));
      }}
      placeholder={getFormattedZero({ mantissa: 1 })}
      placeholderType="number"
      isError={isError}
      disabled={disabled}
      right={
        onClickTokenSelect ? (
          <TokenSelectButton asset={asset} onClick={onClickTokenSelect} />
        ) : (
          asset &&
          simpleInputData && (
            // HALF, MAX
            <PercentButtonGroup
              xFull={false}
              excludedValues={[50]}
              selectedValue={percent}
              onSelect={onSelectPercentInput}
            />
          )
        )
      }
      bottom={
        asset && (
          <div className={`flex justify-between items-start gap-x-2 ${onClickTokenSelect ? 'pt-2' : ''}`}>
            <div className="flex items-center gap-x-1">
              {/* usd */}
              <div className="font_caption_number_s text-surface_variant break-all">
                ≈ {formatUSD(valueUSD, { fixedTo2: false })}
              </div>

              {/* usd diff */}
              {valueUSDDiff !== 0 && (
                <div
                  className={`font_caption_s ${
                    valueUSDDiff > 0
                      ? 'text-semantic_green'
                      : valueUSDDiff < -3
                      ? 'text-semantic_red'
                      : valueUSDDiff < -1
                      ? 'text-semantic_yellow'
                      : 'text-surface_variant'
                  }`}
                >
                  (
                  <span className="font_caption_number_s">
                    {valueUSDDiff > 0 ? '+' : '-'}
                    {formatAmount(new BigNumber(Math.abs(valueUSDDiff)), 2)}%
                  </span>
                  )
                </div>
              )}
            </div>

            {maxAmount && (
              <div className="flex flex-col items-end gap-y-2">
                {/* balance */}
                <InputMaxAmountCaption
                  maxAmount={maxAmount}
                  maxLabel={maxLabel}
                  asset={asset}
                  showTicker={showBalanceTicker}
                />

                {/* HALF, MAX */}
                {onClickTokenSelect && simpleInputData && (
                  <PercentButtonGroup xFull={false} selectedValue={percent} onSelect={onSelectPercentInput} />
                )}
              </div>
            )}
          </div>
        )
      }
    />
  );
};

export default AmountInput;
