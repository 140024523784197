import { ChainTypes } from 'components/constants/chain';
import { SignParams } from 'types/signingClients';
import { PairFilterAsset } from 'types/pair';
import { InfoAsset } from './types/asset';
import { TxType } from 'types/txEvent';
import { IconType } from 'components/icon';
import type { RibbonBadgeColor } from 'components/badges/RibbonBadge';

// TEST
export const IS_TEST = false;

// CONDITION
export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
export const IS_PUBLIC_TESTNET = process.env.REACT_APP_ENV === 'public-testnet';
export const IS_PRIVATE_TESTNET = process.env.REACT_APP_ENV === 'private-testnet';
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ? process.env.REACT_APP_CHAIN_ID : 'ERROR-CHAIN-ID';
export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME ?? 'ERROR-CHAIN-ID';

// INITIAL ASSETS
export const INITIAL_ASSET_PREFIX = 'cre';
export const INITIAL_ASSET: InfoAsset = {
  denom: 'ucre',
  ticker: 'CRE',
  chainId: CHAIN_ID,
  logoUrl: 'https://static-resources.crescent.network/CRE.png',
  baseDenom: 'ucre',
  chainName: CHAIN_NAME,
  exponent: 6,
  chains: [],
};

// STAKING ASSETS
export const STAKING_TICKERS = {
  staked: 'CRE',
  unstaked: 'bCRE',
};
export const STAKING_DENOMS = {
  staked: 'ucre',
  unstaked: 'ubcre',
};

// SETTINGS
export const CLIENT_PROTOCOL = 'https://';
export const ROOT_ADDRESS = process.env.REACT_APP_API_ENDPOINT;
// export const TX_POST_ONLY_ADDRESS = 'https://lp2tjbln4pmycm2ml5qehp4ogi0mkuhz.lambda-url.us-east-1.on.aws';
export const TX_POST_ONLY_ADDRESS = process.env.REACT_APP_API_ENDPOINT;

// EXTERNAL URL
export const MINTSCAN = {
  crescent_tx: IS_PRODUCTION
    ? 'https://mintscan.io/crescent/txs/'
    : 'https://testnet.mintscan.io/crescent-testnet/txs/',
  crescent_account: IS_PRODUCTION
    ? 'https://mintscan.io/crescent/account/'
    : 'https://testnet.mintscan.io/crescent-testnet/account/',
  cosmos_tx: IS_PRODUCTION ? 'https://mintscan.io/cosmos/txs/' : 'https://explorer-not-exist/',
};
export const TERRA_FINDER = IS_PRODUCTION
  ? 'https://finder.terra.money/mainnet/tx/'
  : 'https://finder.terra.money/testnet/tx/';

/**
 *
 * @todo How to handle UNKNOWN' urls is TBD
 * @returns [mainnet, testnet]
 */
export const TX_EXPLORER_URLS: { [key in ChainTypes]: [string, string] } = {
  [ChainTypes.CRESCENT]: ['https://mintscan.io/crescent/txs', 'https://testnet.mintscan.io/crescent-testnet/txs'],
  [ChainTypes.AGORIC]: [
    'https://agoric.bigdipper.live/transactions',
    'https://devnet.agoric.explorers.guru/transaction',
  ],
  [ChainTypes.COSMOS]: ['https://mintscan.io/cosmos/txs', 'https://testnet.mintscan.io/cosmoshub-testnet/txs'],
  [ChainTypes.COLUMBUS]: ['https://finder.terra.money/classic/tx', 'https://finder.terra.money/classic/tx'],
  [ChainTypes.PHOENIX]: ['https://finder.terra.money/mainnet/tx', 'https://finder.terra.money/testnet/tx'],
  [ChainTypes.UNKNOWN]: ['https://explorer-not-exist', 'https://explorer-not-exist'],
};

export const TOOLTIP_TEXTS = {
  APR: `Expected APR. If bCRE is included, staking APR according to assets' ratio is added.`,
  STAKING: `You can also simply swap CRE to bCRE instead of liquid staking.`,
  UNSTAKING: `You can also simply swap bCRE to CRE instead of liquid unstaking.`,
  LIMIT_ORDER_DEADLINE: `Your transaction will revert if it is pending for more than this period of time.`,
  MARKET_ORDER: `Market order places an order at a price significantly higher or lower than the last price depending on buy/sell to prioritize your order to execute all order quantities within the next transaction.`,
  EXPECTED_SLIPPAGE: `The difference between the expected price of a trade and the actual price at which the trade is executed.`,
  MINIMUM_RECEIVED: `Min. received if 100% swapped at the slippage limit price.`,
  SWAP_FEE: `Crescent protocol charges a 0% fee on swap.`,
  // SLIPPAGE_TOLERANCE: `The pricing difference between the price at the confirmation time and the actual price of the transaction users are willing to accept when swapping on Crescent.`,
  WITHDRAW_GRV_ASSET: `It needs 2-step process to send bridged Ethereum tokens back to Ethereum network.`,
  EASY_SWAP: `Easily market order swap to current ranged pool deposit rate.`,
  BASIC_POOL_RANGE: `Price range is 0 ~ infinity for basic pool.`,
  NO_DETAILS_TO_VIEW: `No details to view.`,
};

export const INFOBOX_TEXTS = {
  MY_POOL_OUT_OF_RANGE: `Some of your pools are currently out of range. Remember only pools within range are yield-bearing.`,
  MANAGE_POOL_OUT_OF_RANGE: `This pool is out of range, and one-sided. Click the pool ID above for alternatives.`,
  SLIPPAGE: `Please check the slippage and understand the risk.`,
  EXTERNAL_LINK: `It takes to external link.`,
  DEPOSIT_GRV_ASSET: `You can deposit tokens from Ethereum network to Crescent with the bridge portal or you can deposit
  tokens from Gravity network to Crescent with IBC.`,
  IBC_TIP: `TIP: IBC history will be displayed on Tokens table for 1 hour.`,
  IBC_TIP_ADD_CHAIN_TO_TRANSFER: 'TIP: To transfer, you will need to add the chain to your wallet.',
  UNSTAKE_WARNING: `It will take 14 days to unbond bCRE to CRE. Instead, you can swap bCRE back to CRE.`,
  LF: `When you farm, LF token will not compound interest until the end of the next rewards auction period. Note: Rewards may be reduced according to auction results.`,
  LF_UNFARM: `LF token has not compounded interest for current and the preceding auction period yet.`,
  GDEX: `The assets withdrawn from GDEX here will NOT be on Crescent Network. You have to check your balance on Emeris, and transfer them via IBC to Crescent manually.`,
  MAX_ORDERABLE_PRICE: `Max orderable price is set.`,
  MIN_ORDERABLE_PRICE: `Min orderable price is set.`,
};

export const MODAL_TITLES = {
  OTHER_POOLS: 'Other Pools',
  TOKEN_SELECT: 'Select Token',
  ACCOUNT: 'Account',
  CONNECT_WALLET: 'Connect Wallet',
  DISCLAIMER: 'Disclaimer',
  NOTIFICATION: 'Notification',
  PROPOSAL_DEPOSIT: 'Deposit',
  VOTE: 'Vote',
  TRANSFER: 'Transfer',
  PAIR_SELECT: 'Market',
  TRADE_SETTING: 'Trade Settings',
  FILTER: 'Filter',
  CLAIM_REWARDS: 'Rewards',
};

export const EMPTY_TEXTS = {
  PORTFOLIO: 'Please connect your wallet',
  TOKENS_TABLE: 'No tokens',
  POOLS_TABLE: 'No pools',
  TABLE_FALLBACK: 'No data',
};

export const ALERT_TEXTS = {
  TESTNET_WALLET_CONNECT_NOT_AVAILABLE:
    'Testnet wallet connect is not yet available on mobile device, stay tuned for mainnet or try it out on the desktop!',
};

export const MENU_NAMES = {
  HOME: 'Home',
  TRADE: 'Trade',
  ORDERBOOK: 'Orderbook',
  SWAP: 'Swap',
  EARN: 'Earn',
  STAKING: 'Staking',
  GOV: 'Gov',
  PORTFOLIO: 'Portfolio',
  AIRDROP: 'Airdrop',
};

export type Page = {
  name: string;
  path: string;
  iconType: IconType;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  // mobile props
  folded?: boolean;
};

export const PAGES: Page[] = [
  {
    name: MENU_NAMES.HOME,
    path: '/home',
    mobileOnly: true,
    iconType: 'home',
  },
  {
    name: MENU_NAMES.TRADE,
    path: '/trade',
    mobileOnly: true,
    iconType: 'trade',
  },
  {
    name: MENU_NAMES.ORDERBOOK,
    path: '/orderbook',
    desktopOnly: true,
    iconType: 'trade',
  },
  {
    name: MENU_NAMES.SWAP,
    path: '/swap',
    desktopOnly: true,
    iconType: 'trade',
  },
  {
    name: MENU_NAMES.EARN,
    path: '/farm',
    iconType: 'earn',
  },
  {
    name: MENU_NAMES.STAKING,
    path: '/staking',
    folded: true,
    iconType: 'staking',
  },
  {
    name: MENU_NAMES.GOV,
    path: '/gov',
    folded: true,
    iconType: 'gov',
  },
  {
    name: MENU_NAMES.PORTFOLIO,
    path: '/portfolio',
    iconType: 'portfolio',
  },
  {
    name: MENU_NAMES.AIRDROP,
    path: '/airdrop',
    folded: true,
    iconType: 'airdrop',
  },
];

//BRIDGE INFOS
export const BRIDGES = {
  axelar: 'AXL',
  gravity: 'GRV',
  injective: 'INJ',
  okc: 'OKT',
  neok: 'NEOK',
};

export const BRIDGE_IMAGE_URL = {
  [BRIDGES.axelar]: 'https://static-resources.crescent.network/AXL.png',
  [BRIDGES.gravity]: 'https://static-resources.crescent.network/GRV.svg',
};

export const BRIDGE_GATEWAY = {
  [BRIDGES.axelar]: 'https://satellite.money',
  [BRIDGES.injective]: 'https://hub.injective.network/bridge/',
  [BRIDGES.okc]: 'https://www.okx.com/oktc/ibc',
  [BRIDGES.neok]: 'https://app.evmos.org/assets',
  // [BRIDGES.gravity]: 'https://bridge.blockscape.network',
};

export const BRIDGE_COLOR = {
  [BRIDGES.axelar]: '#fff',
  [BRIDGES.gravity]: '#012fa7',
};

// UPDATE UI
export const isUpdate = false;

/** @summary max gas for the tx requiting dynamic gas */
export const MAX_GAS_AMOUNT = 3500000;

/** @summary tmp var to toggle LF interface */
export const IS_LF_ENABLED = IS_PRODUCTION ? false : true;

export const IS_FEE_ENABLED = false;

export const IS_IBC_ENABLED = true;

export const IBC_DISABLED_NOTE = 'IBC is disabled at the moment.';

export const MOBILE_IBC_DISABLED_BASE_DENOMS = ['aevmos'];
export const MOBILE_IBC_DISABLED_NOTE = 'IBC from/to Evmos is currently only supported on desktops.';

/** @summary when ready for intl, change to (navigator.language ?? 'en') */
export const NUMBER_FORMAT_LOCALE = 'en';

/** @summary pools table filter assets */
export const POOL_ASSET_FILTER_LOCAL_STORAGE_KEY = 'poolsSelectedPair';

export enum PairAssetFilterKeys {
  STABLE = 'Stable',
  CRES = 'CREs',
  ATOMS = 'ATOMs',
}

/** @desc why need specified logoUrls array? to decide to put any images not real logos */
export const PAIR_FILTER_ASSETS_DICT: {
  [key in PairAssetFilterKeys]: PairFilterAsset;
} = {
  [PairAssetFilterKeys.STABLE]: {
    tickers: ['USDC.axl', 'USDC.grv', 'IST', 'CMST'],
    showLogos: false,
    logoUrls: [],
  },
  [PairAssetFilterKeys.CRES]: {
    tickers: ['CRE', 'bCRE'],
    showLogos: true,
    logoUrls: ['https://static-resources.crescent.network/bCRE.png'],
  },
  [PairAssetFilterKeys.ATOMS]: {
    tickers: ['ATOM', 'stATOM', 'stkATOM'],
    showLogos: true,
    logoUrls: ['https://static-resources.crescent.network/bCRE.png'],
  },
};

export enum InvestmentKeys {
  FARMING = 'Farming',
  POOL_TOKENS = 'Pool Tokens',
  LF_TOKENS = 'LF Tokens',
  REWARDS = 'Rewards',
}

export enum PortfolioKeys {
  TOKENS = 'Tokens',
  INVESTMENT = 'Investment',
  UNBONDING = 'Unbonding',
}

/** @desc tx notification */
export const CONFETTI_TXS: TxType[] = ['lpfarmHarvest', 'lpfarmUnfarm'];

export const IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY = 'is-notification-on';

/** @desc tx notification - broadcast */
export const TOAST_TITLE_TX_BROADCAST = 'Broadcasting Transaction';
export const TOAST_CONTENT_TX_BROADCAST = 'Please wait for confirmation.';

/** @desc tx notification - error */
export const TOAST_TITLE_ERROR_INSUFFICIENT_FUNDS = 'Insufficient Balance';
export const TOAST_TITLE_ERROR_INSUFFICIENT_FEE = 'Insufficient Fee';
export const TOAST_TITLE_ERROR_OUT_OF_GAS = 'Out of Gas';
export const TOAST_TITLE_ERROR_ORDER_PRICE_OUT_OF_RANGE = 'Order Price Out of Range';
export const TOAST_TITLE_ERROR_UNKNOWN = 'Error';

export const TOAST_CONTENT_ERROR_WALLET_UNAVAILABLE = 'Please refresh and try again.';

export const TOAST_INFOBOX_ERROR_INSUFFICIENT_FUNDS =
  'Try with the available amount, accordingly leaving tokens to pay fees.';
export const TOAST_INFOBOX_ERROR_INSUFFICIENT_FEE =
  'Try selecting the fee option higher than or equal to the required amount, when you approve transaction in wallet.';
export const TOAST_INFOBOX_ERROR_OUT_OF_GAS =
  'Gas input can be found by clicking "advanced" in the wallet pop-up before approving your transaction.';
export const TOAST_INFOBOX_ERROR_ORDER_PRICE_OUT_OF_RANGE_HIGHER = 'Place your order lower than the Max Price.';
export const TOAST_INFOBOX_ERROR_ORDER_PRICE_OUT_OF_RANGE_LOWER = 'Place your order higher than the Min Price.';

/** @desc tx notification - result */
export const TOAST_TITLE_BUY_ORDER = 'Buy Ordered';
export const TOAST_TITLE_SELL_ORDER = 'Sell Ordered';
export const TOAST_TITLE_BUY_ORDER_FULFILL = 'Buy Order 100% Fulfilled';
export const TOAST_TITLE_SELL_ORDER_FULFILL = 'Sell Order 100% Fulfilled';
export const TOAST_TITLE_REWARDS = 'Congratulations!';
export const TOAST_TITLE_LF_FARM = 'Liquid Farmed';
export const TOAST_TITLE_LF_UNFARM = 'Liquid Unfarmed';
export const TOAST_TITLE_FARM = 'Farmed';
export const TOAST_TITLE_UNFARM = 'Unfarmed';
export const TOAST_TITLE_VOTE = 'Voted';
export const TOAST_TITLE_IBC_WITHDRAW_COMPLETED = 'IBC Withdraw Complete';
export const TOAST_TITLE_IBC_DEPOSIT_COMPLETED = 'IBC Deposit Complete';
export const TOAST_TITLE_IBC_WITHDRAW_PROCESSING = 'IBC Withdraw in Process';
export const TOAST_TITLE_IBC_DEPOSIT_PROCESSING = 'IBC Deposit in Process';
export const TOAST_TITLE_POOL_WITHDRAW = 'Withdrawn from Pool';
export const TOAST_TITLE_POOL_DEPOSIT = 'Deposited to Pool';
export const TOAST_TITLE_UNSTAKE = 'Unstaked';
export const TOAST_TITLE_STAKE = 'Liquid Staked';
export const TOAST_TITLE_BUY_ORDER_EXPIRE = 'Buy Order Expired';
export const TOAST_TITLE_SELL_ORDER_EXPIRE = 'Sell Order Expired';
export const TOAST_TITLE_BUY_ORDER_PARTIAL_EXPIRE = 'Buy Order Partially Expired';
export const TOAST_TITLE_SELL_ORDER_PARTIAL_EXPIRE = 'Sell Order Partially Expired';
export const TOAST_TITLE_BUY_ORDER_CANCEL = 'Buy Order Cancelled';
export const TOAST_TITLE_SELL_ORDER_CANCEL = 'Sell Order Cancelled';

export const TOAST_INFOBOX_WARNING_ORDER_EXPIRE =
  'Please retry with another price. There may have been a price change in the market.';

export const ORDER_SEARCH_TERMS = ['1D', '3D', '1W'];
export const ORDER_UPDATE_TERM = 3000;

export enum EVMChainNames {
  evmos = 'evmos',
  canto = 'canto',
}

export type RunMode = 'mainnet' | 'testnet';

export const EVM_IBC_CLIENT_ID_DICT: {
  [key in EVMChainNames]: {
    [key in RunMode]: string;
  };
} = {
  evmos: {
    mainnet: '07-tendermint-38',
    testnet: '07-tendermint-38',
  },
  canto: {
    mainnet: '07-tendermint-21',
    testnet: '07-tendermint-21',
  },
};

/** @desc decimal */
export const COMPACT_DECIMAL_PLACE = 9;

/** @desc gas */
export const GAS_MIN_AMOUNT_FALLBACK = 200000;

export const GAS_MIN_AMOUNT_DICT: { [key in SignParams['type']]: number } = {
  cancelAllOrders: 200000,
  staking: 10000000,
  unstaking: 10000000,
  claim: 300000,
  harvest: 480000,
  liquidFarm: 300000,
  liquidUnfarm: 300000,
  liquidUnfarmAndWithdraw: 300000,
  /** others */
  deposit: GAS_MIN_AMOUNT_FALLBACK,
  withdraw: GAS_MIN_AMOUNT_FALLBACK,
  swap: GAS_MIN_AMOUNT_FALLBACK,
  farm: GAS_MIN_AMOUNT_FALLBACK,
  unfarm: GAS_MIN_AMOUNT_FALLBACK,
  poolDeposit: GAS_MIN_AMOUNT_FALLBACK,
  poolWithdraw: GAS_MIN_AMOUNT_FALLBACK,
  govDeposit: GAS_MIN_AMOUNT_FALLBACK,
  orderbook: GAS_MIN_AMOUNT_FALLBACK,
  marketOrder: GAS_MIN_AMOUNT_FALLBACK,
  cancelOrder: GAS_MIN_AMOUNT_FALLBACK,
  vote: GAS_MIN_AMOUNT_FALLBACK,
  lpFarm: GAS_MIN_AMOUNT_FALLBACK,
  lpUnfarm: GAS_MIN_AMOUNT_FALLBACK,
};

export const MAX_DECIMAL = 9;

export type HightlightPool = {
  value: 'new' | 'hot' | 'only-here';
  label: string;
  badgeColor: RibbonBadgeColor;
  buttonLabel: string;
  poolId: number;
};

export const HIGHLIGHT_POOLS: HightlightPool[] = [
  {
    value: 'new',
    label: 'NEW🎉',
    badgeColor: 'green',
    buttonLabel: 'Lead the Pack',
    poolId: 79,
  },
  {
    value: 'hot',
    label: 'HOT🔥',
    badgeColor: 'red',
    buttonLabel: 'Join the Fun',
    poolId: 87,
  },
  {
    value: 'only-here',
    label: `ONLY \nHERE`,
    badgeColor: 'orange',
    buttonLabel: 'Farm Exclusive Pool',
    poolId: 75,
  },
];
