import { useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';

type CountingNumberProps = {
  number: number;
  dp?: number;
  startFrom0?: boolean;
};

const CountingNumber = ({ number, dp, startFrom0 = false }: CountingNumberProps) => {
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(startFrom0 ? 0 : number);

  useEffect(() => {
    setStart(end);
    setEnd(number);
  }, [number]);

  const duration = useMemo<number>(() => (start === 0 ? 5 : 3), [start]);

  return <CountUp start={start} end={end} decimals={dp} duration={duration} useEasing={true} />;
};

export default CountingNumber;
