import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spring, animated, SpringConfig } from 'react-spring';

const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

/** @desc configs ref: https://www.react-spring.dev/docs/advanced/config#config-visualizer */
type RollingNumberProps = {
  number: number;
  dp?: number;
  configs?: SpringConfig[];
};

const RollingNumber = ({ number, dp, configs }: RollingNumberProps) => {
  const numbersArr = useMemo<(string | number)[]>(() => {
    const numberString = formatAmount(new BigNumber(number), dp ?? 6);

    return Array.from(numberString, Number).map((x, idx) => (isNaN(x) ? numberString[idx] : x));
  }, [number, dp]);

  const fakeSpanRef = useRef<HTMLElement>(null);
  const [numberHeight, setNumberHeight] = useState(0);

  useEffect(() => {
    const height = fakeSpanRef?.current?.getClientRects()[0]?.height;
    if (height) {
      setNumberHeight(height);
    }
  }, [number]);

  const getConfig = useCallback(
    (configs: SpringConfig[] | undefined, number: number, index: number): SpringConfig | undefined => {
      return configs?.[getRandomIntInclusive(0, configs.length - 1)];
    },
    []
  );

  const keyCount = useRef(0);

  return (
    <span>
      {numberHeight !== 0 && (
        <div className="flex">
          {number < 0 && <span>-</span>}

          {numbersArr.map((n, index) => {
            if (typeof n === 'string') {
              return <div key={index}>{n}</div>;
            }

            return (
              <div
                key={index}
                className="overflow-hidden"
                style={{
                  height: numberHeight,
                }}
              >
                <Spring
                  key={`${keyCount.current++}`}
                  from={{
                    transform: 'translateY(0px)',
                  }}
                  to={{
                    transform: `translateY(${
                      -1 * (numberHeight * numbersArr.map((num) => (typeof num === 'string' ? 0 : num))[index])
                    })`,
                  }}
                  immediate={false}
                  config={getConfig(configs, n, index)}
                >
                  {(props) => (
                    <>
                      {NUMBERS.map((number, i) => (
                        <animated.div key={i} style={{ ...props }}>
                          {number}
                        </animated.div>
                      ))}
                    </>
                  )}
                </Spring>
              </div>
            );
          })}
        </div>
      )}

      {/* dummy span to calculate rendered height */}
      <span ref={fakeSpanRef} className="absolute opacity-0">
        0
      </span>
    </span>
  );
};

const Enhanced = memo(RollingNumber, (prevProps, nextProps) => prevProps.number === nextProps.number);

export default Enhanced;

/** @desc utils */
function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
