import { isMobile } from '@walletconnect/browser-utils';
import type { Coin } from 'common/crescent/lpfarm/types/cosmos/base/v1beta1/coin';
import type { CreatePlanRequest, TerminatePlanRequest } from 'pages/gov/types';

export default function ProposalParameterDetail({ content }: { content: any }) {
  if (content['@type'] === '/cosmos.params.v1beta1.ParameterChangeProposal') {
    return <ParameterChangeDetatil content={content} />;
  } else if (content['@type'] === '/crescent.farming.v1beta1.PublicPlanProposal') {
    return <PublicPlanDetatail content={content} />;
  } else if (content['@type'] === '/crescent.lpfarm.v1beta1.FarmingPlanProposal') {
    return <PublicPlanDetatail content={content} />;
  } else if (content['@type'] === '/cosmos.distribution.v1beta1.CommunityPoolSpendProposal') {
    return (
      <div className="break-all">
        <div className="flex mt-4">
          <div className="basis-48 font_body_s text-on_surface">amount</div>
          <div className="font_body_s text-on_surface">{`${content.amount[0].amount} ${content.amount[0].denom}`}</div>
        </div>
        {content.amount.slice(1).map((coin) => (
          <div className="flex mt-4" key={coin.denom}>
            <div className="basis-48 font_body_s text-on_surface"></div>
            <div className="font_body_s text-on_surface">{`${coin.amount} ${coin.denom}`}</div>
          </div>
        ))}
      </div>
    );
  } else if (content['@type'] === '/cosmos.upgrade.v1beta1.SoftwareUpgradeProposal') {
    let jsonInfo;
    try {
      jsonInfo = JSON.parse(content.plan.info);
    } catch (e) {}
    return (
      <div className="break-all">
        <div className="flex mt-4">
          <div className="basis-48 font_body_s text-on_surface">name</div>
          <div className="font_body_s text-on_surface">{content.plan.name}</div>
        </div>
        <div className="flex mt-4">
          <div className="basis-48 font_body_s text-on_surface">height</div>
          <div className="font_body_s text-on_surface font_body_number_s">{content.plan.height}</div>
        </div>
        <div className="flex mt-4">
          <div className="basis-48 font_body_s text-on_surface">time</div>
          <div className="font_body_s text-on_surface font_body_number_s">{content.plan.time}</div>
        </div>
        {jsonInfo && jsonInfo.binaries ? (
          <>
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">info binaries</div>
            </div>
            {Object.keys(jsonInfo.binaries).map((item) => (
              <div className="flex mt-4 text-on_surface" key={item}>
                <div className="basis-48 shrink-0 font_body_s">{item}</div>
                <div className="whitespace-pre-line font_body_s">{jsonInfo.binaries[item]}</div>
              </div>
            ))}
          </>
        ) : (
          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">info</div>
            <div className="font_body_s text-on_surface">{content.plan.info}</div>
          </div>
        )}
      </div>
    );
  }

  return null;
}

const PublicPlanDetatail = ({ content }: { content: any }) => {
  return (
    <div className="space-y-4">
      {content.add_plan_requests?.map((item) => (
        <div className="mt-6 break-all" key={item.name}>
          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">name</div>
            <div className="font_body_s text-on_surface">{item.name}</div>
          </div>

          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">start_time</div>
            <div className="font_body_s text-on_surface font_body_number_s">{item.start_time}</div>
          </div>

          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">end_time</div>
            <div className="font_body_s text-on_surface font_body_number_s">{item.end_time}</div>
          </div>

          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">farming_pool_address</div>
            <div className="font_body_s text-on_surface">{item.farming_pool_address}</div>
          </div>

          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">termination_address</div>
            <div className="font_body_s text-on_surface">{item.termination_address}</div>
          </div>
          {item.epoch_amount.length > 0 ? (
            <>
              <div className="flex mt-4">
                <div className="basis-48 font_body_s text-on_surface">epoch_amount</div>
                <div className="font_body_s text-on_surface font_body_number_s">{`${item.epoch_amount[0].amount} ${item.epoch_amount[0].denom}`}</div>
              </div>
              {item.epoch_amount.slice(1).map((coin) => (
                <div className="flex mt-4" key={coin.denom}>
                  <div className="basis-48 font_body_s text-on_surface"></div>
                  <div className="font_body_s text-on_surface">
                    <span className="mr-0.5 font_body_number_s">{coin.amount}</span>
                    <span>{coin.denom}</span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">epoch_ratio</div>
              <div className="font_body_s text-on_surface font_body_number_s">{item.epoch_ratio}</div>
            </div>
          )}
          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">staking_coin_weights</div>
            <div className="font_body_s grow text-on_surface">
              {item.staking_coin_weights.map((weight) => (
                <div className="flex mb-4" key={weight.denom}>
                  <div className="basis-20 font_body_s">{weight.denom}</div>
                  <div className="font_body_s font_body_number_s">{weight.amount}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {
        <>
          {content.create_plan_requests && <div className="sBOLD16 text-on_surface">create_plan_requests</div>}
          {(content.create_plan_requests as CreatePlanRequest[])?.map((item, index) => (
            <div className="flex flex-col mt-6 break-all gap-y-4" key={`create_plan_requests${index}`}>
              {item.description && <ParameterDetailItem label="description" value={item.description} />}
              {item.start_time && <ParameterDetailItem label="start_time" value={item.start_time} />}
              {item.end_time && <ParameterDetailItem label="end_time" value={item.end_time} />}
              {item.farming_pool_address && (
                <ParameterDetailItem label="farming_pool_address" value={item.farming_pool_address} />
              )}
              {item.reward_allocations && (
                <ParameterDetailItem
                  column={isMobile()}
                  label="reward_allocations"
                  value={
                    <div className="space-y-5">
                      {item.reward_allocations.map((allocation) => (
                        <div key={allocation.pair_id} className="flex flex-col gap-y-2">
                          <ParameterDetailItem opacity={false} label="pair_id" value={allocation.pair_id} />
                          <ParameterDetailItem opacity={false} label="denom" value={allocation.denom} />
                          <ParameterAmountGroup label="rewards_per_day" coins={allocation.rewards_per_day} />
                        </div>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
          ))}{' '}
        </>
      }
      {
        <>
          {content.terminate_plan_requests && <div className="sBOLD16 text-on_surface">terminate_plan_requests</div>}
          {(content.terminate_plan_requests as TerminatePlanRequest[])?.map((item, index) => (
            <div className="flex flex-col mt-6 break-all gap-y-4" key={`terminate_plan_requests${index}`}>
              {item.plan_id && <ParameterDetailItem label="plan_id" value={item.plan_id} />}
            </div>
          ))}
        </>
      }
      {content.modify_plan_requests?.map((item) => (
        <div className="mt-6 break-all" key={item.plan_id}>
          {item.plan_id && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">plan_id</div>
              <div className="font_body_s text-on_surface font_body_number_s">{item.plan_id}</div>
            </div>
          )}
          {item.name && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">name</div>
              <div className="font_body_s text-on_surface">{item.name}</div>
            </div>
          )}
          {item.start_time && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">start_time</div>
              <div className="font_body_s text-on_surface font_body_number_s">{item.start_time}</div>
            </div>
          )}
          {item.end_time && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">end_time</div>
              <div className="font_body_s text-on_surface font_body_number_s">{item.end_time}</div>
            </div>
          )}
          {item.farming_pool_address && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">farming_pool_address</div>
              <div className="font_body_s text-on_surface">{item.farming_pool_address}</div>
            </div>
          )}
          {item.termination_address && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">termination_address</div>
              <div className="font_body_s text-on_surface">{item.termination_address}</div>
            </div>
          )}

          {item.epoch_amount.length > 0 && (
            <>
              <div className="flex mt-4">
                <div className="basis-48 font_body_s text-on_surface">epoch_amount</div>
                <div className="font_body_s text-on_surface">
                  <span className="mr-0.5 font_body_number_s">{item.epoch_amount[0].amount}</span>
                  <span>{item.epoch_amount[0].denom}</span>
                </div>
              </div>
              {item.epoch_amount.slice(1).map((coin) => (
                <div className="flex mt-4" key={coin.denom}>
                  <div className="basis-48 font_body_s text-on_surface"></div>
                  <div className="font_body_s text-on_surface">
                    <span className="mr-0.5 font_body_number_s">{coin.amount}</span>
                    <span>{coin.denom}</span>
                  </div>
                </div>
              ))}
            </>
          )}
          {parseFloat(item.epoch_ratio) > 0 && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">epoch_ratio</div>
              <div className="font_body_s text-on_surface">{item.epoch_ratio}</div>
            </div>
          )}
          {item.staking_coin_weights.length > 0 && (
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">staking_coin_weights</div>
              <div className="font_body_s grow text-on_surface">
                {item.staking_coin_weights.map((weight) => (
                  <div className="flex mb-4" key={weight.denom}>
                    <div className="basis-20 font_body_s">{weight.denom}</div>
                    <div className="font_body_s font_body_number_s">{weight.amount}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      {content.delete_plan_requests?.map((item) => (
        <div className="mt-6 break-all" key={item.plan_id}>
          <div className="flex mt-4">
            <div className="basis-48 font_body_s text-on_surface">plan_id</div>
            <div className="font_body_s text-on_surface font_body_number_s">{item.plan_id}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ParameterChangeDetatil = ({ content }: { content: any }) => {
  return (
    <div className="break-all">
      {content?.changes?.map((item) => {
        let jsonValue;
        try {
          jsonValue = JSON.parse(item.value);
        } catch (e) {}
        return (
          <div className="mt-6 break-all" key={item.value}>
            <div className="flex">
              <div className="basis-48 font_body_s text-on_surface">Key</div>
              <div className="font_body_s text-on_surface">{item.key}</div>
            </div>
            <div className="flex mt-4">
              <div className="basis-48 font_body_s text-on_surface">subspace</div>
              <div className="font_body_s text-on_surface">{item.subspace}</div>
            </div>
            <div className="mt-6">
              {jsonValue && Array.isArray(jsonValue) ? (
                <>
                  <div className="font_body_s text-on_surface">value</div>
                  {jsonValue?.map?.((_value) =>
                    Object.keys(_value).map((item) => {
                      return (
                        <div className="flex mt-4" key={item}>
                          <div className="basis-48 font_body_s text-on_surface">{item}</div>
                          <div className="font_body_s text-on_surface">{_value[item]}</div>
                        </div>
                      );
                    })
                  )}
                </>
              ) : jsonValue && typeof jsonValue === 'object' ? (
                <>
                  <div className="font_body_s text-on_surface">value</div>
                  {Object.keys(jsonValue).map((item) => {
                    return (
                      <div className="flex mt-4" key={item}>
                        <div className="basis-48 font_body_s text-on_surface">{item}</div>
                        <div className="font_body_s text-on_surface">{JSON.stringify(jsonValue[item])}</div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="flex">
                  <div className="basis-48 font_body_s text-on_surface">value</div>
                  <div className="font_body_s text-on_surface">{item.value}</div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

function ParameterDetailItem({
  label,
  value,
  valueType = 'string',
  opacity = true,
  column = false,
}: {
  label: string;
  value: string | number | JSX.Element;
  valueType?: 'string' | 'number';
  opacity?: boolean;
  column?: boolean;
}) {
  return (
    <div className={`flex w-full ${column ? 'flex-col gap-y-4' : ''}`}>
      <div
        className={`grow-0 shrink-0 ${column ? 'basis-auto' : 'basis-48'} font_body_s ${
          opacity ? 'text-on_surface' : ''
        }`}
      >
        {label}
      </div>
      <div
        className={`grow shrink font_body_s ${opacity ? 'text-on_surface' : ''} ${
          valueType === 'number' ? 'font_body_number_s' : ''
        }`}
      >
        {value}
      </div>
    </div>
  );
}

function ParameterAmountGroup({ label, coins }: { label: string; coins: Coin[] }) {
  return (
    <div className="flex flex-col gap-y-4">
      <ParameterAmount label={label} coin={coins[0]} />
      {coins.slice(1).map((coin) => (
        <ParameterAmount key={coin.denom} coin={coin} />
      ))}
    </div>
  );
}

function ParameterAmount({ label, coin }: { label?: string; coin: Coin }) {
  return (
    <div className="flex">
      <div className="basis-48 font_body_s">{label}</div>
      <div className="font_body_s">
        <span className="mr-0.5 font_body_number_s">{coin.amount}</span>
        <span>{coin.denom}</span>
      </div>
    </div>
  );
}
