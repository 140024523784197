import { useMemo } from 'react';
import { PoolDetail } from 'types/pool';

export default function useTVL(pool: PoolDetail) {
  const tvl = useMemo(() => {
    return pool.totalSupplyAmount.multipliedBy(pool.priceOracle);
  }, [pool]);

  return tvl;
}

// export default function useTVL(pool: PoolDetail) {
//   const store = useMainStore();

//   const tvl = useMemo(() => {
//     if (store.assetsData?.live?.[pool.assets[0].baseDenom] && store.assetsData?.live?.[pool.assets[0].baseDenom]) {
//       const valueX = pool.reserved[0].amount
//         .shiftedBy(-pool.assets[0].exponent)
//         .multipliedBy(store.assetsData.live[pool.assets[0].baseDenom].priceOracle);
//       const valueY = pool.reserved[1].amount
//         .shiftedBy(-pool.assets[0].exponent)
//         .multipliedBy(store.assetsData.live[pool.assets[1].baseDenom].priceOracle);

//       // 가격 있는것만 보여줌.
//       if (!valueX.isNaN() && !valueY.isNaN()) {
//         return valueX.plus(valueY);
//       } else if (!valueX.isNaN()) {
//         return valueX;
//       } else if (!valueY.isNaN()) {
//         return valueY;
//       }
//     }
//     return new BigNumber(0);
//   }, [pool]);

//   return tvl;
// }
