import { useState, useEffect } from 'react';
import { INFOBOX_TEXTS, IS_LF_ENABLED } from 'COMMON_VARIABLES';
import { observer } from 'mobx-react-lite';
import { PoolDetail } from 'types/pool';
import ToggleButton from 'components/buttons/ToggleButton';
import { formatAmount, formatUSD } from 'common/utils';
import Checkbox from 'components/checkbox/Checkbox';
import useUnfarm from './hooks/useUnfarm';
import TextedRanger from 'components/ranger/TextedRanger';
import LabeledData from 'components/texts/LabeledData';
import NumberText from 'components/texts/NumberText';
import Divider from 'components/divider/Divider';
import InfoBox from 'components/infobox';
import Button from 'components/buttons/Button';
import useFarmButton from './hooks/useFarmButton';

const Unfarm = ({ pool, isLfByDefault, close }: { pool: PoolDetail; isLfByDefault: boolean; close: () => void }) => {
  const [rate, setRate] = useState(0);

  /** @summary state ramifies according to isLf */
  const {
    isLf,
    setIsLf,
    state,
    receiveAmountPerLfToken,
    lfReceivePoolTokenAmount,
    withdraw,
    setWithdraw,
    withdrawTokensAmount,
    isLoading,
  } = useUnfarm({ pool, rate, isLfByDefault, onTxSuccess: () => setRate(0) });

  useEffect(() => setRate(0), [isLf]);

  /** @desc new */
  const button = useFarmButton({
    isLoading,
    activeLabel: 'Unfarm',
    amount: state.amount,
    balance: state.balance,
    noBalanceLabel: isLf ? 'No LF token' : 'No pool token',
  });

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-3 md:space-y-6">
          {IS_LF_ENABLED && pool.lfEnabled && (
            <div className="flex items-center space-x-2">
              <ToggleButton size="sm" color="liquid" toggle={() => setIsLf(!isLf)} isEnabled={isLf} />
              <div className="font_caption_s text-on_surface_variant_dark">Unfarm LF token</div>
            </div>
          )}

          <TextedRanger
            color={isLf ? 'liquid' : 'primary'}
            rate={rate}
            unit="%"
            captionNumber={formatUSD(state.amountUSD)}
            onChange={setRate}
            disabled={!state.balance.gt(0)}
          />

          <div className="space-y-3 md:space-y-4">
            <LabeledData
              label={isLf ? 'You have' : 'You farmed'}
              value={
                <NumberText
                  size="sm"
                  value={formatAmount(state.balance.shiftedBy(-state.exponenet), state.exponenet)}
                  unit={state.ticker}
                />
              }
            />

            {state.balance.gt(0) && (
              <div className="space-y-3 md:space-y-4">
                <Divider type="hard" />

                {isLf && (
                  <Checkbox
                    color="liquid"
                    label="Unfarm and withdraw underlying assets at one go."
                    defaultChecked={withdraw}
                    onChange={setWithdraw}
                  />
                )}

                {!isLf && (
                  <LabeledData
                    label="You will unfarm"
                    value={
                      <NumberText
                        size="sm"
                        color="primary"
                        value={formatAmount(state.amount.shiftedBy(-state.exponenet), state.exponenet)}
                        unit={state.ticker}
                      />
                    }
                  />
                )}

                {isLf && (
                  <>
                    {withdraw ? (
                      <div className="space-y-2">
                        <LabeledData
                          label="You will receive"
                          labelTooltipContent={`Return rate \n1 LF Token: ${formatAmount(
                            receiveAmountPerLfToken,
                            12
                          )} Pool Token`}
                          value={
                            <NumberText
                              size="sm"
                              color="primary"
                              value={formatAmount(withdrawTokensAmount[0], pool.assets[0].exponent)}
                              unit={pool.assets[0].ticker}
                            />
                          }
                        />
                        <div className="flex justify-end">
                          <NumberText
                            size="sm"
                            color="primary"
                            value={formatAmount(withdrawTokensAmount[1], pool.assets[1].exponent)}
                            unit={pool.assets[1].ticker}
                          />
                        </div>
                      </div>
                    ) : (
                      <LabeledData
                        label="You will receive"
                        labelTooltipContent={`Return rate \n1 LF Token: ${formatAmount(
                          receiveAmountPerLfToken,
                          12
                        )} Pool Token`}
                        value={
                          <NumberText
                            size="sm"
                            color="primary"
                            value={formatAmount(lfReceivePoolTokenAmount, 12)}
                            unit="Pool Token"
                          />
                        }
                      />
                    )}
                  </>
                )}
              </div>
            )}

            {isLf && <InfoBox>{INFOBOX_TEXTS.LF_UNFARM}</InfoBox>}
          </div>
        </div>

        <Button
          size="lg"
          color={isLf ? 'liquid' : 'plain'}
          label={button.text}
          status={button.status}
          onClick={state.onAction}
          className="w-full"
        />
      </div>
    </>
  );
};

export default observer(Unfarm);
