import TooltipIcon from 'components/tooltips/TooltipIcon';

export type ChipColor =
  | 'gold'
  | 'green'
  | 'red'
  | 'blue'
  | 'yellow'
  | 'gray'
  | 'deposit'
  | 'yes'
  | 'no'
  | 'veto'
  | 'abstain';

type ChipStyle = 'tonal' | 'outlined';
export type ChipSize = 'xs' | 'sm' | 'md';
type ChipLabelType = 'string' | 'number';

const CHIP_COLOR_DICT: { [key in ChipStyle]: { [key in ChipColor]: string } } = {
  tonal: {
    gold: 'bg-semantic_gold_o24 text-semantic_gold',
    green: 'bg-semantic_green_o24 text-semantic_green',
    red: 'bg-semantic_red_o24 text-semantic_red',
    blue: 'bg-semantic_blue_o24 text-semantic_blue',
    yellow: 'bg-semantic_yellow_o24 text-semantic_yellow',
    gray: 'bg-semantic_gray_o24 text-semantic_gray',
    deposit: 'bg-gov_deposit_o24 text-gov_deposit',
    yes: 'bg-gov_yes_o24 text-gov_yes',
    no: 'bg-gov_no_o24 text-gov_no',
    veto: 'bg-gov_no_o24 text-gov_nowithveto',
    abstain: 'bg-gov_abstain_o24 text-gov_abstain',
  },
  outlined: {
    gold: 'border border-semantic_gold text-semantic_gold',
    green: 'border border-semantic_green text-semantic_green',
    red: 'border border-semantic_red text-semantic_red',
    blue: 'border border-semantic_blue text-semantic_blue',
    yellow: 'border border-semantic_yellow text-semantic_yellow',
    gray: 'border border-semantic_gray text-semantic_gray',
    deposit: 'border border-gov_deposit text-gov_deposit',
    yes: 'border border-gov_yes text-gov_yes',
    no: 'border border-gov_no text-gov_no',
    veto: 'border border-gov_nowithveto text-gov_nowithveto',
    abstain: 'border border-gov_abstain text-gov_abstain',
  },
};

/** @desc defined right only for tonal atm. */
const CHIP_SUB_TYPE_DICT: { [key in ChipStyle]: ChipStyle } = {
  tonal: 'outlined',
  outlined: 'tonal',
};

const CHIP_SIZE_DICT: { [key in ChipSize]: { main: string; sub: string } } = {
  xs: { main: 'max-h-[1rem] px-1 py-[0.625rem]', sub: 'max-h-[0.875rem] px-1' },
  sm: { main: 'max-h-[1.25rem] px-1 py-1', sub: 'max-h-[1rem] px-1' },
  md: { main: 'max-h-[2rem] px-2 py-[0.4375rem]', sub: 'max-h-[1.25rem] px-1 py-1' },
};

const CHIP_FONT_DICT: { [key in ChipSize]: { [key in ChipLabelType]: { main: string; sub: string } } } = {
  xs: {
    string: { main: 'font_label_xs', sub: 'font_label_xs' },
    number: { main: 'font_label_number_xs', sub: 'font_label_number_xs' },
  },
  sm: {
    string: { main: 'font_label_s', sub: 'font_label_xs' },
    number: { main: 'font_label_number_s', sub: 'font_label_number_xs' },
  },
  md: {
    string: { main: 'font_label_m', sub: 'font_label_s' },
    number: { main: 'font_label_number_m', sub: 'font_label_number_s' },
  },
};

type ChipProps = {
  color?: ChipColor;
  label: string;
  labelType?: ChipLabelType;
  subColor?: ChipColor;
  subLabel?: string;
  subLabelType?: ChipLabelType;
  type?: ChipStyle;
  size: ChipSize;
  tooltipContent?: JSX.Element | string;
};

const Chip = ({
  color = 'gold',
  label,
  labelType = 'string',
  subColor = 'gold',
  subLabel,
  subLabelType = 'string',
  type = 'tonal',
  size,
  tooltipContent,
}: ChipProps) => {
  return (
    <div
      className={`flex items-center gap-x-1 rounded-4px ${CHIP_SIZE_DICT[size].main} ${CHIP_COLOR_DICT[type][color]}`}
    >
      <div className="flex items-center gap-x-2">
        <div className={`whitespace-nowrap ${CHIP_FONT_DICT[size][labelType].main}`}>
          {label}
          {subLabel ? ':' : ''}
        </div>

        {subLabel && (
          <div
            className={`flex items-center rounded-4px ${CHIP_COLOR_DICT[CHIP_SUB_TYPE_DICT[type]][subColor]} ${
              CHIP_SIZE_DICT[size].sub
            } ${CHIP_FONT_DICT[size][subLabelType].sub}`}
          >
            {subLabel}
          </div>
        )}
      </div>

      {tooltipContent !== undefined && <TooltipIcon tooltipContent={tooltipContent} />}
    </div>
  );
};

export default Chip;
