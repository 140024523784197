import { getTickerAnatomy } from 'common/utils';
import { useMemo } from 'react';
import type { TickerAnatomy } from 'types/asset';
import type { PairDetail } from 'types/pair';
import type { PoolDetail } from 'types/pool';

type PairTickerSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const PAIR_TICKER_TYPO_DICT: {
  [key in PairTickerSize]: {
    text: string;
    slash: string;
  };
} = {
  sm: { text: 'font_title_s', slash: 'font_title_s' },
  md: { text: 'font_title_m', slash: 'font_title_m' },
  lg: { text: 'font_title_xl', slash: 'font_title_m' },
  xl: { text: 'font_headline_xs_mobile md:font_headline_s', slash: 'font_headline_xs_mobile md:font_headline_s' },
  '2xl': { text: 'font_headline_l_mobile md:font_headline_l', slash: 'font_headline_xs_mobile md:font_headline_s' },
};

const PAIR_DIMMED_TICKER_TYPO_DICT: {
  [key in PairTickerSize]: string;
} = {
  sm: 'font_title_xs',
  md: 'font_title_s',
  lg: 'font_title_m',
  xl: 'font_headline_xs_mobile md:font_headline_s',
  '2xl': 'font_headline_xs_mobile md:font_headline_s',
};

const PairTicker = ({
  pair,
  size = 'md',
  color = 'surface',
  isWeakQuote = false,
  className = '',
}: {
  pair: PairDetail | PoolDetail;
  size?: PairTickerSize;
  color?: 'surface' | 'surface-variant';
  isWeakQuote?: boolean;
  className?: string;
}) => {
  const tickerAnatomies = useMemo<[TickerAnatomy, TickerAnatomy]>(
    () => [getTickerAnatomy(pair.assets[0].ticker), getTickerAnatomy(pair.assets[1].ticker)],
    [pair.assets]
  );

  return (
    <div
      className={`${className} overflow-hidden flex items-baseline gap-x-0.5 ${
        color === 'surface' ? 'text-on_surface' : 'text-on_surface_variant_light'
      } ${PAIR_TICKER_TYPO_DICT[size].text}`}
    >
      <div className="flex items-baseline">
        <div>{tickerAnatomies[0].ticker}</div>
        <div>{tickerAnatomies[0].bridgeFix}</div>
      </div>

      <div
        className={`${color === 'surface' ? 'text-on_surface_variant_dark' : 'text-on_surface_variant_light'} ${
          PAIR_TICKER_TYPO_DICT[size].slash
        }`}
      >
        /
      </div>

      <div
        className={`flex items-baseline ${
          isWeakQuote ? `${PAIR_DIMMED_TICKER_TYPO_DICT[size]} text-on_surface_variant_dark` : ''
        }`}
      >
        <div>{tickerAnatomies[1].ticker}</div>
        <div>{tickerAnatomies[1].bridgeFix}</div>
      </div>
    </div>
  );
};

export default PairTicker;
