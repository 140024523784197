import BigNumber from "bignumber.js";
import { getUnderlyingBCreApr } from "common/utils";
import useTVL from "hooks/farm/useTVL";
import { useMainStore } from "provider/mainContext";
import { useMemo } from "react";
import { PoolDetail } from "types/pool";

const usePoolApr = (pool: PoolDetail) => {
    const store = useMainStore();
    const bcreApr = store.getLiquidStakingApr();
    const tvl = useTVL(pool);
    const underlyingBCreApr = useMemo<BigNumber>(() => getUnderlyingBCreApr(pool, tvl, bcreApr), [pool, tvl, bcreApr]);
  
    return { underlyingBCreApr };
}

export default usePoolApr;