import callToast from 'common/callToast';
import WalletLogo from 'components/logos/WalletLogo';
import { Wallet, WalletType } from 'types/wallet';
import { isInstalled as CosmostaionIsInstalled } from '@cosmostation/extension-client';
import {
  addChainsToCosmostation,
  connectCosmostation,
  getCosmostationWallet,
  logCosmostationWallet,
  subscribeCosmostationChange,
} from './cosmostation';
import { addChainsToKeplr, connectKeplr, getKeplrWallet, logKeplrWallet, subscribeKeplrChange } from './keplr';
import { addChainsToLeap, connectLeap, getLeapWallet, logLeapWallet, subscribeLeapChange } from './leap';
import { connectWC, getWCWallet, installWCCosmostation } from './walletconnect';
import { formatTextCase } from 'common/utils';
import WalletConnect from '@walletconnect/client';
import { InfoChain } from 'types/chain';

const notifyWalletInstall = (walletType: WalletType.COSMOSTATION | WalletType.KEPLR | WalletType.LEAP) => {
  const walletName = formatTextCase(walletType, { capitalize: 'first' });

  callToast({
    type: 'info',
    toastId: `install-${walletType}`,
    title: (
      <div className="flex items-center gap-x-2">
        <div>Install {walletName} Wallet</div>
        <WalletLogo type={walletType} />
      </div>
    ),
    content: `Welcome! Please install ${walletName} extension and retry.`,
    autoClose: false,
  });
};

export const isWalletInstalled = (walletType: WalletType) => {
  switch (walletType) {
    case WalletType.COSMOSTATION:
      return CosmostaionIsInstalled();
    case WalletType.KEPLR:
      return !!window.keplr;
    case WalletType.LEAP:
      return !!window.leap;
    case WalletType.WALLETCONNECT:
      return window.navigator.userAgent.includes('Cosmostation');
    default:
      return false;
  }
};

export const onWalletUninstalled = (walletType: WalletType) => {
  switch (walletType) {
    case WalletType.COSMOSTATION:
      // notifyWalletInstall(walletType);
      break;
    case WalletType.KEPLR:
      notifyWalletInstall(walletType);
      break;
    case WalletType.LEAP:
      notifyWalletInstall(walletType);
      break;
    case WalletType.WALLETCONNECT:
      installWCCosmostation();
      break;
  }
};

type GetChainNormalWalletOptions = { walletType: WalletType.COSMOSTATION | WalletType.KEPLR | WalletType.LEAP };
type GetChainWalletConnectWalletOptions = { walletType: WalletType.WALLETCONNECT; connector: WalletConnect };

export const getChainWallet = (
  chainInfo: InfoChain,
  options: GetChainNormalWalletOptions | GetChainWalletConnectWalletOptions
) => {
  switch (options.walletType) {
    case WalletType.COSMOSTATION:
      return getCosmostationWallet(chainInfo);
    case WalletType.KEPLR:
      return getKeplrWallet(chainInfo);
    case WalletType.WALLETCONNECT:
      return getWCWallet(options.connector, chainInfo.chainId);
    case WalletType.LEAP:
      return getLeapWallet(chainInfo);
  }
};

const WALLET_CONNECT_FUNC_DICT: Record<WalletType, (isAutoLogin?: boolean) => Promise<Wallet | null>> = {
  [WalletType.COSMOSTATION]: connectCosmostation,
  [WalletType.KEPLR]: connectKeplr,
  [WalletType.LEAP]: connectLeap,
  [WalletType.WALLETCONNECT]: connectWC,
};

export const connectWallet = (walletType: WalletType, isAutoLogin?: boolean) =>
  WALLET_CONNECT_FUNC_DICT[walletType](isAutoLogin);

export const subscribeWalletChange = (
  walletType: WalletType.KEPLR | WalletType.COSMOSTATION | WalletType.LEAP,
  callback: () => void
) => {
  let subscribeFn;
  if (WalletType.LEAP === walletType) {
    subscribeFn = subscribeLeapChange;
  } else if (WalletType.COSMOSTATION === walletType) {
    subscribeFn = subscribeCosmostationChange;
  } else {
    subscribeFn = subscribeKeplrChange;
  }
  return subscribeFn(callback);
};

export const addChainsToWallet = (
  walletType: WalletType.KEPLR | WalletType.COSMOSTATION | WalletType.LEAP,
  chainInfos: InfoChain[]
) => {
  switch (walletType) {
    case WalletType.COSMOSTATION:
      return addChainsToCosmostation(chainInfos);
    case WalletType.KEPLR:
      return addChainsToKeplr(chainInfos);
    case WalletType.LEAP:
      return addChainsToLeap(chainInfos);
  }
};

export const logWallet = (walletType: WalletType) => {
  switch (walletType) {
    case WalletType.COSMOSTATION:
      return logCosmostationWallet();
    case WalletType.KEPLR:
      return logKeplrWallet();
    case WalletType.LEAP:
      return logLeapWallet();
    case WalletType.WALLETCONNECT:
      return;
  }
};
