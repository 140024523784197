import { IconSize } from 'components/icon';

export type IconButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const ICON_BUTTON_SIZE_DICT: { [key in IconButtonSize]: { button: string; icon: IconSize } } = {
  xs: {
    button: 'w-5 h-5',
    icon: '14px',
  },
  sm: {
    button: 'w-6 h-6',
    icon: '16px',
  },
  md: {
    button: 'w-8 h-8',
    icon: '20px',
  },
  lg: {
    button: 'w-9 h-9',
    icon: '24px',
  },
  xl: {
    button: 'w-10 h-10',
    icon: '28px',
  },
};
