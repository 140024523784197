import Badge from 'components/badges/Badge';
import Coin from 'components/coins/Coin';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import { AssetDetail } from 'types/asset';

const TokenTitle = ({ asset, badgeLabel }: { asset: AssetDetail; badgeLabel?: string }) => {
  return (
    <div className="flex items-center gap-x-2">
      <Coin asset={asset} size="36px" />

      <div className="space-y-1 text-left">
        <div className="flex items-center gap-x-1">
          <div className="font_title_m text-on_surface">{asset.ticker}</div>
          <div className="text-on_surface_variant_dark">
            <TooltipIcon tooltipSize="220px" tooltipWordBreak="break-all" tooltipContent={asset.denom} />
          </div>
          {badgeLabel && <Badge label={badgeLabel} color="green" />}
        </div>
        <div className="font_caption_s text-on_surface_variant_dark">{asset.chainName.toUpperCase()}</div>
      </div>
    </div>
  );
};

export default TokenTitle;
