import InputField from './InputField';

type SearchInputProps = {
  value?: string;
  onChangeValue?: (value: string) => void;
  placeholder?: string;
  isError?: boolean;
  disabled?: boolean;
  className?: string;
};

export default function SearchInput({
  value,
  onChangeValue,
  placeholder = 'Search',
  isError,
  disabled,
  className = '',
}: SearchInputProps) {
  return (
    <InputField
      type="text"
      leadingIcon="search"
      resetEnabled={true}
      placeholder={placeholder}
      value={value}
      isError={isError}
      disabled={disabled}
      onChangeValue={onChangeValue}
      className={className}
    />
  );
}
