const EarnIconSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6767 7.67462C17.6767 10.8097 15.1352 13.3512 12.0001 13.3512C8.86506 13.3512 6.32357 10.8097 6.32357 7.67462C6.32357 4.53954 8.86506 1.99805 12.0001 1.99805C15.1352 1.99805 17.6767 4.53954 17.6767 7.67462ZM12.6745 11.7197H11.3262V10.9107H9.97785V9.29264H12.6745V8.48363H10.652C10.2812 8.48363 9.97785 8.11958 9.97785 7.67462V5.24759C9.97785 4.80264 10.2812 4.43859 10.652 4.43859H11.3262V3.62958H12.6745V4.43859H14.0229V6.0566H11.3262V6.86561H13.3487C13.7195 6.86561 14.0229 7.22967 14.0229 7.67462V10.1016C14.0229 10.5466 13.7195 10.9107 13.3487 10.9107H12.6745V11.7197Z"
        fill="currentColor"
      />
      <path
        d="M3.99762 19.0765C3.68058 18.9397 3.47525 18.6275 3.47524 18.2822C3.47524 17.7548 3.94323 17.3501 4.46515 17.4263L9.17974 18.1142C10.0349 18.2389 10.9078 18.1392 11.7128 17.8248C11.8009 17.7904 11.7939 17.6635 11.7026 17.639L10.2241 17.2429C9.70855 17.1048 9.40261 16.5749 9.54075 16.0593C9.67888 15.5438 10.2088 15.2379 10.7243 15.376L17.1374 17.0944V16.3557H20.5252V22.0021H17.1374V21.1934H10.3176C9.38541 21.1934 8.46309 21.0029 7.60719 20.6337L3.99762 19.0765Z"
        fill="currentColor"
      />
      <path
        d="M20.8269 4.62471L20.2002 5.99805L19.5735 4.62471L18.2002 3.99805L19.5735 3.37138L20.2002 1.99805L20.8269 3.37138L22.2002 3.99805L20.8269 4.62471Z"
        fill="currentColor"
      />
      <path
        d="M3.8202 12.1437L2.4502 12.7737L3.8202 13.4037L4.4502 14.7737L5.08019 13.4037L6.4502 12.7737L5.08019 12.1437L4.4502 10.7737L3.8202 12.1437Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EarnIconSvg;
