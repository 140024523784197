import { useMemo } from 'react';
import { InfoAsset } from 'types/asset';
import Coin from './Coin';
import { CoinColor, CoinSize, COIN_SIZE_REM_DICT } from './constans';
import { LogoStacking, COIN_LOGO_SIZE_DICT, DUMMY_COIN_LOGO_URL } from './constants';

const CoinList = ({
  color,
  assets,
  size = '16px',
  maxCnt = 2,
  showHiddenCnt = false,
  stacking = LogoStacking.LAST_ON_TOP,
  className = '',
}: {
  color?: CoinColor;
  assets: InfoAsset[];
  size?: CoinSize;
  maxCnt?: number;
  showHiddenCnt?: boolean;
  stacking?: LogoStacking;
  className?: string;
}) => {
  const displayedAssets = useMemo<InfoAsset[]>(() => assets.slice(0, maxCnt), [assets, maxCnt]);
  const overflowCnt = useMemo<number>(() => assets.length - maxCnt, [assets.length, maxCnt]);

  const wrapperRemSize = useMemo<number>(() => {
    const fakeCnt = showHiddenCnt && assets.length > maxCnt ? 1 : 0;
    return (
      COIN_SIZE_REM_DICT[size] * (displayedAssets.length + fakeCnt) -
      (COIN_SIZE_REM_DICT[size] / 3) * (displayedAssets.length + fakeCnt - 1)
    );
  }, [showHiddenCnt, displayedAssets.length, assets.length, maxCnt, size]);

  return (
    <div
      className={`${className} relative z-0`}
      style={{ width: `${wrapperRemSize}rem`, height: `${COIN_SIZE_REM_DICT[size]}rem` }}
    >
      {displayedAssets.map((asset, index) => (
        <div
          key={asset.denom}
          title={asset.ticker}
          className="absolute top-0"
          style={{
            left: `${((COIN_SIZE_REM_DICT[size] * 2) / 3) * index}rem`,
            zIndex: stacking === LogoStacking.LAST_ON_TOP ? index : displayedAssets.length - index,
          }}
        >
          <Coin asset={asset} size={size} color={color} />
        </div>
      ))}

      {/* overflow coins cnt */}
      {showHiddenCnt && assets.length > maxCnt && (
        <div
          className={`absolute top-0 rounded-full overflow-hidden ${COIN_LOGO_SIZE_DICT[size]}`}
          style={{
            left: `${((COIN_SIZE_REM_DICT[size] * 2) / 3) * displayedAssets.length}rem`,
            zIndex: stacking === LogoStacking.LAST_ON_TOP ? displayedAssets.length : 0,
          }}
        >
          <img src={DUMMY_COIN_LOGO_URL} alt={`${overflowCnt} more`} className="w-full h-full" />
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex justify-center items-center bg-on_surface_o28 text-on_surface font_data_number_s">
            +{overflowCnt}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoinList;
