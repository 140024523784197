import Icon from 'components/icon';

type ExternalLinkProps = {
  text?: string;
  href: string;
  className?: string;
  disabled?: boolean;
  disabledAlert?: string;
};

const ExternalLink = ({ text, href, className, disabled = false, disabledAlert }: ExternalLinkProps) => {
  const onDisabledClick = () => {
    if (disabled && disabledAlert !== undefined) {
      alert(disabledAlert);
    }
  };

  return (
    <div className={`${className} inline-block`}>
      <div className={`flex ${disabled ? 'cursor-not-allowed' : ''}`} onClick={onDisabledClick}>
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={`flex items-center ${disabled ? 'opacity-40 pointer-events-none' : 'hover:underline'}`}
          aria-disabled={disabled}
        >
          <Icon type="open-in-new" size="16px" />
          {text !== undefined && <div className="ml-1 font_body_xs">{text}</div>}
        </a>
      </div>
    </div>
  );
};

export default ExternalLink;
