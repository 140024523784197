/* eslint-disable */
import { Reader, util, configure, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { Coin } from '../../../cosmos/base/v1beta1/coin';

export const protobufPackage = 'crescent.liquidfarming.v1beta1';

/** MsgLiquidFarm defines a SDK message for farming pool coin for a liquid farm. */
export interface MsgLiquidFarm {
  poolId: number;
  farmer: string;
  farmingCoin: Coin | undefined;
}

/** MsgLiquidFarmResponse defines the MsgLiquidFarmResponse response type. */
export interface MsgLiquidFarmResponse {}

/** MsgLiquidUnfarm defines a SDK message for unfarming LFCoin. */
export interface MsgLiquidUnfarm {
  poolId: number;
  farmer: string;
  unfarmingCoin: Coin | undefined;
}

/** MsgLiquidUnfarmResponse defines the MsgLiquidUnfarmResponse response type. */
export interface MsgLiquidUnfarmResponse {}

/** MsgLiquidUnfarmAndWithdraw defines a SDK message for unfarming LFCoin. */
export interface MsgLiquidUnfarmAndWithdraw {
  poolId: number;
  farmer: string;
  unfarmingCoin: Coin | undefined;
}

/** MsgLiquidUnfarmAndWithdrawResponse defines the MsgLiquidUnfarmAndWithdrawResponse response type. */
export interface MsgLiquidUnfarmAndWithdrawResponse {}

/** MsgPlaceBid defines a SDK message for placing a bid for a rewards auction. */
export interface MsgPlaceBid {
  auctionId: number;
  poolId: number;
  bidder: string;
  biddingCoin: Coin | undefined;
}

/** MsgPlaceBidResponse defines the MsgPlaceBidResponse response type. */
export interface MsgPlaceBidResponse {}

/** MsgRefundBid defines a SDK message for refunding the bid that is not winning for the auction. */
export interface MsgRefundBid {
  auctionId: number;
  poolId: number;
  bidder: string;
}

/** MsgRefundBidResponse defines the MsgRefundBidResponse response type. */
export interface MsgRefundBidResponse {}

/** MsgAdvanceAuction defines a message to advance rewards auction by one. */
export interface MsgAdvanceAuction {
  /** requester defines the bech32-encoded address of the requester */
  requester: string;
}

/** MsgAdvanceAuctionResponse defines the Msg/AdvanceAuction response type. */
export interface MsgAdvanceAuctionResponse {}

const baseMsgLiquidFarm: object = { poolId: 0, farmer: '' };

export const MsgLiquidFarm = {
  encode(message: MsgLiquidFarm, writer: Writer = Writer.create()): Writer {
    if (message.poolId !== 0) {
      writer.uint32(8).uint64(message.poolId);
    }
    if (message.farmer !== '') {
      writer.uint32(18).string(message.farmer);
    }
    if (message.farmingCoin !== undefined) {
      Coin.encode(message.farmingCoin, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgLiquidFarm {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgLiquidFarm } as MsgLiquidFarm;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.poolId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.farmer = reader.string();
          break;
        case 3:
          message.farmingCoin = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgLiquidFarm {
    const message = { ...baseMsgLiquidFarm } as MsgLiquidFarm;
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = Number(object.poolId);
    } else {
      message.poolId = 0;
    }
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = '';
    }
    if (object.farmingCoin !== undefined && object.farmingCoin !== null) {
      message.farmingCoin = Coin.fromJSON(object.farmingCoin);
    } else {
      message.farmingCoin = undefined;
    }
    return message;
  },

  toJSON(message: MsgLiquidFarm): unknown {
    const obj: any = {};
    message.poolId !== undefined && (obj.poolId = message.poolId);
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.farmingCoin !== undefined &&
      (obj.farmingCoin = message.farmingCoin ? Coin.toJSON(message.farmingCoin) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgLiquidFarm>): MsgLiquidFarm {
    const message = { ...baseMsgLiquidFarm } as MsgLiquidFarm;
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = object.poolId;
    } else {
      message.poolId = 0;
    }
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = '';
    }
    if (object.farmingCoin !== undefined && object.farmingCoin !== null) {
      message.farmingCoin = Coin.fromPartial(object.farmingCoin);
    } else {
      message.farmingCoin = undefined;
    }
    return message;
  },
};

const baseMsgLiquidFarmResponse: object = {};

export const MsgLiquidFarmResponse = {
  encode(_: MsgLiquidFarmResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgLiquidFarmResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgLiquidFarmResponse } as MsgLiquidFarmResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgLiquidFarmResponse {
    const message = { ...baseMsgLiquidFarmResponse } as MsgLiquidFarmResponse;
    return message;
  },

  toJSON(_: MsgLiquidFarmResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgLiquidFarmResponse>): MsgLiquidFarmResponse {
    const message = { ...baseMsgLiquidFarmResponse } as MsgLiquidFarmResponse;
    return message;
  },
};

const baseMsgLiquidUnfarm: object = { poolId: 0, farmer: '' };

export const MsgLiquidUnfarm = {
  encode(message: MsgLiquidUnfarm, writer: Writer = Writer.create()): Writer {
    if (message.poolId !== 0) {
      writer.uint32(8).uint64(message.poolId);
    }
    if (message.farmer !== '') {
      writer.uint32(18).string(message.farmer);
    }
    if (message.unfarmingCoin !== undefined) {
      Coin.encode(message.unfarmingCoin, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgLiquidUnfarm {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgLiquidUnfarm } as MsgLiquidUnfarm;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.poolId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.farmer = reader.string();
          break;
        case 3:
          message.unfarmingCoin = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgLiquidUnfarm {
    const message = { ...baseMsgLiquidUnfarm } as MsgLiquidUnfarm;
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = Number(object.poolId);
    } else {
      message.poolId = 0;
    }
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = '';
    }
    if (object.unfarmingCoin !== undefined && object.unfarmingCoin !== null) {
      message.unfarmingCoin = Coin.fromJSON(object.unfarmingCoin);
    } else {
      message.unfarmingCoin = undefined;
    }
    return message;
  },

  toJSON(message: MsgLiquidUnfarm): unknown {
    const obj: any = {};
    message.poolId !== undefined && (obj.poolId = message.poolId);
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.unfarmingCoin !== undefined &&
      (obj.unfarmingCoin = message.unfarmingCoin ? Coin.toJSON(message.unfarmingCoin) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgLiquidUnfarm>): MsgLiquidUnfarm {
    const message = { ...baseMsgLiquidUnfarm } as MsgLiquidUnfarm;
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = object.poolId;
    } else {
      message.poolId = 0;
    }
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = '';
    }
    if (object.unfarmingCoin !== undefined && object.unfarmingCoin !== null) {
      message.unfarmingCoin = Coin.fromPartial(object.unfarmingCoin);
    } else {
      message.unfarmingCoin = undefined;
    }
    return message;
  },
};

const baseMsgLiquidUnfarmResponse: object = {};

export const MsgLiquidUnfarmResponse = {
  encode(_: MsgLiquidUnfarmResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgLiquidUnfarmResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgLiquidUnfarmResponse,
    } as MsgLiquidUnfarmResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgLiquidUnfarmResponse {
    const message = {
      ...baseMsgLiquidUnfarmResponse,
    } as MsgLiquidUnfarmResponse;
    return message;
  },

  toJSON(_: MsgLiquidUnfarmResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgLiquidUnfarmResponse>): MsgLiquidUnfarmResponse {
    const message = {
      ...baseMsgLiquidUnfarmResponse,
    } as MsgLiquidUnfarmResponse;
    return message;
  },
};

const baseMsgLiquidUnfarmAndWithdraw: object = { poolId: 0, farmer: '' };

export const MsgLiquidUnfarmAndWithdraw = {
  encode(message: MsgLiquidUnfarmAndWithdraw, writer: Writer = Writer.create()): Writer {
    if (message.poolId !== 0) {
      writer.uint32(8).uint64(message.poolId);
    }
    if (message.farmer !== '') {
      writer.uint32(18).string(message.farmer);
    }
    if (message.unfarmingCoin !== undefined) {
      Coin.encode(message.unfarmingCoin, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgLiquidUnfarmAndWithdraw {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgLiquidUnfarmAndWithdraw,
    } as MsgLiquidUnfarmAndWithdraw;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.poolId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.farmer = reader.string();
          break;
        case 3:
          message.unfarmingCoin = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgLiquidUnfarmAndWithdraw {
    const message = {
      ...baseMsgLiquidUnfarmAndWithdraw,
    } as MsgLiquidUnfarmAndWithdraw;
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = Number(object.poolId);
    } else {
      message.poolId = 0;
    }
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = '';
    }
    if (object.unfarmingCoin !== undefined && object.unfarmingCoin !== null) {
      message.unfarmingCoin = Coin.fromJSON(object.unfarmingCoin);
    } else {
      message.unfarmingCoin = undefined;
    }
    return message;
  },

  toJSON(message: MsgLiquidUnfarmAndWithdraw): unknown {
    const obj: any = {};
    message.poolId !== undefined && (obj.poolId = message.poolId);
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.unfarmingCoin !== undefined &&
      (obj.unfarmingCoin = message.unfarmingCoin ? Coin.toJSON(message.unfarmingCoin) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgLiquidUnfarmAndWithdraw>): MsgLiquidUnfarmAndWithdraw {
    const message = {
      ...baseMsgLiquidUnfarmAndWithdraw,
    } as MsgLiquidUnfarmAndWithdraw;
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = object.poolId;
    } else {
      message.poolId = 0;
    }
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = '';
    }
    if (object.unfarmingCoin !== undefined && object.unfarmingCoin !== null) {
      message.unfarmingCoin = Coin.fromPartial(object.unfarmingCoin);
    } else {
      message.unfarmingCoin = undefined;
    }
    return message;
  },
};

const baseMsgLiquidUnfarmAndWithdrawResponse: object = {};

export const MsgLiquidUnfarmAndWithdrawResponse = {
  encode(_: MsgLiquidUnfarmAndWithdrawResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgLiquidUnfarmAndWithdrawResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgLiquidUnfarmAndWithdrawResponse,
    } as MsgLiquidUnfarmAndWithdrawResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgLiquidUnfarmAndWithdrawResponse {
    const message = {
      ...baseMsgLiquidUnfarmAndWithdrawResponse,
    } as MsgLiquidUnfarmAndWithdrawResponse;
    return message;
  },

  toJSON(_: MsgLiquidUnfarmAndWithdrawResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgLiquidUnfarmAndWithdrawResponse>): MsgLiquidUnfarmAndWithdrawResponse {
    const message = {
      ...baseMsgLiquidUnfarmAndWithdrawResponse,
    } as MsgLiquidUnfarmAndWithdrawResponse;
    return message;
  },
};

const baseMsgPlaceBid: object = { auctionId: 0, poolId: 0, bidder: '' };

export const MsgPlaceBid = {
  encode(message: MsgPlaceBid, writer: Writer = Writer.create()): Writer {
    if (message.auctionId !== 0) {
      writer.uint32(8).uint64(message.auctionId);
    }
    if (message.poolId !== 0) {
      writer.uint32(16).uint64(message.poolId);
    }
    if (message.bidder !== '') {
      writer.uint32(26).string(message.bidder);
    }
    if (message.biddingCoin !== undefined) {
      Coin.encode(message.biddingCoin, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgPlaceBid {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgPlaceBid } as MsgPlaceBid;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.auctionId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.poolId = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.bidder = reader.string();
          break;
        case 4:
          message.biddingCoin = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgPlaceBid {
    const message = { ...baseMsgPlaceBid } as MsgPlaceBid;
    if (object.auctionId !== undefined && object.auctionId !== null) {
      message.auctionId = Number(object.auctionId);
    } else {
      message.auctionId = 0;
    }
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = Number(object.poolId);
    } else {
      message.poolId = 0;
    }
    if (object.bidder !== undefined && object.bidder !== null) {
      message.bidder = String(object.bidder);
    } else {
      message.bidder = '';
    }
    if (object.biddingCoin !== undefined && object.biddingCoin !== null) {
      message.biddingCoin = Coin.fromJSON(object.biddingCoin);
    } else {
      message.biddingCoin = undefined;
    }
    return message;
  },

  toJSON(message: MsgPlaceBid): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auctionId = message.auctionId);
    message.poolId !== undefined && (obj.poolId = message.poolId);
    message.bidder !== undefined && (obj.bidder = message.bidder);
    message.biddingCoin !== undefined &&
      (obj.biddingCoin = message.biddingCoin ? Coin.toJSON(message.biddingCoin) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgPlaceBid>): MsgPlaceBid {
    const message = { ...baseMsgPlaceBid } as MsgPlaceBid;
    if (object.auctionId !== undefined && object.auctionId !== null) {
      message.auctionId = object.auctionId;
    } else {
      message.auctionId = 0;
    }
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = object.poolId;
    } else {
      message.poolId = 0;
    }
    if (object.bidder !== undefined && object.bidder !== null) {
      message.bidder = object.bidder;
    } else {
      message.bidder = '';
    }
    if (object.biddingCoin !== undefined && object.biddingCoin !== null) {
      message.biddingCoin = Coin.fromPartial(object.biddingCoin);
    } else {
      message.biddingCoin = undefined;
    }
    return message;
  },
};

const baseMsgPlaceBidResponse: object = {};

export const MsgPlaceBidResponse = {
  encode(_: MsgPlaceBidResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgPlaceBidResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgPlaceBidResponse } as MsgPlaceBidResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgPlaceBidResponse {
    const message = { ...baseMsgPlaceBidResponse } as MsgPlaceBidResponse;
    return message;
  },

  toJSON(_: MsgPlaceBidResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgPlaceBidResponse>): MsgPlaceBidResponse {
    const message = { ...baseMsgPlaceBidResponse } as MsgPlaceBidResponse;
    return message;
  },
};

const baseMsgRefundBid: object = { auctionId: 0, poolId: 0, bidder: '' };

export const MsgRefundBid = {
  encode(message: MsgRefundBid, writer: Writer = Writer.create()): Writer {
    if (message.auctionId !== 0) {
      writer.uint32(8).uint64(message.auctionId);
    }
    if (message.poolId !== 0) {
      writer.uint32(16).uint64(message.poolId);
    }
    if (message.bidder !== '') {
      writer.uint32(26).string(message.bidder);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgRefundBid {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgRefundBid } as MsgRefundBid;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.auctionId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.poolId = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.bidder = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgRefundBid {
    const message = { ...baseMsgRefundBid } as MsgRefundBid;
    if (object.auctionId !== undefined && object.auctionId !== null) {
      message.auctionId = Number(object.auctionId);
    } else {
      message.auctionId = 0;
    }
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = Number(object.poolId);
    } else {
      message.poolId = 0;
    }
    if (object.bidder !== undefined && object.bidder !== null) {
      message.bidder = String(object.bidder);
    } else {
      message.bidder = '';
    }
    return message;
  },

  toJSON(message: MsgRefundBid): unknown {
    const obj: any = {};
    message.auctionId !== undefined && (obj.auctionId = message.auctionId);
    message.poolId !== undefined && (obj.poolId = message.poolId);
    message.bidder !== undefined && (obj.bidder = message.bidder);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgRefundBid>): MsgRefundBid {
    const message = { ...baseMsgRefundBid } as MsgRefundBid;
    if (object.auctionId !== undefined && object.auctionId !== null) {
      message.auctionId = object.auctionId;
    } else {
      message.auctionId = 0;
    }
    if (object.poolId !== undefined && object.poolId !== null) {
      message.poolId = object.poolId;
    } else {
      message.poolId = 0;
    }
    if (object.bidder !== undefined && object.bidder !== null) {
      message.bidder = object.bidder;
    } else {
      message.bidder = '';
    }
    return message;
  },
};

const baseMsgRefundBidResponse: object = {};

export const MsgRefundBidResponse = {
  encode(_: MsgRefundBidResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgRefundBidResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgRefundBidResponse } as MsgRefundBidResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgRefundBidResponse {
    const message = { ...baseMsgRefundBidResponse } as MsgRefundBidResponse;
    return message;
  },

  toJSON(_: MsgRefundBidResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgRefundBidResponse>): MsgRefundBidResponse {
    const message = { ...baseMsgRefundBidResponse } as MsgRefundBidResponse;
    return message;
  },
};

const baseMsgAdvanceAuction: object = { requester: '' };

export const MsgAdvanceAuction = {
  encode(message: MsgAdvanceAuction, writer: Writer = Writer.create()): Writer {
    if (message.requester !== '') {
      writer.uint32(10).string(message.requester);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgAdvanceAuction {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgAdvanceAuction } as MsgAdvanceAuction;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requester = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgAdvanceAuction {
    const message = { ...baseMsgAdvanceAuction } as MsgAdvanceAuction;
    if (object.requester !== undefined && object.requester !== null) {
      message.requester = String(object.requester);
    } else {
      message.requester = '';
    }
    return message;
  },

  toJSON(message: MsgAdvanceAuction): unknown {
    const obj: any = {};
    message.requester !== undefined && (obj.requester = message.requester);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgAdvanceAuction>): MsgAdvanceAuction {
    const message = { ...baseMsgAdvanceAuction } as MsgAdvanceAuction;
    if (object.requester !== undefined && object.requester !== null) {
      message.requester = object.requester;
    } else {
      message.requester = '';
    }
    return message;
  },
};

const baseMsgAdvanceAuctionResponse: object = {};

export const MsgAdvanceAuctionResponse = {
  encode(_: MsgAdvanceAuctionResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgAdvanceAuctionResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgAdvanceAuctionResponse,
    } as MsgAdvanceAuctionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MsgAdvanceAuctionResponse {
    const message = {
      ...baseMsgAdvanceAuctionResponse,
    } as MsgAdvanceAuctionResponse;
    return message;
  },

  toJSON(_: MsgAdvanceAuctionResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<MsgAdvanceAuctionResponse>): MsgAdvanceAuctionResponse {
    const message = {
      ...baseMsgAdvanceAuctionResponse,
    } as MsgAdvanceAuctionResponse;
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  /** LiquidFarm defines a method for farming pool coin for a liquid farm */
  LiquidFarm(request: MsgLiquidFarm): Promise<MsgLiquidFarmResponse>;
  /** LiquidUnfarm defines a method for unfarming amount of LFCoin */
  LiquidUnfarm(request: MsgLiquidUnfarm): Promise<MsgLiquidUnfarmResponse>;
  /** LiquidUnfarmAndWithdraw defines a method for unfarming amount of LFCoin and withdraw pool coin from the pool */
  LiquidUnfarmAndWithdraw(request: MsgLiquidUnfarmAndWithdraw): Promise<MsgLiquidUnfarmAndWithdrawResponse>;
  /** PlaceBid defines a method for placing a bid for a rewards auction */
  PlaceBid(request: MsgPlaceBid): Promise<MsgPlaceBidResponse>;
  /** RefundBid defines a method for refunding the bid that is not winning for the auction */
  RefundBid(request: MsgRefundBid): Promise<MsgRefundBidResponse>;
  /**
   * AdvanceAuction defines a method for advancing rewards auction by one.
   * This Msg is defined just for testing purpose and it shouldn't be used in production.
   */
  AdvanceAuction(request: MsgAdvanceAuction): Promise<MsgAdvanceAuctionResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  LiquidFarm(request: MsgLiquidFarm): Promise<MsgLiquidFarmResponse> {
    const data = MsgLiquidFarm.encode(request).finish();
    const promise = this.rpc.request('crescent.liquidfarming.v1beta1.Msg', 'LiquidFarm', data);
    return promise.then((data) => MsgLiquidFarmResponse.decode(new Reader(data)));
  }

  LiquidUnfarm(request: MsgLiquidUnfarm): Promise<MsgLiquidUnfarmResponse> {
    const data = MsgLiquidUnfarm.encode(request).finish();
    const promise = this.rpc.request('crescent.liquidfarming.v1beta1.Msg', 'LiquidUnfarm', data);
    return promise.then((data) => MsgLiquidUnfarmResponse.decode(new Reader(data)));
  }

  LiquidUnfarmAndWithdraw(request: MsgLiquidUnfarmAndWithdraw): Promise<MsgLiquidUnfarmAndWithdrawResponse> {
    const data = MsgLiquidUnfarmAndWithdraw.encode(request).finish();
    const promise = this.rpc.request('crescent.liquidfarming.v1beta1.Msg', 'LiquidUnfarmAndWithdraw', data);
    return promise.then((data) => MsgLiquidUnfarmAndWithdrawResponse.decode(new Reader(data)));
  }

  PlaceBid(request: MsgPlaceBid): Promise<MsgPlaceBidResponse> {
    const data = MsgPlaceBid.encode(request).finish();
    const promise = this.rpc.request('crescent.liquidfarming.v1beta1.Msg', 'PlaceBid', data);
    return promise.then((data) => MsgPlaceBidResponse.decode(new Reader(data)));
  }

  RefundBid(request: MsgRefundBid): Promise<MsgRefundBidResponse> {
    const data = MsgRefundBid.encode(request).finish();
    const promise = this.rpc.request('crescent.liquidfarming.v1beta1.Msg', 'RefundBid', data);
    return promise.then((data) => MsgRefundBidResponse.decode(new Reader(data)));
  }

  AdvanceAuction(request: MsgAdvanceAuction): Promise<MsgAdvanceAuctionResponse> {
    const data = MsgAdvanceAuction.encode(request).finish();
    const promise = this.rpc.request('crescent.liquidfarming.v1beta1.Msg', 'AdvanceAuction', data);
    return promise.then((data) => MsgAdvanceAuctionResponse.decode(new Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

// @ts-ignore
if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
