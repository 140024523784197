import { formatTextCase } from 'common/utils';
import { MENU_NAMES, Page } from 'COMMON_VARIABLES';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import SpreadSparkles from 'resources/images/spread_sparkles.png';

const NAV_COLOR_BY_STATUS: { [key in 'active' | 'inactive']: string } = {
  active: 'text-on_inverse_primary bg-inverse_primary',
  inactive: 'text-on_surface bg-transparent hover:text-on_inverse_primary hover:bg-inverse_primary transition-colors',
};

type NavItemProps = {
  page: Page;
};

const NavItem = ({ page }: NavItemProps) => {
  const showEffect = useMemo<boolean>(() => page.name === MENU_NAMES.EARN, [page.name]);

  return (
    <NavLink
      className={({ isActive }) =>
        `px-3 py-2 rounded-100px font_title_m ${NAV_COLOR_BY_STATUS[isActive ? 'active' : 'inactive']} ${
          showEffect ? 'bg-[length:3rem] bg-no-repeat hover:animate-rain-sparkles' : ''
        }`
      }
      style={
        showEffect
          ? {
              backgroundImage: `url(${SpreadSparkles})`,
              backgroundPosition: 'center 400%',
            }
          : undefined
      }
      to={page.path}
      key={page.path}
    >
      {formatTextCase(page.name, { capitalize: 'first' })}
    </NavLink>
  );
};

export default NavItem;
