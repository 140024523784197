import Long from 'long';

const createGovDepositMsg = (txData: any, creAddress: any) => {
  const msg: any = {
    typeUrl: '/cosmos.gov.v1beta1.MsgDeposit',
    value: {
      amount: [txData.amount],
      proposalId: Long.fromString(String(txData.proposalId)),
      depositor: creAddress,
    },
  };
  return [msg];
};

export default createGovDepositMsg;
