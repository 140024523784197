import { formatAmount, formatUSD } from 'common/utils';
import { PortfolioPieChartLegendItem } from './types';

const PortfolioPieChartLegendLabel = ({
  data,
  className,
}: {
  data: PortfolioPieChartLegendItem;
  className?: string;
}) => {
  return (
    <div className={`${className} gap-x-1 grid grid-flow-col grid-cols-3 md:grid-cols-2 md:gap-x-6`}>
      {/* type  */}
      <div className="col-span-2 md:col-auto w-min flex items-center gap-x-2">
        <div className="w-3 h-3" style={{ background: data.color }}></div>

        <div className="text-left">
          <div className="flex items-center gap-x-1 text-on_surface_variant_light">
            <div className="font_title_s truncate">{data.type}</div>
            {data.poolId !== undefined && <div className="font_caption_number_s">#{data.poolId}</div>}
          </div>

          {/* value */}
          <div className="md:hidden font_caption_number_s text-on_surface_variant_dark">{formatUSD(data.valueBN)}</div>
        </div>
      </div>

      <div className="w-fit flex items-start gap-x-2 md:items-center">
        <div className="font_data_number_s md:font_data_number_l text-on_surface">
          {formatAmount(data.ratio, 1, { fixMantissa: true })}%
        </div>

        {/* value */}
        <div className="hidden md:block font_caption_number_m text-on_surface_variant_dark">
          {formatUSD(data.valueBN)}
        </div>
      </div>
    </div>
  );
};

export default PortfolioPieChartLegendLabel;
