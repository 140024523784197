// hooks
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMainStore } from 'provider/mainContext';
import { observer } from 'mobx-react-lite';

import { useAllPoolLive, useCrescentChainBalance } from 'hooks/useAPI';

import usePools from 'hooks/usePools';
import MyInvestment from 'pages/components/MyInvestment';
import MyTokens from 'pages/components/MyTokens';

import { PoolDetail } from 'types/pool';
import ManageModal from 'components/modals/ManageModal';
import IBCTransferModal from 'components/modals/IBCTransferModal';
import { AssetDetail } from 'types/asset';
import UnbondingsTable from 'components/portfolio/UnbondingsTable';
import H3 from 'components/texts/H3';
import PortfolioTotalPanel from 'pages/components/PortfolioTotalPanel';
import type { Unbonding } from 'types/balance';

const Portfolio = observer(() => {
  const store = useMainStore();

  /** unbondings */
  const { data: crescentChainBalances } = useCrescentChainBalance(
    store.wallet.address || '',
    store.isWatching ? 1000 : 3000
  );
  const unbondings = useMemo<Unbonding[]>(
    () => crescentChainBalances?.data?.unbonding ?? [],
    [crescentChainBalances?.data?.unbonding]
  );

  /** pools */
  const { data: allPoolLiveData } = useAllPoolLive();
  useEffect(() => {
    if (allPoolLiveData) {
      const info: any = {};
      const live: any = {};
      let isDelayed: boolean;
      allPoolLiveData.data?.forEach((poolLive) => {
        live[poolLive.poolDenom] = poolLive;
      });
      isDelayed = false;
      store.setPoolsData({ isInit: true, isDelayed, info, live });
    }
  }, [allPoolLiveData, store]);

  const pools = usePools();
  const assets = store.getAssetDetails();

  /** @summary ibc modal */
  const [isIBCModalOpen, setIsIBCModalOpen] = useState<boolean>(false);

  const [IBCModalToken, setIBCModalToken] = useState<AssetDetail | undefined>(undefined);
  const onTokenClick = useCallback((asset: AssetDetail) => {
    setIBCModalToken(asset);
    setIsIBCModalOpen(true);
  }, []);

  /** @summary manage modal */
  const [modalPool, setModalPool] = useState<PoolDetail | undefined>(undefined);
  const onPoolClick = useCallback((pool: PoolDetail) => setModalPool(pool), []);

  return (
    <section className="space-y-[3rem] md:space-y-[3.5rem]">
      <PortfolioTotalPanel pools={pools} assets={assets} unbondings={unbondings} />

      <MyTokens
        assets={assets}
        onTokenClick={onTokenClick}
        onTransferClick={() => setIsIBCModalOpen(true)}
        localStorageKeyPrefix="portfolio"
      />

      <MyInvestment pools={pools} assets={assets} onPoolClick={onPoolClick} localStorageKeyPrefix="portfolio" />

      {unbondings.length > 0 && (
        <div className="space-y-3 md:space-y-6">
          <H3>Unbonding Asset</H3>
          <UnbondingsTable unbondings={unbondings} />
        </div>
      )}

      {/* ibc modal */}
      {isIBCModalOpen && (
        <IBCTransferModal
          asset={IBCModalToken}
          isOpen={isIBCModalOpen}
          close={() => {
            setIsIBCModalOpen(false);
            setIBCModalToken(undefined);
          }}
        />
      )}

      {/* manage modal */}
      {modalPool && (
        <ManageModal
          isLfByDefault={true}
          pool={modalPool}
          isOpen={modalPool !== undefined}
          close={() => {
            setModalPool(undefined);
          }}
        />
      )}
    </section>
  );
});

export default Portfolio;
