import BigNumber from 'bignumber.js';
import { convertExponent } from 'common/utils';
import DropDown, { DropDownItem } from 'components/dropdown/DropDown';
import { DropDownLayer } from 'components/dropdown/types';
import { useMemo } from 'react';
import { OrderbookData } from 'types/orderbook';

const getPrecisionDropDownItem = (priceUnit: string, exponentDiff: number) => {
  const parsedValue = convertExponent(parseFloat(priceUnit));
  return {
    value: String(parsedValue).length,
    label: convertExponent(new BigNumber(parsedValue).shiftedBy(exponentDiff).toString()),
    labelClassName: 'font_label_number_s',
  };
};

type TickPrecisionDropDownProps = {
  layer: DropDownLayer;
  orderbookData: OrderbookData;
  exponentDiff: number;
  precision?: number;
  onSelectTickPrecision: (item: DropDownItem<number, string>) => void;
};

const TickPrecisionDropDown = ({
  layer,
  orderbookData,
  exponentDiff,
  precision,
  onSelectTickPrecision,
}: TickPrecisionDropDownProps) => {
  const items = useMemo<DropDownItem<number, string>[]>(() => {
    return (
      orderbookData.order_books.map((ob) => {
        return getPrecisionDropDownItem(ob.price_unit, exponentDiff);
      }) ?? []
    );
  }, [exponentDiff, orderbookData]);

  const selectedItem = useMemo<DropDownItem<number, string> | undefined>(() => {
    return items.find((item) => item.value === precision);
  }, [items, precision]);

  return (
    <DropDown<number, string>
      layer={layer}
      type="popover"
      items={items}
      selected={selectedItem}
      unselectedLabel=""
      onSelect={onSelectTickPrecision}
    />
  );
};

export default TickPrecisionDropDown;
