import { DROPDOWN_HOVER_LAYER_DICT } from '../styles';

const DropDownBoxItem = <T, K = string | JSX.Element>({
  selected,
  label,
  className = '',
}: {
  selected: boolean;
  label: K;
  className?: string;
}) => {
  return (
    <div className={`relative px-4 py-2 cursor-pointer ${DROPDOWN_HOVER_LAYER_DICT.box} ${className}`}>{label}</div>
  );
};

export default DropDownBoxItem;
