import PageTitleArea from 'components/layout/PageTitleArea';
import H3 from 'components/texts/H3';

const Terms = () => {
  return (
    <div className="pb-20 md:pb-40">
      <PageTitleArea title="Terms of Use" />

      {/* <div className="h-[6.25rem] md:h-[10.25rem] TITLE-SECTION-GRADIENT-L dark:TITLE-SECTION-GRADIENT-D relative">
        <div className="h-[6.25rem] md:h-[10.25rem] relative max-w-[75rem] px-[0.625rem] m-auto flex justify-between items-end">
          <h1 className="H3 md:H1 text-blackCRE dark:text-whiteCRE mb-6 pt-[4.4375rem]">Term of use</h1>
        </div>
      </div> */}
      <main
        role="main"
        className="w-full max-w-content m-auto px-3 pt-5 md:px-0 md:pt-[3rem] space-y-[3.75rem] font_body_s md:font_body_m text-on_surface_variant_light"
      >
        <section className="space-y-5">
          <div>
            Welcome, and thank you for your interest in Crescent Foundation Limited (“Crescent Foundation” “we”, or“us”)
            and our website at{' '}
            <a href="https://www.crescent.network" target="_blank" rel="noreferrer" className="underline ">
              https://www.crescent.network
            </a>{' '}
            (the “Site”) together with web and mobile applications (the “Apps”). These Terms of Use are a legally
            binding contract between you and Crescent Foundation regarding your use of the Site or the Apps. Please read
            the following terms carefully before using the Site or the Apps. By using the Site or the Apps, you
            acknowledge that you have read, understood, and agree to be bound by the following terms and conditions
            (together, the “Terms”). If you are not eligible, or you do not agree to the Terms, then you do not have our
            permission to use the Site or the Apps.
          </div>

          <div className="space-y-3">
            <H3>Eligibility</H3>
            <div>
              You shall not be persons, residents or entities of any country where, by law, the services provided by
              this Site or the Apps are prohibited. By agreeing to these Terms, you represent and warrant to us that:
              (a) you are at least the age of majority in your jurisdiction (e.g. eighteen years old); and (b) your use
              of the Site or the Apps is in compliance with any and all applicable laws and regulations. If you are an
              entity, organization, or company, the individual accepting these Terms on your behalf represents and
              warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms.
              Additionally, by using the Site or the Apps, you represent and warrant that you are not subject to
              sanctions by the United States, and are not a citizen or resident of a state, country, territory or other
              jurisdiction that is embargoed by the United States or where your use of the Site or the Apps would be
              illegal or otherwise violate any domestic or foreign law, rule, statute, or regulation. We may suspend,
              restrict or terminate your access to any or all of the features on the Site or the Apps, and/or block or
              bar any transactions of yours if: (a) we are so required by a subpoena, court order, or binding order of a
              government authority, or under any applicable laws and regulations; (b) you breach these Terms including
              without limitation to conducting any prohibited activities under these Terms; (c) we determine to do so
              for any legal or regulatory reasons at our sole discretion.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Purchases</H3>
            <div>
              Payment: Purchases of any merchandise are facilitated through a third-party service provider. We may
              provide such service providers with information regarding your credit card or other payment instrument.
              You represent and warrant that such information is true and that you are authorized to use the payment
              instrument. You will be responsible for all taxes associated with your purchase of merchandise through the
              Service. Loss and Cancellation: Title and risk of loss for all merchandise ordered by you will pass to you
              on delivery to the shipping carrier. We reserve the right to cancel any order for any merchandise for any
              reason. Returns: You acknowledge and agree that goods produced for you are bespoke, custom-made goods.
              Other than where goods are faulty, you have no right to cancel any order or return any goods and all
              orders are final. Waiver: Your purchase of an item constitutes a waiver of any and all intellectual
              property, proprietary, personal, and privacy claims relating to that purchase.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Modifications to the Terms</H3>
            <div>
              We reserve the right, in our sole discretion, to modify the Terms at any time. If we make changes, we will
              provide you with notice of such changes by providing a notice through the Site or the Apps or updating the
              date at the top of these Terms. Unless we say otherwise in our notice, any modifications are effective
              immediately, and your continued use of the Site or the Apps will confirm your acceptance of the changes.
              If you do not agree to the amended Terms, you must stop using the Site or the Apps.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Modifications to the Site or the Apps</H3>
            <div>
              We may, at our sole discretion, at any time and with or without prior notice to you, modify, suspend or
              disable, temporarily or permanently, the Site or the Apps, in whole or in part, for any reason whatsoever,
              including, but not limited to, as a result of a security incident. We will not be liable for any losses
              suffered by you resulting from any modification to the Site or the Apps or from any suspension or
              termination, for any reason, of your access to all or any portion of the Site or the Apps. All of these
              Terms will survive any termination of your access to the Site or the Apps regardless of the reasons for
              its expiration or termination, in addition to any other provision which by law or by its nature should
              survive.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Restrictions</H3>
            <div>
              You agree not to engage in, or attempt to engage in, any of the following categories of prohibited
              activity in relation to your access to or use of the Site or the Apps: Intellectual Property Infringement
              - Activity that infringes or violates any person or entity’s copyright, trademark, service mark, patent,
              right of publicity, right of privacy, or other proprietary or intellectual property rights under the law.
              Cyberattack - Activity that seeks to interfere with or compromise the integrity, security or proper
              functioning of any computer, server, network, personal device or other information technology system,
              including (but not limited to) the deployment of viruses and denial of service attacks. Fraud or
              Misrepresentation - Activity that seeks to defraud us or any other person or entity, including (but not
              limited to) providing any false, inaccurate, or misleading information in order to unlawfully obtain the
              property of another. Market Manipulation - Activity that violates any applicable law, rule, or regulation
              concerning the integrity of markets, including (but not limited to) the manipulative tactics commonly
              known as spoofing and wash trading. Securities and Derivatives Violations - Activity that violates any
              applicable law, rule, or regulation concerning the trading of securities or derivatives. Any Other
              Unlawful Conduct - Activity that violates any applicable law, rule, or regulation of the relevant
              jurisdiction.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Ownership</H3>
            <div>
              The Site and the Apps are owned and operated by Crescent Foundation. We or our licensors retain all right,
              title, and interest in and to the Site, the Apps and their content(the “Content”) and any trademarks,
              logos, or service marks displayed on the Site, the Apps or in the Content (the “Marks”). The Site, the
              Apps, the Content, and the Marks are protected by applicable intellectual property laws and international
              treaties. Except as expressly authorized by Crescent Foundation you may not make use of the Site, the
              Apps, the Content, and the Marks.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Custody and Control of Digital Tokens</H3>
            <div>
              You have full custody and control of the digital tokens in your digital wallets at all times. We do not
              custody your digital tokens and do not have access to, or retain the electronic private key of your
              digital wallet. As the owner and custodian of the digital tokens in your digital wallets, you shall bear
              all risk of loss of such digital tokens. You understand that Crescent Foundation is not registered or
              licensed by the Commodity Futures Trading Commission, Securities and Exchange Commission, Financial Crimes
              Enforcement Network, or any financial regulatory authority, and that no financial regulatory authority has
              reviewed or approved the Site or the Apps. You further understand that Crescent Foundation is not acting
              as an investment adviser or commodity trading adviser to any person, does not offer securities services,
              and that the contents of the Site or the Apps do not constitute advice or recommendations concerning any
              commodity, security or other asset.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Risks Associated with Digital Tokens</H3>
            <div>
              By accessing and using the Site or the Apps, you represent that you understand the inherent risks
              associated with using cryptographic and blockchain-based systems, and that you have a working knowledge of
              the usage and intricacies of digital tokens such as bitcoin (BTC), ether (ETH), and other digital tokens
              such as those following the Ethereum Token Standard (ERC-20). Crescent Foundation does not control the
              underlying software protocols of any digital tokens accessible on the Site or the Apps. You agree that we
              are not responsible for the operation, functionality or security of the underlying protocols and not
              liable for any loss of token value you may encounter due to any operating change, malfunction or failure
              of the underlying protocols. You further understand that the markets for these digital tokens are highly
              volatile, and that there are risks associated with digital tokens including (but not limited to) those
              related to adoption, speculation, technology, security, and regulation. You acknowledge that the cost and
              speed of transacting with cryptographic and blockchain-based systems such as Ethereum are variable and may
              increase dramatically at any time. You understand and agree to assume full responsibility for all of the
              risks of accessing and using the Site or the Apps and interacting with the Ethereum Blockchain, and agree
              that Crescent Foundation is not responsible for any loss you may experience as a result of these risks. We
              make no representation about the nature, quality, or legal categorization of the token or associated
              project. You are responsible for doing your own research as well as ensuring that you may legally transact
              in this token in the jurisdiction where you reside.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Fees and Price Estimates</H3>
            <div>
              In connection with your use of the Site or the Apps, you are required to pay all fees necessary including
              “gas” costs as well as all other fees reflected on the Site or the Apps at the time of your use of the
              Site or the Apps. Please note that the fee information we provide reflects our estimates of fees and it
              may vary from the actual fees paid to use the service.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Taxes</H3>
            <div>
              It is your sole responsibility to fulfill your tax obligations that apply to your transactions conducted
              on the Site or the Apps. You should withhold, collect, report and remit the correct amounts of taxes to
              the appropriate tax authorities. We make reasonable efforts to make your transaction history available
              through your account but we make no representation about the completeness or accuracy of that information.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Privacy</H3>
            <div>
              You may upload information to the Site or the Apps. In addition, we may collect registration and other
              information about your use of the Site or the Apps. You hereby grant us a non-exclusive, worldwide,
              royalty-free, perpetual, irrevocable license to use, reproduce, modify, transmit, distribute and otherwise
              exploit all information that you upload and all information that we collect internally or externally in
              any way in aggregate or anonymous format or otherwise in conformity with our Privacy Policy. We use the
              information we collect to detect, prevent, and mitigate financial crime and other illicit or harmful
              activities on the Interface. For these purposes, we may share the information we collect with blockchain
              analytics providers. We share information with these service providers only so that they can help us
              promote the safety, security, and integrity of the Interface. We do not retain the information we collect
              any longer than necessary for these purposes.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Links and Third Party Content</H3>
            <div>
              The Site may contain links to third party products, services, websites, web and mobile applications. We
              exercise no control over the third party products, services, websites, web and mobile applications and we
              are not responsible for their performance, do not endorse them, and are not responsible or liable for any
              content, advertising, or other materials available through the third party products, services, websites,
              web and mobile applications. We are not responsible or liable, directly or indirectly, for any damage or
              loss caused to you by your use of or reliance on any goods or services available through the third party
              products, services, websites, web and mobile applications. Additionally, if you follow a link or otherwise
              navigate away from the Site or the Apps, please be aware that these Terms will no longer govern. You
              should review the applicable terms and policies, including privacy and data gathering practices, of any
              third party websites to which you navigate to from the Site or the Apps.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Disclaimer of Warranties</H3>
            <div>
              Your use of the Site, the Apps and the Content is at your sole risk. The Site, the Apps or the Content is
              provided on an “AS IS” and “AS AVAILABLE” basis. Crescent Foundation expressly disclaims all warranties of
              any kind, whether express or implied, including, but not limited to the implied warranties of
              merchantability, fitness for a particular purpose, title, and non-infringement. We do not guarantee the
              accuracy, completeness, or usefulness of the Site, the Apps or the Content, and you rely on the Site, the
              Apps, or the Content at your own risk. Any material obtained through the Site is done at your own
              discretion and risk and you will be solely responsible for any damage to your computer or loss of data
              that results from the download of any material through the Site. No advice or information, whether oral or
              written, obtained by you from Crescent Foundation or through or from the Site will create any warranty not
              expressly stated in these Terms. However, Crescent Foundation does not disclaim any warranty or other
              right that Crescent Foundation is prohibited from disclaiming under applicable law.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Release of Claims</H3>
            <div>
              You expressly agree that you assume all risks in connection with your access and use of the Site. You
              further expressly waive and release us from any and all liability, claims, causes of action, or damages
              arising from or in any way relating to your use of the Site.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Indemnity</H3>
            <div>
              You agree to hold harmless, release, defend, and indemnify us and our officers, directors, employees,
              contractors, agents, affiliates, and subsidiaries from and against all claims, damages, obligations,
              losses, liabilities, costs, and expenses arising from: (a) your access and use of the Site; (b) your
              violation of any term or condition of these Terms, the right of any third party, or any other applicable
              law, rule, or regulation; and (c) any other party's access and use of the Site with your assistance or
              using any device or account that you own or control.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Limitation of Liability</H3>
            <div>
              Under no circumstances shall we or any of our officers, directors, employees, contractors, agents,
              affiliates, or subsidiaries be liable to you for any indirect, punitive, incidental, special,
              consequential, or exemplary damages, including (but not limited to) damages for loss of profits, goodwill,
              use, data, or other intangible property, arising out of or relating to any access or use of the Site, nor
              will we be responsible for any damage, loss, or injury resulting from hacking, tampering, or other
              unauthorized access or use of the Site or the information contained within it. We assume no liability or
              responsibility for any: (a) errors, mistakes, or inaccuracies of content; (b) personal injury or property
              damage, of any nature whatsoever, resulting from any access or use of the Site; (c) unauthorized access or
              use of any secure server or database in our control, or the use of any information or data stored therein;
              (d) interruption or cessation of function related to the Site; (e) bugs, viruses, trojan horses, or the
              like that may be transmitted to or through the Site; (f) errors or omissions in, or loss or damage
              incurred as a result of the use of, any content made available through the Site; and (g) the defamatory,
              offensive, or illegal conduct of any third party. Under no circumstances shall we or any of our officers,
              directors, employees, contractors, agents, affiliates, or subsidiaries be liable to you for any claims,
              proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the amount you
              paid to us in exchange for access to and use of the Site, or USD$100.00, whichever is greater. This
              limitation of liability applies regardless of whether the alleged liability is based on contract, tort,
              negligence, strict liability, or any other basis, and even if we have been advised of the possibility of
              such liability. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or
              exclusion of certain liabilities and damages. Accordingly, some of the disclaimers and limitations set
              forth in these TermsTerms may not apply to you. This limitation of liability shall apply to the fullest
              extent permitted by law.
            </div>
          </div>

          <div className="space-y-3">
            <H3>No Investment Recommendations or Financial Advice Provided</H3>
            <div>
              No aspect of the Site or the Apps is intended to provide, or should be construed as providing, any
              investment, tax or other financial related advice of any kind. You should not consider any content on the
              Site or the Apps to be a substitute for professional financial advice. If you choose to engage in
              transactions based on content on the Site or the Apps, then such decision and transactions and any
              consequences flowing therfrom are your sole responsibility. Crescent Foundation does not provide
              investment advice directly, indirectly, implicitly, or in any manner whatsoever. You are solely and
              exclusively responsible for determining whether any investment, or strategy, or any other service is
              appropriate or suitable for you based on your investment objectives and personal and financial situation.
              Any past performance indicated on the Site or the Apps is not indicative of future results. Anyone
              investing should be able and prepared to bear a loss of his or her entire investment.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Dispute Resolution</H3>
            <div>
              We will use our best efforts to resolve any potential disputes through informal, good faith negotiations.
              If a potential dispute arises, you must contact us by sending an email to contact@crescent.foundation so
              that we can attempt to resolve it without resorting to formal dispute resolution. If we aren't able to
              reach an informal resolution within sixty days of your email, then you and we both agree to resolve the
              potential dispute according to the process set forth below. Any claim or controversy arising out of or
              relating to the Site, these Terms, or any other acts or omissions for which you may contend that we are
              liable, including (but not limited to) any claim or controversy as to arbitrability ("Dispute"), shall be
              finally and exclusively settled by arbitration in Singapore in accordance with the Arbitration Rules of
              The Singapore International Arbitration Centre and under the law of Singapore. The language of the
              arbitration shall be English. The award rendered by the arbitrator(s) shall be final and binding upon both
              parties concerned.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Class Action and Jury Trial Waiver</H3>
            <div>
              You shall bring any and all Disputes against us in your individual capacity and not as a plaintiff in or
              member of any purported class action, collective action, private attorney general action, or other
              representative proceeding. This provision applies to class arbitration. You and we both agree to waive the
              right to demand a trial by jury.
            </div>
          </div>

          <div className="space-y-3">
            <H3>General Terms</H3>
            <div>
              These Terms and any other agreements expressly incorporated by reference into these Terms, are the entire
              and exclusive understanding and agreement between you and Crescent Foundation regarding your use of the
              Site. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by
              operation of law or otherwise, without our prior written consent. We may assign these Terms at any time
              without notice or consent. The failure to require performance of any provision will not affect our right
              to require performance at any other time after that, nor will a waiver by us of any breach or default of
              these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver
              of the provision itself. Use of section headers in these Terms is for convenience only and will not have
              any impact on the interpretation of any provision. Throughout these Terms the use of the word “including”
              means “including but not limited to”. If any part of these Terms is held to be invalid or unenforceable,
              the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will
              remain in full force and effect.
            </div>
          </div>

          <div className="space-y-3">
            <H3>Governing Law</H3>
            <div>
              The interpretation and enforcement of these Terms, and any dispute related to these Terms or the Site,
              will be governed by and construed and enforced in accordance with the laws of the Singapore, as
              applicable, without regard to conflict of law rules or principles that would cause the application of the
              laws of any other jurisdiction.
            </div>
          </div>
        </section>

        <section>
          <h2 className="font_display_l_mobile md:font_display_l text-on_surface md:pb-10">Privacy Policy</h2>
          This Privacy Policy describes the manner in which Crescent Foundation Limited (“Crescent Foundation”, “we”, or
          “us”) and our website at{' '}
          <a href="https://www.crescent.network" target="_blank" rel="noreferrer" className="underline ">
            https://www.crescent.network
          </a>{' '}
          (the “Site”) or our Apps use and collect data from individuals. This Privacy Policy, which is incorporated
          into and is subject to the Crescent Foundation Terms of Use, describe the information that we gather from
          individuals on the Site or the Apps, how we use and disclose such information, and the steps we take to
          protect such information.
          <br />
          <br />
          The only information we collect from individuals is blockchain wallet address, transaction data, completed
          transaction hashes, and the token names, symbols, or other blockchain identifiers of the tokens that you swap
          or execute other types of transaction. We do not collect any personal information from individuals. We may,
          however, use third-party service providers which may receive or independently obtain individuals’ personal
          information. We do not control how these third parties handle individuals’ data and individuals should review
          their privacy policies to understand how they collect, use, and share personal information.
          <br />
          <br />
          We implement a variety of security measures to maintain the safety of personal information when individuals
          enter, submit, or access personal information.
          <br />
          <br />
          We use cookies to understand and save preferences for future visits and compile aggregate data about site
          traffic and site interaction so that we can offer better site experiences and tools in the future. We may
          contract with third-party service providers to assist us in better understanding our Site or Apps visitors.
          These service providers are not permitted to use the information collected on our behalf except to help us
          conduct and improve our business.
          <br />
          <br />
          We do not sell, trade, or otherwise transfer to outside parties personally identifiable information. This does
          not include trusted third parties who assist us in operating our Site or Apps, conducting our business, or
          servicing, so long as those parties agree to keep this information confidential. We may also release
          individuals’ information when we believe release is appropriate to comply with the law, enforce our Privacy
          Policy, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor
          information may be provided to other parties for marketing, advertising, or other uses.
          <br />
          <br />
          Occasionally, at our discretion, we may include or offer third party products or services on our Site or Apps.
          These third party sites have separate and independent privacy policies. We therefore have no responsibility or
          liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity
          of our site and welcome any feedback about these sites.
          <br />
          <br />
          This Privacy Policy applies only to information collected through our site and not to information collected
          offline.
          <br />
          <br />
          By using our Site or Apps, you consent to our Privacy Policy.
          <br />
          <br />
          If we decide to change our Privacy Policy, we will post those changes on this page.
        </section>
      </main>
    </div>
  );
};

export default Terms;
