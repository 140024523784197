import { useState, useMemo, useCallback, useEffect } from 'react';
import { useMainStore } from 'provider/mainContext';
import BigNumber from 'bignumber.js';
import useTxClient from 'hooks/useTxClient';
import { observer } from 'mobx-react-lite';
import { PoolDetail } from 'types/pool';
import { formatAmount, formatUSD } from 'common/utils';
import TextedRanger from 'components/ranger/TextedRanger';
import LabeledData from 'components/texts/LabeledData';
import NumberText from 'components/texts/NumberText';
import Divider from 'components/divider/Divider';
import Button, { ButtonStatus } from 'components/buttons/Button';

const Withdraw = ({ pool }: { pool: PoolDetail }) => {
  const mainStore = useMainStore();
  const { signAndBroadcast } = useTxClient();
  const [rate, setRate] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const reset = useCallback(() => {
    setRate(0);
    setLoading(false);
  }, []);

  useEffect(() => reset(), [pool.poolId]);

  const myTokenBalance = useMemo(() => {
    const uToken = mainStore?.balanceData?.[pool.poolDenom];
    if (uToken) {
      return new BigNumber(uToken);
    }
    return new BigNumber(0);
  }, [pool, mainStore.balanceData]);

  const amount = useMemo(() => myTokenBalance.multipliedBy(rate / 100), [rate, myTokenBalance]);

  const receiveTokenAmount = useMemo<[BigNumber, BigNumber]>(() => {
    const myShare = new BigNumber(amount).dividedBy(pool.totalSupplyAmount);
    const tokenXAmount = new BigNumber(pool.reserved[0].amount)
      .multipliedBy(myShare)
      .shiftedBy(-pool.assets[0].exponent);
    const tokenYAmount = new BigNumber(pool.reserved[1].amount)
      .multipliedBy(myShare)
      .shiftedBy(-pool.assets[1].exponent);
    return [tokenXAmount.decimalPlaces(pool.assets[0].exponent), tokenYAmount.decimalPlaces(pool.assets[1].exponent)];
  }, [amount, pool]);

  const maxReceiveTokenAmount = useMemo<[BigNumber, BigNumber]>(() => {
    const myMaxShare = new BigNumber(myTokenBalance).dividedBy(pool.totalSupplyAmount);
    const tokenXAmount = new BigNumber(pool.reserved[0].amount)
      .multipliedBy(myMaxShare)
      .dividedBy(10 ** pool.assets[0].exponent);
    const tokenYAmount = new BigNumber(pool.reserved[1].amount)
      .multipliedBy(myMaxShare)
      .dividedBy(10 ** pool.assets[1].exponent);
    return [tokenXAmount.decimalPlaces(pool.assets[0].exponent), tokenYAmount.decimalPlaces(pool.assets[1].exponent)];
  }, [myTokenBalance, pool]);

  const totalUsd = useMemo<BigNumber>(() => {
    if (mainStore.poolsData.isInit && amount.gt(0)) {
      return amount.multipliedBy(mainStore.poolsData.live?.[pool.poolDenom].priceOracle);
    }
    return new BigNumber(0);
  }, [mainStore.poolsData, pool, amount]);

  const withdraw = async () => {
    setLoading(true);
    let error = await signAndBroadcast({
      type: 'poolWithdraw',
      txData: {
        poolId: pool.poolId,
        poolCoin: { denom: pool.poolDenom, amount: amount.decimalPlaces(0).toString() },
      },
      chainData: mainStore.chainsData,
    });
    if (!error) {
      setRate(0);
    }
    setLoading(false);
  };

  const button = useMemo<{
    status: ButtonStatus;
    text: string;
  }>(() => {
    if (isLoading) {
      return {
        status: 'loading',
        text: '',
      };
    } else {
      const _amount = new BigNumber(amount);
      if (myTokenBalance.gt(0) && !_amount.gt(0)) {
        return {
          status: 'disabled',
          text: 'Select amount',
        };
      } else if (!myTokenBalance.gt(0) || _amount.gt(myTokenBalance)) {
        return {
          status: 'disabled',
          text: 'No pooltoken',
        };
      } else {
        return {
          status: 'enabled',
          text: 'Withdraw',
        };
      }
    }
  }, [isLoading, myTokenBalance, amount]);

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-3 md:space-y-6">
          <TextedRanger
            rate={rate}
            unit="%"
            captionNumber={formatUSD(totalUsd)}
            onChange={setRate}
            disabled={!myTokenBalance.gt(0)}
          />

          <div className="space-y-2">
            <LabeledData
              label="You deposited"
              value={
                <NumberText
                  size="sm"
                  value={formatAmount(maxReceiveTokenAmount[0], pool.assets[0].exponent)}
                  unit={pool.assets[0].ticker}
                />
              }
            />
            <div className="flex justify-end">
              <NumberText
                size="sm"
                value={formatAmount(maxReceiveTokenAmount[1], pool.assets[1].exponent)}
                unit={pool.assets[1].ticker}
              />
            </div>
          </div>

          {myTokenBalance.gt(0) && (
            <>
              <Divider type="hard" />

              <div className="space-y-2">
                <LabeledData
                  label="You will withdraw"
                  value={
                    <NumberText
                      color="primary"
                      size="sm"
                      value={formatAmount(receiveTokenAmount[0], pool.assets[0].exponent)}
                      unit={pool.assets[0].ticker}
                    />
                  }
                />
                <div className="flex justify-end">
                  <NumberText
                    color="primary"
                    size="sm"
                    value={formatAmount(receiveTokenAmount[1], pool.assets[1].exponent)}
                    unit={pool.assets[1].ticker}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <Button label={button.text} status={button.status} onClick={withdraw} className="w-full" />
      </div>
    </>
  );
};

export default observer(Withdraw);
