import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MooncatConfetti } from './components';
import ToastBody from './ToastBody';
import ToastFlag from './ToastFlag';
import ToastProgressBar from './ToastProgressBar';
import { FeedbackType } from './types';

type ToastProps = {
  toastId?: string;
  type: FeedbackType;
  title: string | JSX.Element;
  content: JSX.Element | string;
  link?: { label: string; href: string };
  autoClose?: number | false | undefined;
  glow?: boolean;
};

const Toast = ({ toastId, type, title, content, link, autoClose, glow = false }: ToastProps) => {
  const [progressPaused, setProgressPaused] = useState(false);

  const onMouseEnter = useCallback(() => {
    setProgressPaused(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setProgressPaused(false);
  }, []);

  const onProgressComplete = useCallback(() => {
    toast.dismiss(toastId);
  }, [toastId]);

  /** @desc force re-rendering of ToastProgressBar for toast.update */
  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    setShowProgress(false);
  }, [title]);

  useEffect(() => {
    if (!showProgress) setShowProgress(true);
  }, [showProgress]);

  return (
    <div
      className={`relative flex items-stretch border border-outline_soft ${glow ? 'box_glow_l' : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ToastFlag type={type} />
      <ToastBody type={type} title={title} content={content} link={link} className="grow shrink" />

      {type === 'congrats' && <MooncatConfetti className="absolute right-[8px] bottom-0" />}

      {typeof autoClose === 'number' && showProgress && (
        <ToastProgressBar
          type={type}
          autoClose={autoClose}
          paused={progressPaused}
          onProgressComplete={onProgressComplete}
        />
      )}
    </div>
  );
};

export default Toast;
