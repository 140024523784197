import { formatAmount, formatUSD } from 'common/utils';
import TooltipInner from 'components/tooltips/TooltipInner';
import { useCallback } from 'react';
import { PortfolioPieChartItemRaw } from '../types';
import Token from 'components/coins/Token';
import { useMainStore } from 'provider/mainContext';
import NumberText from 'components/texts/NumberText';

const PortfolioPieChartEachAsset = ({
  title,
  items,
}: {
  title: string | JSX.Element;
  items: PortfolioPieChartItemRaw[];
}) => {
  const getBalanceUSD = useCallback((item: PortfolioPieChartItemRaw) => {
    return item.group === 'token'
      ? item.asset.availableBalance.shiftedBy(-item.asset.exponent).multipliedBy(item.asset.priceOracle)
      : item.asset.myTotalBalance.multipliedBy(item.asset.priceOracle);
  }, []);

  const getBalance = useCallback((item: PortfolioPieChartItemRaw) => {
    return item.group === 'token'
      ? item.asset.availableBalance.shiftedBy(-item.asset.exponent)
      : item.asset.myTotalBalance.shiftedBy(-12);
  }, []);

  const store = useMainStore();

  if (items.length <= 0) return null;

  return (
    <TooltipInner title={title}>
      <div className="space-y-2">
        {items.map((item, index) => (
          <div key={index} className="flex justify-start items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              {item.group === 'token' ? (
                <Token asset={store.assetDetailDict[item.asset.denom]} />
              ) : (
                <Token size="16px" asset={store.assetDetailDict[item.asset.poolDenom]} />
              )}

              {items.length === 1 && (
                <div className="font_data_number_l text-on_surface">{formatAmount(item.ratio, 1)}%</div>
              )}
            </div>

            <NumberText
              size="sm"
              value={formatUSD(getBalanceUSD(item))}
              subValue={formatAmount(getBalance(item), item.group === 'token' ? item.asset.exponent : 12)}
              subUnit={item.group === 'token' ? item.asset.ticker : ''}
            />
          </div>
        ))}
      </div>
    </TooltipInner>
  );
};

export default PortfolioPieChartEachAsset;
