import WidgetHeader from './WidgetHeader';

type WidgetSize = 'xs' | 'sm' | 'md' | 'full';
type WidgetPadding = '20px' | '16px' | '12px' | '0';

const WIDGET_MD_WIDTH_DICT: { [key in WidgetSize]: string } = {
  xs: 'md:w-[300px]',
  sm: 'md:w-[380px]',
  md: 'md:w-[420px]',
  full: '',
};

const WIDGET_PADDING_DICT: { [key in WidgetPadding]: string } = {
  '20px': 'px-5 pt-5 pb-6',
  '16px': 'px-4 pt-4 pb-5',
  '12px': 'px-3 pt-3 pb-4',
  '0': 'p-0',
};

type WidgetProps = {
  children: React.ReactNode;
  size?: WidgetSize;
  padding?: WidgetPadding;
  glow?: boolean;
  className?: string;
};

const Widget = ({ children, size = 'md', padding = '20px', glow = false, className }: WidgetProps) => {
  return (
    <section
      className={`relative w-full ${WIDGET_MD_WIDTH_DICT[size]} ${
        WIDGET_PADDING_DICT[padding]
      } bg-surface border border-outline_soft rounded-8px ${glow ? 'box_glow_l' : ''} ${className}`}
    >
      {children}
    </section>
  );
};

Widget.Header = WidgetHeader;
Widget.Body = ({ children }: { children: React.ReactNode }) => <>{children}</>;

export default Widget;
