import { EncodeObject } from '@cosmjs/proto-signing';
import {
  MsgLimitOrder,
  MsgDeposit,
  MsgWithdraw,
  MsgMarketOrder,
  MsgCancelOrder,
  MsgCancelAllOrders,
} from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import { MsgHarvest, MsgStake, MsgUnstake } from '../crescent/farming/types/crescent/farming/v1beta1/tx';
import { MsgLiquidStake, MsgLiquidUnstake } from '../crescent/liquidstaking/types/crescent/liquidstaking/v1beta1/tx';
import { MsgClaim } from '../crescent/claim/types/crescent/claim/v1beta1/tx';
import {
  MsgLiquidFarm,
  MsgLiquidUnfarm,
  MsgLiquidUnfarmAndWithdraw,
} from 'common/crescent/liquidfarming/types/crescent/liquidfarming/v1beta1/tx';
import {
  MsgFarm,
  MsgUnfarm,
  MsgHarvest as MsgLpHarvest,
} from 'common/crescent/lpfarm/types/crescent/lpfarm/v1beta1/tx';

export default {
  // CLAIM
  msgClaim: (data: MsgClaim): EncodeObject => ({
    typeUrl: '/crescent.claim.v1beta1.MsgClaim',
    value: MsgClaim.fromPartial(data),
  }),
  // Liquidity
  msgWithdraw: (data: MsgWithdraw): EncodeObject => ({
    typeUrl: '/crescent.liquidity.v1beta1.MsgWithdraw',
    value: MsgWithdraw.fromPartial(data),
  }),
  msgDeposit: (data: MsgDeposit): EncodeObject => ({
    typeUrl: '/crescent.liquidity.v1beta1.MsgDeposit',
    value: MsgDeposit.fromPartial(data),
  }),
  msgLimitOrder: (data: MsgLimitOrder): EncodeObject => ({
    typeUrl: '/crescent.liquidity.v1beta1.MsgLimitOrder',
    value: MsgLimitOrder.fromPartial(data),
  }),
  msgMarketOrder: (data: MsgMarketOrder): EncodeObject => ({
    typeUrl: '/crescent.liquidity.v1beta1.MsgMarketOrder',
    value: MsgMarketOrder.fromPartial(data),
  }),
  // Farming
  msgUnstake: (data: MsgUnstake): EncodeObject => ({
    typeUrl: '/crescent.farming.v1beta1.MsgUnstake',
    value: MsgUnstake.fromPartial(data),
  }),
  msgStake: (data: MsgStake): EncodeObject => ({
    typeUrl: '/crescent.farming.v1beta1.MsgStake',
    value: MsgStake.fromPartial(data),
  }),
  msgHarvest: (data: MsgHarvest): EncodeObject => ({
    typeUrl: '/crescent.farming.v1beta1.MsgHarvest',
    value: MsgHarvest.fromPartial(data),
  }),
  // Farming (new)
  msgFarm: (data: MsgFarm): EncodeObject => ({
    typeUrl: '/crescent.lpfarm.v1beta1.MsgFarm',
    value: MsgFarm.fromPartial(data),
  }),
  msgUnfarm: (data: MsgUnfarm): EncodeObject => ({
    typeUrl: '/crescent.lpfarm.v1beta1.MsgUnfarm',
    value: MsgUnfarm.fromPartial(data),
  }),
  msgLpHarvest: (data: MsgLpHarvest): EncodeObject => ({
    typeUrl: '/crescent.lpfarm.v1beta1.MsgHarvest',
    value: MsgLpHarvest.fromPartial(data),
  }),
  // Liquid Farming
  msgLiquidFarm: (data: MsgLiquidFarm): EncodeObject => ({
    typeUrl: '/crescent.liquidfarming.v1beta1.MsgLiquidFarm',
    value: MsgLiquidFarm.fromPartial(data),
  }),
  msgLiquidUnfarm: (data: MsgLiquidUnfarm): EncodeObject => ({
    typeUrl: '/crescent.liquidfarming.v1beta1.MsgLiquidUnfarm',
    value: MsgLiquidUnfarm.fromPartial(data),
  }),
  msgLiquidUnfarmAndWithdraw: (data: MsgLiquidUnfarmAndWithdraw): EncodeObject => ({
    typeUrl: '/crescent.liquidfarming.v1beta1.MsgLiquidUnfarmAndWithdraw',
    value: MsgLiquidUnfarmAndWithdraw.fromPartial(data),
  }),
  // Staking
  msgLiquidStake: (data: MsgLiquidStake): EncodeObject => ({
    typeUrl: '/crescent.liquidstaking.v1beta1.MsgLiquidStake',
    value: MsgLiquidStake.fromPartial(data),
  }),
  msgLiquidUnstake: (data: MsgLiquidUnstake): EncodeObject => ({
    typeUrl: '/crescent.liquidstaking.v1beta1.MsgLiquidUnstake',
    value: MsgLiquidUnstake.fromPartial(data),
  }),
  msgCancelOrder: (data: MsgCancelOrder): EncodeObject => ({
    typeUrl: '/crescent.liquidity.v1beta1.MsgCancelOrder',
    value: MsgCancelOrder.fromPartial(data),
  }),
  msgCancelAllOrders: (data: MsgCancelAllOrders): EncodeObject => ({
    typeUrl: '/crescent.liquidity.v1beta1.MsgCancelAllOrders',
    value: MsgCancelAllOrders.fromPartial(data),
  }),
};
