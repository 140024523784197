import TransitionModal from './TransitionModal';
import IconButton from 'components/buttons/IconButton';
import { useMainStore } from 'provider/mainContext';
import { MINTSCAN, MODAL_TITLES } from 'COMMON_VARIABLES';
import ModalHeader from './components/ModalHeader';
import copy from 'copy-to-clipboard';
import Button from 'components/buttons/Button';
import { formatTextCase } from 'common/utils';

export default function AccountModal({
  isOpen,
  disconnect,
  close,
}: {
  isOpen: boolean;
  disconnect: () => void;
  close: () => void;
}) {
  const mainStore = useMainStore();
  return (
    <TransitionModal size="sm" isOpen={isOpen} close={close}>
      <ModalHeader title={MODAL_TITLES.ACCOUNT} onCloseClick={close} />

      <div className="space-y-5 px-4 pb-5 md:px-5 md:pb-6">
        <div className="space-y-3">
          <div className="flex items-center justify-between gap-x-2">
            <div className="font_caption_m text-on_surface_variant_light">Your address</div>
            <div className="font_caption_m text-on_surface_variant_dark text-right">
              Connected with{' '}
              <span className="text-on_surface">
                {formatTextCase(localStorage.getItem('autoLogin') ?? '', { capitalize: 'first' })}
              </span>
            </div>
          </div>

          <div className="w-full flex justify-between items-center gap-x-2 p-3 bg_surface_3 rounded-4px">
            <div className="font_body_xs text-on_surface_variant_light truncate">{mainStore.wallet.address}</div>
            <IconButton iconType="copy" size="sm" onClick={() => copy(mainStore.wallet.address)} />
          </div>
        </div>

        <div className="flex items-center justify-between gap-x-4">
          <Button
            label="Go Mintscan"
            trailingIcon="open-in-new"
            href={`${MINTSCAN.crescent_account}${mainStore.wallet.address}`}
            size="md"
            className="w-full"
          />

          <Button
            label="Disconnect"
            size="md"
            type="tonal"
            onClick={() => {
              disconnect();
              close();
            }}
            className="w-full"
          />
        </div>
      </div>
    </TransitionModal>
  );
}
