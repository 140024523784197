import Icon, { IconType } from 'components/icon';
import { ReactNode } from 'react';

type InfoBoxStyle = 'info' | 'warning' | 'danger' | 'success' | 'tip';

const INFOBOX_STYLE_DICT: { [key in InfoBoxStyle]: { iconType: IconType; className: string } } = {
  info: {
    iconType: 'info',
    className: 'bg-semantic_gray_o24 text-semantic_gray',
  },
  warning: {
    iconType: 'warning',
    className: 'bg-semantic_yellow_o24 text-semantic_yellow',
  },
  danger: {
    iconType: 'error',
    className: 'bg-semantic_red_o24 text-semantic_red',
  },
  success: {
    iconType: 'verified-user',
    className: 'bg-semantic_green_o24 text-semantic_green',
  },
  tip: {
    iconType: 'tips-and-updates',
    className: 'bg-semantic_blue_o24 text-semantic_blue',
  },
};

type InfoBoxProps = { children: ReactNode; type?: InfoBoxStyle; onClickClose?: () => void };

const InfoBox = ({ children, type = 'info', onClickClose }: InfoBoxProps) => {
  return (
    <div className={`p-2 rounded-4px ${INFOBOX_STYLE_DICT[type].className}`}>
      <div className="flex justify-between items-stretch gap-x-2">
        <div className="flex justify-start items-stretch gap-x-2">
          <div className="w-[1.125rem] h-[1.125rem] flex justify-center items-center">
            <Icon type={INFOBOX_STYLE_DICT[type].iconType} size="16px" />
          </div>

          <div className="font_body_xs">{children}</div>
        </div>

        {onClickClose && (
          <div className="w-[1.125rem] h-[1.125rem] flex justify-center items-center">
            <button type="button" onClick={onClickClose} className="hover:opacity-80">
              <Icon type="close" size="16px" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBox;
