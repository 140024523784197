import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import { INITIAL_ASSET } from 'COMMON_VARIABLES';
import Table from 'components/table/Table';
import NumberText from 'components/texts/NumberText';
import { useMemo } from 'react';

type TableUnbondingRow = {
  startTime: JSX.Element;
  unbondingAmount: BigNumber;
  unbondingAmountSet: JSX.Element;
  receiveAmount: BigNumber;
  receiveAmountSet: JSX.Element;
  remainingTime: JSX.Element;
};

const toStartTime = (completeTime: number) => {
  let unbondingPeriod = 3600 * 24 * 14;
  let startTime = new Date((completeTime - unbondingPeriod) * 1000);
  return `${startTime.getFullYear()}/${startTime.getMonth() + 1}/${startTime.getDate()}`;
};

const UnbondingsTable = ({ unbondings }: { unbondings: any[] }) => {
  const tableTxRows = useMemo<TableUnbondingRow[]>(
    () =>
      unbondings.map((unbonding) => {
        const startTime = <NumberText value={toStartTime(unbonding.CompleteTimestamp)} />;

        const unbondingAmount = new BigNumber(unbonding.amount).shiftedBy(-INITIAL_ASSET.exponent);
        const unbondingAmountSet = <UnbondingAmountText amount={unbondingAmount} ticker="bCRE" />;

        const receiveAmount = new BigNumber(unbonding.unbondingAmount).shiftedBy(-INITIAL_ASSET.exponent);
        const receiveAmountSet = <UnbondingAmountText amount={receiveAmount} ticker="CRE" />;

        const remainingTime = <UnbondingRemainingTime time={unbonding.CompleteTimestamp} />;

        return {
          startTime,
          unbondingAmount,
          receiveAmount,
          unbondingAmountSet,
          receiveAmountSet,
          remainingTime,
        };
      }),
    [unbondings]
  );

  return (
    <Table<TableUnbondingRow>
      isLoading={false}
      rows={tableTxRows}
      fields={[
        {
          label: 'Start Time',
          value: 'startTime',
          type: 'jsx',
          sortDisabled: true,
          align: 'left',
        },
        {
          label: 'Unbonding',
          value: 'unbondingAmountSet',
          type: 'jsx',
          sortValue: 'unbondingAmount',
          sortType: 'bignumber',
          align: 'right',
          widthRatio: 16,
        },
        {
          label: 'Receive Amount',
          value: 'receiveAmountSet',
          type: 'jsx',
          sortValue: 'receiveAmount',
          sortType: 'bignumber',
          align: 'right',
          widthRatio: 24,
        },
        {
          label: 'Remaining Time',
          value: 'remainingTime',
          type: 'jsx',
          sortDisabled: true,
          align: 'right',
          widthRatio: 30,
        },
      ]}
    />
  );
};

export default UnbondingsTable;

function UnbondingAmountText({ amount, ticker }: { amount: BigNumber; ticker: 'bCRE' | 'CRE' }) {
  return <NumberText value={formatAmount(amount, INITIAL_ASSET.exponent)} unit={ticker} />;
}

function UnbondingRemainingTimeText({ time, unit }: { time: string | number; unit: string }) {
  return (
    <div className="flex items-baseline">
      <div className="font_data_number_m">{time}</div>
      <div className="font_caption_l text-on_surface_variant_light">{unit}</div>
    </div>
  );
}

function UnbondingRemainingTime({ time }: { time: number }) {
  const _time = useMemo(() => {
    let currentTime = Math.floor(new Date().getTime() / 1000);
    if (time - currentTime < 0) {
      return null;
    }

    let dif = time - currentTime;
    const date = Math.floor(dif / (3600 * 24));
    let rest = dif - date * 3600 * 24;
    const hour = Math.floor(rest / 3600);
    rest = rest - hour * 3600;
    const miniute = Math.floor(rest / 60);
    return {
      date: date >= 10 ? date : `0${date}`,
      hour: hour >= 10 ? hour : `0${hour}`,
      miniute: miniute >= 10 ? miniute : `0${miniute}`,
    };
  }, [time]);

  return (
    <>
      {_time ? (
        <div className="flex items-baseline gap-x-1">
          <UnbondingRemainingTimeText time={_time.date} unit="D" />
          <UnbondingRemainingTimeText time={_time.hour} unit="H" />
          <UnbondingRemainingTimeText time={_time.miniute} unit="M" />
        </div>
      ) : (
        '-'
      )}
    </>
  );
}
