import type { EncodeObject } from '@cosmjs/proto-signing';
import type { MsgLiquidUnfarm } from 'common/crescent/liquidfarming/types/crescent/liquidfarming/v1beta1/tx';
import type { Coin } from 'common/crescent/liquidfarming/types/cosmos/base/v1beta1/coin';
import msgEncoder from 'common/sign/msgEncoder';

export type LiquidUnfarmTxData = {
  poolId: number;
  unfarmingCoin: Coin | undefined;
};

const createLiquidUnfarmMsg = (txData: LiquidUnfarmTxData, creAddress: string): EncodeObject[] => {
  const liquidUnfarmData: MsgLiquidUnfarm = {
    farmer: creAddress,
    poolId: txData.poolId,
    unfarmingCoin: txData.unfarmingCoin,
  };
  console.log('liquidUnfarmData', liquidUnfarmData);
  const msg = msgEncoder.msgLiquidUnfarm(liquidUnfarmData);
  return [msg];
};

export default createLiquidUnfarmMsg;
