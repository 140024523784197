import { Popover as HeadlessPopover } from '@headlessui/react';
import { useState } from 'react';
import { MODAL_TITLES, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import IconButton from 'components/buttons/IconButton';
import Popover from 'components/popover/Popover';
import NumberInput from 'components/inputs/NumberInput';
import TogglableButtonGroup, { TogglableButtonItem } from 'components/buttons/TogglableButtonGroup';
import type { PopoverType } from 'components/popover/types';
import TransitionModal from 'components/modals/TransitionModal';
import ModalHeader from 'components/modals/components/ModalHeader';
import { useMainStore } from 'provider/mainContext';

const ORDER_LIFESPAN_OPTIONS: TogglableButtonItem<number>[] = [
  { labelType: 'text', label: '1Block', value: 0 },
  { labelType: 'number', label: '6H', value: 21600 },
  { labelType: 'number', label: '12H', value: 43200 },
  { labelType: 'number', label: '24H', value: 86400 },
];

const POPOVER_TITLE = MODAL_TITLES.TRADE_SETTING;

/** @component TradeSetting */
const TradeSetting = ({
  setOrderLifespanSeconds,
  orderLifespanSeconds,
}: {
  setOrderLifespanSeconds: (value: number) => void;
  orderLifespanSeconds: number;
}) => {
  const store = useMainStore();

  return (
    <div className="space-y-5 md:space-y-3">
      <div className="space-y-2">
        <label className="w-full flex items-center gap-x-1 pt-1 md:pt-0">
          <div className="font_caption_s md:font_caption_xs text-on_surface_variant_light">
            Limit order deadline (Max 24H)
          </div>
          <TooltipIcon tooltipContent={TOOLTIP_TEXTS.LIMIT_ORDER_DEADLINE} />
        </label>

        <NumberInput
          max="86400"
          unit="S"
          value={orderLifespanSeconds.toString()}
          onChangeValue={(value) => setOrderLifespanSeconds(Number(value))}
        />
      </div>

      <TogglableButtonGroup<number>
        type="outlined"
        size={store.isMobile ? 'sm' : 'xs'}
        list={ORDER_LIFESPAN_OPTIONS}
        onSelect={setOrderLifespanSeconds}
        selectedItem={orderLifespanSeconds}
      />
    </div>
  );
};

/** @component TradeSettingPopover */
type TradeSettingPopoverProps = {
  type?: PopoverType;
  setOrderLifespanSeconds: (value: number) => void;
  orderLifespanSeconds: number;
};

const TradeSettingPopover = ({
  type = 'popover',
  setOrderLifespanSeconds,
  orderLifespanSeconds,
}: TradeSettingPopoverProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const TradeSettingContent = (
    <TradeSetting setOrderLifespanSeconds={setOrderLifespanSeconds} orderLifespanSeconds={orderLifespanSeconds} />
  );

  return type === 'popover' ? (
    <HeadlessPopover className="relative flex items-center">
      <IconButton isPopOver={true} iconType="tune" />

      <HeadlessPopover.Panel className="absolute right-0 top-[2.5rem] z-10">
        <Popover size="2xs" padding="12px">
          <div className="space-y-3">
            <h4 className="font_title_s text-on_surface">{POPOVER_TITLE}</h4>
            {TradeSettingContent}
          </div>
        </Popover>
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  ) : (
    <>
      <IconButton iconType="tune" onClick={() => setIsModalOpen(true)} />

      <TransitionModal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        <ModalHeader title={POPOVER_TITLE} onCloseClick={() => setIsModalOpen(false)} />
        <div className="px-4 pb-5">{TradeSettingContent}</div>
      </TransitionModal>
    </>
  );
};

export default TradeSettingPopover;
