import BigNumber from 'bignumber.js';
import { formatAmount, getTvlByPool, getUnderlyingAssetsAmount } from 'common/utils';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import { ReactNode, useMemo } from 'react';
import { MyPoolDetail } from 'types/pool';
import MyPoolTokens from './MyPoolTokens';
import UnderlyingAssets from './UnderlyingAssets';
import TooltipTitle from 'components/tooltips/TooltipTitle';

const Wrapper = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
  return <div className={`px-3 pt-2 pb-3 ${className}`}>{children}</div>;
};

const MyPoolBalanceDetails = ({ pool }: { pool: MyPoolDetail }) => {
  const maxReceiveTokenAmount = useMemo<[BigNumber, BigNumber]>(() => {
    return getUnderlyingAssetsAmount(pool.myTotalBalance, pool);
  }, [pool]);

  return (
    <TooltipInner title="Pool Tokens" padding={false}>
      <div className="flex flex-col">
        <Wrapper>
          <MyPoolTokens pool={pool} />
        </Wrapper>

        <TooltipTitle title="Underlying Assets" />
        <Wrapper>
          <UnderlyingAssets pool={pool} assetsAmount={[maxReceiveTokenAmount[0], maxReceiveTokenAmount[1]]} />
        </Wrapper>

        <TooltipTitle title="TVL" />
        <Wrapper>
          <NumberText size="sm" color="plain-mono" value={formatAmount(getTvlByPool(pool), 0)} />
        </Wrapper>
      </div>
    </TooltipInner>
  );
};

export default MyPoolBalanceDetails;
