export type TradeDirection = 0 | 1;

const TRADE_DIRECTIONS: TradeDirection[] = [0, 1];

const TRADE_DIRECTION_TABS = ['Buy', 'Sell'];

const TRADE_DIRECTION_TAB_COLOR_DICT: { [key in TradeDirection]: { bg: string; text: string } } = {
  0: { bg: 'border-semantic_green bg-semantic_green_o24', text: 'text-semantic_green' },
  1: { bg: 'border-semantic_red bg-semantic_red_o24', text: 'text-semantic_red' },
};

/** @desc TradeDirectionTabItem */
type TradeDirectionTabItemProps = {
  direction: TradeDirection;
  isSelected: boolean;
  onClick: () => void;
};

const TradeDirectionTabItem = ({ direction, isSelected, onClick }: TradeDirectionTabItemProps) => {
  return (
    <button
      type="button"
      className={`w-full h-full flex justify-center items-center font_label_s transition-colors ${
        isSelected ? TRADE_DIRECTION_TAB_COLOR_DICT[direction].text : 'text-on_surface_variant_dark'
      } md:font_label_m`}
      onClick={onClick}
    >
      <div>{TRADE_DIRECTION_TABS[direction]}</div>
    </button>
  );
};

/** @desc TradeDirectionTabSelector */
type TradeDirectionTabSelectorProps = {
  direction: TradeDirection;
  onChangeDirection: (direction: TradeDirection) => void;
};

const TradeDirectionTabSelector = ({ direction, onChangeDirection }: TradeDirectionTabSelectorProps) => {
  return (
    <div className="relative w-full h-[2rem] flex items-stretch gap-0 bg_surface_1 rounded-4px overflow-hidden md:h-[2.75rem]">
      <div
        className={`absolute top-0 left-0 w-1/2 h-full border-2 rounded-4px transition-all !duration-200 ${TRADE_DIRECTION_TAB_COLOR_DICT[direction].bg}`}
        style={{
          transform: `translateX(${direction === 0 ? '0' : '100%'})`,
        }}
      ></div>

      {TRADE_DIRECTIONS.map((d, index) => (
        <div key={index} className="relative w-full h-full">
          <TradeDirectionTabItem
            direction={d}
            isSelected={d === direction}
            onClick={() => {
              if (d !== direction) onChangeDirection(d);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default TradeDirectionTabSelector;
