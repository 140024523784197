import type { CoinLogoSize } from 'components/icon/types';

export enum TokenTypes {
  NATIVE = 'NATIVE',
  IBC = 'IBC',
  BRIDGED = 'BRIDGED',
  LF = 'LF',
  POOL = 'POOL',
}

export enum MyTokenTypes {
  MY_TOKENS = 'MY_TOKENS',
  MY_LF_TOKENS = 'MY_LF_TOKENS',
}

export const TOKEN_TYPES: { [key in MyTokenTypes]: TokenTypes[] } = {
  [MyTokenTypes.MY_TOKENS]: [TokenTypes.NATIVE, TokenTypes.IBC, TokenTypes.BRIDGED],
  [MyTokenTypes.MY_LF_TOKENS]: [TokenTypes.LF],
};

/**
 * @description [wrapperSize, eachCoinSize]
 */
export const LF_COIN_LOGO_SIZES: { [key in CoinLogoSize]: [string, string] } = {
  sm: ['w-7', 'w-4 h-4'],
  s: ['w-9 md:w-11', 'w-5 h-5 md:w-6 md:h-6'],
  m: ['w-10 md:w-[3.25rem]', 'w-6 h-6 md:w-8 md:h-8'],
  l: ['w-13 md:w-[4.75rem]', 'w-7 h-7 md:w-10 md:h-10'],
};

export const SINGLE_COIN_LOGO_SIZES: { [key in CoinLogoSize]: string } = {
  sm: 'w-4 h-4',
  s: 'w-5 h-5',
  m: 'w-7 h-7 md:w-10 md:h-10',
  l: 'w-7 h-7 md:w-10 md:h-10', // TBD
};
