import CoinPair from 'components/coins/CoinPair';
import PoolId from 'components/pools/PoolId';
import type { InfoAsset } from 'types/asset';

type TxResultPairLabelProps = {
  assets: [InfoAsset, InfoAsset];
  id?: number;
};

const TxResultPairLabel = ({ assets, id }: TxResultPairLabelProps) => {
  return (
    <div className="flex items-center gap-x-1 text-white">
      <CoinPair assets={assets} size="16px" />

      <div className="flex items-center gap-x-1">
        <div className="flex items-center gap-x-0 font_caption_s text-on_surface_variant_light">
          <div>{assets[0].ticker}</div>
          <div>/</div>
          <div>{assets[1].ticker}</div>
        </div>

        {id !== undefined && <PoolId poolId={id} hashTag={true} size="md" opacity={false} />}
      </div>
    </div>
  );
};

export default TxResultPairLabel;
