import { Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom';
import { isMobile } from '@walletconnect/browser-utils';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useMainStore } from 'provider/mainContext';

// pages
import AppHeader from 'components/header/AppHeader';
import Trade from 'pages/trade';
import Trading from 'pages/trade/trading';

import Orderbook from 'pages/trade/orderbook';
import Swap from 'pages/trade/swap';
import Farm from './pages/farm/Farm';
import Portfolio from 'pages/portfolio';
import Staking from 'pages/staking';
import Gov from 'pages/gov';
import GovDetail from 'pages/gov/GovDetail';
import Airdrop from 'pages/airdrop';
import Terms from 'pages/terms';
import Gdex from 'pages/gdex';
import Home from 'pages/home/Home';
import Update from 'pages/update/Update';

// components
import DisclaimerModal from 'components/modals/DisclaimerModal';

import { observer, Observer } from 'mobx-react-lite';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Popover as HeadlessPopover, Transition } from '@headlessui/react';

import { INITIAL_ASSET, IS_PRODUCTION, isUpdate, MENU_NAMES, Page, PAGES } from 'COMMON_VARIABLES';
import useDataPolling from 'hooks/useDataPolling';
import useTailwind from 'hooks/useTailwind';
import IconButton from 'components/buttons/IconButton';
import Icon, { IconType } from 'components/icon';
import Popover from 'components/popover/Popover';
import MenuItem from 'components/buttons/MenuItem';
import Spinner from 'components/spinners/Spinner';

if (
  localStorage.theme !== 'Light' ||
  (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

function App() {
  const mainStore = useMainStore();
  const { networkDelayed } = useDataPolling();

  const isCosmostationLoading = useMemo(() => {
    if (isMobile() && window.navigator.userAgent.includes('Cosmostation') && !mainStore.wallet.address) {
      return true;
    } else {
      return false;
    }
  }, [mainStore.wallet.address]);
  //Disclaimer modal
  const [isDisclaimerModal, setIsDisclaimerModal] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('confirmDisclaimer') !== 'true') setIsDisclaimerModal(true);
  }, []);

  /** tailwind mobile sync */
  const { getIsMobile } = useTailwind();

  const syncIsMobile = () => {
    mainStore.setIsMobile(getIsMobile());
  };

  useEffect(() => {
    syncIsMobile();
    window.addEventListener('resize', syncIsMobile);

    return () => window.removeEventListener('resize', syncIsMobile);
  }, []);

  return (
    <Observer>
      {() =>
        isUpdate ? (
          <Update />
        ) : (
          <>
            <div
              className={`App pb-14 md:pb-0 ${isCosmostationLoading && 'blur-sm'} ${
                isMobile() ? 'hide-scrollbar' : ''
              }`}
            >
              <AppHeader />
              <Routes>
                <Route path="/trade" element={<Trade />} />
                <Route path="/trading" element={<Trading />} />
                <Route path="/home" element={<Home />} />
                <Route path="/orderbook" element={<Orderbook />} />
                <Route path="/swap" element={<Swap />} />
                <Route path="/farm" element={<Farm />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/gov" element={<Gov />} />
                <Route path="/gov/:id" element={<GovDetail />} />
                <Route path="/airdrop" element={<Airdrop />} />
                <Route path="/gdex" element={<Gdex />} />
                <Route path="/terms" element={<Terms />} />

                {/* '/' & 404 handling */}
                {isMobile() ? (
                  <Route path="*" element={<Navigate to="/home" />} />
                ) : (
                  <Route path="*" element={<Navigate to="/orderbook" />} />
                )}
              </Routes>

              {/* mobile menu & warning banner */}
              <div className="fixed bottom-0 left-0 right-0 z-[1]">
                <MobileBottomMenu className="w-full md:hidden" />
                <NetworkDeplayedWarning networkDelayed={networkDelayed} className="w-full" />
              </div>

              {/* Toast */}
              <ToastContainer
                // limit={3}
                transition={Slide}
                position="top-right"
                closeOnClick={false}
                style={{ zIndex: '9999' }}
                newestOnTop={true}
                rtl={false}
                hideProgressBar
                pauseOnFocusLoss
                pauseOnHover
              />

              {/* Disclamer */}
              {IS_PRODUCTION && (
                <DisclaimerModal isOpen={isDisclaimerModal} close={() => setIsDisclaimerModal(false)} />
              )}
            </div>

            {/* {isCosmostationLoading && <MobileLoadingBlur />} */}
          </>
        )
      }
    </Observer>
  );
}

const NetworkDeplayedWarning = ({ networkDelayed, className }: { networkDelayed: boolean; className?: string }) => {
  const [isTestnetNotice, setIsTestnetNotice] = useState<boolean>(true);
  return (
    <div className={className}>
      {networkDelayed ? (
        <div className="w-full px-2 py-1 text-center font_body_s text-semantic_red_variant_light bg-semantic_red warning-flicker">
          App temporarily unstable: Cautious transaction recommended
        </div>
      ) : (
        !IS_PRODUCTION &&
        isTestnetNotice && (
          <div className="flex justify-center w-full px-2 py-1 font_body_s text-on_tertiary_light bg-tertiary_container">
            <div>Testnet - {INITIAL_ASSET.chainId}</div>
            <IconButton
              size="xs"
              iconType="close"
              onClick={() => setIsTestnetNotice(false)}
              className="absolute h-full right-2"
            />
          </div>
        )
      )}
    </div>
  );
};

const mobileMenus: {
  main: Page[];
  hidden: Page[];
} = {
  main: PAGES.filter((page) => !page.desktopOnly && !page.folded),
  hidden: PAGES.filter((page) => !page.desktopOnly && page.folded),
};

const MobileBottomMenu = ({ className }: { className?: string }) => {
  const location = useLocation();

  return (
    <nav className={`px-2 pt-3 pb-7 bg_surface_1 elevation_upward_2 ${className}`}>
      <div className="flex items-center gap-x-2">
        {mobileMenus.main.map((menu) => {
          const selected =
            location.pathname === menu.path ||
            (menu.path === '/trade' && ['/trading', '/swap', '/orderbook'].includes(location.pathname));

          return (
            <NavLink
              className={`basis-1/5 flex flex-col items-stretch justify-center gap-y-1 ${
                selected ? 'text-primary' : 'text-on_surface_variant_light'
              }`}
              to={menu.path}
              key={menu.path}
            >
              <div
                className={`flex items-center justify-center px-5 py-1 rounded-100px ${
                  selected ? 'bg-inverse_primary' : ''
                }`}
              >
                <Icon type={menu.iconType} size="24px" />
              </div>
              <div className="text-center font_label_s">{menu.name}</div>
            </NavLink>
          );
        })}

        <div className={`basis-1/5 text-center`}>
          <HeadlessPopover className="relative">
            {({ open, close }) => (
              <>
                <HeadlessPopover.Button className={`text-on_surface_variant_light ${open ? 'opacity-80' : ''}`}>
                  <div className="flex items-center justify-center px-5 py-1 rounded-100px">
                    <Icon type="more-horiz" size="24px" />
                  </div>
                  <div className="text-center font_label_s">More</div>
                </HeadlessPopover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <HeadlessPopover.Panel className="absolute right-0 bottom-full min-w-[110px]">
                    <Popover padding="0">
                      <div className="px-1 py-2 space-y-2">
                        {mobileMenus.hidden.map((menu) => (
                          <NavLink key={menu.path} to={menu.path} onClickCapture={() => close()}>
                            <MenuItem label={menu.name} iconType={menu.iconType} />
                          </NavLink>
                        ))}
                      </div>
                    </Popover>
                  </HeadlessPopover.Panel>
                </Transition>
              </>
            )}
          </HeadlessPopover>
        </div>
      </div>
    </nav>
  );
};

const MobileLoadingBlur = () => {
  return (
    <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen">
      <div className="mb-10 text-center">
        <div className="text-white mb-[1.25rem] text-lg">Please wait a minute</div>
        <div className="flex items-center justify-center">
          <Spinner spinnerClassName="text-outline_hard" />
        </div>
      </div>
    </div>
  );
};

export default observer(App);
