import Chip, { ChipColor, ChipSize } from 'components/chips/Chip';
import { useMemo } from 'react';
import type { Proposal, ProposalStatus } from 'types/gov';

type ProposalChip = {
  label: string;
  chipColor: ChipColor;
};

const PROPOSAL_STATUS_LABEL_DICT: { [key in ProposalStatus]: ProposalChip } = {
  PROPOSAL_STATUS_VOTING_PERIOD: { label: 'VOTING PERIOD', chipColor: 'blue' },
  PROPOSAL_STATUS_DEPOSIT_PERIOD: { label: 'DEPOSIT PERIOD', chipColor: 'deposit' },
  PROPOSAL_STATUS_PASSED: { label: 'PASSED', chipColor: 'yes' },
  PROPOSAL_STATUS_REJECTED: { label: 'REJECTED', chipColor: 'no' },
};

const ProposalStatusChip = ({ proposal, chipSize }: { proposal: Proposal; chipSize: ChipSize }) => {
  const proposalStatusChip = useMemo<ProposalChip>(
    () => PROPOSAL_STATUS_LABEL_DICT[proposal.proposal.status],
    [proposal.proposal.status]
  );

  return <Chip size={chipSize} label={proposalStatusChip.label} color={proposalStatusChip.chipColor} />;
};

export default ProposalStatusChip;
