import { encodePubkey, makeAuthInfoBytes } from '@cosmjs/proto-signing';
import { Int53 } from '@cosmjs/math';
import { fromBase64, fromHex } from '@cosmjs/encoding';
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';

import { payloadId } from '@walletconnect/utils';

import { makeSignDoc, encodeSecp256k1Pubkey } from '@cosmjs/amino';

export enum SignMode {
  SIGN_MODE_UNSPECIFIED = 0,
  SIGN_MODE_DIRECT = 1,
  SIGN_MODE_TEXTUAL = 2,
  SIGN_MODE_LEGACY_AMINO_JSON = 127,
  UNRECOGNIZED = -1,
}

// const fromHexStringToArray = (hexString) =>
//   Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
export const appSigner = (chainInfo, connector, pubkey, registry, aminoTypes, client) => {
  const sign = async (signerAddress, msgs, fee, memo?) => {
    const chainId = await client.getChainId();
    const { accountNumber, sequence } = await client.getSequence(signerAddress);

    const aminoMsgs = msgs.map((msg) => aminoTypes.toAmino(msg));
    const signDoc = makeSignDoc(aminoMsgs, fee, chainId, '', accountNumber, sequence);
    const signed = await connector?.sendCustomRequest({
      id: payloadId(),
      jsonrpc: '2.0',
      method: 'cosmostation_wc_sign_tx_v1',
      params: [chainId, signerAddress, signDoc],
    });

    const signedTxBody = {
      messages: signed[0].signed.msgs.map((msg) => aminoTypes.fromAmino(msg)),
      memo: signed[0].signed.memo,
    };
    const signedTxBodyEncodeObject = {
      typeUrl: '/cosmos.tx.v1beta1.TxBody',
      value: signedTxBody,
    };
    const arrayPubkey = fromHex(pubkey);
    const _pubkey = encodePubkey(encodeSecp256k1Pubkey(Uint8Array.from(arrayPubkey)));
    const signedTxBodyBytes = registry.encode(signedTxBodyEncodeObject);
    const signedGasLimit = Int53.fromString(signed[0].signed.fee.gas).toNumber();
    const signedSequence = Int53.fromString(signed[0].signed.sequence).toNumber();

    const signedAuthInfoBytes = makeAuthInfoBytes(
      [{ pubkey: _pubkey, sequence: signedSequence }],
      signed[0].signed.fee.amount,
      signedGasLimit,
      SignMode.SIGN_MODE_LEGACY_AMINO_JSON
    );

    return TxRaw.fromPartial({
      bodyBytes: signedTxBodyBytes,
      authInfoBytes: signedAuthInfoBytes,
      signatures: [fromBase64(signed[0].signature.signature)],
    });
  };
  return { sign };
};
