import type { AssetDetail } from 'types/asset';
import { observer } from 'mobx-react-lite';
import TransitionModal from '../TransitionModal';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import ModalHeader from '../components/ModalHeader';
import TabSelector from 'components/tabs/TabSelector';
import { IS_LF_ENABLED, MODAL_TITLES } from 'COMMON_VARIABLES';
import { useMainStore } from 'provider/mainContext';
import { TokenTypes } from 'components/constants/token';
import LabeledData from 'components/texts/LabeledData';
import TokenSelectButton from 'components/buttons/TokenSelectButton';
import Slider from 'components/slider/Slider';
import TokenSelect from 'components/tokenSelect/TokenSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { InfoChain } from 'types/chain';
import useOtherChainBalance from 'hooks/useOtherChainBalance';

type IBCTransferModalProps = { asset?: AssetDetail; isOpen: boolean; close: () => void };

function IBCTransferModal({ asset: defaultAsset, isOpen, close }: IBCTransferModalProps) {
  const store = useMainStore();

  /** @description tx chain */
  const [selectedAsset, setSelectedAsset] = useState<AssetDetail | undefined>(defaultAsset);

  const assetChainId = useMemo<string | undefined>(() => {
    return selectedAsset?.tokenType === TokenTypes.LF ? undefined : selectedAsset?.chainId;
  }, [selectedAsset?.tokenType, selectedAsset?.chainId]);

  const [transferChainId, setTransferChainId] = useState<string | undefined>(assetChainId);
  useEffect(() => setTransferChainId(assetChainId), [assetChainId]);

  const transferChain = useMemo<InfoChain | undefined>(
    () => store.chainsData.info[transferChainId ?? ''],
    [transferChainId, store.chainsData.info]
  );

  const { balance: transferChainBalance, wallet: transferChainWallet } = useOtherChainBalance(
    transferChainId ?? '',
    selectedAsset?.baseDenom ?? ''
  );

  /** @description token selecting */
  const assetOptions = store
    .getAssetDetails({ lf: IS_LF_ENABLED })
    .filter((asset) => asset.tokenType !== TokenTypes.NATIVE);

  const [isTokenSelectOpen, setIsTokenSelectOpen] = useState<boolean>(false);

  const onTokenSelect = useCallback((asset: AssetDetail) => {
    setSelectedAsset(asset);
    setIsTokenSelectOpen(false);
  }, []);

  return (
    <TransitionModal isOpen={isOpen} close={close} align="top" className="mt-5 md:mt-[6vh]">
      <ModalHeader color="surface" title={MODAL_TITLES.TRANSFER} onCloseClick={close}>
        <ModalHeader.Content className="pt-4">
          <LabeledData
            label="Want to transfer"
            value={<TokenSelectButton asset={selectedAsset} onClick={() => setIsTokenSelectOpen(!isTokenSelectOpen)} />}
          />
        </ModalHeader.Content>
      </ModalHeader>

      <div className="px-4 pb-5 pt-5 md:px-5 md:pb-6">
        <TabSelector
          type="line"
          size="sm"
          panels={[
            {
              key: 'Deposit',
              content: (
                <Deposit
                  asset={selectedAsset}
                  transferChain={transferChain}
                  setTransferChainId={setTransferChainId}
                  transferChainBalance={transferChainBalance}
                  transferChainWallet={transferChainWallet}
                  close={close}
                />
              ),
            },
            {
              key: 'Withdraw',
              content: (
                <Withdraw
                  asset={selectedAsset}
                  transferChain={transferChain}
                  setTransferChainId={setTransferChainId}
                  transferChainBalance={transferChainBalance}
                  transferChainWallet={transferChainWallet}
                  close={close}
                />
              ),
            },
          ]}
        />
      </div>

      <Slider direction="vertical" type="8.0625rem" open={isTokenSelectOpen}>
        <Slider.Header
          title="Select token to transfer"
          onBackClick={() => setIsTokenSelectOpen(false)}
          className="pt-5 pb-4"
        />

        <div className="h-[calc(100%_-_4.25rem)]">
          <TokenSelect
            showTokenDetail={false}
            selectedDenom={selectedAsset?.denom}
            availableAssets={assetOptions}
            sortBy="ticker"
            onSelect={onTokenSelect}
          />
        </div>
      </Slider>
    </TransitionModal>
  );
}

export default observer(IBCTransferModal);
