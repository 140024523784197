import BigNumber from 'bignumber.js';
import { alertInsufficientBalanceForTx } from 'common/utils';
import { CHAIN_ID } from 'COMMON_VARIABLES';
import useAmountUtils from 'hooks/useAmountUtils';
import useChainTxFeeCurrency from 'hooks/useChainTxFeeCurrency';
import useGas from 'hooks/useGas';
import { useCallback, useMemo, useState } from 'react';
import { MainStore } from 'store/store';
import type { InfoAsset } from 'types/asset';

const useDepositFee = ({
  mainStore,
  myTokenXBalance,
  myTokenYBalance,
  poolAssets,
}: {
  mainStore: MainStore;
  myTokenXBalance: BigNumber;
  myTokenYBalance: BigNumber;
  poolAssets: [InfoAsset, InfoAsset];
}) => {
  const [needEasySwapFee, setNeedEasySwapFee] = useState<boolean>(false);

  const { gas: poolDepositGas } = useGas('poolDeposit');
  const { gas: swapGas } = useGas('swap');

  const gas = useMemo<number>(
    () => poolDepositGas + (needEasySwapFee ? swapGas : 0),
    [needEasySwapFee, poolDepositGas, swapGas]
  );

  const { getFeeCurrency, hasTokenToPayFeeExcept } = useChainTxFeeCurrency({
    txChain: mainStore.getChain(CHAIN_ID)?.info,
    gas,
  });

  const { getFeeSubtractedPercentAmountOf: getXFeeSubtractedPercentAmountOf } = useAmountUtils({
    asset: poolAssets[0],
    txChainId: CHAIN_ID,
    gas,
  });

  const { getFeeSubtractedPercentAmountOf: getYFeeSubtractedPercentAmountOf } = useAmountUtils({
    asset: poolAssets[1],
    txChainId: CHAIN_ID,
    gas,
  });

  const getBalanceToMaximize = useCallback<(maxTarget: 'x' | 'y') => { xBalance: BigNumber; yBalance: BigNumber }>(
    (maxTarget: 'x' | 'y') => {
      const feeCurrencyX = getFeeCurrency(poolAssets[0]);
      const feeCurrencyY = getFeeCurrency(poolAssets[1]);

      /** case1: X, Y both are not fee currency, or can pay fee with other currency */
      if ((feeCurrencyX === undefined && feeCurrencyY === undefined) || hasTokenToPayFeeExcept(poolAssets))
        return { xBalance: myTokenXBalance, yBalance: myTokenYBalance };

      const feeSubtractedXBalance = getXFeeSubtractedPercentAmountOf(myTokenXBalance, 100);
      const feeSubtractedYBalance = getYFeeSubtractedPercentAmountOf(myTokenYBalance, 100);

      /** exception: chain and asset data is missing */
      if (!(feeSubtractedXBalance && feeSubtractedYBalance))
        return { xBalance: new BigNumber(0), yBalance: new BigNumber(0) };

      const canPayFeeWithX = feeSubtractedXBalance.gte(0);
      const canPayFeeWithY = feeSubtractedYBalance.gte(0);

      /** case2: must pay fee with X */
      if (feeCurrencyX && feeCurrencyY === undefined) {
        if (!canPayFeeWithX) alertInsufficientBalanceForTx(feeCurrencyX);
        return {
          xBalance: canPayFeeWithX ? feeSubtractedXBalance : myTokenXBalance,
          yBalance: myTokenYBalance,
        };
      }

      /** case3: must pay fee with Y */
      if (feeCurrencyX === undefined && feeCurrencyY) {
        if (!canPayFeeWithY) alertInsufficientBalanceForTx(feeCurrencyY);
        return { xBalance: myTokenXBalance, yBalance: canPayFeeWithY ? feeSubtractedYBalance : myTokenYBalance };
      }

      /** case4: must pay fee with X or Y */
      if (feeCurrencyX && feeCurrencyY) {
        if (!canPayFeeWithX && !canPayFeeWithY) alertInsufficientBalanceForTx([feeCurrencyX, feeCurrencyY]);

        if (maxTarget === 'x') {
          /** try with Y, and then try X if fails */
          return {
            xBalance: canPayFeeWithY ? myTokenXBalance : canPayFeeWithX ? feeSubtractedXBalance : myTokenXBalance,
            yBalance: canPayFeeWithY ? feeSubtractedYBalance : myTokenYBalance,
          };
        } else {
          /** try with X, and then try Y if fails */
          return {
            xBalance: canPayFeeWithX ? feeSubtractedXBalance : myTokenXBalance,
            yBalance: canPayFeeWithX ? myTokenYBalance : canPayFeeWithY ? feeSubtractedYBalance : myTokenYBalance,
          };
        }
      }

      return { xBalance: myTokenXBalance, yBalance: myTokenYBalance };
    },
    [
      getFeeCurrency,
      hasTokenToPayFeeExcept,
      getXFeeSubtractedPercentAmountOf,
      getYFeeSubtractedPercentAmountOf,
      myTokenXBalance,
      myTokenYBalance,
      poolAssets,
    ]
  );

  return {
    setNeedEasySwapFee,
    getBalanceToMaximize,
  };
};

export default useDepositFee;
