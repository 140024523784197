import { observer } from 'mobx-react-lite';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BannerMobile from 'resources/images/mobile_home_banner.png';
import NewsBannerLinkWidget from 'components/widgets/NewsBannerLinkWidget';
import OrderbookPairTable from 'components/orderbook/orderbookPairTable';
import useOrderbookData from 'hooks/useOrderbookData';
import type { OrderbookPair } from 'types/orderbook';
import TwitterEmbed from './TwitterEmbed';

const Home = observer(() => {
  const store = useMainStore();

  const { orderbookPairs } = useOrderbookData('', 3);

  const tradePageUrl = useMemo(() => {
    return localStorage.getItem('tradeType') ?? '/swap';
  }, [localStorage.getItem('tradeType')]);

  const navigate = useNavigate();

  const onClickPair = useCallback(
    (pair: OrderbookPair) => {
      const from: string = store.assetsData.info[pair.quoteDenom]?.ticker.replace('.', '').toLowerCase() ?? '';
      const to: string = store.assetsData.info[pair.baseDenom]?.ticker.replace('.', '').toLowerCase() ?? '';
      const destination = `${tradePageUrl}?from=${from}&to=${to}`;

      navigate(destination);
    },
    [tradePageUrl, store.assetsData.info, navigate]
  );

  return (
    <div className="pt-3 pb-20 space-y-4">
      <div className="px-4 space-y-4">
        <div className="text-white font_display_xs_mobile">What's new</div>

        <NewsBannerLinkWidget
          href="https://crescentnetwork.medium.com/looking-back-for-the-future-s-e7477c8b790f"
          src={BannerMobile}
          alt="Looking Back, For the Future(s)?"
        />

        <TwitterEmbed />
      </div>

      <section className="w-full">
        <OrderbookPairTable striped={true} pairs={orderbookPairs} onClickPair={onClickPair} />
      </section>
    </div>
  );
});

export default Home;
