import BigNumber from 'bignumber.js';
import { getHarvestTxsResult, getLpfarmAutoHarvestTxResult, getTxResult, TxResult } from 'common/tx/txResult';
import { useMainStore } from 'provider/mainContext';
import { useEffect, useMemo, useState } from 'react';
import { isValidTxEventType, TxEvent, TxEventResponse, TxHarvest } from 'types/txEvent';

const useOrderedTxResult = (txEventRes: TxEventResponse, onTriggerTxResultNoti: (txResult: TxResult) => void) => {
  const store = useMainStore();

  const newTxEvent = useMemo<TxEvent | undefined>(() => {
    const data: TxEvent | undefined = txEventRes?.data?.[0];
    if (data === undefined) return undefined;
    if (!isValidTxEventType(data)) return undefined;

    /** event more than 1 min ago */
    if (
      Number(new Date().getTime()) >
      new BigNumber(data.timestamp_us).shiftedBy(-3).decimalPlaces(0, 1).plus(60000).toNumber()
    ) {
      console.log('old new txEvent', data);
      return undefined;
    }

    console.log('new txEvent', data);
    store.isWatching = false;

    return data;
  }, [txEventRes?.data]);

  /** @desc unique tx event identifier */
  const newTxId = useMemo<string>(() => {
    const txType = newTxEvent?.tx_type;
    const eventType = newTxEvent?.evt_type;
    const txHash = newTxEvent?.txhash.length ? newTxEvent?.txhash : newTxEvent?.json_raw?.txhash;

    return `${txType}/${eventType}/${txHash}`;
  }, [newTxEvent]);

  /** @desc tx results queue; but manipulated in ordering */
  const [txResults, setTxResults] = useState<TxResult[]>([]);

  const [txNotifiedDict, setTxNotifiedDict] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (newTxEvent === undefined) return;

    const txResult =
      newTxEvent.tx_type === 'lpfarmHarvest'
        ? getHarvestTxsResult(txEventRes.data?.filter(isTxHarvest) ?? [])
        : getTxResult(newTxEvent);
    if (txResult === undefined) return;

    const additionalTxResult =
      newTxEvent.tx_type === 'lpfarmUnfarm' || newTxEvent.tx_type === 'lpfarmFarm'
        ? getLpfarmAutoHarvestTxResult(newTxEvent)
        : undefined;
    const additionalTxResults = additionalTxResult ? [additionalTxResult] : [];

    if (txResult.order === undefined) {
      setTxResults([...txResults, txResult, ...additionalTxResults]);
      return;
    }

    /** @desc reorder tx results */
    const txHash = txResult.txHash ?? '';

    if (txResult.order !== 0 || txNotifiedDict[txHash] === undefined) {
      setTxResults([...txResults, txResult, ...additionalTxResults]);
    }

    if (txResult.order === 1) {
      setTxNotifiedDict({ ...txNotifiedDict, [txHash]: true });
    }
  }, [newTxId]);

  useEffect(
    () => {
      const target: TxResult | undefined = txResults[0];

      if (target !== undefined) {
        onTriggerTxResultNoti(target);
        setTxResults(txResults.slice(1));
      }
    },
    /** @desc a weired dependency but to prevent state update while updating the state */
    [`${txResults[0]?.txType} ${txResults[0]?.feedbackType}`]
  );

  return {};
};

export default useOrderedTxResult;

/** @desc type guard */
function isTxHarvest(txEvent: TxEvent): txEvent is TxHarvest {
  return txEvent.tx_type === 'lpfarmHarvest';
}
