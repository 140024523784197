import { useCallback, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import {
  getDollarValueOnCreChainByDenom,
  convertExponent,
  getChangeSymbol,
  formatAmount,
  formatUSD,
} from 'common/utils';
import usePairSelector, { PAIR_SELECTORS } from 'hooks/usePairSelector';
import TabSelector from 'components/tabs/TabSelector';
import Table from 'components/table/Table';
import { TableStyles } from 'components/table/styles';
import type { OrderbookPair } from 'types/orderbook';
import IconToggleButton from 'components/buttons/IconToggleButton';
import { getChangeTextColor } from '../styles';
import NA from 'components/texts/NA';
import type { PairDetail } from 'types/pair';
import { useMainStore } from 'provider/mainContext';

type TableOrderbookPairRow = {
  pair: OrderbookPair;
  pairTitle: JSX.Element;
  price: JSX.Element;
  change24: JSX.Element;
};

type OrderbookPairTableProps = {
  pairs: PairDetail[] | OrderbookPair[];
  onClickPair?: (pair: OrderbookPair) => void;
  isGridXOffset?: boolean;
  striped?: boolean;
  rowsScrollHeight?: string;
};

const OrderbookPairTable = ({
  pairs,
  onClickPair,
  isGridXOffset = false,
  striped = false,
  rowsScrollHeight,
}: OrderbookPairTableProps) => {
  const { pairIndex, onChangePairIndex, selectedOrderbookPairs, favorites, onFavorite } = usePairSelector(pairs);

  const orderbookPairs = useMemo<TableOrderbookPairRow[]>(() => {
    return selectedOrderbookPairs.map((pair) => {
      return {
        pair,
        pairTitle: (
          <div className="flex items-center gap-x-0.5 md:gap-x-2">
            <IconToggleButton isOn={favorites.has(pair.pairId)} onClick={() => onFavorite(pair.pairId)} />

            <div className="flex flex-wrap items-center gap-x-0.5 md:gap-x-1">
              <div className="font_caption_s text-on_surface">{pair.baseInfo.ticker}</div>
              <span className="font_caption_xs text-on_surface_variant_light">/{pair.quoteInfo.ticker}</span>
            </div>
          </div>
        ),
        price: (
          <div className="text-right space-y-0.5">
            <div className="flex items-center gap-x-1">
              <div className="font_data_number_xs text-on_surface">
                {formatAmount(
                  new BigNumber(convertExponent(new BigNumber(pair.lastPrice).shiftedBy(pair.exponentDiff).toNumber())),
                  9
                )}
              </div>
              <div className="font_caption_xs text-on_surface_variant_light">{pair.quoteInfo.ticker}</div>
            </div>
            <div className="font_caption_number_s text-on_surface_variant_dark">
              ≈
              {formatUSD(
                new BigNumber(
                  getDollarValueOnCreChainByDenom(
                    pair.quoteInfo.denom,
                    new BigNumber(pair.lastPrice).shiftedBy(pair.exponentDiff)
                  )
                ),
                { fixedTo2: false }
              )}
              {/* {formatAmount(
                new BigNumber(
                  getDollarValueOnCreChainByDenom(
                    pair.quoteInfo.denom,
                    new BigNumber(pair.lastPrice).shiftedBy(pair.exponentDiff)
                  )
                ),
                6,
                '$',
                { fixMantissa: true, minimumMantissa: 2 }
              )} */}
            </div>
          </div>
        ),
        change24: (
          <div className={`font_data_number_xs ${getChangeTextColor(pair.change_24 === 100000 ? 0 : pair.change_24)}`}>
            <span>{getChangeSymbol(pair.change_24 === 100000 ? 0 : pair.change_24)}</span>
            {pair.change_24 === 100000 ? <NA /> : `${formatAmount(new BigNumber(Math.abs(pair.change_24)), 2)}%`}
          </div>
        ),
      };
    });
  }, [selectedOrderbookPairs, favorites, onFavorite]);

  const onRowClick = useCallback(
    (row: TableOrderbookPairRow) => {
      onClickPair?.(row.pair);
    },
    [onClickPair]
  );

  const store = useMainStore();

  return (
    <div className="px-4">
      <TabSelector
        type="filled"
        size="sm"
        defaultKey={PAIR_SELECTORS[pairIndex]}
        onChangeTab={onChangePairIndex}
        panels={PAIR_SELECTORS.map((tabLabel) => ({
          key: tabLabel,
          content: (
            <div className={isGridXOffset ? '-mx-4' : ''}>
              {/** @todo selected pair id 일치하는 열에 hover와 같은 효과주기  */}
              <Table<TableOrderbookPairRow>
                type={TableStyles.BORDERLESS}
                rowsScrollHeight={rowsScrollHeight}
                isLoading={false}
                striped={striped}
                rows={orderbookPairs}
                onRowClick={onRowClick}
                fields={[
                  {
                    label: 'Pair',
                    value: 'pairTitle',
                    type: 'jsx',
                    sortDisabled: true,
                  },
                  {
                    label: 'Price',
                    value: 'price',
                    type: 'jsx',
                    sortDisabled: true,
                    align: 'right',
                    widthRatio: 24,
                  },
                  {
                    label: '24h Change',
                    value: 'change24',
                    type: 'jsx',
                    sortDisabled: true,
                    align: 'right',
                    widthPx: store.isMobile ? 70 : 84,
                  },
                ]}
              />
            </div>
          ),
        }))}
      />
    </div>
  );
};

export default OrderbookPairTable;
