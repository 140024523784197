import { ReactNode } from 'react';
import SliderHeader from './SliderHeader';

type SliderDirection = 'vertical' | 'horizontal';
type SliderType = 'full' | '75%' | '5.625rem' | '8.0625rem';

const SLIDER_CLOSED_TRANSLATE_X_DICT: Record<SliderDirection, string> = {
  horizontal: 'translate-x-full',
  vertical: 'translate-y-full',
};

const SLIDER_OPEN_TRANSLATE_X_DICT: Record<SliderDirection, Record<SliderType, string>> = {
  horizontal: {
    full: 'translate-x-0',
    '75%': 'translate-x-1/4',
    '5.625rem': 'translate-x-[5.625rem]',
    '8.0625rem': 'translate-x-[8.0625rem]',
  },
  vertical: {
    full: 'translate-y-0',
    '75%': 'translate-y-1/4',
    '5.625rem': 'translate-y-[5.625rem]',
    '8.0625rem': 'translate-y-[8.0625rem]',
  },
};

const SLIDER_VERT_HEIGHT_DICT: Record<SliderType, string> = {
  full: 'h-full',
  '75%': 'h-3/4',
  '5.625rem': 'h-[calc(100%_-_5.625rem)]',
  '8.0625rem': 'h-[calc(100%_-_8.0625rem)]',
};

type SliderProps = { children: ReactNode; open?: boolean; direction?: SliderDirection; type?: SliderType };

const Slider = ({ children, open, direction = 'horizontal', type = 'full' }: SliderProps) => {
  return (
    <div
      className={`absolute inset-0 w-full h-full will-change-transform transition-all !duration-200 bg_surface_1 ${
        open ? '!ease-[cubic-bezier(0.45,0.05,0.55,0.95)]' : '!ease-in-out'
      } ${open ? SLIDER_OPEN_TRANSLATE_X_DICT[direction][type] : SLIDER_CLOSED_TRANSLATE_X_DICT[direction]}`}
    >
      <div className={direction === 'vertical' ? SLIDER_VERT_HEIGHT_DICT[type] : 'h-full'}>{children}</div>
    </div>
  );
};

Slider.Header = SliderHeader;

export default Slider;
