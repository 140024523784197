import axios from 'axios';
/* eslint-disable no-useless-escape */
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import {
  BRIDGE_GATEWAY,
  BRIDGES,
  MAX_GAS_AMOUNT,
  NUMBER_FORMAT_LOCALE,
  GAS_MIN_AMOUNT_DICT,
  TX_EXPLORER_URLS,
  INITIAL_ASSET,
  IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY,
  TOAST_TITLE_TX_BROADCAST,
  TOAST_CONTENT_TX_BROADCAST,
  ROOT_ADDRESS,
} from 'COMMON_VARIABLES';
import type { InfoAsset, InfoAssetWithMyAmount, AssetDetail, TickerAnatomy } from 'types/asset';
import { TokenTypes } from 'components/constants/token';
import type { MainStore } from 'store/store';
import type { PoolDetail, RewardPlan, RewardsByTokenMap } from 'types/pool';
import type { Currency, CurrencyDetail, GasPriceStep, InfoChain } from 'types/chain';
import { Coin, SigningStargateClient, StargateClient } from '@cosmjs/stargate';
// import type { TxEvent } from 'types/txEvent';
import type { SignParams } from 'types/signingClients';
import { IS_PRODUCTION } from 'COMMON_VARIABLES';
import { ChainTypes } from 'components/constants/chain';
import callToast from './callToast';
import { getTxErrorResult } from './tx/txError';
import type { TxResult } from './tx/txResult';
import { store } from 'provider/mainContext';
import type { ResponseOne } from 'types/format';
import type { Wallet } from 'types/wallet';

export function getImageURL(url: any) {
  if (url) {
    return url;
  } else {
    return require(`resources/images/undefined.png`);
  }
}

export function toShortAddress(address, pre = 18, suf = 14) {
  if (address) {
    return `${address.slice(0, pre)}...${address.slice(-suf)}`;
  }
}

export async function getCrescentChainFee(
  type: SignParams['type'],
  initialAsset,
  airdropClaimData,
  defaultGasRate,
  txData?: any
) {
  let gas = 200000;
  // type === 'deposit'
  //   ? 200000
  //   : type === 'staking' || type === 'unstaking'
  //   ? 2500000
  //   : airdropClaimData
  //   ? type === 'vote'
  //     ? 200000
  //     : 500000
  //   : 200000;
  if (type === 'cancelAllOrders' && txData?.msgCount > 4) {
    gas = 200000 + Number(txData?.msgCount ?? '0') * 40000;
  } else if (type === 'staking' || type === 'unstaking') {
    gas = 10000000; // set default gas for staking and unstaking
    if (IS_PRODUCTION) {
      // increse gas for staking and unstaking if there are more than 15 validators only in production
      // because in testnet there are only 1 validator
      try {
        const validatorsData = await axios.get(
          'https://mainnet.crescent.network:1317/crescent/liquidstaking/v1beta1/validators'
        );
        const valiNumber = validatorsData?.data?.liquid_validators?.length;
        if (valiNumber > 15) {
          gas += (valiNumber - 15) * 500000;
        }
      } catch (e) {
        console.warn('Can not get validators data', e);
      }
    }
  } else if (airdropClaimData && type !== 'vote') {
    gas = 500000;
  } else if (type === 'claim') {
    gas = 300000;
  } else if (type === 'harvest') {
    const msgCountMultiplier = Array.isArray(txData) && txData.length > 4 ? txData.length : 0;
    gas = 500000 + msgCountMultiplier * 200000;
  } else if (['liquidFarm', 'liquidUnfarm', 'liquidUnfarmAndWithdraw'].includes(type)) {
    /** @todo gas is tbd */
    gas = 300000;
  }

  return {
    gas: `${gas * getGasMultiplier(type)}`,
    amount: [
      {
        amount: `${gas * getGasMultiplier(type) * defaultGasRate}`,
        denom: initialAsset.denom,
      },
    ],
  };
}

export async function getValiNumber(): Promise<number> {
  if (IS_PRODUCTION) {
    try {
      const validatorsData = await axios.get(
        'https://mainnet.crescent.network:1317/crescent/liquidstaking/v1beta1/validators'
      );
      const valiNumber = validatorsData?.data?.liquid_validators?.length;
      return valiNumber ?? 0;
    } catch (e) {
      console.warn('Can not get validators data', e);
    }
  }
  return 0;
}

export async function getAddiGasByTx(
  type: SignParams['type'],
  msgCount = 1,
  callback?: (num: number) => void
): Promise<number> {
  if (type === 'staking' || type === 'unstaking') {
    const valiNumber = await getValiNumber();
    const addiGas = valiNumber > 15 ? (valiNumber - 15) * 400000 : 0;
    callback?.(addiGas);
    return addiGas;
  } else if (type === 'cancelAllOrders') {
    const addiGas = msgCount > 4 ? msgCount * 400000 : 0;
    callback?.(addiGas);
    return addiGas;
  } else if (type === 'harvest') {
    const addiGas = msgCount > 5 ? msgCount * 80000 : 0;
    callback?.(addiGas);
    return addiGas;
  }
  return Promise.resolve(0);
}

/** @todo replace getCrecsentChainFee? */
export async function getCrescentGasFee(
  type: SignParams['type'],
  initialAsset,
  airdropClaimData,
  defaultGasRate,
  txData?: any
): Promise<{
  gas: string;
  amount: {
    amount: string;
    denom: any;
  }[];
}> {
  const addiGas = await getAddiGasByTx(type, txData?.msgCount ?? Array.isArray(txData) ? txData.length : 1);
  const gas = GAS_MIN_AMOUNT_DICT[type] + addiGas + (airdropClaimData && type !== 'vote' ? 500000 : 0);

  return {
    gas: `${gas * getGasMultiplier(type)}`,
    amount: [
      {
        amount: `${gas * getGasMultiplier(type) * defaultGasRate}`,
        denom: initialAsset.denom,
      },
    ],
  };
}

/** @summary set tmp gas multiplier for failed tx type */
export function incrementGasMultiplier(txType: SignParams['type'], keep = true) {
  const storageKey = `${txType}-gas-multiplier`;
  if (keep) {
    const multiplier = Number(localStorage.getItem(storageKey) ?? '1') + 0.2;
    localStorage.setItem(storageKey, `${multiplier}`);
  } else {
    localStorage.removeItem(storageKey);
  }
}

export function getGasMultiplier(txType: SignParams['type']): number {
  const storageKey = `${txType}-gas-multiplier`;
  return Number(localStorage.getItem(storageKey) ?? '1');
}

export function getDollarValueOnCreChainByDenom(
  denom: string,
  amount,
  // mainStore,
  zeroSymbol = '-',
  decimalPlaces = 2
): string {
  if (store.assetsData.live?.[denom]?.priceOracle && denom && store) {
    const _temp = getValidDigitNumber(
      new BigNumber(amount).multipliedBy(store.assetsData.live?.[denom]?.priceOracle ?? 0).toNumber(),
      3,
      1
    );

    const _tempDecimalLength = _temp?.split('.')[1]?.length;
    // console.log(
    //   '_temp',
    //   new BigNumber(amount).multipliedBy(store.assetsData.live?.[denom]?.priceOracle ?? 0).toNumber()
    // );

    // new BigNumber(amount)
    // .multipliedBy(store.assetsData.live?.[denom]?.priceOracle ?? 0)
    // .decimalPlaces(decimalPlaces, 1)
    // .toNumber()
    // .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimalPlaces });
    if (_tempDecimalLength) {
      const decimalDiff = decimalPlaces - _tempDecimalLength;
      if (decimalDiff < 0) {
        return _temp;
      } else {
        return _temp + '0'.repeat(decimalDiff);
      }
    } else {
      if (decimalPlaces < 0) {
        return _temp;
      } else {
        return `${_temp}.${'0'.repeat(decimalPlaces)}`;
      }
    }
  } else {
    return zeroSymbol;
  }
}

export function detectBridgeAsset(ticker): {
  ticker: string;
  bridge: string;
  isBridged: boolean;
  gateway: string;
} {
  if (ticker === BRIDGES['injective']) {
    return {
      ticker: ticker,
      bridge: BRIDGES['injective'],
      isBridged: false,
      gateway: BRIDGE_GATEWAY['INJ'],
    };
  } else if (ticker === BRIDGES['neok']) {
    return {
      ticker: ticker,
      bridge: BRIDGES['neok'],
      isBridged: false,
      gateway: BRIDGE_GATEWAY['NEOK'],
    };
  } else if (ticker === BRIDGES['okc']) {
    return {
      ticker: ticker,
      bridge: BRIDGES['okc'],
      isBridged: false,
      gateway: BRIDGE_GATEWAY['OKT'],
    };
  } else if (ticker && !_.isEmpty(ticker) && ticker.includes('.')) {
    let bridge = ticker.split('.')[1].toUpperCase();

    // TEST CODE
    if (bridge === 'A') {
      bridge = 'AXL';
    } else if (bridge === 'G') {
      bridge = 'GRV';
    }
    return { ticker, bridge, isBridged: true, gateway: BRIDGE_GATEWAY[bridge] ?? 'IBC' };
  } else {
    return { ticker, bridge: '', isBridged: false, gateway: 'IBC' };
  }
}

export function goBridge(gateway, bridgeInfo, assetInfo = { source: '', destination: '', denom: '' }) {
  console.log(bridgeInfo);
  if (BRIDGES['axelar'] === bridgeInfo.bridge) {
    window.open(
      gateway + `/?${assetInfo.source ? `source=${assetInfo.source}` : `destination=${assetInfo.destination}`}`
    );
  } else {
    window.open(gateway);
  }
}

export function convertExponent(n: string | number): string {
  let data = String(n).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z: string = '',
    sign: string = Number(n) < 0 ? '-' : '',
    str: string = data[0].replace('.', ''),
    mag: number = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}

export function getExponentDiff(a: string, b: string, mainStore: MainStore): number {
  const aExponent: number | undefined = mainStore.assetsData.info?.[a]?.exponent;
  const bExponent: number | undefined = mainStore.assetsData.info?.[b]?.exponent;
  return aExponent !== undefined && bExponent !== undefined ? aExponent - bExponent : 0;
}

export function onlyNumberValueUpdaterWhiteWords(value: string, func: (value: string) => void) {
  if (value) {
    // 숫자 . e 세개만 허용
    const _value = value.replaceAll(/[^0-9e.\-\+]/g, '');
    if (
      _value.split('.').length <= 2 &&
      _value.split('e').length <= 2 &&
      _value.split('-').length <= 2 &&
      _value.split('+').length <= 2
    ) {
      const minusIndex = _value.indexOf('-');
      const plusIndex = _value.indexOf('+');
      if (minusIndex > 0 && plusIndex > 0) {
        return;
      }
      if (minusIndex === 0 || plusIndex === 0) {
        return;
      }
      if (minusIndex > 0 && _value[minusIndex - 1] !== 'e') {
        return;
      }
      if (plusIndex > 0 && _value[plusIndex - 1] !== 'e') {
        return;
      }
      const eIndex = _value.indexOf('e');
      // console.log(eIndex);
      if (eIndex === 0) {
        return;
      }
      if (eIndex > 0 && _value[eIndex + 1] === '.') {
        return;
      }
      func(_value);
    }
  } else {
    func('');
  }
}

export function onlyNumberValueUpdater(value: string, func: (value: string) => void, exponent?: number) {
  if (
    !/[a-zA-Z]/.test(value.replaceAll('e', '')) &&
    !/[\{\}\[\]\/?,;:|\)*~`!^\_<>@\#$%&\\\=\(\'\"]/g.test(value) &&
    !value.includes(' ')
  ) {
    if (Number(value) < 0) {
      return func('');
    }
    if (value.endsWith('.') || value.endsWith('0')) {
      func(value);
    } else {
      const decimalLimitedValue = new BigNumber(value).toString();
      decimalLimitedValue !== 'NaN' && false ? func(decimalLimitedValue) : func(value);
    }
  }
}

export function getValidDigitNumber(value: number, validDigits = 4, roundMode = 1): string {
  try {
    const commaRemovedValue = String(value).replaceAll(',', '');
    const parsedFloatValue = parseFloat(commaRemovedValue);
    const _temp = String(parsedFloatValue).includes('e')
      ? convertExponent(String(parsedFloatValue))
      : String(parsedFloatValue);

    if (!_temp.includes('.')) {
      const validDigitsLengthDiff = validDigits - _temp.length;
      if (validDigits > 0 && validDigitsLengthDiff > 0) {
        if (validDigitsLengthDiff < 0) {
          return String(_temp);
        } else {
          return String(_temp) + '.' + '0'.repeat(validDigitsLengthDiff);
        }
      } else {
        return _temp;
      }
    } else {
      const dividedNumbers = _temp.split('.');
      const intLength = dividedNumbers[0] !== '0' ? dividedNumbers[0].length : 0;

      let decimalZeroLength: number = 0;
      if (!intLength) {
        for (let unit of dividedNumbers[1]) {
          if (unit === '0') {
            decimalZeroLength += 1;
          } else {
            break;
          }
        }
      }
      const decimalDigits = decimalZeroLength + validDigits - intLength;

      let result: string;
      if (dividedNumbers[1].length < decimalDigits) {
        result = Number(_temp).toFixed(decimalDigits);
      } else {
        const rounding = roundMode === 1 ? BigNumber.ROUND_FLOOR : BigNumber.ROUND_CEIL;
        const _temp2 =
          intLength > 0
            ? new BigNumber(_temp)
                .decimalPlaces(validDigits - intLength < 0 ? 0 : validDigits - intLength, rounding)
                .toString()
            : new BigNumber(_temp).decimalPlaces(decimalZeroLength + validDigits, rounding).toString();
        if (intLength > 0) {
          const decimalLength = _temp2.includes('.') ? _temp2?.split('.')?.[1].length : 0;
          const expectedDecimalLength = validDigits - intLength;
          const diff = expectedDecimalLength - decimalLength;
          if (diff > 0) {
            result = _temp2.includes('.') ? _temp2 + '0'.repeat(diff) : _temp2 + '.' + '0'.repeat(diff);
          } else {
            result = _temp2;
          }
        } else {
          result = _temp2;
        }
      }

      return Number.isNaN(Number(result)) ? '-' : result.includes('e') ? convertExponent(result) : result;
    }
  } catch (e) {
    console.log('validDigitNumber error : ', e);
    return value.toString();
  }
}

/** @desc tx notification */
export function getViewTxLink(txResult: TxResult): { label: string; href: string } | undefined {
  return txResult.txHash
    ? {
        label: 'View transaction details',
        href: getTxExplorerURL({
          txHash: txResult.txHash,
          chainId: txResult.txChainId ?? INITIAL_ASSET.chainId,
        }),
      }
    : undefined;
}

export function notifyTxResult(txResult: TxResult) {
  if (localStorage.getItem(IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY) === 'false') return;

  const toastId = txResult.txHash ?? txResult.time.toString();

  callToast({
    type: txResult.feedbackType,
    toastId,
    title: txResult.title,
    autoClose: txResult.feedbackType === 'success' ? 6000 : false,
    content: txResult.content,
    link: getViewTxLink(txResult),
    update: store.toastIds.has(toastId),
  });

  store.setToastIds(toastId);
}

export function notifyTxBroadcast(hash: string) {
  if (localStorage.getItem(IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY) === 'false') return;

  callToast({
    type: 'waiting',
    toastId: hash,
    title: TOAST_TITLE_TX_BROADCAST,
    autoClose: 8000,
    content: TOAST_CONTENT_TX_BROADCAST,
  });

  store.setToastIds(hash);
}

export function notifyTxError(error: any, txData?: any, txHash?: string) {
  const txErrorResult = getTxErrorResult(error + '', txData);

  callToast({
    type: 'error',
    toastId: txHash ?? error + '',
    update: txHash !== undefined,
    title: txErrorResult.title,
    content: txErrorResult.content,
    link: txErrorResult.link,
    autoClose: false,
  });
}

/**
 * For ranged pool easy swap
 * @param swapRatio
 * @param depositRatio
 * @param param2
 * @param counterAssetBalance
 * @param exponents
 * @returns
 */
export function getMaxSwapInputAmount(
  swapRatio,
  depositRatio,
  { denom, amount },
  counterAssetBalance = 0,
  exponents = { x: 6, y: 6 }
) {
  const standardizeSwapRatio = new BigNumber(swapRatio).shiftedBy(exponents.x - exponents.y);
  const maxAmountAsCounterAsset = new BigNumber(amount).multipliedBy(standardizeSwapRatio).plus(counterAssetBalance); // 9 * 2 + 8 = 26cre
  const ratioAsCounterAsset = new BigNumber(depositRatio).plus(standardizeSwapRatio); // 3cre + 2cre = 5cre
  const maxMultiplier = new BigNumber(maxAmountAsCounterAsset).dividedBy(ratioAsCounterAsset); // 26cre / 5cre = 5.2

  let result = new BigNumber(amount).minus(maxMultiplier).decimalPlaces(exponents.x, 1); // 9 - 5.2 = 3.8 bcre
  if (result.lt(0)) {
    result = new BigNumber(result).absoluteValue().multipliedBy(standardizeSwapRatio).decimalPlaces(exponents.y, 1);
    return { amount: result.toNumber(), denomSide: 'y' };
  } else {
    return { amount: result.toNumber(), denomSide: 'x' };
  }
}

export function getAmp({
  lastPrice,
  minPrice,
  maxPrice,
}: {
  lastPrice: string | number | BigNumber;
  minPrice: string | number | BigNumber;
  maxPrice: string | number | BigNumber;
}): BigNumber {
  /** basic pool */
  if (minPrice === 0 && maxPrice === 0) return new BigNumber(1);
  if (new BigNumber(minPrice).isZero() && new BigNumber(maxPrice).isZero()) return new BigNumber(1);

  /** out of range */
  const lastPriceBN = BigNumber.isBigNumber(lastPrice) ? lastPrice : new BigNumber(lastPrice);
  const isOutOfRange = lastPriceBN.lt(minPrice) || lastPriceBN.gt(maxPrice);
  if (isOutOfRange) return new BigNumber(0);

  /** in range */
  return new BigNumber(2)
    .dividedBy(
      new BigNumber(2)
        .minus(new BigNumber(new BigNumber(minPrice).dividedBy(lastPrice)).squareRoot())
        .minus(new BigNumber(new BigNumber(lastPrice).dividedBy(maxPrice)).squareRoot())
    )
    .decimalPlaces(4, 1);
  // .toNumber();
}

type FormatAmountOptions = {
  compact?: boolean;
  fixMantissa?: boolean;
  roundMode?: BigNumber.RoundingMode;
  locale?: string;
  minimumMantissa?: number;
  symbol?: '$' | '';
};

export function formatAmount(value: BigNumber, mantissa = 2, options?: FormatAmountOptions) {
  const symbol = options?.symbol ?? '';

  const minMantissa =
    mantissa === 2 && symbol === '$'
      ? 2
      : options?.fixMantissa
      ? options?.minimumMantissa
        ? options?.minimumMantissa
        : mantissa
      : undefined;

  const formatter = new Intl.NumberFormat(options?.locale ?? NUMBER_FORMAT_LOCALE, {
    notation: options?.compact ? 'compact' : 'standard',
    maximumFractionDigits: mantissa,
    minimumFractionDigits: minMantissa,
    // @ts-ignore
    trailingZeroDisplay: minMantissa === undefined ? 'stripIfInteger' : 'auto',
  });

  if (value.isZero() || value.isNaN()) return `${symbol}${formatter.format(0)}`;

  const min = new BigNumber(1).shiftedBy(-mantissa);
  if (value.lt(min)) return `<${symbol}${formatter.format(min.toNumber())}`;

  const amount = value.dp(mantissa, options?.roundMode ?? BigNumber.ROUND_DOWN);
  return `${symbol}${formatter.format(amount.toNumber()).toLocaleLowerCase()}`;
}

type FormatUSDOptions = { symbol?: '$' | ''; significant?: boolean; compact?: boolean; fixedTo2?: boolean };

export function formatUSD(value: BigNumber, options?: FormatUSDOptions) {
  const symbol: '$' | '' = options?.symbol ?? '$';
  const compact = options?.compact;

  if (value.isZero()) return `${symbol}0.00`;

  const fixedTo2 = options?.fixedTo2 ?? true;
  if (fixedTo2 && !options?.significant) {
    const minValueFixedTo2 = 0.01;
    if (value.lt(minValueFixedTo2)) return `<${symbol}${minValueFixedTo2}`;

    return formatAmount(value, 2, {
      symbol,
      minimumMantissa: 2,
      compact,
    });
  }

  const minValue = 0.000001;
  if (value.lt(minValue)) return `<${symbol}${minValue}`;

  if (options?.significant)
    return formatAmount(value, 6, {
      symbol,
      minimumMantissa: 2,
    });

  if (value.lt(0.001))
    return formatAmount(value, 6, {
      symbol,
      minimumMantissa: 2,
    });

  if (value.lt(0.1))
    return formatAmount(value, 3, {
      symbol,
      minimumMantissa: 2,
    });

  return formatAmount(value, 2, {
    symbol,
    minimumMantissa: 2,
    compact,
  });
}

export function getFormattedZero({
  mantissa,
  locale,
  symbol = '',
}: {
  mantissa: number;
  locale?: string;
  symbol?: '' | '$';
}) {
  const formatter = new Intl.NumberFormat(locale ?? NUMBER_FORMAT_LOCALE, {
    minimumFractionDigits: mantissa,
  });
  return `${symbol}${formatter.format(0)}`;
}

export function getDecimalSeperator(locale?: string): string | undefined {
  return new Intl.NumberFormat(locale ?? NUMBER_FORMAT_LOCALE)
    .formatToParts(1.1)
    .find((part) => part.type === 'decimal')?.value;
}

export function getIntegerSeperator(locale?: string): string | undefined {
  return new Intl.NumberFormat(locale ?? NUMBER_FORMAT_LOCALE)
    .formatToParts(1000000000)
    .filter((part) => part.type === 'group')
    .map((part) => part.value)[0];
}

export function getTokenName(asset: InfoAssetWithMyAmount | AssetDetail): {
  tokenName: string;
  poolId: string | undefined;
} {
  const tokenName =
    [TokenTypes.POOL, TokenTypes.LF].includes(asset.tokenType) && asset.originPool
      ? `${asset.originPool.assets[0].ticker}/${asset.originPool.assets[1].ticker}`
      : asset.ticker;

  return {
    tokenName,
    poolId: asset.originPool ? `#${asset.originPool.poolId}` : undefined,
  };
}

/**
 *
 * @caution poolTokenAmount is non-exponentized value, to be calculated with pool totalSupplyAmount
 * @returns [baseTokenAmount, quoteTokenAmount]
 */
export function getWithdrawTokensAmount(poolTokenAmount: BigNumber, pool: PoolDetail): [BigNumber, BigNumber] {
  const myShare = new BigNumber(poolTokenAmount).dividedBy(pool.totalSupplyAmount);

  const tokenXAmount = new BigNumber(pool.reserved[0].amount)
    .multipliedBy(myShare)
    .dp(0, BigNumber.ROUND_DOWN)
    .shiftedBy(-pool.assets[0].exponent);
  const tokenYAmount = new BigNumber(pool.reserved[1].amount)
    .multipliedBy(myShare)
    .dp(0, BigNumber.ROUND_DOWN)
    .shiftedBy(-pool.assets[1].exponent);
  return [tokenXAmount.decimalPlaces(pool.assets[0].exponent), tokenYAmount.decimalPlaces(pool.assets[1].exponent)];
}

/** @todo lf identification is TBD */
export function getTokenType(asset: InfoAsset): TokenTypes {
  if (asset.denom.startsWith('pool')) return TokenTypes.POOL;
  if (asset.denom.startsWith('lf')) return TokenTypes.LF;
  if (detectBridgeAsset(asset.ticker).isBridged) return TokenTypes.BRIDGED;
  if (asset.denom.startsWith('ibc/')) return TokenTypes.IBC;
  return TokenTypes.NATIVE;
}

/**
 * @summary copied from useOtherChainBalance hook, to be used in loop
 * @description denom must be denom for Crescent chain, but baseDenom for other chains
 */
export function connectStargateClient(txChain: InfoChain): Promise<StargateClient> {
  return SigningStargateClient.connect(`https://${txChain.wsEndpoint}`);
}

export function fetchChainBalance({
  client,
  wallet,
  denom,
}: {
  client: StargateClient | undefined;
  wallet: Wallet | null;
  denom: string;
}): Promise<Coin> | Coin {
  const result = client && wallet ? client.getBalance(wallet.address, denom) : { denom, amount: '0' };
  return result;
}

/** @desc get gas price of txChain; if no feeCurrency is assigned, fallbacks to first one of txChain */
export function getTxChainGasPrice(
  txChain: InfoChain,
  gasPriceStepKey: keyof GasPriceStep,
  feeCurrency?: Currency
): number {
  const targetCurrency = txChain.feeCurrencies.find((fc) => fc.coinDenom === feeCurrency?.coinDenom);
  return (
    targetCurrency?.gasPriceStep?.[gasPriceStepKey] ??
    txChain.feeCurrencies[0]?.gasPriceStep?.[gasPriceStepKey] ??
    txChain.gasPriceStep?.[gasPriceStepKey] ??
    0
  );
}

/** @desc get fee amount by currency */
export function getFeeAmount({
  txChain,
  feeCurrency,
  gas = MAX_GAS_AMOUNT,
  gasPriceStepKey = 'average',
  txCnt = 1,
}: {
  txChain: InfoChain;
  feeCurrency: Currency;
  gas?: number;
  gasPriceStepKey?: keyof GasPriceStep;
  txCnt?: number;
}): BigNumber {
  const gasPrice = getTxChainGasPrice(txChain, gasPriceStepKey, feeCurrency);

  const isCosmosChain = txChain.chainId.includes('cosmoshub');

  return new BigNumber(gasPrice ?? 0)
    .multipliedBy(gas)
    .div(isCosmosChain ? 1.5 : 1)
    .shiftedBy(-feeCurrency.coinDecimals)
    .multipliedBy(txCnt);
}

/** @desc subtract tx fee amount from action amount, when left balance < fee */
export function getFeeSubtractedAmount({
  amount,
  balance,
  asset,
  feeCurrencies,
  txCnt,
  onInsufficientBalance,
}: {
  amount: BigNumber;
  balance: BigNumber;
  asset: InfoAsset;
  feeCurrencies: CurrencyDetail[];
  txCnt: number;
  onInsufficientBalance: (feeCurrency: CurrencyDetail) => void;
}): BigNumber {
  /** sanity check */
  if (amount.gt(balance)) {
    alert('Warning: amount cannot exceed balance');
    return amount;
  }

  /** case1: no balance */
  if (balance.lte(0)) return new BigNumber(0);

  /** case2: the token is not fee currency */
  const feeCurrency = feeCurrencies.find((currency) => currency.coinMinimalDenom === asset.baseDenom);
  if (feeCurrency === undefined) return amount;

  const feeAmount = feeCurrency.feeAmount.multipliedBy(Number.isInteger(txCnt) ? txCnt : 1);

  /** case4: can pay fee with left balance */
  const leftBalance = balance.minus(amount);
  if (leftBalance.gte(feeAmount)) return amount;

  /** case5: available amount >= 0, after counting out fee considering left balance */
  const availableAmount = amount.minus(feeAmount.minus(leftBalance));
  if (availableAmount.gte(0)) return availableAmount.dp(asset.exponent, BigNumber.ROUND_DOWN);

  /** case6: no availabe amount, force callback and return the negative amount */
  onInsufficientBalance(feeCurrency);
  return availableAmount;
}

export function alertInsufficientBalanceForTx(feeCurrency: CurrencyDetail | CurrencyDetail[]) {
  const feeAmountString = Array.isArray(feeCurrency)
    ? feeCurrency.map((a) => `${a.feeAmount.dp(a.coinDecimals, BigNumber.ROUND_DOWN)} ${a.coinDenom}`).join(' or ')
    : `${feeCurrency.feeAmount.dp(feeCurrency.coinDecimals, BigNumber.ROUND_DOWN).toFormat()} ${feeCurrency.coinDenom}`;

  const feeCurrencyDenomString = Array.isArray(feeCurrency)
    ? feeCurrency.map((a) => a.coinDenom).join(' or ')
    : feeCurrency.coinDenom;

  alert(`Warning \n you have less than ${feeAmountString}, you need ${feeCurrencyDenomString} for tx fee.`);
}

export function removeComma(str: string): string {
  return str.replaceAll(',', '');
}

export function isNumberString(value: string, groupSeperator = ',') {
  const number = Number(value.replaceAll(groupSeperator, ''));
  const bigNumber = new BigNumber(removeComma(value));
  return value.length > 0 && !bigNumber.isNaN() && !Number.isNaN(number) && Number.isFinite(number);
}

/** @summary underlying assets amount by pool token balance; poolTokenBalance is non-exponentized */
export function getUnderlyingAssetsAmount(poolTokenBalance: BigNumber, pool: PoolDetail): [BigNumber, BigNumber] {
  const myMaxShare = poolTokenBalance.dividedBy(pool.totalSupplyAmount);
  const tokenXAmount = new BigNumber(pool.reserved[0].amount)
    .multipliedBy(myMaxShare)
    .shiftedBy(-pool.assets[0].exponent);
  const tokenYAmount = new BigNumber(pool.reserved[1].amount)
    .multipliedBy(myMaxShare)
    .shiftedBy(-pool.assets[1].exponent);

  return [tokenXAmount, tokenYAmount];
}

/** @summary implicit bCRE apr */
export function getUnderlyingBCreApr(pool: PoolDetail, tvl: BigNumber, bcreApr: string): BigNumber {
  if ([pool.assets[0].ticker, pool.assets[1].ticker].includes('bCRE')) {
    if (pool.poolType === 2) {
      const bcreReserve = pool.reserved.filter((r) => {
        return r.denom === 'ubcre';
      })?.[0];
      const bcreValue = new BigNumber(bcreReserve.amount)
        .multipliedBy(bcreReserve.priceOracle)
        .shiftedBy(-6)
        .toString();
      return new BigNumber(bcreApr).multipliedBy(new BigNumber(bcreValue).dividedBy(tvl));
      // .decimalPlaces(2)
      // .toNumber();
    } else {
      return new BigNumber(bcreApr).div(2);
    }
  } else {
    return new BigNumber(0);
  }
}

export function getTvlByPool(pool: PoolDetail): BigNumber {
  return pool.totalSupplyAmount.multipliedBy(pool.priceOracle);
}

/** @summary apr to apy converter; bignumber.js exponentiatedBy api too slow so replaced with Math api */
export function convertToApy(apr: BigNumber, compoundingHour: number): BigNumber {
  const periods = 365 * (24 / compoundingHour);
  // const apy = apr.div(100).div(periods).plus(1).exponentiatedBy(periods).minus(1).multipliedBy(100);
  const apy = (Math.pow(apr.div(100).div(periods).plus(1).toNumber(), periods) - 1) * 100;
  return new BigNumber(apy);
}

/** @summary make sure array mapped by distinct rewardDenom */
export type RewardInfo = {
  asset: InfoAsset | undefined;
  denom: string;
  totalAmount: number;
  plans: RewardPlan[];
};

export function getRewardsPerDistinctRewardToken(pool: PoolDetail): RewardInfo[] {
  const _rewards: Record<string, RewardInfo> = {};

  pool.RewardsPerToken?.forEach((item) => {
    if (_rewards[item.rewardDenom]) {
      _rewards[item.rewardDenom].plans.push(item);
      _rewards[item.rewardDenom].totalAmount += item.rewardAmount;
    } else {
      _rewards[item.rewardDenom] = {
        asset: store.assetsData.info[item.rewardDenom],
        denom: item.rewardDenom,
        totalAmount: item.rewardAmount,
        plans: [item],
      };
    }
  });

  return Object.values(_rewards);
}

/**
 * @desc returns rewards by distinct reward token; from pools
 * @todo Superior compatibility of getRewardsPerDistinctRewardToken; repace it?
 *
 */
export function getRewardsByRewardToken(pools: PoolDetail[]): RewardsByTokenMap {
  return pools.reduce<RewardsByTokenMap>((accm, _pool) => {
    _pool.RewardsPerToken.forEach((rewardsPerToken) => {
      const newItem = { ...rewardsPerToken, poolId: _pool.poolId };
      const prevArray = accm[rewardsPerToken.rewardDenom];
      accm[rewardsPerToken.rewardDenom] = prevArray?.concat([newItem]) ?? [newItem];
    });
    return accm;
  }, {});
}

export function getChangeSymbol(change: number): string {
  return change > 0 ? '+' : change < 0 ? '-' : '';
}

export function getForcedNumber(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export function getFractionDigitsLimitedNumber(
  str: string,
  maximumFractionDigits: number,
  groupSeperator = ','
): string | null {
  if (!isNumberString(str, groupSeperator)) return null;

  const value = str.replaceAll(groupSeperator, '');

  /** @summary handle scientific notation */
  if (value.includes('e')) {
    return new BigNumber(value).dp(maximumFractionDigits, BigNumber.ROUND_DOWN).toString();
  }

  /** @summary handle negative */
  if (new BigNumber(value).isNegative()) {
    return '0';
  }

  const fractionString = value.split('.')[1] ?? '';
  if (fractionString.length <= maximumFractionDigits) {
    return value;
  } else {
    return `${value.split('.')[0] ?? ''}.${fractionString.substring(0, maximumFractionDigits)}`;
  }
}

export type AssetSortBy = 'ticker' | 'balance' | 'nativeness' | 'all';
export const sortAssets = (assets: AssetDetail[], options?: { sortBy: AssetSortBy }): AssetDetail[] => {
  const sortBy: AssetSortBy = options?.sortBy ?? 'all';

  if (sortBy === 'ticker') {
    return assets.sort((a, b) => a.ticker.localeCompare(b.ticker));
  }

  if (sortBy === 'balance') {
    return assets.sort((a, b) => {
      const compared = a.availableBalance.shiftedBy(-a.exponent).comparedTo(b.availableBalance.shiftedBy(-b.exponent));
      return compared === 0 ? a.ticker.localeCompare(b.ticker) : -compared;
    });
  }

  if (sortBy === 'nativeness') {
    const nativeAssets = assets.filter((asset) => asset.tokenType === TokenTypes.NATIVE);
    const nonNativeAssets = assets.filter((asset) => asset.tokenType !== TokenTypes.NATIVE);

    const sortedNativeAssets = nativeAssets.sort((a, b) => a.ticker.localeCompare(b.ticker));
    const sortedNonNativeAssets = nonNativeAssets.sort((a, b) => a.ticker.localeCompare(b.ticker));

    return [...sortedNativeAssets, ...sortedNonNativeAssets];
  }

  const nativeAssets = assets.filter((asset) => asset.tokenType === TokenTypes.NATIVE);
  const nonNativeAssets = assets.filter((asset) => asset.tokenType !== TokenTypes.NATIVE);

  const sortedNativeAssets = nativeAssets.sort((a, b) => a.ticker.localeCompare(b.ticker));
  const sortedNonNativeAssets = nonNativeAssets.sort((a, b) => {
    const compared = a.availableBalance
      .shiftedBy(-a.exponent)
      .multipliedBy(a.priceOracle)
      .comparedTo(b.availableBalance.shiftedBy(-b.exponent).multipliedBy(b.priceOracle));
    return compared === 0 ? a.ticker.localeCompare(b.ticker) : -compared;
  });

  return [...sortedNativeAssets, ...sortedNonNativeAssets];
};

export function getPoolFeaturesScore(pool: PoolDetail): number {
  return pool.poolType + (pool.lfEnabled ? 1 : 0);
}

export function getTickerAnatomy(ticker: string): TickerAnatomy {
  const splits = ticker.split('.');
  return {
    ticker: splits[0] ?? ticker,
    bridgeFix: splits[1] ? `.${splits[1]}` : '',
  };
}

export function getTxExplorerUrl(chainId: string, isProduction: boolean) {
  const seperatorIndex = chainId.lastIndexOf('-');
  const chainName = chainId.substring(0, seperatorIndex).toLowerCase();
  return `https://${isProduction ? '' : 'testnet.'}mintscan.io/${chainName}${isProduction ? '' : '-testnet'}/txs`;
}

/**
 *
 * @todo how to identify other chainType is TBD
 */
export function getChainType(chainId: string): ChainTypes {
  if (chainId === INITIAL_ASSET.chainId) return ChainTypes.CRESCENT;
  if (chainId.includes('agoric')) return ChainTypes.AGORIC;
  if (chainId.includes('cosmos')) return ChainTypes.COSMOS;
  if (chainId.includes('columbus')) return ChainTypes.COLUMBUS;
  if (chainId.includes('phoenix')) return ChainTypes.PHOENIX;
  return ChainTypes.UNKNOWN;
}

export function getTxExplorerURL({ chainId, txHash }: { chainId: string; txHash: string }) {
  const chainType = getChainType(chainId);
  if (chainType !== ChainTypes.UNKNOWN) return `${TX_EXPLORER_URLS[chainType][IS_PRODUCTION ? 0 : 1]}/${txHash}`;

  const chainName: string | undefined = store.chainsData.info[chainId]?.displayName.toLowerCase();

  const hostDomain = `https://${IS_PRODUCTION ? '' : 'testnet.'}mintscan.io`;
  const path = `/${chainName}${IS_PRODUCTION ? '' : '-testnet'}/txs/${txHash}`;

  return `${hostDomain}${path}`;
}

type FormatTextCaseOptions = { capitalize?: 'first' | 'all' | 'none' };

export function formatTextCase(text: string, options?: FormatTextCaseOptions) {
  const capitalize = options?.capitalize ?? 'none';

  const upCaseIndexes: number[] =
    capitalize === 'none' ? [] : capitalize === 'first' ? [0] : [...Array(text.length).keys()];

  return text
    .split('')
    .map((char, index) => (upCaseIndexes.includes(index) ? char.toUpperCase() : char.toLowerCase()))
    .join('');
}

export function sanitizeNumber(num: number): number {
  if (Number.isNaN(num) || !Number.isFinite(num)) {
    return 0;
  }

  return num;
}

/** @description fetching data */
export const fetchCrescentBalance = ({ address, hexAddress }: { address: string; hexAddress: string }) =>
  axios
    .get(`${ROOT_ADDRESS}/acc/${address}/balance/all/connect/${hexAddress}`)
    .catch((e) => console.error(`ERROR: ${ROOT_ADDRESS}/acc/${address}/balance/all/connect/${hexAddress} / ${e}`));

export const fetchChainInfos = () =>
  axios.get<ResponseOne<InfoChain[]>>(`${ROOT_ADDRESS}/chain/info`).catch((e) => {
    console.error(`ERROR: get chain info`);
    return null;
  });

export const fetchCrescentChainInfo = async (): Promise<InfoChain | undefined> => {
  const res = await fetchChainInfos();
  const chainInfos = res?.data.data ?? [];
  const crescentChainInfo = chainInfos.find((info: InfoChain) => info.chainId === INITIAL_ASSET.chainId);
  return crescentChainInfo;
};
