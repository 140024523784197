import { HIGHLIGHT_POOLS, HightlightPool } from 'COMMON_VARIABLES';
import PoolHighlightCard from 'components/pools/PoolHighlightCard';
import Swiper from 'components/swiper/Swiper';
import { useMemo } from 'react';
import type { PoolDetail } from 'types/pool';

type HighlightPoolCardData = HightlightPool & { pool: PoolDetail };

/** @desc type guard */
function isHighlightPoolCardData(data: HighlightPoolCardData | undefined): data is HighlightPoolCardData {
  return data !== undefined;
}

const PoolHighlights = ({ pools, onPoolClick }: { pools: PoolDetail[]; onPoolClick?: (pool: PoolDetail) => void }) => {
  const highlightPools = useMemo<HighlightPoolCardData[]>(() => {
    return HIGHLIGHT_POOLS.map((hp) => {
      const targetPool = pools.find((pool) => pool.poolId === hp.poolId);
      if (targetPool === undefined) return undefined;

      return {
        ...hp,
        pool: targetPool,
      };
    }).filter(isHighlightPoolCardData);
  }, [pools]);

  const cards = useMemo<JSX.Element[]>(
    () =>
      highlightPools.map((item, index) => (
        <PoolHighlightCard
          key={item.poolId}
          pool={item.pool}
          // dummy badge setting
          badgeLabel={item.label}
          badgeColor={item.badgeColor}
          buttonLabel={item.buttonLabel}
          className="md:basis-[28%] md:grow md:shrink md:max-w-[342px]"
          onClick={() => onPoolClick?.(item.pool)}
        />
      )),
    [highlightPools, onPoolClick]
  );

  return (
    <div>
      {/* desktop */}
      <div className={`hidden md:flex flex-wrap items-stretch justify-between gap-8 w-full`}>{cards}</div>
      {/* mobile */}
      <div className="pl-5 pr-10 md:hidden">
        <Swiper className="!overflow-visible" slidesPerView={1} items={cards} />
      </div>
    </div>
  );
};

export default PoolHighlights;
