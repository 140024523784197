import msgEncoder from 'common/sign/msgEncoder';
import Long from 'long';
import createClaimMsg from './Claim';

const createVoteMsg = (txData: any, creAddress: any) => {
  const msg: any = {
    typeUrl: '/cosmos.gov.v1beta1.MsgVote',
    value: {
      option: Number(txData.option),
      proposalId: Long.fromString(String(txData.proposalId)),
      voter: creAddress,
    },
  };
  const msgs: any[] = [msg];
  return msgs;
};

export default createVoteMsg;
