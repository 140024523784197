import CoinPair from 'components/coins/CoinPair';
import PoolId from './PoolId';
import type { PoolDetail } from 'types/pool';
import PairTicker from './PairTicker';
import PoolFeatures from './PoolFeatures';

const PoolTitle = ({ pool }: { pool: PoolDetail }) => {
  return (
    <div className="flex items-center gap-x-3">
      <CoinPair assets={pool.assets} size="32px" />

      <div className="space-y-2">
        <div className="flex items-center gap-x-1">
          <PairTicker pair={pool} size="md" />
          <PoolId poolId={pool.poolId} hashTag={true} size="md" />
        </div>

        <PoolFeatures pool={pool} />
      </div>
    </div>
  );
};

export default PoolTitle;
