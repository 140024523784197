import Tippy from '@tippyjs/react';
import { followCursor as FollowCursor } from 'tippy.js/headless';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

/** @desc ref: https://atomiks.github.io/tippyjs/v6/themes/#styling-the-arrow */
import './tippy.css';
import TooltipInner from './TooltipInner';

type TipplyPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end';

export type TooltipSize = '220px' | '300px';
type TooltipTrigger = 'click';

const TOOLTIP_WIDTH_GETTER_DICT: { [key in TooltipSize]: (widthFixed: boolean) => string } = {
  '220px': (widthFixed) => (widthFixed ? 'md:!w-[220px]' : 'md:!max-w-[220px]'),
  '300px': (widthFixed) => (widthFixed ? 'md:!w-[300px]' : 'md:!max-w-[300px]'),
};

const TOOLTIP_COLOR = 'bg-tooltip_o24 backdrop-blur-50 text-on_surface rounded-8px';

function Tooltip({
  children,
  size = '220px',
  escapeSize = false,
  className = '',
  content,
  placement,
  followCursor,
  zIndex = 10,
  trigger,
  wordBreak = 'break-keep',
}: {
  children: React.ReactNode;
  size?: TooltipSize;
  escapeSize?: boolean;
  className?: string;
  content?: React.ReactNode;
  placement?: TipplyPlacement;
  followCursor?: boolean | 'vertical';
  zIndex?: number;
  trigger?: TooltipTrigger;
  wordBreak?: 'break-all' | 'break-keep';
}) {
  const isString = typeof content === 'string';

  const contentWrap = content ? isString ? <TooltipInner>{content}</TooltipInner> : content : undefined;

  return content !== undefined ? (
    <Tippy
      plugins={[FollowCursor]}
      // tippy.css uses this theme definition
      theme="custom"
      arrow={true}
      // hideOnClick={isMobile()}
      placement={placement}
      trigger={trigger}
      followCursor={followCursor}
      hideOnClick={true}
      content={contentWrap}
      zIndex={zIndex ?? 0}
      className={`tippy-default whitespace-pre-wrap p-0 font_body_s ${wordBreak} ${TOOLTIP_WIDTH_GETTER_DICT[size](
        !isString && !escapeSize
      )} ${TOOLTIP_COLOR}`}
    >
      <div className={`${content !== undefined ? 'cursor-pointer' : ''} ${className}`}>{children}</div>
    </Tippy>
  ) : (
    <>{children}</>
  );
}

export default Tooltip;
