import { useState, useMemo, useEffect } from 'react';
import { INFOBOX_TEXTS, IS_LF_ENABLED } from 'COMMON_VARIABLES';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { PoolDetail } from 'types/pool';
import ToggleButton from 'components/buttons/ToggleButton';
import useFarm from './hooks/useFarm';
import { formatAmount, formatUSD } from 'common/utils';
import ExternalLink from 'components/texts/ExternalLink';
import useLfAuctionDuration from 'hooks/farm/useLfAuctionDuration';
import TextedRanger from 'components/ranger/TextedRanger';
import LabeledData from 'components/texts/LabeledData';
import NumberText from 'components/texts/NumberText';
import Divider from 'components/divider/Divider';
import CoinPair from 'components/coins/CoinPair';
import InfoBox from 'components/infobox';
import Button from 'components/buttons/Button';
import useFarmButton from './hooks/useFarmButton';

const Farm = ({ pool, isLfByDefault, close }: { pool: PoolDetail; isLfByDefault: boolean; close: () => void }) => {
  const [rate, setRate] = useState(0);

  /** @summary state ramifies according to isLf */
  const {
    isLf,
    setIsLf,
    balance,
    amount,
    minAmount,
    amountUSD,
    lfAmount,
    lfMintAmountPerPoolToken,
    lfApr,
    state,
    isLoading,
  } = useFarm({ pool, rate, isLfByDefault, onTxSuccess: () => setRate(0) });

  const rangerEnabled = useMemo(() => balance.gt(0), [balance]);

  useEffect(() => setRate(rangerEnabled ? 100 : 0), [isLf, rangerEnabled]);

  /** @summary auction duration (apy unit) */
  const { getLfApy, getLfApyDescription } = useLfAuctionDuration('hour');

  /** @desc new */
  const button = useFarmButton({
    isLoading,
    activeLabel: isLf ? 'Liquid farm' : 'Farm',
    amount,
    minAmount: isLf ? minAmount : undefined,
    resultAmount: isLf ? lfAmount : undefined,
    balance,
  });

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-3 md:space-y-6">
          {IS_LF_ENABLED && pool.lfEnabled && (
            <div className="flex items-center space-x-2">
              <ToggleButton size="sm" color="liquid" toggle={() => setIsLf(!isLf)} isEnabled={isLf} />
              <div className="font_caption_s text-on_surface_variant_dark">Liquid Farming(LF)</div>
            </div>
          )}

          <TextedRanger
            color={isLf ? 'liquid' : 'primary'}
            rate={rate}
            unit="%"
            captionNumber={formatUSD(amountUSD)}
            onChange={setRate}
            disabled={!rangerEnabled}
          />

          <div className="space-y-3 md:space-y-4">
            <LabeledData
              label="You have"
              value={
                <NumberText
                  size="sm"
                  value={formatAmount(new BigNumber(balance).shiftedBy(-12), 12)}
                  unit="Pool Token"
                />
              }
            />

            {(balance.gt(0) || isLf) && (
              <div className="space-y-3 md:space-y-4">
                <Divider type="hard" />

                {balance.gt(0) && !isLf && (
                  <LabeledData
                    label="You will farm"
                    value={
                      <NumberText
                        size="sm"
                        color="primary"
                        value={formatAmount(amount.shiftedBy(-12), 12)}
                        unit="Pool Token"
                      />
                    }
                  />
                )}

                {isLf && (
                  <>
                    {balance.gt(0) && (
                      <LabeledData
                        label="You will receive"
                        labelTooltipContent={`Return rate \n1 Pool Token : ${formatAmount(
                          lfMintAmountPerPoolToken,
                          12
                        )} LF Token`}
                        value={
                          <div className="flex items-center gap-x-2">
                            <CoinPair assets={pool.assets} size="24px" />
                            <NumberText size="sm" color="liquid" value={formatAmount(lfAmount, 12)} unit="LF Token" />
                          </div>
                        }
                      />
                    )}

                    <LabeledData
                      label="APY"
                      labelTooltipContent={getLfApyDescription(lfApr)}
                      value={<NumberText size="sm" color="liquid" value={`${formatAmount(getLfApy(lfApr), 1)}%`} />}
                    />
                  </>
                )}
              </div>
            )}
          </div>

          {isLf && (
            <InfoBox>
              {INFOBOX_TEXTS.LF}
              <ExternalLink
                href=""
                text="Go to docs"
                disabled
                className="ml-1 translate-y-[1px]"
                disabledAlert="Not yet available, stay tuned for our post soon!"
              />
            </InfoBox>
          )}
        </div>

        <Button
          size="lg"
          color={isLf ? 'liquid' : 'plain'}
          label={button.text}
          status={button.status}
          onClick={state.onAction}
          className="w-full"
        />
      </div>
    </>
  );
};

export default observer(Farm);
