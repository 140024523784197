import Icon from 'components/icon';
import RedDot from 'components/pings/RedDot';
import Tooltip from 'components/tooltips';

type ViewAllButtonProps = {
  count?: number;
  onClick?: () => void;
  openLabel?: string;
  closeLabel?: string;
  isOpen?: boolean;
  ping?: boolean;
  disabled?: boolean;
  className?: string;
};

const ViewAllButton = ({
  count,
  onClick,
  openLabel = 'View All',
  closeLabel = 'Close All',
  isOpen = false,
  ping = false,
  disabled = false,
  className = '',
}: ViewAllButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`relative bg-transparent text-on_surface_variant_light font_label_s flex flex-col items-center gap-y-0.5 ${
        disabled ? 'cursor-not-allowed opacity-40' : 'hover:opacity-80'
      } ${className}`}
      onClick={onClick}
    >
      <div>
        {isOpen ? closeLabel : openLabel}{' '}
        {!isOpen && count !== undefined && <span className="font_label_number_s">({count})</span>}
      </div>

      <Icon type={isOpen ? 'expandless' : 'expandmore'} size="24px" />

      {ping && !isOpen && <RedDot size="sm" className={`absolute ${'-top-1.5 -right-2.5'}`} />}
    </button>
  );
};

const TooltipWrapper = ({
  tooltipContent,
  ...args
}: { tooltipContent?: JSX.Element | string } & ViewAllButtonProps) => {
  return (
    <Tooltip content={tooltipContent}>
      <ViewAllButton {...args} />
    </Tooltip>
  );
};

export default TooltipWrapper;
