import BigNumber from 'bignumber.js';
import { getRewardsByRewardToken, getTvlByPool, sortAssets } from 'common/utils';
import { TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import Table from 'components/table/Table';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { InfoAsset } from 'types/asset';
import type { MyPoolDetail, PoolDetail, RewardsByTokenMap } from 'types/pool';
import { TableStyles } from 'components/table/styles';
import PoolsTable from 'components/pools/PoolsTable';
import CoinList from 'components/coins/CoinList';
import { isMyPoolDetail } from './utils';
import type { TableField } from 'components/table/types';
import useClaimableRewardInfo from 'hooks/farm/useClaimableRewardInfo';
import PairTitle from './PairTitle';
import AprText from 'components/texts/AprText';

type TablePairRow = {
  pairId: number;
  assets: [InfoAsset, InfoAsset];
  pairTitle: JSX.Element;
  minApr: number;
  maxApr: number;
  apr: number;
  aprSet: JSX.Element;
  rewardsByTokenMap: RewardsByTokenMap;
  rewards: JSX.Element;
  tvl: BigNumber;
  subJsx: JSX.Element;
  /** my pool */
  balance: BigNumber | undefined;
  myRewards: BigNumber | undefined;
  ping: boolean | undefined;
  isSubJsxOpen: boolean;
};

type PairsTableField = TableField<TablePairRow>;

type PairsTableProps = {
  pools: PoolDetail[] | MyPoolDetail[];
  onPoolClick?: (pool: PoolDetail) => void;
  /** it determines if localStorage flow will be used */
  localStorageKeyPrefix?: string;
};

const PairsTable = ({ pools, onPoolClick, localStorageKeyPrefix }: PairsTableProps) => {
  const isMyPools = useMemo<boolean>(() => pools.some(isMyPoolDetail), [pools]);
  const dSortValueFallback = useMemo<string>(() => (isMyPools ? 'balance' : 'tvl'), [isMyPools]);

  /** @summary sort sub table rows by parent sorting */
  const [subTableDIsAsc, setSubTableDIsAsc] = useState<boolean>(false);
  const [subTableDSortValue, setSubTableDSortValue] = useState<string>(dSortValueFallback);

  const onSort = useCallback((isAsc: boolean, sortValue: string) => {
    setSubTableDIsAsc(isAsc);
    setSubTableDSortValue(sortValue);
  }, []);

  /** @desc pairs */
  const store = useMainStore();
  const pairs = store.getPairs();

  const { getRewardInfo } = useClaimableRewardInfo();

  /** @desc isSubJsxOpen */
  const storedClosedPairIds = useMemo<Set<number> | null>(() => {
    const localStorageRawData = localStorage.getItem(`${localStorageKeyPrefix}-subjsx-closed-pair-ids`);
    return localStorageRawData ? new Set([...JSON.parse(localStorageRawData)]) : null;
  }, [localStorageKeyPrefix]);

  const allPairIds = useMemo<Set<number>>(() => new Set([...pairs.map((pair) => pair.pairId)]), [pairs]);
  const [closedPairIds, setClosedPairIds] = useState<Set<number>>(storedClosedPairIds ?? allPairIds);
  useEffect(() => {
    setClosedPairIds(storedClosedPairIds ?? allPairIds);
  }, [store.pairsData.isInit]);

  const onToggleRowSubJsx = useCallback(
    (row: TablePairRow, isOpen: boolean) => {
      const newIds = new Set([...closedPairIds]);
      isOpen ? newIds.delete(row.pairId) : newIds.add(row.pairId);

      setClosedPairIds(newIds);

      /** localStorage */
      if (localStorageKeyPrefix) {
        localStorage.setItem(`${localStorageKeyPrefix}-subjsx-closed-pair-ids`, JSON.stringify([...newIds]));
      }
    },
    [closedPairIds, localStorageKeyPrefix]
  );

  const onToggleAllSubJsx = useCallback(
    (isOpen: boolean) => {
      const newIds: Set<number> = isOpen ? new Set() : allPairIds;
      setClosedPairIds(newIds);

      /** localStorage */
      if (localStorageKeyPrefix) {
        localStorage.setItem(`${localStorageKeyPrefix}-subjsx-closed-pair-ids`, JSON.stringify([...newIds]));
      }
    },
    [allPairIds, localStorageKeyPrefix]
  );

  /** @desc table rows */
  const tablePairRows = useMemo<TablePairRow[]>(() => {
    return pairs
      .map((pair) => {
        const _pools = pools.filter((pool) => pool.pairId === pair.pairId);

        if (_pools.length === 0) return undefined;

        /** token pair */
        const assets = pair.assets;
        const pairTitle = <PairTitle pair={pair} />;

        /** apr */
        const aprSortedPools = _pools.filter((pool) => pool.apr > 0).sort((a, b) => a.apr - b.apr);
        const minApr = aprSortedPools[0]?.apr ?? 0;
        const maxApr = aprSortedPools[aprSortedPools.length - 1]?.apr ?? 0;
        const hasBCre = assets.some((asset) => asset.denom === 'ubcre');
        const aprSet = (
          <AprText
            apr={new BigNumber(maxApr)}
            fromApr={new BigNumber(minApr)}
            aprAdditionalCaption={hasBCre ? 'bCRE APR' : undefined}
          />
        );

        /** rewards */
        const rewardsByTokenMap = getRewardsByRewardToken(_pools);
        const rewardsAssets = Object.keys(rewardsByTokenMap)
          .map((denom) => store.assetDetailDict[denom])
          .filter((asset) => asset !== undefined);
        const rewards = (
          <CoinList
            size="24px"
            assets={sortAssets(rewardsAssets, { sortBy: 'ticker' })}
            maxCnt={2}
            showHiddenCnt={true}
          />
        );

        /** tvl */
        const tvl = _pools.reduce<BigNumber>((accm, _pool) => accm.plus(getTvlByPool(_pool)), new BigNumber(0));

        /** sub table - pools */
        const subJsx = (
          <div className="px-2 pb-2 md:p-0">
            <PoolsTable
              pools={_pools}
              type={TableStyles.SECONDARY}
              dIsAsc={subTableDIsAsc}
              dSortValue={subTableDSortValue}
              onPoolClick={onPoolClick}
            />
          </div>
        );

        const myPools = isMyPools ? _pools.filter(isMyPoolDetail) : undefined;

        /** balance */
        const balance = myPools?.reduce<BigNumber>(
          (accm, _pool) => accm.plus(_pool.myTotalBalanceUsd),
          new BigNumber(0)
        );

        /** rewards */
        const myRewards = myPools?.reduce<BigNumber>(
          (accm, _pool) => accm.plus(getRewardInfo(_pool.myStaking).totalRewardUsd),
          new BigNumber(0)
        );

        const ping = myPools?.some((pool) => pool.myBalance.gt(0));

        const isSubJsxOpen = !closedPairIds.has(pair.pairId);

        return {
          pairId: pair.pairId,
          assets,
          pairTitle,
          minApr,
          maxApr,
          aprSet,
          apr: maxApr,
          rewardsByTokenMap,
          rewards,
          tvl,
          subJsx,
          balance,
          myRewards,
          ping,
          isSubJsxOpen,
        };
      })
      .filter(isPairTableRow);
  }, [
    pools,
    pairs,
    subTableDIsAsc,
    subTableDSortValue,
    isMyPools,
    store.assetDetailDict,
    closedPairIds,
    getRewardInfo,
    onPoolClick,
  ]);

  const fieldsByMyOrAll = useMemo<PairsTableField[]>(() => {
    const myPools: PairsTableField[] = [
      {
        label: 'Balance',
        value: 'balance',
        type: 'usd',
        sortType: 'bignumber',
        widthRatio: 20,
        align: 'right',
      },
      {
        label: 'Rewards',
        value: 'myRewards',
        type: 'usd',
        sortType: 'bignumber',
        widthRatio: 13.5,
        align: 'right',
      },
    ];

    const allPools: PairsTableField[] = [
      {
        label: 'Rewards',
        value: 'rewards',
        type: 'jsx',
        sortDisabled: true,
        widthRatio: 13.5,
        align: 'right',
      },
      {
        label: 'TVL',
        value: 'tvl',
        type: 'usd',
        sortType: 'bignumber',
        compact: true,
        widthRatio: 20,
        align: 'right',
      },
    ];

    return isMyPools ? myPools : allPools;
  }, [isMyPools]);

  return (
    <Table<TablePairRow>
      isLoading={false}
      hoverArea="exclude-sub"
      rows={tablePairRows}
      dSortValue={dSortValueFallback}
      dIsAsc={false}
      onSort={onSort}
      onToggleRowSubJsx={onToggleRowSubJsx}
      onToggleAllSubJsx={onToggleAllSubJsx}
      fields={[
        {
          label: 'Pair',
          value: 'pairTitle',
          sortDisabled: true,
          type: 'jsx',
        },
        {
          label: 'APR',
          value: 'aprSet',
          type: 'jsx',
          sortValue: 'apr',
          sortType: 'number',
          tooltipContent: TOOLTIP_TEXTS.APR,
          widthRatio: 24,
          align: 'right',
        },
        ...fieldsByMyOrAll,
      ]}
    />
  );
};

export default PairsTable;

/** @desc type guard */
function isPairTableRow(pair: TablePairRow | undefined): pair is TablePairRow {
  return pair !== undefined;
}
