import { useCallback } from 'react';

/**
 * @todo cannot import tailwind.config because the path is out of src; need to find other ways
 * https://tailwindcss.com/docs/configuration#referencing-in-java-script
 */
const BREAK_POINT_MD = 768;

const useTailwind = () => {
  const getIsMobile = useCallback(() => {
    const matchMedia = window.matchMedia(`(min-width: ${BREAK_POINT_MD}px)`);
    return !matchMedia.matches;
  }, []);

  return { getIsMobile };
};

export default useTailwind;
