import OrderbookSet from 'components/orderbook/index';
import { Observer } from 'mobx-react-lite';
import useNavigateToMobileTrade from './useNavigateToMobileTrade';

function Orderbook() {
  useNavigateToMobileTrade();

  return (
    <Observer>
      {() => (
        <main role="main" className="relative w-full pb-20 md:pb-0 m-auto">
          <OrderbookSet />
        </main>
      )}
    </Observer>
  );
}

export default Orderbook;
