import React, { useEffect, useMemo, useState } from 'react';
import { isAndroid as _isAndriod, saveMobileLinkInfo } from '@walletconnect/browser-utils';

import ReactDom from 'react-dom';

const MODAL_ID = 'cosmostation-qrcode-modal-v1';

function open(uri, cb) {
  const wrapper = document.createElement('div');
  wrapper.setAttribute('id', MODAL_ID);
  document.body.appendChild(wrapper);

  ReactDom.render(<MobileAutoConnectModal uri={uri} />, wrapper);
}

function close() {
  const wrapper = document.getElementById(MODAL_ID);
  if (wrapper) {
    document.body.removeChild(wrapper);
  }
}

function MobileAutoConnectModal({ uri }) {
  const [isAndroid] = useState(() => _isAndriod());
  const navigateToAppURL = useMemo(() => {
    if (isAndroid) {
      saveMobileLinkInfo({
        name: 'Cosmostation',
        href: 'intent://wc#Intent;package=wannabit.io.cosmostaion;scheme=cosmostation;end;',
      });
      return `intent://wc?${uri}#Intent;package=wannabit.io.cosmostaion;scheme=cosmostation;end;`;
    } else {
      saveMobileLinkInfo({
        name: 'Cosmostation',
        href: 'cosmostation://wc',
      });
      return `cosmostation://wc?${uri}`;
    }
  }, [isAndroid, uri]);

  useEffect(() => {
    if (navigateToAppURL) {
      window.location.href = navigateToAppURL;
    }
  }, [navigateToAppURL]);

  return null;
}

export default { open, close };
