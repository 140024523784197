import IconButton from 'components/buttons/IconButton';

type ModalHeaderColor = 'plain' | 'surface';

const MODAL_HEADER_BG_DICT: { [key in ModalHeaderColor]: string } = {
  plain: 'bg-transparent',
  surface: 'bg_surface_4 border-b border-outline_soft',
};

type ModalHeaderProps = {
  children?: React.ReactNode;
  title: string | JSX.Element;
  color?: ModalHeaderColor;
  onCloseClick?: () => void;
  onBackClick?: () => void;
};

const ModalHeaderContent = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
  <div className={className}>{children}</div>
);

const ModalHeader = ({ children, title, color = 'plain', onCloseClick, onBackClick }: ModalHeaderProps) => {
  return (
    <header className={`${MODAL_HEADER_BG_DICT[color]} p-4 md:p-5`}>
      <div className="flex justify-between items-start gap-x-4">
        {onBackClick && (
          <IconButton
            iconType="arrow-back-ios"
            size="md"
            className="text-on_surface_variant_light absolute left-4 md:left-5"
            onClick={onBackClick}
          />
        )}

        <div className={` ${onBackClick ? 'grow shrink text-center' : ''}`}>
          <h5 className="!leading-8 font_headline_s_mobile md:font_headline_s text-on_surface">{title}</h5>
        </div>

        {onCloseClick && (
          <IconButton iconType="close" size="lg" className="text-on_surface_variant_light" onClick={onCloseClick} />
        )}
      </div>

      <ModalHeaderContent>{children}</ModalHeaderContent>
    </header>
  );
};

ModalHeader.Content = ModalHeaderContent;

export default ModalHeader;
