import SwapWidget from 'components/widgets/SwapWidget';
import { Observer } from 'mobx-react-lite';
import useNavigateToMobileTrade from './useNavigateToMobileTrade';

const Swap = () => {
  useNavigateToMobileTrade();

  return (
    <Observer>
      {() => (
        <>
          <main role="main" className="relative w-full max-w-content m-auto md:pt-[3.75rem] px-4 md:px-0">
            <div className="flex flex-col items-center">
              <SwapWidget />
            </div>
          </main>
        </>
      )}
    </Observer>
  );
};

export default Swap;
