const FarmIconSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72528 4.40828L14.0001 2.7002H22.0001V6.32088C22.0001 6.51451 21.8194 6.65742 21.6309 6.61281L19.888 6.2002L18.2973 6.97928C17.7668 7.23912 17.2143 7.45144 16.6463 7.61375L13.6587 8.46748C13.1397 8.65637 12.5659 8.38879 12.377 7.86981C12.1881 7.35083 12.4557 6.77699 12.9747 6.58809L14.0404 6.2002L8.00006 6.36981C7.44778 6.36981 7 5.92209 7 5.36981C7 4.92333 7.29598 4.53094 7.72528 4.40828Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.06701 20.112H2V18.312H5.06701C6.51867 18.312 7.92977 18.791 9.08142 19.6748C10.8029 20.9959 13.1971 20.9959 14.9186 19.6748C16.0702 18.791 17.4813 18.312 18.933 18.312H22V20.112H18.933C17.8776 20.112 16.8517 20.4603 16.0144 21.1028C13.6466 22.9199 10.3534 22.9199 7.98559 21.1028C7.14831 20.4603 6.12241 20.112 5.06701 20.112Z"
        fill="currentColor"
      />
      <path
        d="M11.2059 12.0001C11.8686 12.0001 12.4059 11.4628 12.4059 10.8001C12.4059 10.1374 11.8686 9.6001 11.2059 9.6001C10.5431 9.6001 10.0059 10.1374 10.0059 10.8001C10.0059 11.4628 10.5431 12.0001 11.2059 12.0001Z"
        fill="currentColor"
      />
      <path
        d="M14.2059 15.3458C14.8686 15.3458 15.4059 14.8085 15.4059 14.1458C15.4059 13.4831 14.8686 12.9458 14.2059 12.9458C13.5431 12.9458 13.0059 13.4831 13.0059 14.1458C13.0059 14.8085 13.5431 15.3458 14.2059 15.3458Z"
        fill="currentColor"
      />
      <path
        d="M11.2059 18.69C11.8686 18.69 12.4059 18.1528 12.4059 17.49C12.4059 16.8273 11.8686 16.29 11.2059 16.29C10.5431 16.29 10.0059 16.8273 10.0059 17.49C10.0059 18.1528 10.5431 18.69 11.2059 18.69Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FarmIconSvg;
