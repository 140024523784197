import { useState, useMemo, useCallback } from 'react';
import { useMainStore } from 'provider/mainContext';
import useTxClient from 'hooks/useTxClient';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import { PoolDetail } from 'types/pool';
import { useLpFarmStaking } from 'hooks/useAPI';
import { LpFarmRewardRaw } from 'types/farm';
import { formatAmount, formatUSD } from 'common/utils';
import { HarvestTxData } from 'common/msg/LpHarvest';
import NumberText from 'components/texts/NumberText';
import Button, { ButtonStatus } from 'components/buttons/Button';

type LpFarmRewardInfo = {
  ticker: string;
  exponent: number;
  rewardAmount: BigNumber;
  rewardUsd: BigNumber;
};

const Claim = ({ pool }: { pool: PoolDetail }) => {
  const mainStore = useMainStore();
  const { signAndBroadcast } = useTxClient();
  const [isLoading, setLoading] = useState(false);
  // const { data: farmStakingResponse } = useFarmStaking(mainStore.wallet.address, 5000);
  const { data: farmStakingResponse } = useLpFarmStaking(mainStore.wallet.address, 5000);

  const rewards = useMemo<LpFarmRewardInfo[]>(() => {
    if ((farmStakingResponse?.data ?? []).length > 0) {
      let farmingPool = farmStakingResponse?.data?.filter((item) => item.denom === pool.poolDenom)[0];
      if (farmingPool) {
        const totalRewards = [...farmingPool.harvestable];
        //@ts-ignore
        const _unified_temp: LpFarmRewardRaw[] = [];
        (JSON.parse(JSON.stringify(totalRewards)) as LpFarmRewardRaw[]).forEach((r) => {
          const existReward = _unified_temp.filter((t) => t?.rewardDenom === r.rewardDenom);
          if (existReward.length > 0) {
            existReward[0].rewardAmount += Number(r.rewardAmount);
          } else {
            _unified_temp.push(r);
          }
        });
        const _rewards: LpFarmRewardInfo[] = _unified_temp
          .filter(
            (r) =>
              r.rewardAmount > 0 && mainStore.assetsData.live[r.rewardDenom] && mainStore.assetsData.info[r.rewardDenom]
          )
          .map((harvest) => {
            // if (mainStore.assetsData.live[harvest.rewardDenom] && mainStore.assetsData.info[harvest.rewardDenom]) {
            let assetInfo = mainStore.assetsData.info[harvest.rewardDenom];

            const rewardAmount = new BigNumber(harvest.rewardAmount).dividedBy(10 ** assetInfo.exponent);
            const rewardUsd = rewardAmount.multipliedBy(mainStore.assetsData.live[harvest.rewardDenom].priceOracle);
            return {
              ticker: assetInfo.ticker,
              exponent: assetInfo.exponent,
              rewardAmount,
              rewardUsd,
            };
            // }
            // return null;
          })
          .filter((item) => item);
        return _rewards;
      }
    }
    return [];
  }, [farmStakingResponse, mainStore.assetsData]);

  const totalRewardUsd = useMemo(() => {
    return rewards.reduce((acc, cur) => {
      return acc.plus(cur ? cur.rewardUsd : 0);
    }, new BigNumber(0));
  }, [rewards]);

  const claim = useCallback(async () => {
    setLoading(true);

    const txData: HarvestTxData = {
      denom: pool.poolDenom,
    };

    const error = await signAndBroadcast({
      type: 'harvest',
      txData: [txData],
      chainData: mainStore.chainsData,
    });

    setLoading(false);
  }, [mainStore.chainsData, pool.poolDenom, signAndBroadcast]);

  /** @desc new */
  const button = useMemo<{
    status: ButtonStatus;
    text: string;
  }>(() => {
    if (isLoading) {
      return {
        status: 'loading',
        text: '',
      };
    } else {
      if (totalRewardUsd.gt(0)) {
        return {
          status: 'enabled',
          text: 'Claim',
        };
      } else {
        return {
          status: 'disabled',
          text: 'No rewards',
        };
      }
    }
  }, [isLoading, rewards]);

  return (
    <>
      <div className="space-y-6">
        <div className="flex flex-col items-center gap-y-1">
          <div className="font_headline_s text-on_surface">Total Rewards</div>
          <div className="font_caption_number_l text-on_surface_variant_dark">({formatUSD(totalRewardUsd)})</div>
        </div>

        <div className="space-y-3">
          {rewards.map((item, index) => (
            <div key={index} className="flex items-center justify-between gap-x-2">
              <NumberText size="sm" value={formatAmount(item.rewardAmount, item.exponent)} unit={item.ticker} />

              <div className="font_caption_number_m text-on_surface_variant_dark">({formatUSD(item.rewardUsd)})</div>
            </div>
          ))}
        </div>

        <Button label={button.text} status={button.status} onClick={claim} className="w-full" />
      </div>
    </>
  );
};

export default observer(Claim);
