import { sortAssets } from 'common/utils';
import CoinList from 'components/coins/CoinList';
import type { CoinSize } from 'components/coins/constans';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { InfoAsset } from 'types/asset';
import type { PoolDetail } from 'types/pool';

const PoolRewards = ({ pool, size }: { pool: PoolDetail; size?: CoinSize }) => {
  const store = useMainStore();

  const rewardsAssets = useMemo<InfoAsset[]>(() => {
    const assets = pool.RewardsPerToken.map((rpt) => rpt.rewardDenom)
      .map((denom) => store.assetDetailDict[denom])
      .filter((asset) => asset !== undefined);

    return sortAssets(assets, { sortBy: 'ticker' });
  }, [pool, store.assetDetailDict]);

  const isRewardsValid = useMemo<boolean>(
    () => rewardsAssets.length > 0 && pool.apr > 0,
    [rewardsAssets.length, pool.apr]
  );

  return <>{isRewardsValid && <CoinList assets={rewardsAssets} maxCnt={2} showHiddenCnt={true} size={size} />}</>;
};

export default PoolRewards;
