import { EncodeObject } from '@cosmjs/proto-signing';
import { MsgDeposit } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';
import createClaimMsg from './Claim';

const createDepositMsg = (txData: any, creAddress: any) => {
  const poolDepositData: MsgDeposit = {
    depositor: creAddress,
    poolId: txData.poolId,
    depositCoins: txData.depositCoins
      .sort(function (a, b) {
        return a.denom < b.denom ? -1 : a.denom > b.denom ? 1 : 0;
      })
      .filter((coin) => {
        return Number(coin.amount) > 0;
      }),
  };
  const msg = msgEncoder.msgDeposit(poolDepositData);
  const msgs: EncodeObject[] = [msg];

  return msgs;
};

export default createDepositMsg;
