import IconButton from 'components/buttons/IconButton';
import Icon, { IconType } from 'components/icon';

type InputFieldType = 'text' | 'number' | 'amount';
export type PlaceholderType = 'text' | 'number';

const INPUT_FIELD_VARIANT_DICT: {
  [key in InputFieldType]: {
    padding: string;
    inputMode: React.HTMLAttributes<HTMLInputElement>['inputMode'];
    pattern: string | undefined;
    font: string;
    placeholderFont: {
      [key in PlaceholderType]: string;
    };
    align: string;
  };
} = {
  text: {
    padding: 'px-3 py-3 md:px-2 md:py-2',
    inputMode: undefined,
    pattern: undefined,
    font: 'font_caption_l',
    placeholderFont: {
      text: 'placeholder:font_caption_l placeholder:text-surface_variant',
      number: 'placeholder:font_caption_number_l placeholder:text-surface_variant',
    },
    align: 'text-left',
  },
  number: {
    padding: 'px-2 py-3 max-h-[2.875rem] md:max-h-[none] md:py-2',
    inputMode: 'decimal',
    pattern: '^[0-9]*[.,]?[0-9]*$',
    font: 'font_data_number_m md:font_data_number_s ',
    placeholderFont: {
      text: 'placeholder:font_caption_s placeholder:text-on_surface placeholder:opacity-40 md:placeholder:font_caption_s',
      number:
        'placeholder:font_data_number_m placeholder:text-on_surface placeholder:opacity-40 md:placeholder:font_data_number_s',
    },
    align: 'text-right',
  },
  amount: {
    padding: 'p-3',
    inputMode: 'decimal',
    pattern: '^[0-9]*[.,]?[0-9]*$',
    font: 'font_data_number_xl',
    placeholderFont: {
      text: 'placeholder:font_caption_m placeholder:text-on_surface placeholder:opacity-40',
      number: 'placeholder:font_data_number_xl placeholder:text-on_surface placeholder:opacity-40',
    },
    align: 'text-left',
  },
};

/**
 * @caution we use Big numbers like which have 18 digit decimal,
 * so input value must be as string for safety
 *
 * */
type InputFieldProps = {
  type: InputFieldType;
  value?: string;
  onChangeValue?: (value: string) => void;
  placeholder?: string;
  placeholderType?: PlaceholderType;
  step?: number;
  max?: string;
  isError?: boolean;
  disabled?: boolean;
  leadingIcon?: IconType;
  resetEnabled?: boolean;
  left?: React.ReactNode;
  right?: React.ReactNode;
  bottom?: React.ReactNode;
  className?: string;
};

const InputField = ({
  type,
  value = '',
  onChangeValue,
  placeholder = '',
  placeholderType = 'text',
  step,
  max,
  isError,
  disabled,
  leadingIcon,
  resetEnabled = false,
  left,
  right,
  bottom,
  className = '',
}: InputFieldProps) => {
  return (
    <div className={className}>
      <div
        className={`w-full flex items-center bg-surface_variant_o24 rounded-4px ${
          INPUT_FIELD_VARIANT_DICT[type].padding
        } border ${isError ? 'border-semantic_red' : 'border-outline_soft focus-within:border-outline_hard'} ${
          disabled ? 'opacity-40' : ''
        }`}
      >
        <div className="w-full space-y-1">
          <div className="w-full flex justify-between items-center gap-x-2">
            {left && <div className="grow-0 shrink-0 max-w-[70%] overflow-hidden">{left}</div>}

            <div className="grow shrink flex items-center gap-x-2">
              {leadingIcon && <Icon type={leadingIcon} size="20px" className="text-surface_variant" />}

              <input
                type={type === 'amount' ? 'number' : type}
                disabled={disabled}
                inputMode={INPUT_FIELD_VARIANT_DICT[type].inputMode}
                min="0"
                pattern={INPUT_FIELD_VARIANT_DICT[type].pattern}
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
                value={value}
                step={step}
                max={max}
                onChange={(e) => onChangeValue?.(e.target.value)}
                placeholder={placeholder}
                className={`w-full spin-button-none focus:outline-none bg-transparent text-on_surface ${
                  INPUT_FIELD_VARIANT_DICT[type].font
                } ${INPUT_FIELD_VARIANT_DICT[type].placeholderFont[placeholderType]} ${
                  left && right ? 'text-center' : INPUT_FIELD_VARIANT_DICT[type].align
                }`}
              />

              {resetEnabled && (
                <IconButton
                  iconType="cancel"
                  size="xs"
                  onClick={() => onChangeValue?.('')}
                  className={`transition-all ${value.length > 0 ? '' : 'opacity-0'}`}
                />
              )}
            </div>

            {right && <div className="grow-0 shrink-0 max-w-[70%] overflow-hidden">{right}</div>}
          </div>

          {bottom}
        </div>
      </div>
    </div>
  );
};

export default InputField;
