import { useLocation, useNavigate } from 'react-router-dom';
import TabSelector from 'components/tabs/TabSelector';
import IconButton from 'components/buttons/IconButton';
import { useCallback } from 'react';

export default function TradeTypeSelector({
  className = '',
  selectedIndex,
  onChangeIndex,
}: {
  className?: string;
  selectedIndex: 0 | 1;
  onChangeIndex?: (index: 0 | 1) => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToIntro = useCallback(() => {
    navigate(`/trade${location.search}`);
  }, [location.search]);

  const onChange = (index: number) => {
    if (index === 0 || index === 1) {
      onChangeIndex?.(index);
    }
  };

  return (
    <div className={`w-full ${className}`}>
      <TabSelector
        size="sm"
        type="segmented"
        tabsMaxWidth="180px"
        onChangeTab={onChange}
        fallbackIndex={selectedIndex}
        panels={[{ key: 'Swap' }, { key: 'Orderbook' }]}
        left={<IconButton iconType="arrow-back-ios" size="sm" onClick={navigateToIntro} />}
      />
    </div>
  );
}
