import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD } from 'common/utils';
import Checkbox from 'components/checkbox/Checkbox';
import SearchInput from 'components/inputs/SearchInput';
import Table from 'components/table/Table';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import useTxHistory from 'hooks/useTxHistory';
import { useCallback, useMemo, useState } from 'react';
import type { AssetDetail } from 'types/asset';
import TokenTitle from './TokenTitle';
import TokenTransferButton from './TokenTransferButton';
import MyOpenOrder from './tooltips/MyOpenOrder';
import TxsTable from './TxsTable';
import NumberText from 'components/texts/NumberText';
import { EMPTY_TEXTS } from 'COMMON_VARIABLES';
import useNewTokens from 'hooks/useNewTokens';
import { TokenTypes } from 'components/constants/token';

type TableTokenRow = {
  asset: AssetDetail;
  ticker: string;
  tokenTitle: JSX.Element;
  price: BigNumber;
  change24: BigNumber | undefined;
  balance: BigNumber;
  balanceUSD: BigNumber;
  balanceSet: JSX.Element;
  balanceUSDSortOrder: number;
  subJsx: JSX.Element | undefined;
  isSubJsxOpen: boolean;
  rightEnd: JSX.Element | undefined;
  topFixOrder?: number;
};

type TokensTableProps = {
  assets: AssetDetail[];
  dSortValue?: string;
  onTokenClick?: (pool: AssetDetail) => void;
  /** it determines if localStorage flow will be used */
  localStorageKeyPrefix?: string;
};

const TokensTable = ({ assets, dSortValue, onTokenClick, localStorageKeyPrefix }: TokensTableProps) => {
  /** hide 0 balance */
  const storedHide0Checked = useMemo<boolean>(() => {
    const dataRaw = localStorage.getItem(`${localStorageKeyPrefix}-hide-0-checked`) ?? 'true';
    return dataRaw === 'true';
  }, [localStorageKeyPrefix]);

  const [isHide0Checked, setIsHide0Checked] = useState<boolean>(storedHide0Checked);

  const onToggleHide0Checked = useCallback(
    (checked: boolean) => {
      setIsHide0Checked(checked);
      /** localStorage */
      if (localStorageKeyPrefix) localStorage.setItem(`${localStorageKeyPrefix}-hide-0-checked`, checked + '');
    },
    [localStorageKeyPrefix]
  );

  const newTokens = useNewTokens();

  /** search input */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const matchedAssets = useMemo<AssetDetail[]>(
    () =>
      assets.filter((asset) => {
        const searched = asset.ticker.toUpperCase().includes(searchKeyword.trim().toUpperCase());
        const visible =
          !isHide0Checked ||
          asset.availableBalance.gt(0) ||
          asset.reservedBalance.gt(0) ||
          asset.tokenType === TokenTypes.NATIVE ||
          newTokens.findIndex((nt) => nt.denom === asset.denom) > -1;
        return searched && visible;
      }),
    [assets, searchKeyword, isHide0Checked, newTokens]
  );

  /** txs */
  const { txsByDenomDict } = useTxHistory();

  const tableTokenRows = useMemo<TableTokenRow[]>(
    () =>
      matchedAssets.map((asset) => {
        const ticker = asset.ticker;
        const isNewToken = newTokens.findIndex((nt) => nt.denom === asset.denom) > -1;
        const tokenTitle = <TokenTitle asset={asset} badgeLabel={isNewToken ? 'NEW' : undefined} />;

        const price = asset.priceOracle;

        const basePair = asset.pairs.find((pair) => pair.assets[0].denom === asset.denom);
        const quotePair = asset.pairs.find((pair) => pair.assets[1].denom === asset.denom);

        const change24 = basePair
          ? new BigNumber(basePair.change_24)
          : quotePair
          ? new BigNumber(-quotePair.change_24)
          : undefined;

        const balance = asset.availableBalance.shiftedBy(-asset.exponent);
        const balanceUSD = balance.multipliedBy(asset.priceOracle);
        const balanceSet = <TokenBalance asset={asset} balance={balance} balanceUSD={balanceUSD} />;

        /** @description sort by balance usd & ticker */
        const balanceUSDSortOrder = balanceUSD.isZero() ? -asset.ticker.charCodeAt(0) : balanceUSD.toNumber();

        const canTransfer = asset.denom !== 'ucre' && asset.denom !== 'ubcre';
        const rightEnd = canTransfer ? <TokenTransferButton asset={asset} onClick={onTokenClick} /> : undefined;

        const txs = txsByDenomDict[asset.denom] ?? [];
        const subJsx =
          txs.length > 0 ? (
            <div className="px-4 pb-4">
              <div className="bg-secondary_container_o24 rounded-4px">
                <TxsTable txs={txs} asset={asset} />
              </div>
            </div>
          ) : undefined;

        const topFixOrder = asset.denom === 'ucre' ? 1 : asset.denom === 'ubcre' ? 2 : isNewToken ? 3 : undefined;

        return {
          asset,
          ticker,
          tokenTitle,
          price,
          change24,
          balance,
          balanceUSD,
          balanceSet,
          balanceUSDSortOrder,
          subJsx,
          isSubJsxOpen: true,
          rightEnd,
          topFixOrder,
        };
      }),
    [matchedAssets, txsByDenomDict, onTokenClick]
  );

  return (
    <section className="space-y-4">
      <div className="flex flex-col items-start justify-between md:flex-row md:items-end gap-x-4 gap-y-4">
        <Checkbox
          type="plain"
          label="Hide 0 balance"
          defaultChecked={isHide0Checked}
          isChecked={isHide0Checked}
          onChange={onToggleHide0Checked}
        />
        <SearchInput
          value={searchKeyword}
          onChangeValue={setSearchKeyword}
          className="w-full -order-1 md:order-1 md:w-auto"
        />
      </div>

      <div className="overflow-x-auto overflow-y-hidden">
        <div className="md:min-w-[72rem]">
          <Table<TableTokenRow>
            rows={tableTokenRows}
            isLoading={false}
            noDataLabel={EMPTY_TEXTS.TOKENS_TABLE}
            dSortValue={dSortValue ?? 'balanceUSDSortOrder'}
            dIsAsc={false}
            fields={[
              {
                label: 'Name',
                value: 'tokenTitle',
                sortValue: 'ticker',
                type: 'jsx',
                sortType: 'string',
              },
              {
                label: 'Price',
                value: 'price',
                type: 'usd',
                validDigits: 4,
                sortType: 'bignumber',
                align: 'right',
                widthRatio: 10,
              },
              // {
              //   label: '24H Change',
              //   value: 'change24',
              //   type: 'change',
              //   sortType: 'bignumber',
              //   align: 'right',
              //   widthRatio: 12,
              // },
              {
                label: 'Balance',
                value: 'balanceSet',
                sortValue: 'balanceUSDSortOrder',
                sortType: 'number',
                type: 'jsx',
                align: 'right',
                widthRatio: 48,
                generateClassName: (row) => (row.balance.gt(0) ? '' : 'opacity-40'),
              },
            ]}
          />
        </div>
      </div>
    </section>
  );
};

export default TokensTable;

function TokenBalance({
  asset,
  balance,
  balanceUSD,
}: {
  asset: AssetDetail;
  balance: BigNumber;
  balanceUSD: BigNumber;
}) {
  return (
    <div className="relative flex items-center gap-x-1">
      <NumberText
        align="right"
        value={formatAmount(balance, asset.exponent)}
        unit={asset.ticker}
        subValue={`${new BigNumber(balanceUSD).lt(new BigNumber(1).shiftedBy(-2)) ? '' : '≈'}${formatUSD(balanceUSD)}`}
      />

      {/* warning for open order balance */}
      {asset.reservedBalance.gt(0) && (
        <div className="absolute top-0 -right-[1.25rem] h-full flex items-center">
          <TooltipIcon tooltipSize="220px" tooltipContent={<MyOpenOrder asset={asset} />} color="warning" />
        </div>
      )}
    </div>
  );
}
