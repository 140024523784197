export type RibbonBadgeColor = 'orange' | 'red' | 'green';

const RIBBON_BADGE_BG_DICT: { [key in RibbonBadgeColor]: string } = {
  orange: 'linear-gradient(128.63deg, #713600 0%, #B35D03 39.06%, #FF940A 84.38%, #FFBC65 100%)',
  red: 'linear-gradient(134.04deg, #511C19 40.1%, #77312C 61.98%, #EA5B52 91.67%, #EA5B52 91.68%)',
  green: 'linear-gradient(134.04deg, #004A07 32.29%, #13641B 74.6%, #44A84E 90.51%, #75F782 100%)',
};

type RibbonBadgeProps = {
  label: string;
  color?: RibbonBadgeColor;
  className?: string;
};

const RibbonBadge = ({ label, color = 'orange', className = '' }: RibbonBadgeProps) => {
  return (
    <div
      // px sizing is needed to guarantee to be compatible with the clipPath
      className={`${className} relative w-[64px] h-[62px] rounded-[1px]`}
      style={{
        backgroundImage: RIBBON_BADGE_BG_DICT[color],
        clipPath:
          'path("M0 0.999999C0 0.447714 0.447715 0 1 0H63C63.5523 0 64 0.447715 64 1V60.5687C64 61.2637 63.3086 61.7467 62.656 61.5077L32.344 50.4042C32.1219 50.3228 31.8781 50.3228 31.656 50.4042L1.34396 61.5077C0.691396 61.7467 0 61.2637 0 60.5687V0.999999Z")',
      }}
    >
      {/* border */}
      <svg fill="none" className="w-full h-full">
        <path
          className="w-full h-full"
          d="M0.5 0.999999C0.5 0.723857 0.723857 0.5 1 0.5H63C63.2761 0.5 63.5 0.723857 63.5 1V60.5687C63.5 60.9162 63.1543 61.1577 62.828 61.0382L32.5159 49.9347C32.1828 49.8127 31.8172 49.8127 31.4841 49.9347L1.17198 61.0382C0.845697 61.1577 0.5 60.9162 0.5 60.5687V0.999999Z"
          stroke="#FFF"
          strokeOpacity="0.1"
        />
      </svg>

      {/* label */}
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex justify-center items-center pb-2.5">
        <span
          // px sizing is needed to guarantee not to overflow the clipPath
          className="w-min h-min text-center font_label_m !text-[14px] !leading-[18px] whitespace-pre text-black dark:text-white"
          style={{ textShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)' }}
        >
          {label}
        </span>
      </div>
    </div>
  );
};

export default RibbonBadge;
