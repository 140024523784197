export enum CoinLogoSizes {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export const COIN_LOGO_SIZE_DICT: { [key in CoinLogoSizes]: string } = {
  [CoinLogoSizes.XS]: 'w-4 h-4',
  [CoinLogoSizes.S]: 'w-6 h-6',
  [CoinLogoSizes.M]: 'w-8 h-8',
  [CoinLogoSizes.L]: 'w-10 h-10',
  [CoinLogoSizes.XL]: 'w-12 h-12',
};

export const COIN_LOGO_SIZE_REM_DICT: { [key in CoinLogoSizes]: number } = {
  [CoinLogoSizes.XS]: 1,
  [CoinLogoSizes.S]: 1.5,
  [CoinLogoSizes.M]: 2,
  [CoinLogoSizes.L]: 2.5,
  [CoinLogoSizes.XL]: 3,
};

export enum LogoStacking {
  FIRST_ON_TOP = 'FIRST_ON_TOP',
  LAST_ON_TOP = 'LAST_ON_TOP',
}

export const COIN_BG_COLOR_FALLBACK_DICT: { [ticker: string]: string } = {
  // should we consider dark light mode?
  CMDX: '#19335b',
  INJ: '#13151a',
  AXL: '#fff',
};

export const DUMMY_COIN_LOGO_URL = 'https://static-resources.crescent.network/ATOM.png';
