const PageTitleArea = ({ title }: { title: string }) => {
  return (
    <div className="relative h-[3.875rem] md:h-[7.75rem]">
      <div className="relative md:max-w-content h-full flex justify-between items-end px-3 md:px-0 m-auto">
        <h1 className="font_display_l_mobile md:font_display_l text-on_surface">{title}</h1>
      </div>
    </div>
  );
};

export default PageTitleArea;
