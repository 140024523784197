import BigNumber from 'bignumber.js';
import { getTokenName } from 'common/utils';
import PieChart from 'components/charts/PieChart';
import { PieChartEntry } from 'components/charts/types';
import Tooltip from 'components/tooltips';
import useAmountUSD from 'hooks/useAmountUSD';
import { useMainStore } from 'provider/mainContext';
import { useMemo, useState } from 'react';
import { AssetDetail } from 'types/asset';
import { MyPoolDetail } from 'types/pool';
import PortfolioPieChartEachAsset from './tooltips/PortfolioPieChartEachAsset';
import {
  PortfolioPieChartItemAssetRaw,
  PortfolioPieChartItemPoolRaw,
  PortfolioPieChartItemRaw,
  PortfolioPieChartLegendItem,
} from './types';
import PortfolioPieChartLegendLabel from './PortfolioPieChartLegendLabel';

const PORTFOLIO_CHART_ITEM_CNT = 5;
const PORTFOLIO_CHART_COLORS = [
  'var(--color-dataviz-categorical-1)',
  'var(--color-dataviz-categorical-2)',
  'var(--color-dataviz-categorical-3)',
  'var(--color-dataviz-categorical-4)',
  'var(--color-dataviz-categorical-5)',
  'var(--color-dataviz-categorical-others)',
];

type PortfolioPieChartProps = {
  myPools: MyPoolDetail[];
  myAssets: AssetDetail[];
  className?: string;
};

const PortfolioPieChart = ({ myPools, myAssets, className = '' }: PortfolioPieChartProps) => {
  const store = useMainStore();
  const { getAmountUSDRaw } = useAmountUSD(store);

  const pieChartDataAssetsRaw = useMemo<PortfolioPieChartItemAssetRaw[]>(() => {
    return myAssets.map((asset) => {
      const valueBN = getAmountUSDRaw(asset, asset.availableBalance.shiftedBy(-asset.exponent));
      return {
        group: 'token',
        asset,
        poolId: asset.originPool?.poolId,
        valueBN,
        /** chart entry */
        type: Object.values(getTokenName(asset)).join(' '),
        value: valueBN.toNumber(),
      };
    });
  }, [myAssets, getAmountUSDRaw]);

  const pieChartDataPoolsRaw = useMemo<PortfolioPieChartItemPoolRaw[]>(() => {
    return myPools.map((pool) => ({
      group: 'pool',
      asset: pool,
      poolId: pool.poolId,
      valueBN: pool.myTotalBalanceUsd,
      /** chart entry */
      type: `${pool.assets[0].ticker}/${pool.assets[1].ticker}`,
      value: pool.myTotalBalanceUsd.toNumber(),
    }));
  }, [myPools]);

  const total = useMemo<BigNumber>(() => {
    const assetsTotal = pieChartDataAssetsRaw.reduce((accm, item) => accm.plus(item.valueBN), new BigNumber(0));
    const poolsTotal = pieChartDataPoolsRaw.reduce((accm, item) => accm.plus(item.valueBN), new BigNumber(0));
    return assetsTotal.plus(poolsTotal);
  }, [pieChartDataAssetsRaw, pieChartDataPoolsRaw]);

  /** @desc pie chart raw */
  const pieChartDataRaw = useMemo<PortfolioPieChartItemRaw[]>(() => {
    return [...pieChartDataAssetsRaw, ...pieChartDataPoolsRaw]
      .map((item, index) => ({
        ...item,
        ratio: item.valueBN.div(total).multipliedBy(100),
      }))
      .sort((a, b) => (a.valueBN.gt(b.valueBN) ? -1 : 1));
  }, [pieChartDataAssetsRaw, pieChartDataPoolsRaw, total]);

  /** @desc pie chart entries */
  const pieChartData = useMemo<PieChartEntry<string>[]>(() => {
    const pre = pieChartDataRaw
      .slice(0, PORTFOLIO_CHART_ITEM_CNT)
      .map((item) => ({ type: item.type, value: item.value }));
    const others = pieChartDataRaw
      .slice(PORTFOLIO_CHART_ITEM_CNT)
      .reduce((accm, item) => accm.plus(item.valueBN), new BigNumber(0));
    return [...pre, { type: 'Others', value: others.toNumber() }];
  }, [pieChartDataRaw]);

  /** @desc color map */
  const colorMap = useMemo(() => {
    return pieChartData.reduce(
      (accm, item, index) => ({
        ...accm,
        [item.type]: PORTFOLIO_CHART_COLORS[index] ?? PORTFOLIO_CHART_COLORS[PORTFOLIO_CHART_COLORS.length - 1],
      }),
      {}
    );
  }, [pieChartData]);

  /** @desc pie chart legends */
  const pieChartLegends = useMemo<PortfolioPieChartLegendItem[]>(() => {
    return pieChartDataRaw
      .map((item) => ({
        ...item,
        color: colorMap[item.type],
      }))
      .slice(0, 3);
  }, [pieChartDataRaw, colorMap]);

  const hiddenAssets = useMemo<PortfolioPieChartItemRaw[]>(() => {
    return pieChartDataRaw.filter((item) => pieChartLegends.find((legend) => legend.type === item.type) === undefined);
  }, [pieChartDataRaw, pieChartLegends]);

  const hiddenCnt = useMemo<number>(() => {
    const overCnt = hiddenAssets.length;
    return Math.max(overCnt, 0);
  }, [hiddenAssets]);

  /** @desc chart hover */
  const [chartHoverType, setChartHoverType] = useState<string | undefined>(undefined);

  const chartHoverContent = useMemo<JSX.Element>(() => {
    if (chartHoverType === undefined) return <></>;

    const hoverData = pieChartDataRaw.find((item) => item.type === chartHoverType);

    /** others */
    if (hoverData === undefined) {
      return <PortfolioPieChartEachAsset title="Others" items={pieChartDataRaw.slice(9)} />;
    }

    const title = (
      <div className="flex items-baseline gap-x-0.5">
        <span className="font_title_s">
          {hoverData.group === 'token'
            ? getTokenName(hoverData.asset).tokenName
            : `${hoverData.asset.assets[0].ticker}/${hoverData.asset.assets[1].ticker}`}
        </span>
        {hoverData.group !== 'token' && <span className="font_caption_number_s">#{hoverData.asset.poolId}</span>}
      </div>
    );

    return <PortfolioPieChartEachAsset title={title} items={[hoverData]} />;
  }, [chartHoverType, pieChartDataRaw]);

  return (
    <div className={`${className} flex justify-between md:justify-end items-center gap-x-1 md:gap-x-10`}>
      {/* legends */}
      <div className="grow-0 shrink-0 space-y-3 md:space-y-2">
        {pieChartLegends.map((item, index) => (
          <PortfolioPieChartLegendLabel key={index} data={item} />
        ))}

        {hiddenCnt > 0 && (
          <Tooltip
            content={<PortfolioPieChartEachAsset title={`${hiddenCnt} more`} items={hiddenAssets} />}
            size="300px"
            placement="right"
            className="inline-block"
          >
            <div className="font_caption_s dark:text-on_surface_variant_dark">{hiddenCnt} more</div>
          </Tooltip>
        )}
      </div>

      {/* chart */}
      <div className="grow-0 shrink-0 w-min">
        <Tooltip
          size="300px"
          placement="bottom"
          followCursor={true}
          content={chartHoverContent}
          className="!cursor-pointer"
        >
          <PieChart<string>
            data={pieChartData}
            colorMap={colorMap}
            size={store.isMobile ? 100 : 128}
            type={chartHoverType}
            setType={setChartHoverType}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default PortfolioPieChart;
