import { useMemo } from 'react';
import TransitionModal from './TransitionModal';
import { useEvents } from 'hooks/useAPI';
/** type guard */
import { isValidTxEventType } from 'types/txEvent';
import { getHarvestTxsResult, getLpfarmAutoHarvestTxResult, getTxResult, TxResult } from 'common/tx/txResult';
import ModalHeader from './components/ModalHeader';
import Toast from 'components/toast';
import { getViewTxLink } from 'common/utils';
import Spinner from 'components/spinners/Spinner';
import { MODAL_TITLES } from 'COMMON_VARIABLES';

const NotificationModal = ({ isOpen, close, address }: { isOpen: boolean; close: () => void; address: string }) => {
  /** @desc data comes from the latest */
  const { data: eventsData, isLoading: isEventsDataLoading } = useEvents(isOpen && address ? address : '');

  const txResults = useMemo<TxResult[]>(() => {
    return (
      (eventsData?.data ?? []).filter(isValidTxEventType).reduce((accm, txEvent) => {
        const txResult = txEvent.tx_type === 'lpfarmHarvest' ? getHarvestTxsResult([txEvent]) : getTxResult(txEvent);
        const additionalTxResult =
          txEvent.tx_type === 'lpfarmUnfarm' || txEvent.tx_type === 'lpfarmFarm'
            ? getLpfarmAutoHarvestTxResult(txEvent)
            : undefined;
        const additionalTxResults = additionalTxResult ? [additionalTxResult] : [];
        return txResult ? [...accm, ...additionalTxResults, txResult] : accm;
      }, [] as TxResult[]) ?? []
    );
  }, [eventsData?.data]);

  const categories = useMemo<{ all: TxResult[]; processing: TxResult[] }>(() => {
    return {
      all: txResults.filter((txResult) => txResult.feedbackType !== 'waiting' && txResult.feedbackType !== 'error'),
      processing: txResults.filter(
        (txResult) =>
          txResult.feedbackType === 'waiting' && txResults.find((tx) => tx.txHash === txResult.txHash) === undefined
      ),
    };
  }, [txResults]);

  return (
    <TransitionModal size="sm" isOpen={isOpen} close={close}>
      <ModalHeader title={MODAL_TITLES.NOTIFICATION} onCloseClick={close} />

      <div className="h-[400px] px-4 pb-5 overflow-auto md:px-5 md:pb-6">
        <div className=" outline-none space-y-3 md:space-y-6">
          {categories.all.length > 0 ? (
            categories.all.map((txResult, index) => (
              <Toast
                key={index}
                type={txResult.feedbackType}
                title={txResult.title}
                content={txResult.content}
                link={getViewTxLink(txResult)}
              />
            ))
          ) : isEventsDataLoading ? (
            <Spinner className="absolute top-0 bottom-0 left-0 right-0 w-full h-full" />
          ) : (
            <div className="font_caption_l text-on_surface_variant_dark text-center py-32">No history</div>
          )}
        </div>
      </div>
    </TransitionModal>
  );
};

export default NotificationModal;
