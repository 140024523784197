import Long from 'long';

const createIbcWithdrawMsg = (txData: any, destinationChainInfo: any, fromAddress: any, toAddress) => {
  const IBCWithdrawData = {
    typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
    value: {
      sourcePort: 'transfer',
      sourceChannel: destinationChainInfo.ibcSendChannel,
      sender: fromAddress,
      receiver: toAddress,
      timeoutTimestamp: Long.fromString(new Date().getTime() + 600000 + '000000'),
      token: { amount: String(txData.amount), denom: txData.denom },
    },
  };
  return [IBCWithdrawData];
};

export default createIbcWithdrawMsg;
