import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { formatAmount, formatUSD, getValidDigitNumber } from 'common/utils';
import { ToastContentItem } from 'components/toast/components';
import { store } from 'provider/mainContext';
import {
  INITIAL_ASSET,
  TOAST_INFOBOX_WARNING_ORDER_EXPIRE,
  TOAST_TITLE_BUY_ORDER,
  TOAST_TITLE_BUY_ORDER_CANCEL,
  TOAST_TITLE_BUY_ORDER_EXPIRE,
  TOAST_TITLE_BUY_ORDER_FULFILL,
  TOAST_TITLE_BUY_ORDER_PARTIAL_EXPIRE,
  TOAST_TITLE_FARM,
  TOAST_TITLE_IBC_DEPOSIT_COMPLETED,
  TOAST_TITLE_IBC_DEPOSIT_PROCESSING,
  TOAST_TITLE_IBC_WITHDRAW_COMPLETED,
  TOAST_TITLE_IBC_WITHDRAW_PROCESSING,
  TOAST_TITLE_LF_FARM,
  TOAST_TITLE_LF_UNFARM,
  TOAST_TITLE_POOL_DEPOSIT,
  TOAST_TITLE_POOL_WITHDRAW,
  TOAST_TITLE_REWARDS,
  TOAST_TITLE_SELL_ORDER,
  TOAST_TITLE_SELL_ORDER_CANCEL,
  TOAST_TITLE_SELL_ORDER_EXPIRE,
  TOAST_TITLE_SELL_ORDER_FULFILL,
  TOAST_TITLE_SELL_ORDER_PARTIAL_EXPIRE,
  TOAST_TITLE_STAKE,
  TOAST_TITLE_UNFARM,
  TOAST_TITLE_UNSTAKE,
  TOAST_TITLE_VOTE,
} from 'COMMON_VARIABLES';
import InfoBox from 'components/infobox';
import IBCProcessVisualWidget from 'components/widgets/IBCProcessVisualWidget';
import RewardCoinsByPool from 'components/txResult/RewardCoinsByPool';
import TxResultPairLabel from 'components/txResult/TxResultPairLabel';
import type { FeedbackType } from 'components/toast/types';
import type { CoinDetail, InfoAsset } from 'types/asset';
import type { InfoChain } from 'types/chain';
import type {
  TxEvent,
  TxEventType,
  TxHarvest,
  TxIBCAck,
  TxIBCRecv,
  TxIBCSend,
  TxLiquidFarm,
  TxLiquidStake,
  TxLiquidUnfarm,
  TxLiquidUnstake,
  TxLpFarm,
  TxLpUnfarm,
  TxPoolDepositDone,
  TxPoolWithdrawDone,
  TxSwapCancel,
  TxSwapExpired,
  TxSwapFull,
  TxSwapOrder,
  TxSwapPart,
  TxType,
  TxVote,
  VoteOption,
} from 'types/txEvent';
import type { PoolDetail } from 'types/pool';
import TokenAmount from 'components/txResult/TokenAmount';
import NumberText from 'components/texts/NumberText';
import Chip, { ChipColor } from 'components/chips/Chip';

dayjs.extend(duration);

/** @wip refactor */
export type TxResultUIType = 'toast' | 'noti';

export type TxResult = {
  feedbackType: FeedbackType;
  title: string | JSX.Element;
  content: string | JSX.Element;
  txChainId?: string;
  txType: TxType;
  txHash?: string;
  time: number;
  /** force ordering notifications */
  order?: 0 | 1;
};

/** result content by event */
const getSwapOrderTxResult = (event: TxSwapOrder): TxResult => {
  const orderId = `${event.json_raw.pairId}-${event.json_raw.reqId}`;
  const isBuy = event.result?.direction === 1;

  /** amount */
  const asset: InfoAsset | undefined =
    store.assetsData.info?.[isBuy ? event.result?.demandDenom : event.result?.offerDenom];
  const amount = new BigNumber(event.result?.orderBaseAmount).shiftedBy(-(asset?.exponent ?? 0));

  /** price */
  const pair = store.getPairs().find((pair) => pair.pairId === event.json_raw.pairId);
  const price = new BigNumber(event.result?.orderPrice ?? 0).shiftedBy(pair?.exponentDiff ?? 0);
  const vdPrice = new BigNumber(getValidDigitNumber(price.toNumber(), 4)).toFormat();
  const priceTicker = pair?.assets[isBuy ? 1 : 0]?.ticker ?? '';

  /** expires in */
  const toExpiryMs = event.json_raw.expireTimestamp * 1000 - new Date().getTime();
  const expiresIn = toExpiryMs < 60000 ? 'less than 1M' : dayjs.duration(toExpiryMs).format('H[H] m[M] s[S]');

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        {asset && <ToastContentItem label="Amount" value={<TokenAmount asset={asset} amount={amount} />} />}

        <ToastContentItem label="Price" value={<NumberText size="sm" value={vdPrice} unit={priceTicker} />} />
        <ToastContentItem label="Order ID" value={<div className="font_caption_number_m">{orderId}</div>} />
      </div>

      <InfoBox>Order expires in {expiresIn}.</InfoBox>
    </div>
  );

  return {
    feedbackType: 'success',
    title: isBuy ? TOAST_TITLE_BUY_ORDER : TOAST_TITLE_SELL_ORDER,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    order: 0,
    time: event.timestamp_us,
  };
};

const getSwapPartTxResult = (event: TxSwapPart): TxResult => {
  const orderId = `${event.json_raw.pairId}-${event.json_raw.reqId}`;
  const isBuy = event.json_raw.direction === 1;

  /** amount */
  const asset: InfoAsset | undefined =
    store.assetsData.info?.[isBuy ? event.result?.demandDenom : event.result?.offerDenom];
  const amount = new BigNumber(event.result.swappedBaseAmount).shiftedBy(-(asset?.exponent ?? 0));

  /** expires in */
  const toExpiryMs = event.json_raw.expireTimestamp * 1000 - new Date().getTime();
  const expiresIn = toExpiryMs < 60000 ? 'less than 1M' : dayjs.duration(toExpiryMs).format('H[H] m[M] s[S]');

  /** title */
  const fulfilledRate =
    Number(isBuy ? event.result.demandAmount : event.result.offerAmount) / Number(event.result.swappedBaseAmount);

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        {asset && <ToastContentItem label="Amount" value={<TokenAmount asset={asset} amount={amount} />} />}
        <ToastContentItem label="Order ID" value={<div className="font_caption_number_m">{orderId}</div>} />
      </div>

      <InfoBox>Order expires in {expiresIn}.</InfoBox>
    </div>
  );

  const title = isBuy ? TOAST_TITLE_BUY_ORDER_FULFILL : TOAST_TITLE_SELL_ORDER_FULFILL;

  return {
    feedbackType: 'success',
    title: title.replace('100', fulfilledRate.toFixed(1)),
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    order: 1,
    time: event.timestamp_us,
  };
};

const getSwapFullTxResult = (event: TxSwapFull): TxResult => {
  const orderId = `${event.json_raw.pairId}-${event.json_raw.reqId}`;
  const isBuy = event.json_raw.direction === 1;

  /** amount */
  const asset: InfoAsset | undefined =
    store.assetsData.info?.[isBuy ? event.result?.demandDenom : event.result?.offerDenom];
  const amount = new BigNumber(event.result.swappedBaseAmount).shiftedBy(-(asset?.exponent ?? 0));

  const content = (
    <div className="space-y-3">
      {asset && <ToastContentItem label="Amount" value={<TokenAmount asset={asset} amount={amount} />} />}
      <ToastContentItem label="Order ID" value={<div className="font_caption_number_m">{orderId}</div>} />
    </div>
  );

  return {
    feedbackType: 'success',
    title: isBuy ? TOAST_TITLE_BUY_ORDER_FULFILL : TOAST_TITLE_SELL_ORDER_FULFILL,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    order: 1,
    time: event.timestamp_us,
  };
};

const getSwapCancelTxResult = (event: TxSwapCancel): TxResult => {
  const orderId = `${event.json_raw.pairId}-${event.json_raw.reqId}`;
  const isBuy = event.json_raw.direction === 1;

  const pair = store.getPairs().find((pair) => pair.pairId === event.json_raw.pairId);

  const content = (
    <div className="space-y-3">
      {pair && <ToastContentItem label="Pair" value={<TxResultPairLabel assets={pair.assets} />} />}
      <ToastContentItem label="Order ID" value={<div className="font_caption_number_m">{orderId}</div>} />
    </div>
  );

  return {
    feedbackType: 'success',
    title: isBuy ? TOAST_TITLE_BUY_ORDER_CANCEL : TOAST_TITLE_SELL_ORDER_CANCEL,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    time: event.timestamp_us,
  };
};

const getSwapExpiredTxResult = (event: TxSwapExpired): TxResult => {
  const orderId = `${event.json_raw.pairId}-${event.json_raw.reqId}`;

  const isBuy = event.json_raw.direction === 1;

  const isFullyExpired = event.json_raw.remainOfferAmount === event.json_raw.offerAmount;

  const pair = store.getPairs().find((pair) => pair.pairId === event.json_raw.pairId);

  const fulfillAsset = pair?.assets[0];

  const fulfilledAmount = (
    isBuy
      ? new BigNumber(event.json_raw.receivedDemandAmount)
      : new BigNumber(event.json_raw.offerAmount).minus(event.json_raw.remainOfferAmount)
  )
    .shiftedBy(-(fulfillAsset?.exponent ?? 0))
    .dp(fulfillAsset?.exponent ?? 6);

  const unfulfilledAmount = (
    isBuy
      ? new BigNumber(event.json_raw.orderBaseAmount).minus(event.json_raw.receivedDemandAmount)
      : new BigNumber(event.json_raw.remainOfferAmount)
  )
    .shiftedBy(-(fulfillAsset?.exponent ?? 0))
    .dp(fulfillAsset?.exponent ?? 6);

  const TITLE_DICT: { [key in 'buy' | 'sell']: { [key in 'fully' | 'partially']: string } } = {
    buy: {
      fully: TOAST_TITLE_BUY_ORDER_EXPIRE,
      partially: TOAST_TITLE_BUY_ORDER_PARTIAL_EXPIRE,
    },
    sell: {
      fully: TOAST_TITLE_SELL_ORDER_EXPIRE,
      partially: TOAST_TITLE_SELL_ORDER_PARTIAL_EXPIRE,
    },
  };

  const infoboxContent = isFullyExpired
    ? TOAST_INFOBOX_WARNING_ORDER_EXPIRE
    : `${unfulfilledAmount} ${fulfillAsset?.ticker} was not filled. You ${
        isBuy ? 'received' : 'sold'
      } ${fulfilledAmount} ${fulfillAsset?.ticker ?? ''}.`;

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        {pair && <ToastContentItem label="Amount" value={<TxResultPairLabel assets={pair.assets} />} />}
        <ToastContentItem label="Order ID" value={<div className="font_caption_number_m">{orderId}</div>} />
      </div>

      <InfoBox>{infoboxContent}</InfoBox>
    </div>
  );

  return {
    feedbackType: 'warning',
    title: TITLE_DICT[isBuy ? 'buy' : 'sell'][isFullyExpired ? 'fully' : 'partially'],
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    time: event.timestamp_us,
  };
};

const getLiquidStakeTxResult = (event: TxLiquidStake): TxResult => {
  const stakedAmount = new BigNumber(event.result?.amount).shiftedBy(-6);
  const bondedAmount = new BigNumber(parseInt(event.result?.bondedAmount)).shiftedBy(-6);

  const bondedAsset: InfoAsset | undefined = store.assetsData.info.ubcre;

  const content = (
    <div className="space-y-3">
      <ToastContentItem label="Staked" value={<TokenAmount asset={INITIAL_ASSET} amount={stakedAmount} />} />
      {bondedAsset && (
        <ToastContentItem label="Received" value={<TokenAmount asset={bondedAsset} amount={bondedAmount} />} />
      )}
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_STAKE,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    time: event.timestamp_us,
  };
};

const getLiquidUnstakeTxResult = (event: TxLiquidUnstake): TxResult => {
  const unstakedAmount = new BigNumber(event.result?.amount).shiftedBy(-6);
  const unbondingAmount = new BigNumber(parseInt(event.result?.unbondingAmount)).shiftedBy(-6);

  const bondedAsset: InfoAsset | undefined = store.assetsData.info.ubcre;

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        <ToastContentItem label="Unstaked" value={<TokenAmount asset={bondedAsset} amount={unstakedAmount} />} />

        {bondedAsset && (
          <div className="space-y-1">
            <ToastContentItem
              label="Will Receive"
              value={<TokenAmount asset={INITIAL_ASSET} amount={unbondingAmount} />}
            />
            <div className="w-full text-right font_caption_xs text-on_surface_variant_dark">13D 23H 59M Remaining</div>
          </div>
        )}
      </div>

      <InfoBox>You can see unbonding status at the bottom of Portfolio page.</InfoBox>
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_UNSTAKE,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    time: event.timestamp_us,
  };
};

const getPoolDepositTxResult = (event: TxPoolDepositDone): TxResult => {
  const pool = store.getPools().find((pool) => pool.poolId === event.json_raw.poolId);

  const assetA: InfoAsset | undefined = store.assetsData.info[event.json_raw.denomA];
  const assetB: InfoAsset | undefined = store.assetsData.info[event.json_raw.denomB];

  const priceOracleA = store.assetsData.live[event.json_raw.denomA]?.priceOracle ?? 0;
  const priceOracleB = store.assetsData.live[event.json_raw.denomB]?.priceOracle ?? 0;

  const amountARaw = Number(event.result.acceptedAAmount);
  const amountBRaw = Number(event.result.acceptedBAmount);
  const amountA = new BigNumber(Number.isNaN(amountARaw) ? 0 : amountARaw).shiftedBy(-(assetA?.exponent ?? 0));
  const amountB = new BigNumber(Number.isNaN(amountBRaw) ? 0 : amountBRaw).shiftedBy(-(assetB?.exponent ?? 0));

  const totalUSD = amountA.multipliedBy(priceOracleA).plus(amountB.multipliedBy(priceOracleB));

  const content = (
    <div className="space-y-2">
      {pool && <ToastContentItem label="Pool" value={<TxResultPairLabel assets={pool.assets} id={pool.poolId} />} />}

      <ToastContentItem
        label="Amount"
        value={
          <div className="flex flex-col items-end gap-y-1">
            <NumberText size="md" value={`≈ ${formatUSD(totalUSD)}`} />
            {assetA && <NumberText size="xs" value={formatAmount(amountA, assetA.exponent)} unit={assetA.ticker} />}
            {assetB && <NumberText size="xs" value={formatAmount(amountB, assetB.exponent)} unit={assetB.ticker} />}
          </div>
        }
      />
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_POOL_DEPOSIT,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    time: event.timestamp_us,
  };
};

const getPoolWithdrawTxResult = (event: TxPoolWithdrawDone): TxResult => {
  const pool = store.getPools().find((pool) => pool.poolId === event.json_raw.poolId);

  const assetA: InfoAsset | undefined = store.assetsData.info[event.result.denomA];
  const assetB: InfoAsset | undefined = store.assetsData.info[event.result.denomB];

  const priceOracleA = store.assetsData.live[event.result.denomA]?.priceOracle ?? 0;
  const priceOracleB = store.assetsData.live[event.result.denomB]?.priceOracle ?? 0;

  const amountARaw = Number(event.result.withdrawAAmount);
  const amountBRaw = Number(event.result.withdrawBAmount);
  const amountA = new BigNumber(Number.isNaN(amountARaw) ? 0 : amountARaw).shiftedBy(-(assetA?.exponent ?? 0));
  const amountB = new BigNumber(Number.isNaN(amountBRaw) ? 0 : amountBRaw).shiftedBy(-(assetB?.exponent ?? 0));

  const totalUSD = amountA.multipliedBy(priceOracleA).plus(amountB.isNaN() ? 0 : amountB.multipliedBy(priceOracleB));

  const content = (
    <div className="space-y-3">
      {pool && <ToastContentItem label="Pool" value={<TxResultPairLabel assets={pool.assets} id={pool.poolId} />} />}

      <ToastContentItem
        label="Amount"
        value={
          <div className="flex flex-col items-end gap-y-1">
            <NumberText value={`≈ ${formatUSD(totalUSD)}`} />
            {assetA && <NumberText size="xs" value={formatAmount(amountA, assetA.exponent)} unit={assetA.ticker} />}
            {assetB && <NumberText size="xs" value={formatAmount(amountB, assetB.exponent)} unit={assetB.ticker} />}
          </div>
        }
      />
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_POOL_WITHDRAW,
    content,
    txType: event.tx_type,
    txHash: getTxHashFrom(event),
    time: event.timestamp_us,
  };
};

const getIBCSendTxResult = (event: TxIBCSend): TxResult => {
  const isWithdraw = event.json_raw.direction === 0;

  const baseDenom = isWithdraw ? event.json_raw.denom.split('/')[2] ?? '' : event.json_raw.denom;
  const isLuna = baseDenom === 'uluna';

  /** @caution asset doesn't identify b/w LUNA and LUNC */
  const asset = Object.values(store.assetsData.info).find((asset) => asset.baseDenom === baseDenom);

  const fromChain: InfoChain | undefined = store.chainsData.info[event.json_raw.sendCahinId];
  const toChain: InfoChain | undefined = isWithdraw
    ? isLuna
      ? Object.values(store.chainsData.info).find((chain) => chain.ibcRecvChannel === event.json_raw.recvChannel)
      : store.chainsData.info[asset?.chainId ?? '']
    : store.chainsData.info[INITIAL_ASSET.chainId];
  const targetChain = isWithdraw ? toChain : fromChain;

  const luna = Object.values(store.assetsData.info).find(
    (asset) => asset.ticker === (targetChain?.chainId.includes('columbus') ? 'LUNC' : 'LUNA')
  );

  const targetAsset = isLuna ? luna : asset;
  const amount = new BigNumber(event.json_raw.amount).shiftedBy(-(asset?.exponent ?? 0));

  const content = (
    <div className="space-y-4">
      {targetAsset && fromChain && toChain && (
        <IBCProcessVisualWidget asset={targetAsset} fromChain={fromChain} toChain={toChain} isWithdraw={isWithdraw} />
      )}
      {targetAsset && <ToastContentItem label="Amount" value={<TokenAmount asset={targetAsset} amount={amount} />} />}
    </div>
  );

  return {
    feedbackType: 'waiting',
    title: isWithdraw ? TOAST_TITLE_IBC_WITHDRAW_PROCESSING : TOAST_TITLE_IBC_DEPOSIT_PROCESSING,
    content,
    txType: event.tx_type,
    txHash: event.txhash,
    txChainId: event.json_raw.sendCahinId,
    time: event.timestamp_us,
  };
};

const getIBCRecvTxResult = (event: TxIBCRecv): TxResult => {
  const isWithdraw = event.json_raw.direction === 0;

  const baseDenom = isWithdraw ? event.json_raw.denom.split('/')[2] ?? '' : event.json_raw.denom;
  const isLuna = baseDenom === 'uluna';

  /** @caution asset doesn't identify b/w LUNA and LUNC */
  const asset = Object.values(store.assetsData.info).find((asset) => asset.baseDenom === baseDenom);

  const fromChain: InfoChain | undefined = store.chainsData.info[event.json_raw.sendCahinId];
  const toChain: InfoChain | undefined = isWithdraw
    ? isLuna
      ? Object.values(store.chainsData.info).find((chain) => chain.ibcRecvChannel === event.json_raw.recvChannel)
      : store.chainsData.info[asset?.chainId ?? '']
    : store.chainsData.info[INITIAL_ASSET.chainId];
  const targetChain = isWithdraw ? toChain : fromChain;

  const luna = Object.values(store.assetsData.info).find(
    (asset) => asset.ticker === (targetChain?.chainId.includes('columbus') ? 'LUNC' : 'LUNA')
  );

  const targetAsset = isLuna ? luna : asset;
  const amount = new BigNumber(event.json_raw.amount).shiftedBy(-(asset?.exponent ?? 0));

  const content = (
    <div className="space-y-4">
      {targetAsset && <ToastContentItem label="Amount" value={<TokenAmount asset={targetAsset} amount={amount} />} />}
    </div>
  );

  return {
    feedbackType: 'success',
    title: isWithdraw ? TOAST_TITLE_IBC_WITHDRAW_COMPLETED : TOAST_TITLE_IBC_DEPOSIT_COMPLETED,
    content,
    txType: event.tx_type,
    txHash: event.json_raw.txhash,
    txChainId: event.json_raw.sendCahinId,
    time: event.timestamp_us,
  };
};

const getIBCAckTxResult = (event: TxIBCAck): TxResult => {
  const isWithdraw = event.json_raw.direction === 0;

  const baseDenom = isWithdraw ? event.json_raw.denom.split('/')[2] ?? '' : event.json_raw.denom;
  const isLuna = baseDenom === 'uluna';

  /** @caution asset doesn't identify b/w LUNA and LUNC */
  const asset = Object.values(store.assetsData.info).find((asset) => asset.baseDenom === baseDenom);

  const fromChain: InfoChain | undefined = store.chainsData.info[event.json_raw.sendCahinId];
  const toChain: InfoChain | undefined = isWithdraw
    ? isLuna
      ? Object.values(store.chainsData.info).find((chain) => chain.ibcRecvChannel === event.json_raw.recvChannel)
      : store.chainsData.info[asset?.chainId ?? '']
    : store.chainsData.info[INITIAL_ASSET.chainId];
  const targetChain = isWithdraw ? toChain : fromChain;

  const luna = Object.values(store.assetsData.info).find(
    (asset) => asset.ticker === (targetChain?.chainId.includes('columbus') ? 'LUNC' : 'LUNA')
  );

  const targetAsset = isLuna ? luna : asset;
  const amount = new BigNumber(event.json_raw.amount).shiftedBy(-(asset?.exponent ?? 0));

  const content = (
    <div className="space-y-4">
      {targetAsset && <ToastContentItem label="Amount" value={<TokenAmount asset={targetAsset} amount={amount} />} />}
    </div>
  );

  return {
    feedbackType: 'success',
    title: isWithdraw ? TOAST_TITLE_IBC_WITHDRAW_COMPLETED : TOAST_TITLE_IBC_DEPOSIT_COMPLETED,
    content,
    txType: event.tx_type,
    txHash: event.json_raw.txhash,
    txChainId: event.json_raw.sendCahinId,
    time: event.timestamp_us,
  };
};

const getVoteTxResult = (event: TxVote): TxResult => {
  const OPTION_LABEL_DICT: { [key in VoteOption]: string } = {
    1: 'Yes',
    2: 'Abstain',
    3: 'No',
    4: 'NoWithVeto',
  };

  const OPTION_CHIP_COLOR_DICT: { [key in VoteOption]: ChipColor } = {
    1: 'yes',
    2: 'abstain',
    3: 'no',
    4: 'veto',
  };

  const content = (
    <Chip
      size="md"
      label={OPTION_LABEL_DICT[event.json_raw.option]}
      color={OPTION_CHIP_COLOR_DICT[event.json_raw.option]}
    />
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_VOTE,
    content,
    txType: event.tx_type,
    txHash: event.txhash,
    time: event.timestamp_us,
  };
};

const getLpfarmFarmTxResult = (event: TxLpFarm): TxResult => {
  const pool = store.getPools().find((pool) => pool.poolDenom === event.json_raw.stakingCoin.denom);
  const poolToken = store.assetsData.info[event.json_raw.stakingCoin.denom];

  const amount = new BigNumber(event.json_raw.stakingCoin.amount);
  // .shiftedBy(-poolToken?.exponent ?? 12);
  const amountUSD = amount.multipliedBy(pool?.priceOracle ?? 0);

  const content = (
    <div className="space-y-3">
      {pool && <ToastContentItem label="Pool" value={<TxResultPairLabel assets={pool.assets} id={pool.poolId} />} />}

      <ToastContentItem
        label="Amount"
        value={
          <div className="flex flex-col items-end gap-y-1">
            <NumberText size="md" value={`≈ ${formatUSD(amountUSD)}`} />
            <NumberText
              size="xs"
              value={formatAmount(amount.shiftedBy(-poolToken?.exponent ?? 12), poolToken?.exponent ?? 12)}
              unit="Pool Token"
            />
          </div>
        }
      />
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_FARM,
    content,
    txType: event.tx_type,
    txHash: event.txhash,
    time: event.timestamp_us,
  };
};

const getLpfarmUnfarmTxResult = (event: TxLpUnfarm): TxResult => {
  const pool = store.getPools().find((pool) => pool.poolDenom === event.json_raw.unstakingCoin.denom);
  const poolToken = store.assetsData.info[event.json_raw.unstakingCoin.denom];

  const amount = new BigNumber(event.json_raw.unstakingCoin.amount);
  // .shiftedBy(-poolToken?.exponent ?? 12);
  const amountUSD = amount.multipliedBy(pool?.priceOracle ?? 0);

  const content = (
    <div className="space-y-3">
      {pool && <ToastContentItem label="Pool" value={<TxResultPairLabel assets={pool.assets} id={pool.poolId} />} />}

      <ToastContentItem
        label="Amount"
        value={
          <div className="flex flex-col items-end gap-y-1">
            <NumberText size="md" value={`≈ ${formatUSD(amountUSD)}`} />
            <NumberText
              size="xs"
              value={formatAmount(amount.shiftedBy(-poolToken?.exponent ?? 12), poolToken?.exponent ?? 12)}
              unit="Pool Token"
            />
          </div>
        }
      />
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_UNFARM,
    content,
    txType: event.tx_type,
    txHash: event.txhash,
    time: event.timestamp_us,
  };
};

const getLiquidFarmTxResult = (event: TxLiquidFarm): TxResult => {
  const pool = store.getPools().find((pool) => pool.poolId === event.json_raw.poolId);

  const poolDenom = `pool${event.json_raw.poolId}`;
  const poolToken = store.assetsData.info[poolDenom];

  const amount = new BigNumber(event.json_raw.farmingCoin.split(poolDenom)[0]);
  const amountUSD = amount.multipliedBy(pool?.priceOracle ?? 0);

  const content = (
    <div className="space-y-2">
      {pool && <ToastContentItem label="Pool" value={<TxResultPairLabel assets={pool.assets} id={pool.poolId} />} />}

      <ToastContentItem
        label="Received"
        value={
          <div className="flex flex-col items-end gap-y-1">
            <NumberText size="md" value={`≈ ${formatUSD(amountUSD)}`} />
            <NumberText
              size="xs"
              color="liquid"
              value={formatAmount(amount.shiftedBy(-poolToken?.exponent ?? 12), poolToken?.exponent ?? 12)}
              unit="LF Token"
            />
          </div>
        }
      />
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_LF_FARM,
    content,
    txType: event.tx_type,
    txHash: event.txhash,
    time: event.timestamp_us,
  };
};

const getLiquidUnfarmTxResult = (event: TxLiquidUnfarm): TxResult => {
  const pool = store.getPools().find((pool) => pool.poolId === event.json_raw.poolId);

  const poolDenom = `pool${event.json_raw.poolId}`;
  const poolToken = store.assetsData.info[poolDenom];

  const amount = new BigNumber(event.json_raw.unfarmedCoin.split(poolDenom)[0]);
  const amountUSD = amount.multipliedBy(pool?.priceOracle ?? 0);

  const content = (
    <div className="space-y-2">
      {pool && <ToastContentItem label="Pool" value={<TxResultPairLabel assets={pool.assets} id={pool.poolId} />} />}

      <ToastContentItem
        label="Received"
        value={
          <div className="flex flex-col items-end gap-y-1">
            <NumberText size="md" value={`≈ ${formatUSD(amountUSD)}`} />
            <NumberText
              size="xs"
              value={formatAmount(amount.shiftedBy(-poolToken?.exponent ?? 12), poolToken?.exponent ?? 12)}
              unit="Pool Token"
            />
          </div>
        }
      />
    </div>
  );

  return {
    feedbackType: 'success',
    title: TOAST_TITLE_LF_UNFARM,
    content,
    txType: event.tx_type,
    txHash: event.txhash,
    time: event.timestamp_us,
  };
};

export const getLpfarmAutoHarvestTxResult = (event: TxLpUnfarm | TxLpFarm): TxResult | undefined => {
  const pool = store.getPools().find((pool) => {
    if (event.tx_type === 'lpfarmUnfarm') {
      return pool.poolDenom === event.json_raw.unstakingCoin.denom;
    } else {
      // lpfarmFarm
      return pool.poolDenom === event.json_raw.stakingCoin.denom;
    }
  });

  const rewardCoins: CoinDetail[] = event.result.withdrawnRewards
    .map((reward) => {
      const asset: InfoAsset | undefined = store.assetsData.info[reward.denom];
      if (asset === undefined) return undefined;

      const amount = new BigNumber(reward.amount).shiftedBy(-asset.exponent);
      if (amount.isZero()) return undefined;

      const priceOracle = store.assetsData.live[reward.denom]?.priceOracle ?? 0;
      const amountUSD = amount.multipliedBy(priceOracle);

      return { asset, amount, amountUSD };
    })
    .filter((item) => item !== undefined) as CoinDetail[];

  if (!pool || rewardCoins.length === 0) return undefined;

  const content = <RewardCoinsByPool pool={pool} rewardCoins={rewardCoins} />;

  return {
    feedbackType: 'congrats',
    title: TOAST_TITLE_REWARDS,
    content,
    txType: event.tx_type,
    /** @caution not to update unfarm toast since they have same txhash */
    txHash: undefined,
    time: event.timestamp_us,
  };
};

export const getHarvestTxsResult = (events: TxHarvest[]): TxResult | undefined => {
  const rewardsDisplayList = events
    .map((event) => {
      const pool = store.getPools().find((pool) => pool.poolDenom === event.json_raw.stakingCoinDenom);

      const rewardCoins = event.result.rewardCoins.split(',');
      const rewardsCoinList: CoinDetail[] = rewardCoins
        .map((rewardCoin) => {
          const amountRaw = parseInt(rewardCoin);
          const denom = rewardCoin.split(amountRaw.toString())[1] ?? '';
          const asset: InfoAsset | undefined = store.assetsData.info[denom];
          if (asset === undefined) return undefined;
          if (amountRaw <= 0) return undefined;

          const exponent = asset?.exponent ?? 0;
          const priceOracle = store.assetsData.live[denom]?.priceOracle ?? 0;
          const amount = new BigNumber(amountRaw).shiftedBy(-exponent);
          const amountUSD = amount.multipliedBy(priceOracle);

          return { asset, amount, amountUSD };
        })
        .filter((item) => item !== undefined) as CoinDetail[];

      return {
        pool,
        rewardsCoinList,
      };
    })
    .filter((d) => d.pool !== undefined && d.rewardsCoinList.length > 0) as {
    pool: PoolDetail;
    rewardsCoinList: CoinDetail[];
  }[];

  if (rewardsDisplayList.length === 0) return undefined;

  const content = (
    <div className="max-h-[180px] overflow-auto space-y-4">
      {rewardsDisplayList.map((item, index) => (
        <RewardCoinsByPool key={index} pool={item.pool} rewardCoins={item.rewardsCoinList} />
      ))}
    </div>
  );

  return {
    feedbackType: 'congrats',
    title: TOAST_TITLE_REWARDS,
    content,
    txType: 'lpfarmHarvest',
    txHash: events[0]?.json_raw.txhash ?? '',
    time: events[0]?.timestamp_us ?? 0,
  };
};

/** @todo lfFarm, lfUnfarm UI is tbd */
export const TX_RESULT_FORMAT_FUNC_DICT: {
  [key in Exclude<TxType, 'lpfarmHarvest'>]: {
    [key in TxEventType]?: (event: any) => TxResult;
  };
} = {
  swapOrder: {
    txSend: getSwapOrderTxResult,
    swapPart: getSwapPartTxResult,
    swapFull: getSwapFullTxResult,
    swapCancel: getSwapCancelTxResult,
    swapExpired: getSwapExpiredTxResult,
  },
  liquidStake: {
    txDone: getLiquidStakeTxResult,
  },
  liquidUnstake: {
    txDone: getLiquidUnstakeTxResult,
  },
  // lpfarmHarvest: {
  //   txDone: getHarvestTxsResult,
  // },
  poolDeposit: {
    txDone: getPoolDepositTxResult,
  },
  poolWithdraw: {
    txDone: getPoolWithdrawTxResult,
  },
  ibcSend: {
    txSend: getIBCSendTxResult,
    ibcRecv: getIBCRecvTxResult,
    ibcAck: getIBCAckTxResult,
  },
  vote: {
    TxDone: getVoteTxResult,
    txDone: getVoteTxResult,
  },
  lpfarmFarm: {
    txDone: getLpfarmFarmTxResult,
  },
  lpfarmUnfarm: {
    txDone: getLpfarmUnfarmTxResult,
  },
  lfFarm: {
    txDone: getLiquidFarmTxResult,
  },
  lfUnfarm: {
    txDone: getLiquidUnfarmTxResult,
  },
};

export function getTxResult(event: Exclude<TxEvent, TxHarvest>): TxResult | undefined {
  const feedback = TX_RESULT_FORMAT_FUNC_DICT[event.tx_type]?.[event.evt_type]?.(event);
  return feedback;
}

/** @todo remove */
function getTxHashFrom(txEvent: TxEvent): string | undefined {
  return txEvent?.txhash.length ? txEvent?.txhash : txEvent?.json_raw?.txhash;
}
