import { Proposal } from 'types/gov';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { INITIAL_ASSET } from 'COMMON_VARIABLES';
import { useMainStore } from 'provider/mainContext';
import VotingBar from './VotingBar';
import VotingTurnoutFlags, { MobileColsCnt } from './VotingTurnoutFlags';
import { formatAmount } from 'common/utils';
import ProposalIndex from './ProposalIndex';
import { toShort } from 'common/bigNumberHandlers';
import NumberText from 'components/texts/NumberText';

/** @desc VotingVisualizer */
const VotingVisualizer = ({ proposal, mobileColsCnt }: { proposal: Proposal; mobileColsCnt?: MobileColsCnt }) => {
  const mainStore = useMainStore();
  const totalVoted = useMemo<BigNumber>(() => {
    let _total = new BigNumber(proposal.tally.yes);
    _total = _total.plus(proposal.tally.abstain);
    _total = _total.plus(proposal.tally.no);
    _total = _total.plus(proposal.tally.no_with_veto);
    return _total.dividedBy(10 ** INITIAL_ASSET.exponent);
  }, [proposal]);

  const totalBondedAmount = useMemo(() => {
    let amount = new BigNumber(mainStore.paramsData.info['staking.total_bonded_amount'] || 0);
    amount = amount.dividedBy(10 ** INITIAL_ASSET.exponent);
    return amount;
  }, [mainStore.paramsData]);

  return (
    <div className="pt-2 pb-4 space-y-3">
      <div className="flex flex-col items-end">
        <div className="w-full flex flex-col items-stretch justify-between gap-y-1 gap-x-3 md:flex-row md:items-center">
          <ProposalIndex
            label="Total"
            value={<NumberText size="sm" unit="CRE" value={totalVoted.toFormat()} xsDecimal={true} />}
          />

          <div className="flex flex-col items-stretch gap-y-1 gap-x-3 md:flex-row md:items-center">
            <ProposalIndex
              label="Current turnout"
              labelColor={mainStore.isMobile ? 'dark' : 'light'}
              value={`${
                totalVoted ? formatAmount(totalVoted?.dividedBy(totalBondedAmount).multipliedBy(100), 4) : ''
              }%`}
            />

            <ProposalIndex
              label="Quorum"
              labelColor={mainStore.isMobile ? 'dark' : 'light'}
              value={`${new BigNumber(mainStore.paramsData.info?.['tally.quorum']).shiftedBy(2).dp(0).toString()}%`}
            />
          </div>
        </div>

        <div className="font_caption_xs text-on_surface_variant_light">
          {toShort(totalVoted)} of {toShort(totalBondedAmount)} has voted
        </div>
      </div>

      <VotingBar data={proposal.tally} />

      <VotingTurnoutFlags data={proposal.tally} mobileColsCnt={mobileColsCnt} />
    </div>
  );
};

export default VotingVisualizer;
