import type {
  ChartingLibraryWidgetOptions,
  Overrides,
  StudyOverrides,
} from 'tradingview/charting_library/charting_library';

export const TV_BG_COLOR_HEX = '#181210';
export const TV_UP_COLOR_HEX = '#44A84E';
export const TV_DOWN_COLOR_HEX = '#EA5B52';
export const PRIMARY_COLOR_HEX = '#FFC780'; // primary
export const TV_OUTLINE_COLOR_HEX = '#36312B'; // opacity 30%
export const TV_AXIS_LABEL_COLOR_HEX = '#998D7F';

export enum TVStyles {
  CANDLE = 1,
  LINE = 2,
}

export const MAIN_SERIES_PROPERTIES_JSON = {
  style: TVStyles.LINE,
  esdShowDividends: true,
  esdShowSplits: true,
  esdShowEarnings: true,
  esdShowBreaks: false,
  esdFlagSize: 2,
  showCountdown: false,
  bidAsk: {
    visible: false,
    lineStyle: 0,
    lineWidth: 1,
    bidLineColor: TV_OUTLINE_COLOR_HEX,
    askLineColor: TV_OUTLINE_COLOR_HEX,
  },
  prePostMarket: {
    visible: true,
    lineStyle: 1,
    lineWidth: 1,
    preMarketColor: PRIMARY_COLOR_HEX,
    postMarketColor: PRIMARY_COLOR_HEX,
  },
  highLowAvgPrice: {
    highLowPriceLinesVisible: false,
    highLowPriceLabelsVisible: false,
    averageClosePriceLineVisible: false,
    averageClosePriceLabelVisible: false,
  },
  showInDataWindow: true,
  showPriceLine: true,
  priceLineWidth: 1,
  priceLineColor: '',
  baseLineColor: TV_OUTLINE_COLOR_HEX,
  showPrevClosePriceLine: false,
  prevClosePriceLineWidth: 1,
  prevClosePriceLineColor: TV_OUTLINE_COLOR_HEX,
  dividendsAdjustment: {},
  sessionId: 'regular',
  sessVis: false,
  statusViewStyle: { fontSize: 16, showExchange: true, showInterval: true, symbolTextSource: 'description' },
  candleStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    drawWick: true,
    drawBorder: true,
    borderColor: TV_OUTLINE_COLOR_HEX,
    borderUpColor: TV_UP_COLOR_HEX,
    borderDownColor: TV_DOWN_COLOR_HEX,
    wickColor: TV_OUTLINE_COLOR_HEX,
    wickUpColor: TV_UP_COLOR_HEX,
    wickDownColor: TV_DOWN_COLOR_HEX,
    barColorsOnPrevClose: false,
    drawBody: true,
  },
  hollowCandleStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    drawWick: true,
    drawBorder: true,
    borderColor: TV_OUTLINE_COLOR_HEX,
    borderUpColor: TV_UP_COLOR_HEX,
    borderDownColor: TV_DOWN_COLOR_HEX,
    wickColor: TV_OUTLINE_COLOR_HEX,
    wickUpColor: TV_UP_COLOR_HEX,
    wickDownColor: TV_DOWN_COLOR_HEX,
    drawBody: true,
  },
  haStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    drawWick: true,
    drawBorder: true,
    borderColor: TV_OUTLINE_COLOR_HEX,
    borderUpColor: TV_UP_COLOR_HEX,
    borderDownColor: TV_DOWN_COLOR_HEX,
    wickColor: TV_OUTLINE_COLOR_HEX,
    wickUpColor: TV_UP_COLOR_HEX,
    wickDownColor: TV_DOWN_COLOR_HEX,
    showRealLastPrice: false,
    barColorsOnPrevClose: false,
    inputs: {},
    inputInfo: {},
    drawBody: true,
  },
  barStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    barColorsOnPrevClose: false,
    dontDrawOpen: false,
    thinBars: true,
  },
  hiloStyle: {
    color: PRIMARY_COLOR_HEX,
    showBorders: true,
    borderColor: PRIMARY_COLOR_HEX,
    showLabels: true,
    labelColor: PRIMARY_COLOR_HEX,
    fontSize: 7,
    drawBody: true,
  },
  lineStyle: { color: '#ffc780', linestyle: 0, linewidth: 2, priceSource: 'close', styleType: 2 },
  areaStyle: {
    color1: 'transparent',
    color2: 'transparent',
    linecolor: PRIMARY_COLOR_HEX,
    linestyle: 0,
    linewidth: 2,
    priceSource: 'close',
    transparency: 100,
  },
  renkoStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    borderUpColor: TV_UP_COLOR_HEX,
    borderDownColor: TV_DOWN_COLOR_HEX,
    upColorProjection: TV_UP_COLOR_HEX,
    downColorProjection: TV_DOWN_COLOR_HEX,
    borderUpColorProjection: TV_UP_COLOR_HEX,
    borderDownColorProjection: TV_DOWN_COLOR_HEX,
    wickUpColor: TV_UP_COLOR_HEX,
    wickDownColor: TV_DOWN_COLOR_HEX,
    inputs: { source: 'close', sources: 'Close', boxSize: 3, style: 'ATR', atrLength: 14, wicks: true },
    inputInfo: {
      source: { name: 'source' },
      sources: { name: 'Source' },
      boxSize: { name: 'Box size' },
      style: { name: 'Style' },
      atrLength: { name: 'ATR length' },
      wicks: { name: 'Wicks' },
    },
  },
  pbStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    borderUpColor: TV_UP_COLOR_HEX,
    borderDownColor: TV_DOWN_COLOR_HEX,
    upColorProjection: TV_UP_COLOR_HEX,
    downColorProjection: TV_DOWN_COLOR_HEX,
    borderUpColorProjection: TV_UP_COLOR_HEX,
    borderDownColorProjection: TV_DOWN_COLOR_HEX,
    inputs: { source: 'close', lb: 3 },
    inputInfo: { source: { name: 'Source' }, lb: { name: 'Number of line' } },
  },
  kagiStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    upColorProjection: TV_UP_COLOR_HEX,
    downColorProjection: TV_DOWN_COLOR_HEX,
    inputs: { source: 'close', style: 'ATR', atrLength: 14, reversalAmount: 1 },
    inputInfo: {
      source: { name: 'Source' },
      style: { name: 'Style' },
      atrLength: { name: 'ATR length' },
      reversalAmount: { name: 'Reversal amount' },
    },
  },
  pnfStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    upColorProjection: TV_UP_COLOR_HEX,
    downColorProjection: TV_DOWN_COLOR_HEX,
    inputs: {
      sources: 'Close',
      reversalAmount: 3,
      boxSize: 1,
      style: 'ATR',
      atrLength: 14,
      oneStepBackBuilding: false,
    },
    inputInfo: {
      sources: { name: 'Source' },
      boxSize: { name: 'Box size' },
      reversalAmount: { name: 'Reversal amount' },
      style: { name: 'Style' },
      atrLength: { name: 'ATR length' },
      oneStepBackBuilding: { name: 'One step back building' },
    },
  },
  baselineStyle: {
    baselineColor: TV_OUTLINE_COLOR_HEX,
    topFillColor1: 'transparent',
    topFillColor2: 'transparent',
    bottomFillColor1: 'transparent',
    bottomFillColor2: 'transparent',
    topLineColor: TV_UP_COLOR_HEX,
    bottomLineColor: TV_DOWN_COLOR_HEX,
    topLineWidth: 2,
    bottomLineWidth: 2,
    priceSource: 'close',
    transparency: 50,
    baseLevelPercentage: 50,
  },
  rangeStyle: {
    upColor: TV_UP_COLOR_HEX,
    downColor: TV_DOWN_COLOR_HEX,
    thinBars: true,
    upColorProjection: TV_UP_COLOR_HEX,
    downColorProjection: TV_DOWN_COLOR_HEX,
    inputs: { range: 10, phantomBars: false },
    inputInfo: { range: { name: 'Range' }, phantomBars: { name: 'Phantom bars' } },
  },
  symbol: 'P1',
  shortName: '',
  timeframe: '',
  onWidget: false,
  interval: '1D',
  unitId: null,
  priceAxisProperties: { autoScale: true, alignLabels: true },
};

const PANE_PROPERTIES_JSON = {
  background: TV_BG_COLOR_HEX,
  backgroundGradientEndColor: TV_BG_COLOR_HEX,
  backgroundGradientStartColor: TV_BG_COLOR_HEX,
  backgroundType: 'solid',
  bottomMargin: 8,
  vertGridProperties: {
    color: 'transparent',
  },
  horzGridProperties: {
    color: 'transparent',
  },
  axisProperties: {
    alignLabels: true,
    autoScale: true,
    autoScaleDisabled: false,
    indexedTo100: false,
    isInverted: false,
    lockScale: false,
    log: false,
    logDisabled: false,
    percentage: false,
    percentageDisabled: false,
  },
};

const SCALES_PROPERTIES_JSON = {
  backgroundColor: TV_BG_COLOR_HEX,
  barSpacing: 6,
  fontSize: 12,
  lineColor: TV_OUTLINE_COLOR_HEX,
  scaleSeriesOnly: false,
  seriesLastValueMode: 1,
  showBidAskLabels: false,
  showCurrency: true,
  showFundamentalLastValue: false,
  showFundamentalNameLabel: false,
  showPrePostMarketPriceLabel: true,
  showSeriesLastValue: true,
  showSeriesPrevCloseValue: false,
  showStudyLastValue: false,
  showStudyPlotLabels: false,
  showSymbolLabels: false,
  showUnit: true,
  textColor: TV_AXIS_LABEL_COLOR_HEX,
};

const DATA_WINDOW_PROPERTIES_JSON = {
  background: TV_OUTLINE_COLOR_HEX,
  border: TV_OUTLINE_COLOR_HEX,
  font: 'Crescent',
  fontBold: false,
  fontItalic: false,
  fontSize: 10,
  transparency: 80,
  visible: true,
};

export const CHART_PROPERTIES_JSON = {
  mainSeriesProperties: MAIN_SERIES_PROPERTIES_JSON,
  paneProperties: PANE_PROPERTIES_JSON,
  scalesProperties: SCALES_PROPERTIES_JSON,
  dataWindowProperties: DATA_WINDOW_PROPERTIES_JSON,
};

/** @description library overrides prop */
const PANE_PROPERTIES: StudyOverrides = {
  'paneProperties.background': TV_BG_COLOR_HEX,
  'paneProperties.backgroundType': 'solid',
  'paneProperties.vertGridProperties.color': 'transparent',
  'paneProperties.horzGridProperties.color': 'transparent',
};

const ETC_PROPERTIES: StudyOverrides = {
  'scalesProperties.textColor': TV_AXIS_LABEL_COLOR_HEX,
  'scalesProperties.backgroundColor': TV_BG_COLOR_HEX,
  'chartEventsSourceProperties.breaks.color': TV_OUTLINE_COLOR_HEX,
  'chartEventsSourceProperties.breaks.style': 0,
  'dataWindowProperties.background': TV_OUTLINE_COLOR_HEX,
  'dataWindowProperties.border': TV_OUTLINE_COLOR_HEX,
  'dataWindowProperties.font': 'Crescent',
};

const MAIN_SERIES_PROPERTIES: Overrides = {
  'mainSeriesProperties.style': TVStyles.LINE,
  'mainSeriesProperties.bidAsk.askLineColor': TV_OUTLINE_COLOR_HEX,
  'mainSeriesProperties.bidAsk.bidLineColor': TV_OUTLINE_COLOR_HEX,
  'mainSeriesProperties.bidAsk.lineStyle': 0,
  'mainSeriesProperties.baseLineColor': TV_OUTLINE_COLOR_HEX,
  'mainSeriesProperties.baselineStyle.baselineColor': TV_OUTLINE_COLOR_HEX,
  'mainSeriesProperties.baselineStyle.topLineColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.baselineStyle.bottomLineColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.baselineStyle.topFillColor1': 'transparent',
  'mainSeriesProperties.baselineStyle.topFillColor2': 'transparent',
  'mainSeriesProperties.baselineStyle.bottomFillColor1': 'transparent',
  'mainSeriesProperties.baselineStyle.bottomFillColor2': 'transparent',
  'mainSeriesProperties.candleStyle.wickColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.candleStyle.wickUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.candleStyle.wickDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.candleStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.candleStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.candleStyle.borderColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.candleStyle.borderUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.candleStyle.borderDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.borderColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.borderUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.borderDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.wickColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.wickUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.hollowCandleStyle.wickDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.hiloStyle.borderColor': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.hiloStyle.color': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.hiloStyle.labelColor': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.kagiStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.kagiStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.pbStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.pbStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.pbStyle.borderDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.pbStyle.borderUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.pnfStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.pnfStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.rangeStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.rangeStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.renkoStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.renkoStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.renkoStyle.wickDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.renkoStyle.wickUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.renkoStyle.borderDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.renkoStyle.borderUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.barStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.barStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.haStyle.wickColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.haStyle.wickUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.haStyle.wickDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.haStyle.borderColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.haStyle.borderUpColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.haStyle.borderDownColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.haStyle.upColor': TV_UP_COLOR_HEX,
  'mainSeriesProperties.haStyle.downColor': TV_DOWN_COLOR_HEX,
  'mainSeriesProperties.lineStyle.color': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.lineStyle.linestyle': 0,
  'mainSeriesProperties.lineStyle.linewidth': 1,
  'mainSeriesProperties.lineStyle.priceSource': 'close',
  'mainSeriesProperties.areaStyle.color1': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.areaStyle.color2': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.areaStyle.linecolor': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.areaStyle.linestyle': 0,
  'mainSeriesProperties.areaStyle.linewidth': 1,
  'mainSeriesProperties.prePostMarket.postMarketColor': PRIMARY_COLOR_HEX,
  'mainSeriesProperties.prePostMarket.preMarketColor': PRIMARY_COLOR_HEX,
};

const VOLUME_PROPERTIES: StudyOverrides = {
  'volume.volume.color.0': PRIMARY_COLOR_HEX,
  'volume.volume.color.1': PRIMARY_COLOR_HEX,
  'volume.volume.transparency': 70,
};

export const TV_INITIAL_OVERRIDES = {
  ...PANE_PROPERTIES,
  ...ETC_PROPERTIES,
  ...MAIN_SERIES_PROPERTIES,
};

export const TV_OVERRIDES = {
  ...PANE_PROPERTIES,
  ...ETC_PROPERTIES,
};

export const TV_STUDIES_OVERRIDES = VOLUME_PROPERTIES;

type ChartingLibraryWidgetOptionsBase = Omit<
  ChartingLibraryWidgetOptions,
  'container' | 'datafeed' | 'interval' | 'locale'
>;

export const CHART_OPTIONS: ChartingLibraryWidgetOptionsBase = {
  library_path: '/charting_library/',
  charts_storage_url: 'https://saveload.tradingview.com',
  charts_storage_api_version: '1.1',
  client_id: 'tradingview.com',
  user_id: 'public_user_id',
  fullscreen: false,
  autosize: true,
  overrides: TV_INITIAL_OVERRIDES,
  studies_overrides: TV_STUDIES_OVERRIDES,
  custom_css_url: 'css/custom_v3.css',
  toolbar_bg: TV_BG_COLOR_HEX,
  /** @description custom.css file is dependant on this theme selector, so understand it is dangerous to change this alone */
  theme: 'Dark',
};

export const TV_LOCAL_STORAGE_KEYS = {
  MAIN_SERIES_PROPERTIES: 'tradingview.chartproperties.mainSeriesProperties',
  CHART_PROPERTIES: 'tradingview.chartproperties',
  INTERVAL: 'tradingview.chart.interval',
};

export const DATA_FEED_URL = process.env.REACT_APP_CHART_API_ENDPOINT || '';
