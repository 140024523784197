import { IoMdClose, IoIosMore, IoMdArrowRoundForward } from 'react-icons/io';
import {
  MdOutlineContentCopy,
  MdOutlineSearch,
  MdExpandMore,
  MdExpandLess,
  // MdArrowForwardIos,
  MdSwapHoriz,
  MdNavigateNext,
  MdArrowDownward,
  MdArrowUpward,
  MdSync,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdRemove,
  MdAdd,
  MdWaterDrop,
  MdOutlineNotifications,
  MdCelebration,
  MdArrowDropDown,
  MdHelpOutline,
  MdCancel,
  MdAccessTime,
  MdOutlineChangeCircle,
  MdArrowForward,
  MdWarning,
  MdInfo,
  MdTipsAndUpdates,
  MdDangerous,
  MdVerifiedUser,
  MdArrowRight,
  MdSyncAlt,
  MdStar,
  MdTune,
  MdArrowDropUp,
  MdOutlineDelete,
  MdOpenInNew,
  MdArrowBackIosNew,
  MdRefresh,
  MdMoreHoriz,
  MdHome,
  MdAccountCircle,
  MdDone,
  MdError,
  MdOutlineCheckCircle,
} from 'react-icons/md';
import { BiFilter, BiLoader, BiTrash } from 'react-icons/bi';
import { FaRegCheckCircle, FaBan, FaTelegramPlane } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus, AiOutlineLoading3Quarters, AiOutlineSwap } from 'react-icons/ai';
import { RiArrowLeftRightLine, RiZzzLine } from 'react-icons/ri';
import { IconContext } from 'react-icons';
import { GiReceiveMoney, GiToken } from 'react-icons/gi';
import { FiBookOpen } from 'react-icons/fi';
import { SiDiscord, SiGitbook } from 'react-icons/si';
import { BsMedium, BsTwitter, BsGithub } from 'react-icons/bs';
import { CgFileDocument } from 'react-icons/cg';
import { IoCaretUpSharp, IoCaretDownSharp } from 'react-icons/io5';
import React from 'react';
import SparklesIconSvg from 'components/svgs/SparklesIconSvg';
import CommonWealthIconSvg from 'components/svgs/CommonWealthIconSvg';
import CoinGeckoIconSvg from 'components/svgs/CoinGeckoIconSvg';
import MoonLoaderGif from 'resources/images/moon_loader.gif';
import TradeIconSvg from 'components/svgs/TradeIconSvg';
import FarmIconSvg from 'components/svgs/FarmIconSvg';
import PortfolioIconSvg from 'components/svgs/PortfolioIconSvg';
import GovIconSvg from 'components/svgs/GovIconSvg';
import StakingIconSvg from 'components/svgs/StakingIconSvg';
import AirdropIconSvg from 'components/svgs/AirdropIconSvg';
import EarnIconSvg from 'components/svgs/EarnIconSvg';

export type IconType =
  | 'close'
  | 'copy'
  | 'open-in-new'
  | 'noti'
  | 'more'
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'forward'
  | 'search'
  | 'swap'
  | 'plus'
  | 'minus'
  | 'loading'
  | 'loadingSecondary'
  | 'time'
  | 'receivemoney'
  | 'sleep'
  | 'docs'
  | 'medium'
  | 'twitter'
  | 'discord'
  | 'telegram'
  | 'terms'
  | 'token'
  | 'github'
  | 'gitbook'
  | 'cancel'
  | 'aiSwap'
  | 'trash'
  | 'expandforward'
  | 'changeCircle'
  | 'ibc'
  | 'sortAsc'
  | 'sparkles'
  | 'filter'
  | 'reset'
  | 'subtract'
  | 'add'
  | 'up'
  | 'down'
  | 'waterDrop'
  | 'commonWealth'
  | 'coinGecko'
  | 'congrats'
  /** @wip design-system */
  | 'arrow-drop-down'
  | 'arrow-drop-up'
  | 'arrow-downward'
  | 'arrow-upward'
  | 'arrow-forward'
  | 'arrow-back-ios'
  | 'question'
  | 'swap-horiz'
  | 'tips-and-updates'
  | 'dangerous'
  | 'verified-user'
  | 'expandmore'
  | 'expandless'
  | 'arrow-right'
  | 'checked'
  | 'unchecked'
  | 'sync-alt'
  | 'star'
  | 'tune'
  | 'delete'
  | 'loader'
  | 'more-horiz'
  | 'home'
  | 'trade'
  | 'farm'
  | 'portfolio'
  | 'gov'
  | 'staking'
  | 'airdrop'
  | 'earn'
  | 'account-circle'
  | 'done'
  | 'check_circle';

export const Icons: { [key in IconType]: React.ElementType } = {
  close: IoMdClose,
  copy: MdOutlineContentCopy,
  'open-in-new': MdOpenInNew,
  // noti: FaRegBell,
  noti: MdOutlineNotifications,
  more: IoIosMore,
  success: FaRegCheckCircle,
  forward: IoMdArrowRoundForward,
  search: MdOutlineSearch,
  swap: RiArrowLeftRightLine,
  plus: AiOutlinePlus,
  minus: AiOutlineMinus,
  loading: AiOutlineLoading3Quarters,
  loadingSecondary: BiLoader,
  time: MdAccessTime,
  receivemoney: GiReceiveMoney,
  sleep: RiZzzLine,
  docs: FiBookOpen,
  medium: BsMedium,
  twitter: BsTwitter,
  discord: SiDiscord,
  telegram: FaTelegramPlane,
  terms: CgFileDocument,
  github: BsGithub,
  gitbook: SiGitbook,
  token: GiToken,
  cancel: MdCancel,
  aiSwap: AiOutlineSwap,
  trash: BiTrash,
  // expandforward: MdArrowForwardIos,
  expandforward: MdNavigateNext,
  changeCircle: MdOutlineChangeCircle,
  ibc: MdSwapHoriz,
  // sortAsc: MdArrowDownward,
  // sortDesc: MdArrowUpward,
  sortAsc: MdArrowUpward,
  sparkles: SparklesIconSvg,
  filter: BiFilter,
  reset: MdSync,
  subtract: MdRemove,
  up: IoCaretUpSharp,
  down: IoCaretDownSharp,
  waterDrop: MdWaterDrop,
  commonWealth: CommonWealthIconSvg,
  coinGecko: CoinGeckoIconSvg,
  congrats: MdCelebration,
  /** @wip design-system */
  'arrow-drop-down': MdArrowDropDown,
  'arrow-drop-up': MdArrowDropUp,
  'arrow-downward': MdArrowDownward,
  'arrow-upward': MdArrowUpward,
  'arrow-forward': MdArrowForward,
  'arrow-back-ios': MdArrowBackIosNew,
  question: MdHelpOutline,
  'swap-horiz': MdSwapHoriz,
  'tips-and-updates': MdTipsAndUpdates,
  warning: MdWarning,
  info: MdInfo,
  dangerous: MdDangerous,
  'verified-user': MdVerifiedUser,
  expandmore: MdExpandMore,
  expandless: MdExpandLess,
  'arrow-right': MdArrowRight,
  checked: MdCheckBox,
  unchecked: MdCheckBoxOutlineBlank,
  add: MdAdd,
  'sync-alt': MdSyncAlt,
  star: MdStar,
  tune: MdTune,
  delete: MdOutlineDelete,
  loader: MdRefresh,
  'more-horiz': MdMoreHoriz,
  home: MdHome,
  trade: TradeIconSvg,
  farm: FarmIconSvg,
  portfolio: PortfolioIconSvg,
  gov: GovIconSvg,
  staking: StakingIconSvg,
  airdrop: AirdropIconSvg,
  earn: EarnIconSvg,
  'account-circle': MdAccountCircle,
  done: MdDone,
  error: MdError,
  check_circle: MdOutlineCheckCircle,
};

export type IconSize = '12px' | '14px' | '16px' | '20px' | '24px' | '28px';

const ICON_SIZE_DICT: { [key in IconSize]: string } = {
  '12px': 'w-3 h-3',
  '14px': 'w-[0.875rem] h-[0.875rem]',
  '16px': 'w-4 h-4',
  '20px': 'w-5 h-5',
  '24px': 'w-6 h-6',
  '28px': 'w-7 h-7',
};

type IconProps = {
  type: IconType;
  size: IconSize;
  className?: string;
};

function Icon({ type, size, className = '' }: IconProps) {
  const Icon = Icons[type];

  return type === 'loader' ? (
    <img src={MoonLoaderGif} alt="Rotating Moon" className={`${ICON_SIZE_DICT[size]} ${className}`} />
  ) : (
    <IconContext.Provider
      value={{
        size: '100%',
        className: `${ICON_SIZE_DICT[size]} ${className}`,
      }}
    >
      <Icon className={`${ICON_SIZE_DICT[size]} ${className}`} />
    </IconContext.Provider>
  );
}

export default Icon;
