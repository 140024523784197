import { useMainStore } from 'provider/mainContext';
import { observer } from 'mobx-react-lite';
import { AminoMsg, Coin } from '@cosmjs/amino';
import { QueryClient, setupBankExtension } from '@cosmjs/stargate';
import { Tendermint34Client } from '@cosmjs/tendermint-rpc';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { queryClient } from 'common/tendermint/liquidity';
import { Registry } from '@cosmjs/proto-signing';
import { defaultRegistryTypes as defaultStargateTypes, SigningStargateClient, StargateClient } from '@cosmjs/stargate';
import PoolToken from 'resources/svgs/gdex-pooltoken.svg';
import { MsgWithdrawWithinBatch } from 'common/tendermint/liquidity/types/tendermint/liquidity/v1beta1/tx';
import BigNumber from 'bignumber.js';
import { AminoTypes } from '@cosmjs/stargate';
import { getWalletAddress } from 'hooks/useConnectedWallet';
import { isInstalled } from '@cosmostation/extension-client';
import _m0 from 'protobufjs/minimal';
import { addChain, getSupportedChains } from '@cosmostation/extension-client/tendermint';
import { INFOBOX_TEXTS } from 'COMMON_VARIABLES';
import { Int53 } from '@cosmjs/math';
import { requestAccount } from '@cosmostation/extension-client/tendermint';
import { encodePubkey } from '@cosmjs/proto-signing';
import { encodeSecp256k1Pubkey } from '@cosmjs/amino';
import { makeAuthInfoBytes } from '@cosmjs/proto-signing';
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { fromBase64 } from '@cosmjs/encoding';
import { makeSignDoc } from '@cosmjs/amino';
import PageTitleArea from 'components/layout/PageTitleArea';
import H4 from 'components/texts/H4';
import InfoBox from 'components/infobox';
import Table from 'components/table/Table';
import Button from 'components/buttons/Button';
import { getTxChainGasPrice } from 'common/utils';

const COSMOS_RPC_API_URL = `https://cosmos.crescent.network:26657`;
const COSMOS_LCD_API_URL = `https://cosmos.crescent.network:1317`;

interface AminoMsgWithdrawWithinBatch extends AminoMsg {
  readonly type: 'liquidity/MsgWithdrawWithinBatch';
  readonly value: {
    withdrawer_address: string;
    pool_id: string;
    pool_coin: Coin | undefined;
  };
}

const aminoTypes = new AminoTypes({
  additions: {
    '/tendermint.liquidity.v1beta1.MsgWithdrawWithinBatch': {
      aminoType: 'liquidity/MsgWithdrawWithinBatch',
      toAmino: ({
        withdrawerAddress,
        poolId,
        poolCoin,
      }: MsgWithdrawWithinBatch): AminoMsgWithdrawWithinBatch['value'] => ({
        withdrawer_address: withdrawerAddress,
        pool_id: String(poolId),
        pool_coin: poolCoin,
      }),
      fromAmino: ({
        withdrawer_address,
        pool_id,
        pool_coin,
      }: AminoMsgWithdrawWithinBatch['value']): MsgWithdrawWithinBatch => ({
        withdrawerAddress: withdrawer_address,
        poolId: Number(pool_id),
        poolCoin: pool_coin,
      }),
    },
  },
  prefix: 'cosmos',
});

const txRawEncoder = {
  encode(message: TxRaw, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bodyBytes.length !== 0) {
      writer.uint32(10).bytes(message.bodyBytes);
    }
    if (message.authInfoBytes.length !== 0) {
      writer.uint32(18).bytes(message.authInfoBytes);
    }
    for (const v of message.signatures) {
      writer.uint32(26).bytes(v!);
    }
    return writer;
  },
};

const cosmosClient = {
  msgWithdrawWithinBatch: (data) => ({
    typeUrl: '/tendermint.liquidity.v1beta1.MsgWithdrawWithinBatch',
    value: MsgWithdrawWithinBatch.fromPartial(data),
  }),
};

/** @component GdexPage */
type GDEXBalanceTableRow = {
  poolTokenLabel: JSX.Element;
  balanceLabel: JSX.Element;
  withdrawButton: JSX.Element;
};

const GdexPage = () => {
  const mainStore = useMainStore();
  const [balances, setBalances] = useState<readonly any[]>([]);
  const [pools, setPools] = useState<any[]>([]);
  const [address, setAddress] = useState<string>('');

  const poolBalances = useMemo(() => {
    return balances.map((balance) => {
      let pool_info = pools.filter((pool) => pool.pool_coin_denom === balance.denom)[0];
      return {
        balance,
        pool_info,
      };
    });
  }, [pools, balances]);

  useEffect(() => {
    if (mainStore.wallet.type === 'keplr') {
      getWalletAddress(mainStore.wallet.type, 'cosmoshub-4').then((result) => setAddress(result));
    } else if (mainStore.wallet.type === 'cosmostation') {
      addCosmosChain();
      getWalletAddress(mainStore.wallet.type, 'cosmos').then((result) => setAddress(result));
    } else {
      setAddress('');
    }
  }, [mainStore.wallet.address]);

  useEffect(() => {
    getPoolInfo();
  }, []);

  useEffect(() => {
    getBalances();
  }, [address]);

  const addCosmosChain = async () => {
    const supportedChains = await getSupportedChains();
    const allSupportedChains = [...supportedChains.official, ...supportedChains.unofficial];
    if (!allSupportedChains.includes('cosmoshub-4')) {
      try {
        await addChain({
          chainId: 'cosmoshub-4',
          chainName: 'cosmoshub-4',
          addressPrefix: 'cosmos',
          baseDenom: 'uatom',
          displayDenom: 'atom',
          restURL: COSMOS_LCD_API_URL,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getBalances = useCallback(async () => {
    if (address) {
      const tmClient = await Tendermint34Client.connect(COSMOS_RPC_API_URL);
      const client = QueryClient.withExtensions(tmClient, setupBankExtension);

      client.bank.allBalances(address).then((coins) => {
        setBalances(coins.filter((item) => item.denom.includes('pool')));
      });
    } else {
      setBalances([]);
    }
  }, [address]);

  const getPoolInfo = async () => {
    let qc = await queryClient({ addr: COSMOS_LCD_API_URL });
    let pools = await qc.queryLiquidityPools();
    if (pools?.data?.pools?.length && pools?.data?.pools?.length > 0) {
      setPools(pools.data.pools);
    }
  };

  const withdraw = useCallback(
    async (item) => {
      try {
        if (address) {
          const defaultRegistry = new Registry(defaultStargateTypes);
          defaultRegistry.register('/tendermint.liquidity.v1beta1.MsgWithdrawWithinBatch', MsgWithdrawWithinBatch);
          let msg = cosmosClient.msgWithdrawWithinBatch({
            withdrawerAddress: address,
            poolId: item.pool_info.id,
            poolCoin: { denom: item.balance.denom, amount: item.balance.amount },
          });
          const fee = {
            gas: '500000',
            amount: [
              {
                amount: '0',
                denom: 'uatom',
              },
            ],
          };

          if (mainStore.wallet.type === 'keplr' && window.getOfflineSignerOnlyAmino) {
            const signer = await window.getOfflineSignerOnlyAmino('cosmoshub-4');
            const cosmJS = await SigningStargateClient.connectWithSigner(COSMOS_RPC_API_URL, signer, {
              registry: defaultRegistry,
              aminoTypes: aminoTypes,
              prefix: 'cosmos',
            });
            const response = await cosmJS.signAndBroadcast(address, [msg], fee, '');
            console.log(response);
          } else if (mainStore.wallet.type === 'cosmostation' && isInstalled()) {
            const cosmJS = await StargateClient.connect(COSMOS_RPC_API_URL);
            const signedTx = await signWithCosmostion(
              'cosmos',
              address,
              [msg],
              fee,
              mainStore.chainsData.info['cosmoshub-4']?.feeCurrencies[0]?.gasPriceStep ??
                mainStore.chainsData.info['cosmoshub-4']?.gasPriceStep ?? {
                  low: 0,
                  average: 0,
                  high: 0,
                },
              // mainStore.chainsData.info['cosmoshub-4']?.gasPriceStep,
              '',
              defaultRegistry,
              aminoTypes,
              cosmJS
            );
            const encodedTxRaw = txRawEncoder.encode(signedTx).finish();
            const response = await cosmJS.broadcastTx(encodedTxRaw);
            console.log(response);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        getBalances();
      }
    },
    [address, mainStore.wallet.type, mainStore.chainsData.info, getBalances]
  );

  const signWithCosmostion = async (
    chainName,
    address,
    msgs,
    fee,
    gasRateStep: {
      low: number;
      average: number;
      high: number;
    },
    memo,
    registry,
    aminoTypes,
    client: StargateClient
  ) => {
    const chainId = await client.getChainId();
    const { accountNumber, sequence } = await client.getSequence(address);

    const aminoMsgs = msgs.map((msg) => aminoTypes.toAmino(msg));
    const signDoc = makeSignDoc(aminoMsgs, fee, chainId, '', accountNumber, sequence);
    // @ts-ignore
    const signed = await signAmino(chainName, signDoc, {
      fee: true,
      gasRate: {
        average: `${gasRateStep.high}`,
        low: `${gasRateStep.average}`,
        tiny: `${gasRateStep.low}`,
      },
    });
    const signedTxBody = {
      messages: signed.signed_doc.msgs.map((msg) => aminoTypes.fromAmino(msg)),
      memo: signed.signed_doc.memo,
    };
    const signedTxBodyEncodeObject = {
      typeUrl: '/cosmos.tx.v1beta1.TxBody',
      value: signedTxBody,
    };

    const accountInfo = await requestAccount(chainName);
    const pubkey = encodePubkey(encodeSecp256k1Pubkey(accountInfo.publicKey));

    const signedTxBodyBytes = registry.encode(signedTxBodyEncodeObject);
    const signedGasLimit = Int53.fromString(signed.signed_doc.fee.gas).toNumber();
    const signedSequence = Int53.fromString(signed.signed_doc.sequence).toNumber();
    const signedAuthInfoBytes = makeAuthInfoBytes(
      [{ pubkey: pubkey, sequence: signedSequence }],
      signed.signed_doc.fee.amount,
      signedGasLimit,
      127
    );

    return TxRaw.fromPartial({
      bodyBytes: signedTxBodyBytes,
      authInfoBytes: signedAuthInfoBytes,
      signatures: [fromBase64(signed.signature)],
    });
  };

  /** @desc tmp ui */
  const tableRows = useMemo<GDEXBalanceTableRow[]>(() => {
    return poolBalances.map((item) => {
      const poolTokenLabel = (
        <div className="flex items-center gap-x-2">
          <div
            className="rounded-full overflow-hidden"
            style={{
              background:
                'radial-gradient(at 16.67% 16.67%, rgb(164, 250, 255) 0%, rgb(232, 140, 254) 49%, rgb(40, 106, 122) 82%)',
            }}
          >
            <img src={PoolToken} alt="" />
          </div>

          <div className="font_title_m text-on_surface">Gravity {item.pool_info.id}</div>
        </div>
      );

      const balanceLabel = (
        <div className="font_data_number_m text-white">
          {new BigNumber(item.balance.amount).shiftedBy(-6).toString()}
          {/* G{item.pool_info.id} */}
        </div>
      );

      const withdrawButton = <Button size="md" label="Withdraw" onClick={() => withdraw(item)} />;

      return {
        poolTokenLabel,
        balanceLabel,
        withdrawButton,
      };
    });
  }, [poolBalances, withdraw]);

  return (
    <div className="pb-20 md:pb-40">
      <PageTitleArea title="GDEX Withdraw" />

      <main role="main" className="w-full max-w-content m-auto px-4 pt-5 space-y-5 md:px-0 md:pt-[3rem] md:space-y-6">
        <H4>Your Pool Tokens on GDEX</H4>

        <InfoBox>{INFOBOX_TEXTS.GDEX}</InfoBox>

        <Table<GDEXBalanceTableRow>
          noDataLabel="No pool token"
          rows={tableRows}
          fields={[
            {
              label: 'Token',
              value: 'poolTokenLabel',
              type: 'jsx',
              sortDisabled: true,
              widthRatio: 20,
            },
            {
              label: 'Balance',
              value: 'balanceLabel',
              type: 'jsx',
              sortDisabled: true,
              align: 'right',
            },
            {
              label: '',
              value: 'withdrawButton',
              type: 'jsx',
              sortDisabled: true,
              widthRatio: 24,
              align: 'right',
            },
          ]}
        />
      </main>
    </div>
  );
};

export default observer(GdexPage);
