import { useCallback, useEffect, useMemo, useState } from 'react';
import { getKeplrAddressInfoByChainId } from 'common/signingClients/utils/keplrUtils';
import { useMainStore } from 'provider/mainContext';
import { requestAccount } from '@cosmostation/extension-client/tendermint';
import { Wallet, WalletType } from 'types/wallet';
import type { InfoChain } from 'types/chain';
import { getChainWallet } from 'connection/utils';

export default function useConnectedWallet(chainId: string) {
  const store = useMainStore();

  const chainInfo = useMemo<InfoChain | undefined>(
    () => store.chainsData.info[chainId],
    [chainId, store.chainsData.info]
  );

  const [connectedWallet, setConnectedWallet] = useState<Wallet | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setupConnectedWallet = useCallback(async () => {
    if (!chainInfo) return null;
    if (!store.wallet.isActive || !store.wallet.type) return null;

    const options =
      store.wallet.type === WalletType.WALLETCONNECT
        ? {
            walletType: store.wallet.type,
            connector: store.wallet.connector,
          }
        : {
            walletType: store.wallet.type,
          };

    if (connectedWallet === null) setIsLoading(true);

    const _connectedWallet = await getChainWallet(chainInfo, options);
    setConnectedWallet(_connectedWallet);

    setIsLoading(false);
  }, [chainInfo, store.wallet]);

  useEffect(() => {
    setupConnectedWallet();
  }, [store.wallet, chainInfo]);

  return { connectedWallet, isLoading };
}

export const getWalletAddress = async (walletType: WalletType, chainId: string) => {
  try {
    if (walletType === 'keplr') {
      const accountInfo = await getKeplrAddressInfoByChainId(chainId);
      if (accountInfo) {
        return accountInfo.bech32Address;
      }
    } else if (walletType === 'cosmostation') {
      const accountInfo = await requestAccount(chainId);
      if (accountInfo) {
        return accountInfo.address;
      }
    }
  } catch (e) {
    // console.log('error', e);
  }
  return '';
};
