const NewsBannerLinkWidget = ({
  href,
  src,
  alt,
}: {
  href: string;
  src: React.ImgHTMLAttributes<HTMLImageElement>['src'];
  alt: string;
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className="h-fit bg-transparent flex justify-center items-center rounded-8px overflow-hidden"
      rel="noreferrer"
    >
      {/* jpg width 1236(412 * @3) */}
      <img src={src} alt={alt} className="object-cover w-full" />
    </a>
  );
};

export default NewsBannerLinkWidget;
