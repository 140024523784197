import { useMemo } from 'react';
import NA from './NA';
import { getDecimalSeperator } from 'common/utils';

export type NumberTextSize = 'xs' | 'sm' | 'md' | 'lg';
export type NumberTextColor = 'plain' | 'dark' | 'primary' | 'liquid' | 'deposit' | 'plain-mono' | 'red' | 'green';
type NumberTextStatus = 'normal' | 'warning' | 'error';

/** @desc size */
const NUMBER_TEXT_SIZE_DICT: {
  [key in NumberTextSize]: {
    value: string;
    fractions: string;
    unit: string;
    gap: string;
    subValue: string;
  };
} = {
  xs: {
    value: 'font_data_number_xs',
    fractions: 'font_data_number_xs',
    unit: 'font_caption_s',
    gap: 'gap-x-0.5',
    subValue: 'font_caption_number_xs',
  },
  sm: {
    value: 'font_data_number_s',
    fractions: 'font_data_number_xs',
    unit: 'font_caption_m',
    gap: 'gap-x-1',
    subValue: 'font_caption_number_s',
  },
  md: {
    value: 'font_data_number_m',
    fractions: 'font_data_number_xs',
    unit: 'font_caption_m',
    gap: 'gap-x-1',
    subValue: 'font_caption_number_m',
  },
  lg: {
    value: 'font_data_number_l',
    fractions: 'font_data_number_xs',
    unit: 'font_caption_l',
    gap: 'gap-x-1',
    subValue: 'font_caption_number_m',
  },
};

/** @desc color */
const NUMBER_TEXT_COLOR_DICT: {
  [key in NumberTextColor]: { [key in NumberTextStatus]: { value: string; unit: string } };
} = {
  plain: {
    normal: { value: 'text-on_surface', unit: 'text-on_surface_variant_light' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  dark: {
    normal: { value: 'text-on_surface_variant_dark', unit: 'text-on_surface_variant_dark' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  'plain-mono': {
    normal: { value: 'text-on_surface', unit: 'text-on_surface' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  primary: {
    normal: { value: 'text-primary', unit: 'text-on_surface_variant_light' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  liquid: {
    normal: { value: 'text-liquid', unit: 'text-on_surface_variant_light' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  deposit: {
    normal: { value: 'text-gov_deposit', unit: 'text-on_surface_variant_light' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  red: {
    normal: { value: 'text-semantic_red', unit: 'text-on_surface_variant_light' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
  green: {
    normal: { value: 'text-semantic_green', unit: 'text-on_surface_variant_light' },
    warning: { value: 'text-semantic_yellow', unit: 'text-semantic_yellow' },
    error: { value: 'text-semantic_red', unit: 'text-semantic_red' },
  },
};

const decimalSeperator = getDecimalSeperator() ?? '.';

type NumberTextProps = {
  value: string;
  unit?: string;
  subValue?: string | JSX.Element;
  subUnit?: string;
  color?: NumberTextColor;
  size?: NumberTextSize;
  status?: NumberTextStatus;
  align?: 'left' | 'right';
  xsDecimal?: boolean;
};

const NumberText = ({
  value,
  unit,
  subValue,
  subUnit,
  color = 'plain',
  size = 'md',
  status = 'normal',
  align = 'left',
  xsDecimal = false,
}: NumberTextProps) => {
  const values = useMemo<{ normal: string; fractions?: string }>(() => {
    if (!xsDecimal) return { normal: value };

    const [normal, fractions] = value.split(decimalSeperator);
    return { normal, fractions };
  }, [value, xsDecimal]);

  if (value === '') return <NA />;

  return (
    <div className={`flex flex-col ${align === 'left' ? 'items-start' : 'items-end'}`}>
      <div className={`flex items-baseline ${NUMBER_TEXT_SIZE_DICT[size].gap}`}>
        {/* value */}
        <div className="flex items-baseline break-all">
          <div className={`${NUMBER_TEXT_COLOR_DICT[color][status].value} ${NUMBER_TEXT_SIZE_DICT[size].value}`}>
            {values.normal}
          </div>

          {values.fractions && (
            <div className={`${NUMBER_TEXT_COLOR_DICT[color][status].value} ${NUMBER_TEXT_SIZE_DICT[size].fractions}`}>
              {decimalSeperator}
              {values.fractions}
            </div>
          )}
        </div>

        {/* unit */}
        {unit && (
          <div className={`${NUMBER_TEXT_COLOR_DICT[color][status].unit} ${NUMBER_TEXT_SIZE_DICT[size].unit}`}>
            {unit}
          </div>
        )}
      </div>

      {/* subValue */}
      {subValue && (
        <div
          className={`flex items-baseline gap-x-0.5 ${
            color === 'plain-mono' ? 'text-on_surface' : 'text-on_surface_variant_dark'
          }`}
        >
          <div className={`${NUMBER_TEXT_SIZE_DICT[size].subValue} break-all`}>{subValue}</div>{' '}
          <div className="font_caption_xs">{subUnit}</div>
        </div>
      )}
    </div>
  );
};

export default NumberText;
