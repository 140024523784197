import { formatAmount, formatUSD } from 'common/utils';
import Coin from 'components/coins/Coin';
import NumberText from 'components/texts/NumberText';
import { useCallback } from 'react';
import type { RewardPlanDetail } from 'types/pool';

type RewardsPlanItemUnit = 'coin' | 'usd';

const RewardsPlanItem = ({
  title,
  plans,
  unit = 'coin',
}: {
  title: string;
  plans: RewardPlanDetail[];
  unit?: RewardsPlanItemUnit;
}) => {
  const getEndStrings = useCallback((end: number): { date: string; time?: string } => {
    const strings = String(new Date(end * 10 ** 3).toISOString())
      .split('.')[0]
      ?.split('T');

    return {
      date: strings[0],
      time: strings[1],
    };
  }, []);

  const isEndSoon = useCallback((end: number): boolean => {
    // const isPlanWarning = !new BigNumber(
    //   new BigNumber(new BigNumber(reward.end).shiftedBy(3)).minus(new Date().getTime())
    // ).gt(17.28e7);
    return false;
  }, []);

  return (
    <section className="text-on_surface space-y-2 px-3 py-2">
      <header className="font_caption_s text-on_surface_variant_light">{title}</header>

      <div className="space-y-3">
        {plans.map((plan, index) => {
          return (
            <div key={index} className="space-y-0.5">
              <div className="flex items-center justify-start gap-x-1">
                {plan.asset && <Coin asset={plan.asset} size="16px" />}

                {unit === 'coin' ? (
                  <NumberText
                    size="md"
                    value={`≈ ${formatAmount(plan.dailyReceiveAmount, 0)}`}
                    unit={plan.asset?.ticker}
                  />
                ) : (
                  <div className="flex items-baseline gap-x-1">
                    <NumberText size="md" value={formatUSD(plan.dailyReceiveAmountUSD)} />

                    <div className="inline-flex items-baseline text-on_surface_variant_light font_caption_s">
                      (<div className="font_caption_number_s">{formatAmount(plan.dailyReceiveAmount, 6)}</div>
                      <div className="ml-0.5">{plan.asset.ticker}</div>)
                    </div>
                  </div>
                )}
              </div>

              <div className={`flex items-baseline gap-x-1 ${isEndSoon(plan.plan.end) ? 'text-semantic_red' : ''}`}>
                <div className="font_caption_s">until</div>
                <div className="font_caption_number_s">{getEndStrings(plan.plan.end).date}</div>
                <div className="font_caption_number_s">{getEndStrings(plan.plan.end).time}</div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default RewardsPlanItem;
