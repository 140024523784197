import { useCallback, useEffect } from 'react';
import { useMainStore } from 'provider/mainContext';
import { Wallet, WalletType } from 'types/wallet';
import {
  connectWallet,
  isWalletInstalled,
  logWallet,
  onWalletUninstalled,
  subscribeWalletChange,
} from 'connection/utils';

const WALLET_LOCAL_STORAGE_KEYS = {
  AUTO_WALLET_TYPE: 'autoLogin',
  IS_AUTO_LOGIN: 'isAutoLogin',
};

const useConnect = () => {
  const store = useMainStore();

  const disconnect = useCallback(async () => {
    if (window.navigator.userAgent.includes('Cosmostation') || store.wallet.type === WalletType.WALLETCONNECT) {
    }

    store.setWallet({
      type: null,
      isActive: false,
      name: '',
      address: '',
    });
    localStorage.removeItem(WALLET_LOCAL_STORAGE_KEYS.AUTO_WALLET_TYPE);
  }, []);

  const connectTo = useCallback(
    async (walletType: WalletType): Promise<Wallet | null> => {
      console.log('%c Connect Wallet.', 'color: yellow; font-weight: bold');

      if (!isWalletInstalled(walletType)) {
        onWalletUninstalled(walletType);
        return null;
      }

      const isAutoLogin = localStorage.getItem(WALLET_LOCAL_STORAGE_KEYS.IS_AUTO_LOGIN) === 'true';
      const connectedWallet = await connectWallet(walletType, isAutoLogin);

      if (!connectedWallet) {
        disconnect();
        return null;
      }

      store.setWallet({
        // ...store.wallet,
        ...connectedWallet,
      });

      localStorage.setItem(WALLET_LOCAL_STORAGE_KEYS.AUTO_WALLET_TYPE, walletType);
      localStorage.setItem(WALLET_LOCAL_STORAGE_KEYS.IS_AUTO_LOGIN, 'true');

      logWallet(walletType);

      return connectedWallet;
    },
    [disconnect]
  );

  const tryConnection = (walletType: WalletType): Promise<Wallet | null> => {
    //@ts-ignore
    if (window?.aap) {
      //@ts-ignore
      window?.aap({ pixelKey: '3041578584258872492', pg: 14908 });
    }
    return connectTo(walletType);
  };

  useEffect(() => {
    window.onload = () => {
      const key = WALLET_LOCAL_STORAGE_KEYS.AUTO_WALLET_TYPE;
      const walletType = (localStorage.getItem(key) ?? null) as WalletType | null;

      if (walletType && Object.values(WalletType).includes(walletType)) {
        tryConnection(walletType);
      }
    };
  }, []);

  useEffect(() => {
    if (
      store.wallet.type === WalletType.COSMOSTATION ||
      store.wallet.type === WalletType.KEPLR ||
      store.wallet.type === WalletType.LEAP
    ) {
      subscribeWalletChange(store.wallet.type, () => {
        if (store.wallet.type) connectTo(store.wallet.type);
      });
    }
  }, [store.wallet.type]);

  return { connect: tryConnection, disconnect };
};

export default useConnect;
