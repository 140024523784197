type PopoverSize = '2xs' | 'xs' | 'sm' | 'md' | 'full';
type PopoverPadding = '12px' | '0';

const POPOVER_MD_WIDTH_DICT: { [key in PopoverSize]: string } = {
  '2xs': 'md:w-[220px]',
  xs: 'md:w-[300px]',
  sm: 'md:w-[380px]',
  md: 'md:w-[420px]',
  full: '',
};

const POPOVER_PADDING_DICT: { [key in PopoverPadding]: string } = {
  '12px': 'px-3 pt-3 pb-4',
  '0': 'p-0',
};

type WidgetProps = {
  children: React.ReactNode;
  size?: PopoverSize;
  padding?: PopoverPadding;
  className?: string;
};

const Popover = ({ children, size = 'md', padding = '0', className }: WidgetProps) => {
  return (
    <section
      className={`relative w-full ${POPOVER_MD_WIDTH_DICT[size]} ${POPOVER_PADDING_DICT[padding]} bg_surface_1 border border-outline_soft rounded-8px overflow-hidden elevation_dark_4 ${className}`}
    >
      {children}
    </section>
  );
};

export default Popover;
