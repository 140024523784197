import Widget from 'components/widgets/components/Widget';
import OrderbookChart from './OrderbookChart';

const OrderbookChartWidget = ({ pairId, className = '' }: { pairId: string | undefined; className?: string }) => {
  return (
    <div className={className}>
      <Widget padding="0" size="full" className="h-full">
        <OrderbookChart pairId={pairId} />
      </Widget>
    </div>
  );
};

export default OrderbookChartWidget;
