import BigNumber from 'bignumber.js';
import { formatUSD } from 'common/utils';
import NumberText from 'components/texts/NumberText';
import TooltipLabel from 'components/tooltips/TooltipLabel';
import type { MyPoolDetail } from 'types/pool';

const MyPoolTokens = ({ pool }: { pool: MyPoolDetail }) => {
  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center gap-x-2">
        <TooltipLabel label="Unfarmed" />
        <NumberText size="sm" value={formatUSD(pool.myBalance.multipliedBy(pool.priceOracle))} />
      </div>

      <div className="flex justify-between items-center gap-x-2">
        <TooltipLabel label="Farmed" />
        <NumberText
          size="sm"
          color="plain-mono"
          value={formatUSD(pool.myStaking?.stakedAmount.multipliedBy(pool.priceOracle) ?? new BigNumber(0))}
        />
      </div>
    </div>
  );
};

export default MyPoolTokens;
