import type { PoolDetail } from 'types/pool';
import Table from 'components/table/Table';
import { useCallback, useMemo } from 'react';
import { useMainStore } from 'provider/mainContext';
import { TableStyles } from 'components/table/styles';
import { getTvlByPool } from 'common/utils';
import BigNumber from 'bignumber.js';
import PoolFeatures from 'components/pools/PoolFeatures';
import Icon from 'components/icon';

type TableOtherPoolsRow = {
  pool: PoolDetail;
  poolId: JSX.Element;
  features: JSX.Element;
  apr: BigNumber;
  aprRaw: number;
  tvl: BigNumber;
};

const generateRowClassName = (row: TableOtherPoolsRow) => (row.apr.isZero() ? 'opacity-40' : '');

/** @component OtherPools */
type OtherPoolsProps = { pool: PoolDetail; onSelectPool?: (pool: PoolDetail) => void };

const OtherPools = ({ pool: selectedPool, onSelectPool }: OtherPoolsProps) => {
  const store = useMainStore();

  const otherPools = useMemo<PoolDetail[]>(() => {
    return store.getPools().filter((pool) => selectedPool.pair.pools.map((p) => p.poolId).includes(pool.poolId));
  }, [selectedPool, store]);

  const tableRows = useMemo<TableOtherPoolsRow[]>(() => {
    return otherPools.map((pool) => {
      const poolId = (
        <div className="relative">
          {pool.poolId === selectedPool.poolId && (
            <div className="absolute left-0 h-full flex items-center">
              <Icon type="done" size="12px" className="text-on_surface_variant_dark -translate-x-4" />
            </div>
          )}
          <div className="text-left text-on_surface font_caption_number_m">{pool.poolId}</div>
        </div>
      );

      return {
        pool: pool,
        poolId,
        features: <PoolFeatures pool={pool} />,
        apr: new BigNumber(pool.apr),
        aprRaw: pool.apr,
        tvl: getTvlByPool(pool),
      };
    });
  }, [selectedPool, otherPools]);

  const onRowClick = useCallback((row: TableOtherPoolsRow) => onSelectPool?.(row.pool), [onSelectPool]);

  return (
    <section className="w-full h-full px-4 py-5 space-y-4 md:px-5 md:pb-6">
      <div className="w-full h-full">
        <Table<TableOtherPoolsRow>
          type={TableStyles.UNCOMPACT}
          rowsScrollHeight="calc(100% - 5.25rem)"
          dSortValue="aprRaw"
          // dIsAsc={false}
          onRowClick={onRowClick}
          rows={tableRows}
          fields={[
            {
              label: '#',
              value: 'poolId',
              type: 'jsx',
              sortDisabled: true,
              widthPx: 32,
              align: 'left',
              generateClassName: generateRowClassName,
            },
            {
              label: 'Features',
              value: 'features',
              type: 'jsx',
              sortDisabled: true,
              generateClassName: generateRowClassName,
            },
            {
              label: 'APR',
              value: 'apr',
              type: 'change',
              sortValue: 'aprRaw',
              sortType: 'number',
              forceNeutral: true,
              align: 'right',
              widthRatio: 24,
              generateClassName: generateRowClassName,
            },
            {
              label: 'TVL',
              value: 'tvl',
              type: 'usd',
              compact: true,
              sortType: 'bignumber',
              align: 'right',
              widthRatio: 26,
              generateClassName: generateRowClassName,
            },
          ]}
        />
      </div>
      {/* </div> */}
    </section>
  );
};

export default OtherPools;
