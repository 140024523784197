import Button from 'components/buttons/Button';
import Icon from 'components/icon';
import { useMemo } from 'react';
import useSortedRows from './hooks/useSortedRows';
import {
  TableStyles,
  TABLE_FIELD_GRID_X_DICT,
  TABLE_FIELD_GRID_Y_DICT,
  TABLE_SPACE_Y_DICT,
  TABLE_FIELD_BG_DICT,
  getTableCellAlignClassName,
  getTableCellWidthStyle,
  TABLE_ROW_BG_DICT,
  TABLE_EXPAND_ICON_GRID_X,
  TABLE_CONTAINER_BORDER_DICT,
  TABLE_FIELD_BORDER_DICT,
  TABLE_FIELD_RIGHT_PADDING,
} from './styles';
import TableFieldCell from './TableFieldCell';
import TableRow, { HoverArea } from './TableRow';
import { TableField, TableRowData } from './types';
import { EMPTY_TEXTS } from 'COMMON_VARIABLES';

/** table */
type TableProps<T extends TableRowData> = {
  type?: TableStyles;
  hasMouseEffect?: boolean;
  fields: TableField<T>[];
  rows: T[];
  dSortValue?: string;
  dIsAsc?: boolean;
  onRowClick?: (row: T) => void;
  onToggleRowSubJsx?: (data: T, isOpen: boolean) => void;
  onToggleAllSubJsx?: (isOpen: boolean) => void;
  onToggleFoldableOnMobile?: (data: T, isOpen: boolean) => void;
  onSort?: (isAsc: boolean, sortValue: string) => void;
  isLoading?: boolean;
  showRowClickIcon?: boolean;
  noDataLabel?: string;
  rowsScrollHeight?: string;
  hideFields?: boolean;
  striped?: boolean;
  hoverArea?: HoverArea;
};

const Table = <T extends TableRowData>({
  type = TableStyles.PRIMARY,
  hasMouseEffect,
  fields,
  rows,
  dSortValue,
  dIsAsc,
  onRowClick,
  onToggleRowSubJsx,
  onToggleAllSubJsx,
  onToggleFoldableOnMobile,
  onSort,
  isLoading = false,
  showRowClickIcon = false,
  noDataLabel = EMPTY_TEXTS.TABLE_FALLBACK,
  rowsScrollHeight,
  hideFields = false,
  striped,
  hoverArea,
}: TableProps<T>) => {
  /** @summary sorting & filtering */
  const { sortedRows, isAsc, sortValue, sortBy } = useSortedRows({
    rows,
    fields,
    dSortValue,
    dIsAsc,
    onSort,
  });

  const hasAnySubJsx = useMemo<boolean>(() => rows.some((row) => row.subJsx !== undefined), [rows]);
  const hasAnySubJsxOpen = useMemo<boolean>(() => rows.some((row) => row.isSubJsxOpen), [rows]);

  const needRightSpace = useMemo<boolean>(
    () =>
      (showRowClickIcon && onRowClick !== undefined) || hasAnySubJsx || rows.some((row) => row.rightEnd !== undefined),
    [hasAnySubJsx, showRowClickIcon, onRowClick, rows]
  );

  return (
    <div
      className={`relative w-full ${rowsScrollHeight ? 'h-full' : ''} overflow-hidden ${TABLE_SPACE_Y_DICT[type]} ${
        TABLE_CONTAINER_BORDER_DICT[type]
      }`}
    >
      {/* fields */}
      {!hideFields && (
        <ul
          className={`relative w-full ${TABLE_FIELD_BORDER_DICT[type]} ${TABLE_FIELD_GRID_X_DICT[type]} ${
            needRightSpace ? TABLE_FIELD_RIGHT_PADDING : ''
          } ${TABLE_FIELD_GRID_Y_DICT[type]} ${TABLE_FIELD_BG_DICT[type]} ${rowsScrollHeight ? 'overflow-y-auto' : ''}`}
          style={{ scrollbarGutter: 'stable' }}
        >
          {fields
            .filter((field) => !field.hide)
            .map((field) => (
              <div
                key={field.value}
                className={`flex items-center gap-x-1 ${
                  field.sortDisabled ? '' : 'cursor-pointer'
                } ${getTableCellAlignClassName(field, type)}`}
                style={getTableCellWidthStyle(field)}
                onClick={() => {
                  if (!field.sortDisabled) sortBy(field);
                }}
              >
                <TableFieldCell type={type} field={field} />
                {sortValue === (field.sortValue ?? field.value) && (
                  <Icon type={isAsc ? 'sortAsc' : 'arrow-downward'} size="16px" className="text-secondary" />
                )}
              </div>
            ))}

          {hasAnySubJsx && onToggleAllSubJsx && (
            <div
              className={`hidden md:flex items-center justify-center absolute md:right-2 md:top-0 md:bottom-0 ${TABLE_EXPAND_ICON_GRID_X[type]}`}
            >
              <Button
                type="text"
                size="xs"
                label="All"
                onClick={() => onToggleAllSubJsx?.(!hasAnySubJsxOpen)}
                trailingIcon={hasAnySubJsxOpen ? 'expandless' : 'expandmore'}
              />
            </div>
          )}
        </ul>
      )}

      {/* rows */}
      <ul
        className={`relative w-full ${TABLE_SPACE_Y_DICT[type]} ${rowsScrollHeight ? 'overflow-y-auto' : ''}`}
        style={{ height: rowsScrollHeight, scrollbarGutter: 'stable' }}
      >
        {sortedRows.map((row, index) => (
          <TableRow
            key={index}
            type={type}
            hasMouseEffect={hasMouseEffect}
            showRowClickIcon={showRowClickIcon}
            data={row}
            fields={fields}
            onClick={onRowClick}
            onToggleSubJsx={onToggleRowSubJsx}
            onToggleFoldableOnMobile={onToggleFoldableOnMobile}
            needRightSpace={needRightSpace}
            striped={striped}
            hoverArea={hoverArea}
          />
        ))}

        {/* no data */}
        {sortedRows.length === 0 && (
          <div
            className={`relative w-full h-[240px] flex items-center justify-center text-center px-3 py-5 md:px-4 hover:!bg-none font_body_m text-on_surface_variant_light ${TABLE_ROW_BG_DICT[type]}`}
          >
            <span className="opacity-70">{noDataLabel}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Table;
