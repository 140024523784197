import BigNumber from 'bignumber.js';
import { getAmp } from 'common/utils';
import { IS_FEE_ENABLED, IS_LF_ENABLED } from 'COMMON_VARIABLES';
import { useMemo } from 'react';
import { PoolDetail } from 'types/pool';
import Chip, { ChipColor } from 'components/chips/Chip';

const PoolFeatures = ({ pool }: { pool: PoolDetail }) => {
  const amp = useMemo<BigNumber>(
    () => getAmp({ lastPrice: pool.pair.lastPrice, minPrice: pool.minPrice, maxPrice: pool.maxPrice }),
    [pool.pair.lastPrice, pool.minPrice, pool.maxPrice]
  );

  const rangeChip = useMemo<{ label: string; color: ChipColor }>(() => {
    const isRangedPool = pool.poolType === 2;

    return {
      label: isRangedPool ? (amp.isZero() ? 'Out of Range' : 'Ranged') : 'Basic',
      color: isRangedPool ? (amp.isZero() ? 'red' : 'green') : 'gray',
    };
  }, [pool.poolType, amp]);

  return (
    <div className="flex items-stretch gap-x-2">
      <Chip size="xs" label={rangeChip.label} color={rangeChip.color} />
      {IS_LF_ENABLED && pool.lfEnabled && <Chip size="xs" label="Liquid Farm" color="blue" />}
      {IS_FEE_ENABLED && <Chip size="xs" label="0%" labelType="number" color="gray" />}
    </div>
  );
};

export default PoolFeatures;
