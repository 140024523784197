import { useMainStore } from 'provider/mainContext';
import { formatAmount, getExponentDiff } from 'common/utils';
import BigNumber from 'bignumber.js';
import { useTradeHistory } from 'hooks/useAPI';
import { ORDER_SEARCH_TERMS, ORDER_UPDATE_TERM } from 'COMMON_VARIABLES';
import Table from 'components/table/Table';
import { TableStyles } from 'components/table/styles';
import { useMemo } from 'react';
import { TableField } from 'components/table/types';
import OrdersTablePairTitle from './OrdersTablePairTitle';

/** @desc TradeHistoryTable */
type TradeHistoryTableRow = {
  date: string;
  timestamp: number;
  pair: JSX.Element;
  pairDirectionSet: JSX.Element;
  direction: JSX.Element;
  price: string;
  amount: string;
  status: JSX.Element;
  orderId: string;
};

const MOBILE_FIELDS: TableField<TradeHistoryTableRow>[] = [
  {
    label: 'Pair',
    value: 'pairDirectionSet',
    type: 'jsx',
    sortDisabled: true,
  },
];

const MD_FIELDS: TableField<TradeHistoryTableRow>[] = [
  {
    label: 'Pair',
    value: 'pair',
    type: 'jsx',
    sortDisabled: true,
    widthRatio: 14,
  },
  {
    label: 'Direction',
    value: 'direction',
    type: 'jsx',
    sortDisabled: true,
    widthRatio: 10,
  },
];

export default function TradeHistoryTable({ searchTermIndex }: { searchTermIndex: number }) {
  const mainStore = useMainStore();

  const { data, isLoading } = useTradeHistory(
    mainStore.wallet.address || '',
    ORDER_SEARCH_TERMS[searchTermIndex],
    ORDER_UPDATE_TERM
  );

  const tableRows = useMemo<TradeHistoryTableRow[]>(() => {
    return (
      data?.map((th) => {
        const isBuy = th.direction === 1;
        const _pairData = mainStore?.pairsData?.info?.[th.pairId];
        const _assetData = mainStore?.assetsData?.info;
        const baseInfo = _assetData?.[_pairData?.baseDenom];
        const quoteInfo = _assetData?.[_pairData?.quoteDenom];
        const exponentDiff = getExponentDiff(baseInfo.denom, quoteInfo.denom, mainStore);
        const _rawOrderTime = new Date(
          new BigNumber(th.timestamp).shiftedBy(-3).decimalPlaces(0, 1).toNumber()
        ).toLocaleString('en-us');
        const fullYear = new Date(new Date().getTime()).getFullYear();
        const orderTime = _rawOrderTime.replace('/' + fullYear + ',', '').slice(0, -6) + ' ' + _rawOrderTime.slice(-2);

        const pairDirectionSet = (
          <OrdersTablePairTitle baseTicker={baseInfo?.ticker} quoteTicker={quoteInfo?.ticker} isBuy={isBuy} />
        );

        return {
          date: orderTime,
          timestamp: th.timestamp,
          pair: (
            <div className="font_caption_s">
              {baseInfo?.ticker}/{quoteInfo?.ticker}
            </div>
          ),
          direction: (
            <div className={`font_caption_s ${isBuy ? 'text-semantic_green' : 'text-semantic_red'}`}>
              {isBuy ? 'Buy' : 'Sell'}
            </div>
          ),
          pairDirectionSet,
          price: th.price !== '0' ? formatAmount(new BigNumber(th.price).shiftedBy(exponentDiff), 5) : '-',
          amount:
            th.swappedBaseAmount !== '0'
              ? formatAmount(new BigNumber(th.swappedBaseAmount).shiftedBy(-baseInfo.exponent), 9)
              : '-',
          status: (
            <div className="font_caption_s">
              {th.status === 5
                ? 'Canceled'
                : th.status === 4
                ? 'Fulfilled'
                : th.status === 3
                ? 'Partially matched'
                : th.status === 6
                ? 'Expired'
                : ''}
            </div>
          ),
          orderId: `${th.pairId}-${th.reqId}`,
        };
      }) ?? []
    );
  }, [data, mainStore]);

  return (
    <Table<TradeHistoryTableRow>
      type={TableStyles.COMPACT}
      rowsScrollHeight={mainStore.isMobile ? undefined : '17rem'}
      rows={tableRows}
      isLoading={isLoading}
      noDataLabel={mainStore.wallet.isActive ? 'No Trade History' : 'Connect Wallet'}
      fields={[
        ...(mainStore.isMobile ? MOBILE_FIELDS : []),
        {
          label: 'Date',
          value: 'date',
          type: 'jsx',
          sortValue: 'timestamp',
          sortType: 'number',
          sortDisabled: true,
          widthPx: 100,
        },
        ...(mainStore.isMobile ? [] : MD_FIELDS),
        {
          label: 'Price',
          value: 'price',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 10,
          align: 'right',
        },
        {
          label: 'Amount',
          value: 'amount',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 24,
          align: 'right',
        },
        {
          label: 'Status',
          value: 'status',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 10,
        },
        {
          label: 'Order ID',
          value: 'orderId',
          type: 'jsx',
          sortDisabled: true,
          widthPx: 100,
        },
      ]}
    />
  );
}
