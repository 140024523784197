import { AminoTypes } from '@cosmjs/stargate';
import { crescentTypes } from 'common/sign/aminoConverter';
import { INITIAL_ASSET, CLIENT_PROTOCOL, EVMChainNames } from 'COMMON_VARIABLES';
import { InfoChain } from 'types/chain';
import { getKeplrAddressInfoByChainId } from 'common/signingClients/utils/keplrUtils';
import { SigningStargateClient } from '@cosmjs/stargate';
import { decode, encode } from 'bech32';
import defaultRegistry from 'common/sign/registry';
import { getCrescentChainFee, getTxChainGasPrice, notifyTxBroadcast, notifyTxError } from 'common/utils';
import { SignParams } from 'types/signingClients';
import { createIbcWithdrawMsg, createIbcDepositMsg } from 'common/msg';
import { Tendermint34Client } from '@cosmjs/tendermint-rpc';
import _m0 from 'protobufjs/minimal';
import { TxRaw } from 'types/signingClients';
import { toHex } from '@cosmjs/encoding';
import { webSocketTxBroadcastHandler } from 'common/websocket/websocketHandlers';
import { requestAccount } from '@cosmostation/extension-client/tendermint';
import { appSigner } from 'common/sign/appSigner';
import { store } from 'provider/mainContext';
import { getLeapAddressInfoByChainId } from './leapUtils';

const WALLET_TYPES = ['keplr', 'cosmostation', 'walletconnect', 'leap'] as const;
export type WalletType = typeof WALLET_TYPES[number];

export function getSignInfo({ chainData }: { chainData: any }) {
  const aminoTypes = new AminoTypes({ additions: crescentTypes, prefix: 'cre' });
  const creChainId = INITIAL_ASSET.chainId;
  const crebech32PrefixAccAddr = 'cre';
  const creChainInfo: InfoChain | undefined = chainData.info[creChainId];
  return { aminoTypes, creChainId, creChainInfo, crePrefix: crebech32PrefixAccAddr };
}

export async function getIBCData({
  walletType,
  IBCType,
  // data params by wallet
  data: { IS_PROTO_SIGN, chainData, txData, creAddress, walletConnectWallet },
}: {
  walletType: WalletType;
  IBCType: 'deposit' | 'withdraw';
  data: { IS_PROTO_SIGN?: boolean; walletConnectWallet?: any; chainData: any; txData: any; creAddress: string };
}): Promise<any> {
  if (walletType === 'keplr') {
    if (window.getOfflineSignerOnlyAmino === undefined || window.getOfflineSigner === undefined) {
      throwSigningDataError({ walletType: 'keplr', errorType: 'offlineSigner' });
      return;
    }
    if (IBCType === 'deposit') {
      const originChainInfo = chainData.info[txData.originChainId];
      const originChainOfflineSigner = IS_PROTO_SIGN
        ? await window.getOfflineSigner(originChainInfo.chainId)
        : await window.getOfflineSignerOnlyAmino(originChainInfo.chainId);
      const fromChainInfo = await getKeplrAddressInfoByChainId(originChainInfo.chainId);
      const fromAddress = fromChainInfo?.bech32Address ? fromChainInfo?.bech32Address : '';
      const toAddress = creAddress;
      // http:// => wss:// util에 컨버터 만들기
      const externalChainSigningClient = await SigningStargateClient.connectWithSigner(
        getWebsocketUrl({ originChainInfo, type: 'externalNode' }),
        originChainOfflineSigner
      );
      const ibcDepositMsg = createIbcDepositMsg(txData, originChainInfo, fromAddress, toAddress);
      return { fromAddress, ibcDepositMsg, externalChainSigningClient };
    } else if (IBCType === 'withdraw') {
      const destinationChainInfo = chainData.info[txData.destinationChainId];
      const toChainInfo = await getKeplrAddressInfoByChainId(destinationChainInfo.chainId);
      const toAddress = toChainInfo?.bech32Address ? toChainInfo?.bech32Address : '';
      const ibcWithdrawMsg = createIbcWithdrawMsg(txData, destinationChainInfo, creAddress, toAddress);
      return { ibcWithdrawMsg };
    }
  } else if (walletType === 'leap') {
    if (window.leap.getOfflineSignerOnlyAmino === undefined || window.leap.getOfflineSigner === undefined) {
      throwSigningDataError({ walletType: 'leap', errorType: 'offlineSigner' });
      return;
    }
    if (IBCType === 'deposit') {
      const originChainInfo = chainData.info[txData.originChainId];
      const originChainOfflineSigner = IS_PROTO_SIGN
        ? await window.leap.getOfflineSigner(originChainInfo.chainId, { preferNoSetFee: true })
        : await window.leap.getOfflineSignerOnlyAmino(originChainInfo.chainId, { preferNoSetFee: true });
      const fromChainInfo = await getLeapAddressInfoByChainId(originChainInfo.chainId);
      const fromAddress = fromChainInfo?.bech32Address ? fromChainInfo?.bech32Address : '';
      const toAddress = creAddress;
      // http:// => wss:// util에 컨버터 만들기
      const externalChainSigningClient = await SigningStargateClient.connectWithSigner(
        getWebsocketUrl({ originChainInfo, type: 'externalNode' }),
        originChainOfflineSigner
      );
      const ibcDepositMsg = createIbcDepositMsg(txData, originChainInfo, fromAddress, toAddress);
      return { fromAddress, ibcDepositMsg, externalChainSigningClient };
    } else if (IBCType === 'withdraw') {
      const destinationChainInfo = chainData.info[txData.destinationChainId];
      const toChainInfo = await getLeapAddressInfoByChainId(destinationChainInfo.chainId);
      const toAddress = toChainInfo?.bech32Address ? toChainInfo?.bech32Address : '';
      const ibcWithdrawMsg = createIbcWithdrawMsg(txData, destinationChainInfo, creAddress, toAddress);
      return { ibcWithdrawMsg };
    }
  } else if (walletType === 'cosmostation') {
    if (IBCType === 'deposit') {
      const originChainInfo = chainData.info[txData.originChainId];
      const fromAddress = (await requestAccount(originChainInfo.displayName.toLowerCase())).address;
      const toAddress = creAddress;
      const ibcDepositMsg = createIbcDepositMsg(txData, originChainInfo, fromAddress, toAddress);
      const originChainOfflineSigner = window.cosmostation.providers.keplr.getOfflineSignerOnlyAmino(
        txData.originChainId
      );
      const externalChainSigningClient = await SigningStargateClient.connectWithSigner(
        getWebsocketUrl({ originChainInfo, type: 'externalNode' }),
        originChainOfflineSigner
      );
      return { fromAddress, ibcDepositMsg, externalChainSigningClient };
    } else if (IBCType === 'withdraw') {
      const destinationChainInfo = chainData.info[txData.destinationChainId];
      let toChainName = destinationChainInfo.displayName.toLowerCase();
      if (toChainName === 'gravity-bridge') {
        toChainName = 'gravity bridge';
      }
      const toAddress = (await requestAccount(toChainName))?.address || '';
      const ibcWithdrawMsg = createIbcWithdrawMsg(txData, destinationChainInfo, creAddress, toAddress);
      return { ibcWithdrawMsg };
    }
  } else if (walletType === 'walletconnect') {
    const { aminoTypes } = getSignInfo({ chainData });
    if (IBCType === 'deposit') {
      const originChainInfo = chainData.info[txData.originChainId];
      const fromAddress = txData.originChainWallet?.address || '';
      const toAddress = creAddress;

      const client = await SigningStargateClient.connect(getWebsocketUrl({ originChainInfo, type: 'externalNode' }));
      const externalChainSigningClient = appSigner(
        originChainInfo,
        walletConnectWallet?.connector,
        walletConnectWallet?.pubkey,
        defaultRegistry,
        aminoTypes,
        client
      );
      const ibcDepositMsg = createIbcDepositMsg(txData, originChainInfo, fromAddress, toAddress);
      return { fromAddress, ibcDepositMsg, externalChainSigningClient };
    } else if (IBCType === 'withdraw') {
      const destinationChainInfo = chainData.info[txData.destinationChainId];
      const ibcWithdrawMsg = createIbcWithdrawMsg(
        txData,
        destinationChainInfo,
        creAddress,
        txData.destinationChainWallet.address
      );
      return { ibcWithdrawMsg };
    }
  }
}

export async function getCreAddressByWallet({
  walletType,
  creChainId,
  IS_PROTO_SIGN,
}: {
  walletType: WalletType;
  creChainId: string;
  IS_PROTO_SIGN?: boolean;
}) {
  try {
    let creAddress: string = '';
    if (walletType === 'keplr') {
      if (window.getOfflineSignerOnlyAmino === undefined || window.getOfflineSigner === undefined) {
        throwSigningDataError({ walletType: 'keplr', errorType: 'offlineSigner' });
        return creAddress;
      }
      const creOfflineSigner = IS_PROTO_SIGN
        ? await window.getOfflineSigner(creChainId)
        : await window.getOfflineSignerOnlyAmino(creChainId);
      const [account] = await creOfflineSigner.getAccounts();
      creAddress = encode('cre', decode(account.address).words) ?? '';
    } else if (walletType === 'leap') {
      const creOfflineSigner = window.leap.getOfflineSignerOnlyAmino(creChainId);
      const [account] = await creOfflineSigner.getAccounts();
      creAddress = encode('cre', decode(account.address).words) ?? '';
    } else if (walletType === 'cosmostation') {
      const account = await requestAccount(INITIAL_ASSET.chainName);
      creAddress = account.address;
    }

    if (creAddress === '') {
      throwSigningDataError({ walletType: 'keplr', errorType: 'creAddress' });
      return '';
    } else {
      return creAddress;
    }
  } catch (e) {
    throwSigningDataError({ walletType: 'keplr', errorType: 'creAddress' });
    return '';
  }
}

export function throwSigningDataError({
  walletType,
  errorType,
}: {
  walletType: WalletType;
  errorType: 'chainData' | 'offlineSigner' | 'creAddress';
}) {
  const errorFormat = `${walletType}/${errorType} Signing data error:`;
  if (errorType === 'chainData') {
    throw new Error(`${errorFormat} mainStore chainData not available!`);
  } else if (errorType === 'offlineSigner') {
    throw new Error(`${errorFormat} offlineSigner not available!`);
  } else if (errorType === 'creAddress') {
    throw new Error(`${errorFormat} creAddress not available!`);
  }
}

export async function getCrescentChainSigningClientByWallet({
  walletType,
  chainData,
  walletConnectWallet,
  IS_PROTO_SIGN,
}: {
  walletType: WalletType;
  chainData: any;
  walletConnectWallet?: any;
  IS_PROTO_SIGN?: boolean;
}) {
  const { aminoTypes, creChainId, creChainInfo } = getSignInfo({ chainData });
  if (walletType === 'keplr') {
    if (window.getOfflineSignerOnlyAmino === undefined || window.getOfflineSigner === undefined) {
      throwSigningDataError({ walletType: 'keplr', errorType: 'offlineSigner' });
      return;
    }
    const creOfflineSigner = IS_PROTO_SIGN
      ? await window.getOfflineSigner(creChainId)
      : await window.getOfflineSignerOnlyAmino(creChainId);

    return await SigningStargateClient.connectWithSigner(
      getWebsocketUrl({ chainData, type: 'crescentNode' }),
      creOfflineSigner,
      { registry: defaultRegistry, aminoTypes: aminoTypes, prefix: 'cre' }
    );
  } else if (walletType === 'leap') {
    const creOfflineSigner = await window.leap.getOfflineSignerOnlyAmino(creChainId, { preferNoSetFee: true });
    return await SigningStargateClient.connectWithSigner(
      getWebsocketUrl({ chainData, type: 'crescentNode' }),
      creOfflineSigner,
      { registry: defaultRegistry, aminoTypes: aminoTypes, prefix: 'cre' }
    );
  } else if (walletType === 'cosmostation') {
    const creOfflineSigner = window.cosmostation.providers.keplr.getOfflineSignerOnlyAmino(creChainId);
    return await SigningStargateClient.connectWithSigner(
      getWebsocketUrl({ chainData, type: 'crescentNode' }),
      creOfflineSigner,
      { registry: defaultRegistry, aminoTypes: aminoTypes, prefix: 'cre' }
    );
  } else if (walletType === 'walletconnect') {
    const client = await SigningStargateClient.connect(getWebsocketUrl({ chainData, type: 'crescentNode' }));
    return appSigner(
      creChainInfo,
      walletConnectWallet?.connector,
      walletConnectWallet?.pubkey,
      defaultRegistry,
      aminoTypes,
      client
    );
  }
}

export function checkSigningDataError(chainData) {
  if (!chainData.isInit) {
    throwSigningDataError({ walletType: 'keplr', errorType: 'chainData' });
  }
}

export async function getCrescentChainSigningData({
  walletType,
  txType,
  airdropClaimData,
  IS_PROTO_SIGN,
  chainData,
  txData,
}: {
  walletType: WalletType;
  txType: SignParams['type'];
  airdropClaimData?: any;
  IS_PROTO_SIGN?: boolean;
  chainData: any;
  txData: any;
}) {
  const { creChainInfo } = getSignInfo({ chainData });
  let crescentChainFee;
  let crescentChainSigningClient;

  if (txType !== 'deposit') {
    const defaultGasRate = creChainInfo ? getTxChainGasPrice(creChainInfo, 'low') : 0;
    crescentChainFee = await getCrescentChainFee(txType, INITIAL_ASSET, airdropClaimData, defaultGasRate, txData);
    crescentChainSigningClient = await getCrescentChainSigningClientByWallet({
      walletType: walletType,
      chainData,
      IS_PROTO_SIGN,
      walletConnectWallet: store.wallet,
    });
  }
  return { crescentChainFee, crescentChainSigningClient };
}

export function checkSigningData({
  type,
  txData,
  fee,
  chainData,
}: {
  type: string;
  txData: any;
  fee: any;
  chainData: any;
}) {
  if (chainData.isInit === false) {
    displayAlert();
    throw new Error('txDataCheck error: chainData is not init');
  } else if (type === 'deposit') {
    if (!txData.originChainId || !fee) {
      displayAlert();
      throw new Error('txDataCheck error: IBC deposit txData.originChainId or fee is empty');
    }
  } else if (type === 'withdraw') {
    if (!txData.destinationChainId) {
      displayAlert();
      throw new Error('txDataCheck error: IBC withdraw txData.destinationChainId is empty');
    }
  }
  function displayAlert() {
    alert('txDataCheck error: check console!');
  }
}

export function displayError(e) {
  console.error(`Error sign TX ${e}`);
  const eMsg = e + '';
  if (eMsg.includes('rejected') || eMsg.includes('declined')) return new Error(`Error sign TX ${e}`);
  notifyTxError(e);
}

export const txRawEncoder = {
  encode(message: TxRaw, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bodyBytes.length !== 0) {
      writer.uint32(10).bytes(message.bodyBytes);
    }
    if (message.authInfoBytes.length !== 0) {
      writer.uint32(18).bytes(message.authInfoBytes);
    }
    for (const v of message.signatures) {
      writer.uint32(26).bytes(v!);
    }
    return writer;
  },
};

export async function broadcastTxHandler({
  signedTxRaw,
  webSocket,
  type,
  txData,
  chainData,
}: {
  signedTxRaw: any;
  webSocket: any;
  chainData: any;
  txData: any;
  type: SignParams['type'];
}) {
  let broadcastResult: any;
  if (type === 'deposit') {
    // External Chain Broadcast
    const originChainInfo = chainData.info[txData.originChainId];
    const tmClient = Tendermint34Client.connect(getWebsocketUrl({ originChainInfo, type: 'externalNode' }));
    const encodedTxRaw = txRawEncoder.encode(signedTxRaw).finish();
    broadcastResult = await (await tmClient).broadcastTxCommit({ tx: encodedTxRaw });
  } else {
    // Crescent Chain Broadcast
    const tmClient = Tendermint34Client.connect(getWebsocketUrl({ chainData, type: 'crescentNode' }));
    const encodedTxRaw = txRawEncoder.encode(signedTxRaw).finish();
    broadcastResult = await (await tmClient).broadcastTxCommit({ tx: encodedTxRaw });
  }
  console.log('Broadcast Result', broadcastResult);
  // @ts-ignore
  const hash = toHex(broadcastResult?.hash)?.toUpperCase();

  console.log('Broadcasting...', `\n hash:${hash}`);

  // fail checkTx & deliverTx
  if (Number(broadcastResult?.checkTx?.code ?? 0) + Number(broadcastResult?.deliverTx?.code ?? 0) !== 0) {
    const checkTxErrorLog = broadcastResult?.checkTx?.log !== '[]' ? broadcastResult?.checkTx?.log ?? '' : '';
    if (checkTxErrorLog.length > 0) notifyTxError(checkTxErrorLog, txData);

    const deliverTxErrorLog = broadcastResult?.deliverTx?.log !== '[]' ? broadcastResult?.deliverTx?.log ?? '' : '';
    if (deliverTxErrorLog.length > 0) notifyTxError(deliverTxErrorLog, txData);
  } else {
    // success checkTx & deliverTx
    notifyTxBroadcast(hash);
    webSocket.close();
    // await webSocketTxBroadcastHandler(webSocket, hash, type);
  }
}

export function getWebsocketUrl({
  originChainInfo,
  chainData,
  type,
}: {
  chainData?: any;
  originChainInfo?: any;
  type: 'crescentNode' | 'externalNode';
}) {
  if (type === 'crescentNode') {
    if (!chainData) {
      alert('getWebsocketUrl: chainData is empty');
      return '';
    }
    const { creChainInfo } = getSignInfo({ chainData });
    return `${CLIENT_PROTOCOL}${creChainInfo?.wsEndpoint}`;
  } else if (type === 'externalNode') {
    // EVM-TEST
    // return 'wss:///evmos.crescent.network:26657';

    if (!originChainInfo) {
      alert('getWebsocketUrl: originChainInfo is empty');
      return '';
    }

    return `${CLIENT_PROTOCOL}${originChainInfo.wsEndpoint}`;
  } else {
    alert('getWebsocketUrl: type is not correct');
    return '';
  }
}

export function checkEvmChain(chainId: string): { isEvm: boolean; chainName: EVMChainNames | undefined } {
  const chainName = Object.values(EVMChainNames).find((evmChainName) => chainId.includes(evmChainName));
  return { isEvm: Boolean(chainName), chainName };
}
