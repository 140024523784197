import BigNumber from 'bignumber.js';
import { getValidDigitNumber } from 'common/utils';
import { PastOrderEvent } from 'types/orderbook';
import Table from 'components/table/Table';
import { TableStyles } from 'components/table/styles';
import { useMemo } from 'react';

type PastOrderDetailsTableRow = {
  date: string;
  timestamp: number;
  price: string;
  amount: string;
  status: JSX.Element;
};

type PastOrderDetailsTableProps = {
  events: PastOrderEvent[];
  baseExponent: number;
  exponentDiff: number;
};

export default function PastOrderDetailsTable({ events, baseExponent, exponentDiff }: PastOrderDetailsTableProps) {
  const tableRows = useMemo<PastOrderDetailsTableRow[]>(() => {
    return events.map((th) => {
      const _rawOrderTime = new Date(
        new BigNumber(th.timestamp).shiftedBy(-3).decimalPlaces(0, 1).toNumber()
      ).toLocaleString('en-us');
      const fullYear = new Date(new Date().getTime()).getFullYear();
      const orderTime = _rawOrderTime.replace('/' + fullYear + ',', '').slice(0, -6) + ' ' + _rawOrderTime.slice(-2);

      return {
        date: orderTime,
        timestamp: th.timestamp,
        price: Number(th.swap_price)
          ? getValidDigitNumber(new BigNumber(th.swap_price).shiftedBy(exponentDiff).toNumber(), 5)
          : '-',
        amount:
          th.swapped_base_amount !== '0'
            ? new BigNumber(th.swapped_base_amount)
                .shiftedBy(-baseExponent)
                .toNumber()
                .toLocaleString(undefined, { maximumFractionDigits: 9 })
            : '-',
        status: (
          <div className="font_caption_s">
            {th.status === 5
              ? 'Canceled'
              : th.status === 4
              ? 'Fulfilled'
              : th.status === 3
              ? 'Partially matched'
              : th.status === 6
              ? 'Expired'
              : ''}
          </div>
        ),
      };
    });
  }, [events, baseExponent, exponentDiff]);

  return (
    <Table<PastOrderDetailsTableRow>
      type={TableStyles.COMPACT}
      isLoading={false}
      rows={tableRows}
      fields={[
        {
          label: 'Date',
          value: 'date',
          type: 'jsx',
          sortValue: 'timestamp',
          sortType: 'number',
          sortDisabled: true,
          widthRatio: 24,
        },
        {
          label: 'Price',
          value: 'price',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 10,
          align: 'right',
        },
        {
          label: 'Amount',
          value: 'amount',
          type: 'jsx',
          sortDisabled: true,
          align: 'right',
        },
        {
          label: 'Status',
          value: 'status',
          type: 'jsx',
          sortDisabled: true,
          widthRatio: 22,
        },
      ]}
    />
  );
}
