import { Switch } from '@headlessui/react';

type ToggleButtonColor = 'primary' | 'liquid';
type ToggleButtonSize = 'xs' | 'sm' | 'md';

const TOGGLE_BUTTON_COLOR_DICT: { [key in ToggleButtonColor]: { [key in ToggleButtonSize]: { wrapper: (on: boolean) => string; ball: (on: boolean) => string }} } = {
  primary: {
    xs: {
      wrapper:(on) => on ? 'bg-primary_container': 'bg-semantic_gray',
      ball: (on) => on ? 'bg-primary' : 'bg-semantic_on_gray',
    },
    sm: {
      wrapper: (on) => on ? 'bg-primary_container': 'bg-outline_hard_o24 border-2 border-outline_hard',
      ball: (on) => on ? 'bg-primary' : 'bg-outline_hard',
    },
    md: {
      wrapper: (on) => on ? 'bg-primary_container': 'bg-outline_hard_o24 border-2 border-outline_hard',
      ball: (on) => on ? 'bg-primary' : 'bg-outline_hard',
    },
  },
  liquid: {
    xs: {
      wrapper:(on) => on ? 'bg-liquid': 'bg-semantic_gray',
      ball: (on) => on ? 'bg-liquid_container' : 'bg-semantic_on_gray',
    },
    sm: {
      wrapper: (on) => on ? 'bg-liquid': 'bg-outline_hard_o24 border-2 border-outline_hard',
      ball: (on) => on ? 'bg-liquid_container' : 'bg-outline_hard',
    },
    md: {
      wrapper: (on) => on ? 'bg-liquid': 'bg-outline_hard_o24 border-2 border-outline_hard',
      ball: (on) => on ? 'bg-liquid_container' : 'bg-outline_hard',
    },
  }
}

const TOGGLE_BUTTON_SIZE_DICT: { [key in ToggleButtonSize]: { wrapper: string; padding: string; ball: (on: boolean) => string } } = {
  xs: {
    wrapper: 'w-[1.75rem] h-2',
    padding: 'p-0',
    ball: (on) => 'w-3 h-3',
  },
  sm: {
    wrapper: 'w-9 h-5',
    padding: 'p-[0.1875rem]',
    ball: (on) => on ? 'w-[0.875rem] h-[0.875rem]': 'w-[0.625rem] h-[0.625rem]',
  },
  md: {
    wrapper: 'w-12 h-[1.625rem]',
    padding: 'p-1',
    ball: (on) => on ? 'w-[1.125rem] h-[1.125rem]' : 'w-[0.875rem] h-[0.875rem]',
  },
}

const TRANSLATE_DICT: { [key in ToggleButtonSize]: string } = {
  xs: 'translate-x-4',
  sm: 'translate-x-4',
  md: 'translate-x-[1.375rem]',
};

type ToggleButtonProps = {
  toggle: () => void;
  size?: ToggleButtonSize;
  color?: ToggleButtonColor;
  isEnabled: boolean;
};

function ToggleButton({ toggle, isEnabled, size = 'md', color = 'primary' }: ToggleButtonProps) {
  return (
    <Switch
      checked={isEnabled}
      onChange={toggle}
      className={`inline-flex items-center rounded-full box-border ${TOGGLE_BUTTON_SIZE_DICT[size].wrapper} ${TOGGLE_BUTTON_SIZE_DICT[size].padding} ${TOGGLE_BUTTON_COLOR_DICT[color][size].wrapper(isEnabled)}`}
    >
      <span className="sr-only">{isEnabled ? 'On' : 'Off'}</span>
      {/*  */}
      <span
        className={`rounded-full ${
          TOGGLE_BUTTON_SIZE_DICT[size].ball(isEnabled)
        } ${TOGGLE_BUTTON_COLOR_DICT[color][size].ball(isEnabled)} ${isEnabled ? TRANSLATE_DICT[size] : 'translate-x-0'} transform transition duration-200 ease-in-out`}
      ></span>
    </Switch>
  );
}

export default ToggleButton;
