import { useMainStore } from 'provider/mainContext';
import { observer } from 'mobx-react-lite';
import { INITIAL_ASSET } from 'COMMON_VARIABLES';
import { useAirdropInfo } from 'hooks/useAPI';
import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import H4 from 'components/texts/H4';
import Button from 'components/buttons/Button';
import Panel from 'components/panel/Panel';
import Table from 'components/table/Table';
import { TableField } from 'components/table/types';

type DexDropMission = {
  mission: string;
  percent: number;
  claimType:
    | 'CONDITION_TYPE_LIQUIDSTAKE'
    | 'CONDITION_TYPE_SWAP'
    | 'CONDITION_TYPE_DEPOSIT'
    | 'CONDITION_TYPE_VOTE'
    | undefined;
  actionLabel: string;
  to: string | undefined;
};

const DEXDROP_MISSIONS: DexDropMission[] = [
  {
    mission: 'Delegated ATOM before snapshot',
    percent: 20,
    claimType: undefined,
    actionLabel: 'Delegate',
    to: undefined,
  },
  {
    mission: 'Liquid Stake CRE',
    percent: 20,
    claimType: 'CONDITION_TYPE_LIQUIDSTAKE',
    actionLabel: 'Stake',
    to: '/staking',
  },
  {
    mission: 'Swap on Crescent DEX',
    percent: 20,
    claimType: 'CONDITION_TYPE_SWAP',
    actionLabel: 'Swap',
    to: '/swap',
  },
  {
    mission: 'Provide liquidity to bCRE pool',
    percent: 20,
    claimType: 'CONDITION_TYPE_DEPOSIT',
    actionLabel: 'Farm',
    to: '/farm',
  },
  {
    mission: 'Vote on governance proposal',
    percent: 20,
    claimType: 'CONDITION_TYPE_VOTE',
    actionLabel: 'Vote',
    to: '/gov',
  },
];

const ClaimAirdropIndex = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  return (
    <div className="w-full flex justify-between items-center gap-5 md:flex-col md:justify-start">
      <div className="font_caption_s md:font_headline_s text-on_surface_variant_light">{label}</div>
      <div className="font_headline_m_mobile md:font_headline_m text-on_surface_variant_dark">{value}</div>
    </div>
  );
};

const ClaimAirdropIndexValue = ({ numberValue, unit }: { numberValue?: string; unit?: string }) => {
  return (
    <div className="flex items-center gap-x-1">
      <div className="font_headline_number_m_mobile md:font_headline_number_2xl text-on_surface">{numberValue}</div>
      <div className="font_headline_s_mobile md:font_headline_m text-on_surface_variant_dark">{unit}</div>
    </div>
  );
};

/** @component Dexdrop */
// 2022년 10월 9일 ( 4월 14일로부터 179일뒤 )
const DEADLINE = Date.UTC(2022, 9, 10);

type DexdropMissionsTableRow = {
  id: JSX.Element;
  mission: JSX.Element;
  action: JSX.Element;
  mobileTitle: JSX.Element;
};

const MOBILE_FILEDS: TableField<DexdropMissionsTableRow>[] = [
  {
    label: 'Title',
    value: 'mobileTitle',
    type: 'jsx',
  },
];

const MD_FIELDS: TableField<DexdropMissionsTableRow>[] = [
  {
    label: 'Id',
    value: 'id',
    type: 'jsx',
    widthRatio: 4,
  },
  {
    label: 'Mission',
    value: 'mission',
    type: 'jsx',
  },
  {
    label: 'Action',
    value: 'action',
    type: 'jsx',
    widthRatio: 20,
    align: 'right',
  },
];

const Dexdrop = ({ address }: { address?: string }) => {
  const mainStore = useMainStore();

  const { data } = useAirdropInfo(address);
  const [date, setDate] = useState({
    date: '00',
    hour: '00',
    minute: '00',
  });

  const airdropInfo = useMemo(() => {
    if (data?.data && address) {
      const dexdropTotalCre = new BigNumber(data.data.dexAirdropTotalCre).multipliedBy(10 ** INITIAL_ASSET.exponent);
      const claimableAmount = new BigNumber(data.data.claimableCoins.replace('ucre', '') || 0);
      const claimedAmount = dexdropTotalCre.minus(claimableAmount);
      const claimedTypes = {};
      const claimedConditions = data.data.claimedConditions?.split(',').filter((item) => item);
      claimedConditions.forEach((type) => {
        claimedTypes[type] = true;
      });
      const claimedPercent = 20 + claimedConditions.length * 20;
      return {
        totalClaimAmount: dexdropTotalCre
          .dividedBy(10 ** INITIAL_ASSET.exponent)
          .decimalPlaces(6, 1)
          .toString(),
        claimableAmount: claimableAmount
          .dividedBy(10 ** INITIAL_ASSET.exponent)
          .decimalPlaces(6, 1)
          .toString(),
        claimedAmount: claimedAmount
          .dividedBy(10 ** INITIAL_ASSET.exponent)
          .decimalPlaces(6, 1)
          .toString(),
        claimedTypes,
        claimedPercent,
      };
    }
    return null;
  }, [data, address]);

  useEffect(() => {
    let startTime = 60 - new Date().getSeconds();
    let interval;
    calcRemainingDate();
    let timeout = setTimeout(() => {
      calcRemainingDate();
      interval = setInterval(() => {
        calcRemainingDate();
      }, 60 * 1000);
    }, startTime * 1000);
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  const calcRemainingDate = () => {
    const now = Date.now();
    if (DEADLINE > now) {
      const dif = (DEADLINE - now) / 1000;
      const date = Math.floor(dif / (3600 * 24));
      let rest = dif - date * 3600 * 24;
      const hour = Math.floor(rest / 3600);
      rest = rest - hour * 3600;
      const miniute = Math.floor(rest / 60);
      setDate({
        date: date < 10 ? `0${date}` : `${date}`,
        hour: hour < 10 ? `0${hour}` : `${hour}`,
        minute: miniute < 10 ? `0${miniute}` : `${miniute}`,
      });
    }
  };

  const tableRows = useMemo<DexdropMissionsTableRow[]>(() => {
    return DEXDROP_MISSIONS.map((item, index) => {
      const claimable = airdropInfo && item.claimType && !airdropInfo.claimedTypes[item.claimType];

      const textColor = claimable ? 'text-on_surface' : 'text-semantic_gray';

      const id = <div className={`font_caption_number_s md:font_caption_number_m ${textColor}`}>#{index + 1}</div>;
      const mission = (
        <div className={`font_title_s md:font_title_m ${textColor}`}>
          {item.mission} <span className="font_title_number_s md:font_title_number_m">({item.percent}%)</span>
        </div>
      );

      const action = (
        <Button
          type="text"
          size="sm"
          label={claimable ? item.actionLabel : airdropInfo ? 'Claimed' : 'Not eligible'}
          trailingIcon={claimable ? 'arrow-forward' : undefined}
          to={item.to}
          status={claimable ? 'enabled' : 'disabled'}
        />
      );

      const mobileTitle = (
        <div className="w-[calc(100vw_-_4rem)] flex justify-between items-center gap-x-2">
          <div className="grow-0 shrink-0 basis-[1rem]">{id}</div>
          <div className="grow shrink text-left">{mission}</div>
          <div className="grow-0 shrink-0 basis-[25%]">{action}</div>
        </div>
      );

      return {
        id,
        mission,
        action,
        mobileTitle,
      };
    });
  }, [airdropInfo]);

  return (
    <div className="pt-2 space-y-[2rem] md:space-y-[4rem]">
      <section className="Claim_Airdrop space-y-3 md:space-y-5">
        <div className="flex items-center justify-between gap-x-4">
          <H4>Claim Airdrop</H4>

          <Button
            type="text"
            size="sm"
            label="Airdrop details"
            trailingIcon="open-in-new"
            href="https://crescentnetwork.medium.com/cre-dexdrop-breakdown-472ba6f22f2b"
          />
        </div>

        <Panel padding={mainStore.isMobile ? '16px' : '40px'} className="box_glow_l">
          <div className="flex flex-col justify-between items-stretch gap-y-4 gap-x-9 md:flex-row">
            <ClaimAirdropIndex
              label="Claimed airdrop amount"
              value={
                airdropInfo ? (
                  <div className="flex flex-wrap justify-end items-baseline gap-x-0.5 md:justify-center">
                    <ClaimAirdropIndexValue numberValue={airdropInfo?.claimedAmount} />
                    <ClaimAirdropIndexValue numberValue={`/${airdropInfo?.totalClaimAmount}`} unit="CRE" />
                  </div>
                ) : (
                  'Not eligible'
                )
              }
            />

            <ClaimAirdropIndex
              label="Time left to claim airdrop"
              value={
                <div className="flex items-center gap-x-2 md:gap-x-3">
                  <ClaimAirdropIndexValue numberValue={date.date} unit="D" />
                  <ClaimAirdropIndexValue numberValue={date.hour} unit="H" />
                  <ClaimAirdropIndexValue numberValue={date.minute} unit="M" />
                </div>
              }
            />
          </div>
        </Panel>
      </section>

      <section className="My_Progress space-y-4 md:space-y-5">
        <div className="w-full flex items-center justify-between gap-x-4">
          <H4>My Progress</H4>

          <div className="font_headline_number_m_mobile md:font_headline_number_m text-on_surface">
            {airdropInfo?.claimedPercent ?? '0'}%
          </div>
        </div>

        <div className="relative">
          <div className="w-full h-5 flex bg-dataviz_categorical_empty" />
          <div className="top-0 absolute h-5 bg-primary" style={{ width: `${airdropInfo?.claimedPercent ?? '0'}%` }} />
        </div>
      </section>

      <section className="Missions space-y-4 md:space-y-5">
        <H4>Missions</H4>

        <Table<DexdropMissionsTableRow>
          hideFields={true}
          rows={tableRows}
          fields={mainStore.isMobile ? MOBILE_FILEDS : MD_FIELDS}
        />
      </section>
    </div>
  );
};

export default observer(Dexdrop);
