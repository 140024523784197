import { initWebSocket } from 'common/websocket/websocketHandlers';
import { signEvmChain } from 'common/sign/evmSign';
import {
  getSignInfo,
  getIBCData,
  getCreAddressByWallet,
  getCrescentChainSigningData,
  checkSigningData,
  displayError,
  broadcastTxHandler,
  checkEvmChain,
} from 'common/signingClients/utils/signUtils';
import { createCrescentMsgs } from 'common/msg/createMsgs';
import { SignParams } from 'types/signingClients';

export async function signWithKeplr({ type, chainData, txData, airdropClaimData, fee }: SignParams) {
  checkSigningData({ type, txData, fee, chainData });
  const IS_PROTO_SIGN = false; // sign mode: if false, use amino
  const walletType = 'keplr';
  const { creChainId, creChainInfo } = getSignInfo({ chainData });
  const webSocket = initWebSocket({ type, chainData, txData, creChainInfo });
  try {
    await window.keplr.enable(creChainId);
    const creAddress = await getCreAddressByWallet({ walletType, creChainId, IS_PROTO_SIGN });

    let signedTxRaw;
    if (type === 'deposit') {
      // External Chain Signing: IBC Deposit
      if (checkEvmChain(txData.originChainId).isEvm) {
        // EVM chain
        const result = await signEvmChain({
          txData,
          chainData,
          fee,
          toAddress: creAddress,
          walletType,
        });
        signedTxRaw = result;
      } else {
        // Cosmos SDK only chain
        const { fromAddress, ibcDepositMsg, externalChainSigningClient } = await getIBCData({
          IBCType: type,
          walletType,
          data: { IS_PROTO_SIGN, chainData, txData, creAddress },
        });
        signedTxRaw = await externalChainSigningClient.sign(fromAddress, ibcDepositMsg, fee, '');
      }
    } else {
      const { crescentChainFee, crescentChainSigningClient } = await getCrescentChainSigningData({
        walletType,
        txType: type,
        IS_PROTO_SIGN,
        chainData,
        airdropClaimData,
        txData,
      });
      if (type === 'withdraw') {
        // Crescent Chain Signing: IBC Withdraw
        const { ibcWithdrawMsg } = await getIBCData({
          IBCType: type,
          walletType,
          data: { IS_PROTO_SIGN, chainData, txData, creAddress },
        });
        signedTxRaw = await crescentChainSigningClient.sign(creAddress, ibcWithdrawMsg, crescentChainFee, '');
      } else {
        // Crescent Chain Signing: Crescent Msgs
        const CrescentChainMsg = await createCrescentMsgs({ type, txData, creAddress });
        signedTxRaw = await crescentChainSigningClient.sign(creAddress, CrescentChainMsg, crescentChainFee, '');
      }
    }
    await broadcastTxHandler({ signedTxRaw, webSocket, type, txData, chainData });
  } catch (e) {
    webSocket.close();
    return displayError(e);
  }
}
// window.keplr.defaultOptions = {
//   sign: {
//     preferNoSetFee: false,
//     preferNoSetMemo: false,
//   },
// };
