import type { AssetDetail } from '../../../types/asset';
import TransitionModal from '../TransitionModal';
import ModalHeader from '../components/ModalHeader';
import ModalSectionHeader from '../components/ModalSectionHeader';
import TokenSelect from 'components/tokenSelect/TokenSelect';

type TokenSelectModalProps = {
  isOpen?: boolean;
  title: string;
  availableTitle?: string;
  unavailableTitle?: string;
  availableAssets?: AssetDetail[];
  unavailableAssets?: AssetDetail[];
  selectedDenom?: string;
  excludedDenoms?: string[];
  onSelect?: (asset: AssetDetail) => void;
  onClose: () => void;
};

function TokenSelectModal({
  isOpen = false,
  title,
  availableTitle = 'Available',
  unavailableTitle = 'Unavailable',
  availableAssets = [],
  unavailableAssets = [],
  selectedDenom,
  excludedDenoms = [],
  onSelect,
  onClose,
}: TokenSelectModalProps) {
  return (
    <TransitionModal size="sm" isOpen={isOpen} close={onClose}>
      <ModalHeader title={title} onCloseClick={onClose} />
      <div className="h-[434px] md:h-[490px]">
        <TokenSelect
          availableTitle={<ModalSectionHeader title={availableTitle} />}
          unavailableTitle={<ModalSectionHeader title={unavailableTitle} />}
          availableAssets={availableAssets}
          unavailableAssets={unavailableAssets}
          selectedDenom={selectedDenom}
          excludedDenoms={excludedDenoms}
          onSelect={onSelect}
        />
      </div>
    </TransitionModal>
  );
}

export default TokenSelectModal;
