import { INFOBOX_TEXTS } from 'COMMON_VARIABLES';
import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import Button from 'components/buttons/Button';
import InfoBox from 'components/infobox';
import LabeledData from 'components/texts/LabeledData';
import NA from 'components/texts/NA';
import NumberText from 'components/texts/NumberText';
import useSwap from 'hooks/useSwap';
import useTxClient from 'hooks/useTxClient';
import { useMainStore } from 'provider/mainContext';
import { useMemo, useState } from 'react';
import { PoolDetail } from 'types/pool';

export type EasySwapButtonText = 'No need to swap' | 'Insufficient balance for rebalancing' | 'Swap';

export type EasySwapData = {
  exponents: { offer: number; demand: number };
  offerDenom: string;
  demandDenom: string;
  offerAmount: number;
  offerTicker: string;
  demandTicker: string;
  isBuy: boolean;
};

type EasySwapAreaProps = {
  pool: PoolDetail;
  easySwapData: EasySwapData;
  setNeedEasySwapFee: (need: boolean) => void;
  easySwapButtonText: EasySwapButtonText;
  setUpdateType: (type: null | 'max' | 'custom') => void;
  chainsData: any;
  onSuccess: () => void;
};

const EasySwapArea = ({
  pool,
  easySwapData,
  setNeedEasySwapFee,
  easySwapButtonText,
  setUpdateType,
  chainsData,
  onSuccess,
}: EasySwapAreaProps) => {
  const { expectedSlippage, isFinalOrderbookOver } = useSwap({
    pairId: pool.pairId,
    payAssetData: pool.assets.find((asset) => asset.denom === easySwapData.offerDenom),
    payAmount: easySwapData.offerAmount.toString(),
  });

  const [isEasySwapLoading, setIsEasySwapLoading] = useState<boolean>(false);

  const { signAndBroadcast } = useTxClient();

  async function execEasySwap() {
    setIsEasySwapLoading(true);
    try {
      const error = await signAndBroadcast({
        type: 'marketOrder',
        chainData: chainsData,
        txData: {
          pairId: pool.pairId,
          orderDirection: easySwapData.isBuy ? 1 : 2,
          orderLifespanSeconds: 0,
          baseAmount: easySwapData.isBuy
            ? new BigNumber(easySwapData.offerAmount)
                .shiftedBy(easySwapData.exponents.offer)
                .div(pool.poolPrice.toNumber())
                .multipliedBy(0.88)
                .decimalPlaces(0, 1)
                .toString()
            : new BigNumber(easySwapData.offerAmount)
                .shiftedBy(easySwapData.exponents.offer)
                .decimalPlaces(0, 1)
                .toString(),
          offer: {
            denom: easySwapData.offerDenom,
            amount: new BigNumber(easySwapData.offerAmount)
              .shiftedBy(easySwapData.exponents.offer)
              .decimalPlaces(0, 1)
              .toString(),
          },
          demand: {
            denom: easySwapData.demandDenom,
          },
        },
      });
      setIsEasySwapLoading(false);

      if (!error) onSuccess();
    } catch (e) {
      console.log(e);
      setIsEasySwapLoading(false);
    }
  }

  const store = useMainStore();

  const isReady = useMemo<boolean>(
    () => easySwapButtonText === 'Swap' && easySwapData.offerAmount > 0 && !!easySwapData.demandTicker,
    [easySwapData.offerAmount, easySwapData.demandTicker, easySwapButtonText]
  );

  return (
    <div className="p-3 space-y-3 bg_surface_3 rounded-8px md:space-y-4">
      <div className="space-y-2 md:space-y-4">
        <LabeledData
          label="You need to swap"
          value={
            isReady && easySwapData.offerAmount > 0 ? (
              <NumberText
                size="sm"
                value={formatAmount(new BigNumber(easySwapData.offerAmount), easySwapData.exponents.offer)}
                unit={easySwapData.offerTicker}
              />
            ) : (
              <NA />
            )
          }
        />

        <LabeledData
          label="Expected slippage"
          value={
            isReady && easySwapData.offerAmount > 0 && expectedSlippage < 99.9 ? (
              <NumberText
                size="sm"
                value={`${isFinalOrderbookOver ? '>' : ''}${formatAmount(new BigNumber(expectedSlippage), 6)}%`}
                status={expectedSlippage >= 3 ? 'error' : expectedSlippage > 1 ? 'warning' : 'normal'}
              />
            ) : (
              <NA />
            )
          }
        />

        {expectedSlippage >= 3 && <InfoBox type="danger">{INFOBOX_TEXTS.SLIPPAGE}</InfoBox>}
      </div>

      {!isReady && (
        <Button
          type="tonal"
          size={store.isMobile ? 'sm' : 'md'}
          label="Maximize deposit"
          status={isEasySwapLoading ? 'disabled' : 'enabled'}
          onClick={() => {
            /** @caution swap & deposit txs must be considered together */
            setNeedEasySwapFee(true);
            setUpdateType('max');
          }}
          className="w-full"
        />
      )}

      {isReady && (
        <Button
          type="filled"
          size={store.isMobile ? 'sm' : 'md'}
          label={`${easySwapButtonText} ${easySwapData.offerTicker} to ${easySwapData.demandTicker}`}
          status={easySwapButtonText !== 'Swap' ? 'disabled' : isEasySwapLoading ? 'loading' : 'enabled'}
          onClick={execEasySwap}
          className="w-full"
        />
      )}
    </div>
  );
};

export default EasySwapArea;
