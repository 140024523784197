// Types
import type { AssetInfos, AssetLives } from '../types/asset';
import type { PairInfos, PairLives } from '../types/pair';
import type { PoolLiveResponse } from '../types/pool';
import type { ChainInfos, ChainLives } from '../types/chain';
import { useMainStore } from 'provider/mainContext';
import type { ChainParamsResponse } from 'types/chain';
import { INITIAL_ASSET, IS_TEST } from 'COMMON_VARIABLES';
import {
  useAllAssetInfo,
  useAllAssetLive,
  useAllPairInfo,
  useAllPairLive,
  useAllChainInfo,
  useAllChainLive,
  useAllPoolLive,
  useParamsInfo,
  useAllLiquidFarmLive,
} from 'hooks/useAPI';
import { useEffect, useRef, useState } from 'react';
import type { LiquidFarmLiveRaw } from 'types/liquidFarm';
import useLiquidStakingAPR from 'hooks/farm/useLiquidStakingAPR';

interface Result<T> {
  data: T;
  isError: any;
  isLoading: any;
}

export default function useDataPolling() {
  const store = useMainStore();
  const [lastCreHeight, setLastCreHeight] = useState(0);
  const [networkDelayed, setNetworkDelayed] = useState(false);
  const [waitCount, setWaitCount] = useState(0);
  /* START - RAW DATA */
  // Asset Data (info, live)
  const { data: allAssetInfoData }: Result<AssetInfos> = useAllAssetInfo(60000);
  const { data: allAssetLiveData }: Result<AssetLives> = useAllAssetLive(5000);
  const { data: allPoolLiveData }: Result<PoolLiveResponse> = useAllPoolLive(6000);
  const { data: allLiquidFarmLiveData } = useAllLiquidFarmLive(6000);
  const { data: paramsInfoData }: Result<ChainParamsResponse> = useParamsInfo();

  // Pair Data (info, live)
  const { data: allPairInfoData }: Result<PairInfos> = useAllPairInfo();
  const { data: allPairLiveData }: Result<PairLives> = useAllPairLive();
  /* END - RAW DATA */

  // Chain Data (info, live)
  const { data: allChainInfoData }: Result<ChainInfos> = useAllChainInfo();
  const { data: allChainLiveData }: Result<ChainLives> = useAllChainLive(6000);

  const test = useRef(0);

  // Set ASSET data
  useEffect(() => {
    if (allAssetInfoData && allAssetLiveData) {
      const currentTime = Date.now();
      // console.log('allAssetLive', currentTime - test.current);
      test.current = currentTime;

      const info: any = {};
      const live: any = {};
      let isDelayed: boolean;

      allAssetInfoData.data?.forEach((assetInfo) => {
        if (!assetInfo?.isBeta || IS_TEST) {
          info[assetInfo.denom] = assetInfo;
        }
      });
      allAssetLiveData.data?.forEach((assetLive) => {
        live[assetLive.denom] = assetLive;
      });

      //Do delay check
      isDelayed = false;

      store.setAssetsData({ isInit: true, isDelayed, info, live });
    } else {
      //Do error handling
    }
  }, [allAssetInfoData, allAssetLiveData, store, allLiquidFarmLiveData?.data]);

  // Set PAIR data
  useEffect(() => {
    if (allPairInfoData && allPairLiveData) {
      const info: any = {};
      const live: any = {};
      let isDelayed: boolean;

      allPairInfoData.data?.forEach((pairInfo) => {
        info[pairInfo.pairId] = pairInfo;
      });
      allPairLiveData.data?.forEach((pairLive) => {
        live[pairLive.pairId] = pairLive;
      });

      //TODO: delay check by height or timestamp
      isDelayed = false;
      store.setPairsData({ isInit: true, isDelayed, info, live });
    } else {
      //TODO: error handling
    }
  }, [allPairInfoData, allPairLiveData, store]);

  // Set POOL live data
  useEffect(() => {
    if (allPoolLiveData) {
      const info: any = {};
      const live: any = {};
      let isDelayed: boolean;

      allPoolLiveData.data?.forEach((poolLive) => {
        live[poolLive.poolDenom] = poolLive;
      });

      //TODO: delay check by height or timestamp
      isDelayed = false;
      store.setPoolsData({ isInit: true, isDelayed, info, live });
    } else {
      //TODO: error handling
    }
  }, [allPoolLiveData, store]);

  /** @desc set LiquidFarm live data */
  useEffect(() => {
    if (allLiquidFarmLiveData) {
      const info: { [key: string]: any } = {};
      const live: { [key: string]: LiquidFarmLiveRaw } = {};

      allLiquidFarmLiveData.data?.forEach((liquidFarmLive) => {
        live[liquidFarmLive.lfDenom] = liquidFarmLive;
      });

      store.setLiquidFarmsData({ isInit: true, isDelayed: false, info, live });
    }
  }, [allLiquidFarmLiveData, store]);

  /** @desc set liquidStaking apr live data */
  const lsAPR = useLiquidStakingAPR();
  useEffect(() => store.setLiquidStakingApr(lsAPR), [store, lsAPR]);

  // Set Params info data
  useEffect(() => {
    if (paramsInfoData) {
      const info: any = {};
      const live: any = {};
      let isDelayed: boolean;

      paramsInfoData.data?.forEach((param) => {
        info[param.key] = param.value;
      });

      //TODO: delay check by height or timestamp
      isDelayed = false;
      store.setParamsData({ isInit: true, isDelayed, info, live });
    } else {
      //TODO: error handling
    }
  }, [paramsInfoData, store]);
  /* END - MAIN STORE DATA */

  useEffect(() => {
    if (allChainInfoData && allChainLiveData) {
      const info: any = {};
      const live: any = {};
      let isDelayed: boolean;

      allChainInfoData.data?.forEach((chainInfo) => {
        if (!chainInfo?.isBeta || IS_TEST) {
          info[chainInfo.chainId] = chainInfo;
        }
      });
      allChainLiveData.data?.forEach((chainLive) => {
        live[chainLive.chainId] = chainLive;
      });

      isDelayed = false;
      store.setChainsData({ isInit: true, isDelayed, info, live });
      const creHeight = live[INITIAL_ASSET.chainId]?.height;
      if (creHeight && creHeight > lastCreHeight) {
        setLastCreHeight(creHeight);
        setNetworkDelayed(false);
        setWaitCount(0);
      } else {
        if (waitCount > 2) {
          setNetworkDelayed(true);
        } else {
          setWaitCount(waitCount + 1);
        }
      }
    } else {
      //TODO: error handling
    }
  }, [allChainInfoData, allChainLiveData, store]);

  return { networkDelayed };
}
