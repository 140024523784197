import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import IconButton from 'components/buttons/IconButton';
import { useCallback, useMemo } from 'react';
import NA from './NA';

const FormulaItem = ({ value, unit }: { value: string; unit: string }) => {
  return (
    <span className="align-baseline space-x-0.5 text-on_surface_variant_light">
      <span className="font_caption_number_xs md:font_caption_number_s">{value}</span>
      <span className="font_caption_xs md:font_caption_s">{unit}</span>
    </span>
  );
};

type FormulaProps = {
  aValue: BigNumber;
  bRate?: BigNumber;
  aUnit: string;
  bUnit: string;
  dp: number;
  seperator?: string;
  isForward?: boolean;
  onSwitchForward?: (isForward: boolean) => void;
};

const Formula = ({ aValue, bRate, aUnit, bUnit, dp, seperator, isForward = true, onSwitchForward }: FormulaProps) => {
  const bValue = useMemo<BigNumber | undefined>(() => {
    if (bRate === undefined) return undefined;
    return isForward ? aValue.multipliedBy(bRate) : aValue.div(bRate);
  }, [isForward, aValue, bRate]);

  const onSwitch = useCallback(() => {
    const newIsForward = !isForward;
    onSwitchForward?.(newIsForward);
  }, [isForward, onSwitchForward]);

  if (bValue === undefined) return <NA />;

  return (
    <div className="flex items-center gap-x-1">
      <div className="flex items-center gap-x-1">
        <FormulaItem value={formatAmount(aValue, dp)} unit={isForward ? aUnit : bUnit} />
        <div className="font_caption_s text-on_surface_variant_light">{seperator}</div>
        <FormulaItem value={formatAmount(bValue, dp)} unit={isForward ? bUnit : aUnit} />
      </div>

      {onSwitchForward && <IconButton iconType="swap-horiz" type="tonal" size="xs" onClick={onSwitch} />}
    </div>
  );
};

export default Formula;
