import Chip from 'components/chips/Chip';

type OrdersTablePairTitleProps = {
  baseTicker?: string;
  quoteTicker?: string;
  isBuy?: boolean;
};

const OrdersTablePairTitle = ({ baseTicker, quoteTicker, isBuy }: OrdersTablePairTitleProps) => {
  return (
    <div className="flex items-center gap-x-1">
      <span className="align-baseline">
        <span className="font_title_s text-on_surface">{baseTicker}</span>
        <span className="font_title_xs text-on_surface_variant_light">/{quoteTicker}</span>
      </span>

      <Chip size="sm" label={isBuy ? 'Buy' : 'Sell'} color={isBuy ? 'green' : 'red'} />
    </div>
  );
};

export default OrdersTablePairTitle;
