import FAQWidget from 'components/widgets/FAQWidget';

const FAQ_LIST: { question: string; answers: string[] }[] = [
  {
    question: 'How long are the liquidity bonding periods on Crescent DEX?',
    answers: [`Crescent does not have bonding periods, and assets can be withdrawn at any time.`],
  },
  {
    question: 'How often are farming rewards distributed?',
    answers: [
      `Farming rewards are distributed each block, displayed under “My Investment”. This allows you to actively switch your investment position between pools with limited opportunity cost of lost rewards during the change.`,
    ],
  },
];

const FarmFAQArea = ({ className = '' }: { className?: string }) => {
  return (
    <footer
      className={`${className} relative w-full h-max md:h-[548px]`}
      style={{
        backgroundImage: 'linear-gradient(180deg, var(--color-gold80-o0) 0%, var(--color-gold80-o11) 100%)',
      }}
    >
      <FAQWidget
        title="Frequently Asked Questions"
        faqList={FAQ_LIST}
        className="relative w-full max-w-[75rem] m-auto"
      />
    </footer>
  );
};
export default FarmFAQArea;
