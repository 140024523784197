import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useStakingLive } from 'hooks/useAPI';

export default function useBcreAPR() {
  const { data: stakingData } = useStakingLive();

  const bcreApr = useMemo(() => {
    return stakingData?.data ? new BigNumber(stakingData.data.apr).toString() : '0';

  }, [stakingData]);
  return bcreApr;
}
