import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD, RewardInfo } from 'common/utils';
import TooltipInner from 'components/tooltips/TooltipInner';
import { PoolDetail } from 'types/pool';
import useFarmRewards, { RewardPlanType } from '../hooks/useFarmRewards';
import RewardsPlanItem from './rewards/RewardsPlanItem';
import Divider from 'components/divider/Divider';

const DailyRewards = ({
  pool,
  rewards,
  perUSD = 10000,
  multiplier,
}: {
  pool: PoolDetail;
  rewards: RewardInfo[];
  perUSD?: number;
  multiplier: number;
}) => {
  // const totalRewardDollarValue = useMemo(() => {
  //   let total = 0;
  //   rewards.forEach((reward) => {
  //     total += Number(
  //       getDollarValueOnCreChainByDenom(
  //         reward.denom,
  //         new BigNumber(reward.totalAmount).multipliedBy(multiplier).decimalPlaces(6, 1)
  //       )
  //     );
  //   });
  //   return new BigNumber(total);
  // }, [rewards]);

  const { plansDict, dailyTotalUSD } = useFarmRewards(pool, { perUSD });

  return (
    <TooltipInner
      padding={false}
      title={
        <div className="flex items-baseline gap-x-0.5">
          <div>Daily Rewards per</div>
          <div className="font_caption_number_s">
            {/** @descriptoin formatUSD is not used intentially here. */}
            {formatAmount(new BigNumber(perUSD), 0, { fixMantissa: true, symbol: '$' })}
          </div>
        </div>
      }
      highlight={{ text: formatUSD(dailyTotalUSD) }}
    >
      <div className="flex flex-col gap-y-0">
        {Object.keys(plansDict).map((key, index) => {
          const dict = plansDict[key as RewardPlanType];
          return dict ? (
            <div key={key}>
              {index > 0 && <Divider type="hard" />}
              <RewardsPlanItem title={dict.title} plans={dict.plans} unit="usd" />
            </div>
          ) : null;
        })}
      </div>
    </TooltipInner>
  );
};

export default DailyRewards;
