import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD } from 'common/utils';
import { COMPACT_DECIMAL_PLACE } from 'COMMON_VARIABLES';
import Coin from 'components/coins/Coin';
import { useMemo } from 'react';
import type { AssetDetail } from 'types/asset';

type TokenOptionProps = {
  asset: AssetDetail;
  dimmed?: boolean;
  showTokenDetail?: boolean;
  onClick?: () => void;
};

const TokenOption = ({ asset, dimmed = false, showTokenDetail = true, onClick }: TokenOptionProps) => {
  const availableBalance = useMemo<BigNumber>(
    () => asset.availableBalance.shiftedBy(-asset.exponent),
    [asset?.availableBalance, asset.exponent]
  );

  return (
    <button
      type="button"
      className={`w-full min-h-[4rem] flex justify-between items-center gap-x-2 px-4 py-3 bg-transparent hover:bg-on_surface_variant_dark_o24 ${
        dimmed ? 'opacity-40' : ''
      }`}
      onClick={onClick}
    >
      {/* left */}
      <div className="flex items-center gap-x-1">
        <Coin asset={asset} size="36px" />
        <div className="space-y-0.5 text-left">
          <div className="font_title_m text-on_surface">{asset.ticker}</div>
          <div className="font_caption_s text-on_surface_variant_dark">{asset.chainName}</div>
        </div>
      </div>

      {/* right */}
      {showTokenDetail && (
        <div className="space-y-0.5 text-right">
          <div className="inline-flex items-center gap-x-0.5">
            <div className="font_data_number_s text-on_surface">
              {formatAmount(availableBalance, COMPACT_DECIMAL_PLACE)}
            </div>
            <div className="font_caption_s text-on_surface_variant_light">{asset.ticker}</div>
          </div>
          <div className="font_caption_number_s text-on_surface_variant_dark">
            ≈ {formatUSD(availableBalance.multipliedBy(asset.priceOracle))}
          </div>
        </div>
      )}
    </button>
  );
};

export default TokenOption;
