import { useMemo } from 'react';
import { isNthChild } from './utils';

type LoadingRowsProps = {
  rowsCnt: number;
  /** this makes height of LoadingRow */
  loadingDataTypoClassName?: string;
};

export default function LoadingRows({
  rowsCnt,
  loadingDataTypoClassName = 'font_headline_number_xl',
}: LoadingRowsProps) {
  const rows = useMemo<number[]>(() => {
    return new Array(rowsCnt < 0 || Number.isNaN(rowsCnt) ? 0 : rowsCnt).fill(0);
  }, [rowsCnt]);

  return (
    <div className="min-w-[75%] max-w-[100%] grid grid-cols-3 gap-x-[0.5em] gap-y-[0.8em]">
      {rows.map((_, index) => (
        <div
          key={index}
          className={`text-transparent bg_loading ${loadingDataTypoClassName} ${
            rowsCnt === 1
              ? 'col-span-3'
              : isNthChild(index + 1, 4, 1)
              ? 'col-start-1 col-end-3'
              : isNthChild(index + 1, 4, 0)
              ? 'col-start-3 col-end-4'
              : ''
          }`}
        >
          <span>Loading</span>
        </div>
      ))}
    </div>
  );
}
