import { ProposalResult } from 'types/gov';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

export default function VotingBar({ data, className = '' }: { data: ProposalResult; className?: string }) {
  const total = useMemo(() => {
    let _total = Object.values(data).reduce<BigNumber>((acc, cur) => acc.plus(cur), new BigNumber(0));
    if (_total.gt(0)) {
      return _total;
    }
    return new BigNumber(1);
  }, [data]);

  return (
    <div className={`w-full h-5 flex bg-dataviz_categorical_empty ${className}`}>
      <div
        key={'yes'}
        className={`h-5 bg-gov_yes`}
        style={{ width: `${new BigNumber(data.yes).dividedBy(total).multipliedBy(100).toFixed(4)}%` }}
      />
      <div
        key={'no'}
        className={`h-5 bg-gov_no`}
        style={{ width: `${new BigNumber(data.no).dividedBy(total).multipliedBy(100).toFixed(4)}%` }}
      />
      <div
        key={'no_with_veto'}
        className={`h-5 bg-gov_nowithveto`}
        style={{ width: `${new BigNumber(data.no_with_veto).dividedBy(total).multipliedBy(100).toFixed(4)}%` }}
      />
      <div
        key={'abstain'}
        className={`h-5 bg-gov_abstain`}
        style={{ width: `${new BigNumber(data.abstain).dividedBy(total).multipliedBy(100).toFixed(4)}%` }}
      />
    </div>
  );
}
