import { useMemo, useState } from 'react';
import { observer, Observer } from 'mobx-react-lite';
import OrderbookChartWidget from 'components/orderbook/orderbookChartWidget';
import TradingWidget from 'components/orderbook/tradingWidget';
import OrderbookWidget from 'components/orderbook/orderbookWidget';
import useOrderbookData from 'hooks/useOrderbookData';
import OrderbookHeader from './orderbookHeader';
import OrderBoardWidget from './orderBoardWidget';
import Widget from 'components/widgets/components/Widget';
import Spinner from 'components/spinners/Spinner';
import useTradePairSelect from './hooks/useTradePairSelect';

function OrderbookSet() {
  const { selectedPairId, orderIndex, onSelectPairId, onOrderIndexChange } = useTradePairSelect();

  const [selectedPrice, setSelectedPrice] = useState<any>({ price: null, displayPrice: null, totalAmount: 0 });
  const { selectedOrderbookPair, orderbook, orderbookPairs } = useOrderbookData(selectedPairId ?? '', 3);
  const [tickPrecisionDisplayValue, setTickPrecisionDisplayValue] = useState<string>('');

  useMemo(() => {
    console.group('%c PAIR ID ▶︎ PAIR', 'color:yellow; background-color:black; font-size:24px;');
    let consoleData: any = [];
    orderbookPairs.forEach((pair) => {
      consoleData.push({ pairId: pair.pairId, title: pair.title });
    });
    consoleData?.sort((a: any, b: any) => a.pairId - b.pairId);
    consoleData?.forEach((data) => {
      console.log(`${data.pairId} ▶︎ ${data.title}`);
    });
    console.groupEnd();
    // console.table(consoleData);
  }, [orderbookPairs.length]);

  return (
    <Observer>
      {() => (
        <>
          <div className="w-full md:overflow-x-auto">
            <div className="w-full px-2 py-3 space-y-1 md:p-3 md:space-y-3 md:min-w-[1280px]">
              <OrderbookHeader
                pair={selectedOrderbookPair}
                availabePairs={orderbookPairs}
                onSelectPair={onSelectPairId}
              />

              <div className="flex flex-col justify-between items-stretch gap-x-3 gap-y-1 md:flex-row md:h-orderbook-viz">
                {/* chart */}
                <OrderbookChartWidget pairId={selectedPairId} className="hidden md:block md:grow md:shrink" />

                <div className="flex justify-between items-stretch gap-x-1 md:gap-x-3">
                  {selectedPairId && orderbook ? (
                    <OrderbookWidget
                      selectedPairId={selectedPairId}
                      orderbook={orderbook}
                      selectPrice={(p) => setSelectedPrice(p)}
                      tickPrecisionDisplayValue={tickPrecisionDisplayValue}
                      onChangeTickPrecisionDisplayValue={setTickPrecisionDisplayValue}
                      className="basis-[45%] grow-0 shrink-0 md:basis-auto"
                    />
                  ) : (
                    <Widget className="basis-[45%] grow-0 shrink-0 md:basis-auto">
                      <Spinner spinnerClassName="border-on_primary_dark" className="absolute inset-0 w-full h-full" />
                    </Widget>
                  )}

                  <TradingWidget
                    orderbookPair={selectedOrderbookPair}
                    selectedPrice={selectedPrice}
                    tickPrecisionDisplayValue={tickPrecisionDisplayValue}
                    defaultOrderIndex={orderIndex}
                    onOrderIndexChange={onOrderIndexChange}
                    className="grow shrink md:grow-0 md:shrink-0"
                  />
                </div>
              </div>

              <OrderBoardWidget />
            </div>
          </div>
        </>
      )}
    </Observer>
  );
}

export default observer(OrderbookSet);
