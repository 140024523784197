import { Tab } from '@headlessui/react';
import RedDot from 'components/pings/RedDot';
import { Fragment, useCallback, useMemo, useState } from 'react';
import TabItem, { TabSize, TabStyle } from './TabItem';

type TabAlign = 'between' | 'center';

const TAB_SELECTOR_FLEX_JUSTIFY_DICT: { [key in TabAlign]: string } = {
  between: 'justify-between',
  center: 'justify-center',
};

const TAB_SELECTOR_SPACE_Y_DICT: { [key in TabStyle]: string } = {
  line: 'space-y-3 md:space-y-6',
  segmented: 'space-y-4',
  filled: 'space-y-4',
  text: 'space-y-4',
};

const TAB_SELECTOR_GAP_X_DICT: { [key in TabStyle]: string } = {
  line: '',
  segmented: '',
  filled: 'gap-x-1',
  text: 'gap-x-1',
};

export type TabPanel = {
  key: string;
  labelTooltipContent?: string | JSX.Element;
  content?: JSX.Element;
  ping?: boolean;
};

type TabSelectorProps = {
  type: TabStyle;
  size?: TabSize;
  panels: TabPanel[];
  defaultKey?: string;
  fallbackIndex?: number;
  tabsMaxWidth?: string;
  left?: JSX.Element;
  right?: JSX.Element;
  align?: TabAlign;
  onChangeTab?: (index: number) => void;
};

const TabSelector = ({
  type,
  size,
  panels,
  defaultKey,
  fallbackIndex,
  tabsMaxWidth,
  left,
  right,
  align = 'between',
  onChangeTab,
}: TabSelectorProps) => {
  const defaultIndex = useMemo<number>(() => {
    if (fallbackIndex !== undefined) return fallbackIndex;
    const index = panels.findIndex((tab) => tab.key === defaultKey);
    return index >= 0 ? index : 0;
  }, [fallbackIndex, panels, defaultKey]);

  const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);

  const onChange = useCallback(
    (index: number) => {
      const foundTab: TabPanel | undefined = panels[index];
      const newIndex = foundTab ? index : 0;
      setSelectedIndex(newIndex);
      onChangeTab?.(newIndex);
    },
    [panels, onChangeTab]
  );

  if (panels.length < 1) return null;

  return (
    <Tab.Group defaultIndex={defaultIndex} onChange={onChange}>
      <div className={TAB_SELECTOR_SPACE_Y_DICT[type]}>
        <div className={`w-full flex items-center ${TAB_SELECTOR_FLEX_JUSTIFY_DICT[align]}`}>
          {/* on the left */}
          {left}

          {/* tabs */}
          <Tab.List
            className={`relative w-full flex items-center ${TAB_SELECTOR_GAP_X_DICT[type]} overflow-x-auto overflow-y-hidden`}
            style={{ maxWidth: tabsMaxWidth }}
          >
            {panels.map((tab, index) => (
              <Tab as={Fragment} key={tab.key}>
                {({ selected }) => (
                  <div className="relative w-full h-max">
                    <TabItem
                      key={tab.key}
                      labelTooltipContent={tab.labelTooltipContent}
                      size={size}
                      label={tab.key}
                      type={type}
                      active={selected}
                      order={index === 0 ? 'start' : index === panels.length - 1 ? 'end' : 'middle'}
                    />
                    {/* ping */}
                    {tab.ping && <RedDot size="sm" className="absolute top-1 right-1" />}
                  </div>
                )}
              </Tab>
            ))}

            {/* indexer is not included in TabItem component for animation */}
            {type === 'line' && (
              <div
                className={`absolute left-0 right-0 bottom-[1px] h-[2px] transition-all !duration-200 bg-primary box_glow_3`}
                style={{
                  transform: `translateX(${selectedIndex ? selectedIndex * 100 : 0}%)`,
                  width: `${100 / panels.length}%`,
                }}
              ></div>
            )}
          </Tab.List>

          {/* on the right */}
          {right}
        </div>

        {/* panels */}
        {panels.some((panel) => panel.content !== undefined) && (
          <Tab.Panels>
            {panels.map((panel) => (
              <Tab.Panel key={panel.key}>{panel.content}</Tab.Panel>
            ))}
          </Tab.Panels>
        )}
      </div>
    </Tab.Group>
  );
};

export default TabSelector;
