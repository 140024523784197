import TransitionModal from './TransitionModal';
import { useCallback, useState } from 'react';
import ModalHeader from './components/ModalHeader';
import Button from 'components/buttons/Button';
import { MODAL_TITLES } from 'COMMON_VARIABLES';

const ProposalVoteModal = ({
  isOpen,
  vote,
  close,
}: {
  isOpen: boolean;
  vote: (option: number) => void;
  close: () => void;
}) => {
  const [state, setState] = useState({
    type: 1,
    isLoading: false,
  });

  const _vote = useCallback(
    async (number) => {
      setState({
        type: number,
        isLoading: true,
      });
      await vote(number);
      setState({
        type: number,
        isLoading: false,
      });
    },
    [vote]
  );

  return (
    <TransitionModal isOpen={isOpen} close={close} size="sm">
      <ModalHeader title={MODAL_TITLES.VOTE} onCloseClick={close} />

      <div className="grid grid-cols-2 gap-3 px-4 pb-5 md:px-5 md:pb-6">
        <Button
          size="md"
          color="yes"
          type="outlined"
          label="Yes"
          status={state.isLoading && state.type === 1 ? 'loading' : 'enabled'}
          onClick={() => _vote(1)}
          className="w-full"
        />

        <Button
          size="md"
          color="no"
          type="outlined"
          label="No"
          status={state.isLoading && state.type === 3 ? 'loading' : 'enabled'}
          onClick={() => _vote(3)}
          className="w-full"
        />

        <Button
          size="md"
          color="veto"
          type="outlined"
          label="NoWithVeto"
          status={state.isLoading && state.type === 4 ? 'loading' : 'enabled'}
          onClick={() => _vote(4)}
          className="w-full"
        />

        <Button
          size="md"
          color="abstain"
          type="outlined"
          label="Abstain"
          status={state.isLoading && state.type === 2 ? 'loading' : 'enabled'}
          onClick={() => _vote(2)}
          className="w-full"
        />
      </div>
    </TransitionModal>
  );
};

export default ProposalVoteModal;
