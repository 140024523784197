import { SignParams } from 'types/signingClients';
import { v1 as uuidv1 } from 'uuid';
import { incrementGasMultiplier, notifyTxError } from 'common/utils';
import { checkSigningDataError } from 'common/signingClients/utils/signUtils';
import callToast from 'common/callToast';

export function initWebSocket({ type, chainData, txData, creChainInfo }) {
  checkSigningDataError(chainData);
  // EVM-TEST
  // const address = `wss://evmos.crescent.network:26657/websocket`;
  const address = `wss://${
    type === 'deposit' ? chainData?.info?.[txData?.originChainId]?.wsEndpoint : creChainInfo?.wsEndpoint
  }/websocket`;
  const webSocket: WebSocket = new WebSocket(address);
  webSocket.onopen = () => {
    console.log('websocket connected');
  };
  webSocket.onclose = () => {
    console.log('websocket disconnected');
  };
  return webSocket;
}

export function webSocketTxBroadcastHandler(
  websocket: WebSocket,
  txHash: string,
  txType?: SignParams['type']
): Promise<void> {
  return new Promise((resolve, reject) => {
    websocket.send(
      JSON.stringify({
        jsonrpc: '2.0',
        method: 'subscribe',
        params: [`tm.event='Tx' AND tx.hash='${txHash}'`],
        id: uuidv1(),
      })
    );
    websocket.onmessage = (m) => {
      console.log('m?.data', m?.data);
      if (m?.data) {
        const data = JSON.parse(m.data);
        if (data?.result?.query?.includes(txHash)) {
          if (data?.result?.data?.value?.TxResult?.result?.code) {
            notifyTxError(data?.result?.data?.value?.TxResult?.result?.log);
            if (txType) incrementGasMultiplier(txType);
            console.log('Broadcast fail', data);
          } else {
            console.log('Broadcast success');
            if (txType) incrementGasMultiplier(txType, false);
          }
          // websocket.close();
          // resolve();
        }
      }
      websocket.close();
      resolve();
    };
  });
}
