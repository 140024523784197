import { TableCellAlign, TableField } from './types';

export enum TableStyles {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  UNCOMPACT = 'UNCOMPACT',
  BORDERLESS = 'BORDERSS',
  BORDERLESS_COMPACT = 'BORDERLESS_COMPACT',
  COMPACT = 'COMPACT',
}

/** grid */
export const TABLE_CONTAINER_BORDER_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'md:border border-outline_soft rounded-8px',
  [TableStyles.SECONDARY]: '',
  [TableStyles.UNCOMPACT]: '',
  [TableStyles.BORDERLESS_COMPACT]: '',
  [TableStyles.BORDERLESS]: '',
  [TableStyles.COMPACT]: '',
};

export const TABLE_SPACE_Y_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'flex flex-col gap-y-4 md:block md:space-y-0',
  [TableStyles.SECONDARY]: 'space-y-2 md:space-y-0',
  [TableStyles.UNCOMPACT]: 'space-y-0',
  [TableStyles.BORDERLESS_COMPACT]: 'space-y-0',
  [TableStyles.BORDERLESS]: 'space-y-0',
  [TableStyles.COMPACT]: 'space-y-0',
};

// pl-4 pr-24
export const TABLE_FIELD_RIGHT_PADDING = '!pr-24';
export const TABLE_ROW_RIGHT_PADDING = 'md:pr-24';

export const TABLE_FIELD_GRID_X_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'hidden md:flex items-center justify-between gap-x-9 px-4',
  [TableStyles.SECONDARY]: 'hidden md:flex items-center justify-between gap-x-9 px-4',
  [TableStyles.UNCOMPACT]: 'flex items-center justify-between gap-x-2 px-4',
  [TableStyles.BORDERLESS_COMPACT]: 'flex items-center justify-between gap-x-2 px-2 md:px-4',
  [TableStyles.BORDERLESS]: 'flex items-center justify-between gap-x-2 px-3 md:px-4',
  [TableStyles.COMPACT]: 'hidden md:flex items-center justify-between gap-x-4 px-3 md:px-4',
};

export const TABLE_FIELD_GRID_Y_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'min-h-[2.75rem] py-2',
  [TableStyles.SECONDARY]: 'min-h-[2rem] py-2',
  [TableStyles.UNCOMPACT]: 'min-h-[2rem] py-2',
  [TableStyles.BORDERLESS_COMPACT]: 'pt-4 pb-3',
  [TableStyles.BORDERLESS]: 'min-h-[2rem] py-2',
  [TableStyles.COMPACT]: 'min-h-[2rem] py-0',
};

// md:pl-4 md:pr-24
export const TABLE_ROW_GRID_X_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'flex flex-col md:flex-row items-stretch md:items-center justify-between gap-x-9 px-0 md:px-4',
  [TableStyles.SECONDARY]:
    'flex flex-col md:flex-row items-stretch md:items-center justify-between gap-x-9 px-0 md:px-4',
  [TableStyles.UNCOMPACT]: 'flex items-center justify-between gap-x-2 px-4',
  [TableStyles.BORDERLESS_COMPACT]: 'flex items-center justify-between gap-x-2 px-2 md:px-4',
  [TableStyles.BORDERLESS]: 'flex items-center justify-between gap-x-2 px-3 md:px-4',
  [TableStyles.COMPACT]:
    'grid grid-cols-2 items-start gap-x-2 px-3 md:flex md:items-center md:justify-between md:gap-x-4 md:px-4',
};

export const TABLE_ROW_GRID_Y_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'min-h-[4.25rem] gap-y-4 pt-3 pb-4 md:py-3',
  [TableStyles.SECONDARY]: 'min-h-[4.25rem] gap-y-4 pt-3 pb-4 md:py-3',
  [TableStyles.UNCOMPACT]: 'min-h-[4.25rem] gap-y-4 pt-3 pb-4 md:py-3',
  [TableStyles.BORDERLESS_COMPACT]: 'min-h-[1rem] gap-y-4 pt-0 pb-3',
  [TableStyles.BORDERLESS]: 'min-h-[3.5rem] gap-y-0 py-2',
  [TableStyles.COMPACT]: 'gap-y-4 pt-2 pb-3 md:min-h-[2rem] md:gap-y-0 md:pb-2',
};

export const TABLE_CELL_GRID_X_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'flex items-center gap-x-1 pl-3 pr-3 first:pr-2 md:pl-0 md:pr-0 md:first-pr-0',
  [TableStyles.SECONDARY]: 'flex items-center gap-x-1 pl-3 pr-3 first:pr-2 md:pl-0 md:pr-0 md:first-pr-0',
  [TableStyles.UNCOMPACT]: 'flex items-center gap-x-1',
  [TableStyles.BORDERLESS_COMPACT]: 'flex items-center gap-x-1',
  [TableStyles.BORDERLESS]: 'flex items-center gap-x-1',
  [TableStyles.COMPACT]:
    'flex flex-col items-start gap-y-1 first:flex-row first:items-center first:justify-between first:col-span-2 first:w-full md:first-w-auto md:flex-row',
};

export const TABLE_CELL_GRID_Y_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: '',
  [TableStyles.SECONDARY]: '',
  [TableStyles.UNCOMPACT]: '',
  [TableStyles.BORDERLESS_COMPACT]: '',
  [TableStyles.BORDERLESS]: '',
  [TableStyles.COMPACT]: '',
};

export const TABLE_CELL_BORDER_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: '',
  [TableStyles.SECONDARY]: '',
  [TableStyles.UNCOMPACT]: '',
  [TableStyles.BORDERLESS_COMPACT]: '',
  [TableStyles.BORDERLESS]: '',
  [TableStyles.COMPACT]: '',
};

export const TABLE_CELL_TEXT_COLOR_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'text-white',
  [TableStyles.SECONDARY]: 'text-white',
  [TableStyles.UNCOMPACT]: 'text-white',
  [TableStyles.BORDERLESS_COMPACT]: 'text-on_surface',
  [TableStyles.BORDERLESS]: 'text-on_surface',
  [TableStyles.COMPACT]: 'text-on_surface_variant_light',
};

export const TABLE_CELL_FONT_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'font_data_number_s md:font_data_number_m',
  [TableStyles.SECONDARY]: 'font_data_number_s md:font_data_number_m',
  [TableStyles.UNCOMPACT]: 'font_data_number_s md:font_data_number_s',
  [TableStyles.BORDERLESS_COMPACT]: 'font_data_number_s md:font_data_number_m',
  [TableStyles.BORDERLESS]: 'font_data_number_s md:font_data_number_m',
  [TableStyles.COMPACT]: 'font_data_number_s',
};

/** cell alignment */
export const TABLE_CELL_ALIGN_DICT: { [key in TableStyles]: { [key in TableCellAlign]: string } } = {
  [TableStyles.PRIMARY]: {
    left: 'justify-between md:justify-start',
    center: 'justify-between md:justify-center',
    right: 'justify-between md:justify-end',
  },
  [TableStyles.SECONDARY]: {
    left: 'justify-between md:justify-start',
    center: 'justify-between md:justify-center',
    right: 'justify-between md:justify-end',
  },
  [TableStyles.UNCOMPACT]: {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  },
  [TableStyles.BORDERLESS_COMPACT]: {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  },
  [TableStyles.BORDERLESS]: {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  },
  [TableStyles.COMPACT]: {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  },
};

/** border */
export const TABLE_ROW_SUBJSX_BORDER_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'border-x-1 border-b-1 border-outline_soft',
  [TableStyles.SECONDARY]: 'border-x-1 border-b-1 border-outline_soft',
  [TableStyles.UNCOMPACT]: 'border-x-1 border-b-1 border-outline_soft',
  [TableStyles.BORDERLESS_COMPACT]: 'border-x-1 border-b-1 border-transparent',
  [TableStyles.BORDERLESS]: 'border-transparent',
  [TableStyles.COMPACT]: 'border-transparent',
};

/** foldable cell on mobile */
export const TABLE_MOBILE_FOLD_WRAPPER_BG_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'bg_surface_1 text-secondary',
  [TableStyles.SECONDARY]: 'bg-transparent',
  [TableStyles.UNCOMPACT]: 'bg-transparent',
  [TableStyles.BORDERLESS_COMPACT]: 'bg-transparent',
  [TableStyles.BORDERLESS]: 'bg-transparent',
  [TableStyles.COMPACT]: 'bg-transparent',
};

export const TABLE_MOBILE_FOLDED_AREA_BG_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'bg-transparent',
  [TableStyles.SECONDARY]: 'bg-transparent',
  [TableStyles.UNCOMPACT]: 'bg-transparent',
  [TableStyles.BORDERLESS_COMPACT]: 'bg-transparent',
  [TableStyles.BORDERLESS]: 'bg-transparent',
  [TableStyles.COMPACT]: 'bg-transparent',
};

/** color */
export const TABLE_ROW_GROUP = 'group';

export const TABLE_FIELD_BG_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'bg_surface_-3',
  [TableStyles.SECONDARY]: 'bg_surface_1',
  [TableStyles.UNCOMPACT]: 'bg-tranparent',
  [TableStyles.BORDERLESS_COMPACT]: 'bg-tranparent',
  [TableStyles.BORDERLESS]: 'bg-tranparent',
  [TableStyles.COMPACT]: 'bg-transparent',
};

// md:group-last:border-b-0
// export const TABLE_ROW_BG_DICT: { [key in TableStyles]: string } = {
//   [TableStyles.PRIMARY]: 'bg-surface border-t border-outline_soft',
//   [TableStyles.SECONDARY]: 'bg_surface_1 md:border-t border-outline_soft',
//   [TableStyles.BORDERLESS_COMPACT]: 'bg-tranparent',
//   [TableStyles.BORDERLESS]: 'bg-tranparent',
//   [TableStyles.COMPACT]: `bg-transparent border-t border-outline_soft`,
// };

export const TABLE_ROW_BG_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'bg-surface',
  [TableStyles.SECONDARY]: 'bg_surface_1',
  [TableStyles.UNCOMPACT]: 'bg-tranparent',
  [TableStyles.BORDERLESS_COMPACT]: 'bg-tranparent',
  [TableStyles.BORDERLESS]: 'bg-tranparent',
  [TableStyles.COMPACT]: `bg-tranparent`,
};

export const TABLE_ROW_BORDER_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]:
    'border-outline_soft border rounded-8px md:border-0 md:border-b md:last:border-b-0 md:rounded-none',
  [TableStyles.SECONDARY]:
    'border border-outline_hard rounded-8px md:border-0 md:border-b md:last:border-b-0 md:border-outline_soft md:rounded-none',
  [TableStyles.UNCOMPACT]: 'border-b last:border-b-0 border-outline_soft',
  [TableStyles.BORDERLESS_COMPACT]: '',
  [TableStyles.BORDERLESS]: '',
  [TableStyles.COMPACT]: `border-b last:border-b-0 border-outline_soft`,
};

export const TABLE_FIELD_BORDER_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'border-b border-outline_soft',
  [TableStyles.SECONDARY]: 'md:border-b border-outline_soft',
  [TableStyles.UNCOMPACT]: 'border-b border-outline_soft',
  [TableStyles.BORDERLESS_COMPACT]: '',
  [TableStyles.BORDERLESS]: 'border-b border-outline_soft',
  [TableStyles.COMPACT]: `border-b border-outline_soft`,
};

export const TABLE_ROW_HOVER_LAYER =
  'md:before:block before:absolute before:w-full before:h-full before:top-0 before:right-0 before:left-0 before:bottom-0 before:bg-transparent before:transition-all';

export const TABLE_ROW_HOVER_LAYER_BG_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: `${TABLE_ROW_HOVER_LAYER} md:before:hover:bg_moonlight_thin`,
  [TableStyles.SECONDARY]: `${TABLE_ROW_HOVER_LAYER} md:before:hover:bg_moonlight_thin`,
  [TableStyles.UNCOMPACT]: `${TABLE_ROW_HOVER_LAYER} md:before:hover:bg-surface_variant_o24`,
  [TableStyles.BORDERLESS_COMPACT]: '',
  [TableStyles.BORDERLESS]: `${TABLE_ROW_HOVER_LAYER} before:hover:bg-on_surface_variant_dark_o24`,
  [TableStyles.COMPACT]: `${TABLE_ROW_HOVER_LAYER} before:hover:bg-on_surface_variant_dark_o24`,
};

/** text */
export const TABLE_FIELD_TYPO_DICT: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'font_caption_s md:font_caption_m text-on_surface_variant_dark',
  [TableStyles.SECONDARY]: 'font_caption_xs md:font_caption_s text-on_surface_variant_dark',
  [TableStyles.UNCOMPACT]: 'font_caption_xs md:font_caption_s text-on_surface_variant_dark',
  [TableStyles.BORDERLESS_COMPACT]: 'font_caption_xs md:font_caption_s text-on_surface_variant_dark',
  [TableStyles.BORDERLESS]: 'font_caption_xs md:font_caption_s text-on_surface_variant_dark',
  [TableStyles.COMPACT]: 'font_caption_xs md:font_caption_s text-on_surface_variant_dark',
};

export const TABLE_ROW_TYPO = 'text-white';
export const TABLE_ROW_MORE_BUTTON_COLOR = 'text-white';

export const TABLE_EXPAND_ICON_GRID_X: { [key in TableStyles]: string } = {
  [TableStyles.PRIMARY]: 'px-1 md:px-2',
  [TableStyles.SECONDARY]: 'px-0 md:px-2',
  [TableStyles.UNCOMPACT]: 'px-0 md:px-2',
  [TableStyles.BORDERLESS_COMPACT]: 'px-0 md:px-2',
  [TableStyles.BORDERLESS]: 'px-0 md:px-2',
  [TableStyles.COMPACT]: 'px-0 md:px-2',
};

/** style generators */
export const getTableCellAlignClassName = <T>(field: TableField<T>, type: TableStyles) => {
  return `${TABLE_CELL_ALIGN_DICT[type][field.align ?? 'left']} ${
    field.widthPx !== undefined || field.widthRatio !== undefined ? 'grow-0 shrink-0' : 'grow shrink'
  }`;
};

export const getTableCellWidthStyle = <T>(field: TableField<T>) => {
  return {
    flexBasis:
      field.widthPx !== undefined
        ? `${field.widthPx}px`
        : field.widthRatio !== undefined
        ? `${field.widthRatio}%`
        : 'auto w-full',
  };
};

export const getHidableAreaClassName = (isOpen: boolean) =>
  `relative transition-all ease-out ${isOpen ? '' : '-z-10 max-h-0 !border-b-0 opacity-0'}`;
