import { InfoAsset } from 'types/asset';
import { LogoStacking } from './constants';
import CoinList from './CoinList';
import { CoinColor, CoinSize } from './constans';

type CoinPairProps = {
  color?: CoinColor;
  assets: [InfoAsset, InfoAsset];
  size?: CoinSize;
};

const CoinPair = ({ color, assets, size = '24px' }: CoinPairProps) => {
  return (
    <div className={color === 'liquid' ? 'w-fit p-[1px] rounded-full bg-liquid' : ''}>
      <CoinList assets={assets} size={size} color={color} stacking={LogoStacking.FIRST_ON_TOP} />
    </div>
  );
};

export default CoinPair;
