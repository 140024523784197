import Icon from 'components/icon';
import { FeedbackType } from './types';

const TOAST_CONTENT_BG_DICT: { [key in FeedbackType]: string } = {
  info: 'bg_toast_flag_blue',
  success: 'bg_toast_flag_green',
  warning: 'bg_toast_flag_yellow',
  error: 'bg_toast_flag_red',
  waiting: 'bg_toast_flag_gray',
  congrats: 'bg_toast_flag_gold',
};

type ToastBodyProps = {
  type: FeedbackType;
  title: string | JSX.Element;
  content: JSX.Element | string;
  link?: { label: string; href: string };
  className?: string;
};

const ToastBody = ({ type, title, content, link, className = '' }: ToastBodyProps) => {
  return (
    <div className={`w-full overflow-hidden px-4 pt-4 pb-5 space-y-6 ${TOAST_CONTENT_BG_DICT[type]} ${className}`}>
      <div className={`w-full ${typeof content === 'string' ? 'space-y-2' : 'space-y-4'}`}>
        <div className="font_title_m text-on_surface">{title}</div>
        <div className="font_body_s text-on_surface_variant_light">{content}</div>
      </div>

      {link !== undefined && (
        <div className="mt-6">
          <a
            href={link.href}
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-x-0.5 text-on_surface_variant_light hover:opacity-80"
          >
            <div className="font_caption_s underline">{link.label}</div>
            <Icon type="open-in-new" size="16px" />
          </a>
        </div>
      )}
    </div>
  );
};

export default ToastBody;
