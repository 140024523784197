import BigNumber from 'bignumber.js';
import { getAmp, getPoolFeaturesScore, getTvlByPool } from 'common/utils';
import { TableStyles } from 'components/table/styles';
import Table from 'components/table/Table';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo, useState } from 'react';
import type { MyPoolDetail, PoolDetail } from 'types/pool';
import PoolApr from './PoolApr';
import PoolFeatures from './PoolFeatures';
import PoolRange from './PoolRange';
import PoolRewards from './PoolRewards';
import { TableField } from 'components/table/types';
import PoolId from './PoolId';
import { EMPTY_TEXTS, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import MyPoolBalanceDetails from './tooltip/myPoolBalance/MyPoolBalanceDetails';
import { isMyPoolDetail } from './utils';
import MyRewardsDetails from './tooltip/MyRewardsDetails';
import useClaimableRewardInfo from 'hooks/farm/useClaimableRewardInfo';
import PoolTitle from './PoolTitle';
import RewardsEmissionPerDay from './tooltip/RewardsEmissionPerDay';

type TablePoolRow = {
  pool: PoolDetail | MyPoolDetail;
  poolTitle: JSX.Element;
  poolId: number;
  poolIdSet: JSX.Element;
  features: JSX.Element;
  featuresScore: number;
  poolIdAndFeatures: JSX.Element;
  range: JSX.Element;
  amp: BigNumber;
  apr: number;
  aprSet: JSX.Element;
  rewards: JSX.Element;
  rewardsTooltipContent: JSX.Element | undefined;
  tvl: BigNumber;
  // tvlTooltipContent: JSX.Element;
  /** my pool */
  balance: BigNumber | undefined;
  // balanceSet: JSX.Element | undefined;
  balanceTooltipContent: JSX.Element | undefined;
  myRewards: BigNumber | undefined;
  myRewardsTooltipContent: JSX.Element | undefined;
  ping: boolean;
  isFoldableOpen: boolean;
};

type PoolsTableField = TableField<TablePoolRow>;

type PoolsTableProps = {
  pools: PoolDetail[] | MyPoolDetail[];
  type?: TableStyles;
  dSortValue?: string;
  dIsAsc?: boolean;
  onPoolClick?: (pool: PoolDetail) => void;
  /** it determines if localStorage flow will be used */
  localStorageKeyPrefix?: string;
};

const PoolsTable = ({
  pools,
  type = TableStyles.PRIMARY,
  dSortValue,
  dIsAsc,
  onPoolClick,
  localStorageKeyPrefix,
}: PoolsTableProps) => {
  const store = useMainStore();
  const allPools = store.getPools();

  const { getRewardInfo } = useClaimableRewardInfo();

  /** @desc foldableOnMobile */
  const storedClosedPoolIds = useMemo<Set<number> | null>(() => {
    const localStorageRawData = localStorage.getItem(`${localStorageKeyPrefix}-foldable-closed-pool-ids`);
    return localStorageRawData ? new Set([...JSON.parse(localStorageRawData)]) : null;
  }, [localStorageKeyPrefix]);

  const allPoolIds = useMemo<Set<number>>(() => new Set([...allPools.map((pool) => pool.poolId)]), [allPools]);
  const [closedPoolIds, setClosedPoolIds] = useState<Set<number>>(storedClosedPoolIds ?? allPoolIds);

  const onToggleFoldableOnMobile = useCallback(
    (row: TablePoolRow, isOpen: boolean) => {
      const newIds = new Set([...closedPoolIds]);
      isOpen ? newIds.delete(row.poolId) : newIds.add(row.poolId);

      setClosedPoolIds(newIds);

      /** localStorage */
      if (localStorageKeyPrefix)
        localStorage.setItem(`${localStorageKeyPrefix}-foldable-closed-pool-ids`, JSON.stringify([...newIds]));
    },
    [closedPoolIds, localStorageKeyPrefix]
  );

  const tablePoolRows = useMemo<TablePoolRow[]>(() => {
    return pools.map((pool: PoolDetail | MyPoolDetail) => {
      const poolTitle = <PoolTitle pool={pool} />;

      /** poolId */
      const poolId = pool.poolId;
      const poolIdSet = <PoolId poolId={pool.poolId} />;

      /** features */
      const features = <PoolFeatures pool={pool} />;
      const featuresScore = getPoolFeaturesScore(pool);

      const poolIdAndFeatures = (
        <div className="flex items-center gap-x-2">
          <span className="align-baseline text-on_surface space-x-0.5">
            <span className="font_title_xs">Pool</span>
            <span className="font_caption_number_s">#{pool.poolId}</span>
          </span>

          {features}
        </div>
      );

      /** range */
      const range = <PoolRange pool={pool} />;
      const amp = getAmp({ lastPrice: pool.pair.lastPrice, minPrice: pool.minPrice, maxPrice: pool.maxPrice });

      /** apr */
      const apr = pool.apr;
      const aprSet = <PoolApr pool={pool} />;

      /** rewards */
      const rewards = <PoolRewards pool={pool} size={store.isMobile ? '16px' : '20px'} />;
      const hasRewards = pool.RewardsPerToken.some((reward) => reward.rewardAmount > 0);
      const rewardsTooltipContent = hasRewards ? <RewardsEmissionPerDay pool={pool} /> : undefined;

      /** tvl */
      const tvl = getTvlByPool(pool);

      /** balance */
      const balance = isMyPoolDetail(pool) ? pool.myTotalBalanceUsd : undefined;
      const balanceTooltipContent = isMyPoolDetail(pool) ? <MyPoolBalanceDetails pool={pool} /> : undefined;

      /** rewards */
      const rewardInfo = isMyPoolDetail(pool) ? getRewardInfo(pool.myStaking) : undefined;
      const myRewards = rewardInfo?.totalRewardUsd ?? undefined;
      const myRewardsTooltipContent = rewardInfo?.totalRewardUsd.gt(0) ? (
        <MyRewardsDetails rewardInfo={rewardInfo} />
      ) : undefined;

      const ping = isMyPoolDetail(pool) && pool.myBalance.gt(0);

      const isFoldableOpen = !closedPoolIds.has(pool.poolId);

      return {
        pool,
        poolId,
        poolIdSet,
        features,
        featuresScore,
        poolIdAndFeatures,
        range,
        amp,
        poolTitle,
        apr,
        aprSet,
        rewards,
        rewardsTooltipContent,
        tvl,
        balance,
        balanceTooltipContent,
        myRewards,
        myRewardsTooltipContent,
        ping,
        isFoldableOpen,
      };
    });
  }, [pools, getRewardInfo, store.isMobile, closedPoolIds]);

  const prefixFields = useMemo<PoolsTableField[]>(() => {
    const primary: PoolsTableField[] = [
      {
        label: 'Pool',
        value: 'poolTitle',
        type: 'jsx',
        sortValue: 'featuresScore',
        sortType: 'number',
        sortDisabled: true,
      },
    ];

    const secondary: PoolsTableField[] = store.isMobile
      ? [
          {
            label: 'Features',
            value: 'poolIdAndFeatures',
            type: 'jsx',
            sortValue: 'featuresScore',
            sortType: 'number',
            sortDisabled: true,
          },
        ]
      : [
          {
            label: '#',
            value: 'poolIdSet',
            type: 'jsx',
            sortValue: 'poolId',
            sortType: 'number',
            sortDisabled: true,
            widthPx: 22,
          },
          {
            label: 'Features',
            value: 'features',
            type: 'jsx',
            sortValue: 'featuresScore',
            sortDisabled: true,
            sortType: 'number',
          },
        ];

    return type === TableStyles.PRIMARY ? primary : secondary;
  }, [type, store.isMobile]);

  /** final fields */
  const isMyPool = pools.some(isMyPoolDetail);

  const fields = useMemo<PoolsTableField[]>(() => {
    const rests: Record<string, PoolsTableField> = {
      range: {
        label: 'Range',
        value: 'range',
        type: 'jsx',
        sortValue: 'amp',
        sortType: 'bignumber',
        widthRatio: 8.4,
        align: 'center',
        foldableOnMobile: isMyPool,
      },
      apr: {
        label: 'APR',
        value: 'aprSet',
        type: 'jsx',
        tooltipContent: TOOLTIP_TEXTS.APR,
        sortValue: 'apr',
        sortType: 'number',
        widthRatio: type === TableStyles.PRIMARY ? 20 : 24,
        align: 'right',
      },
      /** all pools fields */
      rewards: {
        label: 'Rewards',
        value: 'rewards',
        type: 'jsx',
        sortDisabled: true,
        tooltipSize: '300px',
        widthRatio: type === TableStyles.PRIMARY ? 10 : 13.5,
        align: 'right',
        foldableOnMobile: isMyPool,
      },
      tvl: {
        label: 'TVL',
        value: 'tvl',
        type: 'usd',
        compact: true,
        sortType: 'bignumber',
        generateClassName: (row: TablePoolRow) => (row.amp.isZero() ? 'opacity-40' : ''),
        widthRatio: 20,
        align: 'right',
        foldableOnMobile: isMyPool,
      },
      /** my pools fields */
      balance: {
        label: 'Balance',
        value: 'balance',
        type: 'usd',
        sortValue: 'balance',
        sortType: 'bignumber',
        widthRatio: 20,
        align: 'right',
        tooltipWordBreak: 'break-all',
      },
      myRewards: {
        label: store.isMobile ? 'My rewards' : 'Rewards',
        value: 'myRewards',
        type: 'usd',
        sortType: 'bignumber',
        widthRatio: type === TableStyles.PRIMARY ? 10 : 13.5,
        align: 'right',
        tooltipWordBreak: 'break-all',
      },
    };

    const FIELDS_DICT: { [device in 'mobile' | 'desktop']: { [pool in 'my' | 'all']: PoolsTableField[] } } = {
      mobile: {
        my: [...prefixFields, rests.apr, rests.balance, rests.myRewards, rests.rewards, rests.range, rests.tvl],
        all: [...prefixFields, rests.apr, rests.rewards, rests.range, rests.tvl],
      },
      desktop: {
        my: [...prefixFields, rests.range, rests.apr, rests.balance, rests.myRewards],
        all: [...prefixFields, rests.range, rests.apr, rests.rewards, rests.tvl],
      },
    };

    return FIELDS_DICT[store.isMobile ? 'mobile' : 'desktop'][pools.some(isMyPoolDetail) ? 'my' : 'all'];
  }, [isMyPool, type, prefixFields, store.isMobile]);

  const onRowClick = useCallback((row: TablePoolRow) => onPoolClick?.(row.pool), [onPoolClick]);

  const dSortValueFallback = useMemo<string>(() => (isMyPool ? 'balance' : 'tvl'), [isMyPool]);

  return (
    <>
      <Table<TablePoolRow>
        type={type}
        rows={tablePoolRows}
        isLoading={false}
        noDataLabel={EMPTY_TEXTS.POOLS_TABLE}
        onRowClick={onRowClick}
        onToggleFoldableOnMobile={onToggleFoldableOnMobile}
        dSortValue={dSortValue ?? dSortValueFallback}
        dIsAsc={dIsAsc ?? false}
        fields={fields}
        showRowClickIcon={true}
      />
    </>
  );
};

export default PoolsTable;
