import type { EncodeObject } from '@cosmjs/proto-signing';
import type { MsgLiquidUnfarmAndWithdraw } from 'common/crescent/liquidfarming/types/crescent/liquidfarming/v1beta1/tx';
import type { Coin } from 'common/crescent/liquidfarming/types/cosmos/base/v1beta1/coin';
import msgEncoder from 'common/sign/msgEncoder';

export type LiquidUnfarmAndWithdrawTxData = {
  poolId: number;
  unfarmingCoin: Coin | undefined;
};

const createLiquidUnfarmAndWithdrawMsg = (
  txData: LiquidUnfarmAndWithdrawTxData,
  creAddress: string
): EncodeObject[] => {
  const LiquidUnfarmAndWithdrawData: MsgLiquidUnfarmAndWithdraw = {
    farmer: creAddress,
    poolId: txData.poolId,
    unfarmingCoin: txData.unfarmingCoin,
  };
  console.log('liquidUnfarmAndWithdrawData', LiquidUnfarmAndWithdrawData);
  const msg = msgEncoder.msgLiquidUnfarmAndWithdraw(LiquidUnfarmAndWithdrawData);
  return [msg];
};

export default createLiquidUnfarmAndWithdrawMsg;
