import { addChain, getSupportedChains, requestAccount } from '@cosmostation/extension-client/tendermint';
import { onAccountChanged, offAccountChanged } from '@cosmostation/extension-client/tendermint';
import { INITIAL_ASSET, IS_TEST, TOAST_CONTENT_ERROR_WALLET_UNAVAILABLE } from 'COMMON_VARIABLES';
import type { InfoChain } from 'types/chain';
import callToast from 'common/callToast';
import { NormalWallet, WalletType } from 'types/wallet';
import { fetchCrescentChainInfo } from 'common/utils';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const addChainsToCosmostation = async (chainInfos: InfoChain[]) => {
  const supportedChains = await getSupportedChains();
  const allSupportedChains = [...supportedChains.official, ...supportedChains.unofficial];
  for (const chainInfo of chainInfos) {
    const chaninName = chainInfo.displayName.toLowerCase();

    if (!allSupportedChains.includes(chaninName)) {
      try {
        if (!chainInfo?.isBeta || IS_TEST) {
          await sleep(500);
          // console.log(JSON.parse(JSON.stringify(chainInfo)));
          await addChain({
            chainId: chainInfo.chainId,
            chainName: chaninName,
            addressPrefix: chainInfo.bech32Config.bech32PrefixAccAddr,
            baseDenom: chainInfo.denoms[0].base_denom,
            displayDenom: chainInfo.currencies[0].coinDenom,
            restURL: chainInfo.rest,
            /** @doc https://docs.cosmostation.io/docs/Developer%20Guide/Cosmostation%20Extension/chains#code-using-cosmostationextension-client-1 */
            // gasRate: {
            //   average: `${getTxChainGasPrice(chainInfo, 'high')}`,
            //   low: `${getTxChainGasPrice(chainInfo, 'average')}`,
            //   tiny: `${getTxChainGasPrice(chainInfo, 'low')}`,
            // },
            coinType: `${chainInfo.coinType}`,
            // imageURL?: string;
            // decimals?: number;
            // coinGeckoId?: string;
            // sendGas?: string;
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};

// export const addOtherChainsToCosmostation = async () => {
//   const res = await fetchChainInfos();
//   const chainInfos = res?.data.data ?? [];
//   await addChainsToCosmostation(chainInfos.filter((chainInfo) => chainInfo.chainId !== INITIAL_ASSET.chainId));
// };

export const addCrescentChainToCosmostation = async () => {
  const crescentChainInfo = await fetchCrescentChainInfo();
  if (crescentChainInfo) await addChainsToCosmostation([crescentChainInfo]);
};

export const getCosmostationWallet = async (
  chainInfo?: InfoChain,
  options?: { onFail?: () => void }
): Promise<NormalWallet | null> => {
  const _chainName = (chainInfo?.chainName ?? INITIAL_ASSET.chainName).toLowerCase();

  try {
    const account = await requestAccount(_chainName);
    return account
      ? {
          type: WalletType.COSMOSTATION,
          name: account.name,
          isActive: true,
          address: account.address,
        }
      : null;
  } catch {
    options?.onFail?.();
    return null;
  }
};

export const connectCosmostation = async (): Promise<NormalWallet | null> => {
  try {
    await addCrescentChainToCosmostation();
    return await getCosmostationWallet();
    // 등록해야되는데 케플러에서 주는 값이랑 코스모스테이션에서 주는값이 달라서 코스모스테이션에는 어레이로된 주소를 안줘서 보류
  } catch (e: any) {
    console.log(e);

    if (e?.code !== 4001) {
      callToast({
        type: 'error',
        toastId: e + '',
        title: 'Wallet Unavailable',
        content: TOAST_CONTENT_ERROR_WALLET_UNAVAILABLE,
        autoClose: false,
      });
    }

    return null;
  }
};

export const subscribeCosmostationChange = (callback: () => void) => {
  onAccountChanged(callback);
  return () => {
    offAccountChanged(callback);
  };
};

export const logCosmostationWallet = async () => {
  const account = await requestAccount(INITIAL_ASSET.chainName.toLowerCase());
  console.log('Wallet', account);
};
