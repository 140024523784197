import Long from 'long';

const createIbcDepositMsg = (txData: any, fromChainInfo: any, fromAddress: any, toAddress) => {
  const IBCDepoistData = {
    typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
    value: {
      sourcePort: 'transfer',
      sourceChannel: fromChainInfo.ibcRecvChannel,
      sender: fromAddress,
      receiver: toAddress,
      timeoutTimestamp: Long.fromString(new Date().getTime() + 600000 + '000000'),
      token: { amount: String(txData.amount), denom: txData.denom },
    },
  };
  return [IBCDepoistData];
};

export default createIbcDepositMsg;
// import Long from 'long';

// const createIbcDepositMsg = (txData: any, fromChainInfo: any, fromAddress: any, toAddress) => {
//   const IBCDepoistData = {
//     typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
//     value: {
//       sourcePort: 'transfer',
//       sourceChannel: 'channel-5',
//       sender: fromAddress,
//       receiver: toAddress,
//       timeoutTimestamp: Long.fromString(new Date().getTime() + 600000 + '000000'),
//       token: {
//         amount: '1000000000000000000',
//         denom: 'acanto',
//       },
//     },
//   };
//   return [IBCDepoistData];
// };

// export default createIbcDepositMsg;
