type ProposalIndexProps = { label: string; value: string | JSX.Element; labelColor?: 'light' | 'dark' };

/** @todo seperate color and size props; */
const ProposalIndex = ({ label, value, labelColor = 'light' }: ProposalIndexProps) => (
  <div className="flex justify-between items-center gap-x-1 md:justify-start">
    <div
      className={`${labelColor === 'light' ? 'font_caption_s' : 'font_caption_xs'} md:font_caption_m ${
        labelColor === 'light' ? 'text-on_surface_variant_light' : 'text-on_surface_variant_dark'
      }`}
    >
      {label}:
    </div>

    <div
      className={`${
        labelColor === 'light' ? 'font_body_number_s' : 'font_body_number_xs'
      } md:font_body_number_m text-on_surface`}
    >
      {value}
    </div>
  </div>
);

export default ProposalIndex;
