import BigNumber from 'bignumber.js';
import { formatAmount, getChainType, getTxExplorerURL } from 'common/utils';
import { ChainTypes } from 'components/constants/chain';
import Icon from 'components/icon';
import { TableStyles } from 'components/table/styles';
import Table from 'components/table/Table';
import NumberText from 'components/texts/NumberText';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import { AssetDetail } from 'types/asset';
import type { TxIng } from 'types/tx';

enum IBCStatus {
  PENDING = 'In Process',
  SUCCESS = 'Success',
  FAIL = 'Fail',
}

const IBC_STATUS: Record<number, { message: IBCStatus; desc: string }> = {
  1: { message: IBCStatus.PENDING, desc: '' },
  2: { message: IBCStatus.SUCCESS, desc: '' },
  3: { message: IBCStatus.SUCCESS, desc: '' },
  4: { message: IBCStatus.FAIL, desc: '' },
};

type TableTxRow = Omit<TxIng, 'amount'> & {
  txhash: string;
  txhashLink: JSX.Element;
  ibcType: 'Withdraw' | 'Deposit';
  ibcTypeSet: JSX.Element;
  amount?: BigNumber;
  amountSet?: JSX.Element;
  txStatus: JSX.Element;
};

const TxsTable = ({ txs, asset }: { txs: TxIng[]; asset: AssetDetail }) => {
  const tableTxRows = useMemo<TableTxRow[]>(
    () =>
      txs.map((tx) => {
        const txhash = tx.txhash;
        const txhashLink = <TxHashLink tx={tx} />;

        const ibcType = getChainType(tx.send_chain_id) === ChainTypes.CRESCENT ? 'Withdraw' : 'Deposit';
        const ibcTypeSet = <div className="font_caption_s text-on_surface">{ibcType}</div>;

        const amount = new BigNumber(tx.amount).shiftedBy(-asset.exponent);
        const amountSet = <NumberText size="sm" value={formatAmount(amount, asset.exponent)} unit={asset.ticker} />;

        const txStatus = <TxStatus tx={tx} />;

        return {
          ...tx,
          txhash,
          txhashLink,
          ibcType,
          ibcTypeSet,
          amount,
          amountSet,
          txStatus,
        };
      }),
    [txs, asset]
  );

  const store = useMainStore();

  return (
    <Table<TableTxRow>
      type={TableStyles.BORDERLESS_COMPACT}
      isLoading={false}
      rows={tableTxRows}
      fields={[
        {
          label: store.isMobile ? 'TX Hash' : 'Transaction Hash',
          value: 'txhashLink',
          type: 'jsx',
          sortValue: 'txhash',
          sortType: 'string',
          sortDisabled: true,
          align: 'left',
          widthPx: store.isMobile ? 64 : 320,
        },
        {
          label: 'Type',
          value: 'ibcTypeSet',
          type: 'jsx',
          sortValue: 'ibcType',
          sortType: 'string',
          sortDisabled: true,
          align: 'left',
          widthPx: store.isMobile ? 60 : undefined,
        },
        {
          label: 'Amount',
          value: 'amountSet',
          type: 'jsx',
          sortValue: 'amount',
          sortType: 'bignumber',
          sortDisabled: true,
          align: 'right',
          widthRatio: store.isMobile ? undefined : 36,
        },
        {
          label: 'Status',
          value: 'txStatus',
          type: 'jsx',
          sortDisabled: true,
          align: 'right',
          widthPx: store.isMobile ? 64 : undefined,
          widthRatio: 20,
        },
      ]}
    />
  );
};

export default TxsTable;

function TxHashLink({ tx }: { tx: TxIng }) {
  const href = useMemo<string>(() => {
    return getTxExplorerURL({ txHash: tx.txhash, chainId: tx.send_chain_id });
  }, [tx]);

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="flex items-center md:gap-x-1 text-on_surface hover:underline"
    >
      <div className="truncate max-w-[52px] md:max-w-[10rem] basis-0 grow font_body_xs" title={tx.txhash}>
        {tx.txhash}
      </div>
      <Icon type="open-in-new" size="14px" />
    </a>
  );
}

function TxStatus({ tx }: { tx: TxIng }) {
  const colorClassName = useMemo<string>(() => {
    switch (IBC_STATUS[tx.status].message) {
      case IBCStatus.PENDING:
        return 'text-whiteo70';
      case IBCStatus.SUCCESS:
        return 'text-whiteo70';
      case IBCStatus.FAIL:
        return 'text-redSystem';
      default:
        return 'text-whiteo70';
    }
  }, [tx.status]);

  return (
    <div className={`${colorClassName} flex items-center gap-x-2`}>
      <div className="font_caption_s text-on_surface">{IBC_STATUS[tx.status].message}</div>
      {IBC_STATUS[tx.status].message === IBCStatus.PENDING && <Icon type="loader" size="14px" />}
    </div>
  );
}
