import { useState } from 'react';
import TransitionModal from './TransitionModal';
import ModalHeader from './components/ModalHeader';
import Checkbox from 'components/checkbox/Checkbox';
import Button from 'components/buttons/Button';
import { MODAL_TITLES } from 'COMMON_VARIABLES';

const DisclaimerModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const [isConfirm, setIsConfirm] = useState(false);

  return (
    <TransitionModal isOpen={isOpen}>
      <ModalHeader title={MODAL_TITLES.DISCLAIMER} />

      <div className="flex flex-col gap-y-5 items-center px-4 pb-5 md:px-5 md:pb-6">
        <div className="bg_surface_3 font_body_xs text-on_surface rounded-8px h-[320px] p-3 overflow-scroll md:font_body_s md:h-[380px]">
          The information provided on this website; web applications; mobile applications does not constitute investment
          advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the
          content as such. Crescent Foundation Limited(”Crescent Foundation”) does not recommend that any cryptocurrency
          should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor
          before making any investment decisions.
          <br />
          <br />
          Crescent Foundation will strive to ensure accuracy of information listed on this website; web applications;
          mobile applications although it will not hold any responsibility for any missing or wrong information.
          Crescent Foundation provides all information *as is.* You understand that you are using any and all
          information available here at your own risk.
          <br />
          <br />
          The appearance of third party advertisements and hyperlinks on this website; web applications; mobile
          applications does not constitute an endorsement, guarantee, warranty, or recommendation by Crescent
          Foundation. Do conduct your own due diligence before deciding to use any third party services.
          <br />
          <br />
          Before using our service, you should review the relevant documentation to make sure you understand how the
          service works.
        </div>

        <Checkbox
          label="I have read and understood."
          defaultChecked={isConfirm}
          isChecked={isConfirm}
          onChange={setIsConfirm}
        />

        <Button
          label="Confirm"
          status={isConfirm ? 'enabled' : 'disabled'}
          onClick={() => {
            localStorage.setItem('confirmDisclaimer', 'true');
            close();
          }}
          className="w-full"
        />
      </div>
    </TransitionModal>
  );
};

export default DisclaimerModal;
