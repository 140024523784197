import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateToDesktopTrade = (tradeType?: 'orderbook' | 'swap') => {
  const store = useMainStore();
  const navigate = useNavigate();
  const location = useLocation();

  const removeTradeTypeSearch = useCallback(() => {
    const searches = location.search.replace('?', '').split('&');
    const filteredSearch = searches.filter((search) => !search.includes('type=')).join('&');
    return filteredSearch.length ? `?${filteredSearch}` : '';
  }, [location.search]);

  if (!store.isMobile) {
    const pathname = tradeType ?? 'swap';
    navigate(`/${pathname}${removeTradeTypeSearch()}`);
  }
};

export default useNavigateToDesktopTrade;
