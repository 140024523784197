import { DropDownLayer, DropDownStyle } from './types';

export const DROPDOWN_COLOR_DICT: { [key in DropDownLayer]: string } = {
  plain: 'bg_surface_1 text-on_surface',
  modal: 'bg_surface_3 text-on_surface',
};

export const DROPDOWN_BORDER_COLOR_DICT: { [key in DropDownLayer]: string } = {
  plain: 'border-outline_soft',
  modal: 'border-outline_hard',
};

export const DROPDOWN_LIST_BORDER_GETTER_DICT: { [key in DropDownStyle]: (layer: DropDownLayer) => string } = {
  box: (layer) => `border border-t-0 rounded-b-8px ${DROPDOWN_BORDER_COLOR_DICT[layer]}`,
  popover: (layer) => `border rounded-8px ${DROPDOWN_BORDER_COLOR_DICT[layer]}`,
};

export const DROPDOWN_LIST_GRID_DICT: { [key in DropDownStyle]: string } = {
  box: '',
  popover: 'px-1 py-2 space-y-2',
};

/** @desc color */
export const DROPDOWN_HOVER_LAYER_BASE =
  'md:before:block before:absolute before:w-full before:h-full before:top-0 before:right-0 before:left-0 before:bottom-0 before:bg-transparent before:transition-all';

export const DROPDOWN_HOVER_LAYER_DICT: { [key in DropDownStyle]: string } = {
  box: `${DROPDOWN_HOVER_LAYER_BASE} before:hover:bg-on_surface_variant_dark_o24`,
  popover: `${DROPDOWN_HOVER_LAYER_BASE} before:hover:bg-surface_tint_o24 hover:text-primary`,
};

/** @desc cursor */
export const getDropdownCursor = (selectable: boolean, disabled: boolean) =>
  disabled ? 'cursor-not-allowed' : !selectable ? 'cursor-default' : '';
