import Icon, { IconType } from 'components/icon';

type MenuItemSize = 'sm' | 'md';

const MENUITEM_SIZE_DICT: { [key in MenuItemSize]: { font: string; grid: string } } = {
  sm: {
    font: 'font_title_xs',
    grid: 'gap-x-2 px-3 py-2',
  },
  md: {
    font: 'font_title_s',
    grid: 'gap-x-3 px-3 py-[0.625rem]',
  },
};

const MenuItem = ({
  size = 'sm',
  iconType,
  label,
  arrow = false,
}: {
  size?: MenuItemSize;
  iconType: IconType;
  label: string;
  arrow?: boolean;
}) => {
  return (
    <div
      className={`group flex items-center justify-between rounded-100px overflow-hidden transition-colors text-on_surface_variant_light hover:text-primary hover:bg-surface_tint_o24 ${MENUITEM_SIZE_DICT[size].grid}`}
    >
      <Icon type={iconType} size="16px" />
      <div className={`grow shrink text-left ${MENUITEM_SIZE_DICT[size].font}`}>{label}</div>

      {arrow && (
        <Icon
          type="arrow-forward"
          size="16px"
          className="text-on_surface_variant_dark transition-colors group-hover:text-primary"
        />
      )}
    </div>
  );
};

export default MenuItem;
