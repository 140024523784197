import { useMainStore } from 'provider/mainContext';
import { useState, useEffect } from 'react';
import { PoolDetail } from 'types/pool';

export default function usePools() {
  const store = useMainStore();
  const [pools, setPools] = useState<PoolDetail[]>([]);

  useEffect(() => {
    if (!pools || pools.length === 0) {
      setPools(store.getPools());
    }
  }, [store.assetsData, store.poolsData, store.pairsData]);

  useEffect(() => {
    setPools(store.getPools());
  }, [store.poolsData]);

  return pools;
}
