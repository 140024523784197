import { useState, useMemo } from 'react';
import type { AssetDetail } from 'types/asset';
import { detectBridgeAsset, goBridge } from 'common/utils';
import { useMainStore } from 'provider/mainContext';
import useTxClient from 'hooks/useTxClient';
import BigNumber from 'bignumber.js';
import { INFOBOX_TEXTS, INITIAL_ASSET, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import { TokenTypes } from 'components/constants/token';
import ChainDropDown from 'components/modals/IBCTransferModal/ChainDropDown';
import type { Wallet } from 'types/wallet';
import PoweredByAxelar from 'components/logos/PoweredByAxelar';
import ModalSectionHeader from '../components/ModalSectionHeader';
import IBCTransferArrow from './IBCTransferArrow';
import ExternalLink from 'components/texts/ExternalLink';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import AmountInput from 'components/inputs/AmountInput';
import InfoBox from 'components/infobox';
import Button from 'components/buttons/Button';
import useTokenTransferButton from './hooks/useTokenTransferButton';
import type { InfoChain } from 'types/chain';

type WithdrawProps = {
  asset?: AssetDetail;
  transferChain?: InfoChain;
  setTransferChainId?: (chainId: string) => void;
  transferChainWallet?: Wallet | null;
  transferChainBalance: { denom: string; amount: string };
  isWalletLoading?: boolean;
  close: () => void;
};

export default function IBCTransferModalWithdraw({
  asset,
  transferChain,
  setTransferChainId,
  transferChainWallet = null,
  transferChainBalance,
  isWalletLoading,
  close,
}: WithdrawProps) {
  const store = useMainStore();

  const [isLoading, setLoading] = useState(false);

  const { signAndBroadcast } = useTxClient();

  /** @summary from */
  const fromChainBalance = useMemo<BigNumber>(
    () => asset?.availableBalance?.shiftedBy(-asset.exponent) ?? new BigNumber(0),
    [asset]
  );

  const fromChainWallet = useMemo<Wallet>(() => store.wallet, [store]);

  const lfChainIdOptions = useMemo<string[]>(() => asset?.chains?.map((chain) => chain.chain_id) ?? [], [asset]);

  const [amount, setAmount] = useState('');

  const withdraw = async () => {
    if (asset) {
      setLoading(true);
      const microAmount = new BigNumber(amount)
        .multipliedBy(10 ** asset.exponent)
        .decimalPlaces(0)
        .toString();
      let error = await signAndBroadcast({
        type: 'withdraw',
        txData: {
          destinationChainId: transferChain?.chainId,
          destinationChainWallet: transferChainWallet,
          denom: asset.denom,
          amount: microAmount,
        },
        chainData: store.chainsData,
      });
      setLoading(false);
      if (!error) {
        close();
      }
    }
  };

  const assetBridge = useMemo(() => detectBridgeAsset(asset?.ticker), [asset?.ticker]);
  const isIBC = assetBridge.gateway === 'IBC';
  const isAxlBridged = assetBridge.bridge === 'AXL';

  /** @desc new */
  const button = useTokenTransferButton({
    isLoading,
    chainId: transferChain?.chainId,
    txChain: transferChain,
    txChainWallet: transferChainWallet,
    connectedWallet: store.wallet,
    asset: asset,
    amount: new BigNumber(amount),
    balance: fromChainBalance,
    activeLabel: 'Withdraw',
    transferFunc: withdraw,
  });

  return (
    <>
      <div className="space-y-4 md:space-y-6">
        {/* Chain Selector */}
        <div className="space-y-2">
          <div className="flex">
            <div className="basis-[52.5%]">
              <ModalSectionHeader title="From" />
            </div>
            <div className="basis-[47.5%]">
              <ModalSectionHeader title="To" />
            </div>
          </div>

          <div className="flex items-center">
            <ChainDropDown
              layer="modal"
              selectedChainId={INITIAL_ASSET.chainId}
              selectedChainWallet={fromChainWallet}
              showAddress={isIBC}
            />

            <IBCTransferArrow className="flex justify-center grow" />

            <ChainDropDown
              layer="modal"
              chainIds={asset?.tokenType === TokenTypes.LF ? lfChainIdOptions : undefined}
              selectedChainId={transferChain?.chainId}
              selectedChainWallet={transferChainWallet}
              onSelectChainId={setTransferChainId}
              showAddress={isIBC}
            />
          </div>
        </div>

        {isIBC ? (
          <>
            <div className="space-y-2">
              <div className="flex items-center gap-x-2 font_caption_s text-on_surface_variant_dark">
                Amount to withdraw
                {asset?.ticker.includes('grv') && (
                  <>
                    <ExternalLink text="Bridge" href="https://bridge.blockscape.network" />
                    <TooltipIcon tooltipContent={TOOLTIP_TEXTS.WITHDRAW_GRV_ASSET} />
                  </>
                )}
              </div>

              <AmountInput
                value={amount}
                maximumFractionDigits={asset?.exponent ?? 9}
                asset={asset}
                showBalanceTicker={true}
                onChangeValue={setAmount}
                maxAmount="balance"
                simpleInputData={{
                  signType: 'withdraw',
                  txChainId: INITIAL_ASSET.chainId,
                }}
              />
            </div>
          </>
        ) : (
          isAxlBridged && (
            <div className="flex flex-col items-center justify-center py-4">
              <div className="flex items-center justify-center w-full">
                <PoweredByAxelar />
              </div>
            </div>
          )
        )}

        {isIBC ? (
          <>
            <InfoBox type="tip">
              {button.needToAddChain ? INFOBOX_TEXTS.IBC_TIP_ADD_CHAIN_TO_TRANSFER : INFOBOX_TEXTS.IBC_TIP}
            </InfoBox>
            <Button label={button.text} status={button.status} onClick={button.onClick} className="w-full" />
          </>
        ) : (
          <>
            <InfoBox type="warning">{INFOBOX_TEXTS.EXTERNAL_LINK}</InfoBox>
            <Button
              label="Go bridge"
              status="enabled"
              onClick={() => {
                goBridge(assetBridge.gateway, assetBridge, {
                  source: 'crescent',
                  destination: '',
                  denom: '',
                });
              }}
              className="w-full"
            />
          </>
        )}
      </div>
    </>
  );
}
