/* eslint-disable */
import { Reader, util, configure, Writer } from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import * as Long from 'long';
import { RewardAllocation } from './lpfarm';
import { Coin } from '../../../cosmos/base/v1beta1/coin';

export const protobufPackage = 'crescent.lpfarm.v1beta1';

export interface MsgCreatePrivatePlan {
  creator: string;
  description: string;
  rewardAllocations: RewardAllocation[];
  startTime: Date | undefined;
  endTime: Date | undefined;
}

export interface MsgCreatePrivatePlanResponse {
  planId: number;
  farmingPoolAddress: string;
}

export interface MsgFarm {
  farmer: string;
  coin: Coin | undefined;
}

export interface MsgFarmResponse {
  withdrawnRewards: Coin[];
}

export interface MsgUnfarm {
  farmer: string;
  coin: Coin | undefined;
}

export interface MsgUnfarmResponse {
  withdrawnRewards: Coin[];
}

export interface MsgHarvest {
  farmer: string;
  denom: string;
}

export interface MsgHarvestResponse {
  withdrawnRewards: Coin[];
}

const baseMsgCreatePrivatePlan: object = { creator: '', description: '' };

export const MsgCreatePrivatePlan = {
  encode(message: MsgCreatePrivatePlan, writer: Writer = Writer.create()): Writer {
    if (message.creator !== '') {
      writer.uint32(10).string(message.creator);
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.rewardAllocations) {
      RewardAllocation.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgCreatePrivatePlan {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgCreatePrivatePlan } as MsgCreatePrivatePlan;
    message.rewardAllocations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.creator = reader.string();
          break;
        case 2:
          message.description = reader.string();
          break;
        case 3:
          message.rewardAllocations.push(RewardAllocation.decode(reader, reader.uint32()));
          break;
        case 4:
          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 5:
          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgCreatePrivatePlan {
    const message = { ...baseMsgCreatePrivatePlan } as MsgCreatePrivatePlan;
    message.rewardAllocations = [];
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = String(object.creator);
    } else {
      message.creator = '';
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description);
    } else {
      message.description = '';
    }
    if (object.rewardAllocations !== undefined && object.rewardAllocations !== null) {
      for (const e of object.rewardAllocations) {
        message.rewardAllocations.push(RewardAllocation.fromJSON(e));
      }
    }
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = fromJsonTimestamp(object.startTime);
    } else {
      message.startTime = undefined;
    }
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = fromJsonTimestamp(object.endTime);
    } else {
      message.endTime = undefined;
    }
    return message;
  },

  toJSON(message: MsgCreatePrivatePlan): unknown {
    const obj: any = {};
    message.creator !== undefined && (obj.creator = message.creator);
    message.description !== undefined && (obj.description = message.description);
    if (message.rewardAllocations) {
      obj.rewardAllocations = message.rewardAllocations.map((e) => (e ? RewardAllocation.toJSON(e) : undefined));
    } else {
      obj.rewardAllocations = [];
    }
    message.startTime !== undefined &&
      (obj.startTime = message.startTime !== undefined ? message.startTime.toISOString() : null);
    message.endTime !== undefined &&
      (obj.endTime = message.endTime !== undefined ? message.endTime.toISOString() : null);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgCreatePrivatePlan>): MsgCreatePrivatePlan {
    const message = { ...baseMsgCreatePrivatePlan } as MsgCreatePrivatePlan;
    message.rewardAllocations = [];
    if (object.creator !== undefined && object.creator !== null) {
      message.creator = object.creator;
    } else {
      message.creator = '';
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    } else {
      message.description = '';
    }
    if (object.rewardAllocations !== undefined && object.rewardAllocations !== null) {
      for (const e of object.rewardAllocations) {
        message.rewardAllocations.push(RewardAllocation.fromPartial(e));
      }
    }
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = object.startTime;
    } else {
      message.startTime = undefined;
    }
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = object.endTime;
    } else {
      message.endTime = undefined;
    }
    return message;
  },
};

const baseMsgCreatePrivatePlanResponse: object = {
  planId: 0,
  farmingPoolAddress: '',
};

export const MsgCreatePrivatePlanResponse = {
  encode(message: MsgCreatePrivatePlanResponse, writer: Writer = Writer.create()): Writer {
    if (message.planId !== 0) {
      writer.uint32(8).uint64(message.planId);
    }
    if (message.farmingPoolAddress !== '') {
      writer.uint32(18).string(message.farmingPoolAddress);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgCreatePrivatePlanResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseMsgCreatePrivatePlanResponse,
    } as MsgCreatePrivatePlanResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.planId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.farmingPoolAddress = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgCreatePrivatePlanResponse {
    const message = {
      ...baseMsgCreatePrivatePlanResponse,
    } as MsgCreatePrivatePlanResponse;
    if (object.planId !== undefined && object.planId !== null) {
      message.planId = Number(object.planId);
    } else {
      message.planId = 0;
    }
    if (object.farmingPoolAddress !== undefined && object.farmingPoolAddress !== null) {
      message.farmingPoolAddress = String(object.farmingPoolAddress);
    } else {
      message.farmingPoolAddress = '';
    }
    return message;
  },

  toJSON(message: MsgCreatePrivatePlanResponse): unknown {
    const obj: any = {};
    message.planId !== undefined && (obj.planId = message.planId);
    message.farmingPoolAddress !== undefined && (obj.farmingPoolAddress = message.farmingPoolAddress);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgCreatePrivatePlanResponse>): MsgCreatePrivatePlanResponse {
    const message = {
      ...baseMsgCreatePrivatePlanResponse,
    } as MsgCreatePrivatePlanResponse;
    if (object.planId !== undefined && object.planId !== null) {
      message.planId = object.planId;
    } else {
      message.planId = 0;
    }
    if (object.farmingPoolAddress !== undefined && object.farmingPoolAddress !== null) {
      message.farmingPoolAddress = object.farmingPoolAddress;
    } else {
      message.farmingPoolAddress = '';
    }
    return message;
  },
};

const baseMsgFarm: object = { farmer: '' };

export const MsgFarm = {
  encode(message: MsgFarm, writer: Writer = Writer.create()): Writer {
    if (message.farmer !== '') {
      writer.uint32(10).string(message.farmer);
    }
    if (message.coin !== undefined) {
      Coin.encode(message.coin, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgFarm {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgFarm } as MsgFarm;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.farmer = reader.string();
          break;
        case 2:
          message.coin = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgFarm {
    const message = { ...baseMsgFarm } as MsgFarm;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = '';
    }
    if (object.coin !== undefined && object.coin !== null) {
      message.coin = Coin.fromJSON(object.coin);
    } else {
      message.coin = undefined;
    }
    return message;
  },

  toJSON(message: MsgFarm): unknown {
    const obj: any = {};
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.coin !== undefined && (obj.coin = message.coin ? Coin.toJSON(message.coin) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgFarm>): MsgFarm {
    const message = { ...baseMsgFarm } as MsgFarm;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = '';
    }
    if (object.coin !== undefined && object.coin !== null) {
      message.coin = Coin.fromPartial(object.coin);
    } else {
      message.coin = undefined;
    }
    return message;
  },
};

const baseMsgFarmResponse: object = {};

export const MsgFarmResponse = {
  encode(message: MsgFarmResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.withdrawnRewards) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgFarmResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgFarmResponse } as MsgFarmResponse;
    message.withdrawnRewards = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.withdrawnRewards.push(Coin.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgFarmResponse {
    const message = { ...baseMsgFarmResponse } as MsgFarmResponse;
    message.withdrawnRewards = [];
    if (object.withdrawnRewards !== undefined && object.withdrawnRewards !== null) {
      for (const e of object.withdrawnRewards) {
        message.withdrawnRewards.push(Coin.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: MsgFarmResponse): unknown {
    const obj: any = {};
    if (message.withdrawnRewards) {
      obj.withdrawnRewards = message.withdrawnRewards.map((e) => (e ? Coin.toJSON(e) : undefined));
    } else {
      obj.withdrawnRewards = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<MsgFarmResponse>): MsgFarmResponse {
    const message = { ...baseMsgFarmResponse } as MsgFarmResponse;
    message.withdrawnRewards = [];
    if (object.withdrawnRewards !== undefined && object.withdrawnRewards !== null) {
      for (const e of object.withdrawnRewards) {
        message.withdrawnRewards.push(Coin.fromPartial(e));
      }
    }
    return message;
  },
};

const baseMsgUnfarm: object = { farmer: '' };

export const MsgUnfarm = {
  encode(message: MsgUnfarm, writer: Writer = Writer.create()): Writer {
    if (message.farmer !== '') {
      writer.uint32(10).string(message.farmer);
    }
    if (message.coin !== undefined) {
      Coin.encode(message.coin, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgUnfarm {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgUnfarm } as MsgUnfarm;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.farmer = reader.string();
          break;
        case 2:
          message.coin = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgUnfarm {
    const message = { ...baseMsgUnfarm } as MsgUnfarm;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = '';
    }
    if (object.coin !== undefined && object.coin !== null) {
      message.coin = Coin.fromJSON(object.coin);
    } else {
      message.coin = undefined;
    }
    return message;
  },

  toJSON(message: MsgUnfarm): unknown {
    const obj: any = {};
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.coin !== undefined && (obj.coin = message.coin ? Coin.toJSON(message.coin) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgUnfarm>): MsgUnfarm {
    const message = { ...baseMsgUnfarm } as MsgUnfarm;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = '';
    }
    if (object.coin !== undefined && object.coin !== null) {
      message.coin = Coin.fromPartial(object.coin);
    } else {
      message.coin = undefined;
    }
    return message;
  },
};

const baseMsgUnfarmResponse: object = {};

export const MsgUnfarmResponse = {
  encode(message: MsgUnfarmResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.withdrawnRewards) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgUnfarmResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgUnfarmResponse } as MsgUnfarmResponse;
    message.withdrawnRewards = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.withdrawnRewards.push(Coin.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgUnfarmResponse {
    const message = { ...baseMsgUnfarmResponse } as MsgUnfarmResponse;
    message.withdrawnRewards = [];
    if (object.withdrawnRewards !== undefined && object.withdrawnRewards !== null) {
      for (const e of object.withdrawnRewards) {
        message.withdrawnRewards.push(Coin.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: MsgUnfarmResponse): unknown {
    const obj: any = {};
    if (message.withdrawnRewards) {
      obj.withdrawnRewards = message.withdrawnRewards.map((e) => (e ? Coin.toJSON(e) : undefined));
    } else {
      obj.withdrawnRewards = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<MsgUnfarmResponse>): MsgUnfarmResponse {
    const message = { ...baseMsgUnfarmResponse } as MsgUnfarmResponse;
    message.withdrawnRewards = [];
    if (object.withdrawnRewards !== undefined && object.withdrawnRewards !== null) {
      for (const e of object.withdrawnRewards) {
        message.withdrawnRewards.push(Coin.fromPartial(e));
      }
    }
    return message;
  },
};

const baseMsgHarvest: object = { farmer: '', denom: '' };

export const MsgHarvest = {
  encode(message: MsgHarvest, writer: Writer = Writer.create()): Writer {
    if (message.farmer !== '') {
      writer.uint32(10).string(message.farmer);
    }
    if (message.denom !== '') {
      writer.uint32(18).string(message.denom);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgHarvest {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgHarvest } as MsgHarvest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.farmer = reader.string();
          break;
        case 2:
          message.denom = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgHarvest {
    const message = { ...baseMsgHarvest } as MsgHarvest;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = String(object.farmer);
    } else {
      message.farmer = '';
    }
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = String(object.denom);
    } else {
      message.denom = '';
    }
    return message;
  },

  toJSON(message: MsgHarvest): unknown {
    const obj: any = {};
    message.farmer !== undefined && (obj.farmer = message.farmer);
    message.denom !== undefined && (obj.denom = message.denom);
    return obj;
  },

  fromPartial(object: DeepPartial<MsgHarvest>): MsgHarvest {
    const message = { ...baseMsgHarvest } as MsgHarvest;
    if (object.farmer !== undefined && object.farmer !== null) {
      message.farmer = object.farmer;
    } else {
      message.farmer = '';
    }
    if (object.denom !== undefined && object.denom !== null) {
      message.denom = object.denom;
    } else {
      message.denom = '';
    }
    return message;
  },
};

const baseMsgHarvestResponse: object = {};

export const MsgHarvestResponse = {
  encode(message: MsgHarvestResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.withdrawnRewards) {
      Coin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): MsgHarvestResponse {
    const reader = input instanceof Uint8Array ? new Reader(input) : input;
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseMsgHarvestResponse } as MsgHarvestResponse;
    message.withdrawnRewards = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.withdrawnRewards.push(Coin.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MsgHarvestResponse {
    const message = { ...baseMsgHarvestResponse } as MsgHarvestResponse;
    message.withdrawnRewards = [];
    if (object.withdrawnRewards !== undefined && object.withdrawnRewards !== null) {
      for (const e of object.withdrawnRewards) {
        message.withdrawnRewards.push(Coin.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: MsgHarvestResponse): unknown {
    const obj: any = {};
    if (message.withdrawnRewards) {
      obj.withdrawnRewards = message.withdrawnRewards.map((e) => (e ? Coin.toJSON(e) : undefined));
    } else {
      obj.withdrawnRewards = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<MsgHarvestResponse>): MsgHarvestResponse {
    const message = { ...baseMsgHarvestResponse } as MsgHarvestResponse;
    message.withdrawnRewards = [];
    if (object.withdrawnRewards !== undefined && object.withdrawnRewards !== null) {
      for (const e of object.withdrawnRewards) {
        message.withdrawnRewards.push(Coin.fromPartial(e));
      }
    }
    return message;
  },
};

export interface Msg {
  CreatePrivatePlan(request: MsgCreatePrivatePlan): Promise<MsgCreatePrivatePlanResponse>;
  Farm(request: MsgFarm): Promise<MsgFarmResponse>;
  Unfarm(request: MsgUnfarm): Promise<MsgUnfarmResponse>;
  Harvest(request: MsgHarvest): Promise<MsgHarvestResponse>;
}

export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }
  CreatePrivatePlan(request: MsgCreatePrivatePlan): Promise<MsgCreatePrivatePlanResponse> {
    const data = MsgCreatePrivatePlan.encode(request).finish();
    const promise = this.rpc.request('crescent.lpfarm.v1beta1.Msg', 'CreatePrivatePlan', data);
    return promise.then((data) => MsgCreatePrivatePlanResponse.decode(new Reader(data)));
  }

  Farm(request: MsgFarm): Promise<MsgFarmResponse> {
    const data = MsgFarm.encode(request).finish();
    const promise = this.rpc.request('crescent.lpfarm.v1beta1.Msg', 'Farm', data);
    return promise.then((data) => MsgFarmResponse.decode(new Reader(data)));
  }

  Unfarm(request: MsgUnfarm): Promise<MsgUnfarmResponse> {
    const data = MsgUnfarm.encode(request).finish();
    const promise = this.rpc.request('crescent.lpfarm.v1beta1.Msg', 'Unfarm', data);
    return promise.then((data) => MsgUnfarmResponse.decode(new Reader(data)));
  }

  Harvest(request: MsgHarvest): Promise<MsgHarvestResponse> {
    const data = MsgHarvest.encode(request).finish();
    const promise = this.rpc.request('crescent.lpfarm.v1beta1.Msg', 'Harvest', data);
    return promise.then((data) => MsgHarvestResponse.decode(new Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

// @ts-ignore
if (util.Long !== Long) {
  util.Long = Long as any;
  configure();
}
