import { useState, useEffect, useCallback, Fragment } from 'react';
import { IconType } from 'components/icon';
import { NavLink } from 'react-router-dom';
import { IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY, IS_PRODUCTION } from 'COMMON_VARIABLES';
import { observer } from 'mobx-react-lite';
import IconButton from 'components/buttons/IconButton';
import ToggleButton from 'components/buttons/ToggleButton';
import { Popover, Transition } from '@headlessui/react';
import Divider from 'components/divider/Divider';
import MenuItem from 'components/buttons/MenuItem';
import { useMainStore } from 'provider/mainContext';
import Slider from 'components/slider/Slider';

/** @desc AppMenuItem */
const AppMenuItem = ({
  to,
  isInternalLink = false,
  label,
  iconType,
  onClick,
}: {
  to: string;
  isInternalLink?: boolean;
  label: string;
  iconType: IconType;
  onClick?: () => void;
}) => {
  const store = useMainStore();

  const content = (
    <MenuItem size={store.isMobile ? 'md' : 'sm'} iconType={iconType} label={label} arrow={!store.isMobile} />
  );

  return isInternalLink ? (
    <NavLink to={to} onClick={onClick}>
      {content}
    </NavLink>
  ) : (
    <a href={to} target="_blank" rel="noreferrer" onClick={onClick}>
      {content}
    </a>
  );
};

/** @desc SettingWidgetContent */
const SettingWidgetContent = ({ className, close }: { className?: string; close?: () => void }) => {
  const [isNotificationOn, setIsNotificationOn] = useState<boolean>(true);

  useEffect(() => {
    if (localStorage.getItem(IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY) === 'false') {
      setIsNotificationOn(false);
    }
  }, []);

  const onToggleNotification = useCallback(() => {
    const newValue = !isNotificationOn;
    setIsNotificationOn(newValue);
    localStorage.setItem(IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY, newValue.toString());
  }, [isNotificationOn]);

  const store = useMainStore();

  const onClick = useCallback(() => {
    close?.();
  }, [close]);

  return (
    <div className={`relative space-y-2 md:space-y-3 ${className}`}>
      {/* link items */}
      <AppMenuItem label="Blog" to="https://crescentnetwork.medium.com" iconType="medium" />
      <AppMenuItem label="Docs" to="https://docs.crescent.network/" iconType="gitbook" />
      <AppMenuItem label="Github" to="https://github.com/crescent-network/crescent" iconType="github" />
      <AppMenuItem label="Twitter" to="https://twitter.com/CrescentHub" iconType="twitter" />
      <AppMenuItem label="Discord" to="https://discord.com/invite/ZUfrDnSX8G" iconType="discord" />
      <AppMenuItem label="Telegram" to="https://t.me/crescentnetwork" iconType="telegram" />
      <AppMenuItem label="CoinGecko" to="https://www.coingecko.com/en/coins/crescent-network" iconType="coinGecko" />
      <AppMenuItem
        label="CommonWealth"
        to="https://commonwealth.im/crescent-forum/discussions"
        iconType="commonWealth"
      />
      {IS_PRODUCTION && (
        <>
          <AppMenuItem label="Terms & Policy" to="/terms" iconType="terms" isInternalLink={true} onClick={onClick} />
          <AppMenuItem
            label="Withdraw GDEX Pools"
            to="/gdex"
            iconType="token"
            isInternalLink={true}
            onClick={onClick}
          />
        </>
      )}

      <Divider />

      {/* notification on/off */}
      <div className="flex justify-between items-center gap-x-4 px-3 py-2">
        <div className="font_caption_m md:font_caption_s text-on_surface_variant_light">Notification Popup</div>
        <ToggleButton toggle={onToggleNotification} size={store.isMobile ? 'md' : 'sm'} isEnabled={isNotificationOn} />
      </div>
    </div>
  );
};

/** @desc SettingWidget */
const SettingWidget = () => {
  /** @desc mobile state for animation */
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {/* labtop */}
      <Popover className="hidden md:block relative">
        <IconButton iconType="more" size="lg" isPopOver={true} />

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <Popover.Panel className="absolute top-14 right-0 z-10 w-[13.75rem]">
            <div className="px-2 py-3 bg_surface_1 border border-outline_soft rounded-8px elevation_dark_4">
              <SettingWidgetContent />
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      {/* mobile */}
      <div className="block md:hidden">
        <IconButton iconType="more" size="lg" onClick={() => setIsOpen(!isOpen)} />

        <div className={`fixed top-0 left-0 w-full h-full z-10 ${isOpen ? '' : 'pointer-events-none'}`}>
          {/* mobile dimmed wall */}
          <div
            className={`absolute top-0 left-0 w-full h-full bg-background transition-opacity ${
              isOpen ? 'opacity-80' : 'opacity-0 invisible -z-[1]'
            }`}
            onClick={() => setIsOpen(false)}
          ></div>

          {/* content */}
          <Slider open={isOpen} type="75%">
            <div className="w-[75%] h-full px-2 py-4 space-y-4 bg_surface_1 elevation_dark_3">
              <IconButton iconType="close" size="lg" onClick={() => setIsOpen(false)} />
              <SettingWidgetContent close={() => setIsOpen(false)} />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default observer(SettingWidget);
