import { useMemo } from 'react';
// import './Ranger.css';
import { Range, getTrackBackground } from 'react-range';

/** @desc RangerScale */
const RangerScale = ({ label }: { label: string }) => {
  return (
    <div className="space-y-3">
      <div className="h-4 w-[1px] bg-surface_variant"></div>
      <div className="-ml-[0.34rem] font_caption_number_m text-surface_variant">{label}</div>
    </div>
  );
};

/** @desc Ranger */
export type RangerColor = 'primary' | 'liquid';

const RANGER_COLOR_DICT: { [key in RangerColor]: string } = {
  primary: 'var(--color-primary)',
  liquid: 'var(--color-liquid)',
};

const RANGER_DISABLED_COLOR = 'var(--color-surface-variant)';

type RangerProps = {
  color?: RangerColor;
  value: number;
  scaleCnt?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
};

const Ranger = ({ color = 'primary', value = 0, scaleCnt = 5, disabled = false, onChange }: RangerProps) => {
  const scales = useMemo<string[]>(
    () =>
      new Array(scaleCnt).fill(0).map((_, index) => {
        return `${(100 / (scaleCnt - 1)) * index}`;
      }),
    [scaleCnt]
  );

  return (
    <div className="w-full px-2 pb-10">
      <div className={`relative flex items-center ${disabled ? 'cursor-not-allowed' : ''}`}>
        {scales.map((scale, index) => (
          <div key={index} className={`absolute -top-1.5`} style={{ left: `${(index / (scaleCnt - 1)) * 100}%` }}>
            <RangerScale key={index} label={scale} />
          </div>
        ))}

        <Range
          step={1}
          min={0}
          max={100}
          disabled={disabled}
          values={[value]}
          onChange={(values) => onChange(values[0])}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: 4,
                width: '100%',
                background: getTrackBackground({
                  values: [value],
                  colors: [disabled ? RANGER_DISABLED_COLOR : RANGER_COLOR_DICT[color], RANGER_DISABLED_COLOR],
                  min: 0,
                  max: 100,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: 16,
                width: 16,
                borderRadius: 100,
                backgroundColor: disabled ? RANGER_DISABLED_COLOR : RANGER_COLOR_DICT[color],
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Ranger;
