import { useState, useMemo } from 'react';
import TransitionModal from './TransitionModal';
import DepositVotingBar from 'pages/gov/components/DepositVotingBar';
import { useMainStore } from 'provider/mainContext';
import { INITIAL_ASSET, MODAL_TITLES } from 'COMMON_VARIABLES';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import ModalHeader from './components/ModalHeader';
import Button, { ButtonStatus } from 'components/buttons/Button';
import LabeledData from 'components/texts/LabeledData';
import NumberText from 'components/texts/NumberText';
import { formatAmount } from 'common/utils';
import AmountInput from 'components/inputs/AmountInput';

type ProposalDepositModalProps = {
  isOpen: boolean;
  currentDeposit: any;
  deposit: (amount: string) => void;
  close: () => void;
};

const ProposalDepositModal = ({ isOpen, currentDeposit, deposit, close }: ProposalDepositModalProps) => {
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const mainStore = useMainStore();

  const myBalance = useMemo(() => {
    if (mainStore?.balanceData?.[INITIAL_ASSET.denom]) {
      return new BigNumber(mainStore?.balanceData?.[INITIAL_ASSET.denom]) ?? new BigNumber(0);
    }
    return new BigNumber(0);
  }, [mainStore?.balanceData]);

  const minDeposit = useMemo(() => {
    let amount = mainStore.paramsData.info['deposit.min_deposit']?.replace('ucre', '');
    amount = new BigNumber(amount).dividedBy(10 ** INITIAL_ASSET.exponent);
    return amount;
  }, [mainStore.paramsData]);

  const _deposit = async () => {
    if (amount) {
      setIsLoading(true);
      let _amount = new BigNumber(amount)
        .multipliedBy(10 ** INITIAL_ASSET.exponent)
        .decimalPlaces(0)
        .toString();
      await deposit(_amount);
      setIsLoading(false);
    }
  };

  const button = useMemo<{
    status: ButtonStatus;
    text: string;
  }>(() => {
    if (isLoading) {
      return {
        status: 'loading',
        text: '',
      };
    } else {
      const _amount = new BigNumber(amount).multipliedBy(10 ** INITIAL_ASSET.exponent);
      if (myBalance.gt(0) && !_amount.gt(0)) {
        return {
          status: 'disabled',
          text: 'Enter amount',
        };
      } else if (!myBalance.gt(0) || _amount.gt(myBalance)) {
        return {
          status: 'disabled',
          text: 'Insufficient balance',
        };
      } else {
        return {
          status: 'enabled',
          text: 'Deposit',
        };
      }
    }
  }, [isLoading, myBalance, amount]);

  return (
    <TransitionModal isOpen={isOpen} close={close}>
      <ModalHeader title={MODAL_TITLES.PROPOSAL_DEPOSIT} onCloseClick={close} />

      <div className="flex flex-col items-center gap-y-6 px-4 pb-5 md:px-5 md:pb-6">
        <div className="w-full space-y-4">
          <DepositVotingBar percent={currentDeposit?.dividedBy(minDeposit).multipliedBy(100).toFixed(2) || '0'} />

          <div className="space-y-2">
            <LabeledData
              label="Required deposit"
              value={
                <NumberText
                  size="sm"
                  value={formatAmount(minDeposit, INITIAL_ASSET.exponent)}
                  unit={INITIAL_ASSET.ticker}
                />
              }
            />

            <LabeledData
              label="Current deposit"
              value={
                <NumberText
                  size="sm"
                  color="deposit"
                  value={formatAmount(currentDeposit, INITIAL_ASSET.exponent)}
                  unit={INITIAL_ASSET.ticker}
                />
              }
            />
          </div>

          <AmountInput
            value={amount}
            onChangeValue={setAmount}
            asset={mainStore.assetDetailDict[INITIAL_ASSET.denom]}
            maximumFractionDigits={INITIAL_ASSET.exponent}
            maxAmount="balance"
            simpleInputData={{
              signType: 'govDeposit',
              txChainId: INITIAL_ASSET.chainId,
            }}
          />
        </div>

        <Button label={button.text} status={button.status} onClick={_deposit} className="w-full" />
      </div>
    </TransitionModal>
  );
};

export default observer(ProposalDepositModal);
