import { formatAmount, formatUSD } from 'common/utils';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import type { AssetDetail } from 'types/asset';

const MyOpenOrder = ({ title = 'In Open Order', asset }: { title?: string; asset: AssetDetail }) => {
  return (
    <TooltipInner title={title}>
      <NumberText
        size="sm"
        value={formatAmount(asset.reservedBalance.shiftedBy(-asset.exponent), asset.exponent)}
        unit={asset.ticker}
        subValue={formatUSD(asset.reservedBalance.shiftedBy(-asset.exponent).multipliedBy(asset.priceOracle ?? 0))}
      />
    </TooltipInner>
  );
};

export default MyOpenOrder;
