import { useNavigate } from 'react-router-dom';
import ProposalParameterDetail from './components/ProposalParameterDetail';
import { observer } from 'mobx-react-lite';
import { useProposal } from 'hooks/useAPI';
import BigNumber from 'bignumber.js';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProposalDepositModal from 'components/modals/ProposalDepositModal';
import ProposalVoteModal from 'components/modals/ProposalVoteModal';
import { useMainStore } from 'provider/mainContext';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import useTxClient from 'hooks/useTxClient';
import { Proposal } from 'types/gov';
import IconButton from 'components/buttons/IconButton';
import ProposalStatusChip from './components/ProposalStatusChip';
import ProposalDepositVisualizer from './components/ProposalDepositVisualizer';
import VotingVisualizer from './components/VotingVisualizer';
import Button from 'components/buttons/Button';
import NumberText from 'components/texts/NumberText';

/** @desc text format utils */
function getMilSecRemovedTime(t: string | undefined) {
  if (undefined) {
    return t;
  } else {
    return t?.split('.')[0];
  }
}

const getProposalInitialDepositString = (proposal: Proposal): { value: string } => {
  return { value: new BigNumber(proposal.proposal.total_deposit[0]?.amount ?? 0).dividedBy(10 ** 6).toFormat() };
};

const getProposalTotalDepositString = (proposal: Proposal): { value: string } => {
  const total = proposal.proposal.total_deposit
    .reduce((arr, cur) => arr.plus(cur.amount), new BigNumber(0))
    .dividedBy(10 ** 6)
    .toFormat();

  return { value: total };
};

const getProposalTimeString = (time: string) => {
  const string = time.split('T');
  return `${string[0]} ${getMilSecRemovedTime(string[1])}`;
};

/** @component GovDetailItem */
const GovDetailItem = ({ label, value }: { label: string; value?: string | JSX.Element }) => {
  return (
    <div className="flex justify-between items-start gap-x-10 md:justify-start md:items-center">
      <dt className="md:basis-48 font_caption_s text-on_surface_variant_dark md:font_caption_m">{label}</dt>
      {value && (
        <dd className="max-w-[60%] whitespace-normal break-all text-right font_body_xs text-on_surface md:max-w-none md:text-left md:font_body_s">
          {value}
        </dd>
      )}
    </div>
  );
};

/** @component GovDetail */
function GovDetail() {
  const [isVoteModalOpen, setIsVoteModalOpen] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  const { signAndBroadcast } = useTxClient();

  const mainStore = useMainStore();

  const params = useParams();
  const { data: proposalData } = useProposal(mainStore.wallet.address || 'anonymous', params.id, 5000);

  const proposal = useMemo<Proposal | undefined>(() => proposalData?.data?.[0], [proposalData]);

  const totalDeposit = useMemo(() => {
    return proposal?.proposal.total_deposit
      .reduce((arr, cur) => arr.plus(cur.amount), new BigNumber(0))
      .dividedBy(10 ** 6);
  }, [proposal]);

  const deposit = async (amount) => {
    if (amount && proposal) {
      let error = await signAndBroadcast({
        type: 'govDeposit',
        chainData: mainStore.chainsData,
        txData: { proposalId: proposal.proposalId, amount: { denom: 'ucre', amount: amount } },
      });
      if (!error) {
        setIsDepositModalOpen(false);
      }
    }
  };

  const vote = async (voteOption) => {
    if (voteOption && proposal) {
      let error = await signAndBroadcast({
        type: 'vote',
        txData: { proposalId: proposal?.proposalId, option: voteOption },
        chainData: mainStore.chainsData,
      });
      if (!error) {
        setIsVoteModalOpen(false);
      }
    }
  };

  const navigate = useNavigate();

  const button = useMemo<{ label: string; onClick: () => void } | null>(() => {
    if (proposal?.proposal.status === 'PROPOSAL_STATUS_VOTING_PERIOD') {
      return {
        label: 'Vote',
        onClick: () => setIsVoteModalOpen(true),
      };
    } else if (proposal?.proposal.status === 'PROPOSAL_STATUS_DEPOSIT_PERIOD') {
      return {
        label: 'Deposit',
        onClick: () => setIsDepositModalOpen(true),
      };
    } else {
      return null;
    }
  }, [proposal]);

  return (
    <>
      {proposal && (
        <main role="main" className="pt-2 pb-20 w-full max-w-content m-auto md:pb-40">
          <header className="flex justify-between items-center gap-x-4 pl-4 pr-6 py-4 md:py-6">
            <div className="flex items-center gap-x-4">
              <IconButton
                size={mainStore.isMobile ? 'sm' : 'md'}
                iconType="arrow-back-ios"
                to="/gov/"
                onClick={() => navigate(-1)}
              />

              <h2 className="space-y-2 text-on_surface md:space-y-1">
                <div className="flex items-center gap-x-2">
                  <div className="font_caption_number_m md:font_caption_number_xl">#{proposal.proposalId}</div>
                  <div className="md:hidden">
                    <ProposalStatusChip proposal={proposal} chipSize="sm" />
                  </div>
                </div>

                <div className="whitespace-normal font_title_s md:font_headline_s md:truncate">
                  {proposal.proposal.content.title}
                </div>
              </h2>
            </div>

            <div className="hidden md:block">
              <ProposalStatusChip proposal={proposal} chipSize="md" />
            </div>
          </header>

          <section className="p-4 space-y-2 md:space-y-4 md:p-5">
            <GovDetailItem label="Proposer" value={proposal.proposer} />

            <GovDetailItem
              label="Initial deposit"
              value={<NumberText {...getProposalInitialDepositString(proposal)} unit="CRE" xsDecimal={true} />}
            />

            <GovDetailItem
              label="Total deposit"
              value={<NumberText {...getProposalTotalDepositString(proposal)} unit="CRE" xsDecimal={true} />}
            />

            <GovDetailItem
              label="Voting start"
              value={
                <div className="font_body_number_s">{getProposalTimeString(proposal.proposal.voting_start_time)}</div>
              }
            />

            <GovDetailItem
              label="Voting end"
              value={
                <div className="font_body_number_s">{getProposalTimeString(proposal.proposal.voting_end_time)}</div>
              }
            />

            <GovDetailItem label="Type" value={proposal.proposal.content['@type']} />

            <GovDetailItem
              label="Submit time"
              value={<div className="font_body_number_s">{getProposalTimeString(proposal.proposal.submit_time)}</div>}
            />

            <GovDetailItem
              label="Deposit end time"
              value={
                <div className="font_body_number_s">{getProposalTimeString(proposal.proposal.deposit_end_time)}</div>
              }
            />

            <GovDetailItem label="Description" />

            <section className="h-[212px] overflow-auto bg_surface_2 rounded-8px p-3 md:h-[320px]">
              <ReactMarkdown
                className="prose max-w-none font_body_s text-on_surface prose-strong:text-on_surface prose-b:text-on_surface prose-a:text-on_surface prose-h1:text-on_surface prose-h2:text-on_surface prose-h3:text-on_surface prose-h4:text-on_surface prose-th:text-on_surface prose-tr:text-on_surface prose-td:text-on_surface md:font_body_m"
                remarkPlugins={[remarkGfm]}
              >
                {proposal.proposal.content.description}
              </ReactMarkdown>

              <ProposalParameterDetail content={proposal.proposal.content} />
            </section>

            <div className="pt-2">
              {proposal.proposal.status === 'PROPOSAL_STATUS_DEPOSIT_PERIOD' ? (
                <ProposalDepositVisualizer proposal={proposal} />
              ) : (
                <VotingVisualizer proposal={proposal} mobileColsCnt="1" />
              )}
            </div>

            {/* buttons */}
            <div className="flex justify-center">
              {button !== null && (
                <Button
                  label={button.label}
                  onClick={button.onClick}
                  className="w-full max-w-[15rem]"
                  status={mainStore.wallet.isActive ? 'enabled' : 'disabled'}
                />
              )}
            </div>
          </section>

          {/* modals */}
          <ProposalVoteModal isOpen={isVoteModalOpen} vote={vote} close={() => setIsVoteModalOpen(false)} />

          <ProposalDepositModal
            isOpen={isDepositModalOpen}
            currentDeposit={totalDeposit}
            deposit={deposit}
            close={() => setIsDepositModalOpen(false)}
          />
        </main>
      )}
    </>
  );
}
export default observer(GovDetail);
