import Icon from 'components/icon';
import Tooltip, { TooltipSize } from 'components/tooltips';

type TooltipIconColor = 'plain' | 'warning';

const TOOLTIP_ICON_COLOR_DICT: { [key in TooltipIconColor]: string } = {
  plain: 'text-on_surface_variant_dark',
  warning: 'text-semantic_yellow',
};

type TooltipIconProps = {
  tooltipSize?: TooltipSize;
  tooltipContent?: string | JSX.Element;
  tooltipWordBreak?: 'break-all' | 'break-keep';
  iconType?: 'question' | 'sparkles';
  zIndex?: number;
  color?: TooltipIconColor;
};

function TooltipIcon({
  tooltipSize,
  tooltipContent,
  tooltipWordBreak,
  iconType = 'question',
  zIndex,
  color = 'plain',
}: TooltipIconProps) {
  return (
    <Tooltip size={tooltipSize} content={tooltipContent} wordBreak={tooltipWordBreak} zIndex={zIndex}>
      <Icon
        type={iconType ?? 'question'}
        size={iconType === 'sparkles' ? '24px' : '16px'}
        className={TOOLTIP_ICON_COLOR_DICT[color]}
      />
    </Tooltip>
  );
}

export default TooltipIcon;
