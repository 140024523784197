import { useCallback } from 'react';

import BigNumber from 'bignumber.js';
import { LpFarmStaking } from 'types/farm';
import { useMainStore } from 'provider/mainContext';
import { InfoAsset } from 'types/asset';

type Reward = InfoAsset & {
  rewardAmount: string;
  rewardUsd: BigNumber;
};

export default function useClaimableRewardInfo() {
  const store = useMainStore();

  const getRewardInfo = useCallback<
    (farmStaking?: LpFarmStaking) => {
      totalRewardUsd: BigNumber;
      rewards: Reward[];
    }
  >(
    (farmStaking?: LpFarmStaking) => {
      if (store.assetsData.isInit && farmStaking) {
        const harvestable = [...farmStaking.harvestable];
        const rewards: Reward[] = [];
        let totalRewardUsd = new BigNumber(0);
        harvestable.forEach((reward) => {
          if (new BigNumber(reward.rewardAmount).toNumber() > 0) {
            let assetInfo = store.assetsData.info[reward.rewardDenom];
            const rewardAmount = new BigNumber(reward.rewardAmount).shiftedBy(-assetInfo.exponent);
            const rewardUsd = rewardAmount.multipliedBy(store.assetsData.live[reward.rewardDenom]?.priceOracle);
            totalRewardUsd = totalRewardUsd.plus(rewardUsd);
            rewards.push({
              ...assetInfo,
              rewardAmount: rewardAmount.decimalPlaces(6, 1).toString(),
              rewardUsd,
            });
          }
        });

        return { totalRewardUsd, rewards };
      } else {
        return {
          totalRewardUsd: new BigNumber(0),
          rewards: [],
        };
      }
    },
    [store.assetsData]
  );

  return { getRewardInfo };
}
