import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import { useMemo } from 'react';
import CountingNumber from './CountingNumber';
import RollingNumber from './RollingNumber';

type NumberAnimationType = 'rolling' | 'counting';

type AnimatedNumberProps = {
  number: BigNumber;
  dp?: number;
  type?: NumberAnimationType;
  startFrom0?: boolean;
};

const AnimatedNumber = ({ number, dp, type = 'rolling', startFrom0 }: AnimatedNumberProps) => {
  const parsedNumber = useMemo<number>(() => number.dp(dp ?? 2, BigNumber.ROUND_DOWN).toNumber(), [number, dp]);

  const animatedNumber = useMemo<JSX.Element>(() => {
    const DICT: { [key in NumberAnimationType]: JSX.Element } = {
      rolling: <RollingNumber number={parsedNumber} dp={dp} configs={[{ friction: 60 }]} />,
      counting: <CountingNumber number={parsedNumber} dp={dp} startFrom0={startFrom0} />,
    };

    return DICT[type];
  }, [parsedNumber, dp, type, startFrom0]);

  return number.isZero() ? <>{formatAmount(number, dp)}</> : animatedNumber;
};

export default AnimatedNumber;
