import { INITIAL_ASSET, INITIAL_ASSET_PREFIX, IS_TEST, TOAST_CONTENT_ERROR_WALLET_UNAVAILABLE } from 'COMMON_VARIABLES';
import { decode, encode } from 'bech32';
import callToast from 'common/callToast';
import { getKeplr, getKeplrAddressInfoByChainId } from 'common/signingClients/utils/keplrUtils';
import { NormalWallet, WalletType } from 'types/wallet';
import type { InfoChain } from 'types/chain';
import { fetchCrescentBalance, fetchCrescentChainInfo } from 'common/utils';

const i2hex = (i: number) => {
  return ('0' + i.toString(16)).slice(-2);
};

/** @description utils */
export const addChainsToKeplr = async (chainInfos: InfoChain[]) => {
  for (const chainInfo of chainInfos) {
    try {
      const keplr = await getKeplr();
      if (!chainInfo?.isBeta || IS_TEST) {
        await keplr?.experimentalSuggestChain(chainInfo);
      }
      await keplr?.enable(chainInfo.chainId);
    } catch (e) {
      console.log('ERROR: add chain to Keplr', chainInfo);
    }
  }
};

const addCrescentChainToKeplr = async () => {
  const crescentChainInfo = await fetchCrescentChainInfo();
  if (crescentChainInfo) await addChainsToKeplr([crescentChainInfo]);
};

export const getKeplrWallet = async (
  chainInfo?: InfoChain,
  options?: { onFail?: () => void }
): Promise<NormalWallet | null> => {
  const _chainId = chainInfo?.chainId ?? INITIAL_ASSET.chainId;

  try {
    const account = await getKeplrAddressInfoByChainId(_chainId);

    return account
      ? {
          type: WalletType.KEPLR,
          name: account.name,
          isActive: true,
          address: account.bech32Address,
        }
      : null;
  } catch {
    options?.onFail?.();
    return null;
  }
};

export const connectKeplr = async (isAutoLogin?: boolean): Promise<NormalWallet | null> => {
  try {
    await addCrescentChainToKeplr();

    /** @description first time connection */
    if (window.getOfflineSigner && !isAutoLogin) {
      const creOfflineSigner = await window.getOfflineSigner(INITIAL_ASSET.chainId);
      const [account] = await creOfflineSigner.getAccounts();
      const keplr = await getKeplr();
      const keys = await keplr?.getKey(INITIAL_ASSET.chainId);

      const creAddress = encode(INITIAL_ASSET_PREFIX, decode(account.address).words);
      const hexAddress = keys ? Array.from(keys.address).map(i2hex).join('') : '';

      const res = await fetchCrescentBalance({ address: creAddress, hexAddress });
      if (res?.data) console.log('First Time Connection!', res);
    }

    return await getKeplrWallet();
  } catch (e) {
    console.log(e);

    if (!`${e}`.includes('rejected')) {
      callToast({
        type: 'error',
        toastId: e + '',
        title: 'Wallet Unavailable',
        content: TOAST_CONTENT_ERROR_WALLET_UNAVAILABLE,
        autoClose: false,
      });
    }

    return null;
  }
};

export const subscribeKeplrChange = (callback: () => void) => {
  window.addEventListener('keplr_keystorechange', callback);
  return () => {
    window.removeEventListener('keplr_keystorechange', callback);
  };
};

export const logKeplrWallet = async () => {
  console.log('Wallet', await window.keplr.getKey(INITIAL_ASSET.chainId));
};
