const PortfolioIconSvg = ({ className }: { className: string }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.40896 16.5H8.74229V15.7H9.40896C9.77562 15.7 10.0756 15.34 10.0756 14.9V12.5C10.0756 12.06 9.77562 11.7 9.40896 11.7H7.40896V10.9H10.0756V9.3H8.74229V8.5H7.40896V9.3H6.74229C6.37562 9.3 6.07562 9.66 6.07562 10.1V12.5C6.07562 12.94 6.37562 13.3 6.74229 13.3H8.74229V14.1H6.07562V15.7H7.40896V16.5Z"
        fill="currentColor"
      />
      <path
        d="M14.9004 9.5C15.1765 9.5 15.4004 9.27614 15.4004 9C15.4004 8.72386 15.1765 8.5 14.9004 8.5C14.6242 8.5 14.4004 8.72386 14.4004 9C14.4004 9.27614 14.6242 9.5 14.9004 9.5Z"
        fill="currentColor"
      />
      <path
        d="M18.9004 13.5C19.1765 13.5 19.4004 13.2761 19.4004 13C19.4004 12.7239 19.1765 12.5 18.9004 12.5C18.6242 12.5 18.4004 12.7239 18.4004 13C18.4004 13.2761 18.6242 13.5 18.9004 13.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5004 2.53995C11.7969 2.51351 12.097 2.5 12.4004 2.5C12.7037 2.5 13.0039 2.51351 13.3004 2.53995C18.4016 2.99497 22.4004 7.2805 22.4004 12.5C22.4004 15.1626 21.3598 17.5822 19.663 19.3742C19.4565 19.5923 19.2402 19.8012 19.015 20L19.0092 20.0051C18.7859 20.2018 18.5538 20.3888 18.3135 20.5653C16.8844 21.6148 15.1661 22.2936 13.3004 22.46C13.0039 22.4865 12.7037 22.5 12.4004 22.5C12.097 22.5 11.7969 22.4865 11.5004 22.46C6.39923 22.005 2.40039 17.7195 2.40039 12.5C2.40039 7.2805 6.39923 2.99497 11.5004 2.53995ZM4.20039 12.5C4.20039 8.27542 7.39509 4.79699 11.5004 4.34883V20.6512C7.39509 20.203 4.20039 16.7246 4.20039 12.5ZM17.1171 19.2087C16.0139 19.9862 14.7116 20.4975 13.3004 20.6513V14.8811L17.1171 19.2087ZM14.9004 10.5C15.7288 10.5 16.4004 9.82843 16.4004 9C16.4004 8.17157 15.7288 7.5 14.9004 7.5C14.072 7.5 13.4004 8.17157 13.4004 9C13.4004 9.82843 14.072 10.5 14.9004 10.5ZM13.9761 13.0757L18.9761 8.07574L19.8247 8.92426L14.8247 13.9243L13.9761 13.0757ZM18.9004 14.5C19.7288 14.5 20.4004 13.8284 20.4004 13C20.4004 12.1716 19.7288 11.5 18.9004 11.5C18.072 11.5 17.4004 12.1716 17.4004 13C17.4004 13.8284 18.072 14.5 18.9004 14.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PortfolioIconSvg;
