import { useMemo } from 'react';
import TogglableButtonGroup, { TogglableButtonColor, TogglableButtonItem } from './TogglableButtonGroup';

const PERCENT_OPTIONS_MAX_HALF: TogglableButtonItem<number>[] = [
  { label: 'HALF', value: 50, disabled: false },
  { label: 'MAX', value: 100, disabled: false },
];

const PERCENT_OPTIONS_PERCENTAGES: TogglableButtonItem<number>[] = [
  { label: '10%', labelType: 'number', value: 10, disabled: false },
  { label: '25%', labelType: 'number', value: 25, disabled: false },
  { label: '50%', labelType: 'number', value: 50, disabled: false },
  { label: '100%', labelType: 'number', value: 100, disabled: false },
];

const PERCENT_OPTIONS_DICT: { [key in PercentGroup]: TogglableButtonItem<number>[] } = {
  'max-half': PERCENT_OPTIONS_MAX_HALF,
  percentages: PERCENT_OPTIONS_PERCENTAGES,
};

type PercentGroup = 'max-half' | 'percentages';

type PercentButtonGroupProps = {
  groupType?: PercentGroup;
  xFull?: boolean;
  color?: TogglableButtonColor;
  excludedValues?: number[];
  selectedValue?: number;
  onSelect?: (percent: number) => void;
  disabled?: boolean;
};

const PercentButtonGroup = ({
  groupType = 'max-half',
  xFull,
  color,
  excludedValues = [],
  selectedValue,
  onSelect,
  disabled = false,
}: PercentButtonGroupProps) => {
  const list = useMemo<TogglableButtonItem<number>[]>(
    () =>
      PERCENT_OPTIONS_DICT[groupType]
        .filter((item) => !excludedValues.includes(item.value))
        .map((item) => ({
          ...item,
          disabled,
        })),
    [groupType, excludedValues, disabled]
  );

  return (
    <TogglableButtonGroup<number>
      color={color}
      xFull={xFull}
      type="outlined"
      size="xs"
      list={list}
      selectedItem={selectedValue}
      onSelect={onSelect}
    />
  );
};

export default PercentButtonGroup;
