const TradeIconSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20098 8V3.5H9.00098V8H11.701V17H9.00098V21.5H7.20098V17H4.50098V8H7.20098ZM9.90098 9.8H6.30098V15.2H9.90098V9.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.201 6.2V3.5H18.001V6.2H20.701V18.8H18.001V21.5H16.201V18.8H13.501V6.2H16.201Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TradeIconSvg;
