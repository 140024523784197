import { MsgHarvest } from '../crescent/lpfarm/types/crescent/lpfarm/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

export type HarvestTxData = {
  denom: string;
};

const createLpHarvestMsg = (txDataArray: HarvestTxData[], creAddress: any) => {
  const harvestDataArray: MsgHarvest[] = txDataArray.map((txData) => ({
    farmer: creAddress,
    denom: txData.denom,
  }));
  console.log('harvestDataArray', harvestDataArray);
  const msgs = harvestDataArray.map((harvestData) => msgEncoder.msgLpHarvest(harvestData));
  return msgs;
  // const msg = msgEncoder.msgLpHarvest(harvestData);
  // return [msg];
};

export default createLpHarvestMsg;
