import BigNumber from 'bignumber.js';
import { alertInsufficientBalanceForTx, getFeeSubtractedAmount } from 'common/utils';
import { useMainStore } from 'provider/mainContext';
import { useCallback } from 'react';
import type { InfoAsset } from 'types/asset';
import type { CurrencyDetail, GasPriceStep } from 'types/chain';
import useChainTxFeeCurrency from 'hooks/useChainTxFeeCurrency';
import { INITIAL_ASSET } from 'COMMON_VARIABLES';

const ERROR_MSG_ASSET_DATA_MISSING = 'Error: token data is missing';
const ERROR_MSG_CHAIN_DATA_MISSING = 'Error: chain data is missing';

const useAmountUtils = ({
  asset,
  txChainId,
  gas,
  gasPriceStepKey,
  txCnt,
}: {
  asset: InfoAsset | undefined;
  txChainId: string | undefined;
  gas: number;
  gasPriceStepKey?: keyof GasPriceStep;
  txCnt?: number;
}) => {
  const mainStore = useMainStore();
  const txChain = mainStore.getChain(txChainId ?? '')?.info;

  /** all fee currencies' balances & each fee amount on txChain */
  const { feeCurrencies, hasTokenToPayFeeExcept } = useChainTxFeeCurrency({ txChain, gas, gasPriceStepKey, txCnt });

  const getFeeSubtractedAmountOf = useCallback(
    ({
      amount,
      balance,
      excludeOtherFeeCurrencies = false,
      onInsufficientBalance,
    }: {
      amount: BigNumber;
      balance: BigNumber;
      excludeOtherFeeCurrencies?: boolean;
      onInsufficientBalance?: (feeCurrency: CurrencyDetail) => void;
    }) => {
      if (asset === undefined) {
        alert(ERROR_MSG_ASSET_DATA_MISSING);
        return undefined;
      }

      if (txChain === undefined) {
        alert(ERROR_MSG_CHAIN_DATA_MISSING);
        return undefined;
      }

      if (!excludeOtherFeeCurrencies && hasTokenToPayFeeExcept([asset])) {
        return amount.dp(asset.exponent, BigNumber.ROUND_DOWN);
      }

      const isCREonCrescentChain = asset.denom === INITIAL_ASSET.denom && txChain.chainId === INITIAL_ASSET.chainId;

      return getFeeSubtractedAmount({
        amount: amount.dp(asset.exponent, BigNumber.ROUND_DOWN),
        balance: balance.dp(asset.exponent, BigNumber.ROUND_DOWN),
        asset,
        feeCurrencies,
        txCnt: isCREonCrescentChain ? 5 : 1,
        onInsufficientBalance: onInsufficientBalance ?? (() => {}),
      });
    },
    [asset, txChain, feeCurrencies, hasTokenToPayFeeExcept]
  );

  /** @summary returns the initial amount on insufficient balance, considering if there's other available fee currency */
  const getPercentAmountOf = useCallback(
    (balance: BigNumber, percent: number) => {
      const amount = balance.multipliedBy(percent).div(100);

      const feeSubtractedAmount = getFeeSubtractedAmountOf({
        balance,
        amount,
        excludeOtherFeeCurrencies: true,
        onInsufficientBalance: alertInsufficientBalanceForTx,
      });

      return feeSubtractedAmount?.lt(0) ? amount : feeSubtractedAmount;
    },
    [getFeeSubtractedAmountOf]
  );

  /** @summary returns the raw fee subtracted amount, even when negative, not considering if there's other available fee currency */
  const getFeeSubtractedPercentAmountOf = useCallback(
    (balance: BigNumber, percent: number) => {
      const amount = balance.multipliedBy(percent).div(100);

      const feeSubtractedAmount = getFeeSubtractedAmountOf({
        balance,
        amount,
        excludeOtherFeeCurrencies: true,
      });

      return feeSubtractedAmount;
    },
    [getFeeSubtractedAmountOf]
  );

  return { feeCurrencies, getPercentAmountOf, getFeeSubtractedPercentAmountOf };
};

export default useAmountUtils;
