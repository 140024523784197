import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Deposit from './deposit/Deposit';
import Withdraw from './Withdraw';
import Farm from './Farm';
import Unfarm from './Unfarm';
import Claim from './Claim';
import TransitionModal from '../TransitionModal';
import { useMainStore } from 'provider/mainContext';
import BigNumber from 'bignumber.js';
import type { PoolDetail } from 'types/pool';
import ModalHeader from '../components/ModalHeader';
import TabSelector from 'components/tabs/TabSelector';
import OtherPools from './OtherPools';
import PoolFeatures from 'components/pools/PoolFeatures';
import Button from 'components/buttons/Button';
import Slider from 'components/slider/Slider';
import { MODAL_TITLES } from 'COMMON_VARIABLES';

/** @desc ManageModalTitle */
const ManageModalTitle = ({ pool, onClickPoolSelect }: { pool: PoolDetail; onClickPoolSelect: () => void }) => {
  return (
    <div className="space-y-1.5">
      <div className="flex items-center gap-x-2">
        <div className="font_title_m md:font_title_xl text-on_surface">
          {pool.assets[0].ticker} / {pool.assets[1].ticker}
        </div>

        <Button
          type="surficial"
          size="sm"
          label={`#${pool.poolId}`}
          trailingIcon="arrow-drop-down"
          onClick={onClickPoolSelect}
        />
      </div>
      <PoolFeatures pool={pool} />
    </div>
  );
};

/** @desc ManageModal */
type ManageModalProps = {
  pool: PoolDetail;
  isOpen: boolean;
  close: () => void;
  isLfByDefault: boolean;
};

const ManageModal = ({ pool, isOpen, close, isLfByDefault = false }: ManageModalProps) => {
  const [selectedPool, setSelectedPool] = useState<PoolDetail>(pool);

  const store = useMainStore();

  const myTokenBalance = useMemo(() => {
    const uToken = store?.balanceData?.[selectedPool.poolDenom];
    return uToken ? new BigNumber(uToken) : new BigNumber(0);
  }, [selectedPool, store.balanceData]);

  const [openOtherPools, setOpenOtherPools] = useState<boolean>(false);

  const onSelectPool = useCallback((pool: PoolDetail) => {
    setSelectedPool(pool);
    setOpenOtherPools(false);
  }, []);

  return (
    <TransitionModal isOpen={isOpen} align="top" close={close} className="mt-5 md:mt-[2vh]">
      <ModalHeader
        title={<ManageModalTitle pool={selectedPool} onClickPoolSelect={() => setOpenOtherPools(!openOtherPools)} />}
        onCloseClick={close}
      />

      <div className="px-4 pb-5 md:px-5 md:pb-6">
        <TabSelector
          type="line"
          size={store.isMobile ? 'xs' : 'sm'}
          panels={[
            {
              key: 'Deposit',
              content: <Deposit pool={selectedPool} />,
            },
            {
              key: 'Withdraw',
              content: <Withdraw pool={selectedPool} />,
            },
            {
              key: 'Farm',
              content: <Farm pool={selectedPool} isLfByDefault={isLfByDefault} close={close} />,
              ping: myTokenBalance.gt(0),
            },
            {
              key: 'Unfarm',
              content: <Unfarm pool={selectedPool} isLfByDefault={isLfByDefault} close={close} />,
            },
            {
              key: 'Claim',
              content: <Claim pool={selectedPool} />,
            },
          ]}
        />
      </div>

      <Slider open={openOtherPools} direction="vertical" type="5.625rem">
        <Slider.Header title={MODAL_TITLES.OTHER_POOLS} onBackClick={() => setOpenOtherPools(false)} />
        <OtherPools pool={selectedPool} onSelectPool={onSelectPool} />
      </Slider>
    </TransitionModal>
  );
};

export default observer(ManageModal);
