import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD } from 'common/utils';
import LoadingRows from 'components/skeletons/LoadingRows';
import AnimatedNumber from 'components/texts/AnimatedNumber';
import { TooltipSize } from 'components/tooltips';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import PanelLabel from './PanelLabel';
import { PanelAmountSize } from './types';

type PanelAmountColor = 'primary' | 'surface' | 'gray';

const PANEL_AMOUNT_COLOR_DICT: { [key in PanelAmountColor]: string } = {
  primary: 'text-primary text_glow_1',
  surface: 'text-on_surface',
  gray: 'text-semantic_gray',
};

const PANEL_AMOUNT_TYPO_DICT: {
  [key in PanelAmountSize]: {
    eqSymbol: string;
    amount: string;
  };
} = {
  md: {
    eqSymbol: 'font_data_number_m',
    amount: 'font_headline_number_m_mobile md:font_headline_number_m',
  },
  lg: {
    eqSymbol: 'font_data_number_l',
    amount: 'font_headline_number_l_mobile md:font_headline_number_l',
  },
  xl: {
    eqSymbol: 'font_headline_number_xl_mobile md:font_headline_number_xl',
    amount: 'font_headline_number_2xl',
  },
};

function PanelAmount({
  label,
  amount,
  symbol,
  dp,
  color = 'surface',
  size,
  tooltipContent,
  tooltipSize,
  animated = false,
  startFrom0,
  isLoading = false,
}: {
  label?: string;
  amount: BigNumber;
  symbol: '$' | '';
  dp?: number;
  color?: PanelAmountColor;
  size: PanelAmountSize;
  tooltipContent?: JSX.Element;
  tooltipSize?: TooltipSize;
  animated?: boolean;
  startFrom0?: boolean;
  isLoading?: boolean;
}) {
  return (
    <div className="text-left space-y-2 md:space-y-3">
      {label !== undefined && <PanelLabel label={label} size={size} />}

      {isLoading ? (
        <LoadingRows rowsCnt={1} loadingDataTypoClassName={PANEL_AMOUNT_TYPO_DICT[size].amount} />
      ) : (
        <div className={`flex items-center ${size === 'xl' ? 'gap-x-3' : 'gap-x-2'} ${PANEL_AMOUNT_COLOR_DICT[color]}`}>
          <span className="flex items-center gap-x-0.5">
            <span className={PANEL_AMOUNT_TYPO_DICT[size].eqSymbol}>≈ </span>
            <span className={PANEL_AMOUNT_TYPO_DICT[size].amount}>
              {animated ? (
                <div className="flex items-center">
                  <span>$</span>
                  <AnimatedNumber number={amount} dp={dp} type="counting" startFrom0={startFrom0} />
                </div>
              ) : (
                /** @description formatUSD is not used intentially here */
                formatAmount(amount, dp, { symbol })
              )}
            </span>
          </span>

          {tooltipContent !== undefined && (
            <div className={`text-on_surface_variant_dark ${size === 'xl' ? 'scale-150' : ''}`}>
              <TooltipIcon tooltipSize={tooltipSize} tooltipContent={tooltipContent} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PanelAmount;
