import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import Coin from 'components/coins/Coin';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import type { Reward } from 'types/pool';

const MyRewardsDetails = ({
  title = 'Rewards',
  rewardInfo,
}: {
  title?: string;
  rewardInfo: {
    totalRewardUsd: BigNumber;
    rewards: Reward[];
  };
}) => {
  return (
    <TooltipInner title={title}>
      <div className="space-y-2">
        {rewardInfo.rewards.map((item) => (
          <div key={item.denom} className="flex items-center gap-x-2">
            <Coin asset={item} size="16px" />
            <NumberText
              size="sm"
              color="plain-mono"
              value={formatAmount(new BigNumber(item.rewardAmount), item.exponent)}
              unit={item.ticker}
            />
          </div>
        ))}
      </div>
    </TooltipInner>
  );
};

export default MyRewardsDetails;
