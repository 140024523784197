import { HIGHLIGHT_POOLS, PAIR_FILTER_ASSETS_DICT, PairAssetFilterKeys } from 'COMMON_VARIABLES';
import { TokenTypes } from 'components/constants/token';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { AssetDetail } from 'types/asset';

/** @description no cres, no stable coins */
const filterNormalTokens = (assets: AssetDetail[]) =>
  assets.filter(
    (asset) =>
      asset.tokenType !== TokenTypes.NATIVE &&
      !PAIR_FILTER_ASSETS_DICT[PairAssetFilterKeys.STABLE].tickers.includes(asset.ticker)
  );

const useNewTokens = () => {
  const store = useMainStore();

  const newPoolIds = useMemo(() => HIGHLIGHT_POOLS.filter((hp) => hp.value === 'new').map((hp) => hp.poolId), []);

  const newTokens = useMemo(() => {
    const newPools = store.getPools().filter((pool) => newPoolIds.includes(pool.poolId));

    return newPools.reduce((acc, pool) => {
      const _assets = pool.assets.map((a) => store.assetDetailDict[a.denom]).filter((a) => !!a);
      filterNormalTokens(_assets).forEach((asset) => acc.push(asset));
      return acc;
    }, [] as AssetDetail[]);
  }, [newPoolIds, store.poolsData.isInit]);

  return newTokens;
};

export default useNewTokens;
