import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { TxIng } from 'types/tx';
import { useTxIngHistory } from './useAPI';

const useTxHistory = () => {
  const store = useMainStore();

  const currentTime = useMemo(() => Math.floor(new Date().getTime() / 1000) - 3600, []);
  const { data: txResponse } = useTxIngHistory(store.wallet.address || '', currentTime, store.isWatching ? 1000 : 3000);

  const txsByDenomDict = useMemo<Record<string, TxIng[]>>(() => {
    const histories: any = {};
    txResponse?.data?.forEach((tx: any) => {
      if (!tx.denom.includes('ibc/')) {
        // withdraw
        const baseDenom = tx.denom.split('/').slice(-1)[0];
        const asset = store.assetsData.info[baseDenom];
        if (asset) {
          if (!histories[asset.denom]) {
            histories[asset.denom] = [...tx.txs];
          } else {
            histories[asset.denom].push(...tx.txs);
          }
        }
      } else {
        // deposit
        if (!histories[tx.denom]) {
          histories[tx.denom] = [...tx.txs];
        } else {
          histories[tx.denom].push(...tx.txs);
        }
      }
    });

    Object.keys(histories).forEach((key) =>
      histories[key].sort((a: TxIng, b: TxIng) => b.commit_ts_us - a.commit_ts_us)
    );
    return histories;
  }, [store.assetsData.info, txResponse]);

  return { txsByDenomDict };
};

export default useTxHistory;
