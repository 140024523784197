import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import NA from 'components/texts/NA';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import TooltipLabel from 'components/tooltips/TooltipLabel';
import { useMemo } from 'react';
import type { PoolDetail } from 'types/pool';

const PoolRangeDetails = ({
  title = 'AMP',
  pool,
  amp,
  percentToMax,
  percentToMin,
}: {
  title?: string;
  pool: PoolDetail;
  amp: BigNumber;
  percentToMax: number;
  percentToMin: number;
}) => {
  const exponentDiff = useMemo(() => pool.assets[0].exponent - pool.assets[1].exponent, [pool.assets]);

  return (
    <TooltipInner title={title} highlight={{ text: `${formatAmount(amp, 4)}`, prefixUnit: 'x' }}>
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="flex items-center justify-between gap-x-2">
            <TooltipLabel label="To max price" />
            <NumberText size="sm" color="green" value={`+${formatAmount(new BigNumber(percentToMax), 2)}%`} />
          </div>

          <div className="flex items-center justify-between gap-x-2">
            <TooltipLabel label="To min price" />
            <NumberText size="sm" color="red" value={`-${formatAmount(new BigNumber(percentToMin), 2)}%`} />
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex items-center justify-between gap-x-2">
            <TooltipLabel label="Max price" />

            <NumberText size="sm" value={formatAmount(new BigNumber(pool.maxPrice).shiftedBy(exponentDiff), 5)} />
          </div>

          <div className="flex items-center justify-between gap-x-2">
            <TooltipLabel label="Last price" />
            {pool.poolPrice.isNaN() ? (
              <NA />
            ) : (
              <NumberText size="sm" value={formatAmount(pool.poolPrice.shiftedBy(exponentDiff), 5)} />
            )}
          </div>

          <div className="flex items-center justify-between gap-x-3">
            <TooltipLabel label="Min price" />
            <NumberText size="sm" value={formatAmount(new BigNumber(pool.minPrice).shiftedBy(exponentDiff), 5)} />
          </div>
        </div>
      </div>
    </TooltipInner>
  );
};

export default PoolRangeDetails;
