import { DROPDOWN_HOVER_LAYER_DICT } from '../styles';

const DropDownPopoverItem = <T, K = string | JSX.Element>({
  selected,
  label,
  className = '',
}: {
  selected: boolean;
  label: K;
  className?: string;
}) => {
  return (
    <div
      className={`relative px-4 py-2 cursor-pointer rounded-100px overflow-hidden ${
        selected ? 'bg-inverse_primary text-primary' : ''
      } ${DROPDOWN_HOVER_LAYER_DICT.popover} ${className}`}
    >
      {label}
    </div>
  );
};

export default DropDownPopoverItem;
