import Button, { ButtonProps } from 'components/buttons/Button';
import PoolsTableFilterModal from 'components/pools/modal/PoolsTableFilterModal';
import usePoolFilter from 'components/pools/hooks/usePoolFilter';
import PairsTable from 'components/pools/PairsTable';
import PoolsTable from 'components/pools/PoolsTable';
import { isMyPoolDetail } from 'components/pools/utils';
import TabSelector from 'components/tabs/TabSelector';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MyPoolDetail, PoolDetail, PoolFilterData } from 'types/pool';
import SearchInput from 'components/inputs/SearchInput';
import { PairAssetFilterKeys } from 'COMMON_VARIABLES';
import { useLocation } from 'react-router-dom';

const PoolFilterButton = ({ notified, onClick }: { notified: boolean; onClick: () => void }) => {
  const args: ButtonProps = {
    label: 'Filter',
    type: 'surficial',
    trailingIcon: notified ? 'check_circle' : 'filter',
    status: notified ? 'notified' : 'enabled',
    onClick,
  };

  return (
    <>
      <div className="hidden md:block">
        <Button {...args} size="md" />
      </div>
      <div className="md:hidden">
        <Button {...args} size="sm" />
      </div>
    </>
  );
};

export enum PoolTableDisplayTypes {
  PAIR = 'Pair',
  POOL = 'Pool',
}

type PairsPoolsTableProps = {
  pools: PoolDetail[] | MyPoolDetail[];
  defaultKey?: PoolTableDisplayTypes;
  onPoolClick?: (pool: PoolDetail) => void;
  hasSearchInput?: boolean;
  hasFilter?: boolean;
  poolOnly?: boolean;
  localStorageKeyPrefix?: string;
};

const PairsPoolsTable = ({
  pools,
  defaultKey = PoolTableDisplayTypes.PAIR,
  onPoolClick,
  hasSearchInput = false,
  hasFilter = false,
  poolOnly = false,
  localStorageKeyPrefix,
}: PairsPoolsTableProps) => {
  /** filter default setup */
  const store = useMainStore();

  const isMyPools = useMemo<boolean>(() => isMyPoolDetail(pools[0]), [pools]);

  const defaultFilterData = useMemo<PoolFilterData>(() => {
    return {
      ranged: true,
      basic: true,
      liquidFarm: false,
      incentivised: !isMyPools,
      etc: true,
      pairAssets: Object.values(PairAssetFilterKeys).map((group) => ({ group, checked: true })),
    };
  }, [isMyPools]);

  /** localStorage */
  const storedFilterData = useMemo<PoolFilterData | null>(() => {
    const localStorageRawData = localStorage.getItem(`${localStorageKeyPrefix}-filter`);
    return localStorageRawData ? JSON.parse(localStorageRawData) : null;
  }, [localStorageKeyPrefix]);

  const initialFilterData = useMemo<PoolFilterData>(
    () => storedFilterData ?? defaultFilterData,
    [storedFilterData, defaultFilterData]
  );

  const [filterData, setFilterData] = useState<PoolFilterData>(initialFilterData);

  useEffect(() => {
    setFilterData({
      ...initialFilterData,
    });
  }, [initialFilterData]);

  /** filter modal */
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const onFilterModalClose = useCallback(() => {
    setIsFilterModalOpen(false);
  }, []);

  const onFilterOk = (okData: PoolFilterData) => {
    setFilterData(okData);
    setIsFilterModalOpen(false);

    /** localStorage */
    if (localStorageKeyPrefix) {
      localStorage.setItem(`${localStorageKeyPrefix}-filter`, JSON.stringify(okData));
    }
  };

  /** filter result */
  const { filteredPools, getFilteredCnt } = usePoolFilter({ pools, injectedData: { ...filterData } });

  const filteredCnt = useMemo<number>(() => getFilteredCnt(defaultFilterData), [getFilteredCnt, defaultFilterData]);

  /** search input */
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const searchedPools = useMemo<PoolDetail[] | MyPoolDetail[]>(
    () =>
      searchKeyword.length === 0
        ? filteredPools
        : filteredPools.filter((pool) =>
            pool.assets.some((asset) => asset.ticker.toUpperCase().includes(searchKeyword.trim().toUpperCase()))
          ),
    [filteredPools, searchKeyword]
  );

  /** search input by url param */
  const location = useLocation();

  useEffect(() => {
    const paramsRaw = location.search.replace('?', '').split('&');
    const params: Record<string, string> = {};
    paramsRaw.forEach((item) => {
      const data = item.split('=');
      params[data[0]] = data[1];
    });
    setSearchKeyword(params?.search ?? '');
  }, []);

  const FilterButton = <PoolFilterButton notified={filteredCnt > 0} onClick={() => setIsFilterModalOpen(true)} />;

  return (
    <>
      {poolOnly ? (
        <div className="space-y-4">
          {hasFilter && <div className="flex items-center justify-end">{FilterButton}</div>}
          <PoolsTable pools={searchedPools} onPoolClick={onPoolClick} localStorageKeyPrefix={localStorageKeyPrefix} />
        </div>
      ) : (
        <div className="space-y-4">
          {store.isMobile && hasSearchInput && <SearchInput value={searchKeyword} onChangeValue={setSearchKeyword} />}

          <TabSelector
            size={store.isMobile ? 'sm' : 'md'}
            type="segmented"
            tabsMaxWidth="8.75rem"
            right={
              <div className="flex items-center gap-x-5">
                {hasFilter && FilterButton}
                {!store.isMobile && hasSearchInput && (
                  <SearchInput value={searchKeyword} onChangeValue={setSearchKeyword} />
                )}
              </div>
            }
            defaultKey={defaultKey}
            panels={[
              {
                key: PoolTableDisplayTypes.PAIR,
                content: (
                  <PairsTable
                    pools={searchedPools}
                    onPoolClick={onPoolClick}
                    localStorageKeyPrefix={localStorageKeyPrefix}
                  />
                ),
              },
              {
                key: PoolTableDisplayTypes.POOL,
                content: (
                  <PoolsTable
                    pools={searchedPools}
                    onPoolClick={onPoolClick}
                    localStorageKeyPrefix={localStorageKeyPrefix}
                  />
                ),
              },
            ]}
          />
        </div>
      )}

      {hasFilter && (
        <PoolsTableFilterModal
          isOpen={isFilterModalOpen}
          pools={pools}
          defaultData={defaultFilterData}
          injectedData={filterData}
          onClose={onFilterModalClose}
          onOk={onFilterOk}
        />
      )}
    </>
  );
};

export default PairsPoolsTable;
