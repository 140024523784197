import type { Keplr, Key } from '@keplr-wallet/types';

export async function getKeplr(): Promise<Keplr | undefined> {
  if (window.keplr) {
    return window.keplr;
  }
  if (document.readyState === 'complete') {
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event: Event) => {
      if (event.target && (event.target as Document).readyState === 'complete') {
        resolve(window.keplr);
        document.removeEventListener('readystatechange', documentStateChange);
      }
    };
    document.addEventListener('readystatechange', documentStateChange);
  });
}

export async function getKeplrAddressInfoByChainId(chainId: string): Promise<Key | undefined> {
  // TODO: if keplr undefined, keplr install alert
  const keplr = await getKeplr();
  if (keplr) {
    const result = await Promise.all([await keplr.getKey(chainId)]);
    return result[0];
  } else {
    alert(`Can not get address info from ${chainId}`);
    return undefined;
  }
}
