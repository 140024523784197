const GovIconSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.33359 7.57867H3.43359V12.012H5.33359V7.57867Z" fill="currentColor" />
      <path d="M9.4498 7.57867H7.5498V12.012H9.4498V7.57867Z" fill="currentColor" />
      <path d="M14.8337 13.2787H2.16699V15.1787H14.8337V13.2787Z" fill="currentColor" />
      <path d="M13.567 7.57867H11.667V12.012H13.567V7.57867Z" fill="currentColor" />
      <path d="M8.50033 1.87868L2.16699 5.04534V6.31201H14.8337V5.04534L8.50033 1.87868Z" fill="currentColor" />
    </svg>
  );
};

export default GovIconSvg;
