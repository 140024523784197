import type { InfoAsset } from 'types/asset';
import Button from './Button';
import { useMainStore } from 'provider/mainContext';

type TokenSelectButtonProps = {
  asset?: InfoAsset;
  onClick?: () => void;
};

const TokenSelectButton = ({ asset, onClick }: TokenSelectButtonProps) => {
  const store = useMainStore();

  return (
    <Button
      type={asset ? 'tonal' : 'filled'}
      size={store.isMobile ? 'sm' : 'md'}
      label={asset?.ticker ?? 'Select a token'}
      trailingIcon="arrow-drop-down"
      coinAsset={asset}
      onClick={onClick}
    />
  );
};

export default TokenSelectButton;
