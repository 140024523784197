import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { MainStore } from 'store/store';
import type { InfoAsset } from 'types/asset';
import type { PairDetail } from 'types/pair';

const useTradeSearch = (mainStore: MainStore) => {
  const navigate = useNavigate();

  const getFromToSearch = useCallback(
    ({ fromAsset, toAsset }: { fromAsset: InfoAsset | undefined; toAsset: InfoAsset | undefined }) => {
      const from: string = fromAsset?.ticker.replace('.', '').toLowerCase() ?? '';
      const to: string = toAsset?.ticker.replace('.', '').toLowerCase() ?? '';
      return `from=${from}&to=${to}`;
    },
    []
  );

  const replaceFromToSearch = useCallback((search: string) => {
    const searches = location.search.replace('?', '').split('&');
    const pairRemovedSearch = searches
      .filter((search) => !search.includes('from=') && !search.includes('to='))
      .join('&');
    return `?${search}${pairRemovedSearch.length ? `&${pairRemovedSearch}` : ''}`;
  }, []);

  const navigateAndStorePair = useCallback(
    ({
      path,
      pair,
      isForward,
      doNotStore = false,
    }: {
      path: string;
      pair: PairDetail | null;
      isForward: boolean;
      doNotStore?: boolean;
    }) => {
      const fromToSearch = pair
        ? getFromToSearch({
            fromAsset: isForward ? pair.assets[1] : pair.assets[0],
            toAsset: isForward ? pair.assets[0] : pair.assets[1],
          })
        : '';

      navigate(`${path}${replaceFromToSearch(fromToSearch)}`);

      if (!doNotStore) {
        pair ? localStorage.setItem('orderbookPair', pair.pairId.toString()) : localStorage.removeItem('orderbookPair');
        mainStore.setOrderbookPairDirection(isForward ? 'forward' : 'backward');
      }
    },
    []
  );

  const location = useLocation();

  const locationSearch = useMemo<string>(() => location.search, [location.search]);

  const getTradeAssetsFromLocationSearch = useCallback<() => [InfoAsset | undefined, InfoAsset | undefined]>(() => {
    const paramsRaw = locationSearch.replace('?', '').split('&');
    const params: Record<string, string> = {};
    paramsRaw.forEach((item) => {
      const data = item.split('=');
      params[data[0]] = data[1];
    });

    let payAsset: InfoAsset | undefined;
    let receiveAsset: InfoAsset | undefined;

    if (params.from || params.to) {
      const allAssets = Object.values(mainStore.assetsData.info);
      allAssets.forEach((asset) => {
        const assetTicker = asset.ticker.replace('.', '').toLowerCase();
        if (params.from && assetTicker === params.from.toLowerCase()) {
          payAsset = asset;
        } else if (params.to && assetTicker === params.to.toLowerCase()) {
          receiveAsset = asset;
        }
      });
    }

    return [payAsset, receiveAsset];
  }, [locationSearch, mainStore.assetsData.info]);

  return {
    navigateAndStorePair,
    locationSearch,
    getTradeAssetsFromLocationSearch,
  };
};

export default useTradeSearch;
