import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { AssetDetail } from 'types/asset';

const useMyAssets = (assets: AssetDetail[]) => {
  const myAssets = useMemo<AssetDetail[]>(
    () => assets.filter((asset) => asset.availableBalance.gt(0) || asset.reservedBalance.gt(0)),
    [assets]
  );

  const totalUSD = useMemo<BigNumber>(() => {
    return myAssets.reduce(
      (accm, asset) =>
        accm.plus(
          asset.availableBalance.plus(asset.reservedBalance).shiftedBy(-asset.exponent).multipliedBy(asset.priceOracle)
        ),
      new BigNumber(0)
    );
  }, [myAssets]);

  return { myAssets, totalUSD };
};

export default useMyAssets;
