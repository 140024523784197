import { initWebSocket } from 'common/websocket/websocketHandlers';
import { SignParams } from 'types/signingClients';
import { createCrescentMsgs } from 'common/msg/createMsgs';
import {
  getSignInfo,
  getIBCData,
  getCreAddressByWallet,
  getCrescentChainSigningData,
  checkSigningData,
  displayError,
  broadcastTxHandler,
  checkEvmChain,
} from 'common/signingClients/utils/signUtils';
import { signEvmChain } from 'common/sign/evmSign';

export async function signWithCosmostaion({ type, chainData, txData, airdropClaimData, fee }: SignParams) {
  checkSigningData({ type, txData, fee, chainData });
  console.log('txData', txData);
  const walletType = 'cosmostation';
  const { creChainId, creChainInfo } = getSignInfo({ chainData });
  const webSocket = initWebSocket({ type, chainData, txData, creChainInfo });
  try {
    const creAddress = await getCreAddressByWallet({ walletType, creChainId });
    let signedTxRaw: any;

    if (type === 'deposit') {
      // External Chain Signing: IBC Deposit
      if (checkEvmChain(txData.originChainId).isEvm) {
        // EVM chain
        const result = await signEvmChain({
          txData,
          chainData,
          // EVM-TEST
          fee: {
            denom: fee?.amount[0]?.denom ?? txData?.denom ?? '',
            amount: fee?.amount[0]?.amount ?? '5000000000000000',
            gas: fee?.gas ?? '200000',
          },
          toAddress: creAddress,
          walletType,
        });
        signedTxRaw = result;
      } else {
        // Cosmos SDK only chain
        const { fromAddress, ibcDepositMsg, externalChainSigningClient } = await getIBCData({
          IBCType: type,
          walletType,
          data: { chainData, txData, creAddress },
        });
        signedTxRaw = await externalChainSigningClient.sign(fromAddress, ibcDepositMsg, fee, '');
      }
    } else {
      const { crescentChainFee, crescentChainSigningClient } = await getCrescentChainSigningData({
        walletType,
        txType: type,
        chainData,
        airdropClaimData,
        txData,
      });
      if (type === 'withdraw') {
        // Crescent Chain Signing: IBC Withdraw
        const { ibcWithdrawMsg } = await getIBCData({
          IBCType: type,
          walletType,
          data: { chainData, txData, creAddress },
        });
        signedTxRaw = await crescentChainSigningClient.sign(creAddress, ibcWithdrawMsg, crescentChainFee, '');
      } else {
        // Crescent Chain Signing: Crescent Msgs
        const CrescentChainMsg = await createCrescentMsgs({ type, txData, creAddress });
        signedTxRaw = await crescentChainSigningClient.sign(creAddress, CrescentChainMsg, crescentChainFee, '');
      }
    }
    await broadcastTxHandler({ signedTxRaw, webSocket, type, txData, chainData });
  } catch (e) {
    webSocket.close();
    return displayError(e);
  }
}
