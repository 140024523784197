import { useMainStore } from 'provider/mainContext';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateToMobileTrade = () => {
  const store = useMainStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (store.isMobile) {
      navigate(`/trade${location.search ?? ''}`);
    }
  }, [store.isMobile]);
};

export default useNavigateToMobileTrade;
