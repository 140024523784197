import { Registry } from '@cosmjs/proto-signing';
import { defaultRegistryTypes } from '@cosmjs/stargate';
import {
  MsgLimitOrder,
  MsgDeposit,
  MsgWithdraw,
  MsgMarketOrder,
  MsgCancelAllOrders,
  MsgCancelOrder,
} from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import { MsgHarvest, MsgStake, MsgUnstake } from '../crescent/farming/types/crescent/farming/v1beta1/tx';
import { MsgLiquidStake, MsgLiquidUnstake } from '../crescent/liquidstaking/types/crescent/liquidstaking/v1beta1/tx';
import { MsgClaim } from '../crescent/claim/types/crescent/claim/v1beta1/tx';
import {
  MsgLiquidFarm,
  MsgLiquidUnfarm,
  MsgLiquidUnfarmAndWithdraw,
} from 'common/crescent/liquidfarming/types/crescent/liquidfarming/v1beta1/tx';
import {
  MsgFarm,
  MsgUnfarm,
  MsgHarvest as MsgLpHarvest,
} from 'common/crescent/lpfarm/types/crescent/lpfarm/v1beta1/tx';

const registry = new Registry(defaultRegistryTypes);

registry.register('/crescent.claim.v1beta1.MsgClaim', MsgClaim);
registry.register('/crescent.liquidity.v1beta1.MsgLimitOrder', MsgLimitOrder);
registry.register('/crescent.liquidity.v1beta1.MsgDeposit', MsgDeposit);
registry.register('/crescent.liquidity.v1beta1.MsgWithdraw', MsgWithdraw);
registry.register('/crescent.liquidity.v1beta1.MsgCancelOrder', MsgCancelOrder);
registry.register('/crescent.liquidity.v1beta1.MsgCancelAllOrders', MsgCancelAllOrders);
registry.register('/crescent.liquidity.v1beta1.MsgMarketOrder', MsgMarketOrder);
registry.register('/crescent.farming.v1beta1.MsgStake', MsgStake);
registry.register('/crescent.farming.v1beta1.MsgUnstake', MsgUnstake);
registry.register('/crescent.farming.v1beta1.MsgHarvest', MsgHarvest);
registry.register('/crescent.liquidstaking.v1beta1.MsgLiquidStake', MsgLiquidStake);
registry.register('/crescent.liquidstaking.v1beta1.MsgLiquidUnstake', MsgLiquidUnstake);
registry.register('/crescent.liquidfarming.v1beta1.MsgLiquidFarm', MsgLiquidFarm);
registry.register('/crescent.liquidfarming.v1beta1.MsgLiquidUnfarm', MsgLiquidUnfarm);
registry.register('/crescent.liquidfarming.v1beta1.MsgLiquidUnfarmAndWithdraw', MsgLiquidUnfarmAndWithdraw);
registry.register('/crescent.lpfarm.v1beta1.MsgFarm', MsgFarm);
registry.register('/crescent.lpfarm.v1beta1.MsgUnfarm', MsgUnfarm);
registry.register('/crescent.lpfarm.v1beta1.MsgHarvest', MsgLpHarvest);

export default registry;
