import { useState, useEffect } from 'react';
import { useAllPairLive } from '../hooks/useAPI';
import { InfoAsset } from '../types/asset';
import { PairLives, LivePairRaw } from '../types/pair';

export interface PairDataInterface {
  pay: { available: string[]; unAvailable: string[] };
  receive: { available: string[]; unAvailable: string[] };
}

export function usePoolFinder(
  payCoin: InfoAsset | undefined,
  receiveCoin: InfoAsset | undefined,
): { selectedPair: LivePairRaw | undefined; pairData: PairDataInterface | undefined } {
  const [selectedPair, setSelectedPair] = useState<LivePairRaw>();
  const [pairData, setPairData] = useState<PairDataInterface>();
  const { data: allPairLiveData }: { data: PairLives; isError: any } = useAllPairLive(5000);
  useEffect(() => {
    if (receiveCoin && receiveCoin.denom !== '' && payCoin && payCoin.denom !== '') {
      //TODO: improve perfomance
      const selectedPair = allPairLiveData?.data.find((pair) => {
        const pairDenoms = [pair.baseDenom, pair.quoteDenom];
        return pairDenoms.includes(payCoin.denom) && pairDenoms.includes(receiveCoin.denom);
      });
      setSelectedPair(selectedPair);
    }

    const pairs = allPairLiveData?.data?.map((pair) => [pair.baseDenom, pair.quoteDenom]);
    let allPairsList: string[] = [];
    let payAvailablePairs: string[] = [];
    let payUnavailablePairs: string[] = [];
    let receiveAvailablePairs: string[] = [];
    let receiveUnavailablePairs: string[] = [];

    pairs?.forEach((pair) => {
      if (payCoin) {
        const payIndex = pair.indexOf(payCoin.denom);
        if (payIndex !== -1) {
          payAvailablePairs.push(pair[payIndex === 0 ? 1 : 0]);
        }
      }
      if (receiveCoin) {
        const receiveIndex = pair.indexOf(receiveCoin.denom);
        if (receiveIndex !== -1) {
          receiveAvailablePairs.push(pair[receiveIndex === 0 ? 1 : 0]);
        }
      }
      allPairsList.push(...pair);
    });

    const uniqueAllPairs = [...new Set(allPairsList)];

    payUnavailablePairs = uniqueAllPairs.filter(function (val) {
      return payAvailablePairs.indexOf(val) === -1;
    });
    receiveUnavailablePairs = uniqueAllPairs.filter(function (val) {
      return receiveAvailablePairs.indexOf(val) === -1;
    });

    const result = {
      pay: { available: [...new Set(payAvailablePairs)], unAvailable: [...new Set(payUnavailablePairs)] },
      receive: { available: [...new Set(receiveAvailablePairs)], unAvailable: [...new Set(receiveUnavailablePairs)] },
    };
    setPairData(result);
  }, [payCoin, receiveCoin, allPairLiveData]);
  return { selectedPair, pairData };
}
