import type { Keplr, Key } from '@keplr-wallet/types';

export async function getLeap(): Promise<Keplr | undefined> {
  if (window.leap) {
    return window.leap;
  }
  if (document.readyState === 'complete') {
    return window.leap;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event: Event) => {
      if (event.target && (event.target as Document).readyState === 'complete') {
        resolve(window.leap);
        document.removeEventListener('readystatechange', documentStateChange);
      }
    };
    document.addEventListener('readystatechange', documentStateChange);
  });
}

export async function getLeapAddressInfoByChainId(chainId: string): Promise<Key | undefined> {
  // TODO: if keplr undefined, keplr install alert
  const leap = await getLeap();
  if (leap) {
    const result = await Promise.all([await leap.getKey(chainId)]);
    return result[0];
  } else {
    alert(`Can not get address info from ${chainId}`);
    return undefined;
  }
}
