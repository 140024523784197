import BigNumber from 'bignumber.js';
import { Coin, CoinRaw } from 'types/asset';
import { LpFarmRewardRaw, LpFarmStaking, LpFarmStakingRaw, Reward } from 'types/farm';
import { LiquidFarmLive, LiquidFarmLiveRaw } from 'types/liquidFarm';
import { LivePairRaw, LivePair, PoolRaw, Pool, PoolBN } from 'types/pair';
import { MyPoolRaw, MyPool, PoolLive, PoolLiveRaw, Reserve, ReserveRaw } from 'types/pool';

export function toShort(value: BigNumber | null | undefined) {
  if (value) {
    if (value.gte(1000000)) {
      return `${value.dividedBy(1000000).decimalPlaces(0).toString()} M`;
    } else if (value.gte(1000)) {
      return `${value.dividedBy(1000000).decimalPlaces(0).toString()} K`;
    } else {
      return `${value.toString()}`;
    }
  } else {
    return '';
  }
}

export function toU(value: string | number, exponent: number) {
  if (typeof value === 'string') {
    return parseInt(value) * 10 ** exponent;
  } else if (typeof value === 'number') {
    return value * 10 ** exponent;
  }
}

export function fromU(value: string | number, exponent: number) {
  if (typeof value === 'string') {
    return parseInt(value) / 10 ** exponent;
  } else if (typeof value === 'number') {
    return value / 10 ** exponent;
  }
}

export function toLivePairBN(livePool: LivePairRaw): LivePair;
export function toLivePairBN(livePool: LivePairRaw[]): LivePair[];
export function toLivePairBN(livePool: LivePairRaw | LivePairRaw[]): LivePair | LivePair[] {
  if (Array.isArray(livePool)) {
    return livePool.map<LivePair>((pool: LivePairRaw) => toLivePairBN(pool));
  } else {
    const totalReserved = livePool.totalReserved.map((asset) => {
      return { denom: asset.denom, amount: new BigNumber(asset.amount), priceOracle: new BigNumber(asset.priceOracle) };
    });

    return {
      ...livePool,
      lastPriceDec: new BigNumber(livePool.lastPriceDec),
      predPrice: new BigNumber(livePool.predPrice),
      lastPrice: new BigNumber(livePool.lastPrice),
      totalReserved,
      // swapFee: new BigNumber(livePool.swapFee),
      // withdrawalFee: new BigNumber(livePool.withdrawalFee),
      // totalShare: new BigNumber(livePool.totalShare),
      // lastUniversalPrice: new BigNumber(livePool.lastUniversalPrice),
    };
  }
}

export function toLivePairRAW(livePool: LivePair): LivePairRaw;
export function toLivePairRAW(livePool: LivePair[]): LivePairRaw[];
export function toLivePairRAW(livePool: LivePair | LivePair[]): LivePairRaw | LivePairRaw[] {
  if (Array.isArray(livePool)) {
    return livePool.map<LivePairRaw>((pool: LivePair) => toLivePairRAW(pool));
  } else {
    const totalReserved = livePool.totalReserved.map((asset) => {
      return { denom: asset.denom, amount: asset.amount.toNumber(), priceOracle: asset.priceOracle.toNumber() };
    });

    return {
      ...livePool,
      lastPriceDec: livePool.lastPriceDec.toNumber(),
      predPrice: livePool.predPrice.toString(),
      lastPrice: livePool.lastPrice.toString(),
      totalReserved,
      // swapFee: new BigNumber(livePool.swapFee),
      // withdrawalFee: new BigNumber(livePool.withdrawalFee),
      // totalShare: new BigNumber(livePool.totalShare),
      // lastUniversalPrice: new BigNumber(livePool.lastUniversalPrice),
    };
  }
}

export function toPoolLiveBN(rawPool: PoolLiveRaw): PoolLive;
export function toPoolLiveBN(rawPool: PoolLiveRaw[]): PoolLive[];
export function toPoolLiveBN(rawPool: PoolLiveRaw | PoolLiveRaw[]): PoolLive | PoolLive[] {
  if (Array.isArray(rawPool)) {
    return rawPool.map<PoolLive>((pool: PoolLiveRaw) => toPoolLiveBN(pool));
  } else {
    return {
      ...rawPool,
      poolPrice: new BigNumber(rawPool.poolPrice),
      totalStakedAmount: new BigNumber(rawPool.totalStakedAmount),
      totalSupplyAmount: new BigNumber(rawPool.totalSupplyAmount),
      // totalQueuedAmount: new BigNumber(rawPool.totalQueuedAmount),
      Reserved: rawPool.Reserved.map((item) => toReserveBN(item)),
    };
  }
}

export function toPoolBN(rawPool: PoolRaw): PoolBN;
export function toPoolBN(rawPool: PoolRaw[]): PoolBN[];
export function toPoolBN(rawPool: PoolRaw | PoolRaw[]): PoolBN | PoolBN[] {
  if (Array.isArray(rawPool)) {
    return rawPool.map<PoolBN>((pool: PoolRaw) => toPoolBN(pool));
  } else {
    return {
      ...rawPool,
      // reserved: [
      //   rawPool.reserved[0] ? toCoinBN(rawPool.reserved[0]) : undefined,
      //   rawPool.reserved[1] ? toCoinBN(rawPool.reserved[1]) : undefined,
      // ],
      reserved: rawPool.reserved.map(toCoinBN) as [Coin] | [Coin, Coin],
      totalSupply: new BigNumber(rawPool?.totalSupply),
    };
  }
}

export function toMyPoolBN(rawMyPool: MyPoolRaw): MyPool;
export function toMyPoolBN(rawMyPool: MyPoolRaw[]): MyPool[];
export function toMyPoolBN(rawMyPool: MyPoolRaw | MyPoolRaw[]): MyPool | MyPool[] {
  if (Array.isArray(rawMyPool)) {
    return rawMyPool.map<MyPool>((pool: MyPoolRaw) => toMyPoolBN(pool));
  } else {
    return {
      RewardPerToken: rawMyPool.RewardPerToken.map((item) => ({
        rewardDenom: item.rewardDenom,
        rewardAmount: new BigNumber(item.rewardAmount),
      })),
      balanceAmount: new BigNumber(rawMyPool.balanceAmount),
      denom: rawMyPool.denom,
      priceOracle: new BigNumber(rawMyPool.priceOracle),
      stakedAmount: new BigNumber(rawMyPool.stakedAmount),
      totalStakedAmount: new BigNumber(rawMyPool.totalStakedAmount),
    };
  }
}

/** @todo under replacement; will remove */
// export function toRewardBN(raw: RewardRaw): Reward;
// export function toRewardBN(raw: RewardRaw[]): Reward[];
// export function toRewardBN(raw: RewardRaw | RewardRaw[]): Reward | Reward[] {
//   if (Array.isArray(raw)) {
//     return raw.map<Reward>((reward: RewardRaw) => toRewardBN(reward));
//   } else {
//     return {
//       rewardDenom: raw?.rewardDenom,
//       rewardAmount: new BigNumber(raw?.rewardAmount),
//     };
//   }
// }

export function toLpFarmRewardBN(raw: LpFarmRewardRaw): Reward;
export function toLpFarmRewardBN(raw: LpFarmRewardRaw[]): Reward[];
export function toLpFarmRewardBN(raw: LpFarmRewardRaw | LpFarmRewardRaw[]): Reward | Reward[] {
  if (Array.isArray(raw)) {
    return raw.map<Reward>((reward: LpFarmRewardRaw) => toLpFarmRewardBN(reward));
  } else {
    return {
      rewardDenom: raw?.rewardDenom,
      rewardAmount: new BigNumber(raw?.rewardAmount),
    };
  }
}

export function toReserveBN(raw: ReserveRaw): Reserve {
  return {
    denom: raw?.denom,
    priceOracle: new BigNumber(raw.priceOracle),
    amount: new BigNumber(raw.amount),
  };
}

export function toCoinBN(raw: CoinRaw): Coin {
  return {
    denom: raw?.denom,
    amount: new BigNumber(raw?.amount),
  };
}
/** @todo under replacement; will remove */
// export function toFarmStakingBN(raw: FarmStakingRaw): FarmStaking {
//   return {
//     denom: raw.denom,
//     harvestable: toRewardBN(raw.harvestable),
//     unharvest: toRewardBN(raw.unharvest),
//     queuedAmount: new BigNumber(raw.queuedAmount || 0),
//     stakedAmount: new BigNumber(raw.stakedAmount || 0),
//   };
// }

export function toLpFarmStakingBN(raw: LpFarmStakingRaw): LpFarmStaking {
  return {
    denom: raw?.denom,
    harvestable: toLpFarmRewardBN(raw.harvestable),
    stakedAmount: new BigNumber(raw.stakedAmount ?? 0),
  };
}

export function toLiquidFarmBN(raw: LiquidFarmLiveRaw): LiquidFarmLive {
  return {
    ...raw,
    minDepositAmount: new BigNumber(raw.minDepositAmount),
    stakeAmount: new BigNumber(raw.stakeAmount),
    compoundingAmount: new BigNumber(raw.compoundingAmount),
    lfTotalSupply: new BigNumber(raw.lfTotalSupply),
  };
}
