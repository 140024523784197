import { ReactNode } from 'react';
import TooltipTitle, { TooltipHighlightData } from './TooltipTitle';

const TooltipInner = ({
  children,
  title,
  highlight,
  padding = true,
}: {
  children: ReactNode;
  title?: string | JSX.Element;
  highlight?: TooltipHighlightData;
  padding?: boolean;
}) => {
  return (
    <div className="w-full">
      {title !== undefined && (
        <div className="rounded-t-8px overflow-hidden">
          <TooltipTitle title={title} highlight={highlight} />
        </div>
      )}

      <div className={padding ? 'px-3 py-2' : ''}>{children}</div>
    </div>
  );
};

export default TooltipInner;
