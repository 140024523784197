import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD, getValidDigitNumber } from 'common/utils';
import Icon from 'components/icon';

import { useMemo } from 'react';
import { TableStyles, TABLE_CELL_FONT_DICT, TABLE_CELL_TEXT_COLOR_DICT } from './styles';
import {
  TableDataType,
  TableField,
  TableFieldAmount,
  TableFieldChange,
  TableFieldJsx,
  TableFieldUsd,
  TableRowData,
} from './types';
import TooltipIcon from 'components/tooltips/TooltipIcon';

type TableCellGenerateFunction<T> = (value: any, data: T, field: any) => JSX.Element;

type TableCellProps<T> = {
  data: T;
  field: TableField<T>;
  type: TableStyles;
};

const TableCell = <T extends TableRowData>({ data, field, type }: TableCellProps<T>) => {
  const cellTooltipContent = useMemo<JSX.Element | undefined>(
    () => data[`${field.value}TooltipContent`],
    [data, field.value]
  );

  if (data[field.value] === undefined || data[field.value] === null) return <></>;

  const TABLE_CELL_DICT: { [key in TableDataType]: TableCellGenerateFunction<T> } = {
    amount: (value: BigNumber, data, field: TableFieldAmount<T>) => (
      <span
        className={`${TABLE_CELL_TEXT_COLOR_DICT[type]} ${TABLE_CELL_FONT_DICT[type]} ${
          value.isZero() ? 'opacity-40' : ''
        }`}
      >
        {field.validDigits !== undefined
          ? new BigNumber(getValidDigitNumber(value.toNumber(), field.validDigits)).toFormat()
          : formatAmount(value, data.exponent ?? 6)}
        <span></span>
      </span>
    ),
    usd: (value: BigNumber, data, field: TableFieldUsd<T>) => (
      <span
        className={`${TABLE_CELL_TEXT_COLOR_DICT[type]} ${TABLE_CELL_FONT_DICT[type]} ${
          value.isZero() ? 'opacity-40' : ''
        }`}
      >
        {field.validDigits !== undefined
          ? `$${new BigNumber(getValidDigitNumber(value.toNumber(), field.validDigits)).toFormat()}`
          : formatUSD(value, { compact: field.compact ?? false })}
      </span>
    ),
    change: (value: BigNumber, data, field: TableFieldChange<T>) => {
      const absNumber = value.abs();
      const iconType = value.gt(0) ? 'up' : value.lt(0) ? 'down' : undefined;

      const changeColor = value.gt(0)
        ? 'text-semantic_green'
        : value.lt(0)
        ? 'text-semantic_red'
        : TABLE_CELL_TEXT_COLOR_DICT[type];
      const colorClassName = field.forceNeutral ? TABLE_CELL_TEXT_COLOR_DICT[type] : changeColor;

      return (
        <span className={`flex items-center gap-x-1 ${colorClassName} ${TABLE_CELL_FONT_DICT[type]}`}>
          {!field.forceNeutral && iconType !== undefined && <Icon type={iconType} size="14px" />}
          <span className="">{formatAmount(absNumber, 2, { fixMantissa: true })}%</span>
        </span>
      );
    },
    jsx: (value: JSX.Element, data, field: TableFieldJsx<T>) => (
      <div className={`${TABLE_CELL_TEXT_COLOR_DICT[type]} ${TABLE_CELL_FONT_DICT[type]}`}>{value}</div>
    ),
  };

  return (
    <div className="relative inline-flex items-center gap-x-1">
      <div>{TABLE_CELL_DICT[field.type](data[field.value], data, field)}</div>

      {cellTooltipContent && (
        <div className="md:absolute top-0 -right-[1.25rem] h-full flex items-center text-on_surface_variant_dark">
          <TooltipIcon
            tooltipSize={field.tooltipSize}
            tooltipWordBreak={field.tooltipWordBreak}
            tooltipContent={cellTooltipContent}
          />
        </div>
      )}
    </div>
  );
};

export default TableCell;
