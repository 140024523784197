import { useCallback, useEffect, useState } from 'react';

type LocalStorageData = {
  value: string;
  expires?: string;
};

type UseLocalStorageProps = {
  key: string;
  onGet?: (value: string) => void;
  onFailGet?: () => void;
};

const useLocalStorage = ({ key, onGet, onFailGet }: UseLocalStorageProps) => {
  const setLocal = useCallback(
    (data: LocalStorageData) => {
      localStorage.setItem(key, JSON.stringify(data));
    },
    [key]
  );

  const getLocal = useCallback(() => {
    const localData = localStorage.getItem(key);

    if (localData) {
      const { value, expires } = JSON.parse(localData);

      if (!expires || Number(expires) >= Date.now()) {
        onGet?.(value);
      }
    } else {
      onFailGet?.();
    }
  }, [key, onGet, onFailGet]);

  const removeLocal = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  useEffect(() => {
    if (onGet || onFailGet) getLocal();
  }, []);

  return {
    setLocal,
    removeLocal,
  };
};

export default useLocalStorage;
