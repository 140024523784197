import { MsgCancelAllOrders } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createCancelAllOrdersMsg = (txData: any, creAddress: any) => {
  const CancelAllOrdersData: MsgCancelAllOrders = {
    orderer: creAddress,
    pairIds: txData.pairIds,
  };
  const msg = msgEncoder.msgCancelAllOrders(CancelAllOrdersData);
  return [msg];
};

export default createCancelAllOrdersMsg;
