import { MsgFarm } from '../crescent/lpfarm/types/crescent/lpfarm/v1beta1/tx';
import type { Coin } from 'common/crescent/liquidfarming/types/cosmos/base/v1beta1/coin';
import msgEncoder from 'common/sign/msgEncoder';

export type FarmTxData = {
  coin: Coin | undefined;
};

const createFarmMsg = (txData: FarmTxData, creAddress: any) => {
  const farmData: MsgFarm = {
    farmer: creAddress,
    coin: txData.coin,
  };

  console.log('farmData', farmData);
  const msg = msgEncoder.msgFarm(farmData);
  return [msg];
};

export default createFarmMsg;
