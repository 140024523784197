import {
  IBC_DISABLED_NOTE,
  IS_IBC_ENABLED,
  MOBILE_IBC_DISABLED_BASE_DENOMS,
  MOBILE_IBC_DISABLED_NOTE,
} from 'COMMON_VARIABLES';
import IconButton from 'components/buttons/IconButton';
import { TokenTypes } from 'components/constants/token';
import Tooltip from 'components/tooltips';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import { AssetDetail } from 'types/asset';

/** @desc token transfer button & hook */
const useIsAssetTransferDisabled = (asset: AssetDetail) => {
  const store = useMainStore();

  const isTimeout = useMemo(() => {
    const timeout = store.chainsData.live[asset.chainId]?.timeout;
    return timeout === undefined || timeout > 60 || timeout < 0;
  }, [store.chainsData.live, asset.chainId]);

  const hasNoChainToTransfer = useMemo<boolean>(
    () => (asset.tokenType === TokenTypes.POOL || asset.tokenType === TokenTypes.LF) && asset.chains?.length === 0,
    [asset.tokenType, asset.chains?.length]
  );

  const disabled = !IS_IBC_ENABLED || isTimeout || hasNoChainToTransfer;

  const desc = !IS_IBC_ENABLED
    ? IBC_DISABLED_NOTE
    : isTimeout
    ? 'Temporarily IBC timeout is occurring, Please wait'
    : 'No chain to transfer via at the moment.';

  return { disabled, desc };
};

const TokenTransferButton = ({ asset, onClick }: { asset: AssetDetail; onClick?: (asset: AssetDetail) => void }) => {
  const { disabled, desc } = useIsAssetTransferDisabled(asset);

  const store = useMainStore();
  const isWorkInProgress = useMemo<boolean>(() => {
    return MOBILE_IBC_DISABLED_BASE_DENOMS.includes(asset.baseDenom) && store.isMobile;
  }, [store.isMobile, asset.baseDenom]);

  const button = (
    <IconButton
      size={store.isMobile ? 'lg' : 'md'}
      type="tonal"
      iconType="ibc"
      disabled={disabled || isWorkInProgress}
      onClick={() => onClick?.(asset)}
    />
  );

  return disabled || isWorkInProgress ? (
    <Tooltip content={isWorkInProgress ? undefined : desc}>
      <div
        onClick={() => {
          if (isWorkInProgress) alert(MOBILE_IBC_DISABLED_NOTE);
        }}
      >
        {button}
      </div>
    </Tooltip>
  ) : (
    button
  );
};

export default TokenTransferButton;
