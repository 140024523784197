export type WidgetHeaderStyle = 'headline-text' | 'head-area';

type WidgetHeaderProps = {
  children?: React.ReactNode;
  type?: WidgetHeaderStyle;
  title: string;
};

const WidgetHeader = ({ children, type = 'headline-text', title }: WidgetHeaderProps) => {
  return type === 'headline-text' ? (
    <div className="flex items-center justify-between gap-x-2">
      <h3 className="font_headline_s_mobile md:font_headline_s text-white mb-6">{title}</h3>
      {children}
    </div>
  ) : (
    <div className="w-full h-[2.25rem] flex justify-between items-center gap-x-2 pl-2 pr-2 bg_surface_-3 border-b border-outline_soft rounded-t-[inherit] md:h-[2.75rem] md:pl-3">
      <h3 className="font_caption_s text-on_surface_variant_dark md:font_caption_m">{title}</h3>
      {children}
    </div>
  );
};

WidgetHeader.TopRight = ({ children }: { children: React.ReactNode }) => <>{children}</>;

export default WidgetHeader;
