export type TogglableButtonItem<T> = {
  label: string;
  labelType?: 'text' | 'number';
  value: T;
  disabled?: boolean;
};

export type TogglableButtonColor = 'primary' | 'liquid';
type TogglableButtonStyle = 'outlined';
type ToggleableButtonSize = 'xs' | 'sm';

/** @desc color */
const TOGGLABLE_BUTTON_COLOR_DICT: {
  [key in TogglableButtonColor]: { [key in TogglableButtonStyle]: { active: string; inactive: string } };
} = {
  primary: {
    outlined: {
      active: 'text-primary bg-transparent border border-primary hover:bg-primary_o24',
      inactive: 'text-semantic_gray bg-transparent border border-semantic_gray hover:bg-semantic_gray_o24',
    },
  },
  liquid: {
    outlined: {
      active: 'text-liquid bg-transparent border border-liquid hover:bg-liquid_o24',
      inactive: 'text-semantic_gray bg-transparent border border-semantic_gray hover:bg-semantic_gray_o24',
    },
  },
};

const TOGGLABLE_BUTTON_DISABLED_COLOR = 'text-on_surface_o40 border border-outline_hard_o24';

/** @desc grid */
const TOGGLABLE_BUTTON_GRID_DICT: { [key in ToggleableButtonSize]: string } = {
  xs: 'px-[0.375rem] py-[0.1875rem]',
  sm: 'px-3 py-[0.375rem]',
};

const TOGGLABLE_BUTTON_FONT_DICT: { [key in ToggleableButtonSize]: { [key in 'text' | 'number']: string } } = {
  xs: { text: 'font_label_s', number: 'font_label_number_s' },
  sm: { text: 'font_label_s', number: 'font_label_number_s' },
};

type TogglableButtonGroupProps<T> = {
  color?: TogglableButtonColor;
  type?: TogglableButtonStyle;
  size: ToggleableButtonSize;
  xFull?: boolean;
  list: TogglableButtonItem<T>[];
  selectedItem?: T;
  onSelect?: (value: T) => void;
};

const TogglableButtonGroup = <T extends any>({
  color = 'primary',
  type = 'outlined',
  size,
  xFull = true,
  list = [],
  selectedItem,
  onSelect,
}: TogglableButtonGroupProps<T>) => {
  return (
    <div className={`relative w-full flex items-center gap-x-1 ${xFull ? '' : 'max-w-[max-content]'} md:gap-x-2`}>
      {list.map((item, index) => (
        <button
          key={index}
          type="button"
          disabled={item.disabled}
          onClick={() => {
            onSelect?.(item.value);
          }}
          className={`relative w-full rounded-100px ${TOGGLABLE_BUTTON_GRID_DICT[size]} ${
            TOGGLABLE_BUTTON_FONT_DICT[size][item.labelType ?? 'text']
          } ${
            item.disabled
              ? TOGGLABLE_BUTTON_DISABLED_COLOR
              : TOGGLABLE_BUTTON_COLOR_DICT[color][type][item.value === selectedItem ? 'active' : 'inactive']
          } ${item.disabled ? 'cursor-not-allowed' : ''}`}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default TogglableButtonGroup;
