import BigNumber from 'bignumber.js';
import { getRewardsPerDistinctRewardToken, RewardInfo } from 'common/utils';
import { useMemo } from 'react';
import type { PoolDetail } from 'types/pool';
import DailyRewards from 'components/pools/tooltip/DailyRewards';
import AprText from 'components/texts/AprText';
import usePoolApr from './hooks/usePoolApr';

type PoolAprProps = {
  pool: PoolDetail;
  isLF?: boolean;
  showRewardsDetail?: boolean;
};

const PoolApr = ({ pool, isLF = false, showRewardsDetail = true }: PoolAprProps) => {
  const { underlyingBCreApr } = usePoolApr(pool);

  const rewards = useMemo<RewardInfo[]>(
    () => (showRewardsDetail ? getRewardsPerDistinctRewardToken(pool) : []),
    [pool, showRewardsDetail]
  );

  const value10kMultiplier = useMemo(() => {
    return new BigNumber(10000).dividedBy(new BigNumber(pool.priceOracle).shiftedBy(12)).toNumber();
  }, [pool]);

  /** @caution must sync with isPlanWarning below */
  const isEndWarning = false;
  // const isEndWarning = !rewards.every((reward) => {
  //   const isEndSoon = reward?.plans?.every((plan) => {
  //     return new BigNumber(new BigNumber(new BigNumber(plan.end).shiftedBy(3)).minus(new Date().getTime())).gt(17.28e7);
  //   });
  //   return isEndSoon;
  // });

  return (
    <AprText
      apr={new BigNumber(isLF ? pool.lfApr : pool.apr ?? '0')}
      aprAdditional={underlyingBCreApr}
      tooltipContent={<DailyRewards pool={pool} rewards={rewards} multiplier={value10kMultiplier} />}
    />
  );
};

export default PoolApr;
