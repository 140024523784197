type DividerStyle = 'soft' | 'hard' | 'medium';

const DIVIDER_BG_DICT: { [key in DividerStyle]: string } = {
  soft: 'bg-outline_soft',
  hard: 'bg-outline_hard',
  medium: 'bg-outline_medium',
}

type DividerProps = {
  type?: DividerStyle;
}

const Divider = ({ type = 'soft' }: DividerProps) => {
  return <div className={`w-full h-[1px] ${DIVIDER_BG_DICT[type]}`}></div>;
};

export default Divider;
