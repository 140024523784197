import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStakingLive } from 'hooks/useAPI';
import useTxClient from 'hooks/useTxClient';
import BigNumber from 'bignumber.js';
import { INFOBOX_TEXTS, INITIAL_ASSET } from 'COMMON_VARIABLES';
import { useMainStore } from 'provider/mainContext';
import { STAKING_DENOMS } from 'COMMON_VARIABLES';
import { formatAmount } from 'common/utils';
import StakingAPRPanel from './StakingAPRPanel';
import StakingTitle from './StakingTitle';
import Formula from 'components/texts/Formula';
import AmountInput from 'components/inputs/AmountInput';
import { AssetDetail } from 'types/asset';
import Divider from 'components/divider/Divider';
import LabeledData from 'components/texts/LabeledData';
import NumberText from 'components/texts/NumberText';
import Button, { ButtonStatus } from 'components/buttons/Button';
import InfoBox from 'components/infobox';

type StakingFormProps = {
  direction: 'stake' | 'unstake';
};

const StakingForm = ({ direction }: StakingFormProps) => {
  const mainStore = useMainStore();
  const [payAmount, setPayAmount] = useState('');
  const { data: stakingData, isLoading: isStakingLoading } = useStakingLive();
  const [isLoading, setLoading] = useState(false);

  /** @wip */
  const stakingApr = new BigNumber(mainStore.getLiquidStakingApr());

  const exchangeRate = useMemo<BigNumber | undefined>(() => {
    if (!stakingData?.data) return undefined;

    return direction === 'stake'
      ? new BigNumber(stakingData.data.mintRatio)
      : new BigNumber(1).div(stakingData.data.mintRatio);
  }, [stakingData?.data, direction]);

  const targetAsset = useMemo<AssetDetail | undefined>(
    () => mainStore.assetDetailDict[direction === 'stake' ? 'ucre' : 'ubcre'],
    [direction, mainStore.assetDetailDict]
  );
  const returnAsset = useMemo<AssetDetail | undefined>(
    () => mainStore.assetDetailDict[direction === 'stake' ? 'ubcre' : 'ucre'],
    [direction, mainStore.assetDetailDict]
  );

  const expectedReturnAmount = useMemo<BigNumber | undefined>(() => {
    if (!exchangeRate) return undefined;

    const returnAmount = new BigNumber(payAmount).multipliedBy(exchangeRate);
    return returnAmount.isNaN() ? new BigNumber(0) : returnAmount;
  }, [exchangeRate, payAmount]);

  const balance = useMemo<BigNumber>(() => {
    return targetAsset ? targetAsset.availableBalance.shiftedBy(-targetAsset.exponent) : new BigNumber(0);
  }, [targetAsset]);

  const button = useMemo<{
    status: ButtonStatus;
    label: string;
  }>(() => {
    if (isLoading) return { status: 'loading', label: '' };

    if (balance.lte(0)) return { status: 'disabled', label: 'Insufficient balance' };

    if (new BigNumber(payAmount).isNaN() || new BigNumber(payAmount).lte(0))
      return { status: 'disabled', label: 'Enter amount' };

    if (new BigNumber(payAmount).gt(balance)) return { status: 'disabled', label: 'Insufficient balance' };

    if (direction === 'stake' && new BigNumber(payAmount).lt(1)) return { status: 'disabled', label: 'Min. 1 CRE' };

    return { status: 'enabled', label: direction === 'stake' ? 'Stake' : 'Unstake' };
  }, [isLoading, payAmount, balance, direction]);

  /** @desc input  */
  const handleSetPayAmount = (e: any) => {
    if (!isLoading) {
      setPayAmount(e);
    }
  };

  /** @desc siging */
  const { signAndBroadcast } = useTxClient();

  const stake = useCallback(async () => {
    setLoading(true);
    const amount = new BigNumber(payAmount).shiftedBy(6).toString();
    let error = await signAndBroadcast({
      type: 'staking',
      txData: {
        stakingCoin: { denom: STAKING_DENOMS.staked, amount },
      },
      chainData: mainStore.chainsData,
    });
    if (!error) {
      setPayAmount('');
    }
    setLoading(false);
  }, [payAmount, mainStore.chainsData, signAndBroadcast]);

  const unstake = useCallback(async () => {
    // TODO: some checks, loading
    setLoading(true);
    const amount = new BigNumber(payAmount).shiftedBy(6).toString();
    let error = await signAndBroadcast({
      type: 'unstaking',
      txData: {
        unstakingCoin: { denom: STAKING_DENOMS.unstaked, amount },
      },
      chainData: mainStore.chainsData,
    });
    if (!error) {
      setPayAmount('');
    }
    setLoading(false);
  }, [payAmount, mainStore.chainsData, signAndBroadcast]);

  return (
    <div className="space-y-5 md:space-y-6">
      {direction === 'stake' && <StakingAPRPanel apr={stakingApr} />}

      <div className="flex items-center justify-between gap-x-2">
        <StakingTitle direction={direction} targetAsset={targetAsset} />

        <Formula
          aValue={new BigNumber(1)}
          aUnit={targetAsset?.ticker ?? ''}
          bUnit={returnAsset?.ticker ?? ''}
          bRate={exchangeRate}
          dp={returnAsset?.exponent ?? 6}
          seperator="="
        />
      </div>

      <AmountInput
        value={payAmount}
        asset={targetAsset}
        onChangeValue={handleSetPayAmount}
        maxAmount="balance"
        simpleInputData={{
          signType: direction === 'stake' ? 'staking' : 'unstaking',
          txChainId: INITIAL_ASSET.chainId,
        }}
      />

      <div className="space-y-4">
        <Divider />

        <LabeledData
          label="Expected return amount"
          value={
            <NumberText
              value={expectedReturnAmount ? formatAmount(expectedReturnAmount, 6) : ''}
              unit={returnAsset?.ticker}
              size="sm"
            />
          }
        />
      </div>

      {/** @todo unstake warning text */}
      {direction === 'unstake' && <InfoBox type="warning">{INFOBOX_TEXTS.UNSTAKE_WARNING}</InfoBox>}

      <Button
        type="filled"
        size="lg"
        className="w-full"
        label={button.label}
        onClick={direction === 'stake' ? stake : unstake}
        status={button.status}
      />
    </div>
  );
};

export default observer(StakingForm);
