const TwitterLoadingSkeletonItem = () => {
  return (
    <div className="w-full flex justify-start items-start gap-x-3">
      <div className="grow-0 shrink-0 rounded-full w-[44px] h-[44px] bg_loading"></div>

      <div className="grow shrink w-full space-y-2">
        <div className="w-full grid grid-cols-3">
          <div className="rounded-100px h-[10px] bg_loading"></div>
        </div>
        <div className="rounded-100px w-full h-[10px] bg_loading"></div>
        <div className="rounded-8px w-full h-[50px] bg_loading"></div>
      </div>
    </div>
  );
};

const TwitterLoadingSkeleton = ({ className = '' }: { className?: string }) => {
  return (
    <div
      role="status"
      className={`w-full h-hull overflow-hidden space-y-8 px-3 py-4 border border-outline_soft rounded-8px ${className}`}
    >
      {new Array(3).fill(0).map((_, index) => (
        <TwitterLoadingSkeletonItem key={index} />
      ))}
    </div>
  );
};

export default TwitterLoadingSkeleton;
