import { INFOBOX_TEXTS, MAX_DECIMAL } from 'COMMON_VARIABLES';
import BigNumber from 'bignumber.js';
import {
  formatAmount,
  formatUSD,
  getDecimalSeperator,
  getDollarValueOnCreChainByDenom,
  onlyNumberValueUpdaterWhiteWords,
} from 'common/utils';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import PercentButtonGroup from 'components/buttons/PercentButtonGroup';
import InfoBox from 'components/infobox';
import NumberInput from 'components/inputs/NumberInput';
import LabeledData from 'components/texts/LabeledData';
import NumberText from 'components/texts/NumberText';
import Tooltip from 'components/tooltips';
import TooltipInner from 'components/tooltips/TooltipInner';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { OrderbookPair } from 'types/orderbook';

const ORDER_DIRECTIONS = ['Buy', 'Sell'];

export type AvailableBalance = { ticker: string; denom: string; exponent: number; displayBalance: string };

type TradeFormProps = {
  orderbookPair: OrderbookPair | undefined;
  avblData: AvailableBalance;
  isBalanceOver: boolean;
  orderIndex: 0 | 1;
  orderTypeIndex: 0 | 1 | 2;
  price: string;
  setPrice: (price: string) => void;
  priceStep?: number;
  quantity: string;
  setQuantity: (quantity: string) => void;
  isLoading: boolean;
  selectedPercent: number;
  setSelectedPercent: (percent: number) => void;
  onSelectPercentInput: (percent: number) => void;
  onReset: () => void;
  onOrder: () => void;
};

const TradeForm = ({
  orderbookPair,
  avblData,
  isBalanceOver,
  orderIndex,
  orderTypeIndex,
  price,
  setPrice,
  priceStep,
  quantity,
  setQuantity,
  isLoading,
  selectedPercent,
  setSelectedPercent,
  onSelectPercentInput,
  onReset,
  onOrder,
}: TradeFormProps) => {
  const mainStore = useMainStore();

  const isInputValid = useMemo<boolean>(() => {
    if (orderTypeIndex === 0) {
      return !!(Number(price) && Number(quantity));
    } else {
      return !!Number(quantity);
    }
  }, [price, quantity, orderTypeIndex]);

  const isFormInitialState = useMemo<boolean>(() => {
    if (!orderbookPair?.predPrice || !orderbookPair?.exponentDiff) return true;

    const isInitialQ = selectedPercent === 0 && quantity === '';
    const isInitialP =
      orderTypeIndex !== 0 ||
      price ===
        new BigNumber(orderbookPair?.predPrice).shiftedBy(orderbookPair?.exponentDiff).dp(MAX_DECIMAL, 1).toString();

    return isInitialQ && isInitialP;
  }, [selectedPercent, quantity, orderTypeIndex, price, orderbookPair]);

  return (
    <div className="space-y-4">
      {/* Balance */}
      <LabeledData
        labelSize="sm"
        label={mainStore.isMobile ? 'Available' : 'Available Balance'}
        value={
          <NumberText
            size="xs"
            align="right"
            value={formatAmount(new BigNumber(avblData.displayBalance), 9)}
            unit={avblData.ticker}
            subValue={`≈ ${
              avblData.denom
                ? formatUSD(new BigNumber(getDollarValueOnCreChainByDenom(avblData.denom, avblData.displayBalance)))
                : formatUSD(new BigNumber(0))
            }`}
          />
        }
      />

      <div className="space-y-3 md:space-y-2">
        {/* Price */}
        <LabeledData
          labelSize="sm"
          label={mainStore.isMobile ? undefined : 'Price'}
          valueClassName="w-full md:w-[74%]"
          value={
            orderTypeIndex === 2 ? (
              <div className="w-full flex items-center justify-center px-2 py-[0.8125rem] font_caption_m text-primary bg-surface_variant_o24 rounded-4px border border-transparent cursor-default md:py-2">
                Market Price
              </div>
            ) : (
              <Tooltip
                placement="top"
                trigger="click"
                escapeSize={true}
                content={
                  <TooltipInner>
                    <div className="font_data_number_xs text-on_surface_variant_light break-all">
                      {Number(price?.replaceAll(',', ''))
                        ? `≈ ${formatUSD(
                            new BigNumber(
                              orderbookPair
                                ? getDollarValueOnCreChainByDenom(orderbookPair?.quoteDenom, price?.replaceAll(',', ''))
                                : 0
                            )
                          )}`
                        : `≈ ${formatUSD(new BigNumber(0))}`}
                    </div>
                  </TooltipInner>
                }
              >
                <NumberInput
                  value={price}
                  unit={orderbookPair?.quoteInfo.ticker}
                  onChangeValue={(value) => {
                    if (!isLoading) {
                      onlyNumberValueUpdaterWhiteWords(value, setPrice);
                    }
                  }}
                  disabled={orderTypeIndex !== 0}
                  maximumFractionDigits={
                    priceStep?.toString().split(getDecimalSeperator() ?? '.')[1]?.length ??
                    orderbookPair?.quoteInfo.exponent
                  }
                  placeholder={`Price (${orderbookPair?.quoteInfo.ticker})`}
                  placeholderType={mainStore.isMobile ? 'text' : 'number'}
                  step={priceStep}
                  stepperEnabled={mainStore.isMobile}
                />
              </Tooltip>
            )
          }
        />

        {/* Quantity */}
        <LabeledData
          labelSize="sm"
          label={mainStore.isMobile ? undefined : 'Quantity'}
          valueClassName="w-full md:w-[74%]"
          value={
            <NumberInput
              value={quantity}
              unit={
                orderbookPair && orderTypeIndex === 2 && orderIndex === 0
                  ? orderbookPair?.quoteInfo?.ticker
                  : orderbookPair?.baseInfo?.ticker
              }
              onChangeValue={(value) => {
                if (!isLoading) {
                  onlyNumberValueUpdaterWhiteWords(value, setQuantity);
                  setSelectedPercent(0);
                }
              }}
              isError={isBalanceOver}
              maximumFractionDigits={
                orderbookPair && orderTypeIndex === 2 && orderIndex === 0
                  ? orderbookPair?.quoteInfo?.exponent
                  : orderbookPair?.baseInfo?.exponent
              }
              placeholder={
                mainStore.isMobile
                  ? `Quantity (${
                      orderbookPair && orderTypeIndex === 2 && orderIndex === 0
                        ? orderbookPair?.quoteInfo?.ticker
                        : orderbookPair?.baseInfo?.ticker
                    })`
                  : undefined
              }
              placeholderType={mainStore.isMobile ? 'text' : 'number'}
              step={1}
              stepperEnabled={mainStore.isMobile}
            />
          }
        />

        {/* Amount percent button */}
        <div className="flex items-center justify-end">
          <div className="w-full md:w-[74%]">
            <PercentButtonGroup
              groupType="percentages"
              onSelect={onSelectPercentInput}
              selectedValue={selectedPercent}
            />
          </div>
        </div>
      </div>

      {/* Total */}
      <div className={`transition-opacity ${orderTypeIndex === 2 ? 'opacity-0' : ''}`}>
        <LabeledData
          labelSize="sm"
          label="Total"
          value={
            <NumberText
              size="xs"
              align="right"
              value={`${
                quantity !== '' && price && orderbookPair && !isNaN(Number(quantity))
                  ? formatAmount(
                      new BigNumber(quantity)
                        .multipliedBy(price?.replaceAll(',', ''))
                        .decimalPlaces(orderbookPair.quoteInfo.exponent, 1),
                      9
                    )
                  : '0'
              } `}
              subValue={
                quantity !== '' && orderbookPair && !isNaN(Number(quantity))
                  ? `≈ ${formatUSD(
                      new BigNumber(
                        getDollarValueOnCreChainByDenom(
                          orderbookPair.quoteDenom,
                          new BigNumber(quantity)
                            .multipliedBy(price?.replaceAll(',', ''))
                            .decimalPlaces(orderbookPair.quoteInfo.exponent)
                            .toString()
                        )
                      )
                    )}`
                  : `≈ ${formatUSD(new BigNumber(0))}`
              }
              unit={orderbookPair?.quoteInfo.ticker ?? ''}
            />
          }
        />
      </div>

      {/* Price Alert */}
      {orderbookPair &&
        // @ts-ignore
        (new BigNumber(orderbookPair.orderablePrice?.max).shiftedBy(orderbookPair.exponentDiff).eq(price) ||
          // @ts-ignore
          new BigNumber(orderbookPair.orderablePrice?.min).shiftedBy(orderbookPair.exponentDiff).eq(price)) && (
          <InfoBox type="danger">
            {
              // @ts-ignore
              new BigNumber(orderbookPair.orderablePrice.max).shiftedBy(orderbookPair.exponentDiff).eq(price)
                ? INFOBOX_TEXTS.MAX_ORDERABLE_PRICE
                : INFOBOX_TEXTS.MIN_ORDERABLE_PRICE
            }
          </InfoBox>
        )}

      {/* Buttons */}
      <div className="w-full flex items-center gap-x-2 md:gap-x-3">
        <IconButton
          iconType="reset"
          size="lg"
          type="tonal"
          disabled={isLoading || isFormInitialState}
          onClick={onReset}
        />
        <Button
          size={mainStore.isMobile ? 'md' : 'lg'}
          color={orderIndex === 0 ? 'green' : 'red'}
          label={`${ORDER_DIRECTIONS[orderIndex]} ${orderbookPair?.baseInfo.ticker ?? ''}`}
          status={isLoading ? 'loading' : isBalanceOver || !isInputValid ? 'disabled' : 'enabled'}
          tooltipContent={isBalanceOver ? 'Insufficient Balance' : undefined}
          onClick={onOrder}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default TradeForm;
