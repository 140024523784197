import { AminoMsg, Coin } from '@cosmjs/amino';
import { MsgLiquidStake, MsgLiquidUnstake } from '../crescent/liquidstaking/types/crescent/liquidstaking/v1beta1/tx';
import { MsgStake, MsgUnstake, MsgHarvest } from '../crescent/farming/types/crescent/farming/v1beta1/tx';
import {
  MsgDeposit,
  MsgWithdraw,
  MsgLimitOrder,
  MsgMarketOrder,
  MsgCancelOrder,
  MsgCancelAllOrders,
} from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import { MsgClaim } from '../crescent/claim/types/crescent/claim/v1beta1/tx';

import {} from '@cosmjs/stargate/build/index';
import BigNumber from 'bignumber.js';
import {
  MsgLiquidFarm,
  MsgLiquidUnfarm,
  MsgLiquidUnfarmAndWithdraw,
} from 'common/crescent/liquidfarming/types/crescent/liquidfarming/v1beta1/tx';
import {
  MsgFarm,
  MsgUnfarm,
  MsgHarvest as MsgLpHarvest,
} from 'common/crescent/lpfarm/types/crescent/lpfarm/v1beta1/tx';

export interface AminoMsgClaim extends AminoMsg {
  readonly type: 'liquidstaking/MsgLiquidStake';
  readonly value: {
    airdrop_id: string;
    recipient: string;
    condition_type: number;
  };
}
export interface AminoMsgLiquidStake extends AminoMsg {
  readonly type: 'liquidstaking/MsgLiquidStake';
  readonly value: {
    /** Bech32 account address */
    delegator_address: string;
    amount: Coin | undefined;
  };
}
export interface AminoMsgLiquidUnstake extends AminoMsg {
  readonly type: 'liquidstaking/MsgLiquidUnstake';
  readonly value: {
    /** Bech32 account address */
    delegator_address: string;
    amount: Coin | undefined;
  };
}

export interface AminoMsgDeposit extends AminoMsg {
  readonly type: 'liquidity/MsgDeposit';
  readonly value: {
    /** Bech32 account address */
    depositor: string;
    /** pool_id specifies the pool id */
    pool_id: string;
    /** deposit_coins specifies the amount of coins to deposit. */
    deposit_coins: Coin[];
  };
}

export interface AminoMsgWithdraw extends AminoMsg {
  readonly type: 'liquidity/MsgWithdraw';
  readonly value: {
    /** Bech32 account address */
    withdrawer: string;
    /** pool_id specifies the pool id */
    pool_id: string;
    /** deposit_coins specifies the amount of coins to deposit. */
    pool_coin: Coin | undefined;
  };
}

export interface AminoMsgLimitOrder extends AminoMsg {
  readonly type: 'liquidity/MsgLimitOrder';
  readonly value: {
    orderer: string;
    pair_id: string;
    direction: number;
    offer_coin: Coin | undefined;
    demand_coin_denom: string;
    price: string;
    amount: string;
    order_lifespan: string | undefined;
  };
}

export interface AminoMsgMarketOrder extends AminoMsg {
  readonly type: 'liquidity/MsgMarketOrder';
  readonly value: {
    orderer: string;
    pair_id: string;
    direction: number;
    offer_coin: Coin | undefined;
    demand_coin_denom: string;
    amount: string;
    order_lifespan: string | undefined;
  };
}

export interface AminoMsgCancelOrder extends AminoMsg {
  readonly type: 'liquidity/MsgCancelOrder';
  readonly value: {
    orderer: string;
    pair_id: string;
    order_id: string;
  };
}

export interface AminoMsgCancelAllOrders extends AminoMsg {
  readonly type: 'liquidity/MsgCancelAllOrders';
  orderer: string;
  pair_ids: string[];
}

export interface AminoMsgStake extends AminoMsg {
  readonly type: 'farming/MsgStake';
  readonly value: {
    farmer: string;
    staking_coins: Coin[];
  };
}

export interface AminoMsgUnstake extends AminoMsg {
  readonly type: 'farming/MsgUnstake';
  readonly value: {
    farmer: string;
    unstaking_coins: Coin[];
  };
}

export interface AminoMsgHarvest extends AminoMsg {
  readonly type: 'farming/MsgHarvest';
  readonly value: {
    farmer: string;
    staking_coin_denoms: string[];
  };
}

export interface AminoMsgLiquidFarm extends AminoMsg {
  readonly type: 'liquidfarming/MsgLiquidFarm';
  readonly value: {
    pool_id: string;
    farmer: string;
    farming_coin: Coin | undefined;
  };
}

export interface AminoMsgLiquidUnfarm extends AminoMsg {
  readonly type: 'liquidfarming/MsgLiquidUnfarm';
  readonly value: {
    pool_id: string;
    farmer: string;
    unfarming_coin: Coin | undefined;
  };
}

export interface AminoMsgLiquidUnfarmAndWithdraw extends AminoMsg {
  readonly type: 'liquidfarming/MsgLiquidUnfarmAndWithdraw';
  readonly value: {
    pool_id: string;
    farmer: string;
    unfarming_coin: Coin | undefined;
  };
}

export interface AminoMsgFarm extends AminoMsg {
  readonly type: 'lpfarm/MsgFarm';
  readonly value: {
    farmer: string;
    coin: Coin | undefined;
  };
}

export interface AminoMsgUnfarm extends AminoMsg {
  readonly type: 'lpfarm/MsgUnfarm';
  readonly value: {
    farmer: string;
    coin: Coin | undefined;
  };
}

export interface AminoMsgLpHarvest extends AminoMsg {
  readonly type: 'lpfarm/MsgHarvest';
  readonly value: {
    farmer: string;
    denom: string;
  };
}

export const crescentTypes = {
  '/crescent.claim.v1beta1.MsgClaim': {
    aminoType: 'claim/MsgClaim',
    toAmino: ({ airdropId, recipient, conditionType }: MsgClaim): AminoMsgClaim['value'] => ({
      airdrop_id: String(airdropId),
      recipient,
      condition_type: Number(conditionType),
    }),
    fromAmino: ({ airdrop_id, recipient, condition_type }: AminoMsgClaim['value']): MsgClaim => ({
      airdropId: Number(airdrop_id),
      recipient,
      conditionType: condition_type,
    }),
  },
  '/crescent.liquidstaking.v1beta1.MsgLiquidStake': {
    aminoType: 'liquidstaking/MsgLiquidStake',
    toAmino: ({ delegatorAddress, amount }: MsgLiquidStake): AminoMsgLiquidStake['value'] => ({
      delegator_address: delegatorAddress,
      amount,
    }),
    fromAmino: ({ delegator_address, amount }: AminoMsgLiquidStake['value']): MsgLiquidStake => ({
      delegatorAddress: delegator_address,
      amount,
    }),
  },
  '/crescent.liquidstaking.v1beta1.MsgLiquidUnstake': {
    aminoType: 'liquidstaking/MsgLiquidUnstake',
    toAmino: ({ delegatorAddress, amount }: MsgLiquidUnstake): AminoMsgLiquidUnstake['value'] => ({
      delegator_address: delegatorAddress,
      amount,
    }),
    fromAmino: ({ delegator_address, amount }: AminoMsgLiquidUnstake['value']): MsgLiquidUnstake => ({
      delegatorAddress: delegator_address,
      amount,
    }),
  },
  '/crescent.liquidity.v1beta1.MsgDeposit': {
    aminoType: 'liquidity/MsgDeposit',
    toAmino: ({ depositor, depositCoins, poolId }: MsgDeposit): AminoMsgDeposit['value'] => {
      return {
        depositor,
        deposit_coins: depositCoins,
        pool_id: String(poolId),
      };
    },
    fromAmino: ({ depositor, deposit_coins, pool_id }: AminoMsgDeposit['value']): MsgDeposit => {
      return {
        depositor,
        depositCoins: deposit_coins,
        poolId: Number(pool_id),
      };
    },
  },
  '/crescent.liquidity.v1beta1.MsgWithdraw': {
    aminoType: 'liquidity/MsgWithdraw',
    toAmino: ({ withdrawer, poolId, poolCoin }: MsgWithdraw): AminoMsgWithdraw['value'] => {
      return {
        withdrawer,
        pool_id: String(poolId),
        pool_coin: poolCoin,
      };
    },
    fromAmino: ({ withdrawer, pool_id, pool_coin }: AminoMsgWithdraw['value']): MsgWithdraw => {
      return {
        withdrawer,
        poolId: Number(pool_id),
        poolCoin: pool_coin,
      };
    },
  },
  '/crescent.liquidity.v1beta1.MsgCancelOrder': {
    aminoType: 'liquidity/MsgCancelOrder',
    toAmino: ({ orderer, orderId, pairId }: MsgCancelOrder): AminoMsgCancelOrder['value'] => {
      return {
        orderer,
        pair_id: String(pairId),
        order_id: String(orderId),
      };
    },
    fromAmino: ({ orderer, pair_id, order_id }: AminoMsgCancelOrder['value']): MsgCancelOrder => {
      return {
        orderer,
        pairId: Number(pair_id),
        orderId: Number(order_id),
      };
    },
  },
  '/crescent.liquidity.v1beta1.MsgCancelAllOrders': {
    aminoType: 'liquidity/MsgCancelAllOrders',
    toAmino: ({ orderer, pairIds }: MsgCancelAllOrders): AminoMsgCancelAllOrders['value'] => {
      return {
        orderer,
        pair_ids: pairIds.map((pair) => String(pair)),
      };
    },
    fromAmino: ({ orderer, pair_ids }: AminoMsgCancelAllOrders['value']): MsgCancelAllOrders => {
      return {
        orderer,
        pairIds: pair_ids.map((pair) => Number(pair)),
      };
    },
  },
  '/crescent.liquidity.v1beta1.MsgLimitOrder': {
    aminoType: 'liquidity/MsgLimitOrder',
    toAmino: ({
      orderer,
      pairId,
      direction,
      offerCoin,
      demandCoinDenom,
      price,
      amount,
      orderLifespan,
    }: MsgLimitOrder): AminoMsgLimitOrder['value'] => {
      const _price = `${new BigNumber(price).shiftedBy(-18).toFixed(18)}`;
      return {
        orderer: orderer,
        pair_id: String(pairId),
        direction: Number(direction),
        offer_coin: offerCoin,
        demand_coin_denom: demandCoinDenom,
        price: _price,
        amount: amount,
        order_lifespan: orderLifespan?.seconds !== 0 ? orderLifespan?.seconds + '000000000' : '0',
      };
    },
    fromAmino: ({
      orderer,
      pair_id,
      direction,
      offer_coin,
      demand_coin_denom,
      price,
      amount,
      order_lifespan,
    }: AminoMsgLimitOrder['value']): MsgLimitOrder => {
      return {
        orderer: orderer,
        pairId: parseInt(pair_id),
        direction: direction,
        offerCoin: offer_coin,
        demandCoinDenom: demand_coin_denom,
        price: new BigNumber(price).multipliedBy(10 ** 18).toString(),
        amount: amount,
        orderLifespan: { seconds: Number(order_lifespan) / 10 ** 9, nanos: 0 },
      };
    },
  },
  '/crescent.liquidity.v1beta1.MsgMarketOrder': {
    aminoType: 'liquidity/MsgMarketOrder',
    toAmino: ({
      orderer,
      pairId,
      direction,
      offerCoin,
      demandCoinDenom,
      amount,
      orderLifespan,
    }: MsgMarketOrder): AminoMsgMarketOrder['value'] => {
      return {
        orderer: orderer,
        pair_id: String(pairId),
        direction: Number(direction),
        offer_coin: offerCoin,
        demand_coin_denom: demandCoinDenom,
        amount: amount,
        order_lifespan: orderLifespan?.seconds !== 0 ? orderLifespan?.seconds + '000000000' : '0',
      };
    },
    fromAmino: ({
      orderer,
      pair_id,
      direction,
      offer_coin,
      demand_coin_denom,
      amount,
      order_lifespan,
    }: AminoMsgMarketOrder['value']): MsgMarketOrder => {
      return {
        orderer: orderer,
        pairId: parseInt(pair_id),
        direction: direction,
        offerCoin: offer_coin,
        demandCoinDenom: demand_coin_denom,
        amount: amount,
        orderLifespan: { seconds: Number(order_lifespan) / 10 ** 9, nanos: 0 },
      };
    },
  },
  '/crescent.farming.v1beta1.MsgStake': {
    aminoType: 'farming/MsgStake',
    toAmino: ({ farmer, stakingCoins }: MsgStake): AminoMsgStake['value'] => {
      return {
        farmer,
        staking_coins: stakingCoins,
      };
    },
    fromAmino: ({ farmer, staking_coins }: AminoMsgStake['value']): MsgStake => {
      return {
        farmer,
        stakingCoins: staking_coins,
      };
    },
  },
  '/crescent.farming.v1beta1.MsgHarvest': {
    aminoType: 'farming/MsgHarvest',
    toAmino: ({ farmer, stakingCoinDenoms }: MsgHarvest): AminoMsgHarvest['value'] => {
      return {
        farmer,
        staking_coin_denoms: stakingCoinDenoms,
      };
    },
    fromAmino: ({ farmer, staking_coin_denoms }: AminoMsgHarvest['value']): MsgHarvest => {
      return {
        farmer,
        stakingCoinDenoms: staking_coin_denoms,
      };
    },
  },
  '/crescent.farming.v1beta1.MsgUnstake': {
    aminoType: 'farming/MsgUnstake',
    toAmino: ({ farmer, unstakingCoins }: MsgUnstake): AminoMsgUnstake['value'] => {
      return {
        farmer,
        unstaking_coins: unstakingCoins,
      };
    },
    fromAmino: ({ farmer, unstaking_coins }: AminoMsgUnstake['value']): MsgUnstake => {
      return {
        farmer,
        unstakingCoins: unstaking_coins,
      };
    },
  },
  '/crescent.liquidfarming.v1beta1.MsgLiquidFarm': {
    aminoType: 'liquidfarming/MsgLiquidFarm',
    toAmino: ({ poolId, farmer, farmingCoin }: MsgLiquidFarm): AminoMsgLiquidFarm['value'] => {
      return {
        pool_id: String(poolId),
        farmer,
        farming_coin: farmingCoin,
      };
    },
    fromAmino: ({ pool_id, farmer, farming_coin }: AminoMsgLiquidFarm['value']): MsgLiquidFarm => {
      return {
        poolId: Number(pool_id),
        farmer,
        farmingCoin: farming_coin,
      };
    },
  },
  '/crescent.liquidfarming.v1beta1.MsgLiquidUnfarm': {
    aminoType: 'liquidfarming/MsgLiquidUnfarm',
    toAmino: ({ poolId, farmer, unfarmingCoin }: MsgLiquidUnfarm): AminoMsgLiquidUnfarm['value'] => {
      return {
        pool_id: String(poolId),
        farmer,
        unfarming_coin: unfarmingCoin,
      };
    },
    fromAmino: ({ pool_id, farmer, unfarming_coin }: AminoMsgLiquidUnfarm['value']): MsgLiquidUnfarm => {
      return {
        poolId: Number(pool_id),
        farmer,
        unfarmingCoin: unfarming_coin,
      };
    },
  },
  '/crescent.liquidfarming.v1beta1.MsgLiquidUnfarmAndWithdraw': {
    aminoType: 'liquidfarming/MsgLiquidUnfarmAndWithdraw',
    toAmino: ({
      poolId,
      farmer,
      unfarmingCoin,
    }: MsgLiquidUnfarmAndWithdraw): AminoMsgLiquidUnfarmAndWithdraw['value'] => {
      return {
        pool_id: String(poolId),
        farmer,
        unfarming_coin: unfarmingCoin,
      };
    },
    fromAmino: ({
      pool_id,
      farmer,
      unfarming_coin,
    }: AminoMsgLiquidUnfarmAndWithdraw['value']): MsgLiquidUnfarmAndWithdraw => {
      return {
        poolId: Number(pool_id),
        farmer,
        unfarmingCoin: unfarming_coin,
      };
    },
  },
  '/crescent.lpfarm.v1beta1.MsgFarm': {
    aminoType: 'lpfarm/MsgFarm',
    toAmino: ({ farmer, coin }: MsgFarm): AminoMsgFarm['value'] => {
      return { farmer, coin };
    },
    fromAmino: ({ farmer, coin }: AminoMsgFarm['value']): MsgFarm => {
      return { farmer, coin };
    },
  },
  '/crescent.lpfarm.v1beta1.MsgUnfarm': {
    aminoType: 'lpfarm/MsgUnfarm',
    toAmino: ({ farmer, coin }: MsgUnfarm): AminoMsgUnfarm['value'] => {
      return { farmer, coin };
    },
    fromAmino: ({ farmer, coin }: AminoMsgUnfarm['value']): MsgUnfarm => {
      return { farmer, coin };
    },
  },
  '/crescent.lpfarm.v1beta1.MsgHarvest': {
    aminoType: 'lpfarm/MsgHarvest',
    toAmino: ({ farmer, denom }: MsgLpHarvest): AminoMsgLpHarvest['value'] => {
      return { farmer, denom };
    },
    fromAmino: ({ farmer, denom }: AminoMsgLpHarvest['value']): MsgLpHarvest => {
      return { farmer, denom };
    },
  },
};

// if (type === 'swap' || type === 'poolDeposit' || type === 'poolWithdraw') {
//   defaultRegistry.register('/squad.liquidity.v1beta1.MsgLimitOrder', MsgLimitOrder);
//   defaultRegistry.register('/squad.liquidity.v1beta1.MsgDeposit', MsgDeposit);
//   defaultRegistry.register('/squad.liquidity.v1beta1.MsgWithdraw', MsgWithdraw);
//   creLiquidityClient = await liquidityTxClient(creOfflineSigner, {
//     addr: `${CLIENT_PROTOCOL}${creChainInfo.wsEndpoint}`,
//   });
// } else if (type === 'farm' || type === 'unfarm' || type === 'claim') {
//   defaultRegistry.register('/squad.farming.v1beta1.MsgStake', MsgStake);
//   defaultRegistry.register('/squad.farming.v1beta1.MsgUnstake', MsgUnstake);
//   defaultRegistry.register('/squad.farming.v1beta1.MsgHarvest', MsgHarvest);
//   creFarmingClient = await farmingTxClient(creOfflineSigner, {
//     addr: `${CLIENT_PROTOCOL}${creChainInfo.wsEndpoint}`,
//   });
// } else {
//   defaultRegistry.register('/squad.liquidstaking.v1beta1.MsgLiquidStake', MsgLiquidStake);
//   defaultRegistry.register('/squad.liquidstaking.v1beta1.MsgLiquidUnstake', MsgLiquidUnstake);
//   creStakingClient = await stakingTxClient(creOfflineSigner, {
//     addr: `${CLIENT_PROTOCOL}${creChainInfo.wsEndpoint}`,
//   });
// }
