import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Swiper = ({
  items,
  slidesPerView,
  className,
}: {
  items: JSX.Element[];
  slidesPerView: number;
  className?: string;
}) => {
  return (
    <ReactSwiper
      spaceBetween={20}
      slidesPerView={slidesPerView}
      //   onSlideChange={() => console.log('slide change')}
      //   onSwiper={(swiper) => console.log(swiper)}
      className={className}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>{item}</SwiperSlide>
      ))}
    </ReactSwiper>
  );
};

export default Swiper;
