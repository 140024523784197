import { observer } from 'mobx-react-lite';
import StakingWidget from 'components/widgets/StakingWidget';

const Staking = () => {
  return (
    <main role="main" className="relative w-full max-w-content m-auto pt-5 md:pt-[3.75rem] px-4 md:px-0">
      <div className="flex flex-col items-center">
        <StakingWidget />
      </div>
    </main>
  );
};

export default observer(Staking);
