import { signWithKeplr } from 'common/signingClients/keplrSigningClient';
import { signWithLeap } from 'common/signingClients/leapSigningClient';
import { signWithCosmostaion } from 'common/signingClients/cosmostationSigningClient';
import { signWithWalletConnect } from 'common/signingClients/walletconnectSigningClient';
import { SignParams } from 'types/signingClients';

import { useMainStore } from 'provider/mainContext';
import { ROOT_ADDRESS } from 'COMMON_VARIABLES';
import axios from 'axios';

import { ConditionType } from '../common/crescent/claim/types/crescent/claim/v1beta1/claim';

export default function useTxClient() {
  const mainStore = useMainStore();

  const getClaimData = async (type) => {
    if (
      mainStore.wallet.address &&
      (type === 'swap' || type === 'staking' || type === 'poolDeposit' || type === 'vote')
    ) {
      let response = await axios.get(`${ROOT_ADDRESS}/acc/${mainStore.wallet.address}/airdrop/claimed`);

      if (response?.data?.data) {
        let conditionType;
        if (type === 'swap') {
          if (response.data.data.claimedConditions.includes('CONDITION_TYPE_SWAP')) {
            return null;
          }
          conditionType = ConditionType.CONDITION_TYPE_SWAP;
        } else if (type === 'staking') {
          if (response.data.data.claimedConditions.includes('CONDITION_TYPE_LIQUIDSTAKE')) {
            return null;
          }
          conditionType = ConditionType.CONDITION_TYPE_LIQUIDSTAKE;
        } else if (type === 'poolDeposit') {
          if (response.data.data.claimedConditions.includes('CONDITION_TYPE_DEPOSIT')) {
            return null;
          }
          conditionType = ConditionType.CONDITION_TYPE_DEPOSIT;
        } else if (type === 'vote') {
          if (response.data.data.claimedConditions.includes('CONDITION_TYPE_VOTE')) {
            return null;
          }
          conditionType = ConditionType.CONDITION_TYPE_VOTE;
        }
        return { airdropId: response.data.data.AirdropId, conditionType };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const signAndBroadcast = async ({ type, chainData, txData, fee }: SignParams) => {
    if (mainStore.wallet.isActive && mainStore.wallet.address) {
      // let airdropClaimData = await getClaimData(type);
      let airdropClaimData = undefined;
      mainStore.isWatching = true;

      if (mainStore.wallet.type === 'keplr') {
        return await signWithKeplr({ type, chainData, txData, airdropClaimData, fee });
      } else if (mainStore.wallet.type === 'leap') {
        return await signWithLeap({ type, chainData, txData, airdropClaimData, fee });
      } else if (mainStore.wallet.type === 'cosmostation') {
        return await signWithCosmostaion({ type, chainData, txData, airdropClaimData, fee });
      } else if (mainStore.wallet.type === 'walletconnect:cosmostation') {
        return await signWithWalletConnect({
          wallet: mainStore.wallet,
          type,
          chainData,
          txData,
          airdropClaimData,
          fee,
        });
      }
    }
  };

  return { signAndBroadcast };
}
