import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

type ModalSize = 'sm' | 'md';
type ModalAlign = 'center' | 'top';

const MODAL_MD_WIDTH_DICT: { [key in ModalSize]: string } = {
  sm: 'md:w-[380px]',
  md: 'md:w-[420px]',
};

type TransitionModalProps = {
  children: React.ReactNode;
  size?: ModalSize;
  className?: string;
  isOpen: boolean;
  align?: ModalAlign;
  close?: () => void;
};

export default function TransitionModal({
  children,
  size = 'md',
  className = '',
  isOpen,
  align = 'center',
  close = () => {},
}: TransitionModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" open={true} className="fixed inset-0 z-[1] overflow-y-auto" onClose={() => {}}>
        <div className={`min-h-screen flex justify-center ${align === 'center' ? 'items-center' : ''} px-4 md:px-0`}>
          {/* overlay  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-background_o80" onClick={close}></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          {/* body */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`relative z-10 inline-block w-full h-fit max-h-screen ${MODAL_MD_WIDTH_DICT[size]} bg_surface_1 border border-outline_soft rounded-4px overflow-hidden align-top transition-all transform ${className}`}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
