import { useMainStore } from "provider/mainContext";
import { useMemo } from "react";
import AxelarBadgeWhite from 'resources/images/Powered-by-Axelar-badge-WHITE-s.svg';
import AxelarBadgeBlack from 'resources/images/Powered-by-Axelar-badge-BLACK-s.svg';

export default function PoweredByAxelar() {
    const mainStore = useMainStore();
    const isDarkMode = useMemo<boolean>(() => mainStore.theme === 'Dark', [mainStore])
  
    return <img src={isDarkMode ? AxelarBadgeWhite : AxelarBadgeBlack} alt="" className="w-36" />
}