import { ReactNode, useState } from 'react';
import { useMainStore } from 'provider/mainContext';
import { useIngTxs } from 'hooks/useAPI';
import { ORDER_SEARCH_TERMS } from 'COMMON_VARIABLES';
import TabSelector from 'components/tabs/TabSelector';
import Widget from 'components/widgets/components/Widget';
import OpenOrdersTable from './OpenOrdersTable';
import PastOrdersTable from './PastOrdersTable';
import TradeHistoryTable from './TradeHistoryTable';
import FundsTable from './FundsTable';

/** @component OrderBoardSearchTermSelector */
const OrderBoardSearchTermSelector = ({
  index,
  onChangeIndex,
}: {
  index: number;
  onChangeIndex: (index: number) => void;
}) => {
  return (
    <TabSelector
      type="text"
      size="sm"
      tabsMaxWidth="max-content"
      fallbackIndex={index}
      onChangeTab={onChangeIndex}
      panels={ORDER_SEARCH_TERMS.map((item) => ({ key: item }))}
    />
  );
};

/** @component OrderBoardWrapper */
const OrderBoardWrapper = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
  return <div className={`-mx-2 rounded-b-8px overflow-hidden md:mx-0 ${className}`}>{children}</div>;
};

/** @component OrderBoardWidget */
const OrderBoardWidget = () => {
  const mainStore = useMainStore();
  const [searchTermIndex, setSearchTermIndex] = useState(0);
  const { data: openOrdersData, isLoading: openOrdersDataLoading } = useIngTxs(mainStore.wallet.address || '', 3000);

  // const [hash, setHash] = useState<string>('');
  // useEffect(() => {
  //   // Helpers
  //   const digest = async ({ algorithm = 'SHA-256', message }) =>
  //     Array.prototype.map
  //       .call(new Uint8Array(await crypto.subtle.digest(algorithm, new TextEncoder().encode(message))), (x) =>
  //         ('0' + x.toString(16)).slice(-2)
  //       )
  //       .join('');

  //   if (openOrdersData?.length) {
  //     digest({ message: String(openOrdersData) }).then((res) => {
  //       if (!hash) {
  //         setHash(res);
  //       } else if (hash !== res) {
  //         setHash(res);
  //         setUpdateTerm(3000);
  //       } else {
  //         setUpdateTerm(60000);
  //       }
  //     });
  //   }
  // }, [openOrdersData]);

  return (
    <Widget
      padding={mainStore.isMobile ? '0' : '12px'}
      size="full"
      className={mainStore.isMobile ? 'bg_surface_-3' : ''}
    >
      <div className="w-full px-2 pt-4 md:px-0 md:py-0">
        <TabSelector
          type="filled"
          size="sm"
          tabsMaxWidth="max-content"
          panels={[
            {
              key: `Open Orders (${openOrdersData?.length ?? 0})`,
              content: (
                <OrderBoardWrapper>
                  <OpenOrdersTable openOrders={openOrdersData ?? []} isLoading={openOrdersDataLoading} />
                </OrderBoardWrapper>
              ),
            },
            {
              key: 'Past Orders',
              content: (
                <div className="space-y-3">
                  <OrderBoardSearchTermSelector index={searchTermIndex} onChangeIndex={setSearchTermIndex} />

                  <OrderBoardWrapper className="border-outline_soft border-t md:border-t-0">
                    <PastOrdersTable searchTermIndex={searchTermIndex} />
                  </OrderBoardWrapper>
                </div>
              ),
            },
            {
              key: 'Trade History',
              content: (
                <div className="space-y-3">
                  <OrderBoardSearchTermSelector index={searchTermIndex} onChangeIndex={setSearchTermIndex} />

                  <OrderBoardWrapper className="border-outline_soft border-t md:border-t-0">
                    <TradeHistoryTable searchTermIndex={searchTermIndex} />
                  </OrderBoardWrapper>
                </div>
              ),
            },
            {
              key: 'Funds',
              content: (
                <OrderBoardWrapper className="border-outline_soft border-t md:border-t-0">
                  <FundsTable openOrders={openOrdersData ?? []} isLoading={openOrdersDataLoading} />
                </OrderBoardWrapper>
              ),
            },
          ]}
        />
      </div>
    </Widget>
  );
};

export default OrderBoardWidget;
