import InfoBox from 'components/infobox';
import { ToastContentItem } from 'components/toast/components';
import type { InfoAsset } from 'types/asset';
import BigNumber from 'bignumber.js';
import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import TokenAmount from 'components/txResult/TokenAmount';
import copy from 'copy-to-clipboard';
import { getValidDigitNumber } from 'common/utils';
import { store } from 'provider/mainContext';
import {
  TOAST_INFOBOX_ERROR_INSUFFICIENT_FEE,
  TOAST_INFOBOX_ERROR_INSUFFICIENT_FUNDS,
  TOAST_INFOBOX_ERROR_ORDER_PRICE_OUT_OF_RANGE_HIGHER,
  TOAST_INFOBOX_ERROR_ORDER_PRICE_OUT_OF_RANGE_LOWER,
  TOAST_INFOBOX_ERROR_OUT_OF_GAS,
  TOAST_TITLE_ERROR_INSUFFICIENT_FEE,
  TOAST_TITLE_ERROR_INSUFFICIENT_FUNDS,
  TOAST_TITLE_ERROR_ORDER_PRICE_OUT_OF_RANGE,
  TOAST_TITLE_ERROR_OUT_OF_GAS,
  TOAST_TITLE_ERROR_UNKNOWN,
} from 'COMMON_VARIABLES';
import NumberText from 'components/texts/NumberText';

type TxErrorResult = {
  title: string;
  content: JSX.Element | string;
  link?: {
    label: string;
    href: string;
  };
};

/** @desc parse error log; failed to execute message; message index: 0: 899996ubld is smaller than 905996ubld: insufficient funds */
const getInsufficientFundsTxError = (error: string): TxErrorResult => {
  const splits = error
    .trim()
    .replace('failed to execute message; message index: 0: ', '')
    .replace(': insufficient funds', '')
    .split(' is smaller than ');

  const request = splits[1]?.replaceAll('"', '') ?? '';
  const requestAmountRaw = Number.isNaN(parseInt(request)) ? 0 : parseInt(request);
  const requestDenom = request.split(`${requestAmountRaw}`)[1] ?? '';
  const requestAsset: InfoAsset | undefined = Object.values(store.assetsData.info).find(
    (asset) => asset.baseDenom === requestDenom
  );
  const requestAmount = new BigNumber(requestAmountRaw).shiftedBy(-(requestAsset?.exponent ?? 0));

  const available = splits[0].replaceAll('"', '');
  const availableAmountRaw = Number.isNaN(parseInt(available)) ? 0 : parseInt(available);
  const availableDenom = available.split(`${availableAmountRaw}`)[1] ?? '';
  const availableAsset: InfoAsset | undefined = Object.values(store.assetsData.info).find(
    (asset) => asset.baseDenom === availableDenom
  );
  const availableAmount = new BigNumber(availableAmountRaw).shiftedBy(-(availableAsset?.exponent ?? 0));

  return {
    title: TOAST_TITLE_ERROR_INSUFFICIENT_FUNDS,
    content: (
      <div className="space-y-4">
        <div className="space-y-3">
          {requestAsset && (
            <ToastContentItem label="Requested" value={<TokenAmount asset={requestAsset} amount={requestAmount} />} />
          )}

          {availableAsset && (
            <ToastContentItem
              label="Available"
              value={<TokenAmount asset={availableAsset} amount={availableAmount} />}
            />
          )}
        </div>

        <InfoBox>{TOAST_INFOBOX_ERROR_INSUFFICIENT_FUNDS}</InfoBox>
      </div>
    ),
  };
};

/** @description  'provided fee < minimum global fee (25000000000000000acanto < 200000000000000000acanto). Please increase the gas price.: insufficient fee' */
const parseInsufficientFeeTxErrorVariationCanto = (error: string): string[] => {
  const splits = error
    .trim()
    .replace('provided fee < minimum global fee (', '')
    .replace('). Please increase the gas price.: insufficient fee', '')
    .split(' < ');

  return splits;
};

/** @description "gas prices too low, got: 25000000000aevmos required: 54217934566aevmos. Please retry using a higher gas price or a higher fee: insufficient fee" */
const parseInsufficientFeeTxErrorVariationEvmos = (error: string): string[] => {
  const splits = error
    .trim()
    .replace('gas prices too low, got: ', '')
    .replace('. Please retry using a higher gas price or a higher fee: insufficient fee', '')
    .split(' required: ');
  return splits;
};

/** @description parse error log; insufficient fees; got: 0ucmdx required: 5000ucmdx: insufficient fee */
const getParsedInsufficientFeeError = (error: string): string[] => {
  if (error.includes('gas prices too low, got: ')) return parseInsufficientFeeTxErrorVariationEvmos(error);
  if (error.includes('provided fee < minimum global fee (')) return parseInsufficientFeeTxErrorVariationCanto(error);
  const splits = error
    .trim()
    .replace('insufficient fees; ', '')
    .replace(': insufficient fee', '')
    .replace('got: ', '')
    .split(' required: ');
  return splits;
};

const getInsufficientFeeTxError = (error: string): TxErrorResult => {
  const splits = getParsedInsufficientFeeError(error);

  const paidRaw = splits[0] ?? '';
  const paid = paidRaw.replaceAll('"', '');

  const paidAmountRaw = Number.isNaN(parseInt(paid)) ? 0 : parseInt(paid);
  const paidDenom = paid.split(`${paidAmountRaw}`)[1] ?? '';
  const paidAsset: InfoAsset | undefined = Object.values(store.assetsData.info).find(
    (asset) => asset.baseDenom === paidDenom
  );
  const paidAmount = new BigNumber(paidAmountRaw).shiftedBy(-(paidAsset?.exponent ?? 0));

  const requires = (splits[1] ?? '').split(',').map((item) => item.replaceAll('"', ''));

  const required = paidDenom.length > 0 ? requires.find((req) => req.includes(paidDenom)) ?? '' : '';
  const requiredAmountRaw = Number.isNaN(parseInt(required)) ? 0 : parseInt(required);
  const requiredDenom = required.split(`${requiredAmountRaw}`)[1] ?? '';
  const requiredAsset: InfoAsset | undefined = Object.values(store.assetsData.info).find(
    (asset) => asset.baseDenom === requiredDenom
  );
  const requiredAmount = new BigNumber(requiredAmountRaw).shiftedBy(-(requiredAsset?.exponent ?? 0));

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        {requiredAsset && (
          <ToastContentItem label="Required" value={<TokenAmount asset={requiredAsset} amount={requiredAmount} />} />
        )}

        {paidAsset && <ToastContentItem label="Paid" value={<TokenAmount asset={paidAsset} amount={paidAmount} />} />}
      </div>

      <InfoBox>{TOAST_INFOBOX_ERROR_INSUFFICIENT_FEE}</InfoBox>
    </div>
  );

  return {
    title: TOAST_TITLE_ERROR_INSUFFICIENT_FEE,
    content,
    link: {
      label: 'What is fee?',
      href: 'https://docs.crescent.network/introduction/gas-and-fees',
    },
  };
};

const getOutOfGasTxError = (error: string): TxErrorResult => {
  const splits = error.split('; ')[1]?.split(': out of gas')[0]?.split(', ');

  const required = splits[1]?.split('gasUsed: ')[1] ?? '';
  const paid = splits[0]?.split('gasWanted: ')[1] ?? '';

  const requiredAmount = Number.isNaN(parseInt(required)) ? 0 : parseInt(required);
  const paidAmount = Number.isNaN(parseInt(paid)) ? 0 : parseInt(paid);

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        <ToastContentItem label="Required" value={<NumberText value={new BigNumber(requiredAmount).toFormat()} />} />
        <ToastContentItem label="Paid" value={<NumberText value={new BigNumber(paidAmount).toFormat()} />} />
      </div>

      <InfoBox>{TOAST_INFOBOX_ERROR_OUT_OF_GAS}</InfoBox>
    </div>
  );

  return {
    title: TOAST_TITLE_ERROR_OUT_OF_GAS,
    content,
    link: {
      label: 'What is gas?',
      href: 'https://docs.crescent.network/introduction/gas-and-fees',
    },
  };
};

const getOrderPriceOutOfRangeTxError = (error: string, txData?: any): TxErrorResult => {
  const isHigher = error.includes('is higher than');
  const splits = error
    .split('index: 0: ')[1]
    ?.split(': price out of range limit')[0]
    ?.split(isHigher ? ' is higher than ' : ' is lower than ');

  const receiveExponent: number = txData?.receive?.asset?.exponent ?? 0;
  const payExponent: number = txData?.pay?.asset?.exponent ?? 0;
  const exponentDiff = receiveExponent - payExponent;

  const orderPriceRaw = splits[0] && !Number.isNaN(splits[0]) ? Number(splits[0]) : 0;
  const minMaxPriceRaw = splits[1] && !Number.isNaN(splits[1]) ? Number(splits[1]) : 0;

  const orderPrice = new BigNumber(getValidDigitNumber(orderPriceRaw * 10 ** exponentDiff, 5)).toFormat();
  const minMaxPrice = new BigNumber(getValidDigitNumber(minMaxPriceRaw * 10 ** exponentDiff, 5)).toFormat();

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        <ToastContentItem label="Order Price" value={<NumberText size="md" value={orderPrice} />} />
        <ToastContentItem
          label={isHigher ? 'Max Price' : 'Min Price'}
          value={<NumberText size="md" value={minMaxPrice} />}
        />
      </div>

      <InfoBox>
        {isHigher
          ? TOAST_INFOBOX_ERROR_ORDER_PRICE_OUT_OF_RANGE_HIGHER
          : TOAST_INFOBOX_ERROR_ORDER_PRICE_OUT_OF_RANGE_LOWER}
      </InfoBox>
    </div>
  );

  return {
    title: TOAST_TITLE_ERROR_ORDER_PRICE_OUT_OF_RANGE,
    content,
  };
};

const getUnknownTxError = (error: string): TxErrorResult => {
  return {
    title: TOAST_TITLE_ERROR_UNKNOWN,
    content:
      error.length > 0 ? (
        <div className="flex flex-col items-end gap-y-4">
          <div className="w-full flex justify-between items-start gap-x-2 py-2 pl-3 pr-2 bg_surface_1 rounded-4px">
            <div className="text-on_surface_variant_light font_body_xs break-all">{error}</div>
            <IconButton iconType="copy" size="sm" onClick={() => copy(error)} />
          </div>

          <Button
            label="Ask Community"
            href="https://discord.com/channels/951394719664070666/954206836330987570"
            type="outlined"
            size="sm"
          />
        </div>
      ) : (
        ''
      ),
  };
};

export const getTxErrorResult = (error: string, txData?: any): TxErrorResult => {
  if (error.includes('insufficient funds')) {
    return getInsufficientFundsTxError(error);
  } else if (error.includes('insufficient fee')) {
    return getInsufficientFeeTxError(error);
  } else if (error.includes('out of gas in location')) {
    return getOutOfGasTxError(error);
  } else if (error.includes('price out of range limit')) {
    return getOrderPriceOutOfRangeTxError(error, txData);
  } else {
    return getUnknownTxError(error);
  }
};
