import SearchInput from 'components/inputs/SearchInput';
import { useCallback, useState } from 'react';
import type { AssetDetail } from 'types/asset';
import TokenOption from './TokenOption';
import Divider from 'components/divider/Divider';
import { AssetSortBy, sortAssets } from 'common/utils';

type TokenSelectProps = {
  availableTitle?: JSX.Element;
  unavailableTitle?: JSX.Element;
  availableAssets?: AssetDetail[];
  unavailableAssets?: AssetDetail[];
  selectedDenom?: string;
  showTokenDetail?: boolean;
  excludedDenoms?: string[];
  sortBy?: AssetSortBy;
  onSelect?: (asset: AssetDetail) => void;
};

const TokenSelect = ({
  availableTitle,
  unavailableTitle,
  availableAssets = [],
  unavailableAssets = [],
  selectedDenom,
  showTokenDetail,
  excludedDenoms = [],
  sortBy = 'all',
  onSelect,
}: TokenSelectProps) => {
  /** @desc search input */
  const [keyword, setKeyword] = useState('');

  /** @desc filtering */
  const filterAssets = useCallback(
    (assets: AssetDetail[]) => {
      return assets.filter(
        (asset) => !excludedDenoms.includes(asset.denom) && asset.ticker.toUpperCase().includes(keyword.toUpperCase())
      );
    },
    [excludedDenoms, keyword]
  );

  const filteredAvailableAssets = filterAssets(availableAssets);
  const filteredUnavailableAssets = filterAssets(unavailableAssets);

  const sortFunc = useCallback((assets: AssetDetail[]) => sortAssets(assets, { sortBy }), [sortBy]);

  return (
    <div className="h-full">
      {/* search */}
      <div className="px-4 pb-4 md:px-5">
        <SearchInput value={keyword} onChangeValue={setKeyword} />
      </div>

      {/* token list */}
      <section className="overflow-scroll h-[calc(100%_-_3.375rem)]">
        <section>
          {availableTitle && <div className="px-4 py-3 md:px-5">{availableTitle}</div>}
          <ul>
            {sortFunc(filteredAvailableAssets).map((asset, index) => (
              <li key={index}>
                <TokenOption
                  showTokenDetail={showTokenDetail}
                  asset={asset}
                  dimmed={selectedDenom === asset.denom}
                  onClick={() => onSelect?.(asset)}
                />
              </li>
            ))}
          </ul>
        </section>

        {filteredUnavailableAssets.length > 0 && (
          <>
            <Divider />

            <section>
              {unavailableTitle && <div className="px-4 py-3 md:px-5">{unavailableTitle}</div>}
              <ul>
                {sortFunc(filteredUnavailableAssets).map((asset, index) => (
                  <li key={index}>
                    <TokenOption asset={asset} dimmed={true} onClick={() => onSelect?.(asset)} />
                  </li>
                ))}
              </ul>
            </section>
          </>
        )}
      </section>
    </div>
  );
};

export default TokenSelect;
