type PoolIdSize = 'sm' | 'md' | 'lg';

const POOL_ID_TYPO_DICT: { [key in PoolIdSize]: string } = {
  sm: 'font_caption_number_xs',
  md: 'font_caption_number_s',
  lg: 'font_caption_number_l',
};

const PoolId = ({
  poolId,
  hashTag = false,
  size = 'md',
  opacity = true,
  className = '',
}: {
  poolId: number;
  hashTag?: boolean;
  size?: PoolIdSize;
  opacity?: boolean;
  className?: string;
}) => {
  return (
    <div className={`${className} text-on_surface_variant_dark ${POOL_ID_TYPO_DICT[size]}`}>
      {hashTag && '#'}
      {poolId}
    </div>
  );
};

export default PoolId;
