import TooltipIcon from 'components/tooltips/TooltipIcon';
import { TableStyles, TABLE_FIELD_TYPO_DICT } from './styles';
import type { TableField, TableRowData } from './types';

type TableFieldCellProps<T> = {
  type: TableStyles;
  field: TableField<T>;
  className?: string;
};

const TableFieldCell = <T extends TableRowData>({ type, field, className = '' }: TableFieldCellProps<T>) => {
  return (
    <div className={`${className} flex items-center gap-x-1 ${TABLE_FIELD_TYPO_DICT[type]}`}>
      <div>{field.label}</div>
      {field.tooltipContent && (
        <TooltipIcon
          tooltipSize={field.tooltipSize}
          tooltipWordBreak={field.tooltipWordBreak}
          tooltipContent={field.tooltipContent}
        />
      )}
    </div>
  );
};

export default TableFieldCell;
