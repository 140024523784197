import BigNumber from 'bignumber.js';
import { PortfolioKeys } from 'COMMON_VARIABLES';
import Panel from 'components/panel/Panel';
import PanelAmount from 'components/panel/PanelAmount';
import PortfolioPieChart from 'components/portfolio/PortfolioPieChart';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { AssetDetail } from 'types/asset';
import type { Unbonding } from 'types/balance';
import type { PoolDetail } from 'types/pool';
import useMyPortfolio from './hooks/useMyPortfolio';
import TotalPortfolioDetails from './tooltip/TotalPortfolioDetails';

type PortfolioTotalPanelProps = {
  pools: PoolDetail[];
  assets: AssetDetail[];
  unbondings: Unbonding[];
};

const PortfolioTotalPanel = ({ pools, assets, unbondings }: PortfolioTotalPanelProps) => {
  const { myPools, totalInvestmentUSD, myAssets, totalAssetsUSD, totalUnbondingUSD } = useMyPortfolio({
    pools,
    assets,
    unbondings,
  });

  const totalUSD = useMemo<BigNumber>(
    () => totalInvestmentUSD.plus(totalAssetsUSD).plus(totalUnbondingUSD),
    [totalInvestmentUSD, totalAssetsUSD, totalUnbondingUSD]
  );

  const totalUSDByType = useMemo<{ [key in PortfolioKeys]: { amount: BigNumber; dp: number } }>(() => {
    return {
      [PortfolioKeys.TOKENS]: { amount: totalAssetsUSD, dp: 2 },
      [PortfolioKeys.INVESTMENT]: { amount: totalInvestmentUSD, dp: 2 },
      [PortfolioKeys.UNBONDING]: { amount: totalUnbondingUSD, dp: 2 },
    };
  }, [totalAssetsUSD, totalInvestmentUSD, totalUnbondingUSD]);

  const store = useMainStore();

  return (
    <Panel className="box_glow_l" padding={store.isMobile ? '16px' : '24px'}>
      <div className="w-full flex flex-col md:flex-row items-stretch md:items-center justify-between gap-y-5 gap-x-4 md:pl-3 md:pr-1">
        <PanelAmount
          label="Total"
          amount={totalUSD}
          symbol="$"
          size={store.isMobile ? 'lg' : 'xl'}
          tooltipContent={<TotalPortfolioDetails data={totalUSDByType} />}
        />

        <PortfolioPieChart className="grow shrink" myPools={myPools} myAssets={myAssets} />
      </div>
    </Panel>
  );
};

export default PortfolioTotalPanel;
