import { PairAssetFilterKeys, PAIR_FILTER_ASSETS_DICT } from 'COMMON_VARIABLES';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { OrderbookPair } from 'types/orderbook';
import type { PairDetail } from 'types/pair';

type PairSelectorName = '★' | 'All' | PairAssetFilterKeys;

export const PAIR_SELECTORS: PairSelectorName[] = ['★', 'All', ...Object.values(PairAssetFilterKeys)];

const PAIR_INDEX_LOCAL_STORAGE_KEY = 'pairTabIndex';

/** @desc hook */
const usePairSelector = (pairs: PairDetail[] | OrderbookPair[]) => {
  /** favorites (★) */
  const [favorites, setFavorites] = useState<Set<number>>(
    new Set(JSON.parse(localStorage.getItem('favorites') ?? '[]').map((item) => Number(item)))
  );

  const onFavorite = useCallback(
    (pairId: number) => {
      const newSet = new Set(favorites);
      newSet.has(pairId) ? newSet.delete(pairId) : newSet.add(pairId);

      setFavorites(newSet);

      localStorage.setItem('favorites', JSON.stringify([...newSet]));
    },
    [favorites]
  );

  /** pair index */
  const [pairIndex, setPairIndex] = useState<number>(Number(localStorage.getItem(PAIR_INDEX_LOCAL_STORAGE_KEY) ?? '1'));

  useEffect(() => {
    if (PAIR_SELECTORS[pairIndex] === undefined) setPairIndex(1);
  }, [pairIndex]);

  const onChangePairIndex = useCallback((index: number) => {
    setPairIndex(index);
    localStorage.setItem(PAIR_INDEX_LOCAL_STORAGE_KEY, String(index));
  }, []);

  /** final pairs to display */
  const selectedPairs = useMemo<PairDetail[] | OrderbookPair[]>(() => {
    const selected: PairSelectorName | undefined = PAIR_SELECTORS[pairIndex];

    switch (selected) {
      case '★':
        return pairs.filter((pair) => favorites.has(pair.pairId));
      case 'All':
        return pairs;
      case PairAssetFilterKeys.STABLE:
        return getAssetFilteredPairs(pairs, PairAssetFilterKeys.STABLE);
      case PairAssetFilterKeys.CRES:
        return getAssetFilteredPairs(pairs, PairAssetFilterKeys.CRES);
      case PairAssetFilterKeys.ATOMS:
        return getAssetFilteredPairs(pairs, PairAssetFilterKeys.ATOMS);
      default:
        return pairs;
    }
  }, [pairs, favorites, pairIndex]);

  /** @todo type guard doesnt work properly with array filter atm so type assertion used tmp */
  const selectedOrderbookPairs = useMemo<OrderbookPair[]>(() => {
    return selectedPairs.some(isOrderbookPair) ? (selectedPairs as OrderbookPair[]) : [];
  }, [selectedPairs]);

  return {
    pairIndex,
    onChangePairIndex,
    selectedPairs,
    selectedOrderbookPairs,
    favorites,
    onFavorite,
  };
};

export default usePairSelector;

/** @desc type guard */
function hasOrderbookPairProperties(pair: any): boolean {
  return pair?.title !== undefined;
}

function isOrderbookPair(pair: PairDetail | OrderbookPair): pair is OrderbookPair {
  return hasOrderbookPairProperties(pair);
}

function getAssetFilteredPairs(pairs: PairDetail[] | OrderbookPair[], filterKey: PairAssetFilterKeys) {
  return pairs.filter((pair) =>
    pair.assets.some((asset) => PAIR_FILTER_ASSETS_DICT[filterKey].tickers.includes(asset.ticker))
  ) as PairDetail[] | OrderbookPair[];
}
