import { IS_LF_ENABLED, MODAL_TITLES, PAIR_FILTER_ASSETS_DICT, PairAssetFilterKeys } from 'COMMON_VARIABLES';
import Button from 'components/buttons/Button';
import ToggleButton from 'components/buttons/ToggleButton';
import Checkbox from 'components/checkbox/Checkbox';
import ModalFooter from 'components/modals/components/ModalFooter';
import ModalHeader from 'components/modals/components/ModalHeader';
import ModalSectionHeader from 'components/modals/components/ModalSectionHeader';
import TransitionModal from 'components/modals/TransitionModal';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MyPoolDetail, PoolDetail, PoolFilterData } from 'types/pool';
import usePoolFilter from '../hooks/usePoolFilter';

const Toggler = ({ label, on, onToggle }: { label: string; on: boolean; onToggle: () => void }) => {
  return (
    <div className="flex justify-between items-center gap-x-4">
      <span className="font_caption_m text-on_surface">{label}</span>
      <ToggleButton toggle={onToggle} size="md" isEnabled={on} />
    </div>
  );
};

const CHECKBOX_STYLE = 'outline';

type PoolsTableFilterModalProps = {
  isOpen?: boolean;
  pools: PoolDetail[] | MyPoolDetail[];
  defaultData?: PoolFilterData;
  injectedData?: PoolFilterData;
  onClose: () => void;
  onOk: (okData: PoolFilterData) => void;
};

const PoolsTableFilterModal = ({
  isOpen = false,
  pools,
  defaultData,
  injectedData,
  onClose,
  onOk,
}: PoolsTableFilterModalProps) => {
  const {
    ranged,
    setRanged,
    basic,
    setBasic,
    liquidFarm,
    setLiquidFarm,
    incentivised,
    setIncentivised,
    etc,
    setEtc,
    pairAssets,
    onPairAssetCheckChange,
    selectAllAssets,
    onAssetSelectAllCheckChange,
    filteredPools,
    initialData,
    prevData,
    currentData,
    keepCurrentData,
    rollbackAllData,
    getFilteredCnt,
  } = usePoolFilter({ pools, injectedData });

  /** assets hiding */
  const [showAllAssetChecks, setShowAllAssetChecks] = useState<boolean>(false);

  const fixedAssetChecks = useMemo<{ group: PairAssetFilterKeys; checked: boolean }[]>(
    () => pairAssets.slice(0, 7),
    [pairAssets]
  );
  const toggleAssetChecks = useMemo<{ group: PairAssetFilterKeys; checked: boolean }[]>(
    () => pairAssets.slice(7),
    [pairAssets]
  );

  const store = useMainStore();
  const getAssetCheckLogoAssets = useCallback(
    (group: PairAssetFilterKeys) => {
      if (!PAIR_FILTER_ASSETS_DICT[group].showLogos) return [];
      return Object.values(store.assetsData.info).filter((asset) =>
        PAIR_FILTER_ASSETS_DICT[group].tickers.some((ticker) => ticker.toUpperCase() === asset.ticker.toUpperCase())
      );
    },
    [store.assetsData]
  );

  const buttonLabel = useMemo<string>(() => {
    return filteredPools.length === 0 ? 'No matched pools' : `Show ${filteredPools.length} pools`;
  }, [filteredPools.length]);

  /** keep prev data */
  useEffect(() => {
    if (isOpen) keepCurrentData();
  }, [isOpen]);

  /** rollback data */
  const isFormInitialState = useMemo<boolean>(() => {
    const changedCnt = getFilteredCnt(defaultData ?? initialData);
    return changedCnt === 0;
  }, [getFilteredCnt, defaultData, initialData]);

  const onResetClick = useCallback(() => {
    rollbackAllData(defaultData ?? initialData);
  }, [rollbackAllData, defaultData, initialData]);

  const onCloseEvent = useCallback(() => {
    onClose();
    rollbackAllData(prevData ?? initialData);
  }, [onClose, rollbackAllData, prevData, initialData]);

  /** ok */
  const onOkClick = useCallback(() => {
    onOk(currentData);
  }, [onOk, currentData]);

  return (
    <TransitionModal size="sm" isOpen={isOpen} close={onCloseEvent}>
      <ModalHeader title={MODAL_TITLES.FILTER} onCloseClick={onCloseEvent} />

      <div className="relative pb-[5.25rem]">
        <div className="space-y-[2.25rem] h-[356px] pb-1 overflow-auto md:space-y-12">
          {/* features */}
          <section className="space-y-4 px-4 py-1 md:px-5">
            <ModalSectionHeader title="Features" />

            <div className="space-y-6">
              <div className="flex items-center gap-x-3">
                <Checkbox
                  type={CHECKBOX_STYLE}
                  label="Ranged"
                  isChecked={ranged}
                  onChange={setRanged}
                  className="grow shrink"
                />
                <Checkbox
                  type={CHECKBOX_STYLE}
                  label="Basic"
                  isChecked={basic}
                  onChange={setBasic}
                  className="grow shrink"
                />
              </div>

              <div className="space-y-4">
                {IS_LF_ENABLED && (
                  <Toggler label="Liquid Farm" on={liquidFarm} onToggle={() => setLiquidFarm(!liquidFarm)} />
                )}
                <Toggler label="Incentivised only" on={incentivised} onToggle={() => setIncentivised(!incentivised)} />
              </div>
            </div>
          </section>

          {/* pair tokens */}
          <section className="space-y-4 px-4 py-1 md:px-5">
            <div className="flex justify-between items-center gap-x-4">
              <ModalSectionHeader title="Pair Tokens" />
              <Checkbox
                type="secondary"
                label="Select All"
                isChecked={selectAllAssets}
                onChange={onAssetSelectAllCheckChange}
              />
            </div>

            <div className="relative flex flex-wrap items-center gap-x-2 gap-y-4">
              {fixedAssetChecks.map((asset) => (
                <Checkbox
                  key={asset.group}
                  type={CHECKBOX_STYLE}
                  label={asset.group}
                  coins={getAssetCheckLogoAssets(asset.group)}
                  isChecked={asset.checked}
                  onChange={(checked) => onPairAssetCheckChange(asset.group, checked)}
                />
              ))}

              <Checkbox type={CHECKBOX_STYLE} label="Others" isChecked={etc} onChange={setEtc} />

              {toggleAssetChecks.length > 0 && (
                <Button
                  type="surficial"
                  label={showAllAssetChecks ? '- Less' : '+ More'}
                  size="sm"
                  // for screen reader
                  className="order-1"
                  onClick={() => setShowAllAssetChecks(!showAllAssetChecks)}
                />
              )}

              {toggleAssetChecks.map((asset) => (
                <div
                  key={asset.group}
                  className={
                    showAllAssetChecks ? 'transition-opacity opacity-100' : 'absolute -bottom-10 -z-10 opacity-0'
                  }
                >
                  <Checkbox
                    type={CHECKBOX_STYLE}
                    label={asset.group}
                    coins={getAssetCheckLogoAssets(asset.group)}
                    isChecked={asset.checked}
                    onChange={(checked) => onPairAssetCheckChange(asset.group, checked)}
                  />
                </div>
              ))}
            </div>
          </section>
        </div>

        <ModalFooter
          buttonMetalic={true}
          buttonLabel={buttonLabel}
          buttonStatus={filteredPools.length === 0 ? 'disabled' : 'enabled'}
          isResetDisabled={isFormInitialState}
          onResetClick={onResetClick}
          onOkClick={onOkClick}
        />
      </div>
    </TransitionModal>
  );
};

export default PoolsTableFilterModal;
