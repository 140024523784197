import { HarvestTxData } from 'common/msg/LpHarvest';
import { formatAmount, getAmp } from 'common/utils';
import Button from 'components/buttons/Button';
import ViewAllButton from 'components/buttons/ViewAllButton';
import Coin from 'components/coins/Coin';
import CoinList from 'components/coins/CoinList';
import Panel from 'components/panel/Panel';
import PanelAmount from 'components/panel/PanelAmount';
import H3 from 'components/texts/H3';
import H4 from 'components/texts/H4';
import NumberText from 'components/texts/NumberText';
import useTxClient from 'hooks/useTxClient';
import PairsPoolsTable, { PoolTableDisplayTypes } from 'pages/components/PairsPoolsTable';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssetDetail } from 'types/asset';
import type { PoolDetail } from 'types/pool';
import useMyPortfolio from './hooks/useMyPortfolio';
import ClaimAllRewardsModal from './modal/ClaimAllRewardsModal';
import AllClaimableRewards from './tooltip/AllClaimableRewards';
import TotalInvestmentDetails from './tooltip/TotalInvestmentDetails';
import InfoBox from 'components/infobox';
import useLocalStorage from 'hooks/useLocalStorage';
import { observer } from 'mobx-react-lite';
import { INFOBOX_TEXTS, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';

const MyInvestment = ({
  pools,
  assets,
  onPoolClick,
  localStorageKeyPrefix,
}: {
  pools: PoolDetail[];
  assets: AssetDetail[];
  onPoolClick?: (pool: PoolDetail) => void;
  /** it determines if localStorage flow will be used */
  localStorageKeyPrefix?: string;
}) => {
  const store = useMainStore();

  const {
    isLoading,
    myPools,
    rewardsByRewardToken,
    visibleRewards,
    hiddenRewards,
    totalRewardsUSD,
    totalInvestmentsUSDByType,
    totalInvestmentUSD,
  } = useMyPortfolio({ pools, assets, unbondings: [] });

  const storedIsDetailsOpen = useMemo<boolean>(() => {
    const localData = localStorage.getItem(`${localStorageKeyPrefix}-my-investment-open`);
    return localData === 'true';
  }, [localStorageKeyPrefix]);

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(storedIsDetailsOpen);
  useEffect(() => {
    if (myPools.length === 0) setIsDetailsOpen(false);
  }, [myPools.length]);

  const onClickViewAll = useCallback(() => {
    setIsDetailsOpen(!isDetailsOpen);

    if (localStorageKeyPrefix) localStorage.setItem(`${localStorageKeyPrefix}-my-investment-open`, `${!isDetailsOpen}`);
  }, [isDetailsOpen, localStorageKeyPrefix]);

  const ping = useMemo<boolean>(() => myPools.some((pool) => pool.myBalance.gt(0)), [myPools]);

  /** claim all modal */
  const [isClaimAllModalOpen, setIsClaimAllModalOpen] = useState<boolean>(false);
  useEffect(() => () => setIsClaimAllModalOpen(false), []);

  /** claim all tx */
  const [isSigning, setIsSigning] = useState(false);
  useEffect(() => setIsSigning(false), []);

  const { signAndBroadcast } = useTxClient();

  const claimAll = useCallback(async () => {
    setIsSigning(true);

    const claimAllTxDataArray: HarvestTxData[] = myPools
      .filter((pool) => pool.myStaking?.harvestable.some((reward) => reward.rewardAmount.gt(0)) ?? false)
      .map((pool) => ({ denom: pool.poolDenom }));

    try {
      await signAndBroadcast({
        type: 'harvest',
        txData: claimAllTxDataArray,
        chainData: store.chainsData,
      });
    } catch (e) {
      setIsSigning(false);
      console.log(e);
    }

    setIsSigning(false);
  }, [myPools, store.chainsData, signAndBroadcast]);

  /** @desc warning info box */
  const [showWarning, setShowWarning] = useState<boolean>(true);

  const { setLocal } = useLocalStorage({
    key: 'my-investment-warning',
    onGet: (value) => {
      if (value === 'false') setShowWarning(false);
    },
  });

  const onClickCloseWarning = useCallback(() => {
    setShowWarning(false);

    const value = 'false';
    const durationMs = 1000 * 60 * 60 * 24; // 1 day
    const expires = new Date(Date.now() + durationMs).getTime().toString();

    setLocal({
      value,
      expires,
    });
  }, [setLocal]);

  const hasAnyOutOfRangePool = useMemo<boolean>(() => {
    return myPools.some((pool) => {
      const amp = getAmp({ lastPrice: pool.pair.lastPrice, minPrice: pool.minPrice, maxPrice: pool.maxPrice });
      return amp.isZero();
    });
  }, [myPools]);

  return (
    <div className="space-y-3 md:space-y-6">
      <H3>My Investment</H3>

      <div className="w-full flex flex-col items-center">
        {showWarning && hasAnyOutOfRangePool && (
          <div className="w-full mb-3 md:mb-5">
            <InfoBox type="tip" onClickClose={onClickCloseWarning}>
              {INFOBOX_TEXTS.MY_POOL_OUT_OF_RANGE}
            </InfoBox>
          </div>
        )}

        <div className="w-full flex flex-col md:flex-row gap-x-6 gap-y-3">
          <Panel
            type="outline-medium"
            padding={store.isMobile ? '16px' : '24px'}
            className="grow-0 shrink-0 basis-[21%]"
          >
            <PanelAmount
              label="Total"
              isLoading={isLoading}
              amount={totalInvestmentUSD}
              symbol="$"
              size="md"
              startFrom0={true}
              tooltipContent={
                totalInvestmentUSD.gt(0) ? <TotalInvestmentDetails data={totalInvestmentsUSDByType} /> : undefined
              }
            />
          </Panel>

          <Panel padding={store.isMobile ? '16px' : '24px'}>
            <div className="w-full flex flex-col items-start md:flex-row justify-between md:items-center gap-x-7 gap-y-5 md:px-1">
              <div className="grow shrink flex flex-col md:flex-row gap-x-14 gap-y-4">
                {/* rewards total */}
                <div className="min-w-[30%]">
                  <PanelAmount
                    label="Rewards"
                    isLoading={isLoading}
                    amount={totalRewardsUSD}
                    symbol="$"
                    dp={6}
                    size="lg"
                    color={totalRewardsUSD.gt(0) ? 'primary' : 'gray'}
                    animated={true}
                    startFrom0={true}
                    tooltipSize="220px"
                    tooltipContent={
                      totalRewardsUSD.gt(0) ? (
                        <AllClaimableRewards rewardsByRewardToken={rewardsByRewardToken} />
                      ) : undefined
                    }
                  />
                </div>

                {/* rewards list */}
                {visibleRewards.length > 0 && (
                  <div className="w-fit grid grid-flow-row md:grid-flow-col grid-rows-3 gap-y-2 gap-x-5">
                    {visibleRewards.map((reward, index) => (
                      <div key={index} className="flex items-center gap-x-0.5">
                        <Coin asset={reward.asset} size="16px" />
                        <NumberText
                          size="xs"
                          value={formatAmount(reward.amount, reward.asset.exponent)}
                          unit={reward.asset.ticker}
                        />
                      </div>
                    ))}

                    {hiddenRewards.length > 0 && (
                      <div className="flex items-center gap-x-0.5">
                        <CoinList
                          size="16px"
                          assets={hiddenRewards.map((reward) => reward.asset)}
                          maxCnt={2}
                          className="opacity-40"
                        />
                        <span className="font_data_number_xs text-on_surface_variant_dark">
                          +{hiddenRewards.length}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <Button
                label="Claim All"
                size="lg"
                status={totalRewardsUSD.isZero() ? 'disabled' : isSigning ? 'loading' : 'enabled'}
                onClick={() => setIsClaimAllModalOpen(true)}
                className="grow-0 shrink-0 w-full md:w-auto"
              />
            </div>
          </Panel>
        </div>

        {/* view details button */}
        <ViewAllButton
          openLabel="View Details"
          closeLabel="Close Details"
          ping={ping}
          disabled={myPools.length === 0}
          tooltipContent={myPools.length === 0 ? TOOLTIP_TEXTS.NO_DETAILS_TO_VIEW : undefined}
          isOpen={isDetailsOpen}
          onClick={onClickViewAll}
          // for screen readers
          className="order-1 mt-6"
        />

        {/* my pools */}
        <div
          aria-hidden={!isDetailsOpen}
          className={`w-full space-y-4 transition-all ${
            isDetailsOpen ? 'opacity-100 mt-[2.25rem] md:mt-[3.5rem]' : 'max-h-0 overflow-hidden opacity-0 mt-0'
          }`}
        >
          <H4>My Pools</H4>
          <div className="overflow-x-auto overflow-y-hidden">
            <div className="md:min-w-[72rem]">
              <PairsPoolsTable
                pools={myPools}
                defaultKey={PoolTableDisplayTypes.POOL}
                localStorageKeyPrefix="my-pools"
                poolOnly={store.isMobile}
                onPoolClick={onPoolClick}
              />
            </div>
          </div>
        </div>
      </div>

      <ClaimAllRewardsModal
        isOpen={isClaimAllModalOpen}
        isLoading={isSigning}
        pools={myPools}
        totalRewardsUSD={totalRewardsUSD}
        onOkClick={claimAll}
        onClose={() => setIsClaimAllModalOpen(false)}
      />
    </div>
  );
};

export default observer(MyInvestment);
