import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useTxClient from 'hooks/useTxClient';
import { useMainStore } from 'provider/mainContext';
import { getForcedNumber, getValidDigitNumber } from 'common/utils';
import { autorun } from 'mobx';
import BigNumber from 'bignumber.js';
import { MAX_DECIMAL, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import useOrderbookQuantity from '../hooks/useOrderbookQuantity';
import type { InfoAsset } from 'types/asset';
import callToast from 'common/callToast';
import Widget from 'components/widgets/components/Widget';
import TabSelector from 'components/tabs/TabSelector';
import TradeForm from './TradeForm';
import TradeDirectionTabSelector, { TradeDirection } from './TradeDirectionTabSelector';
import TradeSettingPopover from './TradeSettingPopover';
import { OrderbookPair } from 'types/orderbook';

type TradingWidgetProps = {
  orderbookPair: OrderbookPair | undefined;
  selectedPrice: any;
  // orderbook: Orderbook | undefined;
  tickPrecisionDisplayValue: string;
  defaultOrderIndex?: TradeDirection;
  onOrderIndexChange?: (index: TradeDirection) => void;
  className?: string;
};

const TradingWidget = ({
  orderbookPair,
  selectedPrice,
  // orderbook,
  tickPrecisionDisplayValue,
  defaultOrderIndex,
  onOrderIndexChange,
  className = '',
}: TradingWidgetProps) => {
  const mainStore = useMainStore();
  const { signAndBroadcast } = useTxClient();

  const [orderIndex, setOrderIndex] = useState<TradeDirection>(0);
  useEffect(() => {
    if (defaultOrderIndex !== undefined) setOrderIndex(defaultOrderIndex);
  }, [defaultOrderIndex]);

  const [orderTypeIndex, setOrderTypeIndex] = useState<0 | 1 | 2>(0);
  const [currentPairId, setCurrentPairId] = useState<number>(0);
  const [orderLifespanSeconds, setOrderLifespanSeconds] = useState<number>(86400);

  useEffect(() => {
    setOrderLifespanSeconds(getForcedNumber(orderLifespanSeconds, 0, 86400));
    localStorage.setItem('orderLifespanSeconds', orderLifespanSeconds.toString());
  }, [orderLifespanSeconds]);

  useEffect(() => {
    const preSetOrderLifeSpanSeconds = localStorage.getItem('orderLifespanSeconds');
    if (preSetOrderLifeSpanSeconds) {
      setOrderLifespanSeconds(Number(preSetOrderLifeSpanSeconds));
    }
  }, []);

  const [availableBalance, setAvailableBalance] = useState<string>('0');
  const [price, setPrice] = useState<string>('');
  // const [quantity, setQuantity] = useState<string>('');
  const [selectedPercent, setSelectedPercent] = useState<number>(0);

  // const [isSet, setIsSet] = useState<boolean>(false);
  const [isBalanceOver, setIsBalanceOver] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isBuy = useMemo<boolean>(() => orderIndex === 0, [orderIndex]);

  const orderablePrice = useMemo(() => {
    if (orderbookPair)
      return {
        max: getValidDigitNumber(new BigNumber(orderbookPair.lastPrice).multipliedBy(1.1).toNumber(), 5, 1),
        min: getValidDigitNumber(new BigNumber(orderbookPair.lastPrice).multipliedBy(0.9).toNumber(), 5, 2),
      };
    return null;
  }, [orderbookPair]);

  const commaRemovedPrice = useMemo<string>(() => price?.replaceAll(',', '') ?? '', [price]);

  // HELPER
  // const getInstantPrice = useCallback(
  //   (amount) => {
  //     let finalPrice = '';
  //     if (orderbookPair && orderTypeIndex === 1 && amount) {
  //       const orderbook1x = JSON.parse(
  //         JSON.stringify(
  //           orderbook?.orderbook?.order_books?.filter((ob) => {
  //             return (ob.tick_precision === 2 ? '10x' : '1x') === '1x';
  //           })?.[0]?.[orderIndex === 0 ? 'sells' : 'buys']
  //         )
  //       );
  //       const orderbook10x = JSON.parse(
  //         JSON.stringify(
  //           orderbook?.orderbook?.order_books?.filter((ob) => {
  //             return (ob.tick_precision === 2 ? '10x' : '1x') === '10x';
  //           })?.[0]?.[orderIndex === 0 ? 'sells' : 'buys']
  //         )
  //       );
  //       // const is1x = mainStore.tickPrecision === 3;
  //       const is1x = true;

  //       if (orderIndex === 0) {
  //         orderbook1x.reverse();
  //         orderbook10x.reverse();
  //       }

  //       const baseExponent = orderbookPair.baseInfo?.exponent;
  //       const exponentDiff = orderbookPair.exponentDiff;
  //       let is1xOver = false;
  //       if (is1x) {
  //         let total = 0;
  //         orderbook1x.every((order) => {
  //           total = new BigNumber(order.pool_order_amount)
  //             .plus(order.user_order_amount)
  //             .shiftedBy(-baseExponent)
  //             .plus(total)
  //             .toNumber();
  //           if (new BigNumber(total).gte(amount)) {
  //             finalPrice = String(parseFloat(order.price) * 10 ** exponentDiff);
  //             return false;
  //           } else {
  //             return true;
  //           }
  //         });
  //         if (new BigNumber(amount).gt(total)) {
  //           is1xOver = true;
  //         }
  //       }

  //       if (is1xOver || !is1x) {
  //         let _temp_amount = 0;
  //         // let _last_tick_price = '0';
  //         const is10xOver = orderbook10x.every((order) => {
  //           _temp_amount = new BigNumber(order.pool_order_amount)
  //             .plus(order.user_order_amount)
  //             .shiftedBy(-baseExponent)
  //             .plus(_temp_amount)
  //             .toNumber();
  //           if (new BigNumber(_temp_amount).gte(amount)) {
  //             finalPrice = String(parseFloat(order.price) * 10 ** exponentDiff);
  //             return false;
  //           } else {
  //             // _last_tick_price = String(parseFloat(order.price) * 10 ** exponentDiff);
  //             return true;
  //           }
  //         });

  //         if (is10xOver) {
  //           finalPrice = (isBuy ? orderablePrice?.max : orderablePrice?.min) ?? '';
  //         }
  //       }
  //     } else if (orderTypeIndex === 1) {
  //       finalPrice = 'Auto';
  //     }
  //     return finalPrice;
  //   },
  //   [
  //     orderbookPair.baseInfo?.exponent,
  //     orderbookPair.exponentDiff,
  //     isBuy,
  //     orderbookPair,
  //     orderablePrice?.max,
  //     orderablePrice?.min,
  //     orderIndex,
  //     orderTypeIndex,
  //     orderbook,
  //   ]
  // );

  const payAssetInfo = useMemo<InfoAsset | undefined>(
    () => (orderIndex === 0 ? orderbookPair?.quoteInfo : orderbookPair?.baseInfo),
    [orderIndex, orderbookPair?.quoteInfo, orderbookPair?.baseInfo]
  );

  const { quantity, setQuantity, setQuantityByPercent } = useOrderbookQuantity({
    orderIndex,
    orderTypeIndex,
    availableBalance,
    payAssetInfo,
    baseInfo: orderbookPair?.baseInfo,
    quoteInfo: orderbookPair?.quoteInfo,
    predPrice: orderbookPair?.predPrice,
    price,
    setPrice,
    // getInstantPrice,
  });

  /** @summary pairId sync codes moved out from orderbookPair memo to use setQuantity */
  useEffect(() => {
    if (orderbookPair && currentPairId !== orderbookPair.pairId) {
      if (orderTypeIndex === 1) {
        setPrice('Auto');
      } else if (orderTypeIndex === 2) {
        setPrice('Market');
      } else {
        setPrice(
          new BigNumber(orderbookPair.predPrice)
            .shiftedBy(orderbookPair.exponentDiff)
            .decimalPlaces(MAX_DECIMAL, 1)
            .toString()
        );
      }
      setQuantity('');
      setCurrentPairId(orderbookPair.pairId);
    }
  }, [orderbookPair, currentPairId, orderTypeIndex, setQuantity]);

  const validInputValue = useMemo(() => {
    if (orderTypeIndex === 0) {
      return !!(Number(price) && Number(quantity));
    } else {
      return !!Number(quantity);
    }
  }, [price, quantity]);

  // set available balance
  useEffect(
    () =>
      autorun(() => {
        if (orderbookPair && mainStore.balanceData) {
          const denom = isBuy ? orderbookPair.quoteDenom : orderbookPair.baseDenom;
          const exponent = isBuy ? orderbookPair.quoteInfo.exponent : orderbookPair.baseInfo.exponent;
          if (mainStore.balanceData?.[denom]) {
            setAvailableBalance(new BigNumber(String(mainStore.balanceData?.[denom])).shiftedBy(-exponent).toString());
          } else {
            setAvailableBalance('0');
          }
        }
      }),
    [mainStore.balanceData, orderIndex]
  );

  // set isOver status
  useEffect(() => {
    if (orderIndex === 1) {
      // sell
      setIsBalanceOver(new BigNumber(quantity).gt(availableBalance));
    } else {
      // buy
      setIsBalanceOver(new BigNumber(quantity).multipliedBy(commaRemovedPrice).gt(availableBalance));
    }
  }, [quantity, availableBalance, price]);

  // set initial price by orderType
  useEffect(() => {
    reset();
    if (orderbookPair && orderTypeIndex === 0) {
      setPrice(
        new BigNumber(orderbookPair.predPrice)
          .shiftedBy(orderbookPair.exponentDiff)
          .decimalPlaces(MAX_DECIMAL, 1)
          .toString()
      );
    } else if (orderTypeIndex === 1) {
      setPrice('Auto');
    } else if (orderTypeIndex === 2) {
      setPrice('Market');
    }
  }, [orderIndex, orderTypeIndex, orderbookPair?.pairId]);

  // set price by clicking the orderbook row
  useEffect(() => {
    if (!isLoading && orderbookPair && orderTypeIndex === 0 && selectedPrice.displayPrice) {
      const isSellSection = new BigNumber(orderbookPair.predPrice).gt(selectedPrice.displayPrice);
      const exponentedPrice = new BigNumber(selectedPrice.price).shiftedBy(orderbookPair.exponentDiff).toString();
      if (!orderablePrice) return;
      if (isSellSection) {
        const isUnder = new BigNumber(selectedPrice.price).lt(orderablePrice.min);
        setPrice(
          isUnder ? new BigNumber(orderablePrice.min).shiftedBy(orderbookPair.exponentDiff).toString() : exponentedPrice
        );
      } else {
        const isOver = new BigNumber(selectedPrice.price).gt(orderablePrice.max);
        setPrice(
          isOver ? new BigNumber(orderablePrice.max).shiftedBy(orderbookPair.exponentDiff).toString() : exponentedPrice
        );
      }
      setQuantity(selectedPrice.totalAmount);
    }
  }, [selectedPrice]);

  // current available balance denom related data
  const avblData: { ticker: string; denom: string; exponent: number; displayBalance: string } = useMemo(() => {
    if (orderbookPair && mainStore.balanceData) {
      const isSell = orderIndex === 1;
      const denom = isSell ? orderbookPair.baseDenom : orderbookPair.quoteDenom;
      const exponent = Number(isSell ? orderbookPair.baseInfo.exponent : orderbookPair.quoteInfo.exponent);
      const rawBalance =
        mainStore.balanceData?.[orderIndex === 1 ? orderbookPair.baseDenom : orderbookPair.quoteDenom] ?? 0;
      return {
        ticker: isSell ? orderbookPair.baseInfo.ticker : orderbookPair.quoteInfo.ticker,
        denom,
        exponent,
        displayBalance: new BigNumber(rawBalance).shiftedBy(-exponent).toString(),
      };
    } else {
      return { ticker: '', denom: '', exponent: 0, displayBalance: '0' };
    }
  }, [orderIndex, orderbookPair, mainStore.balanceData]);

  // auto-fill the instant order price
  // useEffect(() => {
  //   if (orderbookPair && orderTypeIndex === 1 && quantity) {
  //     setPrice(getInstantPrice(quantity));
  //   }
  // }, [orderTypeIndex, orderIndex, orderbookPair, quantity, mainStore.tickPrecision, price]);

  ////////////////
  // FUNCTIONS //
  ///////////////

  // order handler
  async function sendOrder() {
    // quantity check
    if (quantity === '' || quantity === '0') {
      callToast({
        toastId: new Date().toString(),
        type: 'info',
        title: 'Input Order Quantity',
        content: '',
      });
      return;
    }

    if (orderbookPair && mainStore.chainsData.isInit && !isLoading) {
      const inputRawAmount = new BigNumber(quantity)
        .shiftedBy(
          orderTypeIndex !== 2
            ? orderbookPair.baseInfo.exponent
            : orderIndex === 0
            ? orderbookPair.quoteInfo.exponent
            : orderbookPair.baseInfo.exponent
        )
        .decimalPlaces(0, 1)
        .toString();

      // min base denom amount check
      if (Number(inputRawAmount) < 100) {
        callToast({
          toastId: new Date().toString(),
          type: 'info',
          title: 'Increase Base Coin Quantity',
          content: 'Increase baseCoin quantity Min. 0.0001',
        });
        return;
      }
      setIsLoading(true);

      if (orderTypeIndex === 2) {
        // Market Order

        // const marketOrderAmount = isBuy
        //   ? new BigNumber(quantity)
        //       .shiftedBy(orderbookPair.quoteInfo.exponent)
        //       .multipliedBy(orderbookPair.lastPrice)
        //       .multipliedBy(1.1)
        //       .decimalPlaces(0, 2)
        //       .toString()
        //   : new BigNumber(quantity)
        //       .shiftedBy(orderbookPair.baseInfo.exponent)
        //       .dividedBy(1.1)
        //       .decimalPlaces(0, 2)
        //       .toString();

        const isBuy = orderIndex === 0;
        await signAndBroadcast({
          type: 'marketOrder',
          chainData: mainStore.chainsData,
          txData: {
            pairId: orderbookPair.pairId,
            orderDirection: isBuy ? 1 : 2,
            orderLifespanSeconds: 0,
            baseAmount: isBuy
              ? new BigNumber(inputRawAmount)
                  .div(orderbookPair.lastPrice)
                  .multipliedBy(0.85)
                  .decimalPlaces(0, 1)
                  .toString()
              : inputRawAmount,
            offer: {
              denom: isBuy ? orderbookPair.quoteDenom : orderbookPair.baseDenom,
              amount: inputRawAmount,
            },
            demand: {
              denom: isBuy ? orderbookPair.baseDenom : orderbookPair.quoteDenom,
            },
          },
        });
        reset();
        setIsLoading(false);
      } else {
        const totalAmount = new BigNumber(quantity)
          .multipliedBy(price.replaceAll(',', ''))
          .shiftedBy(orderbookPair.quoteInfo.exponent)
          .decimalPlaces(0, BigNumber.ROUND_CEIL) // change point
          .toString();
        const rawPrice = new BigNumber(price.replaceAll(',', '')).shiftedBy(-orderbookPair.exponentDiff).toString();
        await signAndBroadcast({
          type: 'orderbook',
          chainData: mainStore.chainsData,
          txData: {
            pairId: orderbookPair?.pairId,
            orderDirection: orderIndex === 0 ? 1 : 2,
            orderPrice: rawPrice,
            orderLifespanSeconds: orderTypeIndex === 0 ? orderLifespanSeconds : 0,
            baseAmount: inputRawAmount,
            offer: {
              denom: orderIndex === 0 ? orderbookPair.quoteDenom : orderbookPair.baseDenom,
              amount: orderIndex === 0 ? totalAmount : inputRawAmount,
            },
            demand: {
              denom: orderIndex === 1 ? orderbookPair.quoteDenom : orderbookPair.baseDenom,
              amount: orderIndex === 1 ? totalAmount : inputRawAmount,
            },
          },
        });
        reset();
        setIsLoading(false);
      }
    } else {
      setQuantity('');
      setSelectedPercent(0);
      setIsLoading(false);
      alert('Error: loading chain data');
    }
  }

  function reset() {
    if (!isLoading && orderbookPair) {
      setQuantity('');
      setSelectedPercent(0);
      if (orderTypeIndex === 0) {
        setPrice(
          new BigNumber(orderbookPair.predPrice)
            .shiftedBy(orderbookPair.exponentDiff)
            .decimalPlaces(MAX_DECIMAL, 1)
            .toString()
        );
      } else if (orderTypeIndex === 1) {
        setPrice('Auto');
      } else {
        setPrice('Market');
      }
    }
  }

  function setSelectedPercentQuantity(percent) {
    if (!isLoading && (Number(price?.replaceAll(',', '')) || orderTypeIndex > 0)) {
      setQuantityByPercent({ percent, onChange: () => setSelectedPercent(percent) });
      // if (Number(availableBalance)) {
      //   const _maxSafeBalance =
      //     orderbookPair.baseInfo.ticker !== 'CRE' || selectedPercent !== 100
      //       ? availableBalance
      //       : Number(availableBalance) - 0.5;
      //   const balance = _maxSafeBalance > 0 ? _maxSafeBalance : availableBalance;
      //   let percentAmount;
      //   if (orderIndex === 0) {
      //     // buy
      //     let rawPrice = '1'; // if marker order
      //     if (orderTypeIndex === 0) {
      //       rawPrice = Number(commaRemovedPrice)
      //         ? commaRemovedPrice
      //         : new BigNumber(orderbookPair.predPrice).toString();
      //     } else if (orderTypeIndex === 1) {
      //       rawPrice =
      //         getInstantPrice(
      //           new BigNumber(balance)
      //             .div(
      //               Number(commaRemovedPrice)
      //                 ? commaRemovedPrice
      //                 : new BigNumber(orderbookPair.predPrice).toString()
      //             )
      //             .div(100 / percent)
      //             .decimalPlaces(orderbookPair.baseInfo.exponent, 1)
      //             .toString()
      //         ) ?? '';
      //       setPrice(rawPrice);
      //     }

      //     percentAmount = new BigNumber(balance)
      //       .div(rawPrice)
      //       .div(100 / percent)
      //       .decimalPlaces(orderbookPair.baseInfo.exponent, 1)
      //       .toString();
      //   } else {
      //     // sell
      //     percentAmount = new BigNumber(balance)
      //       .div(100 / percent)
      //       .decimalPlaces(orderbookPair.baseInfo.exponent, 1)
      //       .toString();
      //     if (orderTypeIndex === 1) {
      //       setPrice(getInstantPrice(balance) ?? '');
      //     }
      //   }
      //   setQuantity(percentAmount);
      //   setSelectedPercent(percent);
      // } else {
      //   setQuantity('');
      // }
    }
  }

  useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  return (
    <>
      <Widget size="xs" padding="0" className={className}>
        <Widget.Header title="Spot" type="head-area">
          {/* trade setting (order lifespan) */}
          <Widget.Header.TopRight>
            <TradeSettingPopover
              type={mainStore.isMobile ? 'modal' : 'popover'}
              setOrderLifespanSeconds={setOrderLifespanSeconds}
              orderLifespanSeconds={orderLifespanSeconds}
            />
          </Widget.Header.TopRight>
        </Widget.Header>

        <Widget.Body>
          <div className="w-full h-full p-2 space-y-4 rounded-b-[inherit] overflow-hidden md:p-3">
            {/* buy & sell */}
            <TradeDirectionTabSelector
              direction={orderIndex}
              onChangeDirection={(index) => {
                if (!isLoading) {
                  setQuantity('');
                  setSelectedPercent(0);
                  setOrderIndex(index);
                  onOrderIndexChange?.(index);
                }
              }}
            />

            {/* limit & market */}
            <TabSelector
              type="filled"
              size="sm"
              tabsMaxWidth={mainStore.isMobile ? '8rem' : '12rem'}
              fallbackIndex={orderTypeIndex}
              onChangeTab={(index) => {
                if (!isLoading) {
                  const oTypeIndex: 0 | 1 | 2 = index === 1 ? 2 : 0;
                  setOrderTypeIndex(oTypeIndex);
                  setQuantity('');
                }
              }}
              panels={[
                {
                  key: 'Limit',
                  content: (
                    <TradeForm
                      // orderbookPair={orderbookPair}
                      avblData={avblData}
                      isBalanceOver={isBalanceOver}
                      orderIndex={orderIndex}
                      orderTypeIndex={orderTypeIndex}
                      price={price}
                      setPrice={setPrice}
                      priceStep={new BigNumber(tickPrecisionDisplayValue).toNumber()}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      orderbookPair={orderbookPair}
                      isLoading={isLoading}
                      selectedPercent={selectedPercent}
                      setSelectedPercent={setSelectedPercent}
                      onSelectPercentInput={setSelectedPercentQuantity}
                      onReset={reset}
                      onOrder={sendOrder}
                    />
                  ),
                },
                {
                  key: 'Market',
                  labelTooltipContent: TOOLTIP_TEXTS.MARKET_ORDER,
                  content: (
                    <TradeForm
                      // orderbookPair={orderbookPair}
                      avblData={avblData}
                      isBalanceOver={isBalanceOver}
                      orderIndex={orderIndex}
                      orderTypeIndex={orderTypeIndex}
                      price={price}
                      setPrice={setPrice}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      orderbookPair={orderbookPair}
                      isLoading={isLoading}
                      selectedPercent={selectedPercent}
                      setSelectedPercent={setSelectedPercent}
                      onSelectPercentInput={setSelectedPercentQuantity}
                      onReset={reset}
                      onOrder={sendOrder}
                    />
                  ),
                },
              ]}
            />
          </div>
        </Widget.Body>
      </Widget>
    </>
  );
};

export default React.memo(TradingWidget);
