import TooltipIcon from 'components/tooltips/TooltipIcon';

export type TabStyle = 'line' | 'segmented' | 'filled' | 'text';
export type TabSize = 'xs' | 'sm' | 'md';
type TabOrder = 'start' | 'middle' | 'end';

const TAB_ITEM_SIZE_DICT: { [key in TabSize]: { font: string; grid: string } } = {
  xs: { font: 'font_label_xs', grid: '' },
  sm: { font: 'font_label_s', grid: '' },
  md: { font: 'font_label_m', grid: '' },
};

const TAB_ITEM_VARIANT_DICT: {
  [key in TabStyle]: {
    height: string;
    padding: string;
    border: (order: TabOrder) => string;
    color: (active: boolean) => string;
  };
} = {
  line: {
    height: 'h-[2rem]',
    padding: 'pb-2',
    border: () => '',
    color: (active) =>
      `bg-transparent border-b border-surface_variant hover:text-primary ${
        active ? 'text-primary' : 'text-on_surface_variant_light'
      }`,
  },
  segmented: {
    height: 'h-7 md:h-[2.25rem]',
    padding: 'px-2 py-[0.625rem]',
    border: (order) =>
      order === 'start'
        ? `border-r-0 rounded-tl-100px rounded-bl-100px`
        : order === 'end'
        ? `rounded-tr-100px rounded-br-100px`
        : 'border-r-0',
    color: (active) =>
      `border border-outline_variant ${
        active
          ? 'bg-inverse_primary text-primary'
          : 'bg-transparent hover:bg-on_surface_variant_light_o24 text-on_surface_variant_light hover:text-on_surface_variant_light'
      }`,
  },
  filled: {
    height: 'h-[1.5rem]',
    padding: 'px-2 py-[0.375rem]',
    border: () => 'rounded-100px overflow-hidden',
    color: (active) =>
      active
        ? 'text-primary bg-inverse_primary hover:bg-on_inverse_primary_o24'
        : 'text-on_surface_variant_light hover:bg-on_surface_variant_light_o24',
  },
  text: {
    height: 'h-[1.5rem]',
    padding: 'px-2 py-[0.375rem]',
    border: () => 'rounded-100px overflow-hidden',
    color: (active) =>
      active
        ? 'text-primary bg-transparent hover:bg-primary_o24'
        : 'text-on_surface_variant_light hover:bg-on_surface_variant_light_o24',
  },
};

type TabItemProps = {
  type: TabStyle;
  size?: TabSize;
  label: string;
  labelTooltipContent?: string | JSX.Element;
  order?: TabOrder;
  active: boolean;
  onClick?: () => void;
};

const TabItem = ({
  type,
  size = 'md',
  label,
  labelTooltipContent,
  order = 'middle',
  active = false,
  onClick,
}: TabItemProps) => {
  return (
    <button
      type="button"
      className={`relative w-full ${TAB_ITEM_VARIANT_DICT[type].height} ${
        TAB_ITEM_VARIANT_DICT[type].padding
      } flex justify-center items-center ${TAB_ITEM_VARIANT_DICT[type].color(active)} ${TAB_ITEM_VARIANT_DICT[
        type
      ].border(order)} transition-all overflow-hidden`}
      onClick={onClick}
    >
      <div className="inline-flex items-center gap-x-1">
        <div className={`whitespace-nowrap ${TAB_ITEM_SIZE_DICT[size].font}`}>{label}</div>
        {labelTooltipContent && <TooltipIcon tooltipSize="300px" tooltipContent={labelTooltipContent} />}
      </div>
    </button>
  );
};

export default TabItem;
