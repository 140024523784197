import Icon, { IconType } from 'components/icon';
import { IconButtonSize, ICON_BUTTON_SIZE_DICT } from './styles';

const IconToggleButton = ({
  iconType = 'star',
  size = 'xs',
  isOn = false,
  onClick,
}: {
  iconType?: IconType;
  size?: IconButtonSize;
  isOn?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      type="button"
      className={`${ICON_BUTTON_SIZE_DICT[size].button} hover:opacity-80 transition-all`}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.();
      }}
    >
      <Icon
        type={iconType}
        size={ICON_BUTTON_SIZE_DICT[size].icon}
        className={`transition-colors ${isOn ? 'text-primary' : 'text-semantic_gray'}`}
      />
    </button>
  );
};

export default IconToggleButton;
