import { useMemo } from 'react';

import { useMainStore } from 'provider/mainContext';
import { useLpFarmStaking } from 'hooks/useAPI';

import BigNumber from 'bignumber.js';
import type { MyPoolDetail, PoolDetail } from 'types/pool';
import { toLpFarmStakingBN } from 'common/bigNumberHandlers';
import type { LpFarmStaking } from 'types/farm';
import { InfoAsset, isCoinDetail } from 'types/asset';

export default function useMyPools(pools: PoolDetail[]) {
  const store = useMainStore();
  // const { data: farmStakingResponse } = useFarmStaking(store.wallet.address, 5000);
  const { data: lpFarmStakingResponse, isLoading: lpFarmStakingResponseLoading } = useLpFarmStaking(
    store.wallet.address,
    5000
  );

  const lpFarmStakingByDenom = useMemo(() => {
    const _lpFarmStakingByDenom: Record<string, LpFarmStaking> = {};
    lpFarmStakingResponse?.data?.forEach((farm) => {
      _lpFarmStakingByDenom[farm.denom] = toLpFarmStakingBN(farm);
    });
    return _lpFarmStakingByDenom;
  }, [lpFarmStakingResponse]);

  const myPools = useMemo<MyPoolDetail[]>(() => {
    if (pools || store?.balanceData || lpFarmStakingByDenom) {
      return pools
        .filter((pool) => store.balanceData?.[pool?.poolDenom] || lpFarmStakingByDenom[pool.poolDenom])
        .map((pool) => {
          // const myPool: any = { ...pool };

          // if (store.balanceData[pool.poolDenom]) {
          //   myPool.myBalance = new BigNumber(store.balanceData?.[pool?.poolDenom]);
          // } else {
          //   myPool.myBalance = new BigNumber(0);
          // }

          // myPool.myTotalBalance = myPool.myBalance;
          // if (lpFarmStakingByDenom[pool.poolDenom]) {
          //   myPool.myStaking = lpFarmStakingByDenom[pool.poolDenom];
          //   // myPool.myTotalBalance = myPool.myTotalBalance.plus(lpFarmStakingByDenom[pool.poolDenom].queuedAmount);
          //   myPool.myTotalBalance = myPool.myTotalBalance.plus(lpFarmStakingByDenom[pool.poolDenom].stakedAmount);
          // }

          // myPool.myBalanceUSD = myPool.myBalance.multipliedBy(pool.priceOracle);
          // myPool.myStakingUSD = myPool.myStaking?.stakedAmount.multipliedBy(pool.priceOracle) ?? new BigNumber(0);
          // myPool.myTotalBalanceUsd = myPool.myTotalBalance.multipliedBy(pool.priceOracle);

          // return myPool as MyPoolDetail;

          const myBalance = new BigNumber(store.balanceData[pool.poolDenom] ?? 0);
          const myStaking = lpFarmStakingByDenom[pool.poolDenom];
          const myTotalBalance = myBalance.plus(myStaking?.stakedAmount ?? 0);

          const myBalanceUSD = myBalance.multipliedBy(pool.priceOracle);
          const myStakingUSD = myStaking?.stakedAmount.multipliedBy(pool.priceOracle) ?? new BigNumber(0);
          const myTotalBalanceUsd = myTotalBalance.multipliedBy(pool.priceOracle);

          const claimableRewards =
            myStaking?.harvestable
              .filter((reward) => reward.rewardAmount.gt(0))
              .map((reward) => {
                const asset: InfoAsset | undefined = store.assetsData.info[reward.rewardDenom];
                const amount = reward.rewardAmount.shiftedBy(-(asset?.exponent ?? 0));
                const priceOracle = store.assetsData.live[reward.rewardDenom]?.priceOracle ?? 0;
                const amountUSD = amount.multipliedBy(priceOracle);
                return { asset, amount, amountUSD };
              })
              .filter(isCoinDetail)
              .sort((a, b) => a.asset.ticker.toUpperCase().localeCompare(b.asset.ticker.toUpperCase())) ?? [];

          return {
            ...pool,
            myBalance,
            myStaking,
            myTotalBalance,
            myBalanceUSD,
            myStakingUSD,
            myTotalBalanceUsd,
            claimableRewards,
          };
        });
    } else {
      return [];
    }
  }, [pools, store.balanceData, lpFarmStakingByDenom, store.assetsData.info, store.assetsData.live]);

  // const farmStakingData = useMemo(() => {
  //   const _farmStaking: Record<string, FarmStaking> = {};
  //   farmStakingResponse?.data?.forEach((farm) => {
  //     _farmStaking[farm.denom] = toFarmStakingBN(farm);
  //   });
  //   return _farmStaking;
  // }, [farmStakingResponse]);

  // const myPools = useMemo<MyPoolDetail[]>(() => {
  //   if (pools || store?.balanceData || farmStakingData) {
  //     return pools
  //       .filter((pool) => store.balanceData?.[pool?.poolDenom] || farmStakingData[pool.poolDenom])
  //       .map((pool) => {
  //         const myPool: any = { ...pool };
  //         if (store.balanceData[pool.poolDenom]) {
  //           myPool.myBalance = new BigNumber(store.balanceData?.[pool?.poolDenom]);
  //         } else {
  //           myPool.myBalance = new BigNumber(0);
  //         }
  //         myPool.myTotalBalance = myPool.myBalance;
  //         if (farmStakingData[pool.poolDenom]) {
  //           myPool.myStaking = farmStakingData[pool.poolDenom];
  //           myPool.myTotalBalance = myPool.myTotalBalance.plus(farmStakingData[pool.poolDenom].queuedAmount);
  //           myPool.myTotalBalance = myPool.myTotalBalance.plus(farmStakingData[pool.poolDenom].stakedAmount);
  //         }
  //         myPool.myTotalBalanceUsd = myPool.myTotalBalance.multipliedBy(pool.priceOracle);
  //         return myPool as MyPoolDetail;
  //       });
  //   } else {
  //     return [];
  //   }
  // }, [pools, store.balanceData, store.assetsData, farmStakingData]);

  // _unified_temp

  // rewards = _unified_temp
  //   .map((harvest) => {
  //     if (mainStore.assetsData.live[harvest.rewardDenom] && mainStore.assetsData.info[harvest.rewardDenom]) {
  //       let assetInfo = mainStore.assetsData.info[harvest.rewardDenom];
  //       const rewardAmount = new BigNumber(harvest.rewardAmount).dividedBy(10 ** assetInfo.exponent);
  //       const rewardUsd = rewardAmount.multipliedBy(mainStore.assetsData.live[harvest.rewardDenom].priceOracle);
  //       return {
  //         ticker: assetInfo.ticker,
  //         rewardAmount: rewardAmount.decimalPlaces(6).toFormat(),
  //         rewardUsd,
  //       };
  //     }
  //     return null;
  //   })
  //   .filter((item) => item?.rewardAmount !== '0');

  // if (rewards.length > 0) {
  //   totalRewardUsd = rewards.reduce((acc, cur) => {
  //     return acc.plus(cur ? cur.rewardUsd : 0);
  //   }, new BigNumber(0));
  // }

  return {
    myPools,
    isLoading: store.wallet.address.length > 0 && lpFarmStakingResponseLoading,
  };
}
