import TooltipInner from 'components/tooltips/TooltipInner';
import type { PoolDetail } from 'types/pool';
import useFarmRewards, { RewardPlanType } from '../hooks/useFarmRewards';
import RewardsPlanItem from './rewards/RewardsPlanItem';
import { formatUSD } from 'common/utils';
import Divider from 'components/divider/Divider';

const RewardsEmissionPerDay = ({ pool }: { pool: PoolDetail }) => {
  const { plansDict, dailyTotalUSD } = useFarmRewards(pool);

  return (
    <TooltipInner padding={false} title="Rewards Emission per Day" highlight={{ text: formatUSD(dailyTotalUSD) }}>
      <div className="flex flex-col gap-y-0">
        {Object.keys(plansDict).map((key, index) => {
          const dict = plansDict[key as RewardPlanType];
          return dict ? (
            <div key={key}>
              {index > 0 && <Divider type="hard" />}
              <RewardsPlanItem title={dict.title} plans={dict.plans} unit="usd" />
            </div>
          ) : null;
        })}
      </div>
    </TooltipInner>
  );
};

export default RewardsEmissionPerDay;
