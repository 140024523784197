import BigNumber from 'bignumber.js';
import { formatUSD } from 'common/utils';
import { InvestmentKeys, IS_LF_ENABLED } from 'COMMON_VARIABLES';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import TooltipLabel from 'components/tooltips/TooltipLabel';

const TotalInvestmentDetails = ({
  data,
}: {
  data: { [key in InvestmentKeys]: { amount: BigNumber; dp?: number } };
}) => {
  const keys = Object.keys(data).filter((key) => IS_LF_ENABLED || key !== InvestmentKeys.LF_TOKENS);

  return (
    <TooltipInner title="My Investment Details">
      <div className="space-y-3">
        {keys.map((key, index) => (
          <div key={index} className="flex items-center justify-between gap-x-2">
            <TooltipLabel label={key} />
            <NumberText
              size="sm"
              color="plain-mono"
              value={formatUSD(data[key].amount, { significant: !!data[key].dp })}
            />
          </div>
        ))}
      </div>
    </TooltipInner>
  );
};

export default TotalInvestmentDetails;
