import type { FeedbackType } from './types';

const TOAST_PROGRESS_BG_DICT: { [key in FeedbackType]: string } = {
  info: 'bg-semantic_blue',
  success: 'bg-semantic_green',
  warning: 'bg-semantic_yellow',
  error: 'bg-semantic_red',
  waiting: 'bg-semantic_gray',
  congrats: 'bg-semantic_gold',
};

type ToastProgressBarProps = {
  type: FeedbackType;
  autoClose: number;
  paused?: boolean;
  onProgressComplete?: () => void;
};

const ToastProgressBar = ({ type, autoClose, paused = false, onProgressComplete }: ToastProgressBarProps) => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex justify-start w-full h-1">
      <div
        className={`w-full h-full ${TOAST_PROGRESS_BG_DICT[type]} opacity-70 origin-left animate-timer`}
        style={{
          animationDuration: `${autoClose}ms`,
          animationPlayState: paused ? 'paused' : 'running',
        }}
        onAnimationEnd={onProgressComplete}
      ></div>
    </div>
  );
};

export default ToastProgressBar;
