import { useMainStore } from 'provider/mainContext';
import PageTitleArea from 'components/layout/PageTitleArea';
import H4 from 'components/texts/H4';
import TabSelector from 'components/tabs/TabSelector';
import ProposalsTable from './components/ProposalsTable';

const Gov = () => {
  const mainStore = useMainStore();

  return (
    <div className="pb-40">
      <PageTitleArea title="Governance" />

      <main role="main" className="relative w-full max-w-content pt-5 md:pt-[3rem] m-auto px-4 md:px-0">
        <TabSelector
          type="segmented"
          size={mainStore.isMobile ? 'sm' : 'md'}
          tabsMaxWidth={mainStore.isMobile ? '200px' : '17.875rem'}
          onChangeTab={(index) => mainStore.setGovTab(index)}
          fallbackIndex={mainStore.govTab}
          panels={[
            {
              key: 'Live',
              content: <ProposalsTable type="live" />,
            },
            {
              key: 'Deposit',
              content: <ProposalsTable type="deposit" />,
            },
            {
              key: 'Past',
              content: <ProposalsTable type="past" />,
            },
          ]}
          left={<H4>Proposals</H4>}
        />
      </main>
    </div>
  );
};

export default Gov;
