import IconButton from 'components/buttons/IconButton';

type SliderHeaderProps = {
  title: string;
  onBackClick?: () => void;
  className?: string;
};

const SliderHeader = ({ title, onBackClick, className = '' }: SliderHeaderProps) => {
  return (
    <div className={className}>
      <header className="flex items-center gap-x-2 px-4 md:px-5">
        {onBackClick && <IconButton iconType="expandmore" size="md" onClick={onBackClick} />}
        <div className="text-on_surface_variant_light font_headline_xs_mobile md:font_headline_xs">{title}</div>
      </header>
    </div>
  );
};

export default SliderHeader;
