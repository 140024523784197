import PercentButtonGroup from 'components/buttons/PercentButtonGroup';
import Ranger, { RangerColor } from '.';

type TextedRangerProps = {
  color?: RangerColor;
  rate: number;
  unit?: string;
  captionNumber?: string;
  onChange?: (rate: number) => void;
  disabled?: boolean;
};

const TextedRanger = ({ color, rate, unit, captionNumber, onChange, disabled = false }: TextedRangerProps) => {
  return (
    <div className="flex flex-col items-center gap-y-4">
      <div className="flex flex-col items-center gap-y-1">
        <div className="relative flex items-center justify-center gap-x-1">
          <div className="text-on_surface font_headline_number_2xl">
            {rate.toFixed(0)}
            <span className="font_headline_number_xl">{unit}</span>
          </div>

          <div className="absolute top-0 -right-[3.125rem] h-full flex items-center">
            <PercentButtonGroup
              xFull={false}
              color={color}
              excludedValues={[50]}
              selectedValue={rate}
              onSelect={(percent) => onChange?.(percent)}
              disabled={disabled}
            />
          </div>
        </div>

        {captionNumber && <div className="font_caption_number_l text-on_surface_variant_dark">({captionNumber})</div>}
      </div>

      <Ranger color={color} value={rate} disabled={disabled} onChange={(rate) => onChange?.(rate)} />
    </div>
  );
};

export default TextedRanger;
