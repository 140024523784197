import IconButton from 'components/buttons/IconButton';
import { useState } from 'react';
import faqBgUrl from 'resources/images/faq_mooncat_bg.png';
import faqBgMobileUrl from 'resources/images/faq_sparkles_bg_mobile.png';
import faqMooncatMobileUrl from 'resources/images/faq_mooncat.png';

type FAQWidgetProps = {
  title: string;
  faqList: { question: string; answers: string[] }[];
  className?: string;
};

const FAQWidget = ({ title, faqList, className = '' }: FAQWidgetProps) => {
  return (
    <section className={`${className} relative`}>
      <FAQBackgroundImg className="!absolute top-0 md:top-12 left-0 right-0 w-screen md:w-[1150px]" />

      <div className="relative px-5 pt-5 pb-5 md:px-10 md:pt-14 md:pb-40 flex flex-col items-stretch md:items-center gap-y-6 md:gap-y-10">
        <h3 className="text-left md:text-center text-on_surface font_display_xs_mobile md:font_display_s">{title}</h3>

        <div className="md:max-w-[37.5rem] space-y-5">
          {faqList.map((faq, index) => (
            <FQAItem
              key={index}
              question={faq.question}
              answers={faq.answers}
              // defaultIsOpen={index === 0}
            />
          ))}
        </div>

        <img aria-hidden="true" src={faqMooncatMobileUrl} alt="" className="md:hidden w-full pointer-events-none" />
      </div>
    </section>
  );
};

export default FAQWidget;

function FAQBackgroundImg({ className }: { className?: string }) {
  const BG_CLASSNAME = 'absolute top-0 bottom-0 left-0 right-0 bg-contain bg-no-repeat bg-center pointer-events-none';

  return (
    <div aria-hidden="true" className={`${className} relative h-0 pb-[200%] md:pb-[467px]`}>
      <div className={`hidden md:block ${BG_CLASSNAME}`} style={{ backgroundImage: `url(${faqBgUrl})` }}></div>
      <div className={`block md:hidden ${BG_CLASSNAME}`} style={{ backgroundImage: `url(${faqBgMobileUrl})` }}></div>
    </div>
  );
}

function FQAItem({
  question,
  answers,
  defaultIsOpen = false,
}: {
  question: string;
  answers: string[];
  defaultIsOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  return (
    <dl className="text-left space-y-1 md:space-y-3">
      <div className="flex justify-between items-center gap-x-2 py-2">
        {/* md:font_headline_s */}
        <dt className="text-on_surface font_title_s md:font_title_l">{question}</dt>
        <div className="grow-0 shrink-0 text-on_surface_variant_dark">
          <IconButton size="lg" iconType={isOpen ? 'expandless' : 'expandmore'} onClick={() => setIsOpen(!isOpen)} />
        </div>
      </div>

      <div
        className={`space-y-2 md:px-4 text-on_surface_variant_dark font_body_s ${
          isOpen ? 'transition-all opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        {answers.map((answer, index) => (
          <dd key={index}>{answer}</dd>
        ))}
      </div>
    </dl>
  );
}
