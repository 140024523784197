import { IS_IBC_ENABLED, TOOLTIP_TEXTS } from 'COMMON_VARIABLES';
import Button from 'components/buttons/Button';
import ViewAllButton from 'components/buttons/ViewAllButton';
import Panel from 'components/panel/Panel';
import PanelAmount from 'components/panel/PanelAmount';
import PanelLabel from 'components/panel/PanelLabel';
import TokensTable from 'components/portfolio/TokensTable';
import LoadingRows from 'components/skeletons/LoadingRows';
import H3 from 'components/texts/H3';
import useMyAssets from 'hooks/portfolio/useMyAssets';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { AssetDetail } from 'types/asset';

type MyTokensProps = {
  assets: AssetDetail[];
  onTokenClick?: (pool: AssetDetail) => void;
  onTransferClick: () => void;
  /** it determines if localStorage flow will be used */
  localStorageKeyPrefix?: string;
};

const MyTokens = ({ assets, onTokenClick, onTransferClick, localStorageKeyPrefix }: MyTokensProps) => {
  const isLoading = false;

  const { myAssets, totalUSD } = useMyAssets(assets);

  const storedIsDetailsOpen = useMemo<boolean>(() => {
    const localData = localStorage.getItem(`${localStorageKeyPrefix}-my-tokens-open`);
    return localData === 'true';
  }, [localStorageKeyPrefix]);

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(storedIsDetailsOpen);

  const onClickViewAll = useCallback(() => {
    setIsDetailsOpen(!isDetailsOpen);

    if (localStorageKeyPrefix) localStorage.setItem(`${localStorageKeyPrefix}-my-tokens-open`, `${!isDetailsOpen}`);
  }, [isDetailsOpen, localStorageKeyPrefix]);

  /** ibc tx */
  const [isSigning, setIsSigning] = useState(false);
  useEffect(() => setIsSigning(false), []);

  const store = useMainStore();

  return (
    <div className="space-y-3 md:space-y-6">
      <H3>Tokens</H3>

      <div className="w-full flex flex-col items-center">
        <div className="w-full flex flex-col md:flex-row gap-x-6 gap-y-3">
          <Panel
            type="outline-medium"
            padding={store.isMobile ? '16px' : '24px'}
            className="grow-0 shrink-0 basis-[21%]"
          >
            <PanelAmount label="Total" isLoading={isLoading} amount={totalUSD} symbol="$" size="md" />
          </Panel>

          <Panel padding={store.isMobile ? '16px' : '24px'}>
            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-x-4 gap-y-5 md:px-1">
              <div className="w-full grow shrink flex flex-col md:flex-row gap-x-14 gap-y-7">
                <div className="text-left space-y-3">
                  <PanelLabel label="Holdings" size="lg" />
                  {isLoading ? (
                    <LoadingRows
                      rowsCnt={1}
                      loadingDataTypoClassName="font_headline_number_l_mobile md:font_headline_number_l"
                    />
                  ) : (
                    <span className="inline-block align-baseline space-x-1 text-on_surface_variant_light">
                      <span className="align-baseline space-x-0.5 font_headline_number_m_mobile md:font_headline_number_m !leading-[2rem]">
                        <span
                          className={`font_headline_number_l_mobile md:font_headline_number_l ${
                            myAssets.length > 0 ? 'text-primary' : 'text-semantic_gray'
                          }`}
                        >
                          {myAssets.length}
                        </span>
                        <span>/{assets.length}</span>
                      </span>

                      <span className="font_caption_m !leading-[1.625rem]">Tokens</span>
                    </span>
                  )}
                </div>
              </div>

              <Button
                label="Transfer"
                status={!IS_IBC_ENABLED ? 'disabled' : isSigning ? 'loading' : 'enabled'}
                onClick={onTransferClick}
                className="w-full md:w-auto grow-0 shrink-0"
              />
            </div>
          </Panel>
        </div>

        {/* view details button */}
        <ViewAllButton
          openLabel="View Details"
          closeLabel="Close Details"
          ping={false}
          disabled={assets.length === 0}
          tooltipContent={assets.length === 0 ? TOOLTIP_TEXTS.NO_DETAILS_TO_VIEW : undefined}
          isOpen={isDetailsOpen}
          onClick={onClickViewAll}
          // for screen readers
          className="order-1 mt-6"
        />

        {/* all assets */}
        <div
          aria-hidden={!isDetailsOpen}
          className={`w-full space-y-4 transition-all ${
            isDetailsOpen ? 'opacity-100 mt-[2.25rem] md:mt-[3.5rem]' : 'max-h-0 overflow-hidden opacity-0 mt-0'
          }`}
        >
          <TokensTable assets={assets} onTokenClick={onTokenClick} localStorageKeyPrefix="my-tokens" />
        </div>
      </div>
    </div>
  );
};

export default MyTokens;
