import { detectBridgeAsset, getImageURL } from 'common/utils';
import { useCallback, useState } from 'react';
import type { InfoAsset } from 'types/asset';
import BridgeBadge from './BridgeBadge';
import { CoinColor, CoinSize } from './constans';
import { COIN_BG_COLOR_FALLBACK_DICT } from './constants';

type CoinProps = {
  color?: CoinColor;
  asset: InfoAsset;
  size?: CoinSize;
  onImgLoad?: () => void;
  className?: string;
};

const Coin = ({ color, asset, size = '24px', onImgLoad, className }: CoinProps) => {
  const [isImgLoaded, setIsImgLoaded] = useState<boolean>(true);

  const onLoad = useCallback<React.ReactEventHandler<HTMLImageElement>>(() => {
    onImgLoad?.();
    setIsImgLoaded(true);
  }, [onImgLoad]);

  const bridgeInfo = detectBridgeAsset(asset.ticker);

  return (
    <div className={`rounded-full ${className}`}>
      <div className={`relative rounded-full transition-opacity ${isImgLoaded ? '' : 'opacity-0'}`}>
        <div
          className="flex justify-center items-center rounded-full overflow-hidden"
          style={{ backgroundColor: COIN_BG_COLOR_FALLBACK_DICT[asset.ticker], width: size, height: size }}
        >
          <img src={getImageURL(asset.logoUrl)} className="object-cover" alt={asset.ticker} onLoad={onLoad} />
        </div>

        {bridgeInfo.isBridged && (
          <BridgeBadge
            color={color}
            parentSize={size}
            bridgeTicker={bridgeInfo.bridge}
            className="absolute -right-[1px] -bottom-[1px]"
          />
        )}
      </div>
    </div>
  );
};

export default Coin;
