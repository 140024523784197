import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import NumberText from 'components/texts/NumberText';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import { ProposalResult } from 'types/gov';

/** @desc VotingTurnoutFlag */
const VOTING_COLOR_DICT: { [key in keyof ProposalResult]: { flag: string; text: string } } = {
  yes: { flag: 'bg-gov_yes', text: 'text-gov_yes' },
  no: { flag: 'bg-gov_no', text: 'text-gov_no' },
  no_with_veto: { flag: 'bg-gov_nowithveto', text: 'text-gov_nowithveto' },
  abstain: { flag: 'bg-gov_abstain', text: 'text-gov_abstain' },
};

const VOTING_LABEL_DICT: { [key in keyof ProposalResult]: string } = {
  yes: 'Yes',
  no: 'No',
  no_with_veto: 'NoWithVeto',
  abstain: 'Abstain',
};

export type MobileColsCnt = '2' | '1';

const MOBILE_FLAGS_GRID_DICT: { [key in MobileColsCnt]: string } = {
  '2': 'grid grid-cols-2 gap-2',
  '1': 'grid grid-cols-1 gap-2',
};

const VotingTurnoutFlag = ({
  option,
  turnout,
  balance,
  balanceUnit,
  align = 'start',
}: {
  option: keyof ProposalResult;
  turnout: BigNumber;
  balance: BigNumber;
  balanceUnit: string;
  align?: 'start' | 'between';
}) => {
  const store = useMainStore();

  return (
    <div className={`grow shrink w-full flex items-stretch gap-x-3 truncate`}>
      <div className={`grow-0 shrink-0 w-1 h-full ${VOTING_COLOR_DICT[option].flag}`}></div>

      <div
        className={`w-full flex ${
          align === 'between' ? 'flex-row justify-between items-center gap-x-3' : 'flex-col gap-y-2'
        }`}
      >
        <div className={`font_title_xs md:font_title_s ${VOTING_COLOR_DICT[option].text}`}>
          {VOTING_LABEL_DICT[option]}
        </div>

        <div className={`flex flex-col gap-y-1 ${align === 'between' ? 'items-end' : ''}`}>
          <NumberText size={store.isMobile ? 'xs' : 'sm'} unit="%" xsDecimal={true} value={formatAmount(turnout, 4)} />
          <NumberText
            size={store.isMobile ? 'xs' : 'sm'}
            unit={balanceUnit}
            xsDecimal={true}
            value={balance.div(10 ** 6).toFormat()}
          />
        </div>
      </div>
    </div>
  );
};

/** @desc VotingTurnoutFlags */
const VotingTurnoutFlags = ({ data, mobileColsCnt = '2' }: { data: ProposalResult; mobileColsCnt?: MobileColsCnt }) => {
  const balance = useMemo<{ [key in keyof ProposalResult | 'total']: BigNumber }>(() => {
    const yes = new BigNumber(data.yes);
    const no = new BigNumber(data.no);
    const no_with_veto = new BigNumber(data.no_with_veto);
    const abstain = new BigNumber(data.abstain);

    let total = yes.plus(no).plus(no_with_veto).plus(abstain);
    if (!total.gt(0)) {
      total = new BigNumber(1);
    }

    return {
      yes,
      no,
      no_with_veto,
      abstain,
      total,
    };
  }, [data]);

  const store = useMainStore();
  const flagAlign = useMemo(
    () => (store.isMobile && mobileColsCnt === '1' ? 'between' : 'start'),
    [store.isMobile, mobileColsCnt]
  );

  return (
    <div className={`${MOBILE_FLAGS_GRID_DICT[mobileColsCnt]} md:flex md:items-stretch md:justify-between md:gap-x-3`}>
      <VotingTurnoutFlag
        option="yes"
        balance={balance.yes}
        balanceUnit="CRE"
        turnout={balance.yes.div(balance.total).multipliedBy(100)}
        align={flagAlign}
      />
      <VotingTurnoutFlag
        option="no"
        balance={balance.no}
        balanceUnit="CRE"
        turnout={balance.no.div(balance.total).multipliedBy(100)}
        align={flagAlign}
      />
      <VotingTurnoutFlag
        option="no_with_veto"
        balance={balance.no_with_veto}
        balanceUnit="CRE"
        turnout={balance.no_with_veto.div(balance.total).multipliedBy(100)}
        align={flagAlign}
      />
      <VotingTurnoutFlag
        option="abstain"
        balance={balance.abstain}
        balanceUnit="CRE"
        turnout={balance.abstain.div(balance.total).multipliedBy(100)}
        align={flagAlign}
      />
    </div>
  );
};

export default VotingTurnoutFlags;
