import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import TooltipIcon from 'components/tooltips/TooltipIcon';

type AprTextProps = {
  apr: BigNumber;
  fromApr?: BigNumber;
  aprAdditional?: BigNumber;
  aprAdditionalCaption?: string;
  tooltipContent?: string | JSX.Element;
};

const AprText = ({ apr, fromApr, aprAdditional, aprAdditionalCaption, tooltipContent }: AprTextProps) => {
  return (
    <div className="relative flex items-center gap-x-0">
      <div
        className={`flex items-center gap-x-1 md:flex-col md:items-end md:gap-y-0.5 ${!apr.gt(0) ? 'opacity-40' : ''}`}
      >
        <div className="text-white font_data_number_m">
          {fromApr && !fromApr.eq(apr) && <span>{formatAmount(fromApr, 1, { fixMantissa: true })}%</span>}
          {fromApr && !fromApr.eq(apr) && <span> ~ </span>}
          <span>{formatAmount(apr, 1, { fixMantissa: true })}%</span>
        </div>

        {aprAdditional?.gt(0) ? (
          <div className="space-x-1 text-primary font_data_number_xs">
            <span>+</span>
            <span>{formatAmount(aprAdditional, 1)}%</span>
          </div>
        ) : (
          aprAdditionalCaption && (
            <div className="space-x-1 text-primary font_data_xs">
              <span>+</span>
              <span>{aprAdditionalCaption}</span>
            </div>
          )
        )}
      </div>

      {tooltipContent && apr.gt(0) && (
        <div className="md:absolute top-0 -right-[1.5rem] h-full flex items-center">
          <TooltipIcon iconType="sparkles" tooltipSize="300px" tooltipContent={tooltipContent} />
        </div>
      )}
    </div>
  );
};

export default AprText;
