import BigNumber from 'bignumber.js';
import { ButtonStatus } from 'components/buttons/Button';
import { useMemo } from 'react';

const useFarmButton = ({
  isLoading,
  amount,
  minAmount = new BigNumber(0),
  resultAmount,
  balance,
  activeLabel,
  noBalanceLabel = 'No pool token',
  selectAmountLabel = 'Select amount',
  selectSufficientAmountLabel = 'Select sufficient amount',
}: {
  isLoading: boolean;
  balance: BigNumber;
  amount: BigNumber;
  minAmount?: BigNumber;
  resultAmount?: BigNumber;
  activeLabel: string;
  noBalanceLabel?: string;
  selectAmountLabel?: string;
  selectSufficientAmountLabel?: string;
}) => {
  return useMemo<{
    text: string;
    status: ButtonStatus
  }>(() => {
    if (isLoading) {
      return {
        status: 'loading',
        text: '',
      };
    }

    if (!balance.gt(0)) {
      return {
        status: 'disabled',
        text: noBalanceLabel,
      };
    }

    /** @summary this is the safeguard in case the amount exceeds caused by UI modification */
    if (amount.gt(balance)) {
      return {
        status: 'disabled',
        text: 'Insufficient balance',
      };
    }

    if (!amount.gt(0)) {
      return {
        status: 'disabled',
        text: selectAmountLabel,
      };
    }

    if (amount.lt(minAmount)) {
      return {
        status: 'disabled',
        text: selectSufficientAmountLabel,
      };
    }

    /** @summary exption when lf minting amount would be 0 or less than the least in decimal places */
    if (resultAmount !== undefined && resultAmount.isZero()) {
      return {
        status: 'disabled',
        text: 'Not available',
      };
    }

    if (resultAmount !== undefined && resultAmount.lt(0.000000000001)) {
      return {
        status: 'disabled',
        text: selectSufficientAmountLabel,
      };
    }

    return {
      status: 'enabled',
      text: activeLabel,
    };
  }, [
    isLoading,
    amount,
    minAmount,
    resultAmount,
    balance,
    activeLabel,
    noBalanceLabel,
    selectAmountLabel,
    selectSufficientAmountLabel,
  ]);
};

export default useFarmButton;
