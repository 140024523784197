import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import { MAX_DECIMAL } from 'COMMON_VARIABLES';
import Coin from 'components/coins/Coin';
import NumberText from 'components/texts/NumberText';
import type { InfoAsset } from 'types/asset';
import type { PoolDetail } from 'types/pool';

const AssetAmount = ({ asset, amount }: { asset: InfoAsset; amount: BigNumber }) => {
  return (
    <div className="flex items-center gap-x-2">
      <Coin size="16px" asset={asset} />
      <NumberText
        size="sm"
        color="plain-mono"
        value={formatAmount(amount, Math.min(asset.exponent, MAX_DECIMAL))}
        unit={asset.ticker}
      />
    </div>
  );
};

const UnderlyingAssets = ({ pool, assetsAmount }: { pool: PoolDetail; assetsAmount: [BigNumber, BigNumber] }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <AssetAmount asset={pool.assets[0]} amount={assetsAmount[0]} />
      <AssetAmount asset={pool.assets[1]} amount={assetsAmount[1]} />
    </div>
  );
};

export default UnderlyingAssets;
