import BigNumber from 'bignumber.js';
import { formatUSD } from 'common/utils';
import { TokenTypes } from 'components/constants/token';
import { useCallback } from 'react';
import type { MainStore } from 'store/store';
import type { InfoAssetWithMyAmount, AssetDetail } from 'types/asset';

const useAmountUSD = (store: MainStore) => {
  /**
   * @summary pool's priceOracle must be de-exponentized, since pool priceOracle already has exponent calculated.
   * @todo pool-related token's priceOracle could be pre-defined in store.
   * @todo lfTokenPriceOracle is TBD; receiveAmountPerLfToken * poolTokenPriceOracle atm.
   */
  const getPriceOracle = useCallback(
    (asset: InfoAssetWithMyAmount | AssetDetail): BigNumber => {
      const poolTokenPriceOracle: BigNumber = new BigNumber(10 ** asset.exponent).multipliedBy(
        asset.originPool?.priceOracle ?? 0
      );
      const lfTokenPriceOracle: BigNumber =
        store
          .getLiquidFarmByLfDenom(asset.denom)
          ?.receiveAmountPerLfToken?.dp(12, BigNumber.ROUND_DOWN)
          .multipliedBy(poolTokenPriceOracle) ?? new BigNumber(0);
      const priceOracle: BigNumber = asset.priceOracle;

      const priceOracles: { [key in TokenTypes]: BigNumber } = {
        [TokenTypes.NATIVE]: priceOracle,
        [TokenTypes.IBC]: priceOracle,
        [TokenTypes.BRIDGED]: priceOracle,
        [TokenTypes.LF]: lfTokenPriceOracle,
        [TokenTypes.POOL]: poolTokenPriceOracle,
      };

      return priceOracles[asset.tokenType];
    },
    [store]
  );

  const getAmountUSDRaw = useCallback(
    (asset: InfoAssetWithMyAmount | AssetDetail, amount: BigNumber): BigNumber => {
      return amount.multipliedBy(getPriceOracle(asset));
    },
    [getPriceOracle]
  );

  const getAmountUSD = useCallback(
    (asset: InfoAssetWithMyAmount | AssetDetail, amount: BigNumber, symbol = '$'): string => {
      return formatUSD(getAmountUSDRaw(asset, amount), { symbol });
    },
    [getAmountUSDRaw]
  );

  return {
    getPriceOracle,
    getAmountUSDRaw,
    getAmountUSD,
  };
};

export default useAmountUSD;
