import BigNumber from 'bignumber.js';
import { convertExponent } from 'common/utils';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Orderbook, OrderbookData } from 'types/orderbook';

type UseTickPrecision = {
  pairOrderbook: Orderbook | null;
  exponentDiff: number;
};

const useTickPrecision = ({ pairOrderbook, exponentDiff }: UseTickPrecision) => {
  const pairId = useMemo<number | undefined>(() => pairOrderbook?.pairId, [pairOrderbook]);

  const orderbooks = useMemo<OrderbookData['order_books']>(
    () => pairOrderbook?.orderbook?.order_books ?? [],
    [pairOrderbook]
  );

  /**
   *
   * @returns precisions for selecting (displayValue: 0.001, 0.0001, etc.)
   */
  const precisions = useMemo<ReadonlyArray<{ precision: number; displayValue: string }>>(() => {
    return orderbooks.map((ob) => {
      const priceUnit = convertExponent(parseFloat(ob.price_unit));

      return {
        precision: priceUnit.length,
        displayValue: `${convertExponent(new BigNumber(priceUnit).shiftedBy(exponentDiff).toNumber())}`,
      };
    });
  }, [orderbooks, exponentDiff]);

  const getPrecisionDisplayValue = useCallback(
    (precision: number) => {
      return precisions.find((p) => p.precision === precision)?.displayValue ?? '';
    },
    [pairId]
  );

  const [tickPrecision, setTickPrecision] = useState<number>(0);

  useEffect(() => {
    const newPrecision = precisions.length > 2 ? precisions[1].precision : precisions[0]?.precision ?? 0;
    setTickPrecision(newPrecision);
  }, [pairId]);

  const store = useMainStore();

  const changeTickPrecision = useCallback(
    (precision: number) => {
      // sanity check
      if (precision <= 0 || precisions.findIndex((p) => p.precision === precision) === -1) {
        return;
      }

      setTickPrecision(precision);
      store.setTickPrecision(precision);
    },
    [pairId]
  );

  const tickPrecisionDisplayValue = useMemo<string>(
    () => getPrecisionDisplayValue(tickPrecision),
    [tickPrecision, getPrecisionDisplayValue]
  );

  return {
    precisions,
    getPrecisionDisplayValue,
    tickPrecision,
    tickPrecisionDisplayValue,
    changeTickPrecision,
  };
};

export default useTickPrecision;
