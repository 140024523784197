import { TokenTypes } from 'components/constants/token';
import type { AssetDetail } from 'types/asset';
import Coin from './Coin';
import CoinPair from './CoinPair';
import { CoinSize } from './constans';

type TokenProps = { asset: AssetDetail; size?: CoinSize };

const Token = ({ asset, size }: TokenProps) => {
  if (asset.tokenType === TokenTypes.POOL && asset.originPool)
    return <CoinPair assets={asset.originPool.assets} size={size} />;

  if (asset.tokenType === TokenTypes.LF && asset.originPool)
    return <CoinPair assets={asset.originPool.assets} size={size} color="liquid" />;

  return <Coin asset={asset} size={size} />;
};

export default Token;
