import DepositVotingBar from './DepositVotingBar';
import { Proposal } from 'types/gov';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { useMainStore } from 'provider/mainContext';
import { INITIAL_ASSET } from 'COMMON_VARIABLES';
import ProposalIndex from './ProposalIndex';
import NumberText from 'components/texts/NumberText';

const ProposalDepositVisualizer = ({ proposal }: { proposal: Proposal }) => {
  const mainStore = useMainStore();
  const totalDepositAmount = useMemo(() => {
    return proposal.proposal.total_deposit
      .reduce((arr, cur) => arr.plus(cur.amount), new BigNumber(0))
      .dividedBy(10 ** 6);
  }, [proposal]);

  const minDeposit = useMemo(() => {
    let amount = mainStore.paramsData.info['deposit.min_deposit']?.replace('ucre', '');
    amount = new BigNumber(amount).dividedBy(10 ** INITIAL_ASSET.exponent);
    return amount;
  }, [mainStore.paramsData]);

  return (
    <div className="pt-2 pb-4 space-y-3">
      <div className="flex flex-col items-stretch justify-between gap-y-1 gap-x-3 md:flex-row md:items-center">
        <ProposalIndex
          label="Current deposit"
          value={<NumberText size="sm" unit="CRE" value={totalDepositAmount.toFormat()} xsDecimal={true} />}
        />
        <div className="-order-1 md:order-1">
          <ProposalIndex
            label="Required deposit"
            labelColor={mainStore.isMobile ? 'dark' : 'light'}
            value={<NumberText size="sm" unit="CRE" xsDecimal={true} value={minDeposit.toFormat()} />}
          />
        </div>
      </div>

      <DepositVotingBar percent={totalDepositAmount.dividedBy(minDeposit).multipliedBy(100).toFixed(2)} />
    </div>
  );
};

export default ProposalDepositVisualizer;
