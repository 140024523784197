import { EncodeObject } from '@cosmjs/proto-signing';
import { MsgLimitOrder } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import BigNumber from 'bignumber.js';
import msgEncoder from 'common/sign/msgEncoder';
import createClaimMsg from './Claim';

const createOrderbookMsg = (txData: any, creAddress: any) => {
  const offerDenom = txData.offer.denom;
  const demandDenom = txData.demand.denom;
  const offerAmount = txData.offer.amount;
  const orderDirection = txData.orderDirection;
  const limitOrderPrice = txData.orderPrice;
  const limitOrderData: MsgLimitOrder = {
    orderer: creAddress,
    pairId: txData.pairId,
    direction: orderDirection,
    offerCoin: {
      denom: offerDenom,
      amount: offerAmount,
    },
    demandCoinDenom: demandDenom,
    price: new BigNumber(limitOrderPrice)
      .shiftedBy(18)
      .decimalPlaces(0, orderDirection === 1 ? BigNumber.ROUND_FLOOR : BigNumber.ROUND_CEIL)
      .toString(),
    amount: txData.baseAmount,
    orderLifespan: { seconds: txData.orderLifespanSeconds, nanos: 0 },
  };
  const msg = msgEncoder.msgLimitOrder(limitOrderData);
  const msgs: EncodeObject[] = [msg];

  return msgs;
};

export default createOrderbookMsg;
