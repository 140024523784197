const CoinGeckoIconSvg = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.16 8.01614C14.16 4.5779 11.3961 1.81762 7.96945 1.81762C4.54283 1.80147 1.79507 4.5779 1.79507 8C1.79507 9.29392 2.19864 10.4923 2.88169 11.4828C3.29637 10.5423 3.45261 9.55014 3.53963 8.54065C3.54663 8.44611 3.55294 8.35157 3.55924 8.25702C3.58445 7.87886 3.60966 7.5007 3.67969 7.12253C3.76723 6.5798 3.97732 6.08959 4.36249 5.6694C4.71264 5.30174 5.15033 5.05664 5.62303 4.88156C6.0082 4.72399 6.41088 4.58393 6.84857 4.56643C7.24789 4.54906 7.63001 4.60057 8.01197 4.6868C8.37725 4.58351 8.75954 4.54999 9.12457 4.68905C9.39498 4.79045 9.60013 4.97343 9.69278 5.25373L9.71981 5.26673C10.1575 5.4418 10.6127 5.61688 11.0679 5.75694C11.5406 5.897 12.0133 6.05457 12.416 6.3522C12.9237 6.73737 13.0638 7.22758 12.8362 7.82284C12.6961 8.1905 12.4335 8.47062 12.1709 8.75074C11.9818 8.94682 11.7871 9.1373 11.5924 9.32778C11.3004 9.61349 11.0084 9.89925 10.7353 10.2039C10.21 10.8166 9.73732 11.4469 9.40467 12.1822C9.12582 12.7922 8.96841 13.4196 9.07063 14.0816C11.9653 13.5537 14.16 11.0193 14.16 8.01614Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88565 6.47475C5.88565 5.82697 6.42838 5.28424 7.09367 5.28424C7.75896 5.28424 8.28419 5.80946 8.28419 6.47475C8.26668 7.14004 7.74145 7.68278 7.07616 7.68278C6.42838 7.66527 5.88565 7.14004 5.88565 6.47475ZM7.93381 6.49231C7.93381 6.94751 7.56615 7.33267 7.11095 7.33267H7.09344C6.63825 7.33267 6.25308 6.96501 6.25308 6.49231V6.4748C6.25308 6.0021 6.63825 5.63444 7.09344 5.63444C7.54864 5.63444 7.93381 6.0196 7.93381 6.49231Z"
        fill="currentColor"
      />
      <path
        d="M8.59932 9.32849C8.37173 9.25846 8.17914 9.1184 8.0741 8.96083C8.5293 9.24095 9.03702 9.27597 9.54474 9.24095C10.245 9.20594 10.9103 9.03086 11.5406 8.76825C11.7857 8.6632 12.0308 8.55816 12.2584 8.4181C12.2759 8.40059 12.2934 8.38308 12.3284 8.40059C12.3171 8.44594 12.2837 8.46192 12.2521 8.47707C12.2349 8.48531 12.2182 8.4933 12.2059 8.50563C11.5231 8.96083 10.7703 9.24095 9.96492 9.39852C9.50972 9.48606 9.05452 9.48606 8.59932 9.32849Z"
        fill="currentColor"
      />
      <path
        d="M11.6456 7.64776C11.6456 7.80533 11.5231 7.92788 11.383 7.92788C11.2255 7.92788 11.1204 7.82284 11.1204 7.66527C11.1204 7.52521 11.2255 7.40266 11.3655 7.40266C11.5231 7.38515 11.6456 7.5077 11.6456 7.64776Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CoinGeckoIconSvg;
