import { formatAmount } from 'common/utils';
import Coin from 'components/coins/Coin';
import CoinPair from 'components/coins/CoinPair';
import PairTicker from 'components/pools/PairTicker';
import PoolId from 'components/pools/PoolId';
import NumberText from 'components/texts/NumberText';
import type { CoinDetail } from 'types/asset';
import type { PoolDetail } from 'types/pool';

type RewardCoinsByPoolProps = { pool: PoolDetail; rewardCoins: CoinDetail[] };

const RewardCoinsByPool = ({ pool, rewardCoins }: RewardCoinsByPoolProps) => {
  return (
    <section className="space-y-2">
      {/* pool */}
      <div className="flex items-center gap-x-2">
        <CoinPair assets={pool.assets} size="16px" />

        <div className="flex items-center gap-x-1">
          <PairTicker pair={pool} size="sm" color="surface-variant" />
          <PoolId poolId={pool.poolId} hashTag={true} size="sm" />
        </div>
      </div>

      {/* rewards */}
      <div className="pl-5 space-y-1">
        {rewardCoins.map((reward, index) => (
          <div key={index} className="flex items-center gap-x-2">
            <Coin asset={reward.asset} size="16px" />
            <NumberText value={formatAmount(reward.amount, reward.asset.exponent)} unit={reward.asset.ticker} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default RewardCoinsByPool;
