import {
  createDepositMsg,
  createGovDepositMsg,
  createHarvestMsg,
  createIbcWithdrawMsg,
  createLimitOrderMsg,
  createLiquidStakeMsg,
  createLiquidUnstakeMsg,
  createStakeMsg,
  createUnstakeMsg,
  createVoteMsg,
  createWithdrawMsg,
  createIbcDepositMsg,
  createOrderbookMsg,
  createMarketOrderMsg,
  createCancelOrderMsg,
  createCancelAllOrdersMsg,
  createLiquidFarmMsg,
  createLiquidUnfarmMsg,
  createLiquidUnfarmAndWithdrawMsg,
  createLpHarvestMsg,
  createFarmMsg,
  createUnfarmMsg,
} from 'common/msg';

export const createCrescentMsgs = async ({
  type,
  txData,
  creAddress,
}: {
  type: string;
  txData: any;
  creAddress: string;
}) => {
  let msgs: any;
  ///////////////////////
  // LIQUIDITY ACTIONS //
  ///////////////////////
  if (type === 'orderbook') {
    msgs = await createOrderbookMsg(txData, creAddress);
  } else if (type === 'marketOrder') {
    msgs = await createMarketOrderMsg(txData, creAddress);
  } else if (type === 'swap') {
    msgs = await createLimitOrderMsg(txData, creAddress);
  } else if (type === 'poolDeposit') {
    msgs = await createDepositMsg(txData, creAddress);
  } else if (type === 'poolWithdraw') {
    msgs = await createWithdrawMsg(txData, creAddress);
  } else if (type === 'cancelOrder') {
    msgs = await createCancelOrderMsg(txData, creAddress);
  } else if (type === 'cancelAllOrders') {
    msgs = await createCancelAllOrdersMsg(txData, creAddress);
  }

  ///////////////////////
  /// FARMING ACTIONS ///
  ///////////////////////
  if (type === 'farm') {
    msgs = createStakeMsg(txData, creAddress);
  } else if (type === 'unfarm') {
    msgs = createUnstakeMsg(txData, creAddress);
  } else if (type === 'claim') {
    msgs = createHarvestMsg(txData, creAddress);
  }

  /** FARMING (NEW) */
  if (type === 'lpFarm') {
    msgs = createFarmMsg(txData, creAddress);
  } else if (type === 'lpUnfarm') {
    msgs = createUnfarmMsg(txData, creAddress);
  } else if (type === 'harvest') {
    msgs = createLpHarvestMsg(txData, creAddress);
  }

  /** LIQUID FARMING */
  if (type === 'liquidFarm') {
    msgs = createLiquidFarmMsg(txData, creAddress);
  } else if (type === 'liquidUnfarm') {
    msgs = createLiquidUnfarmMsg(txData, creAddress);
  } else if (type === 'liquidUnfarmAndWithdraw') {
    msgs = createLiquidUnfarmAndWithdrawMsg(txData, creAddress);
  }

  //////////////////////////////
  /// LIQUID STAKING ACTIONS ///
  //////////////////////////////

  if (type === 'staking') {
    msgs = createLiquidStakeMsg(txData, creAddress);
  } else if (type === 'unstaking') {
    msgs = createLiquidUnstakeMsg(txData, creAddress);
  }

  if (type === 'vote') {
    msgs = createVoteMsg(txData, creAddress);
  } else if (type === 'govDeposit') {
    msgs = createGovDepositMsg(txData, creAddress);
  }
  if (!msgs) {
    alert('No messages found for this transaction type');
  }
  return msgs;
};
