type SparklesIconSvgProps = { className?: string };

const SparklesIconSvg = ({ className = '' }: SparklesIconSvgProps) => {
  return (
    <svg
      className={className}
      style={{
        transform: 'scale(1.29)',
        transformOrigin: 'left center',
      }}
      width="31"
      height="35"
      viewBox="0 0 31 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_376_247303)">
        <path
          d="M12.9427 15.6964C14.4722 13.9833 14.9998 8.40039 14.9998 8.40039C14.9998 8.40039 15.5274 13.9833 17.0569 15.6964C18.5865 17.4095 22.1998 18.0004 22.1998 18.0004C22.1998 18.0004 18.5865 18.5913 17.0569 20.3044C15.5274 22.0175 14.9998 27.6004 14.9998 27.6004C14.9998 27.6004 14.4722 22.0175 12.9427 20.3044C11.4131 18.5913 7.7998 18.0004 7.7998 18.0004C7.7998 18.0004 11.4131 17.4095 12.9427 15.6964Z"
          fill="#FFBC65"
        />
        <path
          d="M20.3144 9.9362C20.8242 9.29378 21.0001 7.2002 21.0001 7.2002C21.0001 7.2002 21.176 9.29378 21.6858 9.9362C22.1957 10.5786 23.4001 10.8002 23.4001 10.8002C23.4001 10.8002 22.1957 11.0218 21.6858 11.6642C21.176 12.3066 21.0001 14.4002 21.0001 14.4002C21.0001 14.4002 20.8242 12.3066 20.3144 11.6642C19.8045 11.0218 18.6001 10.8002 18.6001 10.8002C18.6001 10.8002 19.8045 10.5786 20.3144 9.9362Z"
          fill="#FFBC65"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_376_247303"
          x="-4"
          y="-1"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.337255 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_376_247303" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_376_247303" result="shape" />
        </filter>
      </defs>
    </svg>

    // <svg
    //   className={className}
    //   width="31"
    //   height="35"
    //   viewBox="0 0 31 35"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g filter="url(#filter0_d_181_156307)">
    //     <path
    //       d="M12.9427 16.0089C14.4722 14.2958 14.9998 8.71289 14.9998 8.71289C14.9998 8.71289 15.5274 14.2958 17.0569 16.0089C18.5865 17.722 22.1998 18.3129 22.1998 18.3129C22.1998 18.3129 18.5865 18.9038 17.0569 20.6169C15.5274 22.33 14.9998 27.9129 14.9998 27.9129C14.9998 27.9129 14.4722 22.33 12.9427 20.6169C11.4131 18.9038 7.7998 18.3129 7.7998 18.3129C7.7998 18.3129 11.4131 17.722 12.9427 16.0089Z"
    //       fill="#FFBC65"
    //       // fill="currentColor"
    //     />
    //     <path
    //       d="M20.3144 10.2487C20.8242 9.60628 21.0001 7.5127 21.0001 7.5127C21.0001 7.5127 21.176 9.60628 21.6858 10.2487C22.1957 10.8911 23.4001 11.1127 23.4001 11.1127C23.4001 11.1127 22.1957 11.3343 21.6858 11.9767C21.176 12.6191 21.0001 14.7127 21.0001 14.7127C21.0001 14.7127 20.8242 12.6191 20.3144 11.9767C19.8045 11.3343 18.6001 11.1127 18.6001 11.1127C18.6001 11.1127 19.8045 10.8911 20.3144 10.2487Z"
    //       fill="#FFBC65"
    //       // fill="currentColor"
    //     />
    //   </g>
    //   <defs>
    //     <filter
    //       id="filter0_d_181_156307"
    //       x="-4"
    //       y="-0.6875"
    //       width="38"
    //       height="38"
    //       filterUnits="userSpaceOnUse"
    //       // color-interpolation-filters="sRGB"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       {/* <feFlood flood-opacity="0" result="BackgroundImageFix" /> */}
    //       <feFlood floodOpacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset />
    //       <feGaussianBlur stdDeviation="3.5" />
    //       <feColorMatrix type="matrix" values="0 0 0 0 0.596078 0 0 0 0 0.337255 0 0 0 0 0 0 0 0 1 0" />
    //       <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_181_156307" />
    //       <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_181_156307" result="shape" />
    //     </filter>
    //   </defs>
    // </svg>
  );
};

export default SparklesIconSvg;
