import { useCallback, useMemo, useState } from 'react';
import { useMainStore } from 'provider/mainContext';
import { formatAmount, getExponentDiff } from 'common/utils';
import useTxClient from 'hooks/useTxClient';
import BigNumber from 'bignumber.js';
import { OpenOrder } from 'types/orderbook';
import { observer } from 'mobx-react-lite';
import Table from 'components/table/Table';
import { TableStyles } from 'components/table/styles';
import IconButton from 'components/buttons/IconButton';
import Button from 'components/buttons/Button';
import { TableField } from 'components/table/types';
import { IconButtonSize } from 'components/buttons/styles';
import { IconType } from 'components/icon';
import OrdersTablePairTitle from './OrdersTablePairTitle';

// Helpers
const toTimeString = (completeTime) => {
  let currentTime = Math.floor(new Date().getTime() / 1000);
  if (completeTime - currentTime < 0) {
    return '-';
  }
  let dif = completeTime - currentTime;
  const date = Math.floor(dif / (3600 * 24));
  let rest = dif - date * 3600 * 24;
  const hour = Math.floor(rest / 3600);
  rest = rest - hour * 3600;
  const miniute = Math.floor(rest / 60);
  return `${hour >= 10 ? hour : `0${hour}`}H ${miniute >= 10 ? miniute : `0${miniute}`}M`;
};

/** @desc OpenOrdersTable */
type OpenOrdersTableRow = {
  date: string;
  timestamp: number;
  remainingTime: string;
  pair: JSX.Element;
  pairDirectionSet: JSX.Element;
  rightEnd: JSX.Element | undefined;
  direction: JSX.Element;
  price: string;
  filled: JSX.Element;
  orderId: string;
  cancelButton: JSX.Element;
};

const MOBILE_FIELDS: TableField<OpenOrdersTableRow>[] = [
  {
    label: 'Pair',
    value: 'pairDirectionSet',
    type: 'jsx',
    sortDisabled: true,
  },
];

const MD_FIELDS: TableField<OpenOrdersTableRow>[] = [
  {
    label: 'Pair',
    value: 'pair',
    type: 'jsx',
    sortDisabled: true,
    widthRatio: 14,
  },
  {
    label: 'Direction',
    value: 'direction',
    type: 'jsx',
    sortDisabled: true,
    widthRatio: 4,
  },
];

const OpenOrdersTable = ({ openOrders, isLoading }: { openOrders: OpenOrder[]; isLoading: boolean }) => {
  const mainStore = useMainStore();

  const { signAndBroadcast } = useTxClient();

  const [isCancelAllLoading, setIsCancelAllLoading] = useState<boolean>(false);
  const [singleCancelOrderId, setSingleCancelOrderId] = useState<string>('');
  const [isSingleCanceLoading, setIsSingleCancelLoading] = useState<boolean>(false);

  // Actions
  const cancelOrder = useCallback(
    async (pairId, orderId) => {
      if (!isCancelAllLoading && !isSingleCanceLoading) {
        setSingleCancelOrderId(`${pairId}/${orderId}`);
        setIsSingleCancelLoading(true);
        await signAndBroadcast({
          type: 'cancelOrder',
          chainData: mainStore.chainsData,
          txData: {
            pairId,
            orderId,
          },
        });
        setSingleCancelOrderId('');
        setIsSingleCancelLoading(false);
      }
    },
    [isCancelAllLoading, isSingleCanceLoading, mainStore.chainsData, signAndBroadcast]
  );

  const cancelAllOrders = useCallback(async () => {
    if (openOrders?.length && !isCancelAllLoading && !isSingleCanceLoading) {
      let _temp: number[] = [];
      openOrders.forEach((order) => {
        if (!_temp.includes(order.pairId)) {
          _temp.push(order.pairId);
        }
      });
      setIsCancelAllLoading(true);
      await signAndBroadcast({
        type: 'cancelAllOrders',
        chainData: mainStore.chainsData,
        txData: {
          pairIds: _temp,
          msgCount: openOrders.length,
        },
      });
      setIsCancelAllLoading(false);
    }
  }, [isCancelAllLoading, isSingleCanceLoading, mainStore.chainsData, openOrders, signAndBroadcast]);

  const ClearAllButton = useMemo<JSX.Element>(() => {
    return (
      <Button
        type="text"
        size="xs"
        label="Clear All"
        trailingIcon="delete"
        status={isCancelAllLoading ? 'loading' : openOrders.length > 0 ? 'enabled' : 'disabled'}
        onClick={cancelAllOrders}
      />
    );
  }, [isCancelAllLoading, cancelAllOrders, openOrders.length]);

  const clearField = useMemo<TableField<OpenOrdersTableRow>>(
    () => ({
      label: ClearAllButton,
      value: 'cancelButton',
      type: 'jsx',
      sortDisabled: true,
      align: 'right',
      widthPx: 100,
    }),
    [ClearAllButton]
  );

  const tableRows = useMemo<OpenOrdersTableRow[]>(() => {
    return openOrders.map((openOrder) => {
      const isBuy = openOrder.direction === 1;
      const _pairData = mainStore?.pairsData?.info?.[openOrder.pairId];
      const _assetData = mainStore?.assetsData?.info;
      const baseInfo = _assetData?.[_pairData?.baseDenom];
      const quoteInfo = _assetData?.[_pairData?.quoteDenom];
      const exponentDiff = getExponentDiff(baseInfo.denom, quoteInfo.denom, mainStore);
      const _timestamp = new Date(new BigNumber(openOrder.timestamp).shiftedBy(-3).decimalPlaces(0, 1).toNumber());
      const _rawOrderTime = _timestamp.toLocaleString('en-us');
      const fullYear = new Date(new Date().getTime()).getFullYear();
      const date = _rawOrderTime.replace('/' + fullYear + ',', '').slice(0, -6) + ' ' + _rawOrderTime.slice(-2);

      /** @todo set iconbutton status to loading */
      const clearButtonArgs: {
        size: IconButtonSize;
        iconType: IconType;
        disabled: boolean;
        onClick: () => void;
      } = {
        size: 'sm',
        iconType: 'delete',
        disabled: singleCancelOrderId === `${openOrder.pairId}/${openOrder.reqId}`,
        onClick: () => cancelOrder(openOrder.pairId, openOrder.reqId),
      };

      const pairDirectionSet = (
        <OrdersTablePairTitle baseTicker={baseInfo?.ticker} quoteTicker={quoteInfo?.ticker} isBuy={isBuy} />
      );

      const rightEnd = mainStore.isMobile ? <IconButton type="tonal" {...clearButtonArgs} /> : undefined;

      return {
        date,
        timestamp: _timestamp.getTime(),
        remainingTime: toTimeString(openOrder.expireTimestamp),
        pair: (
          <div className="font_caption_s">
            {baseInfo?.ticker}/{quoteInfo?.ticker}
          </div>
        ),
        pairDirectionSet,
        direction: (
          <div className={`font_caption_s ${isBuy ? 'text-semantic_green' : 'text-semantic_red'}`}>
            {isBuy ? 'Buy' : 'Sell'}
          </div>
        ),
        price: formatAmount(new BigNumber(openOrder.orderPrice).shiftedBy(exponentDiff), 5),
        filled: (
          <div className="text-right">
            {formatAmount(new BigNumber(openOrder.filledBaseAmount).shiftedBy(-baseInfo?.exponent), 9)} /{' '}
            {formatAmount(
              new BigNumber(openOrder.filledBaseAmount).plus(openOrder.openBaseAmount).shiftedBy(-baseInfo?.exponent),
              9
            )}
          </div>
        ),
        orderId: `${openOrder.pairId}-${openOrder.reqId}`,
        cancelButton: <IconButton {...clearButtonArgs} />,
        rightEnd,
      };
    });
  }, [openOrders, mainStore, cancelOrder, singleCancelOrderId]);

  return (
    <div className="md:space-y-2">
      <div className="flex justify-end p-2 border-outline_soft border-b md:hidden">{ClearAllButton}</div>

      <Table<OpenOrdersTableRow>
        type={TableStyles.COMPACT}
        rowsScrollHeight={mainStore.isMobile ? undefined : '17rem'}
        rows={tableRows}
        isLoading={isLoading}
        noDataLabel={mainStore.wallet.isActive ? 'No Open Orders' : 'Connect Wallet'}
        fields={[
          ...(mainStore.isMobile ? MOBILE_FIELDS : []),
          {
            label: 'Date',
            value: 'date',
            type: 'jsx',
            sortValue: 'timestamp',
            sortType: 'number',
            sortDisabled: true,
            widthPx: 100,
          },
          {
            label: 'Remaining Time',
            value: 'remainingTime',
            type: 'jsx',
            sortDisabled: true,
            widthRatio: 10,
          },
          ...(mainStore.isMobile ? [] : MD_FIELDS),
          {
            label: 'Price',
            value: 'price',
            type: 'jsx',
            sortDisabled: true,
            widthRatio: 8,
            align: 'right',
          },
          {
            label: 'Filled',
            value: 'filled',
            type: 'jsx',
            sortDisabled: true,
            widthRatio: 24,
            align: 'right',
          },
          {
            label: 'Order ID',
            value: 'orderId',
            type: 'jsx',
            sortDisabled: true,
            widthPx: 100,
            align: 'center',
          },
          ...(mainStore.isMobile ? [] : [clearField]),
        ]}
      />
    </div>
  );
};

export default observer(OpenOrdersTable);
