import Icon from 'components/icon';
import { DropDownItem } from '../DropDown';
import {
  DROPDOWN_BORDER_COLOR_DICT,
  DROPDOWN_COLOR_DICT,
  DROPDOWN_HOVER_LAYER_DICT,
  getDropdownCursor,
} from '../styles';
import { DropDownLayer } from '../types';

/** @desc DropDownBoxButton */
const DropDownBoxButton = <T, K>({
  layer,
  open,
  selectable,
  disabled,
  minHeight,
  selected,
  label,
  labelClassName = '',
}: {
  layer: DropDownLayer;
  open: boolean;
  selectable: boolean;
  disabled: boolean;
  minHeight?: string;
  selected: DropDownItem<T, K> | undefined;
  label?: K | string | JSX.Element;
  labelClassName?: string;
}) => {
  return (
    <div
      className={`relative self-stretch w-full h-full flex justify-between items-center gap-x-2 px-3 py-4 text-left transition-all overflow-hidden border rounded-8px ${
        open ? 'rounded-b-none' : ''
      } ${selectable ? DROPDOWN_BORDER_COLOR_DICT[layer] : 'border-transparent'} ${DROPDOWN_COLOR_DICT[layer]} ${
        selectable && !disabled ? DROPDOWN_HOVER_LAYER_DICT.box : ''
      } ${getDropdownCursor(selectable, disabled)}`}
      style={{ minHeight }}
    >
      <div
        className={`grow shrink overflow-hidden ${
          selectable && !disabled && selected === undefined ? 'text-primary' : ''
        } ${labelClassName}`}
      >
        {label}
      </div>

      {selectable && (
        <Icon
          type={open ? 'expandless' : 'expandmore'}
          size="20px"
          className={`grow-0 shrink-0 text-on_surface_variant_light ${disabled ? 'opacity-40' : ''}`}
        />
      )}
    </div>
  );
};

export default DropDownBoxButton;
