import { observer } from 'mobx-react-lite';
import BoostdropCat from 'resources/images/boostdrop_cat.png';
import PageTitleArea from 'components/layout/PageTitleArea';
import TabSelector from 'components/tabs/TabSelector';
import Dexdrop from './Dexdrop';
import { useMainStore } from 'provider/mainContext';

const AirdropPage = () => {
  const store = useMainStore();

  return (
    <div className="pb-20 md:pb-40">
      <PageTitleArea title="Airdrop" />

      <main role="main" className="w-full max-w-content m-auto pt-5 md:pt-[3rem] px-4 md:px-0">
        <TabSelector
          type="segmented"
          tabsMaxWidth="15rem"
          align="center"
          panels={[
            {
              key: 'Dexdrop',
              content: <Dexdrop address={store.wallet.address} />,
            },
            {
              key: 'Boostdrop',
              content: (
                <>
                  <div className="sr-only">Coming Soon</div>

                  <div className="blur-sm pointer-events-none" aria-hidden="true">
                    <Dexdrop />
                  </div>

                  <div className="fixed bottom-[84px] left-0 right-0 animate-fade-in md:bottom-0">
                    <img src={BoostdropCat} className="w-full max-w-[704px] m-auto" alt="Coming Soon" />
                  </div>
                </>
              ),
            },
          ]}
        />
      </main>
    </div>
  );
};

export default observer(AirdropPage);
