import { Popover } from '@headlessui/react';
import Icon, { IconType } from 'components/icon';
import RedDot from 'components/pings/RedDot';
import { Link } from 'react-router-dom';
import { IconButtonSize, ICON_BUTTON_SIZE_DICT } from './styles';

type IconButtonStyle = 'standard' | 'tonal';

/** @todo disable hover for disabled */
const ICON_BUTTON_COLOR_DICT: { [key in IconButtonStyle]: string } = {
  standard: 'text-on_surface_variant_light bg-transparent',
  tonal: 'text-on_secondary_container bg-secondary_container disabled:bg-on_surface_o24',
};

const BUTTON_HOVER_LAYER =
  'before:block before:absolute before:w-full before:h-full before:top-0 before:right-0 before:left-0 before:bottom-0 before:bg-transparent before:rounded-full before:transition-all';

const BUTTON_HOVER_DICT: { [key in IconButtonStyle]: string } = {
  standard: `${BUTTON_HOVER_LAYER} before:hover:bg-on_surface_variant_light_o24`,
  tonal: `${BUTTON_HOVER_LAYER} before:hover:bg-on_secondary_container_o24`,
};

interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: IconButtonStyle;
  iconType: IconType;
  size?: IconButtonSize;
  redDot?: boolean;
  disabled?: boolean;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isPopOver?: boolean;
  /** @todo deprecated */
  className?: string;
}

function IconButton({
  type = 'standard',
  iconType,
  size = 'md',
  redDot = false,
  disabled = false,
  to,
  onClick,
  isPopOver = false,
  /** @todo deprecated */
  className = '',
  ...attr
}: IconButtonProps) {
  const args = {
    disabled,
    className: `${ICON_BUTTON_SIZE_DICT[size].button} flex justify-center items-center ${
      ICON_BUTTON_COLOR_DICT[type]
    } rounded-full overflow-hidden ${disabled ? 'cursor-not-allowed' : BUTTON_HOVER_DICT[type]} transition-colors`,
  };

  const content = (
    <div className={disabled ? 'opacity-40' : ''}>
      <Icon type={iconType} size={ICON_BUTTON_SIZE_DICT[size].icon} />
    </div>
  );

  return (
    <div className={`grow-0 shrink-0 rounded-full ${className}`}>
      <div className="relative w-max h-max flex justify-center items-center rounded-full">
        {to !== undefined ? (
          <Link to={to} {...args}>
            {content}
          </Link>
        ) : isPopOver ? (
          <Popover.Button {...args} onClick={onClick}>
            {content}
          </Popover.Button>
        ) : (
          <button type="button" {...args} {...attr} onClick={onClick}>
            {content}
          </button>
        )}

        {redDot && <RedDot className="absolute top-0 right-0" />}
      </div>
    </div>
  );
}

export default IconButton;
