import { PairDetail, LivePairRaw, InfoPair, Pool } from 'types/pair';
import { Wallet } from 'types/wallet';
import { toLiquidFarmBN, toLivePairBN, toPoolBN, toPoolLiveBN } from 'common/bigNumberHandlers';
import { STAKING_DENOMS } from 'COMMON_VARIABLES';
import BigNumber from 'bignumber.js';
import { InfoAsset, LiveAsset, InfoAssetWithMyAmount, Coin, AssetDetail } from 'types/asset';
import { InfoChain, LiveChain } from 'types/chain';
import { PoolDetail, PoolLiveRaw } from 'types/pool';
import { getExponentDiff, getTokenType } from 'common/utils';
import { LiquidFarmDetail, LiquidFarmLiveRaw } from 'types/liquidFarm';
import { TokenTypes } from 'components/constants/token';

interface BaseInfoLive<Info, Live> {
  isInit: boolean;
  isDelayed: boolean;
  info: Record<string, Info>;
  live: Record<string, Live>;
}

type AssetInfoLive = BaseInfoLive<InfoAsset, LiveAsset>;
type PairInfoLive = BaseInfoLive<InfoPair, LivePairRaw>;
type ChainInfoLive = BaseInfoLive<InfoChain, LiveChain>;
type PoolInfoLive = BaseInfoLive<null, PoolLiveRaw>;
type LiquidFarmInfoLive = BaseInfoLive<null, LiquidFarmLiveRaw>;
type ParamInfoLive = BaseInfoLive<any, any>;

export type Theme = 'Dark' | 'Light';
export type OrderbookPairDirection = 'forward' | 'backward';

export interface MainStore {
  // isInit: boolean;
  // setIsInit: (value: boolean) => void;
  theme: Theme;
  isMobile: boolean;
  toastIds: Set<string>;
  liquidStakingApr: string;
  orderbookPairDirection: OrderbookPairDirection;
  isWatching: boolean;
  wallet: Wallet;
  setWallet: (wallet: Wallet) => void;
  isConnectModalOpen: boolean;
  govTab: number;
  setGovTab: (index: number) => void;
  tickPrecision: number;
  setTickPrecision: (tickPrecision: number) => void;
  assetsData: AssetInfoLive;
  assetDetailDict: Record<string, AssetDetail>;
  pairsData: PairInfoLive;
  chainsData: ChainInfoLive;
  poolsData: PoolInfoLive;
  liquidFarmsData: LiquidFarmInfoLive;
  paramsData: ParamInfoLive;
  balanceData: Record<string, string>;
  reservedBalanceData: Record<string, string>;
  setTheme: (theme: Theme) => void;
  setIsMobile: (isMobile: boolean) => void;
  setToastIds: (newToastId: string) => void;
  setLiquidStakingApr: (apr: string) => void;
  getLiquidStakingApr: () => string;
  setOrderbookPairDirection: (direction: OrderbookPairDirection) => void;
  setAssetsData: (data: AssetInfoLive) => void;
  setAssetDetailDict: (assetsData: AssetInfoLive) => void;
  setPairsData: (data: PairInfoLive) => void;
  setChainsData: (data: ChainInfoLive) => void;
  setPoolsData: (data: PoolInfoLive) => void;
  setLiquidFarmsData: (data: LiquidFarmInfoLive) => void;
  setParamsData: (data: ParamInfoLive) => void;
  setBalanceData: (data: any) => void;
  setReservedBalanceData: (data: any) => void;
  getOrderbookPairDirection: () => OrderbookPairDirection;
  getPools: () => PoolDetail[];
  getTokenOriginPool: (denom: string) => PoolDetail | undefined;
  getLiquidFarms: () => LiquidFarmDetail[];
  getLiquidFarmByLfDenom: (lfDenom: string) => LiquidFarmDetail | undefined;
  getPairs: () => PairDetail[];
  getMyPairs: () => PairDetail[];
  getAssetDetails: (options?: { pool?: boolean; lf?: boolean }) => AssetDetail[];
  getMyAssets: () => InfoAssetWithMyAmount[];
  getMyReservedAssets: () => InfoAssetWithMyAmount[];
  getRestAssets: () => InfoAssetWithMyAmount[];
  getChain: (chainId: string) => { info: InfoChain; live: LiveChain } | undefined;
}

export const createMainStore: () => MainStore = () => {
  return {
    //app init
    // isInit: false,
    // setIsInit(value: boolean) {
    //   this.isInit = value;
    // },
    /** theme Light is not used anymore */
    theme: 'Dark',
    setTheme(theme) {
      localStorage.setItem('theme', theme);
      this.theme = theme;
    },
    /** tailwind mobile breakpoint */
    isMobile: false,
    setIsMobile(isMobile) {
      this.isMobile = isMobile;
    },
    toastIds: new Set(),
    setToastIds(newToastId: string) {
      this.toastIds = new Set([...this.toastIds, newToastId]);
    },
    liquidStakingApr: '0',
    orderbookPairDirection:
      (localStorage.getItem('orderbookPairDirection') as OrderbookPairDirection | undefined) ?? 'forward',
    isWatching: false,
    wallet: { type: null, isActive: false, name: '', address: '' },
    setWallet(wallet) {
      this.wallet = wallet;
    },
    isConnectModalOpen: false,
    govTab: 0,
    setGovTab(index: number) {
      this.govTab = index;
    },
    tickPrecision: 3,
    setTickPrecision(tickPrecision) {
      this.tickPrecision = tickPrecision;
    },
    // asset data
    assetsData: { isInit: false, isDelayed: false, info: {}, live: {} },
    setAssetsData(data) {
      this.assetsData = data;
      this.setAssetDetailDict(data);
    },
    assetDetailDict: {},
    setAssetDetailDict(data) {
      if (data.isInit && data.info && data.live) {
        const dict = Object.values(data.info).reduce((accm, asset) => {
          const availableBalance = new BigNumber(this.balanceData[asset.denom] ?? 0);
          const reservedBalance = new BigNumber(this.reservedBalanceData[asset.denom] ?? 0);

          const tokenType = getTokenType(asset);

          /** @todo LF price */
          const priceOracle =
            tokenType === TokenTypes.POOL
              ? new BigNumber(this.poolsData.live?.[asset.denom]?.priceOracle ?? 0).shiftedBy(asset.exponent)
              : new BigNumber(data.live?.[asset.denom]?.priceOracle ?? 0);

          const pairs = this.getPairs().filter((pair) => pair.assets.findIndex((a) => a.denom === asset.denom) > -1);

          const poolDenom =
            tokenType === TokenTypes.LF
              ? this.liquidFarmsData.live[asset.denom]?.poolDenom
              : tokenType === TokenTypes.POOL
              ? asset.denom
              : undefined;
          const originPool = poolDenom !== undefined ? this.getTokenOriginPool(poolDenom) : undefined;

          return {
            ...accm,
            [asset.denom]: {
              ...asset,
              chains: asset.chains?.filter((chain) => this.getChain(chain.chain_id)),
              availableBalance,
              reservedBalance,
              priceOracle,
              pairs,
              tokenType,
              originPool,
            },
          };
        }, {});

        this.assetDetailDict = dict;
        return;
      }

      this.assetDetailDict = {};
    },
    // pair data
    pairsData: { isInit: false, isDelayed: false, info: {}, live: {} },
    setPairsData(data) {
      this.pairsData = data;
    },
    // chain data
    chainsData: { isInit: false, isDelayed: false, info: {}, live: {} },
    setChainsData(data) {
      this.chainsData = data;
    },
    // pool data
    poolsData: { isInit: false, isDelayed: false, info: {}, live: {} },
    setPoolsData(data) {
      this.poolsData = data;
    },
    // liquid farm data
    liquidFarmsData: { isInit: false, isDelayed: false, info: {}, live: {} },
    setLiquidFarmsData(data) {
      this.liquidFarmsData = data;
    },
    // param data
    paramsData: { isInit: false, isDelayed: false, info: {}, live: {} },
    setParamsData(data) {
      this.paramsData = data;
    },
    // balance data
    balanceData: {},
    setBalanceData(data) {
      this.balanceData = data;
      this.setAssetDetailDict(this.assetsData);
    },
    setLiquidStakingApr(apr) {
      this.liquidStakingApr = apr;
    },
    getLiquidStakingApr() {
      return this.liquidStakingApr;
    },
    // reserved balance data
    reservedBalanceData: {},
    setReservedBalanceData(data) {
      this.reservedBalanceData = data;
    },
    setOrderbookPairDirection(direction) {
      this.orderbookPairDirection = direction;
      localStorage.setItem('orderbookPairDirection', direction);
    },
    getOrderbookPairDirection() {
      return this.orderbookPairDirection;
    },
    getPools() {
      if (this.assetsData.isInit && this.poolsData.isInit && this.pairsData.isInit) {
        const pools: PoolDetail[] = [];
        Object.values(this.poolsData.live).forEach((pool: PoolLiveRaw) => {
          const pair: InfoPair | undefined = this.pairsData.info[pool.pairId];

          if (pair && this.assetsData.info[pair.baseDenom] && this.assetsData.info[pair.quoteDenom]) {
            const poolBN = toPoolLiveBN(pool);
            let baseReserved = poolBN.Reserved.filter((r) => r.denom === pair.baseDenom)[0];
            if (!baseReserved) {
              baseReserved = { denom: pair.baseDenom, priceOracle: new BigNumber(0), amount: new BigNumber(0) };
            }
            let quoteReserved = poolBN.Reserved.filter((r) => r.denom === pair.quoteDenom)[0];
            if (!quoteReserved) {
              quoteReserved = { denom: pair.quoteDenom, priceOracle: new BigNumber(0), amount: new BigNumber(0) };
            }
            const RewardsPerToken =
              pool.RewardsPerToken?.map((rpt) => ({ ...rpt, isPairPlan: rpt.pairPlan === 1 })) ?? [];
            const lfEnabled = pool.lfEnabled === 1;

            pools.push({
              ...toPoolLiveBN(pool),
              reserved: [baseReserved, quoteReserved],
              assets: [this.assetsData.info[pair.baseDenom], this.assetsData.info[pair.quoteDenom]],
              exponentDiff: getExponentDiff(pair.baseDenom, pair.quoteDenom, this),
              RewardsPerToken,
              lfEnabled,
              pair,
            });
          }
        });
        return pools;
      }
      return [];
    },
    getTokenOriginPool(denom: string): PoolDetail | undefined {
      const pools = this.getPools();
      return pools.find((pool) => pool.poolDenom === denom);
    },
    getLiquidFarms() {
      if (this.liquidFarmsData.isInit && this.poolsData.isInit && this.pairsData.isInit) {
        return Object.values(this.liquidFarmsData.live)
          .map((liquidFarmLive) => {
            const poolLiveRaw = this.poolsData.live[liquidFarmLive.poolDenom];
            const pair = this.pairsData.info[poolLiveRaw?.pairId.toString() ?? ''];

            if (poolLiveRaw && pair) {
              // const poolBN = toPoolLiveBN(poolLiveRaw);
              const liquidFarmBN = toLiquidFarmBN(liquidFarmLive);

              /** @todo pool/lf token exponent diff must be tested again; both 12 atm */
              const exponentDiff = getExponentDiff(poolLiveRaw.poolDenom, liquidFarmLive.lfDenom, this);
              // const mintAmountPerPoolToken = poolBN.totalSupplyAmount.gt(0)
              //   ? liquidFarmBN.lfTotalSupply.gt(0)
              //     ? liquidFarmBN.lfTotalSupply.div(poolBN.totalSupplyAmount).shiftedBy(exponentDiff)
              //     : new BigNumber(1)
              //   : undefined;
              // const receiveAmountPerLfToken = liquidFarmBN.lfTotalSupply.gt(0)
              //   ? poolBN.totalSupplyAmount
              //       .minus(liquidFarmBN.compoundingAmount)
              //       .div(liquidFarmBN.lfTotalSupply)
              //       .shiftedBy(-exponentDiff)
              //   : undefined;
              const mintAmountPerPoolToken =
                liquidFarmBN.stakeAmount.gt(0) && liquidFarmBN.lfTotalSupply.gt(0)
                  ? liquidFarmBN.lfTotalSupply.div(liquidFarmBN.stakeAmount).shiftedBy(exponentDiff)
                  : new BigNumber(1);

              const receiveAmountPerLfToken = liquidFarmBN.lfTotalSupply.gt(0)
                ? liquidFarmBN.stakeAmount
                    .minus(liquidFarmBN.compoundingAmount)
                    .div(liquidFarmBN.lfTotalSupply)
                    .shiftedBy(-exponentDiff)
                : new BigNumber(0);

              return {
                ...liquidFarmBN,
                mintAmountPerPoolToken,
                receiveAmountPerLfToken,
              };
            } else return undefined;
          })
          .filter(isLiquidFarmDefined);
      } else {
        return [];
      }
    },
    getLiquidFarmByLfDenom(lfDenom: string) {
      return this.getLiquidFarms().find((liquidFarm) => liquidFarm.lfDenom === lfDenom);
    },
    getPairs() {
      if (this.assetsData.isInit && this.pairsData.isInit && this.poolsData.isInit) {
        /** @desc this refac was to resolve mobx out of bounds:1 warning */
        const pairs: (PairDetail | undefined)[] = Object.values(this.pairsData.live).map((pair) => {
          const baseAsset: InfoAsset | undefined = this.assetsData.info[pair.baseDenom];
          const quoteAsset: InfoAsset | undefined = this.assetsData.info[pair.quoteDenom];
          if (!baseAsset || !quoteAsset) return undefined;

          const exponentDiff = baseAsset.exponent - quoteAsset.exponent;

          const pools: Pool[] =
            this.pairsData.info[pair.pairId]?.pools.map((pool) => {
              const poolBN = toPoolBN(pool);
              const base = poolBN.reserved.find((reserve) => reserve.denom === pair.baseDenom);
              const quote = poolBN.reserved.find((reserve) => reserve.denom === pair.quoteDenom);
              const reserved: [Coin | undefined, Coin | undefined] = [base, quote];
              return { ...poolBN, reserved };
            }) ?? [];

          return {
            ...toLivePairBN(pair),
            pools,
            assets: [baseAsset, quoteAsset],
            exponentDiff,
          };
        });

        return pairs.filter(isPairDetail);
      }
      return [];
    },
    getMyPairs() {
      let pairs = this.getPairs();
      if (this.balanceData && pairs.length > 0) {
        return pairs.filter((pair) => {
          return pair?.pools?.reduce((acc: boolean, cur: any) => {
            if (acc) return true;
            return this?.balanceData?.[cur.poolDenom] ? true : false;
          }, false);
        });
      }
      return [];
    },
    getAssetDetails(options?: { pool?: boolean; lf?: boolean }) {
      const NORMALS = [TokenTypes.NATIVE, TokenTypes.IBC, TokenTypes.BRIDGED];
      const poolTypes = options?.pool ? [TokenTypes.POOL] : [];
      const lfTypes = options?.lf ? [TokenTypes.LF] : [];
      const types = [...NORMALS, ...poolTypes, ...lfTypes];

      return Object.values(this.assetDetailDict).filter((asset) => {
        return types.includes(asset.tokenType);
      });
    },
    getMyAssets() {
      if (this.balanceData && this.assetsData.info) {
        const assets = Object.keys(this.assetsData.info)
          .filter(
            (denom) => this.balanceData?.[denom] || denom === STAKING_DENOMS.staked || denom === STAKING_DENOMS.unstaked
          )
          .map<InfoAssetWithMyAmount>((denom) => mapAsset({ denom, balanceStoreKey: 'balanceData', mainStore: this }));
        return assets;
      }
      return [];
    },
    getMyReservedAssets() {
      if (this.reservedBalanceData && this.assetsData.info) {
        const assets = Object.keys(this.assetsData.info)
          .filter((denom) => {
            return (
              Number(this?.reservedBalanceData?.[denom]) > 0 &&
              (this.reservedBalanceData?.[denom] ||
                denom === STAKING_DENOMS.staked ||
                denom === STAKING_DENOMS.unstaked)
            );
          })
          .map<InfoAssetWithMyAmount>((denom) =>
            mapAsset({ denom, balanceStoreKey: 'reservedBalanceData', mainStore: this })
          );
        return assets;
      }
      return [];
    },
    getRestAssets() {
      if (this.balanceData && this.assetsData.info) {
        const assets = Object.keys(this.assetsData.info)
          .filter(
            (denom) =>
              !this.balanceData?.[denom] && denom !== STAKING_DENOMS.staked && denom !== STAKING_DENOMS.unstaked
          )
          .map<InfoAssetWithMyAmount>((denom) => mapAsset({ denom, balanceStoreKey: '', mainStore: this }));
        return assets;
      }
      return [];
    },
    getChain(chainId: string): { info: InfoChain; live: LiveChain } | undefined {
      const info = this.chainsData.info[chainId];
      const live = this.chainsData.live[chainId];
      return info && live ? { info, live } : undefined;
    },
  };
};

/** @summary type guard */
function isLiquidFarmDefined(liquidFarm: LiquidFarmDetail | undefined): liquidFarm is LiquidFarmDetail {
  return liquidFarm !== undefined;
}

function isPairDetail(pair: PairDetail | undefined): pair is PairDetail {
  return pair !== undefined;
}

function mapAsset({
  denom,
  balanceStoreKey,
  mainStore,
}: {
  denom: string;
  balanceStoreKey: string;
  mainStore: MainStore;
}): InfoAssetWithMyAmount {
  const tokenType = getTokenType(mainStore.assetsData.info[denom]);
  const poolDenom = tokenType === TokenTypes.LF ? mainStore.liquidFarmsData.live[denom]?.poolDenom : denom;
  const originPool = poolDenom ? mainStore.getTokenOriginPool(poolDenom) : undefined;
  const assetInfo = mainStore.assetsData.info[denom];
  return {
    ...assetInfo,
    chains: assetInfo.chains?.filter((chain) => mainStore.getChain(chain.chain_id)),
    balance: new BigNumber(mainStore[balanceStoreKey]?.[denom] ?? 0),
    priceOracle: new BigNumber(mainStore.assetsData.live?.[denom]?.priceOracle || 0),
    tokenType,
    originPool,
  };
}
