import { useCallback, useMemo } from 'react';
import { PoolDetail } from 'types/pool';
import { formatAmount, getAmp, getForcedNumber } from 'common/utils';
import BigNumber from 'bignumber.js';
import PoolRangeDetails from './tooltip/PoolRangeDetails';
import TooltipIcon from 'components/tooltips/TooltipIcon';
import { TOOLTIP_TEXTS } from 'COMMON_VARIABLES';

type PoolRangeProps = { pool: PoolDetail; minPercent?: number; maxPercent?: number };

const PoolRange = ({ pool, minPercent = 1, maxPercent = 100 }: PoolRangeProps) => {
  const price = useMemo<number>(() => pool.poolPrice.toNumber(), [pool.poolPrice]);

  const percentToMin = useMemo<number>(
    () => (price <= pool.minPrice ? 0 : 1 - pool.minPrice / price) * 100,
    [price, pool.minPrice]
  );

  const percentToMax = useMemo<number>(
    () => (price >= pool.maxPrice ? 0 : pool.maxPrice / price - 1) * 100,
    [price, pool.maxPrice]
  );

  const amp = useMemo<BigNumber>(
    () => getAmp({ lastPrice: pool.pair.lastPrice, minPrice: pool.minPrice, maxPrice: pool.maxPrice }),
    [pool.pair.lastPrice, pool.minPrice, pool.maxPrice]
  );

  /** @summary stick length % */
  const getLengthRatio = useCallback(
    (percent: number) => {
      if (percent === 0) return 0;

      const MIN_STICK_RATIO = 25;
      const unitRatio = (100 - MIN_STICK_RATIO) / (maxPercent - minPercent);

      const minMaxForcedPercent = getForcedNumber(percent, minPercent, maxPercent);
      return (minMaxForcedPercent - minPercent) * unitRatio + MIN_STICK_RATIO;
    },
    [minPercent, maxPercent]
  );

  const isRangedPool = useMemo<boolean>(() => pool.poolType === 2, [pool.poolType]);
  const isInRange = useMemo<boolean>(() => amp.gt(1), [amp]);

  return (
    <div
      className={`w-20 flex flex-col items-end md:items-center justify-start gap-y-0.5 ${
        amp.isZero() ? 'opacity-40' : ''
      }`}
    >
      {/* amp */}
      <div className="relative flex items-center gap-x-1 font_caption_number_xs text-on_surface_variant_light">
        <div className="flex items-end">
          <div className="font_caption_xs">x</div>
          <div>{formatAmount(amp, 2)}</div>
        </div>
        <div className="text-on_surface_variant_dark">
          <TooltipIcon
            tooltipContent={
              isRangedPool ? (
                <PoolRangeDetails pool={pool} amp={amp} percentToMax={percentToMax} percentToMin={percentToMin} />
              ) : (
                TOOLTIP_TEXTS.BASIC_POOL_RANGE
              )
            }
          />
        </div>
      </div>

      {/* range */}
      {isInRange && (
        <div className="flex items-stretch justify-center w-full h-fit gap-x-0">
          <PoolRangeStick ratio={getLengthRatio(percentToMin)} direction="down" />
          <div className={`w-[1px] h-2 grow-0 shrink-0 bg-on_surface`}></div>
          <PoolRangeStick ratio={getLengthRatio(percentToMax)} direction="up" />
        </div>
      )}
    </div>
  );
};

export default PoolRange;

function PoolRangeStick({ ratio, direction }: { ratio: number; direction: 'up' | 'down' }) {
  const arrowRightClassName = useMemo<string>(() => (direction === 'up' ? 'right-0' : 'left-0'), [direction]);
  const arrowRotateClassName = useMemo<string>(
    () => (direction === 'up' ? 'rotate-45' : 'rotate-[-135deg]'),
    [direction]
  );

  return (
    <div className="relative w-full h-full grow shrink">
      <div
        title={`Price range to ${direction === 'up' ? 'maximum' : 'minimum'} price`}
        className={`absolute top-1 ${direction === 'up' ? 'left-0' : 'right-0'} h-[1px] bg-on_surface_variant_dark`}
        style={{
          width: `${ratio}%`,
        }}
      >
        {ratio > 0 && (
          <div
            className={`absolute -top-[2px] ${arrowRightClassName} border-t border-r border-on_surface_variant_dark bg-transparent w-[5px] h-[5px] origin-center-left ${arrowRotateClassName}`}
          ></div>
        )}
      </div>
    </div>
  );
}
