import React, { useCallback } from 'react';
import AccountModal from 'components/modals/AccountModal';
import NotificationModal from 'components/modals/NotificationModal';
import ConnectWalletModal from 'components/modals/ConnectWalletModal';
import IconButton from 'components/buttons/IconButton';
import { useMainStore } from 'provider/mainContext';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useCrescentChainBalance, useNewEvents } from 'hooks/useAPI';
import useConnect from 'hooks/useConnect';
import { notifyTxResult } from 'common/utils';
import type { TxResult } from 'common/tx/txResult';
import Button from 'components/buttons/Button';
import useOrderedTxResult from './useOrderedTxResult';

function WalletWidget() {
  // Variables
  const mainStore = useMainStore();
  const { connect, disconnect } = useConnect();

  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const { data: crescentChainBalances } = useCrescentChainBalance(
    mainStore.wallet.address || '',
    mainStore.isWatching ? 1000 : 3000
  );
  const { data: newEvents } = useNewEvents(mainStore.wallet.address || '', mainStore.isWatching ? 1000 : 5000);

  useEffect(() => {
    // set main store balance
    const denomIndexBalance: any = {};
    const denomIndexReservedBalance: any = {};
    crescentChainBalances?.data?.asset?.forEach((asset) => {
      denomIndexBalance[asset.denom] = asset.amount;
      denomIndexReservedBalance[asset.denom] = asset.reserved;
    });
    mainStore.setBalanceData(denomIndexBalance);
    mainStore.setReservedBalanceData(denomIndexReservedBalance);
  }, [crescentChainBalances]);

  /** tx notification */
  // const [runConfetti, setRunConfetti] = useState(false);

  const onTriggerTxResultNoti = useCallback((txResult: TxResult) => {
    // if (txResult.feedbackType === 'congrats' && CONFETTI_TXS.includes(txResult.txType) && !runConfetti)
    //   setRunConfetti(true);

    notifyTxResult(txResult);
  }, []);

  useOrderedTxResult(newEvents, onTriggerTxResultNoti);

  return (
    <div>
      {mainStore.wallet.isActive && mainStore.wallet.address ? (
        <div className="flex items-center gap-x-3">
          {/* {mainStore.wallet.type === 'cosmostation' && <AutoSignWidget />} */}
          <Button
            label={mainStore.wallet.name}
            type="text"
            size={mainStore.isMobile ? 'sm' : 'md'}
            onClick={() => setIsAccountModalOpen(true)}
            className="max-w-[64px] md:max-w-none"
          />
          <IconButton
            iconType="noti"
            size="lg"
            className="flex items-center justify-center w-10 h-10"
            onClick={() => setIsNotificationModalOpen(true)}
          />
          {/* modal */}
          <AccountModal
            isOpen={isAccountModalOpen}
            disconnect={disconnect}
            close={() => setIsAccountModalOpen(false)}
          />
          <NotificationModal
            isOpen={isNotificationModalOpen}
            close={() => setIsNotificationModalOpen(false)}
            address={mainStore.wallet.address}
          />
        </div>
      ) : (
        <>
          <Button
            label="Connect Wallet"
            type="filled"
            size={mainStore.isMobile ? 'sm' : 'md'}
            onClick={() => (mainStore.isConnectModalOpen = true)}
          />
          {/* modal */}
          <ConnectWalletModal
            isOpen={mainStore.isConnectModalOpen}
            connect={connect}
            close={() => (mainStore.isConnectModalOpen = false)}
          />
        </>
      )}

      {/* {localStorage.getItem(IS_NOTIFICATION_ON_LOCAL_STORAGE_KEY) !== 'false' && runConfetti && (
        <Confetti run={runConfetti} onComplete={() => setRunConfetti(false)} />
      )} */}
    </div>
  );
}

export default React.memo(observer(WalletWidget));

//TODO: get cresent chain  address (need chain info)
// Hooks
// const [totalDollarValue, setTotalDollarValue] = useState('0');
// useEffect(() => {
//   // set the account total dollar value
//   if (mainStore.assetsData.isInit && crescentChainBalances?.data?.asset.length > 0) {
//     const result = crescentChainBalances.data.asset.reduce((prev: any, cur: any) => {
//       if (mainStore.assetsData.live[cur.denom]?.priceOracle && mainStore.assetsData.info[cur.denom]?.exponent) {
//         const assetDollarValue = new BigNumber(mainStore.assetsData.live[cur.denom].priceOracle)
//           .shiftedBy(-mainStore.assetsData.info[cur.denom].exponent)
//           .multipliedBy(cur.amount)
//           .decimalPlaces(2, 0)
//           .toNumber();
//         return prev + assetDollarValue;
//       } else {
//         return prev;
//       }
//     }, 0);
//     setTotalDollarValue(new BigNumber(result).decimalPlaces(2, 0).toString());
//   }
// }, [crescentChainBalances, mainStore.assetsData]);
