import { useMemo, useState, useEffect, useRef, useCallback, RefObject } from 'react';
import { useMainStore } from 'provider/mainContext';
import { formatAmount, formatUSD, getDollarValueOnCreChainByDenom, sanitizeNumber } from 'common/utils';
import { getValidDigitNumber } from 'common/utils';
import BigNumber from 'bignumber.js';
import Icon from 'components/icon';
import { isMobile } from '@walletconnect/browser-utils';
import { Orderbook } from 'types/orderbook';
import Widget from 'components/widgets/components/Widget';
import { getChangeTextColor } from '../styles';
import NA from 'components/texts/NA';
import Tooltip from 'components/tooltips';
import NumberText from 'components/texts/NumberText';
import { DropDownItem } from 'components/dropdown/DropDown';
import TickPrecisionDropDown from './TickPrecisionDropDown';
import TooltipInner from 'components/tooltips/TooltipInner';
import TooltipLabel from 'components/tooltips/TooltipLabel';
import { InfoAsset } from 'types/asset';
import useTickPrecision from './useTickPrecision';
import { MAX_DECIMAL } from 'COMMON_VARIABLES';

type OrderbookRowData = {
  baseAmount: BigNumber | undefined;
  quoteAmount: BigNumber | undefined;
  avgPrice: BigNumber | undefined;
};

type OrderbookRowDepthTooltipContentProps = {
  rowData: OrderbookRowData;
  baseTicker: string;
  quoteTicker: string;
  quoteDenom: string;
};

const OrderbookRowDepthTooltipContent = ({
  rowData,
  baseTicker,
  quoteTicker,
  quoteDenom,
}: OrderbookRowDepthTooltipContentProps) => {
  return (
    <TooltipInner>
      <div className="w-full space-y-3">
        <TooltipLabel
          label="Avg Price"
          value={<NumberText size="xs" value={`≈ ${rowData.avgPrice ? formatAmount(rowData.avgPrice, 9) : ''}`} />}
        />
        <TooltipLabel
          label={`Sum ${baseTicker}`}
          value={<NumberText size="xs" value={rowData.baseAmount ? formatAmount(rowData.baseAmount, 6) : ''} />}
        />
        <TooltipLabel
          label={`Sum ${quoteTicker}`}
          value={<NumberText size="xs" value={rowData.quoteAmount ? formatAmount(rowData.quoteAmount, 6) : ''} />}
        />
        <TooltipLabel
          label="Total USD"
          value={
            <NumberText
              size="xs"
              value={`≈ ${formatUSD(
                new BigNumber(getDollarValueOnCreChainByDenom(quoteDenom, rowData.quoteAmount?.toString() ?? ''))
              )}`}
            />
          }
        />
      </div>
    </TooltipInner>
  );
};

// Helpers
function char(value) {
  return Math.floor(Math.log10(value));
}

function tickToUpTick(tick, prec) {
  return tick + Math.pow(10, char(tick) - prec);
}

function valueToUpTick(value, prec) {
  const pow = Math.pow(10, char(value) - prec);
  let tick = Math.round(value / pow) * pow;
  if (tick < value) {
    tick = tickToUpTick(tick, prec);
  }
  return tick;
}

function convertExponent(n) {
  let data = String(n).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = '',
    sign = n < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}

function countDecimal(v: number) {
  const value = convertExponent(v);
  if (new BigNumber(value).mod(1).toNumber() !== 0) {
    return value.toString().split('.')[1]?.length ?? 0;
  } else {
    return 0;
  }
}

type OrderbookWidgetProps = {
  orderbook: Orderbook;
  selectedPairId: string;
  selectPrice: (any) => void;
  tickPrecisionDisplayValue: string;
  onChangeTickPrecisionDisplayValue: (value: string) => void;
  className?: string;
};

const OrderbookWidget = ({
  orderbook,
  selectedPairId,
  selectPrice,
  onChangeTickPrecisionDisplayValue,
  className = '',
}: OrderbookWidgetProps) => {
  // Variables
  const [orderBookData, setOrderBookData] = useState<any>('');
  const [prevBasePriceData, setPrevBasePriceData] = useState<any>([]);
  const [selectedPairs, setSelectedPairs] = useState<any>(['', '']);
  const [hoverPart, setHoverPart] = useState<'buy' | 'sell' | ''>('');
  const [hoverPrice, setHoverPrice] = useState<any>(null);
  const [hoverInfo, setHoverInfo] = useState<OrderbookRowData>({
    baseAmount: undefined,
    quoteAmount: undefined,
    avgPrice: undefined,
  });
  // For initial scroll position
  const sellRef = useRef<HTMLDivElement>(null);

  // Hooks
  const mainStore = useMainStore();

  // for scroll setup and price tracking reset, reset precision
  useEffect(() => {
    if (sellRef?.current) {
      setPrevBasePriceData([]);
    }
  }, [sellRef.current]);

  const selectedPairData = useMemo<Orderbook | null>(() => {
    if (String(orderbook.pairId) === String(selectedPairId)) {
      return orderbook;
    } else return null;
  }, [selectedPairId, orderbook]);

  const pairAssets = useMemo<[InfoAsset, InfoAsset] | undefined>(() => {
    const baseAsset: InfoAsset | undefined = mainStore.assetsData.info?.[selectedPairData?.baseDenom ?? ''];
    const quoteAsset: InfoAsset | undefined = mainStore.assetsData.info?.[selectedPairData?.quoteDenom ?? ''];

    return baseAsset && quoteAsset ? [baseAsset, quoteAsset] : undefined;
  }, [mainStore.assetsData.isInit, selectedPairId, selectedPairData?.baseDenom, selectedPairData?.quoteDenom]);

  const exponentDiff = useMemo<number>(() => {
    const diff = Number(pairAssets?.[0].exponent ?? 0) - Number(pairAssets?.[1].exponent ?? 0);
    return sanitizeNumber(diff);
  }, [pairAssets, selectedPairId]);

  /**
   *
   * @description Tick precision states and functions
   */
  const { precisions, tickPrecision, tickPrecisionDisplayValue, changeTickPrecision } = useTickPrecision({
    pairOrderbook: selectedPairData,
    exponentDiff,
  });

  useEffect(() => {
    onChangeTickPrecisionDisplayValue(tickPrecisionDisplayValue);
  }, [tickPrecisionDisplayValue]);

  const onSelectTickPrecision = (item: DropDownItem<number, string>) => changeTickPrecision(item.value);

  const selectedOrderbookData = useMemo(() => {
    if (!precisions) return;
    const precisionIndex = precisions.map((prec) => prec.precision).indexOf(tickPrecision);
    if (!selectedPairData?.orderbook.order_books?.[precisionIndex]) return;

    const decimalLength = countDecimal(Number(tickPrecisionDisplayValue));
    const _temp = JSON.parse(JSON.stringify(selectedPairData?.orderbook.order_books?.[precisionIndex]));
    setDisplayInfo(_temp.buys, 'buy');
    setDisplayInfo(_temp.sells, 'sell');

    //helper
    function setDisplayInfo(data, type) {
      data?.forEach((order) => {
        if (exponentDiff > 0) {
          order.display_price = parseFloat(new BigNumber(order.price).shiftedBy(exponentDiff).toString());
        } else {
          order.display_price = parseFloat(order.price);
        }
        order.display_price = new BigNumber(order.display_price).toFixed(decimalLength, 1).toString();

        order.amount = new BigNumber(order.pool_order_amount)
          .plus(order.user_order_amount)
          .shiftedBy(-(pairAssets?.[0].exponent ?? 0));
      });
    }
    return _temp;
  }, [tickPrecision, selectedPairData, precisions, tickPrecisionDisplayValue, pairAssets?.[0].exponent]);

  // hover info
  useEffect(() => {
    let temp_base_amount = 0;
    let temp_quote_amount = 0;
    if (!selectedOrderbookData) return;
    if (hoverPart === 'sell') {
      selectedOrderbookData.sells.forEach((order) => {
        if (+order.price <= +hoverPrice) {
          const base_amount = +order.pool_order_amount + +order.user_order_amount;
          temp_base_amount += base_amount;
          temp_quote_amount += +order.price * base_amount;
        }
      });
    } else if (hoverPart === 'buy') {
      selectedOrderbookData.buys.forEach((order) => {
        if (+order.price >= +hoverPrice) {
          const base_amount = +order.pool_order_amount + +order.user_order_amount;
          temp_base_amount += base_amount;
          temp_quote_amount += +order.price * base_amount;
        }
      });
    }
    const exponentDiff =
      Number(mainStore.assetsData.info?.[selectedPairData?.baseDenom ?? '']?.exponent ?? 0) -
      Number(mainStore.assetsData.info?.[selectedPairData?.quoteDenom ?? '']?.exponent ?? 0);

    if (!selectedPairData) return;

    const avgPrice = new BigNumber(temp_quote_amount)
      .dividedBy(temp_base_amount)
      .shiftedBy(exponentDiff)
      .decimalPlaces(9, 1);
    // .toLocaleString();
    setHoverInfo({
      baseAmount: new BigNumber(temp_base_amount)
        .shiftedBy(-mainStore.assetsData.info[selectedPairData?.baseDenom]?.exponent)
        .decimalPlaces(6, 1),
      // .toLocaleString(),
      quoteAmount: new BigNumber(temp_quote_amount)
        .shiftedBy(-mainStore.assetsData.info[selectedPairData?.quoteDenom]?.exponent)
        .decimalPlaces(6, 1),
      // .toLocaleString(),
      avgPrice: avgPrice.isNaN() ? new BigNumber(0) : avgPrice,
      // avgPrice: avgPrice !== 'NaN' ? avgPrice : '0',
    });
  }, [hoverPart, hoverPrice, selectedOrderbookData, selectedPairData]);

  //
  useEffect(() => {
    setOrderBook();

    async function setOrderBook() {
      if (selectedPairId === '') return;

      //set selected pair data
      setSelectedPairs([selectedPairData?.baseTicker, selectedPairData?.quoteTicker]);

      if (!selectedOrderbookData) return;
      const sod = JSON.parse(JSON.stringify(selectedOrderbookData));
      sod.base_price = parseFloat(selectedPairData?.orderbook.base_price ?? '0');

      // set displayed sell/buy orderbook data
      const quoteDenomExponentModifier =
        10 ** (mainStore.assetsData.info[selectedPairData?.baseDenom ?? '']?.exponent ?? 1);

      let max = 0;
      setMax(sod?.sells);
      setMax(sod?.buys);

      // price checker
      const temp = [
        parseFloat(selectedPairData?.orderbook.base_price ?? '0'),
        ...JSON.parse(JSON.stringify(prevBasePriceData)),
      ];
      if (temp.length >= 3) {
        temp.pop();
      }
      setPrevBasePriceData(temp);

      // set displayed orderbook data
      setOrderBookData({ data: sod, max: valueToUpTick(max, 1) });

      // helper
      function setMax(orders) {
        orders?.forEach((order) => {
          order.user_order_amount = Number(order.user_order_amount) / quoteDenomExponentModifier;
          order.pool_order_amount = Number(order.pool_order_amount) / quoteDenomExponentModifier;
          const sum = order.user_order_amount + order.pool_order_amount;
          if (!max) {
            max = sum;
          } else if (sum > max) {
            max = sum;
          }
        });
      }
    }
  }, [selectedPairId, selectedPairData, tickPrecision]);

  const sellsAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sellsArea = sellsAreaRef.current;
    sellsArea?.scroll(0, sellsArea.scrollHeight);
  }, [orderbook.orderbook?.pair_id, tickPrecisionDisplayValue, orderBookData.data?.sells.length]);

  function obGenerator(data, max) {
    const noExponentPrice = new BigNumber(convertExponent(data?.base_price)).shiftedBy(exponentDiff).toString();
    const priceChangeIndex: number =
      prevBasePriceData.length < 2 || prevBasePriceData?.[1] === data.base_price
        ? 0
        : prevBasePriceData?.[1] > data.base_price
        ? -1
        : 1;

    const lastPrice = new BigNumber(getValidDigitNumber(new BigNumber(noExponentPrice).toNumber(), 5));
    const lastPriceUSD = new BigNumber(
      getDollarValueOnCreChainByDenom(selectedPairData?.quoteDenom ?? '', noExponentPrice)
    );

    return (
      <div className="w-full h-full">
        {/* sell stack */}
        {tickRowGenerator(data?.sells, 'sell', tickPrecisionDisplayValue, sellsAreaRef)}

        {/* last price */}
        <div className="flex items-center w-full px-2 py-3 gap-x-2 bg-surface md:gap-x-3 md:px-3 md:py-4">
          <div className="flex items-center gap-x-0.5 md:gap-x-1">
            <div className="font_data_number_m text-on_surface">
              {noExponentPrice === 'NaN' ? <NA /> : formatAmount(lastPrice, 5)}
            </div>

            {priceChangeIndex !== 0 && (
              <Icon
                size={mainStore.isMobile ? '16px' : '20px'}
                type={priceChangeIndex > 0 ? 'arrow-upward' : 'arrow-downward'}
                className={getChangeTextColor(priceChangeIndex)}
              />
            )}
          </div>

          <div className="font_caption_number_xs text-on_surface_variant_dark md:font_caption_number_m">
            {noExponentPrice === 'NaN' ? <NA /> : formatUSD(lastPriceUSD, { significant: true })}
          </div>
        </div>

        {/* buy stack */}
        {tickRowGenerator(data?.buys, 'buy', tickPrecisionDisplayValue)}
      </div>
    );

    //helper
    function tickRowGenerator(orders, direction, tickPrecisionDisplayValue: string, ref?: RefObject<HTMLDivElement>) {
      const isSell = direction === 'sell';
      const _orders = !isMobile() ? orders : isSell ? orders?.slice(-6) : orders?.slice(0, 6);

      return (
        <div
          ref={ref}
          className="overflow-y-scroll md:h-orderbook-viz-stack"
          onMouseMove={() => setHoverPart(direction)}
          onMouseLeave={() => {
            setHoverPart('');
            setHoverPrice(null);
          }}
        >
          <div className={`${isSell ? 'min-h-full flex flex-col justify-end' : ''}`} ref={isSell ? sellRef : null}>
            <Tooltip
              size="220px"
              content={
                hoverInfo.baseAmount?.gt(0) ? (
                  <OrderbookRowDepthTooltipContent
                    rowData={hoverInfo}
                    baseTicker={selectedPairs[0]}
                    quoteTicker={selectedPairs[1]}
                    quoteDenom={selectedPairData?.quoteDenom ?? ''}
                  />
                ) : undefined
              }
              placement={isMobile() ? 'right' : 'left'}
              followCursor={'vertical'}
            >
              {_orders?.map((order) => {
                const isSamePrice = order.price === hoverPrice;
                return (
                  <div
                    className="relative h-[1.5rem] flex items-center justify-between pl-2 overflow-hidden cursor-pointer md:h-[1.25rem] md:pl-3"
                    key={order.price}
                    onMouseEnter={() => setHoverPrice(order.price)}
                    onClick={() =>
                      selectPrice({
                        displayPrice: order.display_price,
                        price: order.price,
                        totalAmount: hoverInfo.baseAmount,
                      })
                    }
                  >
                    {/* amount shadow */}
                    <div aria-hidden="true" className="absolute top-0 right-0 flex flex-row-reverse w-full h-full">
                      <div
                        className={`h-full ${isSell ? 'bg-semantic_red_o24' : 'bg-semantic_green_o24'}`}
                        style={{
                          width: `${
                            Math.trunc(((order.pool_order_amount + order.user_order_amount) * 10000) / max) / 100
                          }%`,
                        }}
                      />
                    </div>

                    {/* price */}
                    <div
                      className={`relative basis-1/2 font_body_number_xs ${
                        isSell ? 'text-semantic_red' : 'text-semantic_green'
                      }`}
                    >
                      {formatAmount(
                        new BigNumber(order.display_price),
                        countDecimal(Number(tickPrecisionDisplayValue)),
                        { fixMantissa: true }
                      )}
                    </div>

                    {/* amount */}
                    <div
                      className={`relative basis-1/2 pr-2 text-right font_body_number_xs md:pr-3 ${
                        isSell ? 'text-semantic_red_variant_light' : 'text-semantic_green_variant_light'
                      }`}
                    >
                      {formatAmount(new BigNumber(order.amount), Math.min(pairAssets?.[0].exponent ?? 0, MAX_DECIMAL), {
                        fixMantissa: true,
                      })}
                    </div>

                    {/* hover layer */}
                    <div
                      className={`absolute right-0 top-0 w-full h-full bg-on_surface_variant_light_o24 border-on_surface border-dashed ${
                        isSamePrice ? (direction === 'sell' ? 'border-t' : 'border-b') : ''
                      } ${
                        hoverPart === direction
                          ? isSell
                            ? Number(hoverPrice) >= Number(order.price)
                              ? ''
                              : 'hidden'
                            : Number(hoverPrice) <= Number(order.price)
                            ? ''
                            : 'hidden'
                          : 'hidden'
                      }`}
                    />
                  </div>
                );
              })}
            </Tooltip>
          </div>
        </div>
      );
    }
  }

  return (
    <Widget size="xs" padding="0" className={className}>
      <Widget.Header title="Orderbook" type="head-area">
        <Widget.Header.TopRight>
          <div className="relative flex items-center">
            {/** @todo add as a type of DropDown */}
            {orderbook.orderbook && (
              <TickPrecisionDropDown
                layer="plain"
                orderbookData={orderbook.orderbook}
                exponentDiff={exponentDiff}
                precision={tickPrecision}
                onSelectTickPrecision={onSelectTickPrecision}
              />
            )}
          </div>
        </Widget.Header.TopRight>
      </Widget.Header>

      <Widget.Body>
        <div className="w-full bg-surface rounded-b-[inherit] overflow-hidden">
          {/* labels */}
          <div className="flex items-center justify-between w-full px-3 pt-2 pb-2 gap-x-2 text-on_surface_variant_dark font_caption_xs md:font_caption_s md:pt-3">
            <div className="text-left">Price ({selectedPairs?.[1] ?? '-'})</div>
            <div className="text-right">Amount ({selectedPairs?.[0] ?? '-'})</div>
          </div>

          {/* orderbook stacks */}
          <div id="orderbook">{selectedPairData ? obGenerator(orderBookData.data, orderBookData.max) : ''}</div>
        </div>
      </Widget.Body>
    </Widget>
  );
};

export default OrderbookWidget;
