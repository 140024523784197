import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import { PortfolioKeys } from 'COMMON_VARIABLES';
import NumberText from 'components/texts/NumberText';
import TooltipInner from 'components/tooltips/TooltipInner';
import TooltipLabel from 'components/tooltips/TooltipLabel';

const TotalPortfolioDetails = ({ data }: { data: { [key in PortfolioKeys]: { amount: BigNumber; dp: number } } }) => {
  return (
    <TooltipInner title="Total">
      <div className="space-y-2">
        {Object.keys(data).map((key, index) => (
          <div key={index} className="flex justify-between items-center gap-x-2">
            <TooltipLabel label={key} />
            <NumberText size="sm" color="plain-mono" value={formatAmount(data[key].amount, data[key].dp)} />
          </div>
        ))}
      </div>
    </TooltipInner>
  );
};

export default TotalPortfolioDetails;
