import IconButton from 'components/buttons/IconButton';
import Icon from 'components/icon';
import RedDot from 'components/pings/RedDot';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo } from 'react';
import useMouseEffect from './hooks/useMouseEffect';
import {
  TableStyles,
  TABLE_ROW_BG_DICT,
  TABLE_ROW_GRID_X_DICT,
  TABLE_ROW_GRID_Y_DICT,
  TABLE_ROW_TYPO,
  getTableCellAlignClassName,
  getTableCellWidthStyle,
  TABLE_ROW_SUBJSX_BORDER_DICT,
  TABLE_CELL_GRID_X_DICT,
  TABLE_CELL_GRID_Y_DICT,
  TABLE_CELL_BORDER_DICT,
  TABLE_EXPAND_ICON_GRID_X,
  TABLE_MOBILE_FOLD_WRAPPER_BG_DICT,
  TABLE_MOBILE_FOLDED_AREA_BG_DICT,
  getHidableAreaClassName,
  TABLE_ROW_HOVER_LAYER_BG_DICT,
  TABLE_ROW_BORDER_DICT,
  TABLE_ROW_RIGHT_PADDING,
} from './styles';
import MobileSubJsxToggleButton from './MobileSubJsxToggleButton';
import TableCell from './TableCell';
import TableFieldCell from './TableFieldCell';
import { TableField, TableRowData } from './types';
import Divider from 'components/divider/Divider';

export type HoverArea = 'row' | 'exclude-sub';

/** row */
type TableRowProps<T extends TableRowData> = {
  data: T;
  fields: TableField<T>[];
  onClick?: (data: T) => void;
  onToggleSubJsx?: (data: T, isOpen: boolean) => void;
  onToggleFoldableOnMobile?: (data: T, isOpen: boolean) => void;
  type: TableStyles;
  hasMouseEffect?: boolean;
  showRowClickIcon?: boolean;
  needRightSpace: boolean;
  striped?: boolean;
  hoverArea?: HoverArea;
};

const TableRow = <T extends TableRowData>({
  data,
  fields,
  onClick,
  onToggleSubJsx,
  onToggleFoldableOnMobile,
  type,
  hasMouseEffect,
  showRowClickIcon = false,
  needRightSpace,
  striped = false,
  hoverArea = 'row',
}: TableRowProps<T>) => {
  const store = useMainStore();

  /** subjsx */
  const isSubJsxOpen = useMemo<boolean>(() => data.isSubJsxOpen ?? true, [data.isSubJsxOpen]);

  const tailwindGroupName = useMemo<string>(
    () => (data.subJsx === undefined ? `group` : 'group/hassub'),
    [data.subJsx]
  );

  const onRowClick = useCallback(() => {
    onClick?.(data);

    if (data.subJsx !== undefined) {
      onToggleSubJsx?.(data, !isSubJsxOpen);
    }
  }, [data, onClick, onToggleSubJsx, isSubJsxOpen]);

  const showSubJsxCloseButton = useMemo<boolean>(
    () => store.isMobile && data.subJsx !== undefined && isSubJsxOpen && onToggleSubJsx !== undefined,
    [store.isMobile, data.subJsx, isSubJsxOpen, onToggleSubJsx]
  );

  /** foldable cells on mobile */
  const isFoldableOpen = useMemo<boolean>(() => data.isFoldableOpen ?? false, [data.isFoldableOpen]);

  const foldableFields = useMemo<TableField<T>[]>(
    () => (store.isMobile ? fields.filter((field) => field.foldableOnMobile) : []),
    [fields, store.isMobile]
  );
  const fixedFields = useMemo<TableField<T>[]>(
    () => (store.isMobile ? fields.filter((field) => !field.foldableOnMobile) : fields),
    [fields, store.isMobile]
  );

  /** row right end space */
  const hasAnyRightEndElement = useMemo<boolean>(() => {
    return (
      data.rightEnd !== undefined ||
      (showRowClickIcon && onClick !== undefined) ||
      data.subJsx !== undefined ||
      data.ping === true
    );
  }, [data.rightEnd, showRowClickIcon, onClick, data.subJsx, data.ping]);

  /** click area */
  const isWholeRowClickable = useMemo<boolean>(() => {
    return !store.isMobile || (store.isMobile && !showRowClickIcon);
  }, [store.isMobile, showRowClickIcon]);

  /** mouse effect */
  const { style, onMouseMove, onMouseLeave } = useMouseEffect(hasMouseEffect);

  const hoverLayerClassName = useMemo<string>(
    () => (onClick || (data.subJsx && onToggleSubJsx) ? `cursor-pointer ${TABLE_ROW_HOVER_LAYER_BG_DICT[type]}` : ''),
    [onClick, data.subJsx, onToggleSubJsx, type]
  );

  return (
    <li
      className={`relative ${TABLE_ROW_BG_DICT[type]} ${striped ? 'even:bg-surface_variant_o24' : ''} ${
        hoverArea === 'row' ? hoverLayerClassName : ''
      } ${TABLE_ROW_BORDER_DICT[type]} ${tailwindGroupName} ${showSubJsxCloseButton ? '!mb-5' : ''}`}
    >
      <div
        className={`relative w-full ${TABLE_ROW_TYPO} ${TABLE_ROW_GRID_X_DICT[type]} ${
          needRightSpace ? TABLE_ROW_RIGHT_PADDING : ''
        } ${TABLE_ROW_GRID_Y_DICT[type]} ${hoverArea === 'exclude-sub' ? hoverLayerClassName : ''}`}
        onClick={isWholeRowClickable ? onRowClick : undefined}
        style={style}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        {/* cells */}
        {fixedFields.map((field, index) => (
          <div
            key={field.value}
            className={`${field.generateClassName?.(data) ?? ''} ${TABLE_CELL_GRID_X_DICT[type]} ${
              TABLE_CELL_GRID_Y_DICT[type]
            } ${TABLE_CELL_BORDER_DICT[type]} ${getTableCellAlignClassName(field, type)}`}
            style={getTableCellWidthStyle(field)}
          >
            {/* field */}
            {![TableStyles.UNCOMPACT, TableStyles.BORDERLESS_COMPACT, TableStyles.BORDERLESS].includes(type) &&
              index > 0 && <TableFieldCell type={type} field={field} className="md:hidden" />}

            {/* cell data */}
            <TableCell data={data} field={field} type={type} />

            {/* ping, expand icon */}
            {hasAnyRightEndElement &&
              ![TableStyles.UNCOMPACT, TableStyles.BORDERLESS_COMPACT, TableStyles.BORDERLESS].includes(type) &&
              index === 0 && (
                <div
                  className={`relative md:absolute md:right-2 md:top-0 md:bottom-0 flex items-center justify-center ${TABLE_EXPAND_ICON_GRID_X[type]}`}
                >
                  <div className="relative w-8 h-8 p-1 flex justify-center items-center">
                    {data.rightEnd ??
                      (showRowClickIcon && onClick ? (
                        isWholeRowClickable ? (
                          <Icon size="24px" type="arrow-right" className="text-on_surface_variant_light" />
                        ) : (
                          <IconButton
                            iconType="arrow-right"
                            size="lg"
                            onClick={store.isMobile ? () => onClick(data) : undefined}
                          />
                        )
                      ) : (
                        data.subJsx !== undefined &&
                        (isWholeRowClickable ? (
                          <Icon
                            size="24px"
                            type={isSubJsxOpen ? 'expandless' : 'expandmore'}
                            className="text-on_surface_variant_light"
                          />
                        ) : (
                          <IconButton
                            iconType={isSubJsxOpen ? 'expandless' : 'expandmore'}
                            size="lg"
                            onClick={store.isMobile ? () => onToggleSubJsx?.(data, !isSubJsxOpen) : undefined}
                          />
                        ))
                      ))}
                    {data.ping && <RedDot size="sm" className="absolute top-0 right-0" />}
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>

      {/* folded cells on mobile */}
      {type !== TableStyles.BORDERLESS_COMPACT && store.isMobile && foldableFields.length > 0 && (
        <>
          {/* folded area */}
          <div
            className={`relative w-full px-3 ${TABLE_MOBILE_FOLDED_AREA_BG_DICT[type]} ${getHidableAreaClassName(
              isFoldableOpen
            )}`}
          >
            <Divider />
            <div className={`flex flex-col gap-y-4 py-3 ${TABLE_ROW_TYPO}`}>
              {foldableFields.map((field) => (
                <div
                  key={field.value}
                  className={`${
                    field.generateClassName?.(data) ?? ''
                  } flex items-center gap-x-1 ${getTableCellAlignClassName(field, type)}`}
                  style={getTableCellWidthStyle(field)}
                >
                  <TableFieldCell type={type} field={field} className="md:hidden" />
                  <TableCell data={data} field={field} type={type} />
                </div>
              ))}
            </div>
          </div>

          <FoldableToggleButton
            type={type}
            isOpen={isFoldableOpen}
            onClick={() => {
              onToggleFoldableOnMobile?.(data, !isFoldableOpen);
            }}
          />
        </>
      )}

      {/* row subjsx */}
      {data.subJsx && (
        <div
          className={`relative box-content ${TABLE_ROW_SUBJSX_BORDER_DICT[type]} ${getHidableAreaClassName(
            isSubJsxOpen
          )}`}
        >
          {data.subJsx}

          {/* mobile fold button */}
          {type !== TableStyles.BORDERLESS_COMPACT && showSubJsxCloseButton && (
            <div className={`absolute -bottom-2 left-0 right-0 flex justify-center items-center`}>
              <MobileSubJsxToggleButton type={type} onClick={() => onToggleSubJsx?.(data, !isSubJsxOpen)} />
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export default TableRow;

/** @desc table row specific buttons */
function FoldableToggleButton({ type, isOpen, onClick }: { type: TableStyles; isOpen: boolean; onClick: () => void }) {
  return (
    <button
      type="button"
      className={`relative w-full h-[2.25rem] flex justify-center items-center gap-x-0.5 rounded-b-[inherit] border-t border-outline_soft hover:opacity-80 ${TABLE_MOBILE_FOLD_WRAPPER_BG_DICT[type]}`}
      onClick={onClick}
    >
      <Icon type={isOpen ? 'subtract' : 'add'} size="14px" />
      <div className="font_label_s">{isOpen ? 'Less' : 'More'}</div>
    </button>
  );
}
