import { MsgHarvest } from '../crescent/farming/types/crescent/farming/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createHarvestMsg = (txData: any, creAddress: any) => {
  const claimData: MsgHarvest = {
    farmer: creAddress,
    stakingCoinDenoms: txData.claimingCoins,
  };
  console.log('claimData', claimData);
  const msg = msgEncoder.msgHarvest(claimData);
  return [msg];
};

export default createHarvestMsg;
