import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo } from 'react';
import { LiquidFarmDetail } from 'types/liquidFarm';

const useLiquidFarm = () => {
  const mainStore = useMainStore();

  const liquidFarms = useMemo<LiquidFarmDetail[]>(() => mainStore.getLiquidFarms(), [mainStore]);

  const getLiquidFarmByPoolId = useCallback(
    (poolId: number) => {
      return liquidFarms.find((liquidFarm) => liquidFarm.poolId === poolId);
    },
    [liquidFarms]
  );

  return { liquidFarms, getLiquidFarmByPoolId };
};
export default useLiquidFarm;
