import { useState, useEffect, useMemo } from 'react';
import { useMainStore } from 'provider/mainContext';
import { useOrderbook } from 'hooks/useAPI';
import usePools from 'hooks/usePools';
import _, { isArray } from 'lodash';
import { Orderbook, OrderbookPair } from 'types/orderbook';

export default function useOrderbookData(selectedPairId: string, precision = 3) {
  const mainStore = useMainStore();

  const { data: orderbookData } = useOrderbook(selectedPairId, precision, 2000);
  const [updateToggle, setUpdateToggle] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
  const pools = usePools();

  const orderbookPairs = useMemo<OrderbookPair[]>(() => {
    // REFACTOR: seperate orderbookPairs / orderbook
    const pairs = mainStore.getPairs();
    if (pairs.length > 0 && pools.length) {
      const _incentivisedPoolIndexer: Record<number, boolean> = {};
      pools.forEach((pool) => {
        if (pool.RewardsPerToken) {
          _incentivisedPoolIndexer[pool.pairId] = true;
        } else {
          if (!_incentivisedPoolIndexer[pool.pairId]) {
            _incentivisedPoolIndexer[pool.pairId] = false;
          }
        }
      });

      const _incentivisedPairs: Record<string, OrderbookPair[]> = { bCRE: [], CRE: [], ETH: [], USDC: [] };
      const _incentivisedPairsOthers: OrderbookPair[] = [];
      const _noRewardPairs: Record<string, OrderbookPair[]> = { bCRE: [], CRE: [], ETH: [], USDC: [] };
      const _noRewardPairsOthers: OrderbookPair[] = [];
      const _quotePairs = ['bCRE', 'CRE'];
      mainStore.getPairs()?.forEach((pair) => {
        const _result = {
          ...pair,
          title: `${mainStore.assetsData.info[pair.baseDenom]?.ticker}/${
            mainStore.assetsData.info[pair.quoteDenom]?.ticker
          }`,
          baseOraclePrice:
            pair.totalReserved.filter((reserve) => {
              return reserve.denom === pair.baseDenom;
            })[0]?.priceOracle ?? 0,
          baseInfo: mainStore.assetsData.info[pair.baseDenom],
          quoteInfo: mainStore.assetsData.info[pair.quoteDenom],
          exponentDiff:
            mainStore.assetsData.info[pair.baseDenom]?.exponent -
              mainStore.assetsData.info[pair.quoteDenom]?.exponent ?? 0,
        };
        const quoteTicker = _result.title.split('/')[1];
        if (_incentivisedPoolIndexer[pair.pairId]) {
          if (_quotePairs.includes(quoteTicker)) {
            _incentivisedPairs[quoteTicker].push(_result);
          } else if (quoteTicker.includes('ETH')) {
            _incentivisedPairs.ETH.push(_result);
          } else if (quoteTicker.includes('USDC')) {
            _incentivisedPairs.USDC.push(_result);
          } else {
            _incentivisedPairsOthers.push(_result);
          }
        } else {
          if (_quotePairs.includes(quoteTicker)) {
            _noRewardPairs[quoteTicker].push(_result);
          } else if (quoteTicker.includes('ETH')) {
            _noRewardPairs.ETH.push(_result);
          } else if (quoteTicker.includes('USDC')) {
            _noRewardPairs.USDC.push(_result);
          } else {
            _noRewardPairsOthers.push(_result);
          }
        }
      });
      const _tempPairs = [
        Object.values(_incentivisedPairs),
        _incentivisedPairsOthers,
        Object.values(_noRewardPairs),
        _noRewardPairsOthers,
      ];
      const _pairs = _tempPairs.map((pair) => {
        if (pair.some((p) => isArray(p))) {
          return _.flatten(
            pair.map((p) => {
              return p.sort((a, b) => {
                if (a.baseInfo.ticker.toUpperCase() === b.baseInfo.ticker.toUpperCase()) {
                  return a.quoteInfo.ticker.toUpperCase() > b.quoteInfo.ticker.toUpperCase() ? 0 : -1;
                } else {
                  return a.baseInfo.ticker.toUpperCase() > b.baseInfo.ticker.toUpperCase() ? 0 : -1;
                }
              });
            })
          );
        } else {
          return pair.sort((a, b) => {
            if (a.baseInfo.ticker.toUpperCase() === b.baseInfo.ticker.toUpperCase()) {
              return a.quoteInfo.ticker.toUpperCase() > b.quoteInfo.ticker.toUpperCase() ? 0 : -1;
            } else {
              return a.baseInfo.ticker.toUpperCase() > b.baseInfo.ticker.toUpperCase() ? 0 : -1;
            }
          });
        }
      });
      return _.flatten(_pairs);
    } else {
      return [];
    }
  }, [mainStore.assetsData, mainStore.pairsData, updateToggle, pools]);

  useEffect(() => {
    let intervalId;
    if (orderbookPairs.length === 0) {
      intervalId = setInterval(() => {
        setUpdateToggle((updateToggle) => !updateToggle);
      }, 300);
      setIntervalId(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (orderbookPairs.length > 0) {
      clearInterval(Number(intervalId));
    }
  }, [orderbookPairs.length]);

  const selectedOrderbookPair = useMemo<OrderbookPair | undefined>(
    () => orderbookPairs.find((pair) => pair.pairId === Number(selectedPairId)),
    [orderbookPairs, selectedPairId]
  );

  const orderbook = useMemo<Orderbook | undefined>(() => orderbookData?.data?.[0], [orderbookData?.data]);

  return { selectedOrderbookPair, orderbook, orderbookPairs };
}
