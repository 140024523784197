import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import WalletWidget from 'components/widgets/WalletWidget';
import SettingWidget from 'components/widgets/SettingWidget';
import Button from 'components/buttons/Button';
import NavItem from 'components/nav/NavItem';
import AppLogo from 'components/logos/AppLogo';

import { IS_PRODUCTION, PAGES } from 'COMMON_VARIABLES';
import { useMainStore } from 'provider/mainContext';

const AppHeader = ({ className = '' }: { className?: string }) => {
  /** @desc legacy faucet states and callback moved into this hook */
  // useFaucet()

  const store = useMainStore();

  return (
    <header className="relative w-full min-h-navbar px-4 py-3 flex items-center justify-between gap-x-4 bg-transparent">
      <nav className="flex items-center gap-x-4">
        <NavLink to="/trade">
          <AppLogo />
        </NavLink>

        {/* nav links */}
        <div className="hidden md:flex items-center gap-x-1">
          {PAGES.filter((page) => !page.mobileOnly).map((page) => (
            <NavItem key={page.path} page={page} />
          ))}
        </div>
      </nav>

      <div className="flex items-center gap-x-3">
        <Button
          label="Faucet"
          type="outlined"
          size={store.isMobile ? 'sm' : 'md'}
          leadingIcon="waterDrop"
          href={IS_PRODUCTION ? 'https://faucet.crescent.network/' : 'https://testnet-faucet.crescent.network/'}
        />

        <WalletWidget />

        <SettingWidget />
      </div>
    </header>
  );
};

export default observer(AppHeader);
