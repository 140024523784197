import msgEncoder from 'common/sign/msgEncoder';
import type { Coin } from 'common/crescent/liquidfarming/types/cosmos/base/v1beta1/coin';
import type { MsgLiquidFarm } from 'common/crescent/liquidfarming/types/crescent/liquidfarming/v1beta1/tx';
import type { EncodeObject } from '@cosmjs/proto-signing';

export type LiquidFarmTxData = {
  poolId: number;
  farmingCoin: Coin | undefined;
};

const createLiquidFarmMsg = (txData: LiquidFarmTxData, creAddress: string): EncodeObject[] => {
  const liquidFarmData: MsgLiquidFarm = {
    farmer: creAddress,
    poolId: txData.poolId,
    farmingCoin: txData.farmingCoin,
  };
  console.log('liquidFarmData', liquidFarmData);
  const msg = msgEncoder.msgLiquidFarm(liquidFarmData);
  return [msg];
};

export default createLiquidFarmMsg;
