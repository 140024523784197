type TooltipLabelSize = 'xs' | 'sm';

const TOOLTIP_LABEL_FONT_DICT: { [key in TooltipLabelSize]: string } = {
  xs: 'font_caption_xs',
  sm: 'font_caption_s',
};

const TooltipLabel = ({
  size = 'sm',
  label,
  labelSeperator = '',
  value,
  className,
}: {
  size?: TooltipLabelSize;
  label: string;
  labelSeperator?: ':' | '';
  value?: JSX.Element;
  className?: string;
}) => {
  return (
    <div className="flex justify-between items-center gap-x-3">
      <div
        className={`flex items-baseline gap-x-0.5 text-on_surface_variant_light ${TOOLTIP_LABEL_FONT_DICT[size]} ${className}`}
      >
        <div>{label}</div>
        {labelSeperator !== '' && <div>{labelSeperator}</div>}
      </div>
      {value && <div>{value}</div>}
    </div>
  );
};

export default TooltipLabel;
