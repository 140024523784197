import ViewAllButton from 'components/buttons/ViewAllButton';
import { useProposals } from 'hooks/useAPI';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useMemo, useState } from 'react';
import Table from 'components/table/Table';
import { Proposal, ProposalStatus, VoteOptionNumber } from 'types/gov';
import Chip, { ChipColor } from 'components/chips/Chip';
import { useNavigate } from 'react-router-dom';
import VotingVisualizer from './VotingVisualizer';
import ProposalDepositVisualizer from './ProposalDepositVisualizer';
import ProposalStatusChip from './ProposalStatusChip';
import Icon from 'components/icon';
import { TableField } from 'components/table/types';

type ProposalsTableRow = {
  mobileTitle: JSX.Element;
  proposalId: number;
  proposalIdLabel: JSX.Element;
  title: string;
  content: JSX.Element;
  status: string;
  statusLabel: JSX.Element;
  myVote: number;
  myVoteLabel: JSX.Element;
  proposer: string;
  proposerLabel: JSX.Element;
  subJsx?: JSX.Element;
};

const MOBILE_FILEDS: TableField<ProposalsTableRow>[] = [
  {
    label: 'Title',
    value: 'mobileTitle',
    type: 'jsx',
    sortDisabled: true,
  },
];

const MD_FIELDS: { prefix: TableField<ProposalsTableRow>[]; suffix: TableField<ProposalsTableRow>[] } = {
  prefix: [
    {
      label: '#',
      value: 'proposalIdLabel',
      type: 'jsx',
      sortValue: 'proposalId',
      sortType: 'number',
      sortDisabled: true,
      widthRatio: 4,
    },
    {
      label: 'Title / Brief content',
      value: 'content',
      type: 'jsx',
      sortValue: 'title',
      sortType: 'string',
      sortDisabled: true,
      widthPx: 480,
    },
  ],
  suffix: [
    {
      label: 'Proposer',
      value: 'proposerLabel',
      type: 'jsx',
      sortValue: 'proposer',
      sortType: 'string',
      sortDisabled: true,
      widthPx: 100,
    },
  ],
};

export type ProposalType = 'live' | 'deposit' | 'past';

const PROPOSAL_STATUS_DICT: { [key in ProposalType]: ProposalStatus[] } = {
  live: ['PROPOSAL_STATUS_VOTING_PERIOD'],
  deposit: ['PROPOSAL_STATUS_DEPOSIT_PERIOD'],
  past: ['PROPOSAL_STATUS_PASSED', 'PROPOSAL_STATUS_REJECTED'],
};

const UNVOTED_LABEL_DICT: { [key in ProposalType]: { label: string; chipColor: ChipColor } } = {
  live: { label: 'NOT VOTED', chipColor: 'gray' },
  deposit: { label: 'DISABLED', chipColor: 'gray' },
  past: { label: 'NOT VOTED', chipColor: 'gray' },
};

const VOTE_OPTION_LABEL_DICT: { [key in VoteOptionNumber]: { label: string; chipColor: ChipColor } } = {
  0: { label: 'Unspecified', chipColor: 'gray' },
  1: { label: 'Yes', chipColor: 'yes' },
  2: { label: 'Abstain', chipColor: 'abstain' },
  3: { label: 'No', chipColor: 'no' },
  4: { label: 'NoWithVeto', chipColor: 'veto' },
};

type ProposalsTableProps = {
  type: ProposalType;
  pageSize?: number;
};

const ProposalsTable = ({ type, pageSize = 10 }: ProposalsTableProps) => {
  const mainStore = useMainStore();
  const { data: propsalsData, isLoading } = useProposals(mainStore.wallet.address || 'anonymous');

  /** @desc new */
  const proposals = useMemo<Proposal[]>(
    () =>
      propsalsData?.data?.filter((proposal) =>
        PROPOSAL_STATUS_DICT[type].includes(proposal.proposal.status as ProposalStatus)
      ) ?? [],
    [propsalsData, type]
  );

  const [page, setPage] = useState<number>(1);

  const tableRows = useMemo<ProposalsTableRow[]>(() => {
    return proposals.slice(0, pageSize * page).map((proposal) => {
      const voteOptionChip = proposal.voteOption ? VOTE_OPTION_LABEL_DICT[proposal.voteOption.option] : undefined;
      const voteStatusChip: { label: string; chipColor: ChipColor; subLabel?: string; subColor?: ChipColor } =
        voteOptionChip
          ? { label: 'VOTED', chipColor: 'blue', subLabel: voteOptionChip.label, subColor: voteOptionChip.chipColor }
          : UNVOTED_LABEL_DICT[type];

      const proposalIdLabel = (
        <div className="text-on_surface font_caption_number_s md:font_caption_number_m">#{proposal.proposalId}</div>
      );

      const title = proposal.proposal.content.title;
      const content = (
        <div className="w-[calc(100vw_-_4rem)] space-y-1 md:w-[480px]">
          <div className="font_title_s whitespace-normal text-on_surface md:truncate md:font_title_m">{title}</div>
          <div className="font_caption_xs truncate text-on_surface_variant_dark md:font_caption_s">
            {proposal.proposal.content.description}
          </div>
        </div>
      );

      const mobileTitle = (
        <div className="w-full space-y-2">
          <div className="w-full flex justify-between items-center gap-x-2">
            {proposalIdLabel}

            <div className="flex items-center gap-x-0.5 text-on_surface_variant_light">
              <Icon type="account-circle" size="12px" />
              <div className="w-[86px] truncate font_caption_xs">{proposal.proposer}</div>
            </div>
          </div>

          {content}
        </div>
      );

      const subJsx =
        type !== 'past' ? (
          <div className="px-3 pb-4 md:px-4">
            <div className="px-3 bg-secondary_container_o24 rounded-4px md:px-4">
              {type === 'live' ? (
                <VotingVisualizer proposal={proposal} />
              ) : (
                <ProposalDepositVisualizer proposal={proposal} />
              )}
            </div>
          </div>
        ) : undefined;

      return {
        mobileTitle,
        proposalId: proposal.proposalId,
        proposalIdLabel,
        title,
        content,
        status: proposal.proposal.status,
        statusLabel: <ProposalStatusChip proposal={proposal} chipSize={mainStore.isMobile ? 'sm' : 'md'} />,
        myVote: proposal.voteOption?.option ?? -1,
        myVoteLabel: (
          <Chip
            size={mainStore.isMobile ? 'sm' : 'md'}
            label={voteStatusChip.label}
            color={voteStatusChip.chipColor}
            subLabel={voteStatusChip.subLabel}
            subColor={voteStatusChip.subColor}
          />
        ),
        proposer: proposal.proposer,
        proposerLabel: <div className="w-[100px] truncate font_body_s text-on_surface">{proposal.proposer}</div>,
        subJsx,
      };
    });
  }, [proposals, pageSize, page, type, mainStore.isMobile]);

  const navigate = useNavigate();

  const onRowClick = useCallback((row: ProposalsTableRow) => navigate(`/gov/${row.proposalId}`), [navigate]);

  const viewMoreCount = useMemo<number>(() => proposals.length - pageSize * page, [proposals.length, pageSize, page]);

  return (
    <div className="flex flex-col items-center">
      <Table<ProposalsTableRow>
        noDataLabel="No proposal found"
        isLoading={isLoading}
        rows={tableRows}
        onRowClick={onRowClick}
        showRowClickIcon={!mainStore.isMobile}
        fields={[
          ...(mainStore.isMobile ? MOBILE_FILEDS : MD_FIELDS.prefix),
          {
            label: 'Proposal status',
            value: 'statusLabel',
            type: 'jsx',
            sortValue: 'status',
            sortType: 'string',
            sortDisabled: true,
            widthRatio: 13,
          },
          {
            label: 'My vote status',
            value: 'myVoteLabel',
            type: 'jsx',
            sortValue: 'myVote',
            sortType: 'string',
            sortDisabled: true,
          },
          ...(mainStore.isMobile ? [] : MD_FIELDS.suffix),
        ]}
      />

      {viewMoreCount > 0 && (
        <ViewAllButton
          openLabel="View More"
          count={viewMoreCount}
          closeLabel="Close Details"
          onClick={() => {
            setPage(page + 1);
          }}
          className="mt-6"
        />
      )}
    </div>
  );
};

export default ProposalsTable;
