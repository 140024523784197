import React, { useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { getDollarValueOnCreChainByDenom, formatAmount, getChangeSymbol, formatUSD } from 'common/utils';
import Icon from 'components/icon';
import { OrderbookPair } from 'types/orderbook';
import Panel from 'components/panel/Panel';
import Coin from 'components/coins/Coin';
import PairTicker from 'components/pools/PairTicker';
import PairSelectPopover from './PairSelectPopover';
import LoadingRows from 'components/skeletons/LoadingRows';
import { getChangeTextColor } from '../styles';
import { useMainStore } from 'provider/mainContext';
import Chip from 'components/chips/Chip';
import IconButton from 'components/buttons/IconButton';

/** @desc OrderbookPairTitle */
const OrderbookPairTitle = ({
  pair,
  onEnter,
  className = '',
}: {
  pair: OrderbookPair;
  onEnter?: () => void;
  className?: string;
}) => {
  const store = useMainStore();

  return store.isMobile ? (
    <PairTicker pair={pair} size="md" isWeakQuote={true} />
  ) : (
    <button type="button" className={`flex items-center ${className}`} onMouseEnter={onEnter} onClick={onEnter}>
      <div className="flex items-center gap-x-2">
        <div className="w-6 h-6 flex items-center justify-center  md:w-9 md:h-9">
          <Icon type="sync-alt" size="24px" className="text-on_surface_variant_light" />
        </div>

        <Coin asset={pair.baseInfo} className="box_glow_1" />
        <PairTicker pair={pair} size="lg" />
      </div>
    </button>
  );
};

/** @desc OrderbookPriceTitle */
const OrderbookPriceTitle = ({ pair, align }: { pair: OrderbookPair; align?: 'left' | 'right' }) => {
  return (
    <div className="md:space-y-0.5 text-right md:text-left">
      <div className={`font_data_number_s md:font_data_number_l ${getChangeTextColor(pair.change_24)}`}>
        {formatAmount(pair.lastPrice.shiftedBy(pair.exponentDiff), 8)}
      </div>

      <div className="font_caption_number_xs md:font_caption_number_m text-on_surface_variant_dark">
        ≈{' '}
        {formatUSD(
          new BigNumber(
            getDollarValueOnCreChainByDenom(
              pair.quoteInfo.denom,
              new BigNumber(pair.lastPrice).shiftedBy(pair.exponentDiff).toString()
            )
          ),
          { fixedTo2: false }
        )}
        {/* {formatAmount(
          new BigNumber(
            getDollarValueOnCreChainByDenom(
              pair.quoteInfo.denom,
              new BigNumber(pair.lastPrice).shiftedBy(pair.exponentDiff).toString()
            )
          ),
          6,
          '$',
          { fixMantissa: true, minimumMantissa: 2 }
        )} */}
      </div>
    </div>
  );
};

const OrderbookIndex = ({
  label,
  numberValue,
  change = 0,
}: {
  label: string;
  numberValue: string;
  change?: number;
}) => {
  return (
    <div className="text-left space-y-0.5">
      <div className="font_caption_m text-on_surface_variant_dark">{label}</div>
      <div className={`font_data_number_s ${getChangeTextColor(change)}`}>{numberValue}</div>
    </div>
  );
};

/** @desc OrderbookHeader */
type OrderbookHeaderProps = {
  pair: OrderbookPair | undefined;
  availabePairs: OrderbookPair[];
  onSelectPair: (pairId: string) => void;
};

const OrderbookHeader = ({ pair, availabePairs, onSelectPair }: OrderbookHeaderProps) => {
  const [isPairSelectorOpen, setIsPairSelectorOpen] = useState<boolean>(false);

  const change24Label = useMemo<string>(
    () =>
      pair
        ? `${pair.change_24 === 100000 ? '' : getChangeSymbol(pair.change_24)}${
            pair.change_24 === 100000 ? '-' : formatAmount(new BigNumber(Math.abs(pair.change_24)), 2) + '%'
          }`
        : '',
    [pair]
  );

  const store = useMainStore();

  return (
    <header className="relative w-full">
      <Panel type="outline-soft" padding="12px" className="md:min-h-[4.75rem]">
        {pair ? (
          <div className="flex items-center justify-between gap-x-2 md:justify-start md:gap-x-10 md:py-1">
            {/* title */}
            <div className="flex items-center gap-x-2">
              <OrderbookPairTitle pair={pair} onEnter={() => setIsPairSelectorOpen(true)} />

              <div className="md:hidden">
                <Chip
                  size="xs"
                  color={pair.change_24 > 0 ? 'green' : pair.change_24 < 0 ? 'red' : 'gray'}
                  label={change24Label}
                  labelType="number"
                />
              </div>
            </div>

            <div className="md:hidden">
              <IconButton iconType="expandmore" size="lg" onClick={() => setIsPairSelectorOpen(!isPairSelectorOpen)} />
            </div>

            {/* price */}
            <div className="hidden md:flex items-center gap-x-10">
              <OrderbookPriceTitle pair={pair} />

              <div className="flex items-center gap-x-7">
                <OrderbookIndex label="24h Change" numberValue={change24Label} change={pair.change_24} />

                <OrderbookIndex label="24h High" numberValue={formatAmount(new BigNumber(pair.high_24), 6)} />
                <OrderbookIndex label="24h Low" numberValue={formatAmount(new BigNumber(pair.low_24), 6)} />
                <OrderbookIndex
                  label={`24h Volume (${pair.baseInfo.ticker})`}
                  numberValue={formatAmount(new BigNumber(pair.vol_24), 6)}
                />
              </div>
            </div>
          </div>
        ) : (
          <LoadingRows rowsCnt={1} loadingDataTypoClassName="font_headline_number_l" />
        )}
      </Panel>

      <PairSelectPopover
        type={store.isMobile ? 'modal' : 'popover'}
        isOpen={isPairSelectorOpen}
        pairList={availabePairs}
        selectedPairId={pair?.pairId.toString() ?? ''}
        selectPair={onSelectPair}
        onClose={() => setIsPairSelectorOpen(false)}
        className="absolute left-0 top-[5.25rem] z-[1] elevation_dark_4"
      />
    </header>
  );
};

export default React.memo(OrderbookHeader);
