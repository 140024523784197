import { MsgCancelOrder } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createCancelOrderMsg = (txData: any, creAddress: any) => {
  const CancelOrderData: MsgCancelOrder = {
    orderer: creAddress,
    pairId: Number(txData.pairId),
    orderId: Number(txData.orderId),
  };
  const msg = msgEncoder.msgCancelOrder(CancelOrderData);
  return [msg];
};

export default createCancelOrderMsg;
