import BigNumber from 'bignumber.js';
import { ButtonStatus } from 'components/buttons/Button';
import { addChainsToWallet } from 'connection/utils';
import { useCallback, useMemo, useState } from 'react';
import type { InfoAsset } from 'types/asset';
import type { InfoChain } from 'types/chain';
import { Wallet, WalletType } from 'types/wallet';

const useTokenTransferButton = ({
  isLoading,
  chainId,
  txChain,
  txChainWallet,
  connectedWallet,
  asset,
  amount,
  balance,
  activeLabel,
  selectChainLabel = 'Select chain',
  selectTokenLabel = 'Select token',
  transferFunc,
}: {
  isLoading: boolean;
  chainId: string | undefined;
  txChain: InfoChain | undefined;
  txChainWallet: Wallet | null;
  connectedWallet: Wallet | null;
  asset: InfoAsset | undefined;
  amount: BigNumber;
  balance: BigNumber;
  activeLabel: string;
  selectChainLabel?: string;
  selectTokenLabel?: string;
  transferFunc?: () => void;
}) => {
  const [isAddingChain, setIsAddingChain] = useState<boolean>(false);

  const needToAddChain = useMemo<boolean>(() => {
    return (
      txChainWallet === null &&
      (connectedWallet?.type === WalletType.COSMOSTATION || connectedWallet?.type === WalletType.KEPLR)
    );
  }, [txChainWallet, connectedWallet?.type]);

  const onClickAddChain = useCallback(async () => {
    if (
      txChain &&
      connectedWallet &&
      connectedWallet.type !== null &&
      (connectedWallet?.type === WalletType.COSMOSTATION || connectedWallet?.type === WalletType.KEPLR)
    ) {
      setIsAddingChain(true);
      await addChainsToWallet(connectedWallet.type, [txChain]);
      setIsAddingChain(false);
    }
  }, [connectedWallet, txChain]);

  return useMemo<{
    status: ButtonStatus;
    text: string;
    onClick?: () => void;
    needToAddChain?: boolean;
  }>(() => {
    if (isLoading || isAddingChain) {
      return {
        status: 'loading',
        text: '',
      };
    }

    if (chainId === undefined) {
      return {
        status: 'disabled',
        text: selectChainLabel,
      };
    }

    if (needToAddChain) {
      return {
        status: 'enabled',
        text: `Enable ${txChain?.chainId.toUpperCase() ?? 'chain'}`,
        onClick: onClickAddChain,
        needToAddChain,
      };
    }

    if (txChainWallet === null) {
      return {
        status: 'disabled',
        text: 'Unsupported chain',
      };
    }

    if (asset === undefined) {
      return {
        status: 'disabled',
        text: selectTokenLabel,
      };
    }

    if (!balance.gt(0) || amount.gt(balance)) {
      return {
        status: 'disabled',
        text: 'Insufficient balance',
      };
    }

    if (balance.gt(0) && !amount.gt(0)) {
      return {
        status: 'disabled',
        text: 'Enter amount',
      };
    }

    return {
      status: 'enabled',
      text: activeLabel,
      onClick: transferFunc,
    };
  }, [
    isLoading,
    isAddingChain,
    needToAddChain,
    onClickAddChain,
    chainId,
    txChain,
    txChainWallet,
    asset,
    balance,
    amount,
    activeLabel,
    selectChainLabel,
    selectTokenLabel,
    transferFunc,
  ]);
};

export default useTokenTransferButton;
