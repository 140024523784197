import BigNumber from 'bignumber.js';
import { INITIAL_ASSET, InvestmentKeys } from 'COMMON_VARIABLES';
import { TokenTypes } from 'components/constants/token';
import useMyPools from 'hooks/farm/useMyPools';
import useMyAssets from 'hooks/portfolio/useMyAssets';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { CoinDetail, AssetDetail } from 'types/asset';
import type { Unbonding } from 'types/balance';
import type { PoolDetail } from 'types/pool';

const MAX_REWARD_TOKENS_CNT = 3;

const useMyPortfolio = ({
  pools,
  assets,
  unbondings,
}: {
  pools: PoolDetail[];
  assets: AssetDetail[];
  unbondings: Unbonding[];
}) => {
  const { myPools, isLoading } = useMyPools(pools);

  const rewardsByRewardToken = useMemo<CoinDetail[]>(() => {
    const RewardsByRewardDenomMap = myPools.reduce<{ [denom: string]: CoinDetail }>((accm, pool) => {
      pool.claimableRewards.forEach((reward) => {
        if (reward.amount.gt(0)) {
          const prev = accm[reward.asset.denom];
          accm[reward.asset.denom] = {
            asset: reward.asset,
            amount: reward.amount.plus(prev?.amount ?? 0),
            amountUSD: reward.amountUSD.plus(prev?.amountUSD ?? 0),
          };
        }
      });
      return accm;
    }, {});

    return Object.values(RewardsByRewardDenomMap);
  }, [myPools]);

  const visibleRewards = useMemo<CoinDetail[]>(
    () => rewardsByRewardToken.slice(0, MAX_REWARD_TOKENS_CNT),
    [rewardsByRewardToken]
  );
  const hiddenRewards = useMemo<CoinDetail[]>(
    () => rewardsByRewardToken.slice(MAX_REWARD_TOKENS_CNT),
    [rewardsByRewardToken]
  );

  const totalRewardsUSD = useMemo<BigNumber>(
    () => rewardsByRewardToken.reduce((accm, reward) => accm.plus(reward.amountUSD), new BigNumber(0)),
    [rewardsByRewardToken]
  );

  const { myAssets, totalUSD: totalAssetsUSD } = useMyAssets(assets);

  const totalInvestmentsUSDByType = useMemo<{
    [key in InvestmentKeys]: {
      amount: BigNumber;
      dp?: number;
    };
  }>(() => {
    const poolsUSD = myPools.reduce<{ farming: BigNumber; unfarming: BigNumber }>(
      (accm, pool) => {
        accm.farming = accm.farming.plus(pool.myStakingUSD);
        accm.unfarming = accm.unfarming.plus(pool.myBalanceUSD);
        return accm;
      },
      { farming: new BigNumber(0), unfarming: new BigNumber(0) }
    );

    const lfTokensUSD = myAssets
      .filter((asset) => asset.tokenType === TokenTypes.LF)
      .reduce((accm, asset) => {
        const balanceUSD = asset.availableBalance.shiftedBy(-asset.exponent).multipliedBy(asset.priceOracle);
        return accm.plus(balanceUSD);
      }, new BigNumber(0));

    return {
      [InvestmentKeys.FARMING]: {
        amount: poolsUSD.farming,
      },
      [InvestmentKeys.POOL_TOKENS]: {
        amount: poolsUSD.unfarming,
      },
      [InvestmentKeys.LF_TOKENS]: {
        amount: lfTokensUSD,
      },
      [InvestmentKeys.REWARDS]: {
        amount: totalRewardsUSD,
        dp: 6,
      },
    };
  }, [myPools, myAssets, totalRewardsUSD]);

  const totalInvestmentUSD = useMemo<BigNumber>(
    () =>
      Object.keys(totalInvestmentsUSDByType).reduce(
        (accm, key) => accm.plus(totalInvestmentsUSDByType[key].amount),
        new BigNumber(0)
      ),
    [totalInvestmentsUSDByType]
  );

  const store = useMainStore();

  const totalUnbondingUSD = useMemo<BigNumber>(() => {
    const priceOracle = store.assetsData.live[INITIAL_ASSET.denom]?.priceOracle ?? 0;

    return unbondings.reduce((accm, unbonding) => {
      const receiveAmountUSD = new BigNumber(unbonding.unbondingAmount)
        .shiftedBy(-INITIAL_ASSET.exponent)
        .multipliedBy(priceOracle);
      return accm.plus(receiveAmountUSD);
    }, new BigNumber(0));
  }, [unbondings, store.assetsData.live]);

  return {
    isLoading,
    myPools,
    rewardsByRewardToken,
    visibleRewards,
    hiddenRewards,
    totalRewardsUSD,
    totalInvestmentsUSDByType,
    totalInvestmentUSD,
    myAssets,
    totalAssetsUSD,
    totalUnbondingUSD,
  };
};

export default useMyPortfolio;
