import useTradeSearch from 'hooks/trade/useTradeSearch';
import useOrderbookData from 'hooks/useOrderbookData';
import { useMainStore } from 'provider/mainContext';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PairDetail } from 'types/pair';

const useTradePairSelect = () => {
  const [selectedPairId, setSelectedPairId] = useState<string>();
  const [orderIndex, setOrderIndex] = useState<0 | 1>(0);

  const { selectedOrderbookPair, orderbookPairs } = useOrderbookData(selectedPairId ?? '', 3);

  const store = useMainStore();

  /** @summary orderbook ↔︎ swap search(from, to) sync */
  const { navigateAndStorePair, getTradeAssetsFromLocationSearch } = useTradeSearch(store);

  const setSearchedPair = useCallback((): boolean => {
    const [payAsset, receiveAsset] = getTradeAssetsFromLocationSearch();

    const searchedPair =
      payAsset &&
      receiveAsset &&
      store
        .getPairs()
        .find(
          (pair) =>
            pair.assets.filter((asset) => [payAsset.denom, receiveAsset.denom].includes(asset.denom)).length === 2
        );

    if (searchedPair) {
      setSelectedPairId(String(searchedPair.pairId));
      localStorage.setItem('orderbookPair', searchedPair.pairId.toString());

      const isForward = searchedPair.assets[1].denom === payAsset?.denom;
      setOrderIndex(isForward ? 0 : 1);
      store.setOrderbookPairDirection(isForward ? 'forward' : 'backward');
    }

    return searchedPair !== undefined;
  }, []);

  const location = useLocation();

  const setStoredPair = useCallback(() => {
    const storedPairId = localStorage.getItem('orderbookPair') ?? '1';
    const storedDirection = store.getOrderbookPairDirection();

    /** @summary set default */
    const allPairs = store.getPairs();

    const pair: PairDetail | undefined = allPairs.find((pair) => pair.pairId === Number(storedPairId)) ?? allPairs[0];
    const isForward = storedPairId ? storedDirection === 'forward' : true;

    if (pair) {
      setSelectedPairId(String(pair.pairId));
      setOrderIndex(isForward ? 0 : 1);

      navigateAndStorePair({
        path: location.pathname,
        pair,
        isForward,
      });
    }
  }, []);

  useEffect(() => {
    if (store.assetsData.isInit && store.pairsData.isInit && store.poolsData.isInit) {
      if (!setSearchedPair()) setStoredPair();
    }
  }, [store.assetsData.isInit, store.pairsData.isInit, store.poolsData.isInit]);

  const onSelectPairId = useCallback(
    (pairId: string) => {
      const pair = store.getPairs().find((pair) => pair.pairId === Number(pairId));

      if (pair) {
        navigateAndStorePair({
          path: location.pathname,
          pair,
          isForward: true,
        });

        setSelectedPairId(pairId);
        setOrderIndex(0);
      }
    },
    [location.pathname]
  );

  const onOrderIndexChange = useCallback(
    (index: 0 | 1) => {
      if (selectedOrderbookPair) {
        navigateAndStorePair({
          path: location.pathname,
          pair: selectedOrderbookPair,
          isForward: index === 0,
        });
      }
    },
    [selectedOrderbookPair, location.pathname]
  );

  return {
    selectedOrderbookPair,
    orderbookPairs,
    selectedPairId,
    onSelectPairId,
    orderIndex,
    onOrderIndexChange,
  };
};

export default useTradePairSelect;
