import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useMainStore } from 'provider/mainContext';
import Popover from 'components/popover/Popover';
import SearchInput from 'components/inputs/SearchInput';
import type { OrderbookPair } from 'types/orderbook';
import OrderbookPairTable from '../orderbookPairTable';
import { PopoverType } from 'components/popover/types';
import TransitionModal from 'components/modals/TransitionModal';
import ModalHeader from 'components/modals/components/ModalHeader';
import { MODAL_TITLES } from 'COMMON_VARIABLES';

type PairSelectPopoverProps = {
  type?: PopoverType;
  isOpen?: boolean;
  pairList: OrderbookPair[];
  selectedPairId: string;
  selectPair: (id: string) => void;
  className?: string;
  onClose?: () => void;
};

const PairSelectPopover = ({
  type = 'popover',
  isOpen = false,
  pairList,
  selectedPairId,
  selectPair,
  className = '',
  onClose,
}: PairSelectPopoverProps) => {
  const [keyword, setKeyword] = useState('');

  const onClickPair = useCallback(
    (pair: OrderbookPair) => {
      selectPair(String(pair.pairId));
      localStorage.setItem('orderbookPair', pair.pairId.toString());

      onClose?.();
    },
    [selectPair, onClose]
  );

  const filteredPairs = useMemo<OrderbookPair[]>(() => {
    return pairList.filter((pair) => pair.title.toUpperCase().includes(keyword.toUpperCase()));
  }, [pairList, keyword]);

  const PairSelectPopoverContent = useMemo<JSX.Element>(
    () => (
      <>
        <div className="p-4 pt-0 md:p-5">
          <SearchInput value={keyword} onChangeValue={setKeyword} />
        </div>
        <OrderbookPairTable
          isGridXOffset={true}
          rowsScrollHeight="300px"
          pairs={filteredPairs}
          onClickPair={onClickPair}
        />
      </>
    ),
    [filteredPairs, keyword, onClickPair]
  );

  return type === 'popover' ? (
    <div className={`${isOpen ? 'animate-fade-in' : 'hidden'} ${className}`} onMouseLeave={onClose}>
      <Popover padding="0" size="md">
        {PairSelectPopoverContent}
      </Popover>
    </div>
  ) : (
    <TransitionModal isOpen={isOpen} close={onClose}>
      <ModalHeader title={MODAL_TITLES.PAIR_SELECT} onCloseClick={onClose} />
      {PairSelectPopoverContent}
    </TransitionModal>
  );
};

export default React.memo(PairSelectPopover);
