import { EncodeObject } from '@cosmjs/proto-signing';
import { MsgMarketOrder } from '../crescent/liquidity/types/crescent/liquidity/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';
import createClaimMsg from './Claim';

const createMarketOrderMsg = (txData: any, creAddress: any) => {
  const offerDenom = txData.offer.denom;
  const demandDenom = txData.demand.denom;
  const offerAmount = txData.offer.amount;
  const orderDirection = txData.orderDirection;
  const marketOrderData: MsgMarketOrder = {
    orderer: creAddress,
    pairId: txData.pairId,
    direction: orderDirection,
    offerCoin: {
      denom: offerDenom,
      amount: offerAmount,
    },
    demandCoinDenom: demandDenom,

    amount: txData.baseAmount,
    orderLifespan: { seconds: txData.orderLifespanSeconds, nanos: 0 },
  };

  const msg = msgEncoder.msgMarketOrder(marketOrderData);
  const msgs: EncodeObject[] = [msg];

  return msgs;
};

export default createMarketOrderMsg;
