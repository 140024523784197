import { TwitterTimelineEmbed } from 'react-twitter-embed';
import TwitterLoadingSkeleton from 'components/skeletons/TwitterLoadingSkeleton';
import { useState } from 'react';

const TWITTER_EMBED_PX_HEIGHT = 160;

const TwitterEmbed = () => {
  const [isTwitterLoading, setIsTwitterLoading] = useState(true);

  return (
    <div className="relative max-w-full rounded-8px overflow-auto" style={{ height: `${TWITTER_EMBED_PX_HEIGHT}px` }}>
      {isTwitterLoading && <TwitterLoadingSkeleton className="absolute inset-0" />}

      <div className="relative">
        <TwitterTimelineEmbed
          onLoad={() => {
            setIsTwitterLoading(false);
          }}
          noHeader
          noFooter
          options={{
            height: TWITTER_EMBED_PX_HEIGHT,
            chrome: 'noheader nofooter noborders',
          }}
          theme="dark"
          sourceType="profile"
          screenName={'CrescentHub'}
        />
      </div>
    </div>
  );
};

export default TwitterEmbed;
