import { createContext, useContext } from 'react';
// import { useLocalObservable } from 'mobx-react-lite';
import { createMainStore, MainStore } from '../store/store';
import { observable } from 'mobx';

const MainContext = createContext({} as MainStore);

export const store = observable(createMainStore());

export const MainProvider = ({ children }: { children: React.ReactNode }) => {
  // const mainStore = useLocalObservable(createMainStore);
  // const mainStore = observable(store);
  return <MainContext.Provider value={store}>{children}</MainContext.Provider>;
};

export const useMainStore = () => useContext(MainContext);
