import BigNumber from 'bignumber.js';
import { formatAmount } from 'common/utils';
import Token from 'components/coins/Token';
import NumberText from 'components/texts/NumberText';
import { useMainStore } from 'provider/mainContext';
import { useMemo } from 'react';
import type { InfoAsset } from 'types/asset';

type TokenAmountProps = {
  asset: InfoAsset;
  amount: BigNumber;
  compact?: boolean;
  className?: string;
};

const TokenAmount = ({ asset, amount, compact, className = '' }: TokenAmountProps) => {
  const store = useMainStore();

  const amountString = useMemo<string>(
    () => formatAmount(amount, asset.exponent, { compact }),
    [amount, asset.exponent, compact]
  );

  const assetDetail = useMemo(() => store.assetDetailDict[asset.denom], [store.assetDetailDict, asset.denom]);

  return (
    <div className={`${className} flex items-center gap-x-2`}>
      <Token size="16px" asset={assetDetail} />
      <NumberText value={amountString} unit={asset.ticker} xsDecimal={true} align="right" />
    </div>
  );
};

export default TokenAmount;
