import { MsgUnstake } from '../crescent/farming/types/crescent/farming/v1beta1/tx';
import msgEncoder from 'common/sign/msgEncoder';

const createUnstakeMsg = (txData: any, creAddress: any) => {
  const unfarmData: MsgUnstake = {
    farmer: creAddress,
    unstakingCoins: txData.unfarmingCoin,
  };
  console.log('unfarmData', unfarmData);
  const msg = msgEncoder.msgUnstake(unfarmData);
  return [msg];
};

export default createUnstakeMsg;
