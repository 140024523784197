import { createTxRaw } from '@evmos/proto';
import axios from 'axios';
import { createTxIBCMsgTransfer } from '@evmos/transactions';
import Long from 'long';
import { MessageIBCMsgTransfer } from '@evmos/transactions/dist/messages/ibcMsgTransfer';
import { checkEvmChain, WalletType } from 'common/signingClients/utils/signUtils';
import { EVM_IBC_CLIENT_ID_DICT, IS_PRODUCTION } from 'COMMON_VARIABLES';

// import { StargateClient } from '@cosmjs/stargate';
// import { generateEndpointBroadcast, generatePostBodyBroadcast } from '@evmos/provider';

// TODO: strong type
export async function signEvmChain({
  txData,
  chainData,
  fee,
  toAddress,
  walletType,
}: {
  txData: any;
  chainData: any;
  fee: any;
  toAddress: string;
  walletType: WalletType;
}) {
  const chainId: string = txData.originChainId;
  const receiver: string = toAddress;
  let sequence: number;
  let accountNumber: number;
  let revisionData: any;
  let latestBlockHeight: any;
  let signer: any;
  let account: any;
  let sender: any;
  let pubkey: any;

  // TODO: get signer by wallets
  if (walletType === 'keplr') {
    signer = await window.getOfflineSignerOnlyAmino(chainId);
    account = await signer.getAccounts();
    sender = account[0]?.address;
    pubkey = account[0]?.pubkey;
  } else if (walletType === 'leap') {
    signer = await window.leap.getOfflineSignerOnlyAmino(chainId, { preferNoSetFee: true });
    account = await signer.getAccounts();
    sender = account[0]?.address;
    pubkey = account[0]?.pubkey;
  } else if (walletType === 'cosmostation') {
    signer = await window.cosmostation.providers.keplr.getOfflineSignerOnlyAmino(chainId);
    account = await signer.getAccounts();
    sender = account[0]?.address;
    pubkey = account[0]?.pubkey;
  }

  try {
    const evmChainName = checkEvmChain(chainId).chainName;
    if (evmChainName === undefined) throw new Error('Invalid chainId');

    const ibcClientId = EVM_IBC_CLIENT_ID_DICT[evmChainName][IS_PRODUCTION ? 'mainnet' : 'testnet'];
    const restApiEndPoint: string | undefined = chainData?.info?.[chainId]?.restEndpoint; // @example https://evmos.crescent.network:1317

    let sequenceResponse: any;
    let revisionResponse: any;
    let blockResponse: any;
    await Promise.all([
      axios.get(`${restApiEndPoint}/cosmos/auth/v1beta1/accounts/${sender}`),
      axios.get(`${restApiEndPoint}/ibc/core/client/v1/client_states/${ibcClientId}`),
      axios.get(`https://mainnet.crescent.network:1317/cosmos/base/tendermint/v1beta1/blocks/latest`),
    ])
      .then((values) => {
        sequenceResponse = values[0];
        revisionResponse = values[1];
        blockResponse = values[2];
      })
      .catch((e) => {
        throw new Error(`Error sign TX ${e}`);
      });

    revisionData = revisionResponse?.data?.proof_height;
    sequence = sequenceResponse?.data?.account?.base_account?.sequence;
    accountNumber = sequenceResponse?.data?.account?.base_account?.account_number;
    latestBlockHeight = blockResponse?.data?.block?.header?.height;
    console.log('revisionResponse', revisionResponse);
    console.log('blockResponse', blockResponse);
  } catch (e) {
    console.log('error', e);
    return new Error(`Error sign TX ${e}`);
  }

  // EVM-TEST
  // const sourceChannel = 'channel-11';
  const sourceChannel = chainData?.info?.[chainId]?.ibcRecvChannel;
  const ibcMsgParams: MessageIBCMsgTransfer = {
    sourcePort: 'transfer',
    sourceChannel: sourceChannel,
    denom: txData.denom,
    amount: String(txData.amount),
    receiver: receiver,
    revisionNumber: Number(revisionData.revision_number),
    revisionHeight: Number(latestBlockHeight) + 100,
    timeoutTimestamp: new Date().getTime() + 600000 + '000000',
  };

  const ibcMsg = await createTxIBCMsgTransfer(
    // below chainId property is eth chain id, no use for now
    { chainId: 0, cosmosChainId: chainId },
    {
      accountAddress: sender,
      sequence: sequence,
      accountNumber: accountNumber,
      pubkey: pubkey,
    },
    fee,
    '',
    ibcMsgParams
  );

  // console.log('txData', txData);
  // console.log('chainData', chainData.info);
  // console.log('sender', sender);
  // console.log('sequence', sequence);
  // console.log('accountNumber', accountNumber);
  // console.table('revisionData', revisionData);
  // console.log('ibcMsg', ibcMsg.signDirect.body.timeout_height);

  let signedTxRaw;
  try {
    // TODO: sign by wallets
    let walletSigner: CustomKeplr | undefined;
    if (walletType === 'keplr') {
      walletSigner = window?.keplr;
    } else if (walletType === 'leap') {
      walletSigner = window.leap;
    } else if (walletType === 'cosmostation') {
      walletSigner = window.cosmostation.providers.keplr;
    }
    if (walletSigner === undefined) throw new Error('Wallet not found');
    signedTxRaw = await walletSigner?.signDirect(
      chainId,
      sender,
      {
        bodyBytes: ibcMsg.signDirect.body.serializeBinary(),
        authInfoBytes: ibcMsg.signDirect.authInfo.serializeBinary(),
        chainId: chainId,
        accountNumber: new Long(accountNumber),
      },
      // @ts-expect-error the types are not updated on Keplr side
      { isEthereum: false }
    );
  } catch (e: any) {
    throw new Error(e);
  }

  if (signedTxRaw !== undefined) {
    let rawTx = createTxRaw(signedTxRaw.signed.bodyBytes, signedTxRaw.signed.authInfoBytes, [
      new Uint8Array(Buffer.from(signedTxRaw.signature.signature, 'base64')),
    ]);

    return {
      authInfoBytes: rawTx?.message.auth_info_bytes,
      bodyBytes: rawTx?.message.body_bytes,
      signatures: rawTx?.message.signatures,
    };
  }
}
