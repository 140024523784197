import CoinPair from 'components/coins/CoinPair';
import type { PairDetail } from 'types/pair';
import PairTicker from './PairTicker';

const PairTitle = ({ pair }: { pair: PairDetail }) => {
  return (
    <div className="flex items-center gap-x-3">
      <CoinPair assets={pair.assets} size="32px" />
      <PairTicker pair={pair} size="md" />
    </div>
  );
};

export default PairTitle;
