import BigNumber from 'bignumber.js';
import { formatAmount, formatUSD, getChangeSymbol, getDollarValueOnCreChainByDenom } from 'common/utils';
import { useMemo, useState } from 'react';
import type { OrderbookPair } from 'types/orderbook';
import Coin from 'components/coins/Coin';
import Icon from 'components/icon';
import PairTicker from 'components/pools/PairTicker';
import NumberText from 'components/texts/NumberText';
import Chip from 'components/chips/Chip';
import PairSelectPopover from 'components/orderbook/orderbookHeader/PairSelectPopover';

type TradeHeaderProps = {
  pair: OrderbookPair;
  availabePairs: OrderbookPair[];
  onSelectPair: (pairId: string) => void;
};

const TradeHeader = ({ pair, availabePairs, onSelectPair }: TradeHeaderProps) => {
  const [isPairSelectorOpen, setIsPairSelectorOpen] = useState<boolean>(false);

  const change24Label = useMemo<string>(
    () =>
      pair
        ? `${pair.change_24 === 100000 ? '' : getChangeSymbol(pair.change_24)}${
            pair.change_24 === 100000 ? '-' : formatAmount(new BigNumber(Math.abs(pair.change_24)), 2) + '%'
          }`
        : '',
    [pair]
  );

  return (
    <div className="flex items-center justify-between gap-x-2 px-2">
      <Coin size="64px" asset={pair.baseInfo} className="box_glow_m" />

      <button type="button" className="flex flex-col items-end gap-y-1" onClick={() => setIsPairSelectorOpen(true)}>
        <div className="h-10 flex items-center gap-x-4">
          <Icon type="expandmore" size="24px" className="text-on_surface_variant_light hover:opacity-80" />
          <PairTicker pair={pair} size="2xl" isWeakQuote={true} />
        </div>

        <div className="flex flex-col items-end gap-y-2">
          <div className="flex items-center gap-x-2">
            <div className="flex items-baseline gap-x-1">
              <NumberText
                size="sm"
                color="primary"
                value={formatAmount(pair.lastPrice.shiftedBy(pair.exponentDiff), 8)}
              />
              <div className="font_caption_m text-primary">{pair.quoteInfo.ticker}</div>
            </div>

            <Chip
              size="sm"
              color={pair.change_24 > 0 ? 'green' : pair.change_24 < 0 ? 'red' : 'gray'}
              label={change24Label}
              labelType="number"
            />
          </div>

          <NumberText
            size="sm"
            color="dark"
            value={formatUSD(
              new BigNumber(
                getDollarValueOnCreChainByDenom(
                  pair.quoteInfo.denom,
                  new BigNumber(pair.lastPrice).shiftedBy(pair.exponentDiff).toString()
                )
              ),
              { fixedTo2: false }
            )}
          />
        </div>
      </button>

      <PairSelectPopover
        type="modal"
        isOpen={isPairSelectorOpen}
        pairList={availabePairs}
        selectedPairId={pair?.pairId.toString() ?? ''}
        selectPair={onSelectPair}
        onClose={() => setIsPairSelectorOpen(false)}
        className="absolute left-0 top-[5.25rem] z-[1] elevation_dark_4"
      />
    </div>
  );
};

export default TradeHeader;
